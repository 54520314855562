export const removeValueFromArrayOnce = (arr: string[], value: string): string[] => {
    const newArray = arr.slice();

    const index = newArray.indexOf(value);

    if (index > -1) {
        newArray.splice(index, 1);
    }

    return newArray;
};

export const addValueToArray = (arr: string[], value: any): string[] => {
    const newArray = arr.slice();

    newArray.push(value);

    return newArray;
};
