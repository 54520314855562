import { createElement } from 'react';

import { cn } from '@/utils/cn';

import type { BlockResource, PropsFromParent } from '@/app/editor/blocks/types';
import type { ThemeResource } from '@/app/editor/themes/types';
import type { FC, ComponentClass, ReactNode } from 'react';

export interface Props {
    children?: ReactNode;
    previewBlock: BlockResource;
    component: FC | ComponentClass;
    activeTheme: ThemeResource;
    nestedLevel: number;
    propsFromParent?: PropsFromParent;
    isInColumn?: boolean;
}

const TemplateBlockView = ({
    children,
    previewBlock,
    component,
    propsFromParent,
    activeTheme,
    nestedLevel,
}: Props) => {
    // Enrich Block Component with props
    const blockElement = component
        ? createElement(
              component,
              {
                  isPreview: true,
                  blockId: previewBlock.id,
                  nestedLevel,
                  activeTheme,
                  ...propsFromParent,
                  ...previewBlock.attributes.content,
              },
              children,
          )
        : null;

    return (
        <div className={cn('pointer-events-none relative', propsFromParent?.blockContainerClass)}>
            {blockElement}
        </div>
    );
};

export default TemplateBlockView;
