import {
    EditorEngineEventType,
    EditorEngineExecutionDirection,
    EditorEngineHistoryEntryRecordedActionStatusType,
    EditorEngineHistoryExecuteErrorType,
    EditorEngineLifecycleHandlerTimingType,
} from '@/app/editor/engine/core/types';
import { makeEditorEngineError } from '@/app/editor/engine/utils/error/makeEditorEngineError';

import type {
    EditorEngineActionUpdater,
    EditorEngineEventEmitter,
    EditorEngineHistoryEntryActionResult,
    EditorEnginePersistedUpdate,
    EditorEngineError,
} from '@/app/editor/engine/core/types';

/**
 * The Editor Engine history has two queues: one for processing an action when
 * it is enqueued (including its optimistic update), and one for processing the
 * persisted update of each action.
 *
 * This function will be passed to the queue managed by `useProcessingQueue`
 * for all persisted updates.
 */
export const processPersistedUpdateQueue = async ({
    update,
    onFail,
    updateAction,
    eventEmitter,
}: {
    /**
     * The persisted update to process.
     */
    update: EditorEnginePersistedUpdate;
    /**
     * A function to call when the processing of the update fails.
     */
    onFail: (error: Error) => void;
    /**
     * This function will be called to update the status of the action.
     */
    updateAction: EditorEngineActionUpdater;
    /**
     * The event emitter instance.
     */
    eventEmitter: EditorEngineEventEmitter;
}) => {
    const persistedUpdateStartedAt = Date.now();

    updateAction({
        entry: update.entry,
        recordedAction: update.recordedAction,
        result: {
            type: EditorEngineHistoryEntryRecordedActionStatusType.ExecutingPersistedUpdate,
            direction: update.direction,
            persistedUpdateStartedAt,
            executionDebug: {},
        },
    });

    const reportError = (error: EditorEngineError) => {
        eventEmitter.emit({
            name: EditorEngineEventType.ActionFailed,
            payload: {
                action: update.recordedAction.action,
            },
        });

        eventEmitter.emit({
            name: EditorEngineEventType.HistoryError,
            payload: {
                error: {
                    type: EditorEngineHistoryExecuteErrorType.ActionFailed,
                    error,
                    entry: update.entry,
                    action: update.recordedAction,
                },
            },
        });
    };

    // The below is useful for errors that don't fail the action. If we reach
    // this point that means the backend sync succeeded. The after* function
    // should be only used for non-critical operations. In that case, we
    // only report the error.
    const reportPrePostError = (
        error: EditorEngineError,
        timing: EditorEngineLifecycleHandlerTimingType,
    ) => {
        eventEmitter.emit({
            name: EditorEngineEventType.PrePostError,
            payload: {
                error,
                action: update.recordedAction.action,
                timing,
            },
        });

        eventEmitter.emit({
            name: EditorEngineEventType.HistoryError,
            payload: {
                error: {
                    type: EditorEngineHistoryExecuteErrorType.PrePostError,
                    error,
                    entry: update.entry,
                    timing,
                },
            },
        });
    };

    let result: EditorEngineHistoryEntryActionResult;

    try {
        if (update.direction === EditorEngineExecutionDirection.Forward) {
            update.entry.action.onBeforeExecute?.(update.isReExecution);
        } else {
            update.entry.action.onBeforeUndo?.();
        }
    } catch (error) {
        reportPrePostError(
            makeEditorEngineError({
                error,
            }),
            EditorEngineLifecycleHandlerTimingType.Pre,
        );
    }

    try {
        if (update.direction === EditorEngineExecutionDirection.Forward) {
            result = await update.recordedAction.action.execute(update.isReExecution);
        } else {
            result = await update.recordedAction.action.undo();
        }

        updateAction({
            entry: update.entry,
            recordedAction: update.recordedAction,
            result: {
                direction: update.direction,
                persistedUpdateStartedAt,
                executionDebug: result.additionalDebugData ?? {},
                ...(result.success
                    ? {
                          type: EditorEngineHistoryEntryRecordedActionStatusType.ExecutedSuccessfully,
                          persistedUpdateFinishedAt: Date.now(),
                      }
                    : {
                          type: EditorEngineHistoryEntryRecordedActionStatusType.Failed,
                          persistedUpdateFailedAt: Date.now(),
                      }),
            },
        });

        if (result.success) {
            eventEmitter.emit({
                name: EditorEngineEventType.ActionFinished,
                payload: {
                    action: update.recordedAction.action,
                },
            });
        }

        if (result.success === false) {
            reportError(result.error);
        }
    } catch (error) {
        onFail(error);

        updateAction({
            entry: update.entry,
            recordedAction: update.recordedAction,
            result: {
                type: EditorEngineHistoryEntryRecordedActionStatusType.Failed,
                direction: update.direction,
                persistedUpdateStartedAt,
                persistedUpdateFailedAt: Date.now(),
                executionDebug: {},
            },
        });

        reportError(
            makeEditorEngineError({
                error,
            }),
        );
    }

    try {
        // Note: post execute and post undo will not be called in case of an error
        if (!result || !result.success) {
            // todo(editorengine): maybe we need onError though?
            return;
        }

        if (update.direction === EditorEngineExecutionDirection.Forward) {
            await update.entry.action.onAfterExecute?.({
                result: result.result,
                isRedo: update.isReExecution,
            });
        } else {
            await update.entry.action.onAfterUndo?.({ result: result.result });
        }
    } catch (error) {
        reportPrePostError(
            makeEditorEngineError({
                error,
            }),
            EditorEngineLifecycleHandlerTimingType.Post,
        );
    }
};
