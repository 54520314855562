import type { EditorEngineDefaultDraggableConfiguration } from '@/app/editor/engine/core/types';

/**
 * Always disable dragging for any node.
 */
export const AlwaysDisableDraggableConfiguration = {
    canBeDragged: () => false,
    canBeTargeted: () => false,
    canTarget: () => false,
    executeDrop: () => {
        return {
            handler: () => {},
        };
    },
    getMessageForOperation: () => false,
    getDropPreviewOptionsForOperation: () => ({}),
} satisfies EditorEngineDefaultDraggableConfiguration;
