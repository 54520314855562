import { NAME } from '@/app/editor/blocks/constants';

import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { getActiveCampaign } from '@/app/campaigns/models/campaigns';
import { getBlockDetails } from '@/app/editor/blocks/helpers';
import { getActivePageBlockIds, getBlockIndexOnPage } from '@/app/editor/blocks/models/blockOrder';
import {
    fetchBlock,
    fetchBlockWithChildren,
    getActiveBlockId,
    getBlockById,
    setActiveBlock,
    setBlock,
} from '@/app/editor/blocks/models/blocks';
import { fetchTrackingProperties } from '@/app/editor/blocks/models/personalization';
import { fetchSinglePage, getActivePageId } from '@/app/editor/pages/models/pages';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_STRING } from '@/utils/empty';
import { addValueToArray, removeValueFromArrayOnce } from '@/utils/mutation';

import { DuplicateBlockCommand } from '../../commands/commands/duplicateBlockCommand';
import getHistoryController from '../../commands/utils/HistoryControllers';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    duplicating: string[];
    clipboardBlock: string;
}

const initialState: State = {
    duplicating: EMPTY_ARRAY,
    clipboardBlock: EMPTY_STRING,
};

export const blockDuplicateSlice = createSlice({
    name: `editor/${NAME}/duplicate`,
    initialState,
    reducers: {
        setDuplicatingBlocks(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                duplicating: action.payload,
            };
        },
        setClipboardBlockId(state, action: PayloadAction<string>) {
            return {
                ...state,
                clipboardBlock: action.payload,
            };
        },
        reset: (state, action: PayloadAction<boolean>) => {
            // Keep `clipboardBlock` when switching funnels (Editor resets on unmount)
            const resetClipboard = action.payload;

            return {
                clipboardBlock: resetClipboard ? initialState.clipboardBlock : state.clipboardBlock,
                duplicating: initialState.duplicating,
            };
        },
    },
});

// === Actions ======

export const { setDuplicatingBlocks, setClipboardBlockId, reset } = blockDuplicateSlice.actions;

// === Selectors ======

export const getDuplicatingBlocks = (state: AppState) =>
    state[NAME].duplicateBlockReducer?.duplicating;

export const getDuplicatingBlockById = (state: AppState, blockId: string) =>
    state[NAME]?.duplicateBlockReducer?.duplicating.includes(blockId);

export const getClipboardBlockId = (state: AppState) => {
    return state[NAME]?.duplicateBlockReducer?.clipboardBlock || EMPTY_STRING;
};

// === Helpers ======

const postDuplication = (newBlock: BlockResource) => async (dispatch) => {
    const { campaignId, pageId, childComponents, parentComponent, config } =
        getBlockDetails(newBlock);

    // Re-fetch Tracking Properties when inputs/questions change
    dispatch(fetchTrackingProperties(campaignId));

    // Fetch Child Components if the duplicated component has any
    if (childComponents.data?.length) {
        await dispatch(fetchBlockWithChildren(newBlock.id));
    }

    // Fetch Parent if duplicated component has one
    if (parentComponent.data?.id) {
        await dispatch(fetchBlock(parentComponent.data.id));
    }

    // Re-fetch page for updated block relationships
    await dispatch(fetchSinglePage(pageId));

    // set new block as active block
    dispatch(setActiveBlock(newBlock.id));

    // Execute additional action after duplication if defined
    const action = get(config, 'actions.onDuplicate');

    if (action) {
        await dispatch(action(newBlock));
    }
};

const addToDuplicatingBlocks = (blockId: string) => (dispatch, getState) => {
    const state = getState();
    const duplicatingBlocks = getDuplicatingBlocks(state);

    // Add blockId
    const updatedDuplicatingBlocks = addValueToArray(duplicatingBlocks, blockId);

    dispatch(setDuplicatingBlocks(updatedDuplicatingBlocks));
};

const removeFromDuplicatingBlocks = (blockId: string) => (dispatch, getState) => {
    const state = getState();
    const duplicatingBlocks = getDuplicatingBlocks(state);

    // Remove blockId
    const updatedDuplicatingBlocks = removeValueFromArrayOnce(duplicatingBlocks, blockId);

    dispatch(setDuplicatingBlocks(updatedDuplicatingBlocks));
};

// === Thunks ======

export const duplicateBlock =
    (blockId: string, data = {}): AppThunk<Promise<BlockResource>> =>
    async (dispatch) => {
        try {
            dispatch(addToDuplicatingBlocks(blockId));

            const response = await apiPost<ResponseData<BlockResource>>(
                `/components/${blockId}/duplicate`,
                { data },
            );

            const newBlock = getDataFromResponse(response);

            // set block data in Redux
            await dispatch(setBlock(newBlock));

            await dispatch(postDuplication(newBlock));

            return newBlock;
        } catch (err) {
            handleRuntimeError(err, { debugMessage: `duplicating block ${blockId} failed:` });
        } finally {
            dispatch(removeFromDuplicatingBlocks(blockId));
        }
    };

export const duplicateBlockFromClipboard =
    (command?: (index: number) => void): AppThunk =>
    async (_, getState) => {
        const state = getState();
        const clipboardBlockId = getClipboardBlockId(state);
        const clipboardBlock = getBlockById(state, clipboardBlockId);

        if (!clipboardBlock) {
            return;
        }

        try {
            const historyController = getHistoryController();
            const campaign = getActiveCampaign(state);
            const pageId = getActivePageId(state);
            const blocks = getActivePageBlockIds(state);
            const activeBlockId = getActiveBlockId(state);
            const activeBlockIndex = getBlockIndexOnPage(state, activeBlockId);
            const index =
                Boolean(activeBlockId) && activeBlockIndex !== -1
                    ? activeBlockIndex + 1
                    : blocks.length;

            // Needed for the editor engine to override the actual duplication
            if (command) {
                command(index);

                return;
            }

            const duplicateBlockCommand = new DuplicateBlockCommand(clipboardBlock.id, {
                campaign: campaign.id,
                page: pageId,
                index,
            });

            await historyController.executeCommand(duplicateBlockCommand);
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'duplicating block from clipboard failed:' });
        }
    };

export default blockDuplicateSlice.reducer;
