import { EditorEngineDropPosition } from '@/app/editor/engine/core/types';
import { EditorEngineOrientation } from '@/app/editor/engine/core/types/util';
import { getOrientationFromPosition } from '@/app/editor/engine/core/utils/dragAndDrop/getOrientationFromPosition';
import { isDropNoOp } from '@/app/editor/engine/core/utils/dragAndDrop/isDropNoOp';

import type {
    EditorEngineDropPreview,
    EditorEngineNodeData,
    EditorEngineNodeManager,
} from '@/app/editor/engine/core/types';

/**
 * Check if a drop is allowed. While the draggable configuration (as setup
 * by the developer) will determine if a node can be dragged, this function
 * exists to exclude certain drop operations that are never allowed
 * independently of the draggable configuration.
 *
 * This function will not allow a drop if:
 *
 * - The original and target nodes are the same
 * - The original node is an ancestor of the target node
 * - In a sequence of nodes, a drop is only allowed after each node, or
 * before if the target is the first one in the sequence
 */
export const isDropAllowed = <TNodeData extends EditorEngineNodeData>({
    nodeManager,
    preview,
}: {
    nodeManager: EditorEngineNodeManager<TNodeData>;
    preview: EditorEngineDropPreview<TNodeData>;
}) => {
    if (nodeManager.identify(preview.original) === nodeManager.identify(preview.target)) {
        return false;
    }

    if (
        nodeManager.isAncestor({
            nodeId: nodeManager.identify(preview.target),
            potentialAncestorId: nodeManager.identify(preview.original),
        })
    ) {
        return false;
    }

    const { futurePosition } = preview;
    const orientation = getOrientationFromPosition(futurePosition);
    const isVertical = orientation === EditorEngineOrientation.Vertical;
    const isHorizontal = orientation === EditorEngineOrientation.Horizontal;
    const isNoOp = isDropNoOp({ nodeManager, preview });

    if (!nodeManager.isFirstInOrientation(preview.target, orientation)) {
        // When the target is not the first one in this orientation, we only
        // allow the drop after the target.
        // If the operation is no op, we allow it. That's because the user might
        // want to cancel the operation by dropping the node in the same place,
        // which means it should be possible to drop above the target in that
        // case.
        if (isVertical && futurePosition === EditorEngineDropPosition.Top && !isNoOp) {
            return false;
        }

        if (isHorizontal && futurePosition === EditorEngineDropPosition.Left && !isNoOp) {
            return false;
        }
    }

    return true;
};
