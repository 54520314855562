import type {
    EditorEngineEventListener,
    EditorEngineEventListenerMap,
    EditorEngineEventType,
} from '@/app/editor/engine/core/types';
import type { getRemoveListener } from '@/app/editor/engine/core/utils/events/getRemoveListener';
import type { MutableRefObject } from 'react';

export const getAddListener = ({
    listeners,
    removeListener,
}: {
    listeners: MutableRefObject<EditorEngineEventListenerMap>;
    removeListener: ReturnType<typeof getRemoveListener>;
}) => {
    return <TEventType extends EditorEngineEventType>({
        name,
        listener,
    }: {
        name: TEventType;
        listener: EditorEngineEventListener<TEventType>;
    }) => {
        listeners.current = {
            ...listeners.current,
            [name]: [...(listeners.current[name] ?? []), listener],
        };

        return () => removeListener({ name, listener });
    };
};
