import { Component } from 'react';

import { reportMessage } from '@/utils/sentry';

import type { ReactNode } from 'react';

export interface Props {
    children: ReactNode;
}

// Prevents draft.js to crash the app
// https://github.com/facebook/draft-js/issues/1320#issuecomment-472776784

class DraftErrorBoundary extends Component<Props> {
    componentDidCatch(error: Error) {
        reportMessage({
            message: `Caught an error in DraftEditor: ${error.message}`,
            source: 'draftjs',
        });

        this.forceUpdate();
    }

    render() {
        return <>{this.props.children}</>;
    }
}

export default DraftErrorBoundary;
