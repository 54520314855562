import { MODAL_OPTIONS } from '@/app/modals/constants';

import { createSlice } from '@reduxjs/toolkit';
import { change, submit } from 'redux-form';

import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME, TESTIMONIAL_CROP_ASPECT_SIZE } from '../constants';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    activeIndex: { [sliderId: string]: number };
}

const initialState: State = {
    activeIndex: EMPTY_OBJECT,
};

export const imageSliderReducer = createSlice({
    name: `editor/${NAME}/slider`,
    initialState,
    reducers: {
        setActiveIndex(state, action: PayloadAction<{ sliderId: string; index: number }>) {
            return {
                ...state,
                activeIndex: {
                    ...state.activeIndex,
                    [action.payload.sliderId]: action.payload.index,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setActiveIndex, reset } = imageSliderReducer.actions;

// === Selectors ======

export const getActiveIndexBySliderId = (state: AppState, sliderId: string) =>
    state[NAME]?.sliderReducer?.activeIndex[sliderId] || 0;

// === Thunks ======

export const cropCurrentSlide =
    (block: BlockResource): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const activeIndex = getActiveIndexBySliderId(state, block?.id);
        const size = block?.attributes?.content?.size;

        const images = block?.attributes?.content?.images;

        const trimmedSrc = `${images[activeIndex]?.split('?')[0]}?w=640&dpr=2`;

        const handleSave = async (url: string) => {
            // update edit form data and submit
            await dispatch(change(block?.id, `attributes.content.images[${activeIndex}]`, url));
            dispatch(submit(block?.id));
        };

        dispatch(
            showModal(
                Modals.IMAGE_CROPPING,
                {
                    src: trimmedSrc,
                    originalSize: size,
                    onSave: handleSave,
                    withSizeSlider: true,
                },
                MODAL_OPTIONS[Modals.IMAGE_CROPPING],
            ),
        );
    };

export const cropCurrentTestimonial =
    (block: BlockResource): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const activeIndex = getActiveIndexBySliderId(state, block?.id);

        const testimonials = block?.attributes?.content?.testimonials;

        const trimmedSrc = `${testimonials[activeIndex].image?.split('?')[0]}?w=640&dpr=2`;

        const handleSave = async (url: string) => {
            // update edit form data and submit
            await dispatch(
                change(block?.id, `attributes.content.testimonials[${activeIndex}].image`, url),
            );
            dispatch(submit(block?.id));
        };

        dispatch(
            showModal(
                Modals.IMAGE_CROPPING,
                {
                    src: trimmedSrc,
                    onSave: handleSave,
                    originalSize: TESTIMONIAL_CROP_ASPECT_SIZE,
                },
                MODAL_OPTIONS[Modals.IMAGE_CROPPING],
            ),
        );
    };

export default imageSliderReducer.reducer;
