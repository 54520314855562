import { cn } from '@/utils/cn';

export interface Props {
    url?: string;
    onClick?: (url?: string) => void;
    disabled?: boolean;
    clickable?: boolean;
}

const PlayButton = ({ onClick, url, disabled, clickable }: Props) => {
    const handleClick = () => onClick?.(url);

    const cursorClass = cn('absolute left-0 top-0 flex size-full items-center justify-center', {
        'cursor-pointer': onClick || clickable,
        'cursor-not-allowed': disabled,
        'cursor-default': !onClick && !disabled && !clickable,
    });

    return (
        <div className={cursorClass} onClick={onClick ? handleClick : undefined}>
            <div className="flex size-16 items-center justify-center rounded-full border-2 border-white bg-black/20 text-[0px] backdrop-blur-sm">
                <div className="size-6 pl-0.5">
                    <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 21 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.5 27.6351V2.2311C0.5 0.54203 2.46429 -0.386475 3.76948 0.685642L19.5764 13.6699C20.5641 14.4813 20.5477 15.9983 19.5426 16.788L3.73564 29.2077C2.42339 30.2388 0.5 29.3039 0.5 27.6351Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default PlayButton;
