import { INVALID_SCRIPT, MALFORMED_SCRIPT, STICKY_SCRIPT } from '../helper';

export const provenexpertDefaultValidator = (
    container: HTMLDivElement,
    head: string,
    body: string,
) => {
    let isOnlyOneAnchor = false;
    let hasNestedImage = false;
    let linksToPE = false;
    let hasPEIMageSource = false;
    let isNotSticky = false;

    if (container.childNodes.length === 3 || container.childNodes.length === 4) {
        container.removeChild(container.firstChild);
        container.removeChild(container.lastChild);
    } else if (container.childNodes.length !== 1) {
        return {
            valid: false,
            message: MALFORMED_SCRIPT,
        };
    }

    if (body.includes('position:fixed')) {
        return {
            valid: false,
            message: STICKY_SCRIPT,
        };
    } else {
        isNotSticky = true;
    }

    if (container.firstElementChild?.tagName === 'A') {
        isOnlyOneAnchor = true;
    }

    if (container.firstElementChild?.firstElementChild?.tagName === 'IMG') {
        hasNestedImage = true;
    }

    if (
        container.firstElementChild
            ?.getAttribute('href')
            ?.startsWith('https://www.provenexpert.com')
    ) {
        linksToPE = true;
    }

    if (
        container.firstElementChild?.firstElementChild
            ?.getAttribute('src')
            ?.startsWith('https://images.provenexpert.com')
    ) {
        hasPEIMageSource = true;
    }

    return {
        valid: isOnlyOneAnchor && hasNestedImage && linksToPE && hasPEIMageSource && isNotSticky,
        message: INVALID_SCRIPT,
    };
};
