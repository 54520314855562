import { XMarkIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useRef } from 'react';

import ButtonLoadingSpinner from '@/ui/components/ButtonLoadingSpinner';
import { cn } from '@/utils/cn';

import type { ChangeEventHandler, KeyboardEventHandler, SyntheticEvent } from 'react';

export interface Props {
    onChange: ChangeEventHandler<HTMLInputElement>;
    onFocus?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: ChangeEventHandler<HTMLInputElement>;
    onEnter?: KeyboardEventHandler<HTMLInputElement>;
    onClear?: (event: SyntheticEvent) => void;
    value: string;
    loading?: boolean;
    placeholder: string;
    className?: string;
}

export const SearchInput = ({
    onChange,
    onClear,
    onFocus,
    onBlur,
    onEnter,
    value,
    loading,
    placeholder,
    className,
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClear = (event: SyntheticEvent) => {
        inputRef.current?.focus();
        onClear?.(event);
    };

    const handleKeydown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter') {
            onEnter?.(event);
        }
    };

    return (
        <div
            className={cn(
                'relative flex h-10 items-center rounded-lg bg-gray-100 text-gray-400 focus-within:text-blue-500 hover:text-gray-600 focus-within:hover:text-blue-500',
                className ? className : 'mt-2',
            )}
        >
            <MagnifyingGlassIcon className="ml-4 size-5" />
            <input
                onKeyDown={handleKeydown}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                type="text"
                ref={inputRef}
                className="placeholder-normal absolute h-10 w-full rounded-md bg-transparent px-12 text-black outline-none sm:text-sm"
                placeholder={placeholder}
                value={value}
            />
            {loading && <ButtonLoadingSpinner className="absolute right-4 text-gray-600" />}
            {!loading && onClear && value?.length > 0 && (
                <XMarkIcon
                    className="absolute right-4 size-5 cursor-pointer text-gray-400 hover:text-gray-500"
                    onClick={handleClear}
                />
            )}
        </div>
    );
};

export default SearchInput;
