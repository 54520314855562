import { NAME } from '@/app/editor/blocks/constants';

import { CreditCardIcon, LinkIcon } from '@heroicons/react/24/outline';
import get from 'lodash/get';
import { useTranslation } from 'next-i18next';
import { Field, reduxForm } from 'redux-form';

import { usePaymentAccountConnection } from '@/app/editor/blocks/hooks/mutations/usePaymentAccountConnection';
import {
    CurrencyDropdown,
    DEFAULT_CURRENCY_CODE,
} from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CurrencyDropdown';
import TextInput from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextInput';
import { Button } from '@/ui/components/Button';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FormErrors, InjectedFormProps } from 'redux-form';

type Props = DefaultEditFormProps & InjectedFormProps;

const amountRegex = /^\d{1,3}(?:[.,]\d{3})*[.,]\d{2}$/;

const validate = (values: BlockResource): FormErrors => {
    const errors: FormErrors<BlockResource, any> = {
        attributes: {
            content: {},
        },
    };

    const amountInText = get(values, 'attributes.content.amountInText', '');

    if (amountInText !== '' && !amountRegex.test(amountInText)) {
        errors.attributes.content.amountInText = 'payment.validation.invalid-amount';
    }

    return errors;
};

const Form = ({ handleSubmit, blockId }: Props) => {
    const { t } = useTranslation(NAME);
    const { mutate: connectPaymentAccount, isPending: isConnecting } =
        usePaymentAccountConnection(blockId);

    const handleConnectClick = async () => {
        if (!isConnecting) {
            await connectPaymentAccount();
        }
    };

    return (
        <>
            <div className="mb-2 mt-4">
                <Field
                    name="attributes.content.amountInText"
                    component={TextInput}
                    onBlurSubmit={handleSubmit}
                    placeholder="29,90"
                    icon={CreditCardIcon}
                    parse={(value) => value.replace(/[^0-9.,]/g, '')}
                />
            </div>
            <div className="mb-2 mt-4">
                <Field
                    name="attributes.content.currency"
                    component={CurrencyDropdown}
                    onChangeSubmit={handleSubmit}
                    defaultValue={DEFAULT_CURRENCY_CODE}
                />
            </div>
            <div className="mb-2 mt-4">
                <Button
                    loading={isConnecting}
                    className="text-sm font-normal md:w-full"
                    variant="primary"
                    type="button"
                    onClick={handleConnectClick}
                >
                    <LinkIcon className="mr-2 size-5" /> {t('payment.stripe.connect')}
                </Button>
            </div>
        </>
    );
};

export const PaymentFormForm = reduxForm({
    validate,
})(Form);
