import Head from 'next/head';

import { getIsLocalhostEnv } from '@/utils/environments';

const PerspectiveFavicon = () => {
    const favicon16 = getIsLocalhostEnv()
        ? '/favicon/favicon-dev-16x16.png'
        : '/favicon/favicon-16x16.png';
    const favicon32 = getIsLocalhostEnv()
        ? '/favicon/favicon-dev-32x32.png'
        : '/favicon/favicon-32x32.png';

    return (
        <Head>
            <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
            <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
            <link rel="manifest" href="/favicon/site.webmanifest" />
            <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="apple-mobile-web-app-title" content="Perspective" />
            <meta name="application-name" content="Perspective" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
};

export default PerspectiveFavicon;
