import { UserRole } from '@/app/user/types';

import type { CampaignResource } from '../campaigns/types';
import type {
    Relationship,
    RelationshipObject,
    Resource,
    RelationshipArray,
} from '@/core/api/types';
import type { ReactNode, SVGProps } from 'react';

export interface WorkspaceAttributes {
    name: string;
    default?: boolean;
    logo?: string;
    perspectiveDomain?: string;
}

export interface WorkspaceRelationships {
    campaigns: RelationshipArray;
    company: Relationship;
    favicons: RelationshipArray;
    images: RelationshipArray;
    users: RelationshipArray;
}

export type WorkspaceResource = Resource<WorkspaceAttributes, WorkspaceRelationships>;

export type Workspaces = Record<string, WorkspaceResource>;

export type WorkspaceWithKpisResource = WorkspaceResource & {
    analytics: {
        sessions: number;
        contacts: number;
        conversion: number;
        campaigns: number;
    };
};

export enum InvitationStatus {
    Pending = 'pending',
    Cancelled = 'cancelled',
    Success = 'success',
    Error = 'error',
}

export enum WorkspaceInvitationRoles {
    crm = UserRole.crm,
    editor = UserRole.editor, // part of advancedRoles feature
    workspaceAdmin = UserRole.workspaceAdmin, // part of advancedRoles feature
    admin = UserRole.admin,
}

export interface WorkspaceInvitationAttributes {
    status: InvitationStatus;
    email: string;
    expiresAt: string;
    role: WorkspaceInvitationRoles;
}

export interface WorkspaceInvitationRelationships {
    workspace: Relationship;
    company: Relationship;
}

export type WorkspaceInvitationResource = Resource<
    WorkspaceInvitationAttributes,
    WorkspaceInvitationRelationships
>;

export const enum SetUpSteps {
    Intro,
    Info,
    Domain,
    MoveFunnelsToWorkspace,
    InviteUser,
    SetupFinish,
}

export const enum WorkspaceInfoFields {
    Name = 'name',
    Logo = 'logo',
    Domain = 'perspectiveDomain',
    User = 'user',
}

export const enum InviteMemberFormFields {
    Email = 'email',
    Role = 'role',
}

export type InviteMemberFormData = {
    [InviteMemberFormFields.Email]: string;
    [InviteMemberFormFields.Role]: WorkspaceInvitationRoles;
};

export type NewWorkspaceFormData = {
    [WorkspaceInfoFields.Name]?: string;
    [WorkspaceInfoFields.Domain]?: string;
    [WorkspaceInfoFields.User]?: string;
    [WorkspaceInfoFields.Logo]?: File;
};

export const enum AccountSetUpScreens {
    Account,
    Success,
    Error,
    Expired,
}

export const enum AccountLoginScreens {
    Login,
    PasswordReset,
}

export const enum AccountInfoFields {
    Name = 'name',
    Email = 'email',
    Password = 'password',
    Consent = 'consent',
    RememberMe = 'rememberMe',
}

export type NewAccountData = {
    [AccountInfoFields.Name]?: string;
    [AccountInfoFields.Password]?: string;
};

export const enum RequestState {
    Idle,
    InProgress,
    Error,
    Success,
    Done,
}

export type DomainAvailabilityResponseData = {
    available?: boolean;
};

export interface FunnelSelectOption {
    campaign: CampaignResource;
    withWorkspaceLabel?: boolean;
}

export const enum WorkspaceSetting {
    General = 'GENERAL',
    Icon = 'ICON',
    Preview = 'PREVIEW',
    Funnels = 'FUNNELS',
    /* TODO: Remove Funnel and People settings after wks-settings page ff is off :web-nikos
     */
    Funnel = 'FUNNEL',
    People = 'PEOPLE',
    Members = 'MEMBERS',
    DeleteWorkspace = 'DELETE_WORKSPACE',
    StatusTemplates = 'STATUS_TEMPLATES',
    SenderSettings = 'SENDER_SETTINGS',
}

export interface SidebarSetting {
    id: WorkspaceSetting;
    icon: (props: SVGProps<SVGSVGElement>) => ReactNode;
    activeIcon: (props: SVGProps<SVGSVGElement>) => ReactNode;
    title: string;
    isActive: boolean;
    isDanger?: boolean;
    link?: string;
}

export const enum DomainAvaibilityStatus {
    Available,
    NotAvailable,
    Invalid,
}

export const enum FunnelSelectionActions {
    Move,
    Remove,
    Add,
    MoreActions,
}

export interface InvitationData {
    email: string;
    status: InvitationStatus;
    expiresAt: string;
}

export interface InvitationRelationship {
    company?: RelationshipObject<'company'>;
    workspace?: RelationshipObject<'workspace'>;
}

export type InvitationResource = Resource<InvitationData, InvitationRelationship>;

export type AllWorkspacesView = 'grid' | 'list';

export type AllWorkspacesViewEntry = {
    default: boolean;
    id: string;
    name?: string;
    logoSrc?: string;
    memberAmount: number;
    funnelAmount: number;
    contactAmount: number;
    conversionRate: number;
};
