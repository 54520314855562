import { Children, cloneElement, isValidElement } from 'react';

import NewComponent from '@/app/editor/blocks/components/QuestionMediaAnswer/NewComponent';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '../../types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({ children, nestedLevel, additionalBlocks }) => {
        const mediaAnswerMediaBorderRadius = useBorderRadius('topWrapper');
        const mediaAnswerTextBorderRadius = useBorderRadius('bottomWrapper');
        const childBlocks = Children.toArray(children);

        const mediaBlock = isValidElement<BlockProps>(childBlocks[0])
            ? cloneElement(childBlocks[0], {
                  nestedLevel: nestedLevel + 1,
                  propsFromParent: {
                      wrapperClass: cn(
                          'overflow-hidden rounded-b-none',
                          mediaAnswerMediaBorderRadius,
                      ),
                      themedBackgroundColor: 'formFieldBackgroundColor',
                      formData: additionalBlocks?.[children[0]?.key],
                  },
              })
            : null;

        const textBlock = isValidElement<BlockProps>(childBlocks[1])
            ? cloneElement(childBlocks[1], {
                  nestedLevel: nestedLevel + 1,
                  propsFromParent: {
                      wrapperClass: cn(
                          'h-full overflow-hidden rounded-t-none *:h-full',
                          mediaAnswerTextBorderRadius,
                      ),
                      blockContainerClass: 'flex-grow',
                      blockWrapperClass: 'h-full',
                      themedBackgroundColor: 'formFieldBackgroundColor',
                      isLightnessDependent: true,
                  },
              })
            : null;

        return (
            <div className="flex h-full flex-col">
                {mediaBlock}
                {textBlock}
            </div>
        );
    },
    NewComponent,
);

Component.displayName = 'QuestionMediaAnswer';

export default Component;
