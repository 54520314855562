import getVideoId from 'get-video-id';
import { useEffect, useState } from 'react';

import { reportMessage } from '@/utils/sentry';
import { requestVimeoDetails } from '@/utils/video/vimeo';
import { requestYoutubeDetails } from '@/utils/video/youtube';

// Types
export interface VideoDetails {
    title: string;
    thumbnail?: string;
    duration: number;
}

export const defaultDetails: VideoDetails = {
    title: '',
    thumbnail: '',
    duration: 0,
} as const;

export const useVideoDetails = (url: string): VideoDetails => {
    const [details, setDetails] = useState(defaultDetails);

    useEffect(() => {
        const { id, service } = getVideoId(url);

        if (id && (service === 'youtube' || service === 'vimeo')) {
            (async () => {
                try {
                    if (service === 'youtube') {
                        const details = await requestYoutubeDetails(id);
                        setDetails(details);
                    }

                    if (service === 'vimeo') {
                        const details = await requestVimeoDetails(id);
                        setDetails(details);
                    }
                } catch (err) {
                    reportMessage({
                        message: `fetching video details failed: ${err.message}`,
                        source: 'video-details',
                    });
                }
            })();
        }

        // To prevent memory leaked and "Can't perform a React state update on an unmounted component." warning
        // Read more: https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
        return () => {
            setDetails(defaultDetails);
        };
    }, [url]);

    return details;
};
