import type {
    EditorEngineEventListenerMap,
    EditorEngineEventType,
    EditorEngineGetEventPayload,
} from '@/app/editor/engine/core/types';
import type { MutableRefObject } from 'react';

export const getEmit = ({
    listeners,
}: {
    listeners: MutableRefObject<EditorEngineEventListenerMap>;
}) => {
    return <TEventType extends EditorEngineEventType>({
        name,
        payload,
    }: {
        name: TEventType;
        payload: Omit<EditorEngineGetEventPayload<TEventType>, 'type'>;
    }) => {
        for (const listener of listeners.current[name] ?? []) {
            listener({
                type: name,
                ...payload,
            } as EditorEngineGetEventPayload<TEventType>);
        }
    };
};
