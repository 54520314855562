import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { Field, reduxForm } from 'redux-form';

import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({ handleSubmit }) => {
    const { t } = useTranslation(NAME);

    return (
        <div className="grid grid-cols-3 gap-2">
            <Field
                name="attributes.content.box.backgroundColor"
                component={Color}
                expand="right"
                submit={handleSubmit}
                hiddenThemingButton={true}
                tooltip={t('background-color')}
            />
        </div>
    );
};

Form.displayName = 'AccordionItemEditForm';

export default reduxForm({})(Form);
