import pkg from '../../../package.json';

import type { SubscriptionResource } from '@/app/billing/types';
import type { CompanyResource } from '@/app/company/types';
import type { UserResource } from '@/app/user/types';
import type { AllFeatureFlags } from '@/core/loli-feature-flags/types';
import type { UserTraits } from '@/core/tracking/types';

export const showWelcomeLog = () => {
    if (typeof window !== 'undefined') {
        console.log(
            '    ____                                 __  _          \n' +
                '   / __ \\___  ______________  ___  _____/ /_(_)   _____ \n' +
                '  / /_/ / _ \\/ ___/ ___/ __ \\/ _ \\/ ___/ __/ / | / / _ \\\n' +
                ' / ____/  __/ /  (__  ) /_/ /  __/ /__/ /_/ /| |/ /  __/\n' +
                `/_/    \\___/_/  /____/ .___/\\___/\\___/\\__/_/ |___/\\___/ v${pkg.version}\n` +
                '                    /_/                                 ' +
                '\n' +
                'Looking for a job? Visit https://www.perspective.co/jobs',
        );
    }
};

export const buildUserTraits = (
    user: UserResource,
    company: CompanyResource,
    subscription: SubscriptionResource,
    allFeatureFlags: AllFeatureFlags,
): UserTraits => {
    return {
        id: user?.attributes?.trackingId,
        email: user?.attributes?.email,
        name: user?.attributes?.fullName,
        firstName: user?.attributes?.firstName,
        lastName: user?.attributes?.lastName,
        language: user?.attributes?.language,
        plan: subscription?.attributes?.planId,
        company: {
            id: company?.id,
            name: company?.attributes?.name,
            plan: subscription?.attributes?.planId,
        },
        featureFlags: allFeatureFlags,
    };
};
