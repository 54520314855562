import { NAME } from '@/app/editor/editor/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { ShareIcon } from '@heroicons/react/20/solid';
import get from 'lodash/get';
import { useTranslation } from 'next-i18next';
import { useState, useEffect, useMemo } from 'react';

import { getBlockById } from '@/app/editor/blocks/models/blocks';
import {
    getLinkingAsString,
    getTrackingLinkingType,
} from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Linking/helper';
import { createPageOptions } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/ResultMapping/helper';
import { getPopulatedResultPagesByCampaignId } from '@/app/editor/pages/models/pageMapping';
import {
    getResultMappingByCampaignId,
    updateResultMapping,
} from '@/app/editor/pages/models/resultMapping';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import CategorizedDropdown from '@/ui/components/_BlockEditFields/CategorizedDropdown';
import { EMPTY_ARRAY } from '@/utils/empty';

import type { DropdownCategory } from '@/ui/types';

export interface Props {
    campaignId: string;
    blockId: string;
    pageId: string;
    className?: string;
}

const ResultMapping = ({ pageId, campaignId, blockId, className }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();

    const selectPopulatedResultPages = useMemo(
        () => getPopulatedResultPagesByCampaignId(campaignId),
        [campaignId],
    );

    const mapping = useAppSelector((state) => getResultMappingByCampaignId(state, campaignId));
    const resultPages = useAppSelector(selectPopulatedResultPages);
    const block = useAppSelector((state) => getBlockById(state, blockId));

    const [categories, setCategories] = useState<DropdownCategory[]>([]);

    const currentValue = get(
        mapping,
        `attributes.pageMaps.${pageId}.${blockId}.destination`,
        'none',
    );

    useEffect(() => {
        let categories: DropdownCategory[] = [
            {
                options: [
                    {
                        key: t('no-result'),
                        value: 'none',
                        icon: 'none',
                    },
                ],
            },
            {
                name: t('results'),
                options: [],
            },
        ];

        // Add result pages
        categories[1].options = createPageOptions(resultPages, 'results');

        setCategories(categories);
    }, [t, resultPages, dispatch, campaignId]);

    const handleSelect = (resultId: string) => {
        dispatch(
            updateResultMapping({
                mappingId: mapping.id,
                pageId,
                blockId,
                resultId,
            }),
        );

        // Tracking
        if (resultId !== 'none') {
            track(TRACKING_EVENTS.campaign.editor.resultPageLogic.added, {
                component_type: block?.attributes?.componentType,
            });
        }
    };

    const isLinkingPointingToResultPage = useMemo(() => {
        const linking = getLinkingAsString(get(block, 'attributes.content.linking', {}));

        // Consciously passing an empty array for "otherPages", as we are only interested
        // in whether the linking is a result_page linking.
        return getTrackingLinkingType(linking, EMPTY_ARRAY, resultPages) === 'result_page';
    }, [block, resultPages]);

    return (
        !isLinkingPointingToResultPage && (
            <div className={className}>
                <CategorizedDropdown
                    categories={categories}
                    value={currentValue}
                    onChange={handleSelect}
                    buttonIcon={ShareIcon}
                />
            </div>
        )
    );
};

export default ResultMapping;
