import { isDropNoOp } from '@/app/editor/engine/core/utils/dragAndDrop/isDropNoOp';

import type { EditorEngineDefaultTypeInput } from '../../types';
import type { EditorEngineHistory } from '../../types';
import type { EditorEngineDropCandidate } from '@/app/editor/engine/core/types';
import type { DndContextProps } from '@dnd-kit/core';
import type { MutableRefObject } from 'react';

/**
 * Returns a function that handles the end of a drag operation.
 */
export const getOnDragEnd = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    setDraggedNodeId,
    dropCandidate,
    clearDropCandidate,
    history,
    actions,
    nodeManager,
    isDraggingRef,
}: {
    /**
     * A function for setting the ID of the node that is being dragged.
     */
    setDraggedNodeId: (nodeId: string) => void;
    /**
     * The drop candidate.
     */
    dropCandidate: EditorEngineDropCandidate<TEditorEngineTypeInput> | null;
    /**
     * A function for clearing the drop candidate.
     */
    clearDropCandidate: () => void;
    /**
     * The history that contains the user's actions.
     */
    history: EditorEngineHistory;
    /**
     * The actions that the editor engine can perform.
     */
    actions: TEditorEngineTypeInput['Actions'];
    /**
     * The node manager.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
    /**
     * A reference to a boolean. The value is `true` if there is currently a drag operation in progress.
     */
    isDraggingRef: MutableRefObject<boolean>;
}) => {
    return (() => {
        isDraggingRef.current = false;

        if (
            dropCandidate &&
            !isDropNoOp({
                preview: dropCandidate.preview,
                nodeManager,
            })
        ) {
            dropCandidate.executionConfiguration.handler({
                history,
                actions,
            });
        }

        clearDropCandidate();
        setDraggedNodeId(null);
    }) satisfies DndContextProps['onDragEnd'];
};
