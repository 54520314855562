import { NORMALIZE_CSS } from '@/app/editor/blocks/constants';

import unescape from 'lodash/unescape';
import { useEffect, useRef, useState } from 'react';

import Box, { defaultBox } from '@/app/editor/blocks/components/_wrapper/Box';
import { sanitizeGoogleMapsSnippet } from '@/app/editor/blocks/components/Embed/helper';
import Placeholder from '@/app/editor/blocks/components/Embed/Placeholder';
import { providerValidatorMap } from '@/app/editor/blocks/components/Embed/validators';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';

import type { EmbedProps } from '@/app/editor/blocks/components/Embed/Component';

export const PredefinedEmbeds = ({
    box = defaultBox,
    provider,
    isDragged,
    head,
    body,
    artBoardIndex,
}: EmbedProps) => {
    const [embedFrame, setEmbedFrame] = useState<HTMLIFrameElement>();
    const wrapper = useRef<HTMLDivElement>();
    const embedBorderRadiusClass = useBorderRadius('embed');
    const validationResult = providerValidatorMap[provider](head, body);

    useEffect(() => {
        if (wrapper.current && wrapper.current.firstElementChild) {
            const iframeElement = wrapper.current.firstElementChild as HTMLIFrameElement;
            iframeElement.classList.remove(
                ...Array.from(iframeElement.classList).filter((className) =>
                    className.startsWith('rounded'),
                ),
            );
            iframeElement.classList.add(embedBorderRadiusClass);
        }
    }, [embedBorderRadiusClass]);

    useEffect(() => {
        if (!providerValidatorMap[provider](head, body)) {
            if (embedFrame) {
                embedFrame.remove();
            }

            setEmbedFrame(undefined);
        }

        const newFrame = document.createElement('iframe');
        newFrame.classList.add(
            'pointer-events-none',
            'w-full',
            'transition-all',
            embedBorderRadiusClass,
        );

        if (wrapper.current) {
            wrapper.current.innerHTML = '';
            wrapper.current.appendChild(newFrame);
        }

        setEmbedFrame(newFrame);
    }, [provider, head, body, artBoardIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!providerValidatorMap[provider](head, body) || !embedFrame) {
            return;
        }

        const { contentWindow: window } = embedFrame;

        if (!window) {
            return;
        }

        const headElem = window?.document?.getElementsByTagName('head')[0];
        const bodyElem = window?.document?.getElementsByTagName('body')[0];

        let rawHead = unescape(head);
        let rawBody = unescape(body);

        if (provider === 'google-maps') {
            rawBody = sanitizeGoogleMapsSnippet(rawBody);
        }

        const headNode = window.document
            .createRange()
            .createContextualFragment(
                `${NORMALIZE_CSS}${rawHead}<style>body > #content > a[href*="provenexpert.com"]{width:100%;display:inline-flex;justify-content:center;}body > #content > a[href*="provenexpert.com"] > img{max-width:100%}</style>`,
            );
        const bodyNode = window.document
            .createRange()
            .createContextualFragment(
                `<div id="content" style="width:100%;display:flex;justify-content:center;align-items:center">${rawBody}</div>`,
            );

        const observer = new ResizeObserver((entries) => {
            const content = entries[0].contentRect;
            const { height } = content;

            embedFrame.style.height = `${height}px`;
        });

        while (headElem.firstChild) {
            headElem.removeChild(headElem.lastChild);
        }

        while (headElem.firstChild) {
            headElem.removeChild(headElem.lastChild);
        }

        headElem.appendChild(headNode);
        bodyElem.appendChild(bodyNode);

        bodyElem.style.display = 'flex';
        bodyElem.style.justifyContent = 'center';
        bodyElem.style.alignItems = 'center';

        const contentElem = window.document.querySelector('#content');

        observer.observe(contentElem);
    }, [embedFrame]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!validationResult.valid) {
        return <Placeholder box={box} isDragged={isDragged} provider={provider} />;
    }

    return (
        <Box box={box} isDragged={isDragged} artBoardIndex={artBoardIndex}>
            <div ref={wrapper} />
        </Box>
    );
};
