import { ORIGIN_MAP } from '@/ui/constants';

import {
    useFloating,
    useInteractions,
    useHover,
    useFocus,
    useRole,
    useDismiss,
    FloatingPortal,
    offset,
    flip,
    shift,
    autoUpdate,
    safePolygon,
} from '@floating-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { cloneElement } from 'react';
import { useState, useMemo } from 'react';
import { mergeRefs } from 'react-merge-refs';

import { cn } from '@/utils/cn';

import HotkeyLabels from './HotkeyLabels';

import type { Placement } from '@floating-ui/core';
import type { ReactNode } from 'react';

export interface Props {
    content: ReactNode;
    placement?: Placement;
    children: JSX.Element;
    offsetValue?: number;
    delay?: number;
    disabled?: boolean;
    keepOpenOnHover?: boolean;
    className?: string;
    hotkeys?: string[];
    controlledIsOpen?: boolean;
    onControlledOpen?: (open: boolean) => void;
}

export const Tooltip = ({
    children,
    content,
    placement = 'top',
    offsetValue = 5,
    delay = 40,
    disabled = false,
    className,
    hotkeys,
    onControlledOpen,
    keepOpenOnHover = false,
    controlledIsOpen = false,
}: Props) => {
    const [isOpen, setIsOpen] = useState(controlledIsOpen);

    const handleOpen = (newOpenState: boolean) => {
        setIsOpen(newOpenState);
        onControlledOpen?.(newOpenState);
    };

    const {
        x,
        y,
        strategy,
        context,
        refs,
        placement: calculatedPlacement,
    } = useFloating({
        placement,
        open: isOpen,
        onOpenChange: handleOpen,
        middleware: [offset(offsetValue), flip(), shift({ padding: 8 })],
        whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, {
            restMs: delay,
            handleClose: keepOpenOnHover ? safePolygon() : null,
        }),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
    ]);

    // Preserve the consumer's ref
    const ref = useMemo(
        () => mergeRefs([refs.setReference, (children as any).ref]),
        [refs, children],
    );

    return (
        <>
            {cloneElement(children, getReferenceProps({ ref, ...children.props }))}

            <AnimatePresence>
                {!disabled && content && isOpen && (
                    <FloatingPortal>
                        <motion.div
                            className={cn(
                                'z-[99999] flex items-center rounded-md bg-gray-800 px-2 py-1.5 font-sans text-sm text-white',
                                ORIGIN_MAP[calculatedPlacement],
                                className ?? 'max-w-sm',
                            )}
                            // Floating UI props
                            ref={refs.setFloating}
                            {...getFloatingProps()}
                            style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
                            // Framer motion props
                            initial={{ opacity: 0, scale: 0.85 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.85 }}
                        >
                            <span className="whitespace-pre-line">{content}</span>
                            {!!hotkeys && <HotkeyLabels hotkeys={hotkeys} />}
                        </motion.div>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};

export default Tooltip;
