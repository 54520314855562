import { store } from '@/core/redux/store';

import { getAllChildrenBlocksRecursively } from '@/app/editor/commands/utils/getAllChildrenBlocksRecursively';
import { apiPost } from '@/core/api';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { getBlockIndexOnPage } from '../../blocks/models/blockOrder';
import { fetchPageBlocks, getBlockById } from '../../blocks/models/blocks';
import { deleteBlock } from '../../blocks/models/delete';
import { fetchSinglePage, getActivePageId, setActivePage } from '../../pages/models/pages';
import { insertActiveSection, insertColumnInLayout } from '../../sections/models/insert';

import type { BlockResource } from '../../blocks/types';
import type { PageResource } from '../../pages/types';
import type { Command } from '../types';
import type { ResponseData } from '@/core/api/types';

export class InsertSectionCommand implements Command {
    private previousActivePageId?: string;
    private newBlockId?: string;
    private blockIndex?: number;
    private block?: BlockResource;

    constructor(private isInsertColumn: boolean = false) {}

    private async updateView() {
        const state = store.getState();
        const activePageId = getActivePageId(state);
        const activeCampaignId = getCampaignIdFromRouter();

        if (activePageId !== this.previousActivePageId) {
            await store.dispatch(setActivePage(activeCampaignId, this.previousActivePageId));
        }

        const blockElement = document.getElementById(this.newBlockId);

        if (blockElement) {
            blockElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }

    public async execute() {
        const state = store.getState();

        this.previousActivePageId = getActivePageId(state);

        if (this.isInsertColumn) {
            this.newBlockId = await store.dispatch(insertColumnInLayout());
        } else {
            this.newBlockId = await store.dispatch(insertActiveSection());
        }

        return { success: true, canUndo: true };
    }

    public async undo() {
        await this.updateView();
        const state = store.getState();

        this.block = getBlockById(state, this.newBlockId);
        this.blockIndex = getBlockIndexOnPage(state, this.newBlockId);

        await store.dispatch(deleteBlock(this.newBlockId));

        return { success: true, canRedo: true };
    }

    public async redo() {
        await this.updateView();

        const state = store.getState();
        const activePageId = getActivePageId(state);
        const componentsToRestore = getAllChildrenBlocksRecursively(this.block);

        // Update block order on page
        const updatedPage = await apiPost<ResponseData<PageResource>>(
            `/pages/${activePageId}/components`,
            { data: { position: this.blockIndex, components: [componentsToRestore] } },
        );

        await Promise.all([
            store.dispatch(fetchSinglePage(updatedPage.data.data.id)),
            store.dispatch(fetchPageBlocks(updatedPage.data.data.id)),
        ]);

        await this.updateView();

        return { success: true, canUndo: true };
    }
}
