import { useCallback, useMemo } from 'react';

import { PerspectiveEditorEnginePreviewAwareNodeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';
import { PropsFromParentOverrideProvider } from '@/app/editor/engine/core/components/view/PropsFromParentOverrideProvider';
import { DraggableConfigurationHelper } from '@/app/editor/engine/core/utils/dragAndDrop/configuration';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps, PropsFromParent } from '@/app/editor/blocks/types';
import type { PerspectiveEditorEnginePreviewAwareNodeRendererProps } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';
import type { PerspectiveEditorEngineNodeData } from '@/app/editor/engine/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {}

const Component: FC<Props> = ({ additionalBlocks, blockId }) => {
    const mediaAnswerMediaBorderRadius = useBorderRadius('topWrapper');
    const mediaAnswerTextBorderRadius = useBorderRadius('bottomWrapper');

    const getMediaPropsFromParent = useCallback(
        (nodes) => ({
            wrapperClass: cn('overflow-hidden rounded-b-none', mediaAnswerMediaBorderRadius),
            themedBackgroundColor: 'formFieldBackgroundColor',
            formData: additionalBlocks?.[nodes[0]?.block.id],
        }),
        [additionalBlocks, mediaAnswerMediaBorderRadius],
    ) satisfies (nodes: PerspectiveEditorEngineNodeData[]) => PropsFromParent;

    const propsFromParent = useMemo(
        () => ({
            wrapperClass: cn(
                'h-full overflow-hidden rounded-t-none *:h-full',
                mediaAnswerTextBorderRadius,
            ),
            outerWrapperClass: 'flex-grow',
            blockContainerClass: 'h-full',
            blockWrapperClass: 'h-full',
            themedBackgroundColor: 'formFieldBackgroundColor',
            isLightnessDependent: true,
        }),
        [mediaAnswerTextBorderRadius],
    ) satisfies PropsFromParent;

    const renderer = useCallback(
        ({ nodes, renderNode }) => {
            return (
                <>
                    <PropsFromParentOverrideProvider
                        propsFromParent={getMediaPropsFromParent(nodes)}
                    >
                        {renderNode({
                            node: nodes[0],
                        })}
                    </PropsFromParentOverrideProvider>

                    <PropsFromParentOverrideProvider propsFromParent={propsFromParent}>
                        {renderNode({
                            node: nodes[1],
                        })}
                    </PropsFromParentOverrideProvider>
                </>
            );
        },
        [getMediaPropsFromParent, propsFromParent],
    ) satisfies PerspectiveEditorEnginePreviewAwareNodeRendererProps['renderer'];

    return (
        <div className="flex h-full flex-col">
            <PerspectiveEditorEnginePreviewAwareNodeRenderer
                draggableConfiguration={DraggableConfigurationHelper.alwaysDisable}
                parentId={blockId}
                renderer={renderer}
            />
        </div>
    );
};

Component.displayName = 'QuestionMediaAnswer';

export default Component;
