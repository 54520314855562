import { createContext } from 'react';

import type { PropsFromParent } from '@/app/editor/blocks/types';

/**
 * A context that holds props received from a parent node.
 */
export const propsFromParentContext = createContext<PropsFromParent>({});

/**
 * Provider for the `propsFromParentContext`.
 */
export const PropsFromParentProvider = propsFromParentContext.Provider;
