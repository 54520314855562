import type {
    EditorEngineHistoryDefaultEntry,
    EditorEngineHistoryEntryCollectionSetter,
    EditorEngineHistoryEntryRecordedStep,
    EditorEngineHistoryEntryRecordedStepResult,
} from '@/app/editor/engine/core/types';

interface Input<TExtraContext extends object> {
    /**
     * The entry to update.
     */
    entry: EditorEngineHistoryDefaultEntry;
    /**
     * The recorded step to update.
     */
    step: EditorEngineHistoryEntryRecordedStep<TExtraContext>;
    /**
     * The recorded step result to set.
     */
    result: EditorEngineHistoryEntryRecordedStepResult;
    /**
     * A function to set the entries information.
     */
    setEntriesInfo: EditorEngineHistoryEntryCollectionSetter;
}

/**
 * Update the recorded step result of an entry.
 *
 * Whenever some handler would like to update the result of the record of a
 * step, it can use this utility to do so.
 *
 * todo(editorengine): possibly optimize this function
 */
export const updateEntryStepRecordedResult = <TExtraContext extends object>({
    entry,
    step,
    result,
    setEntriesInfo,
}: Input<TExtraContext>) => {
    setEntriesInfo((oldEntriesInfo) => ({
        ...oldEntriesInfo,
        entries: oldEntriesInfo.entries.map((eachEntry) => {
            if (eachEntry.id !== entry.id) {
                return eachEntry;
            }

            return {
                ...eachEntry,
                recordedSteps: eachEntry.recordedSteps.map((eachStep) => {
                    if (eachStep.id !== step.id) {
                        return eachStep;
                    }

                    return {
                        ...eachStep,
                        executionResult: result,
                    };
                }),
            };
        }),
    }));
};
