import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_ARRAY } from '@/utils/empty';

import { NAME } from '../constants';

import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    undoStack: string[];
    redoStack: string[];
    isExecuting: boolean;
}

const initialState: State = {
    undoStack: EMPTY_ARRAY,
    redoStack: EMPTY_ARRAY,
    isExecuting: false,
};

export const historySlice = createSlice({
    name: `editor/${NAME}/history`,
    initialState,
    reducers: {
        setExecuting(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isExecuting: action.payload,
            };
        },
        addToUndoStack(state, action: PayloadAction<string>) {
            const newUndoStack = [...state.undoStack];

            newUndoStack.push(action.payload);

            return {
                ...state,
                undoStack: newUndoStack,
            };
        },
        popFromUndoStack(state) {
            const newUndoStack = [...state.undoStack];

            newUndoStack.pop();

            return {
                ...state,
                undoStack: newUndoStack,
            };
        },
        addToRedoStack(state, action: PayloadAction<string>) {
            const newRedoStack = [...state.redoStack];

            newRedoStack.push(action.payload);

            return {
                ...state,
                redoStack: newRedoStack,
            };
        },
        popFromRedoStack(state) {
            const newRedoStack = [...state.redoStack];

            newRedoStack.pop();

            return {
                ...state,
                redoStack: newRedoStack,
            };
        },
        clearRedoStack(state) {
            return {
                ...state,
                redoStack: [],
            };
        },
        clearStacks(state) {
            return {
                ...state,
                undoStack: [],
                redoStack: [],
            };
        },
    },
});

// === Actions ======

export const {
    addToUndoStack,
    addToRedoStack,
    popFromUndoStack,
    popFromRedoStack,
    clearStacks,
    clearRedoStack,
    setExecuting,
} = historySlice.actions;

// === Selectors ======

export const getUndoStack = (state: AppState) => state[NAME].historyReducer.undoStack;
export const getRedoStack = (state: AppState) => state[NAME].historyReducer.redoStack;
export const getIsExecuting = (state: AppState) => state[NAME].historyReducer.isExecuting;

export default historySlice.reducer;
