import { closestCorners, defaultDropAnimation, DndContext, DragOverlay } from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    rectSortingStrategy,
    rectSwappingStrategy,
    SortableContext,
} from '@dnd-kit/sortable';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import debounce from 'lodash/debounce';
import { Children, isValidElement } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { optimisticallyMoveBlock } from '@/app/editor/blocks/models/update';
import { BlockComponentType } from '@/app/editor/blocks/types';
import { MoveBlockCommand } from '@/app/editor/commands/commands/moveBlockCommand';
import { UpdateChildrenOrderCommand } from '@/app/editor/commands/commands/updateChildrenOrderCommand';
import getHistoryController from '@/app/editor/commands/utils/HistoryControllers';
import SortableBlock from '@/app/editor/editor/components/ArtBoard/SortableBlockList/SortableBlock';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { getThemeFont } from '@/app/editor/themes/helpers';
import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';
import { EMPTY_OBJECT } from '@/utils/empty';

import Block from '../../../../editor/components/ArtBoard/Block/Block.controller';

import type { RelationshipObject } from '@/core/api/types';
import type { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import type { ReactNode } from 'react';

export interface Props {
    children?: ReactNode;
    parentBlockId: string;
    sortingStrategy: 'vertical' | 'horizontal' | 'swap' | 'rect';
    nestedLevel: number;
    className?: string;
}

const strategies = {
    vertical: verticalListSortingStrategy,
    horizontal: horizontalListSortingStrategy,
    rect: rectSortingStrategy,
    swap: rectSwappingStrategy,
};

const SortableLayout = ({
    children,
    sortingStrategy,
    nestedLevel,
    className,
    parentBlockId,
}: Props) => {
    const dispatch = useAppDispatch();
    const historyController = getHistoryController();

    const [activeDragId, setActiveDragId] = useState(null);
    const artboardSize = useArtboardSize();

    // For moving between columns
    const [movedBlock, setMovedBlock] = useState(null);

    const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);
    const themeFont = getThemeFont(previewOrActiveTheme);
    const fontWeight = themeFont === 'Inter' ? '300' : undefined;

    const childBlocks = Children.toArray(children);
    const childBlockProps = useMemo(
        () =>
            childBlocks.map((child) => {
                if (isValidElement(child)) {
                    return child.props;
                }
            }),
        [childBlocks],
    );
    const childBlockIds: string[] = useMemo(
        () => childBlockProps.map((props) => props.blockId),
        [childBlockProps],
    );

    // Create object of { [blockId] : parentProps }
    const propsFromParent = useMemo(() => {
        let props = EMPTY_OBJECT;

        childBlocks.forEach((child) => {
            if (isValidElement(child)) {
                props = {
                    ...props,
                    [child.props.blockId]: child.props.propsFromParent,
                };
            }
        });

        return props;
    }, [childBlocks]);

    const dragOverlayClass = cn('group/artboard', {
        'is-mobile': artboardSize === ArtBoardSize.MOBILE,
        'is-tablet': artboardSize === ArtBoardSize.TABLET,
        'is-desktop': artboardSize === ArtBoardSize.DESKTOP,
    });

    const handleDragStart = ({ active }: DragStartEvent) => {
        if (!active) {
            return;
        }

        setActiveDragId(active.id);
    };

    // Dragging between Layout columns
    const handleDragOver = useCallback(
        ({ active, over }: DragOverEvent) => {
            if (!over) {
                return;
            }

            const activeId = active.id as string;
            const activeComponentType = active.data.current?.block.attributes.componentType;
            const overComponentType = over.data.current?.block.attributes.componentType;
            const activeBlockColumnId = active.data.current?.parentColumn?.id;
            const overBlockColumnId = over.data.current?.parentColumn?.id;
            const overBlockId = over.data.current?.block.id;
            const hasNoChildren =
                over.data.current?.block.relationships.components?.data.length === 0;

            // Dragging a block to an empty grid column
            if (
                activeComponentType !== BlockComponentType.GRID_COLUMN &&
                overComponentType === BlockComponentType.GRID_COLUMN &&
                hasNoChildren
            ) {
                dispatch(
                    optimisticallyMoveBlock({
                        blockId: activeId,
                        oldParentId: activeBlockColumnId,
                        newParentId: overBlockId,
                        newIndex: 0,
                    }),
                );

                // For handleDragEnd
                setMovedBlock({
                    blockId: activeId,
                    oldParentId: activeBlockColumnId,
                    newParentId: overBlockId,
                    newIndex: 0,
                });
            }

            // Dragging a block to another grid column
            if (
                activeComponentType !== BlockComponentType.GRID_COLUMN &&
                overComponentType !== BlockComponentType.GRID_COLUMN &&
                overComponentType !== BlockComponentType.GRID_ROW &&
                activeBlockColumnId !== overBlockColumnId
            ) {
                const newIndex = over.data.current.sortable.index;

                // Get block ids of over column
                const overColumnBlockIds: string[] =
                    over.data.current?.parentColumn.relationships.components.data.map(
                        (component: RelationshipObject) => component.id,
                    );

                // Check if block is already in column
                if (!overColumnBlockIds.includes(activeId as string)) {
                    dispatch(
                        optimisticallyMoveBlock({
                            blockId: activeId,
                            oldParentId: activeBlockColumnId,
                            newParentId: overBlockColumnId,
                            newIndex,
                        }),
                    );

                    // For handleDragEnd
                    // if new column is not the original column it's moving
                    if (overBlockColumnId !== movedBlock?.oldParentId) {
                        setMovedBlock({
                            blockId: activeId,
                            oldParentId: activeBlockColumnId,
                            newParentId: overBlockColumnId,
                            newIndex,
                        });
                    } else {
                        // else it's sorting in the same column
                        setMovedBlock(null);
                    }
                }
            }
        },
        [dispatch, movedBlock?.oldParentId],
    );

    // Debounce to prevent "max update depth exceeded"
    const debouncedDragOver = useMemo(() => debounce(handleDragOver, 100), [handleDragOver]);

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        if (active?.id !== over?.id && over?.id && !movedBlock) {
            const activeComponentType = active.data.current?.block.attributes.componentType;

            const activeColumn = active.data.current?.parentColumn;
            const overColumn = over.data.current?.parentColumn;
            const activeBlockColumnId = activeColumn?.id;
            const overBlockColumnId = overColumn?.id;

            const oldIndex = active.data.current.sortable.index;
            const newIndex = over.data.current.sortable.index;

            // Sorting columns
            if (activeComponentType === BlockComponentType.GRID_COLUMN) {
                // update column order
                const updatedBlockOrder = arrayMove(childBlockIds, oldIndex, newIndex);

                // Update in DB
                const updateChildOrderCommand = new UpdateChildrenOrderCommand(
                    parentBlockId, // gridRow
                    updatedBlockOrder,
                );
                historyController.executeCommand(updateChildOrderCommand);
            }

            // Sorting blocks in same column
            if (
                activeBlockColumnId === overBlockColumnId &&
                activeComponentType !== BlockComponentType.GRID_COLUMN
            ) {
                const columnBlockIds: string[] = activeColumn.relationships.components.data.map(
                    (component: RelationshipObject) => component.id,
                );

                // update block order
                const updatedBlockOrder = arrayMove(columnBlockIds, oldIndex, newIndex);

                // Update in DB
                const updateChildOrderCommand = new UpdateChildrenOrderCommand(
                    activeBlockColumnId, // gridColumn
                    updatedBlockOrder,
                );
                historyController.executeCommand(updateChildOrderCommand);
            }
        }

        // Move block to another column
        if (movedBlock && over?.id) {
            const newIndex = over.data.current.sortable.index;

            const moveBlockCommand = new MoveBlockCommand(
                movedBlock.blockId,
                movedBlock.oldParentId,
                movedBlock.newParentId,
                newIndex,
            );
            historyController.executeCommand(moveBlockCommand);

            setMovedBlock(null);
        }

        setActiveDragId(null);
    };

    const handleDragCancel = () => setActiveDragId(null);

    return (
        <DndContext
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragOver={debouncedDragOver}
            collisionDetection={closestCorners}
        >
            {/* Columns */}
            <SortableContext items={childBlockIds} strategy={strategies[sortingStrategy]}>
                <div className={className}>
                    {childBlockIds.map((blockId: string) => (
                        <SortableBlock
                            key={blockId}
                            blockId={blockId}
                            nestedLevel={nestedLevel + 1}
                            propsFromParent={propsFromParent[blockId]}
                        />
                    ))}
                </div>
            </SortableContext>

            {/* Dragging block */}
            {createPortal(
                <DragOverlay
                    className={dragOverlayClass}
                    dropAnimation={defaultDropAnimation}
                    style={{ fontFamily: themeFont, fontWeight }}
                >
                    {activeDragId ? (
                        <Block
                            blockId={activeDragId}
                            isDragged
                            propsFromParent={propsFromParent[activeDragId]}
                        />
                    ) : null}
                </DragOverlay>,
                document.body,
            )}
        </DndContext>
    );
};

export default SortableLayout;
