/**
 * A critical error that will prevent the Editor Engine from functioning
 * correctly.
 */
export class EditorEngineCriticalError extends Error {
    debug: Record<string, unknown>;

    constructor(message: string, debug: Record<string, unknown>) {
        super(message);
        this.debug = debug;
    }
}
