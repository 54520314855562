import { Children, cloneElement, isValidElement } from 'react';

import Box from '@/app/editor/blocks/components/_wrapper/Box';
import SortableLayout from '@/app/editor/blocks/components/_wrapper/SortableLayout/SortableLayout';
import { getBgImageStyles } from '@/app/editor/blocks/helpers/getBgImageStyles';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { cn } from '@/utils/cn';

import NewComponent from './NewComponent';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({ blockId, children, box, isDragged, artBoardIndex, isPreview, nestedLevel, activeTheme }) => {
        const columnCount = Children.count(children);
        const artboardSize = useArtboardSize();

        const childBlocks = Children.map(children, (child) => {
            return isValidElement<BlockProps>(child)
                ? cloneElement(child, {
                      propsFromParent: { blockWrapperClass: 'h-full' },
                  })
                : null;
        });

        const wrapperClass = cn('transition-all duration-200', {
            'px-4': artboardSize === ArtBoardSize.MOBILE,
            'px-6': artboardSize === ArtBoardSize.TABLET,
            'px-12': artboardSize === ArtBoardSize.DESKTOP,
        });

        const gridClass = cn('grid gap-4', {
            'grid-cols-1': columnCount === 1 || artboardSize === ArtBoardSize.MOBILE,
            'sm:grid-cols-2': columnCount >= 2 && artboardSize !== ArtBoardSize.MOBILE,
            'sm:grid-cols-3': columnCount === 3 && artboardSize === ArtBoardSize.DESKTOP,
            'sm:grid-cols-4': columnCount >= 4 && artboardSize === ArtBoardSize.DESKTOP,
        });

        const { backgroundColor, bgImage } = box || {};
        const { visible, src } = bgImage || {};
        const showBgImage = visible && src;

        return (
            <div
                className={cn({ relative: showBgImage })}
                style={{
                    background: getThemedOrCustomColor(
                        backgroundColor,
                        'backgroundColor',
                        activeTheme,
                    ),
                }}
            >
                {/* Background Image */}
                {showBgImage && (
                    <div
                        className="pointer-events-none absolute inset-0 bg-cover bg-no-repeat"
                        style={getBgImageStyles(bgImage)}
                    />
                )}

                {/* Content */}
                <div className={wrapperClass}>
                    <Box
                        box={box}
                        isDragged={isDragged}
                        artBoardIndex={artBoardIndex}
                        limiterClass="max-w-screen-2xl"
                        noBackground
                    >
                        {isPreview ? (
                            <div className={gridClass}>{childBlocks}</div>
                        ) : (
                            <SortableLayout
                                parentBlockId={blockId}
                                sortingStrategy="rect"
                                nestedLevel={nestedLevel}
                                className={gridClass}
                            >
                                {childBlocks}
                            </SortableLayout>
                        )}
                    </Box>
                </div>
            </div>
        );
    },
    NewComponent,
);

Component.displayName = 'GridRow';

export default Component;
