import { useEffect, useRef, useState } from 'react';

import type { EffectCallback } from 'react';

// @todo: useEffect?
export const useOnMounted = (effect: EffectCallback) => {
    const [mounted, setMounted] = useState(false);
    const effectRef = useRef(effect);

    useEffect(() => {
        setMounted(true);

        return effectRef.current?.();
    }, []);

    return mounted;
};

export const useIsMounted = () => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return mounted;
};
