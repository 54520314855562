import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';

import { InsertSectionCommand } from '@/app/editor/commands/commands/insertSectionCommand';
import getHistoryController from '@/app/editor/commands/utils/HistoryControllers';
import { setActiveView } from '@/app/editor/editor/models/sidebar';
import { EditorEngineNodeView } from '@/app/editor/engine/core/components/view/EditorEngineNodeView';
import { IsPreviewProvider } from '@/app/editor/engine/core/context/isPreviewContext';
import { normalizePreviewBlock } from '@/app/editor/engine/core/functions/utils/normalizePreviewBlock';
import { usePerspectiveEditorEngine } from '@/app/editor/engine/PerspectiveEditorEngine';
import { getActiveSectionId, getUsingSection, reset } from '@/app/editor/sections/models/insert';
import { memoizedGetPreviewsById } from '@/app/editor/sections/models/sections';
import { useAppSelector, useAppDispatch } from '@/core/redux/hooks';
import { ButtonLoadingSpinner } from '@/ui/components/ButtonLoadingSpinner';
import { cn } from '@/utils/cn';

import TemplateBlock from './TemplateBlock';

import type { MouseEvent } from 'react';

export interface Props {
    sectionPreviewIds: string[];
}

export const SectionPreview = ({ sectionPreviewIds }: Props) => {
    const {
        actions,
        Component,
        documentManager,
        isActive: isWithinEditorEngine,
    } = usePerspectiveEditorEngine();
    const dispatch = useAppDispatch();
    const historyController = getHistoryController();
    const loading = useAppSelector(getUsingSection);
    const activeSectionId = useAppSelector(getActiveSectionId);
    const previewBlocks = useAppSelector((state) =>
        memoizedGetPreviewsById(state, sectionPreviewIds),
    );

    const handleAccept = (event: MouseEvent) => {
        event.stopPropagation();

        if (isWithinEditorEngine) {
            actions.insertBlock.enqueue({});
        } else {
            const insertCommand = new InsertSectionCommand();

            void historyController.executeCommand(insertCommand);
        }
    };

    const handleReject = (event: MouseEvent) => {
        event.stopPropagation();

        // Reset
        dispatch(setActiveView('pages'));
        dispatch(reset());
    };

    const previewContent = isWithinEditorEngine ? (
        <>
            <IsPreviewProvider value={true}>
                <div className="pointer-events-none">
                    {sectionPreviewIds.map((sectionPreviewId, index) => (
                        <EditorEngineNodeView
                            key={sectionPreviewId}
                            id={sectionPreviewId}
                            node={{
                                block: normalizePreviewBlock(previewBlocks[index]),
                            }}
                            childIndex={0}
                            Component={Component}
                            document={documentManager.document}
                            isPreview={true}
                            dragContext={undefined}
                        />
                    ))}
                </div>
            </IsPreviewProvider>
        </>
    ) : (
        sectionPreviewIds.map((sectionPreviewId) => (
            <TemplateBlock
                key={sectionPreviewId}
                previewBlockId={sectionPreviewId}
                nestedLevel={0}
            />
        ))
    );

    return (
        <div className="relative z-10" id={activeSectionId}>
            <div
                className={cn(
                    'z-10 rounded-lg outline-dashed outline-2 outline-offset-2 outline-blue-500',
                    {
                        'animate-pulse': loading,
                    },
                )}
                onClick={handleAccept}
            >
                {previewContent}
            </div>

            <div className="absolute left-full top-0 flex h-full items-center pl-6">
                <div className="flex-col">
                    <button
                        className="flex size-10 items-center justify-center rounded-full border bg-white shadow-sm transition-shadow hover:shadow-md"
                        onClick={loading ? undefined : handleAccept}
                    >
                        {loading ? (
                            <ButtonLoadingSpinner className="text-blue-500" />
                        ) : (
                            <CheckIcon className="size-5 text-green-500" />
                        )}
                    </button>

                    <button
                        className="mt-4 flex size-10 items-center justify-center rounded-full border bg-white shadow-sm transition-shadow hover:shadow-md"
                        onClick={handleReject}
                    >
                        <XMarkIcon className="size-5 text-red-500" />
                    </button>
                </div>
            </div>
        </div>
    );
};
