import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { isProductionEnv } from '@/utils/environments';

import type { EditorEngineDebugProps } from '@/app/editor/engine/core/components/utils/EditorEngineDebug/EditorEngineDebug';

let DebugComponent: (props: EditorEngineDebugProps) => JSX.Element;

interface Props extends EditorEngineDebugProps {}

/**
 * Loads the debug component for the editor engine if the environment is not
 * production.
 */
export const EditorEngineDebugLoader = (props: Props) => {
    const [hasComponent, setHasComponent] = useState(false);

    useEffect(() => {
        if (!isProductionEnv()) {
            const load = async () => {
                const DebugComponentImport = await import('./EditorEngineDebug');
                DebugComponent = DebugComponentImport.EditorEngineDebug;
                setHasComponent(true);
            };

            void load();
        }
    }, []);

    const target = document.getElementById('ps-debug-portal');

    if (!DebugComponent || !hasComponent || !target) {
        return null;
    }

    return <>{createPortal(<DebugComponent {...props} />, target)}</>;
};
