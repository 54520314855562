import { useRef } from 'react';
import ContentEditable from 'react-contenteditable';

import { getCleanIconId } from '@/app/editor/blocks/helpers';
import { useContentEditablePlaceholder } from '@/app/editor/blocks/hooks/useContentEditablePlaceholder';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

export interface Props {
    showIcon: boolean;
    placeholder: string;
    icon: string;
    blockId: string;
    isActiveBlock: boolean;
}

const TextArea = ({ icon, showIcon, placeholder, blockId, isActiveBlock }: Props) => {
    const placeholderRef = useRef<HTMLInputElement>(null);
    const { updatedPlaceholder, handlePlaceholderChange, handlePlaceholderBlur, handleKeyDown } =
        useContentEditablePlaceholder({
            blockId,
            initialPlaceholder: placeholder,
            ref: placeholderRef,
        });
    const borderRadius = useBorderRadius('fieldsWrapper');

    const cleanIconId = getCleanIconId(icon);

    return (
        <div className={cn('flex h-32 items-start border bg-white p-4', borderRadius)}>
            {showIcon && (
                <div className="pr-4 text-zero">
                    {/* @ts-ignore */}
                    <em-emoji id={cleanIconId} size="20px" />
                </div>
            )}
            <div className="font-light text-gray-400">
                <ContentEditable
                    innerRef={placeholderRef}
                    html={updatedPlaceholder}
                    onChange={handlePlaceholderChange}
                    onBlur={handlePlaceholderBlur}
                    className="ring-none cursor-text outline-none"
                    onKeyDown={handleKeyDown}
                    disabled={!isActiveBlock}
                />
            </div>
        </div>
    );
};

export default TextArea;
