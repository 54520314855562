import Head from 'next/head';

const WorkspacesFavicon = () => {
    return (
        <Head>
            <link rel="apple-touch-icon" sizes="180x180" href="/favicon/ws-apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon/ws-favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon/ws-favicon-16x16.png" />
            <link rel="manifest" href="/favicon/ws-site.webmanifest" />
            <link rel="mask-icon" href="/favicon/ws-safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="apple-mobile-web-app-title" content="Workspaces" />
            <meta name="application-name" content="Workspaces" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
};

export default WorkspacesFavicon;
