import { AlwaysDisableDraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/configuration/alwaysDisableDraggableConfiguration';
import { broadenDraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/configuration/broadenDraggableConfiguration';
import { extendDraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/configuration/extendDraggableConfiguration';
import { restrictDraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/configuration/restrictDraggableConfiguration';

/**
 * A helper object that provides functions to modify draggable configurations.
 */
export const DraggableConfigurationHelper = {
    alwaysDisable: AlwaysDisableDraggableConfiguration,
    restrict: restrictDraggableConfiguration,
    broaden: broadenDraggableConfiguration,
    extend: extendDraggableConfiguration,
};
