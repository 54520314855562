import { change } from 'redux-form';

import { store } from '@/core/redux/store';

import { getIsTipTapData } from '@/app/editor/blocks/helpers';
import { setPreviousContent } from '@/app/editor/blocks/models/tiptap';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { getBlockById, setActiveBlock } from '../../blocks/models/blocks';
import { initDraftEditorState } from '../../blocks/models/draftEditorStates';
import { updateBlock } from '../../blocks/models/update';
import { getActivePageId, setActivePage } from '../../pages/models/pages';

import type { BlockResource } from '../../blocks/types';
import type { Command } from '../types';

export class UpdateBlockCommand implements Command {
    public id: string;
    private previousValue?: BlockResource;
    private pageId?: string;

    constructor(private block: BlockResource) {}

    private async updateView() {
        let state = store.getState();
        const activeCampaignId = getCampaignIdFromRouter();
        const activePageId = getActivePageId(state);

        if (activePageId !== this.pageId) {
            await store.dispatch(setActivePage(activeCampaignId, this.pageId));
        }

        await store.dispatch(setActiveBlock(this.block.id));

        const wysiwyg =
            this.previousValue?.attributes?.content?.wysiwyg ??
            this.previousValue?.attributes?.content?.misc?.wysiwyg;

        if (wysiwyg) {
            if (getIsTipTapData(wysiwyg)) {
                await store.dispatch(
                    setPreviousContent({ blockId: this.block.id, content: wysiwyg }),
                );
            } else {
                await store.dispatch(initDraftEditorState(this.block.id, wysiwyg));
            }
        }

        if (this.previousValue) {
            await store.dispatch(
                change(this.block.id, 'attributes', this.previousValue.attributes),
            );
        }

        const blockElement = document.getElementById(this.block.id);

        if (blockElement) {
            blockElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }

    public async execute() {
        const state = store.getState();

        this.previousValue = getBlockById(state, this.block.id);
        this.pageId = getActivePageId(state);

        await store.dispatch(updateBlock(this.block));

        return { success: true, canUndo: true };
    }

    public async undo() {
        await this.updateView();
        await store.dispatch(updateBlock(this.previousValue));

        this.previousValue = this.block;

        return { success: true, canRedo: true };
    }

    public async redo() {
        await this.updateView();

        return await this.execute();
    }
}
