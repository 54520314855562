import {
    ENDPOINTS,
    FIXED_FOLDER_IDS,
    MUTATION_KEYS,
    QUERY_KEYS,
    UPLOAD_RESET_TIMEOUT_MS,
} from '@/app/mediaLibrary/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';

import { getVideoDuration } from '@/app/mediaLibrary/helper';
import { apiPost } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { reportError } from '@/utils/sentry';

import type { MediaResource, MediaType } from '@/app/mediaLibrary/types';
import type { ResponseData } from '@/core/api/types';

const createMediaPayload = ({
    mediaId,
    workspaceId,
    folderId,
    fileName,
    type,
    duration,
}: {
    mediaId: string;
    workspaceId: string;
    folderId: string;
    fileName: string;
    type: MediaType;
    duration?: number;
}) => {
    const payload = {
        data: {
            type,
            id: mediaId,
            attributes: {
                filename: fileName,
                duration,
            },
            relationships: {
                workspace: {
                    data: {
                        type: 'workspace',
                        id: workspaceId,
                    },
                },
                folder: {
                    data: {
                        type: 'folder',
                        id: folderId,
                    },
                },
            },
        },
    };

    if (folderId === FIXED_FOLDER_IDS.all) {
        // remove folder from payload
        delete payload.data.relationships.folder;
    }

    return payload;
};

export const useUploadMedia = () => {
    const queryClient = useQueryClient();

    const [progress, setProgress] = useState(0);
    const [uploadedCount, setUploadedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const reset = () => {
        setProgress(0);
        setUploadedCount(0);
        setTotalCount(0);
    };

    const mutation = useMutation({
        mutationKey: MUTATION_KEYS.uploadMedia(),
        mutationFn: async ({
            folderId,
            files,
            workspaceId,
            type,
        }: {
            folderId: string;
            files: File[];
            workspaceId: string;
            type: MediaType;
            successCb: (uploadedMedia: MediaResource[]) => void;
        }) => {
            const totalFiles = files.length;
            let completedPercentage = Array(totalFiles).fill(0); // Track progress per file

            setTotalCount(totalFiles);
            const uploadedMedia: MediaResource[] = [];

            for (const [index, file] of files.entries()) {
                const fileName = file.name.toLowerCase();
                let duration: number;

                // 0. Optional: Get video duration and thumbnail from file
                if (type === 'video') {
                    duration = await getVideoDuration(file);
                }

                // 1. Get signed URL
                const signedUrlResponse = await apiPost<
                    ResponseData<{ putUrl: string; mediaId: string }>
                >(ENDPOINTS.POST.generateUploadUrl(), {
                    data: {
                        format: fileName.split('.').pop(),
                    },
                });

                const signedUrl = getDataFromResponse(signedUrlResponse)?.putUrl;
                const mediaId = getDataFromResponse(signedUrlResponse)?.mediaId;

                // 2. Upload to signed URL
                const uploadResponse = await axios.put(signedUrl, file, {
                    headers: { 'Content-Type': file.type },
                    onUploadProgress: (progressEvent) => {
                        // Update this file's progress
                        completedPercentage[index] = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        );

                        const overallPercent =
                            completedPercentage.reduce(
                                (progressA, progressB) => progressA + progressB,
                                0,
                            ) / totalFiles;

                        setProgress(Math.round(overallPercent * 1e2) / 1e2); // Set the overall progress
                    },
                });

                if (uploadResponse.status !== 200) {
                    throw new Error(
                        `Upload to signed URL failed with status ${uploadResponse.status}`,
                    );
                }

                // 3. Create media resource only after upload was successful
                const mediaResourceResponse = await apiPost<ResponseData<MediaResource>>(
                    ENDPOINTS.POST.createMediaResource(),
                    createMediaPayload({
                        mediaId,
                        workspaceId,
                        folderId,
                        duration,
                        fileName,
                        type,
                    }),
                );

                setUploadedCount(index + 1);

                const media = getDataFromResponse(mediaResourceResponse);

                uploadedMedia.push(media);
            }

            return uploadedMedia;
        },
        onSuccess: async (data, variables) => {
            const { folderId, successCb } = variables;

            if (successCb) {
                successCb(data);
            }

            await queryClient.invalidateQueries({
                queryKey: QUERY_KEYS.folderMedia(folderId),
            });

            // Also invalidate "All images"
            if (folderId !== FIXED_FOLDER_IDS.all) {
                await queryClient.invalidateQueries({
                    queryKey: QUERY_KEYS.folderMedia(FIXED_FOLDER_IDS.all),
                });
            }

            setTimeout(() => {
                reset();
            }, UPLOAD_RESET_TIMEOUT_MS);
        },
        onError: (err, variables) => {
            reportError({
                error: err,
                source: 'media-library',
                debug: variables,
            });

            reset();
        },
    });

    return { ...mutation, progress, totalCount, uploadedCount };
};
