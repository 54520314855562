export interface Props {
    className?: string;
}

export const UnsplashLogo = ({ className }: Props) => {
    return (
        <div className={className}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.375 3.9375V0H9.625V3.9375H4.375ZM9.625 6.125H14V14H0V6.125H4.375V10.0625H9.625V6.125Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};
