import { Children, cloneElement, isValidElement } from 'react';

import NewComponent from '@/app/editor/blocks/components/List/NewComponent';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';
import SortableList from '../_wrapper/SortableList';

import type {
    Alignment,
    DefaultBlockComponentProps,
    GlobalTextSize,
    ListItemVisualSize,
} from '@/app/editor/blocks/types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { BorderRadius } from '@/app/editor/themes/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
    borderRadius: BorderRadius;
    textSize: GlobalTextSize;
    visualSize: ListItemVisualSize;
}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({
        artBoardIndex,
        children,
        box,
        blockId,
        nestedLevel,
        align,
        isPreview,
        borderRadius,
        textSize,
        visualSize,
        isDragged,
    }) => {
        const { isInColumn, isVerticalOnly } = useLayoutInfo(blockId, isPreview);

        const nrOfChildren = Children.count(children);
        const wrapperClass = isVerticalOnly
            ? 'flex flex-col gap-4'
            : cn('grid gap-6', {
                  'grid-cols-1': nrOfChildren < 2,
                  'grid-cols-2': nrOfChildren >= 2,
              });

        const childBlocks = Children.map(children, (child) =>
            isValidElement<BlockProps>(child)
                ? cloneElement(child, {
                      propsFromParent: {
                          align,
                          borderRadius,
                          visualSize,
                          globalSize: textSize ?? 'small',
                      },
                  })
                : null,
        );

        return (
            <Box
                box={getBoxAttributes({ box, isInColumn })}
                isDragged={isDragged}
                artBoardIndex={artBoardIndex}
            >
                {isPreview ? (
                    // No sorting required for preview
                    <div className={wrapperClass}>{childBlocks}</div>
                ) : (
                    <SortableList
                        parentBlockId={blockId}
                        sortingStrategy={isVerticalOnly ? 'vertical' : 'rect'}
                        nestedLevel={nestedLevel}
                        className={wrapperClass}
                    >
                        {childBlocks}
                    </SortableList>
                )}
            </Box>
        );
    },
    NewComponent,
);

Component.displayName = 'List';

export default Component;
