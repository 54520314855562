import { createSlice } from '@reduxjs/toolkit';

import { fetchCampaign } from '@/app/campaigns/models/campaigns';
import {
    getOtherPagesByCampaignId,
    getResultPagesByCampaignId,
    setOtherPages,
    setResultPages,
} from '@/app/editor/pages/models/pageMapping';
import { getActivePageId, setActivePage } from '@/app/editor/pages/models/pages';
import { apiDelete, handleRuntimeError } from '@/core/api';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';
import { removeValueFromArrayOnce } from '@/utils/mutation';

import { NAME } from '../constants';

import type { PageResource } from '@/app/editor/pages/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const deletePageSlice = createSlice({
    name: `editor/${NAME}/delete`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = deletePageSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.deletePageReducer?.loading;

// === Thunks ======

// Optimistic helper
const optimisticDeletePage =
    (page: PageResource): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const activePageId = getActivePageId(state);
        const campaignId = getCampaignIdFromRouter();
        const resultPages = getResultPagesByCampaignId(state, campaignId);
        const otherPages = getOtherPagesByCampaignId(state, campaignId);

        // remove id from page mapping
        if (page.attributes.isResult) {
            const updatedResultPages = removeValueFromArrayOnce(resultPages, page.id);

            // if current page is deleted -> set new active page
            if (page.id === activePageId) {
                dispatch(setActivePage(campaignId, otherPages[0]));
            }

            dispatch(setResultPages({ campaignId, pageIds: updatedResultPages }));
        } else {
            const updatedOtherPages = removeValueFromArrayOnce(otherPages, page.id);

            // if current page is deleted -> set new active page
            if (page.id === activePageId) {
                dispatch(setActivePage(campaignId, updatedOtherPages[0]));
            }

            dispatch(setOtherPages({ campaignId, pageIds: updatedOtherPages }));
        }
    };

export const deletePage =
    (page: PageResource): AppThunk =>
    async (dispatch) => {
        const campaignId = page?.relationships?.campaign?.data?.id;

        dispatch(setLoading(true));

        try {
            // optimistic delete
            dispatch(optimisticDeletePage(page));

            // delete page in DB
            await apiDelete(`/pages/${page.id}`);

            // fetch updated campaign + mapping
            await dispatch(fetchCampaign(campaignId));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'deleting page failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default deletePageSlice.reducer;
