import { NAME } from '@/app/billing/constants';

import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    hasDunning: boolean;
    initialized: boolean;
}

const initialState: State = {
    hasDunning: false,
    initialized: false,
};

export const dunningSlice = createSlice({
    name: `${NAME}/dunning`,
    initialState,
    reducers: {
        setInitialized(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                initialized: action.payload,
            };
        },
        setHasDunning(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                hasDunning: action.payload,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======

export const { reset, setHasDunning, setInitialized } = dunningSlice.actions;

// === Selectors ======

export const getDunningInitialized = (state: AppState) => state[NAME]?.dunning?.initialized;

export const getDunning = (state: AppState) => state[NAME]?.dunning?.hasDunning;

// === Thunks =====

export const fetchDunning = (): AppThunk<Promise<boolean>> => async (dispatch) => {
    try {
        const response = await apiGet<ResponseData<boolean>>('/subscriptions/has-dunning');
        const hasDunning = getDataFromResponse(response);

        dispatch(setHasDunning(hasDunning));
        dispatch(setInitialized(true));

        return hasDunning;
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'fetching dunning failed:' });
    }
};

export const validateDunning = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const dunningInitialized = getDunningInitialized(state);
    const hadDunning = getDunning(state);

    const hasDunning = await dispatch(fetchDunning());

    if (!dunningInitialized && hasDunning) {
        dispatch(showModal(Modals.DUNNING, {}));
    } else if (dunningInitialized && hasDunning) {
        showToast({
            type: 'warning',
            message: 'please-update-payment-method',
            actionText: 'check-payment-method',
            onActionClick: () => {
                toast.dismiss();
                dispatch(showModal(Modals.DUNNING, {}));
            },
            fixed: true,
        });
    } else if (dunningInitialized && hadDunning && !hasDunning) {
        showToast({
            type: 'success',
            message: `${NAME}:payment-method-updated`,
        });
    }

    return hasDunning;
};

export default dunningSlice.reducer;
