import type { State } from './index';
import type { StateCreator } from 'zustand';

export interface VirtualIdSlice {
    virtualIdMap: Map<string, string>;
    mapVirtualId: (virtualId: string, concreteId: string) => void;
}

export const createVirtualIdSlice: StateCreator<
    State,
    [['zustand/devtools', never]],
    [],
    VirtualIdSlice
> = (set) => ({
    virtualIdMap: new Map(),
    mapVirtualId: (virtualId, concreteId) => {
        set((state) => {
            const virtualIdMap = new Map(state.virtualIdMap);
            virtualIdMap.set(virtualId, concreteId);

            return {
                virtualIdMap,
            };
        });
    },
});
