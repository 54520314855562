import { getComponentBorderRadiusConfiguration } from '@/app/editor/themes/helpers';
import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppSelector } from '@/core/redux/hooks';

// Types
type UtilityParameters = Parameters<typeof getComponentBorderRadiusConfiguration>;

export const useBorderRadius = (
    componentType: UtilityParameters[0],
    themeSetting: UtilityParameters[1] = undefined,
) => {
    const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);
    const activeThemeSetting = previewOrActiveTheme?.attributes?.borderRadius;

    return getComponentBorderRadiusConfiguration(
        componentType,
        themeSetting ? themeSetting : activeThemeSetting,
    );
};
