import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    failed: boolean;
    success: boolean;
}

const initialState: State = {
    loading: false,
    failed: false,
    success: false,
};

// Helper Axios instance without interceptors (e.g. 401 -> redirect to login)
const uninterceptedApi = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_URL });

export const emailVerificationSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setFailed(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                failed: action.payload,
            };
        },
        setSuccess(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                success: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, setFailed, setSuccess, reset } = emailVerificationSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.emailVerificationReducer?.loading;
export const getSuccess = (state: AppState) => state[NAME]?.emailVerificationReducer?.success;
export const getFailed = (state: AppState) => state[NAME]?.emailVerificationReducer?.failed;

// === Thunks ======

export const verifyEmail =
    (eventId: string, token: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoading(true));
        dispatch(setSuccess(false));
        dispatch(setFailed(false));

        try {
            const response = await uninterceptedApi.get(`/events/emailVerification/${eventId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const email = getDataFromResponse(response)?.attributes?.email;
            const status = getDataFromResponse(response)?.attributes?.status;

            if (status && status === 'pending') {
                await uninterceptedApi.post(
                    `/events/emailVerification/${eventId}/use`,
                    { data: { email } },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
            } else {
                throw new Error('unknown');
            }

            dispatch(setSuccess(true));
        } catch (err) {
            dispatch(setFailed(true));
            handleRuntimeError(err, { debugMessage: 'email verification failed:', silent: true });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default emailVerificationSlice.reducer;
