import { reportError } from '@/utils/sentry';

import { LS_CRM_FILTERS, LS_CRM_FILTERS_OPENED } from '../constants';

import type { CRMFilter } from '../types';

const getCRMFiltersFromLS = () => {
    const storedValue = localStorage.getItem(LS_CRM_FILTERS);

    try {
        if (storedValue) {
            return JSON.parse(storedValue);
        }

        return {};
    } catch (error) {
        reportError({
            error,
            source: 'runtime',
        });

        return {};
    }
};

export const getCampaignCRMFiltersFromLS = (campaignId: string) => {
    const crmFilters = getCRMFiltersFromLS();

    return crmFilters[campaignId] ?? [];
};

export const updateCampaignCRMFiltersOnLS = (campaignId: string, filters: CRMFilter[]) => {
    const crmFilters = getCRMFiltersFromLS();

    crmFilters[campaignId] = filters;

    localStorage.setItem(LS_CRM_FILTERS, JSON.stringify(crmFilters));
};

const getCRMFiltersOpenedFromLS = () => {
    const storedValue = localStorage.getItem(LS_CRM_FILTERS_OPENED);

    try {
        if (storedValue) {
            return JSON.parse(storedValue);
        }

        return {};
    } catch (error) {
        reportError({
            error,
            source: 'runtime',
        });

        return {};
    }
};

export const getCampaignCRMFiltersOpenedFromLS = (campaignId: string) => {
    const storedValue = getCRMFiltersOpenedFromLS();

    return storedValue[campaignId] ?? true;
};

export const updateCampaignCRMFiltersOpenedFromLS = (campaignId: string, isOpen: boolean) => {
    const crmFiltersOpened = getCRMFiltersOpenedFromLS();

    crmFiltersOpened[campaignId] = isOpen;

    localStorage.setItem(LS_CRM_FILTERS_OPENED, JSON.stringify(crmFiltersOpened));
};
