import { NAME } from '@/app/editor/blocks/constants';

import { CodeBracketIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';
import { getFormValues, reduxForm, Field } from 'redux-form';

import { useCustomHtmlBlockFlags } from '@/app/company/hooks/useCustomHtmlBlockFlags';
import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { IconToggleButton } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/IconToggleButton';
import LabeledToggleButton from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/LabeledToggleButton';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import Tooltip from '@/ui/components/Tooltip';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({
    blockId,
    handleSubmit,
    activeTheme,
    initialValues,
}) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const formValues = useAppSelector((state) => getFormValues(blockId)(state)) as BlockResource;
    const { isInColumn } = useLayoutInfo(blockId);
    const provider = initialValues.attributes.content.provider;
    const { canEnableNativeEmbedding } = useCustomHtmlBlockFlags();
    const isEnforceCenteredCustomHtmlContentEnabled =
        formValues.attributes?.content?.enforceCenteredCustomHtmlContent;

    const editCodeSnippet = () =>
        dispatch(
            showModal(Modals.PASTE_EMBED_SNIPPET, {
                blockId,
                provider: formValues.attributes.content.provider,
            }),
        );

    return (
        <>
            <div className="grid grid-cols-3 gap-x-2">
                <FieldContainer>
                    <Tooltip content={t('edit-embed-code')}>
                        <button
                            className="flex h-12 w-full items-center justify-center rounded-lg bg-gray-100 text-gray-400 hover:text-gray-600"
                            onClick={editCodeSnippet}
                        >
                            <CodeBracketIcon className="size-4" />
                        </button>
                    </Tooltip>
                </FieldContainer>

                {provider === 'custom-html' && (
                    <Field
                        name="attributes.content.enforceCenteredCustomHtmlContent"
                        component={IconToggleButton}
                        submit={handleSubmit}
                        icon="centerCustomHtmlContent"
                        tooltip={
                            isEnforceCenteredCustomHtmlContentEnabled
                                ? t('deactivate-enforce-center-html-embed-block-content')
                                : t('activate-enforce-center-html-embed-block-content')
                        }
                    />
                )}
            </div>

            {provider === 'custom-html' && canEnableNativeEmbedding && (
                <Field
                    name="attributes.content.embedCustomHtmlNatively"
                    initialValue={!!initialValues?.attributes?.content?.embedCustomHtmlNatively}
                    component={LabeledToggleButton}
                    text={t('custom-html-embed-block-native-embed')}
                    submit={handleSubmit}
                />
            )}

            <BoxSettings
                blockId={blockId}
                activeTheme={activeTheme}
                handleSubmit={handleSubmit}
                hiddenColor={isInColumn}
            />
        </>
    );
};

Form.displayName = 'EmbedEditForm';

export default reduxForm({})(Form);
