import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { fetchIcons } from '@/app/editor/iconLibrary/models/icons';
import { apiGet, handleRuntimeError } from '@/core/api';
import { EMPTY_ARRAY } from '@/utils/empty';

import { NAME, DEFAULT_PLATFORM } from '../constants';

import type { Platform } from '@/app/editor/iconLibrary/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    fetching: boolean;
    platforms: Platform[];
    currentPlatform: {
        id: string;
        isColor: boolean;
    };
}

const initialState: State = {
    fetching: false,
    platforms: EMPTY_ARRAY,
    currentPlatform: {
        id: '',
        isColor: false,
    },
};

export const platformsSlice = createSlice({
    name: `editor/${NAME}/platforms`,
    initialState,
    reducers: {
        setFetching(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                fetching: action.payload,
            };
        },
        setPlatforms(state, action: PayloadAction<Platform[]>) {
            return {
                ...state,
                platforms: action.payload,
            };
        },
        setCurrentPlatform(state, action: PayloadAction<{ id: string; isColor: boolean }>) {
            return {
                ...state,
                currentPlatform: {
                    id: action.payload.id,
                    isColor: action.payload.isColor,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setFetching, setPlatforms, setCurrentPlatform, reset } = platformsSlice.actions;

// === Selectors ======

export const getFetching = (state: AppState) => state[NAME]?.platformsReducer?.fetching;

export const getPlatforms = (state: AppState) => state[NAME]?.platformsReducer?.platforms;

export const getCurrentPlatform = (state: AppState) =>
    state[NAME]?.platformsReducer?.currentPlatform;

// === Thunks ======

export const fetchPlatforms = (initialPlatform = ''): AppThunk => {
    return async (dispatch) => {
        dispatch(setFetching(true));

        try {
            const response = await apiGet('/components/icon-platforms');

            const platforms = get(response, 'data.data', EMPTY_ARRAY).map((platform) => {
                return {
                    value: platform.attributes.apiCode,
                    key: platform.attributes.title,
                    isColor: platform.attributes.isColor,
                };
            });

            // if Icon already has a platform, select it
            let platformToSelect = {
                value: DEFAULT_PLATFORM,
                isColor: false,
            };

            if (initialPlatform) {
                const foundPlatform = platforms.find(
                    (platform) => platform.value === initialPlatform,
                );
                platformToSelect = foundPlatform ? foundPlatform : platformToSelect;
            }

            await dispatch(setPlatforms(platforms));
            await dispatch(
                setCurrentPlatform({
                    id: platformToSelect.value,
                    isColor: platformToSelect.isColor,
                }),
            );

            // Fetch Icons for platform
            dispatch(fetchIcons());
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching platforms failed:' });
        } finally {
            dispatch(setFetching(false));
        }
    };
};

export default platformsSlice.reducer;
