import * as RSlider from '@radix-ui/react-slider';

import { Tooltip } from '@/ui/components/Tooltip';

import type { ReactNode, SVGProps } from 'react';

interface Props {
    tooltip?: string;
    defaultValue: number;
    value: number;
    onChange: (value: number[]) => void;
    onCommit: (value: number[]) => void;
    min?: number;
    max?: number;
    step?: number;
    Icon?: (props: SVGProps<SVGSVGElement>) => ReactNode;
}

export const Slider = ({
    tooltip,
    defaultValue,
    value,
    onChange,
    onCommit,
    min,
    max,
    step,
    Icon,
}: Props) => {
    return (
        <Tooltip content={tooltip} disabled={!tooltip}>
            <div className="flex items-center rounded-lg bg-gray-100 px-4 text-gray-400 hover:text-gray-600">
                {Icon && (
                    <div className="mr-4 flex-shrink-0">
                        <Icon className="size-5" aria-hidden="true" />
                    </div>
                )}
                <RSlider.Root
                    className="relative flex h-12 w-full cursor-pointer items-center"
                    defaultValue={[defaultValue]}
                    value={[value]}
                    onValueChange={onChange}
                    onValueCommit={onCommit}
                    min={min}
                    max={max}
                    step={step}
                >
                    <RSlider.Track className="relative h-0.5 grow rounded-full bg-gray-300">
                        <RSlider.Range className="absolute h-full rounded-full bg-gray-300" />
                    </RSlider.Track>
                    <RSlider.Thumb className="block size-3 cursor-pointer rounded-full bg-gray-400 hover:bg-gray-600 focus:outline-none" />
                </RSlider.Root>
            </div>
        </Tooltip>
    );
};
