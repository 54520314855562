import Image from 'next/image';

import { getHasAnimation } from '@/app/editor/blocks/components/Logos/helper';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps } from '../../types';

interface LogosProps {
    images: string[];
    isGrayscale: boolean;
}

export interface ComponentProps extends DefaultBlockComponentProps, LogosProps {}

const LOGO_WIDTH = 160;
const ANIMATION_SPEED = 0.3; // logos/s

const LogoList = ({ images, isGrayscale }: LogosProps) => {
    return images.map((image, index) => (
        <div
            key={index}
            className="flex h-6 items-center justify-center px-6"
            style={{ width: `${LOGO_WIDTH}px` }}
        >
            <div className="relative size-full">
                <Image
                    src={image}
                    alt={`Logo #${index}`}
                    className={cn('object-contain', { grayscale: isGrayscale })}
                    fill
                />
            </div>
        </div>
    ));
};

const Component = ({ artBoardIndex, blockId, box, images, isGrayscale }: ComponentProps) => {
    const { isInColumn, isMobile, columnCount } = useLayoutInfo(blockId);

    const hasAnimation = getHasAnimation(images?.length, isMobile, columnCount);
    const animationDuration = hasAnimation ? images?.length / ANIMATION_SPEED : 0;

    return (
        <Box
            box={{
                ...getBoxAttributes({ box, isInColumn }),
            }}
            artBoardIndex={artBoardIndex}
        >
            <div
                className="relative w-full overflow-hidden"
                style={{
                    maskImage: hasAnimation
                        ? 'linear-gradient(90deg, transparent 5%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, transparent 95%)'
                        : undefined,
                }}
            >
                {/* Carousel track */}
                <div
                    className={cn('flex items-center justify-center', {
                        'animate-slide-loop': hasAnimation,
                        'opacity-75': isGrayscale,
                    })}
                    style={{
                        width: hasAnimation ? `${images?.length * LOGO_WIDTH * 2}px` : undefined,
                        animationDuration: hasAnimation ? `${animationDuration}s` : undefined,
                    }}
                >
                    <LogoList images={images} isGrayscale={isGrayscale} />

                    {/* Second list to create endless loop */}
                    {hasAnimation && <LogoList images={images} isGrayscale={isGrayscale} />}
                </div>
            </div>
        </Box>
    );
};

Component.displayName = 'Logos';

export default Component;
