import Component from './Component';
import config from './config';
import { PaymentFormForm } from './Form';

import type { BlockBundle } from '../../types';

const block: BlockBundle = {
    component: Component,
    form: PaymentFormForm,
    config,
};

export default block;
