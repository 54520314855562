import { FEATURE_IDS } from '@/app/billing/constants';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useRef } from 'react';
import ContentEditable from 'react-contenteditable';

import { useFeatureAvailability } from '@/app/company/hooks/useFeatureAvailability';
import { getCleanIconId } from '@/app/editor/blocks/helpers';
import { useContentEditablePlaceholder } from '@/app/editor/blocks/hooks/useContentEditablePlaceholder';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { PhoneValidationKind } from '@/app/editor/types';
import FlagEmoji from '@/ui/components/FlagEmoji/FlagEmoji';
import { cn } from '@/utils/cn';
import { getPhoneCode } from '@/utils/countries';

import type { CustomInputBlockOptions, HtmlInputType } from '@/app/editor/blocks/types';

export interface Props {
    showIcon: boolean;
    placeholder: string;
    icon: string;
    blockId: string;
    isActiveBlock: boolean;
    inputType?: HtmlInputType;
    customOptions?: CustomInputBlockOptions;
    inputClassName?: string;
}

const TextInput = ({
    icon,
    showIcon,
    placeholder,
    blockId,
    isActiveBlock,
    inputType = 'text',
    customOptions = {},
    inputClassName,
}: Props) => {
    const placeholderRef = useRef<HTMLInputElement>(null);
    const { updatedPlaceholder, handlePlaceholderChange, handlePlaceholderBlur, handleKeyDown } =
        useContentEditablePlaceholder({
            blockId,
            initialPlaceholder: placeholder,
            ref: placeholderRef,
        });

    const borderRadius = useBorderRadius('fieldsWrapper');
    const hasPhoneWithFormatValidation = useFeatureAvailability(FEATURE_IDS.phoneFormatValidation);
    const isPhoneWithFormatValidation =
        inputType === 'phone' &&
        hasPhoneWithFormatValidation &&
        customOptions?.phoneValidationKind === PhoneValidationKind.CountryFormat;
    const defaultCountry = customOptions?.defaultCountry || 'DE';
    const cleanIconId = getCleanIconId(icon);
    const showPrefixImage = showIcon || isPhoneWithFormatValidation;

    return (
        <div className={cn('flex h-14 items-center border bg-white px-4', borderRadius)}>
            {showPrefixImage && (
                <>
                    {isPhoneWithFormatValidation ? (
                        <div className="flex h-5 w-9 min-w-0 flex-shrink-0 items-center">
                            <FlagEmoji code={defaultCountry} />
                            <ChevronDownIcon className="w-3" />
                        </div>
                    ) : (
                        <div className="flex size-5 flex-shrink-0 items-center text-zero leading-[initial]">
                            {/* @ts-ignore */}
                            <em-emoji id={cleanIconId} size="20px" />
                        </div>
                    )}
                </>
            )}
            <div
                className={cn('min-w-full pr-4 font-light text-gray-400', inputClassName, {
                    'pl-4': showPrefixImage && !isPhoneWithFormatValidation,
                    'text-gray-800': isPhoneWithFormatValidation,
                })}
            >
                {isPhoneWithFormatValidation ? (
                    getPhoneCode(defaultCountry)
                ) : (
                    <ContentEditable
                        innerRef={placeholderRef}
                        html={updatedPlaceholder}
                        onChange={handlePlaceholderChange}
                        onBlur={handlePlaceholderBlur}
                        className="ring-none max-w-full cursor-text overflow-hidden text-nowrap outline-none"
                        onKeyDown={handleKeyDown}
                        disabled={!isActiveBlock}
                    />
                )}
            </div>
        </div>
    );
};

export default TextInput;
