import { reset as resetArtBoard } from './artboard';
import { reset as resetSidebar } from './sidebar';
import resetBlocks from '../../blocks/models/reset';
import resetIconLibrary from '../../iconLibrary/models/reset';
import resetPages from '../../pages/models/reset';
import resetSections from '../../sections/models/reset';
import resetThemes from '../../themes/models/reset';

import type { AppThunk } from '@/core/redux/types';

// Happens on logout.ts _and_ when the Editor unmounts
export const resetEditorState =
    ({ withThemes = true, withBlockCopyPasteClipboard = true, withPages = true }): AppThunk =>
    (dispatch) => {
        dispatch(resetBlocks(withBlockCopyPasteClipboard));
        dispatch(resetPages(withPages));
        dispatch(resetSections());
        dispatch(resetArtBoard());
        dispatch(resetSidebar());
        dispatch(resetIconLibrary());

        // When unmounting the editor you want to keep the stuff that's the same across all funnel
        dispatch(resetThemes(withThemes));
    };
