import { store } from '@/core/redux/store';

import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { getBlockById, setActiveBlock } from '../../blocks/models/blocks';
import { updateChildOrder } from '../../blocks/models/update';
import { getActivePageId, setActivePage } from '../../pages/models/pages';

import type { Command } from '../types';

export class UpdateChildrenOrderCommand implements Command {
    private previousChildrenOrder?: string[];
    private previousActiveBlockId?: any;
    private pageId: string;

    constructor(
        private parentBlockId: string,
        private reorderedBlockIds: string[],
    ) {}

    private async updateView() {
        const state = store.getState();
        const activePageId = getActivePageId(state);
        const activeCampaignId = getCampaignIdFromRouter();

        if (activePageId !== this.pageId) {
            await store.dispatch(setActivePage(activeCampaignId, this.pageId));
        }

        await store.dispatch(setActiveBlock(this.parentBlockId));

        const blockElement = document.getElementById(this.previousActiveBlockId);

        if (blockElement) {
            blockElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }

    public async execute() {
        const state = store.getState();

        const parentBlock = getBlockById(state, this.parentBlockId);

        this.previousChildrenOrder = parentBlock.relationships.components.data.map(
            (block) => block.id,
        );
        this.pageId = getActivePageId(state);

        await store.dispatch(updateChildOrder(this.parentBlockId, this.reorderedBlockIds));

        return { success: true, canUndo: true };
    }

    public async undo() {
        await store.dispatch(updateChildOrder(this.parentBlockId, this.previousChildrenOrder));
        await this.updateView();

        return { success: true, canRedo: true };
    }

    public async redo() {
        const result = await this.execute();
        await this.updateView();

        return result;
    }
}
