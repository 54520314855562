import dynamic from 'next/dynamic';

import type { MappedMedia, MediaType } from '@/app/mediaLibrary/types';
import type { FieldInputProps } from 'react-final-form';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps | FieldInputProps<string>;
    mediaType: MediaType;
    onMediaSelect?: (media: MappedMedia) => void;
    submit?: () => void;
}

const SidebarMediaLibrary = dynamic(
    () => import('@/app/mediaLibrary/components/SidebarMediaLibrary'),
);

export const MediaSrc = ({ input, mediaType = 'image', submit, onMediaSelect }: Props) => {
    const { value, onChange } = input;

    const handleChange = (media: MappedMedia) => {
        onChange(media.url);

        if (onMediaSelect) {
            onMediaSelect(media);
        }

        if (submit) {
            setTimeout(submit);
        }
    };

    return <SidebarMediaLibrary currentSrc={value} onChange={handleChange} mediaType={mediaType} />;
};
