import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { getEditorUrl } from '@/app/campaigns/helpers';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { getCompanyId } from '../../company/models/company';
import { hideModal } from '../../modals/models/modals';
import { getActiveWorkspaceId, getDefaultWorkspace } from '../../workspaces/models/workspaces';
import { NAME } from '../constants';

import type { CampaignResource } from '@/app/campaigns/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    showFunnelIntro: { [campaignId: string]: boolean };
}

const initialState: State = {
    loading: false,
    showFunnelIntro: EMPTY_OBJECT,
};

export const useTemplateSlice = createSlice({
    name: `${NAME}/useTemplate`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setShowFunnelIntro(state, action: PayloadAction<{ campaignId: string; visible: boolean }>) {
            return {
                ...state,
                showFunnelIntro: {
                    ...state.showFunnelIntro,
                    [action.payload.campaignId]: action.payload.visible,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, setShowFunnelIntro } = useTemplateSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.useTemplateReducer?.loading;

export const getShowFunnelIntroByCampaignId = (state: AppState, campaignId: string): boolean =>
    state[NAME]?.useTemplateReducer?.showFunnelIntro[campaignId];

// === Thunks ======

// Helper function
const createCampaignPayload = (companyId: string, workspaceId: string, name: string) => {
    return {
        data: {
            type: 'campaign',
            attributes: {
                name,
                tags: [],
            },
            relationships: {
                workspace: {
                    data: {
                        id: workspaceId,
                        type: 'workspace',
                    },
                },
                company: {
                    data: {
                        id: companyId,
                        type: 'company',
                    },
                },
            },
        },
    };
};

export const createFunnelFromTemplate =
    (funnelTemplateId: string, name: string, navigateVia: 'push' | 'replace' = 'push'): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const activeWorkspaceId = getActiveWorkspaceId(state);
        const defaultWorkspace = getDefaultWorkspace(state);
        const companyId = getCompanyId(state);

        const campaignPayload = createCampaignPayload(
            companyId,
            activeWorkspaceId || defaultWorkspace?.id,
            name,
        );

        dispatch(setLoading(true));

        try {
            const response = await apiPost<ResponseData<CampaignResource>>(
                `/templates/campaigns/${funnelTemplateId}/use`,
                campaignPayload,
            );

            const createdCampaignId = getDataFromResponse(response)?.id;

            dispatch(setShowFunnelIntro({ campaignId: createdCampaignId, visible: true }));

            if (navigateVia === 'push') {
                await Router.push(getEditorUrl(createdCampaignId));
            } else if (navigateVia === 'replace') {
                await Router.replace(getEditorUrl(createdCampaignId));
            }

            await dispatch(hideModal());
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: `failed to use template ${funnelTemplateId}:`,
            });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default useTemplateSlice.reducer;
