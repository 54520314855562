import type { SVGProps } from 'react';

export const TwoArrowsPointingInwards = (
    props: Omit<SVGProps<SVGSVGElement>, 'viewBox' | 'fill' | 'xmlns'>,
) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.7803 7.21967C19.0732 7.51256 19.0732 7.98744 18.7803 8.28033L15.5607 11.5H18.2488H22.25C22.6642 11.5 23 11.8358 23 12.25C23 12.6642 22.6642 13 22.25 13H15.5607L18.7803 16.2197C19.0732 16.5126 19.0732 16.9874 18.7803 17.2803C18.4874 17.5732 18.0126 17.5732 17.7197 17.2803L13.2197 12.7803C12.9268 12.4874 12.9268 12.0126 13.2197 11.7197L17.7197 7.21967C18.0126 6.92678 18.4874 6.92678 18.7803 7.21967Z"
                fill="currentColor"
            />
            <path
                d="M5.21967 7.21967C4.92678 7.51256 4.92678 7.98744 5.21967 8.28033L8.43934 11.5H5.75121H1.75C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13H8.43934L5.21967 16.2197C4.92678 16.5126 4.92678 16.9874 5.21967 17.2803C5.51256 17.5732 5.98744 17.5732 6.28033 17.2803L10.7803 12.7803C11.0732 12.4874 11.0732 12.0126 10.7803 11.7197L6.28033 7.21967C5.98744 6.92678 5.51256 6.92678 5.21967 7.21967Z"
                fill="currentColor"
            />
        </svg>
    );
};
