import { FEATURE_IDS } from '@/app/billing/constants';

import { createSelector, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { getFeatureAvailability } from '@/app/company/models/company';
import { apiGet, handleRuntimeError } from '@/core/api';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';

import type { CampaignHasVariantsRes } from '@/app/analytics/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState } from '@/core/redux/types';
import type { AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    fetching: boolean;
    hasSplitTest: {
        [campaignId: string]: boolean;
    };
    splitTestStart: {
        [campaignId: string]: string;
    };
}

const initialState: State = {
    fetching: false,
    hasSplitTest: EMPTY_OBJECT,
    splitTestStart: EMPTY_OBJECT,
};

export const splitTestSlice = createSlice({
    name: `${NAME}/splitTest`,
    initialState,
    reducers: {
        setFetching(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                fetching: action.payload,
            };
        },
        setCampaignHasSplitTest(
            state,
            action: PayloadAction<{ campaignId: string; hasSplitTest: boolean }>,
        ) {
            return {
                ...state,
                hasSplitTest: {
                    ...state.hasSplitTest,
                    [action.payload.campaignId]: action.payload.hasSplitTest,
                },
            };
        },
        setSplitTestStart(
            state,
            action: PayloadAction<{ campaignId: string; splitTestStart: string }>,
        ) {
            return {
                ...state,
                splitTestStart: {
                    ...state.splitTestStart,
                    [action.payload.campaignId]: action.payload.splitTestStart,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setFetching, setCampaignHasSplitTest, setSplitTestStart, reset } =
    splitTestSlice.actions;

// === Selectors ======

export const getFetching = (state: AppState) => state[NAME]?.splitTestReducer?.fetching;

const getHasSplitTest = (state: AppState) =>
    get(state[NAME], 'splitTestReducer.hasSplitTest', EMPTY_OBJECT);

const getSplitTestStart = (state: AppState) =>
    get(state[NAME], 'splitTestReducer.splitTestStart', EMPTY_OBJECT);

export const getHasSplitTestForId = (campaignId: string) =>
    createSelector(getHasSplitTest, (hasSplitTest) => {
        return get(hasSplitTest, campaignId, false);
    });

export const getSplitTestStartForId = (campaignId: string) =>
    createSelector(getSplitTestStart, (splitTestStart): string =>
        get(splitTestStart, campaignId, null),
    );

// === Thunks ======

export const fetchHasSplitTesting = (campaignId: string): AppThunk => {
    return async (dispatch) => {
        const hasSplitTestingFeature = getFeatureAvailability(FEATURE_IDS.splitTesting);

        if (!hasSplitTestingFeature) {
            return;
        }

        dispatch(setFetching(true));

        try {
            const response = await apiGet<ResponseData<CampaignHasVariantsRes>>(
                `/campaigns/${campaignId}/has-variants`,
            );

            const hasSplitTest = get(response, 'data.data.hasVariants', false);
            const splitTestStart = get(response, 'data.data.startedAt', null);

            if (hasSplitTest) {
                dispatch(setCampaignHasSplitTest({ campaignId, hasSplitTest: true }));

                if (splitTestStart) {
                    dispatch(setSplitTestStart({ campaignId, splitTestStart }));
                }
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching split test failed:' });
        } finally {
            dispatch(setFetching(false));
        }
    };
};

export default splitTestSlice.reducer;
