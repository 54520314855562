import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getFormValues } from 'redux-form';

import { addSetCampaigns } from '@/app/campaigns/models/campaigns';
import { fetchPageBlocks } from '@/app/editor/blocks/models/blocks';
import { getActivePageId } from '@/app/editor/pages/models/pages';
import { apiGet, apiPatch, apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, resourceArrayToObject } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME, THEME_CONFIG_FORM } from '../constants';

import type { CampaignResource } from '@/app/campaigns/types';
import type { ThemeResource } from '@/app/editor/themes/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    themes: { [themeId: string]: ThemeResource };
    activeTheme: ThemeResource;
    previewTheme: ThemeResource | null;
    triggerTransition: boolean;
    resettingStyles: boolean;
}

const initialState: State = {
    themes: EMPTY_OBJECT,
    activeTheme: null,
    previewTheme: null,
    triggerTransition: false,
    resettingStyles: false,
};

export const themesSlice = createSlice({
    name: `editor/${NAME}/themes`,
    initialState,
    reducers: {
        addThemes(state, action: PayloadAction<{ [themeId: string]: ThemeResource }>) {
            return {
                ...state,
                themes: {
                    ...state.themes,
                    ...action.payload,
                },
            };
        },
        setThemes(state, action: PayloadAction<{ [themeId: string]: ThemeResource }>) {
            return {
                ...state,
                themes: action.payload,
            };
        },
        setPreviewTheme(state, action: PayloadAction<ThemeResource | null>) {
            return {
                ...state,
                previewTheme: action.payload,
            };
        },
        setActiveTheme(state, action: PayloadAction<ThemeResource>) {
            return {
                ...state,
                activeTheme: action.payload,
            };
        },
        setTriggerTransition(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                triggerTransition: action.payload,
            };
        },
        setResettingStyles(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                resettingStyles: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setActiveTheme,
    setPreviewTheme,
    addThemes,
    setThemes,
    setTriggerTransition,
    setResettingStyles,
    reset,
} = themesSlice.actions;

// === Selectors ======

export const getThemes = (state: AppState) => state[NAME]?.themesReducer.themes;

export const getThemesAsArray = createSelector(getThemes, (themes) =>
    Object.keys(themes).map((themeId) => themes[themeId]),
);

export const getThemeBydId = (state: AppState, themeId: string) =>
    state[NAME]?.themesReducer?.themes[themeId];

export const getActiveTheme = (state: AppState) => state[NAME]?.themesReducer?.activeTheme;

export const getPreviewTheme = (state: AppState) => state[NAME]?.themesReducer?.previewTheme;

export const getPreviewOrActiveTheme = createSelector(
    [getFormValues(THEME_CONFIG_FORM), getPreviewTheme, getActiveTheme],
    (formValues, previewTheme, activeTheme) => {
        // If theme is currently configured -> return the config form values as active Theme
        // enables live editing
        if (formValues) {
            return formValues as ThemeResource;
        }

        return previewTheme ?? activeTheme;
    },
);

export const getTriggerTransition = (state: AppState) =>
    state[NAME]?.themesReducer?.triggerTransition;

export const getResettingStyles = (state: AppState) => state[NAME]?.themesReducer?.resettingStyles;

// === Thunks ======

// Set the active theme (and fetches it if necessary) in Redux for instant live preview
export const setActiveThemeAndFetch =
    (themeId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        let theme = getThemeBydId(state, themeId);

        // if theme wasn't fetch already (e.g. boot) fetch it now
        if (!theme) {
            try {
                const response = await apiGet(`/themes/${themeId}`);
                theme = getDataFromResponse(response);

                // add to list of fetched themes
                dispatch(addThemes({ [theme?.id]: theme }));
            } catch (err) {
                handleRuntimeError(err, { debugMessage: 'fetching theme failed:' });
            }
        }

        dispatch(setActiveTheme(theme));
    };

// Saves the theme for the campaign by updating the campaigns theme relationship in the DB
export const updateCampaignTheme =
    (campaignId: string, themeId: string): AppThunk =>
    async (dispatch) => {
        const payload = {
            data: [
                {
                    id: themeId,
                    type: 'theme',
                },
            ],
        };

        try {
            const response = await apiPatch(
                `/campaigns/${campaignId}/relationships/theme`,
                payload,
            );

            // set updated campaign in redux
            const newCampaign: CampaignResource = getDataFromResponse(response);
            dispatch(addSetCampaigns({ [campaignId]: newCampaign }));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating campaign theme failed:' });
        }
    };

// Used in boot to populate initial themes
export const setThemesFromArray =
    (themes: ThemeResource[]): AppThunk =>
    (dispatch) => {
        const newThemes = resourceArrayToObject(themes);

        dispatch(addThemes(newThemes));
    };

// Make all blocks in the funnel use the theme
export const resetStylesToTheme =
    (campaignId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const activePageId = getActivePageId(state);

        try {
            dispatch(setResettingStyles(true));
            await apiPost(`/campaigns/${campaignId}/components/reset-styles`, null);
            dispatch(fetchPageBlocks(activePageId));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'resetting component styles failed:' });
        } finally {
            dispatch(setResettingStyles(false));
        }
    };

export const applyTheme =
    (campaignId: string, themeId: string, resetStyles: boolean): AppThunk =>
    (dispatch) => {
        dispatch(setTriggerTransition(true));
        dispatch(setActiveThemeAndFetch(themeId));
        dispatch(updateCampaignTheme(campaignId, themeId));

        // reset block styles to use theme colors
        if (resetStyles) {
            dispatch(resetStylesToTheme(campaignId));
        }
    };

export default themesSlice.reducer;
