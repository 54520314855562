import { combineReducers } from 'redux';

import customFontsReducer from './customFonts';
import deleteThemeReducer from './deleteTheme';
import newThemeReducer from './newTheme';
import themesReducer from './themes';
import updateThemeReducer from './updateTheme';

export default combineReducers({
    themesReducer,
    newThemeReducer,
    updateThemeReducer,
    deleteThemeReducer,
    customFontsReducer,
});
