import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { fetchCampaign, getCampaignById } from '@/app/campaigns/models/campaigns';
import { addPageVariant } from '@/app/editor/pages/models/variant';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, resourceArrayToObject } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@/utils/empty';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { setPageBlockOrder } from '../../blocks/models/blockOrder';
import { fetchPageBlocks, getFetchedBlockPages } from '../../blocks/models/blocks';
import { NAME } from '../constants';

import type { PageResource } from '../types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosResponse } from 'axios';

interface PagesState {
    pages: {
        [campaignId: string]: {
            [id: string]: PageResource;
        };
    };
    activePageId: { [campaignId: string]: string };
    firstPage: { [campaignId: string]: PageResource };
    activeMenu: string;
}

const initialState: PagesState = {
    pages: EMPTY_OBJECT,
    activePageId: EMPTY_OBJECT,
    firstPage: EMPTY_OBJECT,
    activeMenu: EMPTY_STRING,
};

export const pagesSlice = createSlice({
    name: `editor/${NAME}/pages`,
    initialState,
    reducers: {
        setPages(
            state,
            action: PayloadAction<{ campaignId: string; pages: { [id: string]: PageResource } }>,
        ) {
            const { campaignId, pages } = action.payload;

            return {
                ...state,
                pages: {
                    ...state.pages,
                    [campaignId]: pages,
                },
            };
        },
        setPage(state, action: PayloadAction<{ campaignId: string; page: PageResource }>) {
            const { campaignId, page } = action.payload;

            return {
                ...state,
                pages: {
                    ...state.pages,
                    [campaignId]: {
                        ...state.pages[campaignId],
                        [page.id]: page,
                    },
                },
            };
        },
        setActivePageId(state, action: PayloadAction<{ campaignId: string; pageId: string }>) {
            const { campaignId, pageId } = action.payload;

            return {
                ...state,
                activePageId: {
                    ...state.activePageId,
                    [campaignId]: pageId,
                },
            };
        },
        setFirstPageForCampaignId(
            state,
            action: PayloadAction<{ campaignId: string; page: PageResource }>,
        ) {
            const { campaignId, page } = action.payload;

            return {
                ...state,
                firstPage: {
                    ...state.firstPage,
                    [campaignId]: page,
                },
            };
        },
        setActiveMenu(state, action: PayloadAction<string>) {
            return {
                ...state,
                activeMenu: action.payload,
            };
        },
        reset(state, action: PayloadAction<boolean>) {
            const withPages = action.payload;

            if (withPages) {
                return initialState;
            }

            // keep page data
            return {
                ...initialState,
                pages: state.pages,
                firstPage: state.firstPage,
            };
        },
    },
});

// === Actions ======

export const {
    setPages,
    setPage,
    setActivePageId,
    setFirstPageForCampaignId,
    setActiveMenu,
    reset,
} = pagesSlice.actions;

// === Selectors ======

export const getPages = (state: AppState) => {
    return state[NAME]?.pagesReducer?.pages;
};

export const getPageById = (state: AppState, campaignId: string, pageId: string) => {
    return state[NAME]?.pagesReducer?.pages[campaignId]?.[pageId] || (EMPTY_OBJECT as PageResource);
};

export const getPageCountByCampaignId = (state: AppState, campaignId: string) => {
    const pages = getPages(state);

    if (!pages?.[campaignId]) {
        return 0;
    }

    return Object.keys(pages[campaignId]).length;
};

export const getFirstPageByCampaignId = (state: AppState, campaignId: string) =>
    state[NAME]?.pagesReducer?.firstPage?.[campaignId];

export const getFirstPageIdByCampaignId = (state: AppState, campaignId: string) =>
    state[NAME]?.pagesReducer?.firstPage?.[campaignId]?.id;

export const getActivePageId = (state: AppState) => {
    const campaignId = getCampaignIdFromRouter();

    return state[NAME]?.pagesReducer?.activePageId[campaignId];
};

export const getActivePage = (state: AppState) => {
    const pageId = getActivePageId(state);
    const campaignId = getCampaignIdFromRouter();

    return getPageById(state, campaignId, pageId);
};

export const getActiveMenu = (state: AppState) => state[NAME]?.pagesReducer?.activeMenu;

// === Thunks ======

// fetch all pages of campaign
export const fetchPages =
    (campaignId: string): AppThunk =>
    async (dispatch, getState) => {
        let campaign = getCampaignById(getState(), campaignId);

        // Fetch campaign if not present
        if (!campaign) {
            campaign = await dispatch(fetchCampaign(campaignId));
        }

        const pages = get(campaign, 'relationships.pages.data', EMPTY_ARRAY);

        try {
            const promises = pages.map((page) => {
                return apiGet<ResponseData<PageResource>>(`/pages/${page.id}`);
            });

            const responses = await Promise.allSettled(promises);
            const fetchedPages: PageResource[] = responses
                .filter(({ status }) => status === 'fulfilled')
                .map(
                    (response) =>
                        response as PromiseFulfilledResult<
                            AxiosResponse<ResponseData<PageResource>>
                        >,
                )
                .map((response) => getDataFromResponse(response.value));

            // Check for split testing
            const variants = fetchedPages.filter((page) => {
                return page.attributes.isVariant;
            });

            if (variants.length) {
                dispatch(addPageVariant({ pageVariant: variants[0], campaignId }));
            }

            // set ordered blocks for optimistic block reordering
            fetchedPages.forEach((page) => {
                dispatch(setPageBlockOrder(page));
            });

            // set fetched pages
            dispatch(setPages({ campaignId, pages: resourceArrayToObject(fetchedPages) }));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching pages failed:' });
        }
    };

// fetch a single page
export const fetchSinglePage =
    (pageId: string): AppThunk<Promise<PageResource>> =>
    async (dispatch) => {
        try {
            const response = await apiGet<ResponseData<PageResource>>(`/pages/${pageId}`);
            const page: PageResource = getDataFromResponse(response);

            if (page) {
                const campaignId = page.relationships?.campaign?.data?.id;

                await dispatch(setPage({ campaignId, page }));

                // set ordered blocks for optimistic block reordering
                dispatch(setPageBlockOrder(page));

                return page;
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: `fetching page ${pageId} failed:` });
        }
    };

// fetch first page
export const fetchFirstPage =
    (campaignId: string): AppThunk<Promise<PageResource>> =>
    async (dispatch, getState) => {
        const firstPage = getFirstPageByCampaignId(getState(), campaignId);

        if (firstPage) {
            return firstPage;
        }

        try {
            const response = await apiGet<ResponseData<PageResource>>(
                `/campaigns/${campaignId}/pages/first`,
            );
            const page = getDataFromResponse(response);

            dispatch(setFirstPageForCampaignId({ page, campaignId }));

            return page;
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: `fetching first page for funnel ${campaignId} failed:`,
            });
        }
    };

// Conditional page block fetching
export const fetchPageBlocksIfNotPresent =
    (pageId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const fetchedBlockPages = getFetchedBlockPages(state);

        // Check if page components have already been fetched; if not -> fetch em'
        if (!fetchedBlockPages?.includes(pageId)) {
            await dispatch(fetchPageBlocks(pageId));
        }
    };

// set active page and fetch its components
export const setActivePage =
    (campaignId: string, pageId: string): AppThunk =>
    async (dispatch) => {
        // Set active page
        await Promise.all([
            dispatch(setActivePageId({ campaignId, pageId })),
            dispatch(fetchPageBlocksIfNotPresent(pageId)),
        ]);
    };

// preload first page (hover in campaign overview)
export const preloadFirstPage =
    (campaignId: string): AppThunk =>
    async (dispatch) => {
        try {
            const page = await dispatch(fetchFirstPage(campaignId));

            if (page) {
                dispatch(setPageBlockOrder(page));
                await dispatch(fetchPageBlocksIfNotPresent(page.id));
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'preloading first failed:' });
        }
    };

export default pagesSlice.reducer;
