import {
    NAME,
    PUBLISH_HELP_ARTICLE_URL,
    PUBLISH_HELP_ARTICLE_URL_EN,
} from '@/app/campaigns/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';
import { i18n } from 'next-i18next';

import { hasCampaignBeenPublishedBefore } from '@/app/campaigns/helpers';
import { createLaunchPayload } from '@/app/campaigns/helpers/createLaunchPayload';
import { preparePublishState } from '@/app/navigation/components/Navbar/EditorNav/helpers/utils';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiPost, handleRuntimeError } from '@/core/api';
import { track } from '@/core/tracking';
import { EMPTY_ARRAY } from '@/utils/empty';

import { fetchCampaignAndVersions } from './campaigns';
import { setDomainEditing, setPublishSlug } from './domainEditing';

import type { CampaignResource } from '../types';
import type { DomainResource } from '@/app/domains/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    error: string;
    publishing: boolean;
    buildingCampaigns: string[];
}

const initialState: State = {
    error: '',
    publishing: false,
    buildingCampaigns: EMPTY_ARRAY,
};

export const publishSlice = createSlice({
    name: `${NAME}/publish`,
    initialState,
    reducers: {
        setPublishing(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                publishing: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setPublishing, reset } = publishSlice.actions;

// === Selectors ======

export const getIsPublishing = (state: AppState) => state[NAME]?.publishReducer?.publishing;

export const getBuildingCampaigns = (state: AppState) =>
    state[NAME]?.publishReducer?.buildingCampaigns;

const dataLaunchCampaign = (campaign: CampaignResource, payload: any) => {
    return async () => {
        return await apiPost(`/campaigns/${campaign.id}/launch`, payload);
    };
};

export const openHelpArticle = () => {
    window.open(
        Router.locale === 'de' ? PUBLISH_HELP_ARTICLE_URL : PUBLISH_HELP_ARTICLE_URL_EN,
        '_ blank',
    );
};

type PublishCampaignOptions = {
    campaign: CampaignResource;
    domain: DomainResource;
    slug: string;
    errorToastContent: {
        actionText: string;
        message: string;
    };
};

// === Thunks ======

export const publishCampaign = ({
    campaign,
    domain,
    slug,
    errorToastContent,
}: PublishCampaignOptions): AppThunk<Promise<void>> => {
    return async (dispatch) => {
        // Init publishing
        dispatch(setPublishing(true));

        try {
            const payload = createLaunchPayload(domain?.id, slug);
            await dispatch(dataLaunchCampaign(campaign, payload));
            await dispatch(fetchCampaignAndVersions(campaign.id));
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: 'publishing campaign failed:',
                silent: true,
            });

            showToast({
                ...errorToastContent,
                onActionClick: openHelpArticle,
                type: 'warning',
            });
        } finally {
            dispatch(setPublishing(false));
        }
    };
};

export const fastPublishCurrentCampaign = (): AppThunk<Promise<void>> => {
    return async (dispatch, getState) => {
        const state = getState();

        if (getIsPublishing(state)) {
            return;
        }

        try {
            // The "isCampaignBuilding" selector will be set to true first
            // when "publishCampaign" gets dispatched. As "preparePublishState"
            // might load some data, we want to show the loading state in the UI earlier.
            // That's why we are "isFastPublishing" to true during a fast publish action.
            dispatch(setPublishing(true));

            const { campaign, domain, slug, publishUrl } = await preparePublishState();

            await dispatch(
                publishCampaign({
                    campaign,
                    domain,
                    slug,
                    errorToastContent: {
                        message: i18n.t('navigation:publish.fastPublish.failureToastContent'),
                        actionText: i18n.t('navigation:publish.fastPublish.failureToastAction'),
                    },
                }),
            );

            showToast({
                type: 'success',
                message: i18n.t('navigation:publish.fastPublish.success'),
                actionText: i18n.t('navigation:publish.fastPublish.successToastAction'),
                onActionClick: () => {
                    window.open(publishUrl, '_blank', 'noreferrer noopener');

                    void track(
                        TRACKING_EVENTS.campaign.publish.navbar.fastPublish
                            .successToastOpenFunnelClicked,
                        {
                            campaign_id: campaign.id,
                            has_been_published_before: hasCampaignBeenPublishedBefore(campaign),
                        },
                    );
                },
                duration: 5000,
            });
        } finally {
            dispatch(setPublishing(false));
        }
    };
};

export const saveSlug = (slug: string = '', toastMessage: string, campaignId: string): AppThunk => {
    return (dispatch) => {
        dispatch(setDomainEditing(false));
        dispatch(setPublishSlug({ slug, campaignId }));
        showToast({
            type: 'success',
            message: toastMessage,
        });
    };
};

export default publishSlice.reducer;
