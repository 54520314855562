import { NAME } from '@/app/mediaLibrary/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { useTranslation } from 'next-i18next';
import { useCallback, useEffect } from 'react';

import { track } from '@/core/tracking';
import { FieldContainer } from '@/ui/components/_BlockEditFields/FieldContainer';
import { Slider } from '@/ui/components/_BlockEditFields/Slider';

import type { WrappedFieldInputProps } from 'redux-form';

interface Props {
    input: WrappedFieldInputProps;
    submit: () => void;
}

const getOpacity = (value: number | undefined): number => {
    if (value === undefined || typeof value !== 'number') {
        return 100;
    }

    return value;
};

export const BgImageOpacity = ({ input, submit }: Props) => {
    const { t } = useTranslation(NAME);
    const { value, onChange } = input;

    // Init with 100% opacity if not set
    useEffect(() => {
        if (typeof value !== 'number') {
            onChange(100);
            setTimeout(submit);
        }
    }, [onChange, submit, value]);

    const handleChangeCommit = useCallback(
        (value: number[]) => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.opacity, {});
            onChange(value[0]);
            setTimeout(submit);
        },
        [onChange, submit],
    );

    const handleChange = useCallback(
        (value: number[]) => {
            onChange(value[0]);
        },
        [onChange],
    );

    return (
        <FieldContainer>
            <Slider
                tooltip={t('image-opacity', {
                    opacity: getOpacity(value),
                })}
                defaultValue={getOpacity(value)}
                value={value}
                onChange={handleChange}
                onCommit={handleChangeCommit}
            />
        </FieldContainer>
    );
};
