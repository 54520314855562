import {
    EditorEngineExecutionDirection,
    EditorEngineHistoryEntryRecordedStepStatusType,
    EditorEngineHistoryEntryStepForeachFilter,
} from '@/app/editor/engine/core/types';
import { makeEditorEngineError } from '@/app/editor/engine/utils/error/makeEditorEngineError';

import type {
    EditorEngineHistoryDefaultEntry,
    EditorEngineHistoryEntryRecordedStep,
    EditorEngineHistoryForeachStepExecutionResult,
    EditorEngineHistoryEntryTransactionStepResult,
} from '@/app/editor/engine/core/types';
import type { FailedResult } from '@/app/editor/engine/core/types/util';

interface Input<TResult, TExtraContext extends object> {
    /**
     * The entry to iterate over.
     */
    entry: EditorEngineHistoryDefaultEntry;
    /**
     * The direction to iterate in.
     */
    direction: EditorEngineExecutionDirection;
    /**
     * The filter to apply to the steps.
     */
    filter: EditorEngineHistoryEntryStepForeachFilter;
    /**
     * The handler to execute for each step.
     */
    handler: (step: EditorEngineHistoryEntryRecordedStep<TExtraContext>) => Promise<TResult>;
}

/**
 * Iterate over each step in the entry in a specific direction.
 *
 * While this can be solved by simply iterating over the steps (it is an array,
 * after all), this function provides a more convenient way that also handles
 * filtering, early stopping, crash and error handling.
 */
export const foreachEntryRecordedStep = async <
    TResult extends EditorEngineHistoryEntryTransactionStepResult,
    TExtraContext extends object,
>({
    entry,
    direction,
    filter,
    handler,
}: Input<TResult, TExtraContext>): Promise<
    EditorEngineHistoryForeachStepExecutionResult<TResult, TExtraContext>
> => {
    const steps =
        direction === EditorEngineExecutionDirection.Forward
            ? entry.recordedSteps
            : [...entry.recordedSteps].reverse();

    let result: TResult;

    for (const step of steps) {
        try {
            if (filter === EditorEngineHistoryEntryStepForeachFilter.All) {
                result = await handler(step);
            } else if (
                filter === EditorEngineHistoryEntryStepForeachFilter.Failed &&
                step.executionResult.type === EditorEngineHistoryEntryRecordedStepStatusType.Failed
            ) {
                result = await handler(step);
            } else if (
                filter === EditorEngineHistoryEntryStepForeachFilter.Successful &&
                step.executionResult.type ===
                    EditorEngineHistoryEntryRecordedStepStatusType.ExecutedSuccessfully
            ) {
                result = await handler(step);
            }

            if (result.success === false) {
                return {
                    finished: false as const,
                    crash: false as const,
                    result: result as FailedResult<TResult>,
                    step,
                };
            }
        } catch (error) {
            return {
                finished: false as const,
                crash: true as const,
                step,
                error: makeEditorEngineError({
                    error,
                    debug: {
                        stepId: step.id,
                        entryId: entry.id,
                        actionName: entry.action.name,
                    },
                }),
            };
        }
    }

    return { finished: true, additionalDebugData: {} };
};
