import { PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS } from '@/app/crm/constants';

import { XMarkIcon } from '@heroicons/react/20/solid';

import { cn } from '@/utils/cn';

import type { MouseEvent } from 'react';

interface Props {
    children: string;
    inline?: boolean;
    className?: string;
    onDeselect?: (event: MouseEvent) => void;
}

export const AnswerPill = ({ children, className, onDeselect, inline = false }: Props) => {
    return (
        <div
            className={cn(
                'flex items-center justify-between overflow-hidden rounded-md border bg-gray-50 px-2 py-1 text-left text-sm text-gray-700',
                className,
            )}
        >
            <span
                className={cn({
                    truncate: inline,
                })}
            >
                {children}
            </span>

            {!!onDeselect && (
                <XMarkIcon
                    className={cn(
                        'ml-2 size-4 shrink-0 transition-colors duration-150 hover:text-gray-400',
                        PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS,
                    )}
                    onClick={onDeselect}
                />
            )}
        </div>
    );
};
