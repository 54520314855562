import { createSlice } from '@reduxjs/toolkit';

import { fetchUsage } from '@/app/company/models/usages';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import { NAME } from '../constants';

import type { CompanyResource } from '@/app/company/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    company: CompanyResource;
}

const initialState: State = {
    company: null,
};

export const companySlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setCompany(state, action: PayloadAction<CompanyResource>) {
            return {
                ...state,
                company: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setCompany, reset } = companySlice.actions;

// === Selectors ======

export const getCompany = (state: AppState) => state[NAME]?.company?.company;

export const getCompanyId = (state: AppState) => state[NAME]?.company?.company?.id;

export const getFeatureAvailability =
    (feature: string) =>
    (state: AppState): boolean => {
        const company = getCompany(state);

        return company?.attributes?.limitations?.features?.[feature] ?? false;
    };

// === Thunks ======

export const fetchCompany = (): AppThunk => async (dispatch, getState) => {
    const company = getCompany(getState());

    try {
        dispatch(fetchUsage());
        const response = await apiGet<ResponseData<CompanyResource>>(`/companies/${company.id}`);
        dispatch(setCompany(getDataFromResponse(response)));
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'fetching company failed:' });
    }
};

export default companySlice.reducer;
