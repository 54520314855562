import { createSlice } from '@reduxjs/toolkit';

import { updateCampaign } from '@/app/campaigns/models/update';
import { handleRuntimeError } from '@/core/api';
import { EMPTY_STRING } from '@/utils/empty';

import { NAME } from '../constants';

import type { CampaignResource } from '@/app/campaigns/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    selectedImage: string;
}

const initialState: State = {
    loading: false,
    selectedImage: EMPTY_STRING,
};

export const sharingSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setSelectedImage(state, action: PayloadAction<string>) {
            return {
                ...state,
                selectedImage: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, setSelectedImage, reset } = sharingSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.sharingReducer?.loading;

// === Thunks ======

export const updateCampaignMetaInfos =
    (campaign: CampaignResource, title: string, description: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const updatedCampaign = {
                ...campaign,
                attributes: {
                    ...campaign.attributes,
                    metaTitle: title,
                    description,
                },
            };

            await dispatch(updateCampaign(updatedCampaign));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating meta infos failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default sharingSlice.reducer;
