import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    submit: () => void;
}

const Picker = dynamic(() => import('@emoji-mart/react'));

const EmojiPicker = ({ input, submit }: Props) => {
    const { locale } = useRouter();

    const handleSelect = (emoji) => {
        input.onChange(emoji.id);

        setTimeout(submit);
    };

    return (
        <Picker
            // @ts-ignore
            emojiSize={24}
            perLine={6}
            locale={locale}
            onEmojiSelect={handleSelect}
            theme="light"
            className="w-full"
            emojiButtonSize={40.5}
            skinTonePosition="none" // enabling this needs more work in actually saving the emoji id + skin tone
        />
    );
};

export default EmojiPicker;
