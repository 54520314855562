import { createSlice } from '@reduxjs/toolkit';

import { getSearch, getType } from './menu';
import { fetchRecentCampaigns } from './recentCampaigns';
import { fetchOtherCampaigns, fetchActiveWorkspaceCampaigns } from './workspaceCampaigns';
import { NAME } from '../constants';
import { CommandMenuType } from '../types';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const campaignsSlice = createSlice({
    name: `${NAME}/campaigns`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = campaignsSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.campaignsReducer?.loading;

// === Thunks ======

export const fetchCampaigns =
    (canUpdateCampaign?: boolean): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();

        dispatch(setLoading(true));

        const type = getType(state);
        const search = getSearch(state);

        switch (type) {
            case CommandMenuType.workspace:
                await Promise.all([
                    dispatch(fetchActiveWorkspaceCampaigns(search, canUpdateCampaign)),
                    dispatch(fetchOtherCampaigns(search, canUpdateCampaign)),
                ]);
                break;
            default:
                await dispatch(fetchRecentCampaigns(search));
                break;
        }

        dispatch(setLoading(false));
    };

export default campaignsSlice.reducer;
