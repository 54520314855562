import type {
    EditorEngineHistoryDefaultEntry,
    EditorEngineHistoryDefaultEntryCollection,
} from '@/app/editor/engine/core/types';

/**
 * Check if the entry is the latest entry.
 *
 * This is useful when, for example, a new action has been queued. If the
 * user has already undone some actions, the new action will be the latest one,
 * and the following actions will be removed.
 */
export const isLatestEntry = ({
    entry,
    entriesInfo,
}: {
    /**
     * The entry that is being checked.
     */
    entry: EditorEngineHistoryDefaultEntry;
    /**
     * Information about the entries.
     */
    entriesInfo: EditorEngineHistoryDefaultEntryCollection;
}) => {
    return (
        entriesInfo.current?.id === entry.id &&
        entriesInfo.currentIndex === entriesInfo.entries.length - 1
    );
};
