import { NAME } from '@/app/editor/blocks/constants';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';

import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';
import { turnOffAutoComplete } from '@/utils/inputFields';

import type { KeyboardEvent, SVGProps, ChangeEventHandler, HTMLProps } from 'react';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    placeholder: string;
    autoFocus?: boolean;
    icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
    onBlurSubmit?: () => void;
    action?: {
        icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
        onClick: () => void;
        tooltip?: string;
        disabled?: boolean;
    };
    onChange?: ChangeEventHandler<HTMLInputElement>;
    step?: HTMLProps<HTMLInputElement>['step'];
    className?: string;
}

const TextInput = ({
    input,
    placeholder,
    autoFocus,
    icon,
    onBlurSubmit,
    action,
    meta: { touched, invalid, error },
    onChange,
    step,
    className,
}: Props) => {
    const { t } = useTranslation(NAME);
    const inputRef = useRef(null);

    const isInvalid = touched && invalid;
    const Icon = icon;
    const ActionIcon = action?.icon;

    const handleBlur = () => {
        if (onBlurSubmit) {
            onBlurSubmit();
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.code === 'Enter') {
            inputRef?.current?.blur();
        }
    };

    const handleChange = ((event) => {
        input.onChange(event);

        if (onChange) {
            onChange(event);
        }
    }) satisfies ChangeEventHandler<HTMLInputElement>;

    return (
        <div
            className={cn(
                'group relative flex h-12 items-center rounded-lg bg-gray-100 text-gray-500 focus-within:border-blue-500 focus-within:text-blue-500',
                className,
            )}
        >
            {icon && <Icon className="ml-4 size-5" />}
            <input
                {...input}
                className={cn(
                    'placeholder-normal absolute h-10 w-full truncate rounded-md bg-transparent text-gray-500 outline-none sm:text-sm',
                    isInvalid || !isEmpty(action) ? 'pr-12' : 'pr-4',
                    icon ? 'pl-12' : 'pl-4',
                )}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                ref={inputRef}
                onChange={handleChange}
                step={step}
                {...turnOffAutoComplete()}
            />

            {isInvalid && (
                <Tooltip content={t(error)}>
                    <ExclamationCircleIcon
                        className="absolute right-4 size-5 text-yellow-500"
                        style={{ fill: 'none' }}
                    />
                </Tooltip>
            )}

            {!isEmpty(action) && !isInvalid && (
                <Tooltip content={action.tooltip}>
                    <button
                        type="button"
                        className={cn('absolute right-4 size-5', {
                            'text-gray-400 hover:text-blue-500': !action.disabled,
                            'cursor-not-allowed text-gray-300': action.disabled,
                        })}
                        onClick={action.onClick}
                        disabled={action.disabled}
                    >
                        <ActionIcon className="size-5" />
                    </button>
                </Tooltip>
            )}
        </div>
    );
};

export default TextInput;
