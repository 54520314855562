import { change, getFormValues } from 'redux-form';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import type { BlockResource, BoxProps } from '@/app/editor/blocks/types';

export const useFrameSubmit = (blockId: string, handleSubmit: (values: BlockResource) => void) => {
    const dispatch = useAppDispatch();
    const formValues = useAppSelector((state) => getFormValues(blockId)(state)) as BlockResource;

    return (framed: boolean) => {
        const box: BoxProps = formValues?.attributes?.content?.box;

        const updatedBox: BoxProps = {
            ...box,
            top: framed ? 'medium' : 'none',
            right: framed ? 'large' : 'none',
            bottom: framed ? 'medium' : 'none',
            left: framed ? 'large' : 'none',
        };

        // also update image Box in addition to `framed`
        dispatch(change(blockId, 'attributes.content.box', updatedBox));

        setTimeout(handleSubmit);
    };
};
