import { EyeSlashIcon } from '@heroicons/react/24/outline';

import { cn } from '@/utils/cn';

import DashedIcon from './DashedIcon';
import SolidIcon from './SolidIcon';

import type { LineType } from '@/app/editor/blocks/types';

export interface Props {
    id: LineType;
    onClick: (style: string) => void;
    active: boolean;
}

const iconMap = {
    hidden: EyeSlashIcon,
    solid: SolidIcon,
    dashed: DashedIcon,
};

export const LineStyleItem = ({ id, onClick, active }: Props) => {
    const handleClick = () => onClick(id);

    const Icon = iconMap[id];

    return (
        <div
            className={cn(
                'flex flex-1 cursor-pointer justify-center',
                active ? 'text-blue-500' : 'text-gray-400 hover:text-gray-600',
            )}
            onClick={handleClick}
        >
            <Icon className="inline size-5" />
        </div>
    );
};
