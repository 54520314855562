import { MAX_IMAGE_FILE_SIZE, NAME } from '@/app/mediaLibrary/constants';

import { CloudArrowUpIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import ButtonLoadingSpinner from '@/ui/components/ButtonLoadingSpinner';
import { Tooltip } from '@/ui/components/Tooltip';

import type { ChangeEvent } from 'react';

interface Props {
    onChange: (file: File) => void;
    loading: boolean;
}

export const FileUploadButton = ({ onChange, loading }: Props) => {
    const { t } = useTranslation(NAME);
    const [error, setError] = useState<string | null>(null);

    const handleChange = useCallback(
        (evt: ChangeEvent<HTMLInputElement>) => {
            const file = evt.target.files?.[0];
            const fileSize = file?.size || 0;

            if (fileSize > MAX_IMAGE_FILE_SIZE) {
                setError(
                    t('file-too-large-description', {
                        MAX_FILE_SIZE: `${MAX_IMAGE_FILE_SIZE / 1000 / 1000}MB`,
                    }),
                );

                return;
            }

            if (file) {
                onChange(file);
            }
        },
        [onChange, setError, t],
    );

    return (
        <FieldContainer>
            <input
                type="file"
                accept="image/jpeg, image/png, image/gif"
                className="hidden"
                id="bg-image-file-input"
                onChange={handleChange}
                multiple={false}
            />

            <Tooltip content={error ?? t('common:upload-image')}>
                <label htmlFor="bg-image-file-input" className="cursor-pointer">
                    <div className="bump flex h-12 w-full items-center justify-center rounded-lg bg-gray-100 px-4 text-gray-400 hover:text-gray-600">
                        {loading ? (
                            <ButtonLoadingSpinner className="text-current" />
                        ) : error ? (
                            <ExclamationTriangleIcon className="size-5 text-amber-500" />
                        ) : (
                            <CloudArrowUpIcon className="size-5" />
                        )}
                    </div>
                </label>
            </Tooltip>
        </FieldContainer>
    );
};
