import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { useEntry } from '@/core/contentful/hooks/queries/useEntry';
import { Loader } from '@/ui/components/Loader';
import { cn } from '@/utils/cn';

const Markdown = dynamic(() => import('@/ui/components/Markdown'));

interface Props {
    contentId: string;
    showInformationCircleIcon?: boolean;
}

export const HelpPopover = ({ contentId, showInformationCircleIcon = true }: Props) => {
    const { t } = useTranslation('common');

    const { data: entry, isLoading, isError } = useEntry(contentId);

    const title = entry?.fields.title;
    const content = entry?.fields.content;
    const learnMoreLink = entry?.fields.learnMoreLink;

    return (
        <div className="max-w-96 rounded-2xl border bg-white p-4 text-sm shadow-dropdown transition-all">
            <Loader loading={isLoading} narrow size="small">
                {isError ? (
                    <p className="text-gray-400">{t('entry-not-found')}</p>
                ) : (
                    <>
                        <div className="flex items-center">
                            {showInformationCircleIcon && (
                                <InformationCircleIcon className="size-5 text-blue-500" />
                            )}
                            <h3
                                className={cn('font-semibold', {
                                    'ml-2': showInformationCircleIcon,
                                })}
                            >
                                {title}
                            </h3>
                        </div>
                        {content && <Markdown>{content}</Markdown>}
                        {learnMoreLink && (
                            <a
                                href={learnMoreLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="relative mt-6 inline-flex h-9 items-center rounded-md bg-blue-500 px-4 text-sm font-medium text-white shadow-sm outline-none hover:bg-blue-600"
                            >
                                {t('learn-more')} <ArrowLongRightIcon className="ml-1 size-5" />
                            </a>
                        )}
                    </>
                )}
            </Loader>
        </div>
    );
};
