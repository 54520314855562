import type { ReactNode } from 'react';

export interface Props {
    children: ReactNode;
}

export const InputSettings = ({ children }: Props) => {
    return (
        <div className="absolute inset-y-0 right-0 z-20 flex h-full translate-x-full items-center gap-2 pr-2 transition-all duration-150 ease-in-out group-focus-within:translate-x-0 group-hover:translate-x-0">
            {children}
        </div>
    );
};
