import last from 'lodash/last';

import { store } from '@/core/redux/store';

import { randomId } from '@/app/editor/engine/utils/randomId';

import {
    addToRedoStack,
    addToUndoStack,
    clearRedoStack,
    clearStacks,
    getIsExecuting,
    getRedoStack,
    getUndoStack,
    popFromRedoStack,
    popFromUndoStack,
    setExecuting,
} from '../models/history';
import { HistoryAction } from '../types';

import type { Command, HistoryEntry } from '../types';

export class HistoryController {
    private queue: Command[] = [];
    private historyRepo: Record<string, HistoryEntry> = {};

    public addToHistory(entry: HistoryEntry) {
        this.historyRepo[entry.id] = entry;
    }

    public removeFromHistory(entryId: string) {
        const entry = this.historyRepo[entryId];

        if (entry) {
            delete this.historyRepo[entryId];
        }
    }

    public clearHistory() {
        this.historyRepo = {};
        store.dispatch(clearStacks());
    }

    public async executeCommand(command: Command) {
        const state = store.getState();
        const isExecutingCommand = getIsExecuting(state);

        if (isExecutingCommand) {
            // Add command to queue
            this.queue.push(command);
        }

        store.dispatch(setExecuting(true));

        const commandResult = await command.execute();
        const redoStack = getRedoStack(store.getState());

        if (commandResult.success && commandResult.canUndo) {
            const entryId = randomId();

            this.addToHistory({
                id: entryId,
                command,
                action: HistoryAction.Execute,
            });

            store.dispatch(addToUndoStack(entryId));
        }

        if (commandResult.success && redoStack.length > 0) {
            redoStack.forEach((entryId) => this.removeFromHistory(entryId));
            store.dispatch(clearRedoStack());
        }

        store.dispatch(setExecuting(false));

        // Check if there are commands in the queue
        if (this.queue.length > 0) {
            const nextCommand = this.queue.shift();

            if (nextCommand) {
                await this.executeCommand(nextCommand);
            }
        }

        return commandResult;
    }

    public async undoLastCommand() {
        const state = store.getState();
        const entryId = last(getUndoStack(state));
        const entry = this.historyRepo[entryId];
        const isExecutingCommand = getIsExecuting(state);

        if (!entry || isExecutingCommand) {
            return;
        }

        store.dispatch(setExecuting(true));

        const commandResult = await entry.command.undo();

        if (commandResult.success) {
            store.dispatch(popFromUndoStack());
        }

        if (commandResult.success && commandResult.canRedo) {
            const entryId = randomId();

            this.addToHistory({
                id: entryId,
                command: entry.command,
                action: HistoryAction.Redo,
            });

            store.dispatch(addToRedoStack(entryId));
        }

        store.dispatch(setExecuting(false));
    }

    public async redoLastCommand() {
        const state = store.getState();
        const entryId = last(getRedoStack(state));
        const entry = this.historyRepo[entryId];
        const isExecutingCommand = getIsExecuting(state);

        if (!entry || isExecutingCommand) {
            return;
        }

        store.dispatch(setExecuting(true));

        const commandResult = await entry.command.redo();

        if (commandResult.success && commandResult.canUndo) {
            const entryId = randomId();

            this.addToHistory({
                id: entryId,
                command: entry.command,
                action: HistoryAction.Undo,
            });

            store.dispatch(addToUndoStack(entryId));
            store.dispatch(popFromRedoStack());
        }

        store.dispatch(setExecuting(false));
    }
}

let controller: HistoryController;

export default function getHistoryController() {
    if (controller) {
        return controller;
    }

    controller = new HistoryController();

    return controller;
}
