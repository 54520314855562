import { DATE_FORMAT } from '@/app/editor/editor/constants';

import formatDate from 'date-fns/format';

import { getNow } from '@/utils/common';

const exampleDate = getNow();
exampleDate.setMonth(2);
exampleDate.setDate(25);

export const getDateFormatOptions = () => {
    return [
        {
            key: formatDate(exampleDate, "dd.MM.'1992'"),
            value: DATE_FORMAT.DEFAULT_EU,
        },
        {
            key: formatDate(exampleDate, "MM/dd/'1992'"),
            value: DATE_FORMAT.DEFAULT_US,
        },
        {
            key: formatDate(exampleDate, "dd/MM/'1992'"),
            value: DATE_FORMAT.MIXED,
        },
    ];
};
