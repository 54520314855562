import { createSlice } from '@reduxjs/toolkit';

import { getCampaignById, addSetCampaigns } from '@/app/campaigns/models/campaigns';
import { apiPatch, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { CampaignResource } from '@/app/campaigns/types';
import type { FaviconData } from '@/app/funnelSettings/favicon/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const updateFaviconSlice = createSlice({
    name: `funnelSettings/${NAME}/update`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = updateFaviconSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.updateFaviconReducer?.loading;

// === Thunks ======

// Helper function
const optimisticFaviconUpdate =
    (campaign: CampaignResource, favicon: FaviconData) => (dispatch) => {
        const updatedCampaign: CampaignResource = {
            ...campaign,
            attributes: {
                ...campaign.attributes,
                favicon: favicon.url,
            },
        };

        dispatch(addSetCampaigns({ [updatedCampaign.id]: updatedCampaign }));
    };

export const updateFavicon =
    (campaignId: string, favicon: FaviconData): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const campaign = getCampaignById(state, campaignId);

        try {
            dispatch(setLoading(true));

            // optimistically update campaign
            dispatch(optimisticFaviconUpdate(campaign, favicon));

            // Update in DB
            await apiPatch(`/campaigns/${campaignId}/favicon`, {
                data: { favicon: favicon.url },
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating favicon failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default updateFaviconSlice.reducer;
