import TiltScreen from '@/ui/components/TiltScreen';

export interface Props {
    title: string;
    headline: string;
    description: string;
    buttonText: string;
    onButtonClick: () => void;
}

const ErrorScreen = ({ title, headline, description, buttonText, onButtonClick }: Props) => {
    return (
        <div className="mx-auto flex max-w-screen-md flex-col space-y-12 md:flex-row md:space-x-12 md:space-y-0">
            <TiltScreen title={title} />

            <div className="flex flex-1 flex-col items-center justify-center text-center md:items-start md:text-left">
                <h1 className="text-2xl font-semibold">{headline}</h1>
                <p className="mt-2 text-sm text-gray-400">{description}</p>
                <button
                    type="button"
                    className="mt-6 rounded-lg bg-blue-500 px-6 py-3 text-white hover:bg-blue-600"
                    onClick={onButtonClick}
                >
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default ErrorScreen;
