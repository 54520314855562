export const createLaunchPayload = (domainId: string, slug: string) => {
    if ((!domainId && !slug) || !domainId) {
        return { data: { type: 'campaignVersion' } };
    }

    return {
        data: {
            type: 'campaignVersion',
            attributes: { slug: slug?.toLowerCase() ?? '' },
            relationships: {
                domain: {
                    data: {
                        id: domainId,
                        type: 'domain',
                    },
                },
            },
        },
    };
};
