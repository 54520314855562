import { reset as dunningReset } from './dunning';
import { reset as individualReset } from './individual';
import { reset as subscriptionReset } from './subscription';
import { reset as timeframeReset } from './timeframe';

import type { AppThunk } from '@/core/redux/types';

export const resetBillingState = (): AppThunk => (dispatch) => {
    dispatch(timeframeReset());
    dispatch(individualReset());
    dispatch(dunningReset());
    dispatch(subscriptionReset());
};
