import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';

import type { DomainResource } from '@/app/domains/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    publishDomains: {
        [campaignId: string]: DomainResource;
    };
    publishDomainHasFetched: {
        [campaignId: string]: boolean;
    };
    domainSelectVisible: boolean;
    domainSlugEditing: boolean;
    publishSlugs: {
        [campaignId: string]: string;
    };
}

const initialState: State = {
    publishDomains: EMPTY_OBJECT,
    publishDomainHasFetched: EMPTY_OBJECT,
    domainSelectVisible: false,
    domainSlugEditing: false,
    publishSlugs: EMPTY_OBJECT,
};

export const domainEditingSlice = createSlice({
    name: `${NAME}/domainEditing`,
    initialState,
    reducers: {
        setPublishDomain(
            state,
            action: PayloadAction<{ campaignId: string; domain: DomainResource }>,
        ) {
            return {
                ...state,
                publishDomains: {
                    ...state.publishDomains,
                    [action.payload.campaignId]: action.payload.domain,
                },
            };
        },
        setPublishDomainHasFetched(
            state,
            action: PayloadAction<{ campaignId: string; hasFetched: boolean }>,
        ) {
            return {
                ...state,
                publishDomainHasFetched: {
                    ...state.publishDomainHasFetched,
                    [action.payload.campaignId]: action.payload.hasFetched,
                },
            };
        },
        setDomainSelectVisible(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                domainSelectVisible: action.payload,
            };
        },
        setDomainSlugEditing(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                domainSlugEditing: action.payload,
            };
        },
        setPublishSlug(state, action: PayloadAction<{ campaignId: string; slug: string }>) {
            return {
                ...state,
                publishSlugs: {
                    ...state.publishSlugs,
                    [action.payload.campaignId]: action.payload.slug,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setPublishDomain,
    setPublishDomainHasFetched,
    setDomainSelectVisible,
    setDomainSlugEditing,
    setPublishSlug,
    reset,
} = domainEditingSlice.actions;

// === Selectors ======

export const getPublishDomainByCampaignId = (state: AppState, campaignId: string) =>
    get(state[NAME], `domainEditingReducer.publishDomains[${campaignId}]`);

export const getPublishDomainHasFetchedByCampaignId = (state: AppState, campaignId: string) =>
    get(state[NAME], `domainEditingReducer.publishDomainHasFetched[${campaignId}]`);

export const getDomainSelectVisible = (state: AppState) =>
    state[NAME]?.domainEditingReducer?.domainSelectVisible;

export const getDomainSlugEditing = (state: AppState) =>
    state[NAME]?.domainEditingReducer?.domainSlugEditing;

export const getPublishSlugByCampaignId = (state: AppState, campaignId: string) =>
    get(state[NAME], `domainEditingReducer.publishSlugs[${campaignId}]`);

// === Helper ======

const dataFetchSlugAvailability = async (
    campaignId: string,
    slug: string,
    publishDomainId: string,
) => {
    return await apiPost(`/campaigns/${campaignId}/launch/available`, {
        data: {
            type: 'campaignVersion',
            attributes: { slug: slug.toLowerCase() },
            relationships: {
                domain: {
                    data: {
                        id: publishDomainId,
                        type: 'domain',
                    },
                },
            },
        },
    });
};

// === Thunks ======
export const setDomainEditing = (editing: boolean): AppThunk => {
    return async (dispatch) => {
        dispatch(setDomainSelectVisible(editing));
        dispatch(setDomainSlugEditing(editing));
    };
};

export const fetchSlugAvailability = (campaignId: string, slug: string): AppThunk => {
    return async (_, getState): Promise<boolean> => {
        const publishDomain = getPublishDomainByCampaignId(getState(), campaignId);

        try {
            const response = await dataFetchSlugAvailability(campaignId, slug, publishDomain?.id);
            const { available } = getDataFromResponse(response) || false;

            return available;
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching slug availability failed:' });
        }
    };
};

export default domainEditingSlice.reducer;
