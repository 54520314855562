import { LINE_STYLES } from '@/app/editor/editor/constants';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import { Label } from '@/ui/components/_BlockEditFields/Label';

import { LineStyleItem } from './LineStyleItem';

import type { LineType } from '@/app/editor/blocks/types';

export interface Props {
    value: LineType;
    onChange: (style: LineType) => void;
    label?: string;
}

const LineStyle = ({ onChange, value, label }: Props) => {
    const handleChange = (style: LineType) => {
        onChange(style);
    };

    return (
        <FieldContainer>
            {label && <Label text={label} />}
            <div className="flex h-12 w-full items-center rounded-lg bg-gray-100">
                {LINE_STYLES.map((style) => (
                    <LineStyleItem
                        key={style}
                        id={style}
                        onClick={handleChange}
                        active={value === style}
                    />
                ))}
            </div>
        </FieldContainer>
    );
};

export default LineStyle;
