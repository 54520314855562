import { reset as resetColumnSelect } from './columnSelect';
import { reset as resetDateRangePicker } from './dateRangePicker';
import { reset as resetDropOffChart } from './dropOffChart';
import { reset as resetKpis } from './kpis';
import { reset as resetLeadExport } from './leadExport';
import { reset as resetSessions } from './sessions';
import { reset as resetVariantAnalytics } from './variantAnalytics';

import type { AppThunk } from '@/core/redux/types';

export const resetAnalyticsState = (): AppThunk => (dispatch) => {
    dispatch(resetColumnSelect());
    dispatch(resetDateRangePicker());
    dispatch(resetDropOffChart());
    dispatch(resetKpis());
    dispatch(resetLeadExport());
    dispatch(resetSessions());
    dispatch(resetVariantAnalytics());
};
