import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react';

export const useFloatingDropdown = (open: boolean) => {
    const { x, y, strategy, context, refs, placement } = useFloating({
        open,
        middleware: [
            offset(4),
            flip({
                fallbackPlacements: ['top-start', 'top-end'],
                padding: 10,
            }),
            size({
                apply({ rects, elements, availableHeight }) {
                    Object.assign(elements.floating.style, {
                        maxHeight: `${availableHeight}px`,
                        minWidth: `${rects.reference.width}px`,
                    });
                },
            }),
        ],
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
    });

    return { x, y, strategy, context, refs, placement };
};
