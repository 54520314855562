import { MODAL_OPTIONS } from '@/app/modals/constants';

import { change, submit } from 'redux-form';

import { BorderMenuItem } from '@/app/editor/blocks/types';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';

import { DEFAULT_BLOCK_CONFIG } from '../../../editor/constants';
import { getBlockChildComponentByType } from '../../models/blocks';

import type { BlockConfig } from '@/app/editor/blocks/types';

const config: BlockConfig = {
    ...DEFAULT_BLOCK_CONFIG,
    borderMenu: {
        ...DEFAULT_BLOCK_CONFIG.borderMenu,
        items: [BorderMenuItem.delete, BorderMenuItem.move, BorderMenuItem.duplicate],
        showDuplicateAsAdd: true,
        addTooltip: 'add-answer',
    },
    actions: {
        onDoubleClick: (block) => (dispatch, getState) => {
            const state = getState();
            const mediaChild = getBlockChildComponentByType(state, block?.id, 'media');

            const srcType = mediaChild?.attributes?.content?.srcType;
            const src = mediaChild?.attributes?.content?.src;
            const size = mediaChild?.attributes?.content?.size;

            const handleSave = async (url: string) => {
                // update edit form data and submit
                await dispatch(
                    change(
                        block?.id,
                        `additionalBlocks[${mediaChild?.id}].attributes.content.src`,
                        url,
                    ),
                );

                dispatch(submit(block?.id));
            };

            // Open Image Cropping Modal
            if (srcType === 'image') {
                dispatch(
                    showModal(
                        Modals.IMAGE_CROPPING,
                        {
                            src: src,
                            originalSize: size,
                            onSave: handleSave,
                            withSizeSlider: false,
                        },
                        MODAL_OPTIONS[Modals.IMAGE_CROPPING],
                    ),
                );
            }
        },
        getAdditionalBlocksConfig: () => {
            return [
                {
                    ommitedPaths: [
                        'attributes.content.icon',
                        'attributes.content.iconName',
                        'attributes.content.platform',
                    ],
                    target: 'child',
                    childType: 'media',
                },
            ];
        },
    },
    deletionReminder: true,
    highlightWithActiveChild: false,
};

export default config;
