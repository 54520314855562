import { HelpIcon } from '@/app/helpTooltips/components/HelpIcon';
import { cn } from '@/utils/cn';

import type { Props as HelpIconProps } from '@/app/helpTooltips/components/HelpIcon';

export interface Props {
    text: string;
    className?: string;
    helpTooltip?: HelpIconProps;
}

const Subheading = ({ text, className, helpTooltip }: Props) => {
    return (
        <div className={cn('flex items-center justify-between', className ? className : 'mt-6')}>
            <h5 className="text-xl font-medium">{text}</h5>
            {helpTooltip && <HelpIcon {...helpTooltip} />}
        </div>
    );
};

export default Subheading;
