import { UNIQUE_INPUTS } from '@/app/editor/blocks/constants';
import { NAME } from '@/app/editor/editor/constants';

import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useEffect, useState, useRef } from 'react';

import LoadingSpinner from '@/ui/components/LoadingSpinner';
import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import type { KeyboardEvent, ChangeEvent, MouseEvent } from 'react';
import type { Nullable } from 'types/generic';

export interface Props {
    value: string;
    onSave: (value: string) => void;
    loading?: boolean;
}

const validate = (value: string) => {
    const BERegex = /^[a-zA-Z][a-zA-Z0-9_-]*$/;

    if (!value) {
        return 'validation-required';
    }

    // check if value includes a space
    if (value.includes(' ')) {
        return 'validation-no-spaces';
    }

    // check if value is a number
    if (!isNaN(Number(value))) {
        return 'validation-is-number';
    }

    // check if value starts with a number
    if (!isNaN(Number(value[0]))) {
        return 'validation-starts-with-a-number';
    }

    // check if value includes a .
    if (value.includes('.')) {
        return 'validation-no-dots';
    }

    // check if value contains a reserved word
    if (UNIQUE_INPUTS.includes(value.toLowerCase())) {
        return 'validation-unique';
    }

    // check if value includes a special character
    if (!BERegex.test(value)) {
        return 'validation-invalid-characters';
    }

    return 'valid';
};

type ValidationError = ReturnType<typeof validate>;

const EditInput = ({ value, onSave, loading = false }: Props) => {
    const { t } = useTranslation(NAME);
    const [editing, setEditing] = useState(false);
    const [initialValue, setInitialValue] = useState(value);
    const [updatedValue, setUpdatedValue] = useState(value);
    const [validationError, setValidationError] = useState<Nullable<ValidationError>>(null);

    const textInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setUpdatedValue(value);
    }, [value]);

    useEffect(() => {
        // validate
        const validationResult = validate(updatedValue);

        if (validationResult === 'valid') {
            setValidationError(null);
        } else {
            setValidationError(validationResult);
        }
    }, [updatedValue]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUpdatedValue(event.target.value);
    };

    const reset = () => {
        setEditing(false);
        setUpdatedValue(initialValue);
        setValidationError(null);
    };

    const handleEdit = () => {
        if (loading) {
            return;
        }

        setEditing(true);
        textInput.current?.focus();
    };

    const handleSave = () => {
        if (onSave && !validationError && updatedValue) {
            setValidationError(null);
            onSave(updatedValue);

            setEditing(false);
            setUpdatedValue(updatedValue);
            setInitialValue(updatedValue);
        } else {
            reset();
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            reset();
        }

        if (event.key === 'Enter') {
            handleSave();
        }
    };

    const getTooltipContent = () => {
        if (validationError) {
            return t(validationError);
        }

        return t('common:save');
    };

    const getEditButtonAction = () => {
        if (loading) {
            return undefined;
        }

        if (!editing) {
            return handleEdit;
        }

        if (!validationError) {
            return handleSave;
        }

        return undefined;
    };

    const selectAll = (event: MouseEvent<HTMLInputElement>) => {
        if (!editing) {
            event.currentTarget.select();
        }
    };

    let tooltipContent = t('common:edit');

    if (editing) {
        tooltipContent = getTooltipContent();
    }

    return (
        <div className="flex h-12 items-center rounded-lg bg-gray-100">
            <div className="ml-4 w-5 cursor-default text-center font-medium text-gray-500">ID</div>

            <input
                value={updatedValue}
                className={cn('flex-1 truncate bg-transparent pl-3 text-sm outline-none', {
                    'text-gray-500': !validationError,
                    'text-amber-500': editing && validationError,
                    'cursor-pointer': !editing,
                    'cursor-text': editing,
                    'cursor-not-allowed': loading,
                })}
                placeholder={loading ? t('common:loading') : ''}
                readOnly={!editing}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onClick={selectAll}
                ref={textInput}
            />

            {loading ? (
                <LoadingSpinner size="tiny" className="mx-2.5 text-gray-400" />
            ) : (
                <Tooltip content={tooltipContent} offsetValue={-8} placement="top-end">
                    <button
                        className="bump flex h-full cursor-pointer items-center px-4"
                        onClick={getEditButtonAction()}
                    >
                        {editing ? (
                            validationError ? (
                                <ExclamationTriangleIcon className="size-5 text-amber-500" />
                            ) : (
                                <CheckCircleIcon className="size-5 text-gray-400 hover:text-blue-500" />
                            )
                        ) : (
                            <PencilSquareIcon className="size-5 text-gray-400 hover:text-blue-500" />
                        )}
                    </button>
                </Tooltip>
            )}
        </div>
    );
};

export default EditInput;
