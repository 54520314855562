import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'react-i18next';
import { change, Field, getFormValues, reduxForm } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import { getIsImgixImage } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getActiveIndexBySliderId } from '@/app/editor/blocks/models/slider';
import { Align } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Align';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import { CropButton } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/CropButton';
import { IconToggleButton } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/IconToggleButton';
import { Rating } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Rating';
import Slides from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Slides';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({ blockId, handleSubmit, activeTheme }) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const { isInColumn } = useLayoutInfo(blockId);

    const activeImageIndex = useAppSelector((state) => getActiveIndexBySliderId(state, blockId));
    const formValues = useAppSelector((state) => getFormValues(blockId)(state) as BlockResource);
    const images = formValues?.attributes?.content?.images || [];
    const showStars = formValues?.attributes?.content?.showStars;
    const activeImage = images[activeImageIndex];

    const handleCropSave = (src: string) => {
        dispatch(change(blockId, `attributes.content.images[${activeImageIndex}]`, src));

        setTimeout(handleSubmit);
    };

    return (
        <>
            <div className="grid w-full grid-cols-3 gap-x-2">
                <Field
                    name="attributes.content.showImages"
                    component={IconToggleButton}
                    submit={handleSubmit}
                    icon="avatars"
                    tooltip={t('show-avatars')}
                />

                <Field
                    name="attributes.content.showStars"
                    component={IconToggleButton}
                    submit={handleSubmit}
                    icon="star"
                    tooltip={t('show-stars')}
                />

                <CropButton
                    src={images[activeImageIndex]}
                    onSave={handleCropSave}
                    size="xLarge"
                    disabled={!getIsImgixImage(activeImage)}
                    showTooltipWhenDisabled
                    overridenTooltip={
                        !getIsImgixImage(activeImage) ? t('brandfetch-crop-warning') : undefined
                    }
                />
            </div>

            {showStars && (
                <Field name="attributes.content.rating" component={Rating} submit={handleSubmit} />
            )}

            <div className="grid w-full grid-cols-3 gap-x-2">
                <Field
                    name="attributes.content.align"
                    component={Align}
                    submit={handleSubmit}
                    tooltip={t('align')}
                />

                <Field
                    name="attributes.content.color"
                    tooltip={t('star-color')}
                    component={Color}
                    expand="right"
                    submit={handleSubmit}
                />
            </div>

            <Subheading text="Avatars" />

            <Field
                name="attributes.content.images"
                component={Slides}
                submit={handleSubmit}
                blockId={blockId}
                maxCount={4}
            />

            <BoxSettings
                blockId={blockId}
                handleSubmit={handleSubmit}
                activeTheme={activeTheme}
                hiddenColor={isInColumn}
            />
        </>
    );
};

Form.displayName = 'ReviewsEditForm';

export default reduxForm({})(Form);
