import { FEATURE_IDS } from '@/app/billing/constants';

import { arrayMove } from '@dnd-kit/sortable';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import Router from 'next/router';
import qs from 'query-string';

import { navigateToBillingAndPromoteFeature } from '@/app/billing/helpers/navigateToBillingAndPromoteFeature';
import { getCompany } from '@/app/company/models/company';
import { reset as resetSetUp } from '@/app/workspaces/models/setup';
import { RequestState } from '@/app/workspaces/types';
import { apiDelete, apiPatch, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, resourceArrayToObject } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { CREATE_WORKSPACE_PATH, NAME } from '../constants';
import {
    getWorkspaceByDomainRequest,
    getWorkspaceByIdRequest,
    getWorkspacesRequest,
    updateWorkspaceOrder,
} from '../helpers/apis';
import { getIsDefaultWorkspace } from '../helpers/utils';
import { getWorkspaceInfoFormData } from '../helpers/utils';

import type { NewWorkspaceFormData, WorkspaceResource, Workspaces } from '@/app/workspaces/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

type RequestType = 'workspaceById' | 'deleteWorkspace' | 'getWorkspaces';

interface State {
    activeWorkspaceId?: string;
    workspaces: Workspaces;
    requestState: Partial<Record<RequestType, RequestState>>;
    updating: boolean;
    deleting: boolean;
    isPreview: boolean;
    workspaceOrder: string[];
}

const initialState: State = {
    activeWorkspaceId: undefined,
    workspaces: EMPTY_OBJECT,
    requestState: {
        workspaceById: RequestState.Idle,
        deleteWorkspace: RequestState.Idle,
        getWorkspaces: RequestState.Idle,
    },
    updating: false,
    deleting: false,
    isPreview: false,
    workspaceOrder: [],
};

export const workspaceSlice = createSlice({
    name: `${NAME}/workspaces`,
    initialState,
    reducers: {
        setActiveWorkspaceId(state, action: PayloadAction<undefined | string>) {
            state.activeWorkspaceId = action.payload;
        },
        setWorkspace(state, action: PayloadAction<WorkspaceResource>) {
            state.workspaces[action.payload.id] = action.payload;
        },
        removeWorkspace(state, action: PayloadAction<string>) {
            const workspaces = cloneDeep(state.workspaces);
            delete workspaces[action.payload];

            state.workspaces = workspaces;
        },
        setWorkspaces(state, action: PayloadAction<{ [workspaceId: string]: WorkspaceResource }>) {
            state.workspaces = action.payload;
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        setUpdating(state, action: PayloadAction<boolean>) {
            state.updating = action.payload;
        },
        setDeleting(state, action: PayloadAction<boolean>) {
            state.deleting = action.payload;
        },
        setIsPreview(state, action: PayloadAction<boolean>) {
            state.isPreview = action.payload;
        },
        setWorkspaceOrder(state, action: PayloadAction<State['workspaceOrder']>) {
            state.workspaceOrder = action.payload;
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setActiveWorkspaceId,
    setWorkspace,
    removeWorkspace,
    setWorkspaces,
    setRequestState,
    setUpdating,
    setDeleting,
    setIsPreview,
    setWorkspaceOrder,
    reset,
} = workspaceSlice.actions;

// === Selectors ======

export const getActiveWorkspaceId = (state: AppState) =>
    state[NAME]?.workspaceReducer?.activeWorkspaceId;

export const getWorkspaces = (state: AppState) => state[NAME]?.workspaceReducer?.workspaces;

export const getHasCustomWorkspaces = createSelector(
    getWorkspaces,
    (workspaces) => Object.keys(workspaces).length > 1,
);

export const getWorkspaceById = (state: AppState, workspaceId: string) =>
    state[NAME]?.workspaceReducer?.workspaces[workspaceId] || (EMPTY_OBJECT as WorkspaceResource);

export const getWorkspaceByDomain = (workspaceDomain: string) =>
    createSelector(getWorkspaces, (workspaces): WorkspaceResource => {
        const workspaceId = Object.keys(workspaces)?.find(
            (workspaceId) =>
                workspaces[workspaceId]?.attributes?.perspectiveDomain === workspaceDomain,
        );

        return workspaces?.[workspaceId] || (EMPTY_OBJECT as WorkspaceResource);
    });

export const getActiveWorkspace = (state: AppState) =>
    createSelector(getActiveWorkspaceId, (activeWorkspaceId): WorkspaceResource => {
        return getWorkspaceById(state, activeWorkspaceId);
    })(state);

export const getDefaultWorkspace = createSelector(
    getWorkspaces,
    (workspaces): WorkspaceResource => {
        const workspaceId = Object.keys(workspaces).find(
            (workspaceId) => workspaces?.[workspaceId]?.attributes?.default,
        ) as string;

        return workspaces?.[workspaceId];
    },
);

export const getCustomWorkspaceIds = createSelector(getWorkspaces, (workspaces) => {
    return Object.keys(workspaces).filter(
        (workspaceId) => workspaces[workspaceId] && !getIsDefaultWorkspace(workspaces[workspaceId]),
    );
});

export const getRequestState = (state: AppState) => state[NAME]?.workspaceReducer?.requestState;

export const getUpdating = (state: AppState) => state[NAME]?.workspaceReducer?.updating;

export const getDeleting = (state: AppState) => state[NAME]?.workspaceReducer?.deleting;

export const getIsPreview = (state: AppState) => state[NAME]?.workspaceReducer?.isPreview;

export const getWorkspaceOrder = (state: AppState) => state[NAME]?.workspaceReducer?.workspaceOrder;

export const getWorkspaceLimit = (state: AppState) => {
    const company = getCompany(state);

    const quantities = company?.attributes?.limitations?.quantities?.workspaces ?? 0;
    const prepaidQuantities = company?.attributes?.limitations?.prepaidQuantities?.workspaces ?? 0;

    return quantities + prepaidQuantities;
};

// === Thunks ======

export const fetchAllWorkspaces = (): AppThunk => async (dispatch) => {
    dispatch(
        setRequestState({
            getWorkspaces: RequestState.InProgress,
        }),
    );

    try {
        const workspaces = getDataFromResponse(await getWorkspacesRequest());

        if (!workspaces) {
            return;
        }

        dispatch(setWorkspaces(resourceArrayToObject(workspaces)));
        dispatch(setWorkspaceOrder(workspaces.map((item) => item.id)));
        dispatch(
            setRequestState({
                getWorkspaces: RequestState.Success,
            }),
        );
    } catch (err) {
        handleRuntimeError(err, {
            debugMessage: 'fetching all workspaces failed:',
        });

        dispatch(
            setRequestState({
                getWorkspaces: RequestState.Error,
            }),
        );
    }
};

export const fetchWorkspaceById =
    (workspaceId: string): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                workspaceById: RequestState.InProgress,
            }),
        );

        try {
            const response = await getWorkspaceByIdRequest(workspaceId);

            dispatch(setWorkspace(getDataFromResponse(response)));
            dispatch(
                setRequestState({
                    workspaceById: RequestState.Success,
                }),
            );
        } catch (err) {
            dispatch(
                setRequestState({
                    workspaceById: RequestState.Error,
                }),
            );

            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'fetching workspace failed:',
            });
        }
    };

export const dataFetchWorkspaceByDomain = async (domain: string) => {
    const response = await getWorkspaceByDomainRequest(domain);

    return getDataFromResponse(response);
};

export const updateWorkspace =
    ({
        workspaceId,
        values,
    }: {
        workspaceId: string;
        values: NewWorkspaceFormData;
    }): AppThunk<Promise<{ success: boolean }>> =>
    async (dispatch) => {
        dispatch(setUpdating(true));

        try {
            const formData = getWorkspaceInfoFormData(values);
            const response = await apiPatch(`/workspaces/${workspaceId}`, formData);
            const updatedWorkspace = getDataFromResponse(response);

            dispatch(setWorkspace(updatedWorkspace));

            return { success: true };
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating workspace failed:' });

            return { success: false };
        } finally {
            dispatch(setUpdating(false));
        }
    };

export const deleteWorkspace =
    ({ workspaceId }: { workspaceId: string }): AppThunk<Promise<{ success: boolean }>> =>
    async (dispatch, getState) => {
        const workspaces = getWorkspaces(getState());

        if (getIsDefaultWorkspace(workspaces[workspaceId])) {
            return;
        }

        dispatch(setDeleting(true));

        try {
            await apiDelete(`/workspaces/${workspaceId}`);
            dispatch(removeWorkspace(workspaceId));
            await dispatch(fetchAllWorkspaces());

            return { success: true };
        } catch (err) {
            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'deleting workspace failed:',
            });

            return { success: false };
        } finally {
            dispatch(setDeleting(false));
        }
    };

export const updateOrder =
    (activeIndex: number, overIndex: number): AppThunk =>
    async (dispatch, getState) => {
        const oldOrder = getWorkspaceOrder(getState());
        const newOrder = arrayMove([...oldOrder], activeIndex, overIndex);

        dispatch(setWorkspaceOrder(newOrder));

        try {
            updateWorkspaceOrder(newOrder);
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'error reordering workspaces' });
            dispatch(setWorkspaceOrder([...oldOrder]));
        }
    };

export const navigateToAddNewWorkspacePage =
    (canAddNewWorkspace: boolean, query?: { funnelId: string }): AppThunk =>
    async (dispatch) => {
        if (!canAddNewWorkspace) {
            navigateToBillingAndPromoteFeature(FEATURE_IDS.workspacesBeta);

            return;
        }

        const stringifiedQuery = query ? `?${qs.stringify(query)}` : '';

        dispatch(resetSetUp());
        Router.push(`${CREATE_WORKSPACE_PATH}${stringifiedQuery}`);
    };

export default workspaceSlice.reducer;
