import type {
    EditorEngineEventListener,
    EditorEngineEventListenerMap,
    EditorEngineEventType,
} from '@/app/editor/engine/core/types';
import type { MutableRefObject } from 'react';

export const getRemoveListener = ({
    listeners,
}: {
    listeners: MutableRefObject<EditorEngineEventListenerMap>;
}) => {
    return <TEventType extends EditorEngineEventType>({
        name,
        listener,
    }: {
        name: TEventType;
        listener: EditorEngineEventListener<TEventType>;
    }) => {
        listeners = {
            ...listeners,
            [name]: (listeners.current[name] ?? []).filter(
                (storedListener) => storedListener !== listener,
            ),
        };
    };
};
