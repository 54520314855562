import { SIMULATED_GLOBAL_VARIABLES } from '@/app/editor/blocks/components/Embed/constants';
import { NORMALIZE_CSS } from '@/app/editor/blocks/constants';

import {
    receiveIframeDimensionsUpdates,
    sendSetBodyContentMessage,
    sendSetHeadContentMessage,
} from '@perspective-software/cross-origin-html-embed';
import unescape from 'lodash/unescape';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Box, { defaultBox } from '@/app/editor/blocks/components/_wrapper/Box';
import {
    createUniqueCrossOriginIframeSandboxUrl,
    interpolateScriptVariables,
} from '@/app/editor/blocks/components/Embed/helper';
import Placeholder from '@/app/editor/blocks/components/Embed/Placeholder';
import { providerValidatorMap } from '@/app/editor/blocks/components/Embed/validators';
import { getTrackingPropertiesByCampaignId } from '@/app/editor/blocks/models/personalization';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import type { EmbedProps } from '@/app/editor/blocks/components/Embed/Component';

export const CustomHtmlEmbed = ({
    provider,
    head: rawHead,
    body: rawBody,
    artBoardIndex,
    box = defaultBox,
    isDragged,
    blockId,
    enforceCenteredCustomHtmlContent,
}: EmbedProps) => {
    const { locale } = useRouter();
    const embedBorderRadiusClass = useBorderRadius('embed');
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [iframeHeight, setIframeHeight] = useState(0);

    const trackingProperties = useAppSelector((state) =>
        getTrackingPropertiesByCampaignId(state, getCampaignIdFromRouter()),
    );

    const iframeUrl = useMemo(() => {
        return createUniqueCrossOriginIframeSandboxUrl(`${getCampaignIdFromRouter()}-${blockId}`);
    }, [blockId]);

    const codeValidationResult = useMemo(() => {
        return providerValidatorMap[provider](rawHead, rawBody);
    }, [provider, rawHead, rawBody]);

    // Now we construct the HTML snippets to be injected.
    // We alter/wrap the content of the props "rawHead" and "rawBody" to
    // normalize CSS, center the contents, etc.
    const headHtmlToInject = useMemo(() => {
        if (!codeValidationResult.valid) {
            return '';
        }

        return interpolateScriptVariables(
            `
            ${NORMALIZE_CSS}
            <style>
                body {
                    width: 100%;
                    ${
                        enforceCenteredCustomHtmlContent
                            ? 'display: flex; flex-direction: column; justify-content: center; align-items: center;'
                            : ''
                    }
                }
                style, script, title {
                    display: none !important;
                }
                iframe {
                    max-width: 100% !important;
                }
            </style>
            ${unescape(rawHead)}
        `,
            trackingProperties,
            SIMULATED_GLOBAL_VARIABLES,
            locale,
        );
    }, [
        rawHead,
        codeValidationResult,
        enforceCenteredCustomHtmlContent,
        locale,
        trackingProperties,
    ]);

    const bodyHtmlToInject = useMemo(() => {
        if (!codeValidationResult.valid) {
            return '';
        }

        return interpolateScriptVariables(
            unescape(rawBody),
            trackingProperties,
            SIMULATED_GLOBAL_VARIABLES,
            locale,
        );
    }, [rawBody, codeValidationResult, locale, trackingProperties]);

    // We listen for size updates to size the iframe correctly.
    useEffect(() => {
        return receiveIframeDimensionsUpdates(iframeUrl.origin, ({ data }) => {
            setIframeHeight(data.documentElementHeight);
        });
    }, [iframeUrl]);

    const injectCode = useCallback(() => {
        if (iframeRef.current) {
            sendSetHeadContentMessage(iframeRef.current, headHtmlToInject);
            sendSetBodyContentMessage(iframeRef.current, bodyHtmlToInject);
        }
    }, [headHtmlToInject, bodyHtmlToInject]);

    // This is used as the "key" for the iframe element. An increment
    // will cause React to create a fresh iframe element. This mechanism is used
    // to create a fresh iframe element for every HTML code version.
    const [iframeVersion, setIframeVersion] = useState(0);

    // Re-inject the code / create new iframe element
    // whenever injectCode respectively the html changes.
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIframeVersion((old) => old + 1);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [injectCode]);

    if (!codeValidationResult.valid) {
        return <Placeholder box={box} isDragged={isDragged} provider={provider} />;
    }

    return (
        <Box box={box} isDragged={isDragged} artBoardIndex={artBoardIndex}>
            <iframe
                key={iframeVersion}
                ref={iframeRef}
                src={iframeUrl.href}
                onLoad={injectCode}
                className={cn('pointer-events-none w-full', embedBorderRadiusClass)}
                style={{ height: `${iframeHeight}px` }}
            />
        </Box>
    );
};
