import formatDate from 'date-fns/format';
import formatDateIntl from 'date-fns/intlFormat';
import { de, enUS } from 'date-fns/locale';
import isDate from 'lodash/isDate';
import { useRouter } from 'next/router';

import { EMPTY_OBJECT } from '../empty';

import type { Language } from 'types/generic';

export const localeMap = {
    de: de,
    en: enUS,
};

type DateParam = Date | string | number;
type Options = {
    locale: Language;
};

export const useDateFormat = (date: number | string | Date, format = 'PPP') => {
    const { locale } = useRouter();
    const dateToFormat = isDate(date) ? date : new Date(date);

    return formatDate(dateToFormat as Date, format, { locale: localeMap[locale as Language] });
};

export const useDateFormatIntl = (
    date: DateParam | undefined,
    format = EMPTY_OBJECT,
    options?: Options,
) => {
    const router = useRouter();

    if (!date) {
        return;
    }

    const dateToFormat = isDate(date) ? date : new Date(date);
    const locale = options?.locale || (router.locale as Language);

    return formatDateIntl(dateToFormat as Date, format, {
        locale: localeMap[locale]?.code,
    });
};
