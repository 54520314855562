import { showWelcomeLog } from '@/core/tracking/helper';
import { initSegment, identifySegmentUser, trackSegment } from '@/core/tracking/segment';
import { identifySentryUser } from '@/core/tracking/sentry';
import { infoLog } from '@/utils/debugLogs';

import type { SubscriptionResource } from '@/app/billing/types';
import type { CompanyResource } from '@/app/company/types';
import type { UserResource } from '@/app/user/types';
import type { AllFeatureFlags } from '@/core/loli-feature-flags/types';
import type { TrackingObject } from '@/core/tracking/types';
import type { Language } from 'types/generic';

export const initAllTracking = () => {
    showWelcomeLog();
    initSegment();
};

export const identifyUser = (
    user: UserResource,
    company: CompanyResource,
    subscription: SubscriptionResource,
    language: Language,
    allFeatureFlags: AllFeatureFlags,
) => {
    identifySegmentUser(user, company, subscription, language, allFeatureFlags);
    identifySentryUser(user, company);
};

export const track = (eventName: string, trackingObj: TrackingObject) => {
    if (
        process.env.NODE_ENV !== 'production' ||
        process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
    ) {
        infoLog(`Track: "${eventName}" with ${JSON.stringify(trackingObj)}`);

        return;
    }

    trackSegment(eventName, trackingObj);
};
