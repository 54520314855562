import { createSelector, createSlice } from '@reduxjs/toolkit';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';

import { handleRuntimeError } from '@/core/api';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import {
    addContacts,
    fetchContactsResource,
    getSearch,
    removeContacts,
    setSearching,
} from './contacts';
import { getCRMFilters } from './filters';
import { getSchema, updateSchemaPropertyOption } from './schema';
import {
    EMPTY_PAGINATION,
    KANBAN_DEFAULT_PAGINATION,
    KANBAN_DEFAULT_SORTING,
    NAME,
} from '../constants';
import { getStatusSchema } from '../helpers';
import { UniqueFieldName } from '../types';

import type { ContactResource, Pagination, SchemaResource } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface KanbanState {
    fetching: boolean;
    contactIds: { [status: string]: string[] };
    hasFinishedFetching: boolean;
    kanbanColumnOrder: string[];
    hiddenColumns: string[];
    pagination: {
        [status: string]: Pagination;
    };
    loadingMore: {
        [status: string]: boolean;
    };
}

const initialState: KanbanState = {
    fetching: false,
    contactIds: EMPTY_OBJECT,
    hasFinishedFetching: false,
    kanbanColumnOrder: EMPTY_ARRAY,
    hiddenColumns: EMPTY_ARRAY,
    pagination: EMPTY_OBJECT,
    loadingMore: EMPTY_OBJECT,
};

const kanbanSlice = createSlice({
    name: `${NAME}/kanban`,
    initialState,
    reducers: {
        setFetching(state, action: PayloadAction<boolean>) {
            state.fetching = action.payload;
        },
        setContactIds(state, action: PayloadAction<KanbanState['contactIds']>) {
            state.contactIds = action.payload;
        },
        addContactIdsForStatus(
            state,
            action: PayloadAction<{ status: string; contactIds: string[] }>,
        ) {
            const contactIdsWithoutDuplicates = difference(
                action.payload.contactIds,
                state.contactIds[action.payload.status] || EMPTY_ARRAY,
            );

            state.contactIds[action.payload.status] = [
                ...(state.contactIds[action.payload.status] || EMPTY_ARRAY),
                ...contactIdsWithoutDuplicates,
            ];
        },
        setContactIdsForStatus(
            state,
            action: PayloadAction<{ status: string; contactIds: string[] }>,
        ) {
            state.contactIds[action.payload.status] = action.payload.contactIds;
        },
        removeContactIdsForStatus(
            state,
            action: PayloadAction<{ status: string; contactIds: string[] }>,
        ) {
            const statusContacts = state.contactIds?.[action.payload.status];

            state.contactIds[action.payload.status] = difference(
                statusContacts,
                action.payload.contactIds,
            );
        },
        setHasFinishedFetching(state, action: PayloadAction<boolean>) {
            state.hasFinishedFetching = action.payload;
        },
        setKanbanColumnOrder(state, action: PayloadAction<string[]>) {
            state.kanbanColumnOrder = action.payload;
        },
        setHiddenColumns(state, action: PayloadAction<string[]>) {
            state.hiddenColumns = action.payload;
        },
        setPagination(state, action: PayloadAction<KanbanState['pagination']>) {
            state.pagination = action.payload;
        },
        setPaginationForStatus(
            state,
            action: PayloadAction<{
                status: string;
                pagination: Pagination;
            }>,
        ) {
            state.pagination = {
                ...state.pagination,
                [action.payload.status]: action.payload.pagination,
            };
        },
        setLoadingMoreForStatus(
            state,
            action: PayloadAction<{
                status: string;
                loadingMore: boolean;
            }>,
        ) {
            state.loadingMore = {
                ...state.loadingMore,
                [action.payload.status]: action.payload.loadingMore,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======
export const {
    setFetching,
    setContactIds,
    addContactIdsForStatus,
    setContactIdsForStatus,
    removeContactIdsForStatus,
    setHasFinishedFetching,
    setKanbanColumnOrder,
    setHiddenColumns,
    setPagination,
    setPaginationForStatus,
    setLoadingMoreForStatus,
    reset,
} = kanbanSlice.actions;

// === Selectors ======
export const getFetching = (state: AppState) => state[NAME].kanbanReducer.fetching;
export const getKanbanContactIds = (state: AppState) =>
    state[NAME].kanbanReducer.contactIds || EMPTY_OBJECT;
export const getContactIdsForStatus = (status: string) => (state: AppState) =>
    state[NAME].kanbanReducer.contactIds[status] || EMPTY_ARRAY;
export const getHasFinishedFetching = (state: AppState) =>
    state[NAME].kanbanReducer.hasFinishedFetching;
export const getKanbanColumnOrder = (state: AppState) =>
    state[NAME].kanbanReducer.kanbanColumnOrder;
export const getHiddenColumns = (state: AppState) => state[NAME].kanbanReducer.hiddenColumns;
export const getKanbanPagination = (state: AppState) => state[NAME].kanbanReducer.pagination;
export const getPaginationForStatus = (state: AppState, status: string) =>
    state[NAME].kanbanReducer.pagination?.[status] || (KANBAN_DEFAULT_PAGINATION as Pagination);

// TODO: Not used (yet?)
export const getLoadingMoreForStatus = (state: AppState, status: string) =>
    state[NAME].kanbanReducer.loadingMore?.[status];

export const getStatusTagColor = (state: AppState, statusKey: string) =>
    createSelector(getSchema, (schema) => {
        const statusSchema = getStatusSchema(schema);
        const statusOptions = statusSchema?.options;

        return statusOptions.find((option) => option?.value === statusKey)?.color;
    })(state);

// === Thunks ======
export const dataFetchContactsByStatus =
    ({
        campaignId,
        status,
        page,
        limit,
    }: {
        campaignId: string;
        status: string;
        page?: number;
        limit?: number;
    }): AppThunk<
        Promise<{ contacts: { [contactId: string]: ContactResource }; pagination: Pagination }>
    > =>
    async (dispatch, getState) => {
        const search = getSearch(getState());
        const crmFilters = getCRMFilters(getState());

        try {
            const statusQuery = {
                kanbanColumnStatusValue: status,
                searchValue: search ? [search, search, search] : undefined,
                searchField: search ? ['email', 'person', 'phone'] : undefined,
                page: page ?? KANBAN_DEFAULT_PAGINATION.page,
                limit: limit ?? KANBAN_DEFAULT_PAGINATION.limit,
                ...KANBAN_DEFAULT_SORTING,
            };

            const statusFilter = crmFilters.find((filter) => filter.fieldName === 'status');

            // If we are filtering by status and not asking for one of the filtered status, then
            // we skip the request and return no contacts
            if (statusFilter && !statusFilter.values.includes(status)) {
                return {
                    contacts: {},
                    pagination: EMPTY_PAGINATION,
                };
            }

            const { contacts, pagination } = await dispatch(
                fetchContactsResource(
                    `/contacts/list?campaignId=${campaignId}&${qs.stringify(statusQuery)}`,
                ),
            );

            return { contacts, pagination };
        } catch (err) {
            handleRuntimeError(err, {
                message: `Failed to fetch contacts by status where status is ${status}`,
            });
        }
    };

export const fetchContactsByStatus =
    (campaignId: string, statusList: string[]): AppThunk =>
    async (dispatch) => {
        if (!statusList?.length) {
            return;
        }

        try {
            const promisesByStatus = statusList?.map((status) =>
                dispatch(dataFetchContactsByStatus({ campaignId, status })),
            );
            const promisesResult = await Promise.all(promisesByStatus);

            promisesResult.forEach((res, index) => {
                const pagination = res?.pagination;
                const contacts = res?.contacts;
                const status = statusList?.[index];

                // Save contacts in state
                // Note that contacts can be `undefined` when request fails
                if (contacts) {
                    dispatch(addContacts(contacts));
                    dispatch(setContactIdsForStatus({ contactIds: Object.keys(contacts), status }));
                    dispatch(
                        setPaginationForStatus({
                            pagination,
                            status,
                        }),
                    );
                }
            });
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to fetch contacts by status' });
        }
    };

export const initKanbanData =
    (campaignId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setFetching(true));
        dispatch(setHasFinishedFetching(false));

        const state = getState();
        const schema = getSchema(state);

        try {
            const statusSchema = getStatusSchema(schema);
            const statusOptions = statusSchema?.options;
            const flatStatusList = statusOptions?.map((option) => option?.value);
            const flatHiddenStatusList = statusOptions
                ?.filter((option) => !(option.visible ?? true)) // default to true
                ?.map((option) => option?.value);

            dispatch(setKanbanColumnOrder(flatStatusList || EMPTY_ARRAY));
            dispatch(setHiddenColumns(flatHiddenStatusList || EMPTY_ARRAY));

            // Reset contacts & pagination before new fetch
            dispatch(setContactIds(EMPTY_OBJECT));
            dispatch(removeContacts({ shouldKeepInstead: true, selectedIds: EMPTY_ARRAY }));
            dispatch(setPagination(EMPTY_OBJECT));

            // Fetch contacts
            await dispatch(fetchContactsByStatus(campaignId, flatStatusList));
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to initialize Kanban data' });
        } finally {
            dispatch(setFetching(false));
            dispatch(setSearching(false));
            dispatch(setHasFinishedFetching(true));
        }
    };

export const fetchPaginationForStatus =
    (campaignId: string, status: string): AppThunk =>
    async (dispatch) => {
        try {
            const { pagination } = await dispatch(
                dataFetchContactsByStatus({ campaignId, status }),
            );

            dispatch(
                setPaginationForStatus({
                    pagination,
                    status,
                }),
            );
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to fetch pagination for status' });
        }
    };

export const fetchNextContactsPageByStatus =
    (campaignId: string, status: string) => (): AppThunk => async (dispatch, getState) => {
        dispatch(setLoadingMoreForStatus({ status, loadingMore: true }));

        try {
            const state = getState();
            const { next: nextPageUrl, page, limit } = getPaginationForStatus(state, status);
            const localContactIdsLength = getContactIdsForStatus(status)(state)?.length;
            const maxLocalContacts = (page + 1) * limit;

            const shouldRefetchCurrentPage = maxLocalContacts > localContactIdsLength;

            if (!nextPageUrl) {
                return;
            }

            // Refetch current page if there are missing items
            if (shouldRefetchCurrentPage) {
                const { contacts: currContacts } = await dispatch(
                    dataFetchContactsByStatus({ campaignId, status, page, limit }),
                );

                dispatch(addContacts(currContacts));
                dispatch(addContactIdsForStatus({ contactIds: Object.keys(currContacts), status }));
            }

            const { contacts: nextContacts, pagination: nextPagination } = await dispatch(
                fetchContactsResource(nextPageUrl),
            );

            dispatch(addContacts(nextContacts));
            dispatch(addContactIdsForStatus({ contactIds: Object.keys(nextContacts), status }));
            dispatch(setPaginationForStatus({ pagination: nextPagination, status }));
        } catch (err) {
            handleRuntimeError(err, {
                message: `Failed to fetch next contacts page for status ${status}`,
            });
        } finally {
            dispatch(setLoadingMoreForStatus({ status, loadingMore: false }));
        }
    };

export const handleKanbanContactStatusChange =
    ({
        contactId,
        originalStatus,
        newStatus,
    }: {
        contactId: string;
        originalStatus: string;
        newStatus: string;
    }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();

        const kanbanContactIds = getKanbanContactIds(state);
        const paginationOriginalStatus = getPaginationForStatus(state, originalStatus);
        const paginationNewStatus = getPaginationForStatus(state, newStatus);

        const originalStatusIsValid = !isEmpty(paginationOriginalStatus);

        if (newStatus !== originalStatus) {
            // Fail gracefully if original status is outdated
            if (originalStatusIsValid) {
                // Optimistically update count
                dispatch(
                    setPaginationForStatus({
                        status: originalStatus,
                        pagination: {
                            ...paginationOriginalStatus,
                            count: paginationOriginalStatus.count - 1,
                        },
                    }),
                );
            }

            dispatch(
                setPaginationForStatus({
                    status: newStatus,
                    pagination: {
                        ...paginationNewStatus,
                        count: paginationNewStatus.count + 1,
                    },
                }),
            );

            const updatedOriginalStatusColumn = difference(
                kanbanContactIds[originalStatus] || EMPTY_ARRAY,
                [contactId],
            );

            const updatedNewStatusColumn = [
                contactId,
                ...(kanbanContactIds[newStatus] || EMPTY_ARRAY),
            ];

            dispatch(
                setContactIdsForStatus({
                    status: originalStatus,
                    contactIds: updatedOriginalStatusColumn,
                }),
            );

            dispatch(
                setContactIdsForStatus({
                    status: newStatus,
                    contactIds: updatedNewStatusColumn,
                }),
            );
        }
    };

export const resetKanbanColumnsAndPagination =
    ({ campaignId, schema }: { campaignId: string; schema: SchemaResource }): AppThunk =>
    async (dispatch) => {
        const statusSchema = getStatusSchema(schema);
        const flatStatusList = statusSchema?.options?.map((option) => option?.value);

        await dispatch(fetchContactsByStatus(campaignId, flatStatusList));
        dispatch(setKanbanColumnOrder(flatStatusList));
    };

export const handleDeleteKanbanContact =
    (contactId: string, status: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const pagination = getPaginationForStatus(state, status);

        dispatch(
            removeContactIdsForStatus({
                contactIds: [contactId],
                status,
            }),
        );

        dispatch(
            setPaginationForStatus({
                status,
                pagination: { ...pagination, count: pagination.count - 1 },
            }),
        );
    };

export const setColumnVisibility =
    ({
        status,
        visible,
        campaignId,
    }: {
        status: string;
        visible: boolean;
        campaignId: string;
    }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);
        const hiddenColumns = getHiddenColumns(state);
        const columnOrder = getKanbanColumnOrder(state);
        const index = columnOrder?.indexOf(status);

        const statusOptions = getStatusSchema(schema)?.options;
        const optionToUpdate = statusOptions[index];

        const shouldUpdate =
            (visible && hiddenColumns.indexOf(status) >= 0) ||
            (!visible && hiddenColumns.indexOf(status) < 0);

        if (!shouldUpdate) {
            return;
        }

        if (visible) {
            dispatch(setHiddenColumns(hiddenColumns.filter((column) => column !== status)));
        } else {
            dispatch(setHiddenColumns([...hiddenColumns, status]));
        }

        // Update in DB
        dispatch(
            updateSchemaPropertyOption({
                fieldName: UniqueFieldName.status,
                index,
                updatedOption: { ...optionToUpdate, visible },
                campaignId,
            }),
        );
    };

export default kanbanSlice.reducer;
