import qs from 'query-string';

export const compressAvatar = (url: string) => {
    const urlWithoutParams = url.split('?')[0];
    const params = url.split('?')[1];
    const parsedParams = qs.parse(params);

    const newParams = {
        ...parsedParams,
        auto: 'compress,format',
        dpr: 2,
        h: 40,
        w: 40,
    };

    return `${urlWithoutParams}?${qs.stringify(newParams)}`;
};
