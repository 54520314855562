import { useFavicon } from '../components/Favicon/useFavicon';

import type { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

const RootLayout = ({ children }: Props) => {
    const favicon = useFavicon();

    return (
        <>
            {favicon}
            {children}
        </>
    );
};

export default RootLayout;
