import { normalizePreviewBlock } from '@/app/editor/engine/core/functions/utils/normalizePreviewBlock';
import { emptyNonReservedTrackingFields } from '@/app/editor/engine/utils/node/emptyNonReservedTrackingFields';

import type { BlockResource } from '@/app/editor/blocks/types';

/**
 * Get a function that will create a block from a preview.
 */
export const getMakeBlockFromPreview = ({
    activePageId,
    activeFunnelId,
    companyId,
    getPreviewById,
}: {
    activePageId: string;
    activeFunnelId: string;
    companyId: string;
    getPreviewById: (id: string) => BlockResource;
}) => {
    /**
     * Create a block from a preview.
     */
    return ({ id, previewId }: { id: string; previewId: string }) => {
        const previewBlock = getPreviewById(previewId);

        return emptyNonReservedTrackingFields(
            normalizePreviewBlock({
                ...previewBlock,
                id,
                relationships: {
                    ...previewBlock.relationships,
                    campaign: {
                        data: {
                            type: 'campaign',
                            id: activeFunnelId,
                        },
                    },
                    page: {
                        data: {
                            type: 'page',
                            id: activePageId,
                        },
                    },
                    components: {
                        data: [],
                    },
                    company: {
                        data: {
                            type: 'company',
                            id: companyId,
                        },
                    },
                },
            } satisfies BlockResource),
        );
    };
};
