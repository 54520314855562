import { createSlice } from '@reduxjs/toolkit';
import { change } from 'redux-form';

import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';

import type { Props as GenericSizeProps } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/GenericSize';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    activeFormBlockId: string;
    selectedSize: string;
    valueMap: GenericSizeProps['valueMap'];
}

const initialState: State = {
    activeFormBlockId: '',
    selectedSize: '',
    valueMap: EMPTY_OBJECT as GenericSizeProps['valueMap'],
};

export const mediaSizeSlice = createSlice({
    name: `editor/${NAME}/mediaSize`,
    initialState,
    reducers: {
        setActiveFromBlockId(state, action: PayloadAction<string>) {
            return {
                ...state,
                activeFormBlockId: action.payload,
            };
        },
        setSelectedSize(state, action: PayloadAction<string>) {
            return {
                ...state,
                selectedSize: action.payload,
            };
        },
        setValueMap(state, action: PayloadAction<GenericSizeProps['valueMap']>) {
            return {
                ...state,
                valueMap: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setActiveFromBlockId, setSelectedSize, setValueMap, reset } = mediaSizeSlice.actions;

// === Selectors ======

export const getActiveFormBlockId = (state: AppState) =>
    state[NAME]?.mediaSizeReducer?.activeFormBlockId;
export const getSelectedSize = (state: AppState) => state[NAME]?.mediaSizeReducer?.selectedSize;
export const getValueMap = (state: AppState) => state[NAME]?.mediaSizeReducer?.valueMap;

// === Thunks ======

export const changeMediaSize = (size: string): AppThunk => {
    return (dispatch) => {
        dispatch(setSelectedSize(size));
    };
};

export const saveMediaSize = (size: string): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        const formBlockId = getActiveFormBlockId(state);

        dispatch(change(formBlockId, 'attributes.content.size', size));
    };
};

export default mediaSizeSlice.reducer;
