import { ClockIcon } from '@heroicons/react/24/outline';

import type { KeyboardEvent, ChangeEvent } from 'react';

export interface Props {
    totalDuration: string;
    onChange: (event: ChangeEvent) => void;
    onBlur: () => void;
    onKeyDown: (event: KeyboardEvent) => void;
    value: string;
}

const VideoDuration = ({ totalDuration, value, onChange, onBlur, onKeyDown }: Props) => {
    return (
        <div className="relative flex h-12 w-full items-center rounded-lg bg-gray-100 text-sm text-gray-400">
            <ClockIcon className="absolute left-4 size-5" style={{ fill: 'none' }} />
            <div className="flex w-full justify-between pl-12 pr-4">
                <input
                    className="placeholder-normal w-16 min-w-0 bg-transparent text-black outline-none"
                    type="text"
                    placeholder="00:00:00"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    onKeyDown={onKeyDown}
                />
                <div className="flex-1 text-center">/</div>
                <div>{totalDuration}</div>
            </div>
        </div>
    );
};

export default VideoDuration;
