import { EditorEngineOrientation } from '@/app/editor/engine/core/types/util';

import type { EditorEngineNode } from '../../core/types';
import type { PerspectiveEditorEngineNodeData } from '../../types';

/**
 * This function determines whether two nodes are contiguous, assuming they
 * appear in rows of a determinate length.
 *
 * An array is returned if `firstNode` and `secondNode` are contiguous. The
 * array will contain the nodes in the order that they appear in the tree.
 *
 * If the nodes are not contiguous, the function will return `false`.
 */
export const areNodesInRowsOfNItemsContiguous = ({
    firstNode,
    secondNode,
    firstNodeIndex,
    secondNodeIndex,
    orientation,
    length,
}: {
    /**
     * The first node that is being evaluated.
     */
    firstNode: EditorEngineNode<PerspectiveEditorEngineNodeData>;
    /**
     * The second node that is being evaluated.
     */
    secondNode: EditorEngineNode<PerspectiveEditorEngineNodeData>;
    /**
     * The position of the first node within its parent.
     */
    firstNodeIndex: number;
    /**
     * The position of the second node within its parent.
     */
    secondNodeIndex: number;
    /**
     * Which orientation should be considered when determining if the two nodes
     * are contiguous or not.
     */
    orientation: EditorEngineOrientation;
    /**
     * How many nodes appear in a single row.
     */
    length: number;
}) => {
    if (orientation !== EditorEngineOrientation.Horizontal) {
        return false;
    }

    const smallerIndex = Math.min(firstNodeIndex, secondNodeIndex);

    // If the nodes are not in the same row, they are not contiguous.
    if (smallerIndex % length > length - 2) {
        return false;
    }

    if (firstNodeIndex + 1 === secondNodeIndex) {
        return [firstNode, secondNode] as [
            EditorEngineNode<PerspectiveEditorEngineNodeData>,
            EditorEngineNode<PerspectiveEditorEngineNodeData>,
        ];
    }

    if (secondNodeIndex + 1 === firstNodeIndex) {
        return [secondNode, firstNode] as [
            EditorEngineNode<PerspectiveEditorEngineNodeData>,
            EditorEngineNode<PerspectiveEditorEngineNodeData>,
        ];
    }

    return false;
};
