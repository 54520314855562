import { LOG_HISTORY_MAX_ITEMS } from '@/app/editor/engine/core/constants';

import { useCallback, useMemo, useState } from 'react';

import { getLogConsoleReporter } from '@/app/editor/engine/core/utils/log/getLogConsoleReporter';

import type {
    EditorEngineLogHandler,
    EditorEngineLogRecord,
} from '@/app/editor/engine/core/types/log';

/**
 * Debug the history of log records in the development and preview environments.
 */
export const useDebugLogs = ({
    name,
    maxItems = LOG_HISTORY_MAX_ITEMS,
}: {
    /**
     * The name of the Editor Engine instance.
     */
    name: string;
    /**
     * The maximum number of items to keep in the log history.
     */
    maxItems?: number;
}) => {
    const consoleReporter = useMemo(() => getLogConsoleReporter({ name }), [name]);
    const [records, setRecords] = useState<EditorEngineLogRecord[]>([]);
    const handler = useCallback(
        (record) => {
            setRecords((prevRecords) => [
                ...(prevRecords.length >= maxItems ? prevRecords.slice(1) : prevRecords),
                record,
            ]);

            consoleReporter(record);
        },
        [consoleReporter, maxItems],
    ) satisfies EditorEngineLogHandler;

    // todo(editorengine): these debug nodes will be introduced in a later PR
    const debugNodes = null;
    const debugMenuNodes = null;

    return {
        records,
        handler,
        debugNodes,
        debugMenuNodes,
    };
};
