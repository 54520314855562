import { createSlice } from '@reduxjs/toolkit';
import qs from 'query-string';

import { getWorkspaces } from '@/app/workspaces/models/workspaces';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY } from '@/utils/empty';

import { NAME } from '../constants';

import type { CampaignResource } from '@/app/campaigns/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    recentCampaigns: CampaignResource[];
}

const initialState: State = {
    recentCampaigns: EMPTY_ARRAY,
};

export const recentCampaignsSlice = createSlice({
    name: `${NAME}/recentCampaigns`,
    initialState,
    reducers: {
        setRecentCampaigns(state, action: PayloadAction<CampaignResource[]>) {
            state.recentCampaigns = action.payload;
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setRecentCampaigns, reset } = recentCampaignsSlice.actions;

// === Selectors ======

export const getRecentCampaigns = (state: AppState) =>
    state[NAME]?.recentCampaignsReducer?.recentCampaigns;

// === Thunks ======

export const fetchRecentCampaigns =
    (search = ''): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const workspaceIds = Object.keys(getWorkspaces(state));

        const query = {
            status: 'draft,live,offline',
            order: '-updatedAt',
            page: 1,
            limit: 5,
            // the api understands comma separated IDs for workspaceId
            workspaceId: workspaceIds.join(','),
            ...(search ? { search } : {}),
        };

        try {
            const response = await apiGet(`/campaigns/filter?${qs.stringify(query)}`);

            const campaigns: CampaignResource[] = getDataFromResponse(response);

            dispatch(setRecentCampaigns(campaigns));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching recent campaigns failed:' });
        }
    };

export default recentCampaignsSlice.reducer;
