import { MINIMUM_CHILD_COUNT } from '@/app/editor/blocks/constants';
import {
    BLOCK_DELETION_REMINDER_LS_KEY,
    DEFAULT_BLOCK_CONFIG,
} from '@/app/editor/editor/constants';

import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { invert } from 'lodash/object';
import omit from 'lodash/omit';
import set from 'lodash/set';

import { BorderMenuItem } from '@/app/editor/blocks/types';
import { thirdPartyMap } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/InputType/fieldNameMap';
import { getHasBorderItem } from '@/app/editor/editor/helper';

import { BlockMap } from '../components/BlockMap';
import {
    TRANSITION_DELAY_BETWEEN_BLOCKS,
    TRANSITION_DURATION,
    TRANSITION_DURATION_SLOW,
    TRANSITION_PREVIEW_DELAY,
} from '../constants';

import type {
    AdditionalBlocks,
    AdditionalBlocksConfig,
    BlockConfig,
    BlockDetails,
    BlockResource,
    BoxProps,
} from '@/app/editor/blocks/types';
import type { InputOption } from '@/app/editor/editor/types';
import type { TransitionEffect } from '@/app/editor/themes/types';
import type { JSONContent } from '@tiptap/core';
import type { RawDraftContentState } from 'draft-js';
import type { FunctionComponent } from 'react';

// For Block Preview when adding a new block
export const getPreviewIdToShow = ({
    blockId,
    previewBlockId,
    sectionPreviewId,
}: {
    blockId: string;
    previewBlockId: string;
    sectionPreviewId: string;
}) => {
    if (blockId === previewBlockId && sectionPreviewId) {
        return sectionPreviewId;
    }

    return null;
};

export const getPreviewIdsToShow = ({
    blockId,
    previewBlockId,
    sectionPreviewIds,
}: {
    blockId: string;
    previewBlockId: string;
    sectionPreviewIds: string[];
}) => {
    if (blockId === previewBlockId && sectionPreviewIds.length) {
        return sectionPreviewIds;
    }

    return null;
};

// get Editor Component of block
export const getBlockComponent = (componentType: string): FunctionComponent<any> => {
    if (BlockMap[componentType] && BlockMap[componentType].component) {
        return BlockMap[componentType].component;
    }

    // Debug
    if (process.env.NODE_ENV !== 'production') {
        console.log('No Block Component found for:', componentType);
    }

    return null;
};

// Get Config of block
export const getBlockConfig = (componentType: string): BlockConfig => {
    if (BlockMap[componentType] && BlockMap[componentType].config) {
        return BlockMap[componentType].config;
    }

    // Debug
    if (process.env.NODE_ENV !== 'production') {
        console.log('No Block Config found for:', componentType);
    }

    return {
        ...DEFAULT_BLOCK_CONFIG,
    };
};

// get ComponentType of block
export const getBlockComponentType = (block: BlockResource | null): string => {
    if (!block) {
        return;
    }

    let componentType = get(block.attributes, 'componentType', '').replace('Template', '');

    if (componentType === 'button' && get(block, 'relationships.parent.data.id')) {
        componentType = 'submit';
    }

    return componentType;
};

// get block details via relationships
export const getBlockDetails = (block: BlockResource): BlockDetails => {
    if (!block) {
        return;
    }

    const campaignId = block.relationships.campaign.data.id;
    const pageId = block.relationships.page?.data?.id;
    const childComponents = block.relationships.components;
    const parentComponent = block.relationships.parent;
    const componentType = getBlockComponentType(block);
    const config = getBlockConfig(componentType);

    return {
        campaignId,
        pageId,
        childComponents,
        parentComponent,
        componentType,
        config,
    };
};

// for Personalization feature
const fieldNameToFieldMap = invert(thirdPartyMap);

export const getTranslatedFieldName = (translations: InputOption[], fieldName: string) => {
    const field: string = fieldNameToFieldMap[fieldName];

    // if field is no input field (e.g. custom fields) return fieldName
    if (!field) {
        return fieldName;
    }

    // Get Translation and return translated name
    const translation = find(translations, { field });

    if (translation) {
        return translation.name;
    }

    // if no translation was found return unaltered fieldName
    return fieldName;
};

// Show/hide delete button in BorderMenu
export const getCanBeDeleted = (
    block: BlockResource,
    parentBlock: BlockResource,
    blockConfig: BlockConfig,
) => {
    if (!block) {
        return false;
    }

    if (!getHasBorderItem(blockConfig, BorderMenuItem.delete)) {
        return false;
    }

    const parentType = parentBlock?.attributes?.componentType;

    // Email and Name fields are required in Calendly form
    if (block && parentType === 'formURL') {
        const fieldName = block?.attributes?.content?.fieldName;

        return !['email', 'name'].includes(fieldName);
    }

    // Prevent deletion of all child blocks
    if (!MINIMUM_CHILD_COUNT[parentType]) {
        return true;
    }

    return parentBlock?.relationships?.components?.data?.length > MINIMUM_CHILD_COUNT[parentType];
};

// Show/hide duplicate button in BorderMenu
export const getCanBeDuplicated = (maxCount: number, parent: BlockResource) => {
    // Check if maxCount is reached
    return !(maxCount && parent?.relationships?.components?.data?.length >= maxCount);
};

// Remove `:` from icon strings
export const getCleanIconId = (icon: string) => {
    return icon?.replace(/:\s*/g, '');
};

const getAdditionalBlockTarget = ({
    targetType,
    childType,
    parent,
    targetParent,
    children,
}: {
    targetType: AdditionalBlocksConfig['target'];
    childType?: string;
    parent?: BlockResource;
    targetParent?: BlockResource;
    children?: BlockResource[];
}): BlockResource => {
    switch (targetType) {
        case 'targetParent':
            return targetParent;
        case 'parent':
            return parent;
        case 'child':
            if (!childType) {
                return;
            }

            return find(children, (child) => child?.attributes?.componentType === childType);
        default:
            return;
    }
};

// Get additional blocks data from Block config
export const getAdditionalBlocksData = ({
    block,
    parent,
    targetParent,
    children,
}: {
    block: BlockResource;
    parent?: BlockResource;
    targetParent?: BlockResource;
    children?: BlockResource[];
}): AdditionalBlocks => {
    const componentType = getBlockComponentType(block);
    const config = getBlockConfig(componentType);

    let additionalBlocks = {};

    const additionalBlockConfig = config?.actions?.getAdditionalBlocksConfig?.(block, parent);

    const hasAdditionalBlockConfig = !isEmpty(additionalBlockConfig);

    if (hasAdditionalBlockConfig) {
        additionalBlockConfig.forEach((blockConfig) => {
            let target = getAdditionalBlockTarget({
                targetType: blockConfig.target,
                childType: blockConfig.childType,
                parent,
                targetParent,
                children,
            });

            if (!target) {
                return;
            }

            // If config has paths -> only select paths from config
            // Otherwise - select the whole target object with omitted paths
            if (blockConfig?.paths) {
                blockConfig.paths?.forEach((path) => {
                    const value = get(target, path);

                    set(additionalBlocks, `additionalBlocks.${target.id}.${path}`, value);
                });
            } else {
                // Remove omittedPaths if needed
                const cleanTarget = blockConfig.ommitedPaths
                    ? omit(target, blockConfig.ommitedPaths)
                    : target;

                set(additionalBlocks, `additionalBlocks.${cleanTarget.id}`, cleanTarget);
            }
        });
    }

    return additionalBlocks;
};

// Transition helpers
export const getPageTransitionProps = (
    transition: TransitionEffect,
    artBoardIndex?: number,
): { enterFrom: string; enterTo: string } => {
    if (isNaN(artBoardIndex)) {
        return {
            enterFrom: '',
            enterTo: '',
        };
    }

    switch (transition) {
        case 'slide':
            return {
                enterFrom: 'opacity-0 translate-y-[50px]',
                enterTo: 'opacity-100 translate-y-0',
            };
        case 'scale':
            return {
                enterFrom: 'opacity-0 scale-[0.8]',
                enterTo: 'opacity-100 scale-100',
            };
        case 'fade':
            return {
                enterFrom: 'opacity-0',
                enterTo: 'opacity-100',
            };
        case 'blur':
            return {
                enterFrom: 'opacity-0 blur',
                enterTo: 'opacity-100 blur-none',
            };

        default:
            return {
                enterFrom: 'opacity-0 translate-y-2.5',
                enterTo: 'opacity-100 translate-y-0',
            };
    }
};

export const getPageTransitionDuration = (transition: TransitionEffect): number => {
    if (transition === 'none') {
        return 0;
    }

    if (transition === 'fade' || transition === 'blur') {
        return TRANSITION_DURATION_SLOW;
    }

    return TRANSITION_DURATION;
};

export const getPageTransitionDelay = (artBoardIndex?: number): number => {
    return isNaN(artBoardIndex)
        ? TRANSITION_PREVIEW_DELAY
        : artBoardIndex * TRANSITION_DELAY_BETWEEN_BLOCKS + TRANSITION_PREVIEW_DELAY;
};

export const getMediaFormType = (srcType: string, parentComponentType: string) => {
    if (parentComponentType === 'gridColumn') {
        return srcType;
    }

    return parentComponentType ? parentComponentType : srcType;
};

export const getBoxAttributes = ({
    box,
    isInColumn = false,
    isInMediaAnswer = false,
}: {
    box: BoxProps;
    isInColumn?: boolean;
    isInMediaAnswer?: boolean;
}): BoxProps => {
    if (!isInColumn || isInMediaAnswer) {
        return box;
    }

    // Is in column
    return {
        ...box,
        left: 'none',
        right: 'none',
        backgroundColor: 'transparent',
    };
};

export const getStorageBlockDeletionReminder = () =>
    localStorage.getItem(BLOCK_DELETION_REMINDER_LS_KEY);

export const getIsTipTapData = (wysiwyg: JSONContent | RawDraftContentState) => {
    if (!wysiwyg) {
        return false;
    }

    return 'type' in wysiwyg && wysiwyg?.type === 'doc';
};

export const getIsImgixImage = (src: string) => {
    if (!src) {
        return false;
    }

    return (
        src.includes('https://perspective.imgix.net') || src.includes('https://images.unsplash.com')
    );
};
