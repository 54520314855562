import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';
import { Field, FormSection, getFormValues, reduxForm } from 'redux-form';

import { getBlockIsOnlyChild } from '@/app/editor/blocks/models/blocks';
import { BgImageFocalPoint } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/BgImage/BgImageFocalPoint';
import { BgImageOpacity } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/BgImage/BgImageOpacity';
import { BgImageSrc } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/BgImage/BgImageSrc';
import { BgImageToggle } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/BgImage/BgImageToggle';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import { ColorPickerHistoryProvider } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color/ColorPickerHistoryProvider';
import FlexAlign from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/FlexAlign';
import FrameToggle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/FrameToggle';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({ form, handleSubmit, activeTheme }) => {
    const { t } = useTranslation(NAME);
    const formValues = useAppSelector((state) => getFormValues(form)(state) as BlockResource);
    const artboardSize = useArtboardSize();

    const bgImageSettingVisible = formValues?.attributes?.content?.box?.bgImage?.visible;
    const bgImageSrc = formValues?.attributes?.content?.box?.bgImage?.src;

    const isFramed = formValues?.attributes?.content?.framed;
    const isSingleColumn = useAppSelector((state) => getBlockIsOnlyChild(state, form));
    const showVerticalAlign =
        (!isSingleColumn && artboardSize === ArtBoardSize.DESKTOP) ||
        (bgImageSettingVisible && isFramed);

    return (
        <>
            {showVerticalAlign && (
                <Field
                    name="attributes.content.verticalAlign"
                    component={FlexAlign}
                    submit={handleSubmit}
                />
            )}
            <div className="grid grid-cols-3 gap-2">
                <Field
                    name="attributes.content.framed"
                    component={FrameToggle}
                    formSubmit={handleSubmit}
                />

                {isFramed && (
                    <FormSection name="attributes.content.box" component={Fragment}>
                        <ColorPickerHistoryProvider>
                            <Field
                                name="backgroundColor"
                                component={Color}
                                expand="right"
                                submit={handleSubmit}
                                themeColor={getThemeColor(activeTheme, 'backgroundColor')}
                                tooltip={t('background-color')}
                                hasGradient
                                themeKey="backgroundColor"
                            />
                        </ColorPickerHistoryProvider>

                        <Field
                            name="bgImage.visible"
                            component={BgImageToggle}
                            submit={handleSubmit}
                            isEmpty={!bgImageSrc}
                        />
                    </FormSection>
                )}
            </div>

            {isFramed && bgImageSettingVisible && (
                <FormSection name="attributes.content.box.bgImage">
                    <Field
                        name="focalPoint"
                        component={BgImageFocalPoint}
                        submit={handleSubmit}
                        src={bgImageSrc}
                    />
                    <Field name="src" component={BgImageSrc} submit={handleSubmit} blockId={form} />
                    <Field name="opacity" component={BgImageOpacity} submit={handleSubmit} />
                </FormSection>
            )}
        </>
    );
};

Form.displayName = 'GridColumnEditForm';

export default reduxForm({})(Form);
