import { FEATURE_IDS } from '@/app/billing/constants';
import { LOLI_FEATURE_FLAG_NAME } from '@/core/loli-feature-flags/constants';

import { useFeatureAvailability } from '@/app/company/hooks/useFeatureAvailability';
import { useLoliFeatureFlag } from '@/core/loli-feature-flags/store';

export const useCustomHtmlBlockFlags = () => {
    return {
        canAddHtmlEmbedBlocks: useFeatureAvailability(FEATURE_IDS.customHtmlEmbedBlock),
        canEnableNativeEmbedding: useLoliFeatureFlag(
            LOLI_FEATURE_FLAG_NAME.customHtmlEmbedBlockNativeEmbed,
        ),
    };
};
