import { DndContext } from '@dnd-kit/core';
import { useCallback, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { EditorEngineDragOverlay } from '@/app/editor/engine/core/components/dragAndDrop/EditorEngineDragOverlay';
import { closestPointerCollisionDetection } from '@/app/editor/engine/core/utils/dragAndDrop/closestPointerCollisionDetection';
import { getOnDragEnd } from '@/app/editor/engine/core/utils/dragAndDrop/getOnDragEnd';
import { getOnDragMove } from '@/app/editor/engine/core/utils/dragAndDrop/getOnDragMove';
import { getOnDragStart } from '@/app/editor/engine/core/utils/dragAndDrop/getOnDragStart';

import type { useEditorEngineDragAndDropState } from '@/app/editor/engine/core/hooks/dragAndDrop/useEditorEngineDragAndDropState';
import type {
    EditorEngineComponent,
    EditorEngineHistory,
    EditorEngineDefaultTypeInput,
} from '@/app/editor/engine/core/types';
import type { ReactNode } from 'react';

interface Props<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> {
    /**
     * The children to render.
     */
    children: ReactNode;
    /**
     * The component to render for each node.
     */
    Component: EditorEngineComponent<
        TEditorEngineTypeInput['Document'],
        TEditorEngineTypeInput['Data']
    >;
    /**
     * The document manager.
     */
    documentManager: TEditorEngineTypeInput['DocumentManager'];
    /**
     * The node manager.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
    /**
     * The drag and drop state.
     */
    dragAndDropState: ReturnType<typeof useEditorEngineDragAndDropState<TEditorEngineTypeInput>>;
    /**
     * The actions that the editor engine can perform.
     */
    actions: TEditorEngineTypeInput['Actions'];
    /**
     * The history that contains the user's actions.
     */
    history: EditorEngineHistory;
}

/**
 * A context that provides drag and drop functionality.
 */
export const EditorEngineDragAndDropContext = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    children,
    Component,
    documentManager,
    nodeManager,
    dragAndDropState: {
        dropCandidateState: { dropCandidate, setDropCandidate },
    },
    actions,
    history,
}: Props<TEditorEngineTypeInput>) => {
    const isDraggingRef = useRef(false);
    const [draggedNodeId, setDraggedNodeId] = useState<string | null>(null);
    const draggedNode = useMemo(
        () => nodeManager.getNode(draggedNodeId),
        [draggedNodeId, nodeManager],
    );
    const onDragStart = useMemo(
        () => getOnDragStart({ setDraggedNodeId, isDraggingRef }),
        [setDraggedNodeId],
    );
    const onDragMove = useMemo(
        () =>
            getOnDragMove<TEditorEngineTypeInput>({
                documentManager,
                nodeManager,
                setDropCandidate,
                isDraggingRef,
            }),
        [documentManager, nodeManager, setDropCandidate],
    );
    const clearDropCandidate = useCallback(() => setDropCandidate(null), [setDropCandidate]);
    const onDragEnd = useMemo(
        () =>
            getOnDragEnd({
                setDraggedNodeId,
                dropCandidate,
                clearDropCandidate,
                history,
                actions,
                nodeManager,
                isDraggingRef,
            }),
        [dropCandidate, clearDropCandidate, history, actions, nodeManager],
    );
    const onDragCancel = useCallback(() => {
        setDraggedNodeId(null);
        setDropCandidate(null);
    }, [setDropCandidate]);
    const draggedNodeContent = useMemo(
        () =>
            createPortal(
                <EditorEngineDragOverlay
                    Component={Component}
                    document={documentManager.document}
                    identify={nodeManager.identify}
                    draggedNode={draggedNode}
                />,
                document.body,
            ),
        [Component, documentManager.document, draggedNode, nodeManager.identify],
    );

    return (
        <DndContext
            onDragStart={onDragStart}
            onDragMove={onDragMove}
            onDragEnd={onDragEnd}
            collisionDetection={closestPointerCollisionDetection}
            onDragCancel={onDragCancel}
        >
            {children}
            {draggedNodeContent}
        </DndContext>
    );
};
