export const NAME = 'settings';

export const CHARGEBEE_SECTIONS = {
    SUBSCRIPTION_DETAILS: 'SUBSCRIPTION_DETAILS',
    SUBSCRIPTION_CANCELLATION: 'SUBSCRIPTION_CANCELLATION',
    EDIT_SUBSCRIPTION: 'EDIT_SUBSCRIPTION',
    VIEW_SCHEDULED_CHANGES: 'VIEW_SCHEDULED_CHANGES',
    ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
    EDIT_ACCOUNT_DETAILS: 'EDIT_ACCOUNT_DETAILS',
    ADDRESS: 'ADDRESS',
    EDIT_BILLING_ADDRESS: 'EDIT_BILLING_ADDRESS',
    EDIT_SHIPPING_ADDRESS: 'EDIT_SHIPPING_ADDRESS',
    EDIT_SUBSCRIPTION_CUSTOM_FIELDS: 'EDIT_SUBSCRIPTION_CUSTOM_FIELDS',
    PAYMENT_SOURCES: 'PAYMENT_SOURCES',
    ADD_PAYMENT_SOURCE: 'ADD_PAYMENT_SOURCE',
    EDIT_PAYMENT_SOURCE: 'EDIT_PAYMENT_SOURCE',
    VIEW_PAYMENT_SOURCE: 'VIEW_PAYMENT_SOURCE',
    CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION: 'CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION',
    BILLING_HISTORY: 'BILLING_HISTORY',
};

export const ENDPOINTS = {
    GET: {
        invoices: (pageOffset = 0) => {
            return !pageOffset
                ? '/subscriptions/invoices'
                : `/subscriptions/invoices?offset=${encodeURIComponent(pageOffset)}`;
        },
    },
    POST: {
        updateBillingAddress: (invoiceId: string) =>
            `/subscriptions/invoices/${invoiceId}/billing-address`,
    },
} as const;

export const QUERY_KEYS = {
    invoices: ['invoices', 'list'],
} as const;

export const MUTATION_KEYS = {
    updateBillingAddress: ['invoices', 'update', 'billingAddress'],
} as const;
