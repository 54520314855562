import imgix from '@/core/imgix';

import { DomainType } from './types';

import type { DomainResource } from './types';

export const NAME = 'domains';

export const DOMAIN_TYPE_MAP = {
    [DomainType.sub]: 'domainSub',
    [DomainType.custom]: 'domainCustom',
    [DomainType.cloudflare]: 'domainCloudflare',
    [DomainType.instant]: 'domainInstant',
    [DomainType.managed]: 'domainManaged',
};

export const ENDPOINTS = {
    GET: {
        domain: (domainType: DomainType, domainId: string) => `/domains/${domainType}/${domainId}`,
        domains: (domainType: DomainType) => `/domains/${domainType}`,
        domainFunnels: (domainType: DomainType, domainId: string) =>
            `/domains/${domainType}/${domainId}/campaigns`,
    },
    POST: {
        verifyDomain: (domainType: DomainType, domainId: string) =>
            `/domains/${domainType}/${domainId}/verify`,
        setDefault: (domainType: DomainType, domainId: string) =>
            `/domains/${domainType}/${domainId}/default`,
        addFacebookVerification: (domainType: DomainType, domainId: string) =>
            `/domains/${domainType}/${domainId}/external-verification`,
        domainAvailability: (domainType: DomainType) =>
            `/domains/${DOMAIN_TYPE_MAP[domainType]}/available`,
        addDomain: (domainType: DomainType) => `/domains/${DOMAIN_TYPE_MAP[domainType]}`,
    },
    DELETE: {
        removeDomain: (domainType: DomainType, domainId: string) =>
            `/domains/${domainType}/${domainId}`,
    },
} as const;

export const QUERY_KEYS = {
    allDomains: ['domains', 'list'],
    singleDomain: (domainType: DomainType, domainId: string) => ['domain', domainType, domainId],
    domainFunnels: (domainType: DomainType, domainId: string) => [
        'domain',
        'funnels',
        'list',
        domainType,
        domainId,
    ],
} as const;

export const MUTATION_KEYS = {
    verifyDomain: (domainId: string) => ['domain', 'verify', domainId],
    setDefaultDomain: (domainId: string) => ['domain', 'setDefault', domainId],
    removeDomain: (domainId: string) => ['domain', 'remove', domainId],
    addFacebookVerification: (domainId: string) => ['domain', 'addFacebookVerification', domainId],
    addDomain: (domainType: DomainType) => ['domain', 'add', domainType],
} as const;

export const LEGACY_DOMAIN_DEFAULT: DomainResource = {
    type: 'domainInstant',
    id: 'perspective_default',
    attributes: {
        name: 'funnel.perspective.co',
        status: 'verified',
        default: false,
        url: 'funnel.perspective.co',
    },
    relationships: {},
};

export const DOMAIN_DEFAULT: DomainResource = {
    ...LEGACY_DOMAIN_DEFAULT,
    attributes: {
        ...LEGACY_DOMAIN_DEFAULT.attributes,
        name: 'perspectivefunnel.co',
        url: 'perspectivefunnel.co',
    },
};

export const NEW_DEFAULT_DOMAIN_TIMESTAMP = 1673218800; // Unix for 9. Jan 2023

export const CLOUDFLARE_CNAME_DOMAIN_WITHOUT_LAST_DOT =
    process.env.NEXT_PUBLIC_PERSPECTIVE_CNAME_DOMAIN;
export const CLOUDFLARE_CNAME_DOMAIN = `${CLOUDFLARE_CNAME_DOMAIN_WITHOUT_LAST_DOT}.`;

export interface DnsProviderInfo {
    logo: string;
    helpArticleLinkI18nKey: string;
    dashboardLink: string;
}

export const DNS_PROVIDERS_AND_THEIR_INFO: Record<string, DnsProviderInfo> = {
    'All-Inkl': {
        logo: imgix.domains.ownDomain.providers.allInkl,
        helpArticleLinkI18nKey: 'domains:dnsProviderHelpCenterArticles.allIncl',
        dashboardLink: 'https://all-inkl.com/login/',
    },
    GoDaddy: {
        logo: imgix.domains.ownDomain.providers.goDaddy,
        helpArticleLinkI18nKey: 'domains:dnsProviderHelpCenterArticles.godaddy',
        dashboardLink: 'https://sso.godaddy.com/',
    },
    Strato: {
        logo: imgix.domains.ownDomain.providers.strato,
        helpArticleLinkI18nKey: 'domains:dnsProviderHelpCenterArticles.strato',
        dashboardLink: 'https://www.strato.de/apps/CustomerService#/skl',
    },
    IONOS: {
        logo: imgix.domains.ownDomain.providers.ionos,
        helpArticleLinkI18nKey: 'domains:dnsProviderHelpCenterArticles.ionos',
        dashboardLink: 'https://login.ionos.de/',
    },
    Namecheap: {
        logo: imgix.domains.ownDomain.providers.namecheap,
        helpArticleLinkI18nKey: 'domains:dnsProviderHelpCenterArticles.namecheap',
        dashboardLink: 'https://www.namecheap.com/myaccount/login/',
    },
};

export const DNS_PROVIDERS_THAT_DO_NOT_SUPPORT_CNAME_ON_ROOT_DOMAIN = [
    'All-Inkl',
    'Strato',
    'IONOS',
    'GoDaddy',
];
