export const Team = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect opacity="0.15" x="2" y="13" width="9" height="9" rx="2" fill="#007AFF" />
            <rect x="2" y="2" width="9" height="9" rx="2" fill="#007AFF" />
            <rect x="13" y="13" width="9" height="9" rx="2" fill="#007AFF" />
            <rect opacity="0.15" x="13" y="2" width="9" height="9" rx="2" fill="#007AFF" />
        </svg>
    );
};
