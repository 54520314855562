import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';

import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    webinarPreviews: { [blockId: string]: boolean };
}

const initialState: State = {
    webinarPreviews: EMPTY_OBJECT,
};

export const webinarSlice = createSlice({
    name: `editor/${NAME}/webinar`,
    initialState,
    reducers: {
        setWebinarPreview(state, action: PayloadAction<{ blockId: string; visible: boolean }>) {
            return {
                ...state,
                webinarPreviews: {
                    ...state.webinarPreviews,
                    [action.payload.blockId]: action.payload.visible,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setWebinarPreview, reset } = webinarSlice.actions;

// === Selectors ======

export const getWebinarPreviewByBlockId = (state: AppState, blockId: string) =>
    state[NAME]?.webinarReducer?.webinarPreviews[blockId];

export default webinarSlice.reducer;
