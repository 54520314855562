import { Bars3BottomLeftIcon, Bars3Icon } from '@heroicons/react/20/solid';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    submit: () => void;
    tooltip: string;
    disabled?: boolean;
    showTooltipWhenDisabled?: boolean;
}

export type AlignFormProps = Omit<Props, 'tooltip' | 'showTooltipWhenDisabled'>;

export const Align = ({ input, submit, tooltip, disabled, showTooltipWhenDisabled }: Props) => {
    const { value, onChange } = input;

    const handleClick = () => {
        const newValue = value === 'center' ? 'left' : 'center';

        onChange(newValue);

        if (submit) {
            setTimeout(submit);
        }
    };

    return (
        <FieldContainer>
            <Tooltip content={tooltip} disabled={disabled && !showTooltipWhenDisabled}>
                <button
                    className={cn(
                        'flex h-12 w-full items-center justify-center rounded-lg bg-gray-100 text-gray-400 hover:text-gray-600',
                        {
                            bump: !disabled,
                            'cursor-not-allowed text-gray-300 hover:text-gray-300': disabled,
                        },
                    )}
                    onClick={disabled ? undefined : handleClick}
                >
                    {value === 'center' ? (
                        <Bars3Icon className="size-4" />
                    ) : (
                        <Bars3BottomLeftIcon className="size-4" />
                    )}
                </button>
            </Tooltip>
        </FieldContainer>
    );
};
