import { combineReducers } from 'redux';

import campaignsReducer from './campaigns';
import menuReducer from './menu';
import recentCampaignsReducer from './recentCampaigns';
import workspaceCampaignsReducer from './workspaceCampaigns';

export default combineReducers({
    campaignsReducer,
    recentCampaignsReducer,
    workspaceCampaignsReducer,
    menuReducer,
});
