import Image from 'next/image';
import { useRef } from 'react';
import ContentEditable from 'react-contenteditable';

import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useContentEditablePlaceholder } from '@/app/editor/blocks/hooks/useContentEditablePlaceholder';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getActiveIndexBySliderId } from '@/app/editor/blocks/models/slider';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps, Testimonial } from '../../types';
import type { FC, MouseEventHandler } from 'react';

export interface Props extends DefaultBlockComponentProps {
    testimonials: Testimonial[];
    framed: boolean;
    backgroundColor?: string;
}

const Component: FC<Props> = ({ artBoardIndex, blockId, box, testimonials, framed, isPreview }) => {
    const { isTablet, isInSingleColumn, isInColumn, isDesktop } = useLayoutInfo(blockId, isPreview);
    const activeImageIndex = useAppSelector((state) => getActiveIndexBySliderId(state, blockId));
    const sliderImageBorderRadius = useBorderRadius('sliderImage');
    const activeTestimonial = testimonials[activeImageIndex];
    const isWide = isDesktop && (isInSingleColumn || !isInColumn);

    const headlineRef = useRef<HTMLInputElement>(null);
    const subHeadlineRef = useRef<HTMLInputElement>(null);

    const {
        updatedPlaceholder: headlinePlaceholder,
        handlePlaceholderChange: handleHeadlinePlaceholderChange,
        handlePlaceholderBlur: handleHeadlinePlaceholderBlur,
        handleKeyDown: handleHeadlineKeydown,
    } = useContentEditablePlaceholder({
        blockId,
        initialPlaceholder: activeTestimonial?.headline,
        dataPath: `attributes.content.testimonials[${activeImageIndex}].headline`,
        ref: headlineRef,
    });

    const {
        updatedPlaceholder: subHeadlinePlaceholder,
        handlePlaceholderChange: handleSubHeadlinePlaceholderChange,
        handlePlaceholderBlur: handleSubHeadlinePlaceholderBlur,
        handleKeyDown: handleSubHeadlineKeydown,
    } = useContentEditablePlaceholder({
        blockId,
        initialPlaceholder: activeTestimonial?.subHeadline,
        dataPath: `attributes.content.testimonials[${activeImageIndex}].subHeadline`,
        ref: subHeadlineRef,
    });

    const handlePlaceholderClick: MouseEventHandler<HTMLDivElement> = (event) => {
        const clickCount = event.detail;

        if (clickCount === 2) {
            event.stopPropagation();
        }
    };

    const tabletWrapperClasses = cn({
        'px-14 py-6': isTablet && framed,
    });

    const hasBorderRadius = framed || isInColumn || (!isInColumn && isDesktop);

    const wrapperClasses = cn('relative overflow-hidden transition-all duration-200 ease-out', {
        [sliderImageBorderRadius]: hasBorderRadius,
        'border border-gray-200/50': hasBorderRadius,
        'text-white': activeTestimonial?.mode === 'dark',
        'text-black': activeTestimonial?.mode === 'light',
    });

    const gradientClasses = cn(
        'absolute inset-0 z-10 h-full transition-all duration-200 ease-out',
        {
            'w-[60%] bg-gradient-to-r': isWide,
            'w-full bg-gradient-to-t': !isWide,
            'to-black-0 from-black': activeTestimonial?.mode === 'dark',
            'to-white-0 from-white': activeTestimonial?.mode === 'light',
        },
    );

    const headlineClasses = cn(
        'ring-none w-full cursor-text break-words font-semibold outline-none transition-all duration-200 ease-out',
        {
            'text-lg': headlinePlaceholder?.length <= 150 && !isWide,
            'text-base': headlinePlaceholder?.length > 150 && !isWide,
            'text-4xl': headlinePlaceholder?.length <= 150 && isWide,
            'text-2xl': headlinePlaceholder?.length > 150 && isWide,
        },
    );

    const subTitleClasses = cn(
        'ring-none mt-5 cursor-text break-words font-semibold outline-none',
        {
            'text-sm': !isWide,
            'text-lg': isWide,
        },
    );

    return (
        <Box
            box={{
                ...getBoxAttributes({ box, isInColumn }),
                top: framed ? 'medium' : 'none',
                right: framed ? 'large' : 'none',
                bottom: framed ? 'medium' : 'none',
                left: framed ? 'large' : 'none',
            }}
            artBoardIndex={artBoardIndex}
        >
            <div className={tabletWrapperClasses}>
                <div className={wrapperClasses}>
                    <div className="absolute bottom-3 left-0 right-0 z-20 flex w-full justify-center space-x-1 px-3">
                        {testimonials.length > 1 &&
                            testimonials.map((testimonial, index) => {
                                if (!testimonial) {
                                    return null;
                                }

                                const pillWrapperClasses = cn(
                                    'h-1 w-full max-w-[70px] flex-grow overflow-hidden rounded-sm',
                                    {
                                        'bg-white':
                                            index <= activeImageIndex &&
                                            activeTestimonial?.mode === 'dark',
                                        'bg-gray-200':
                                            index <= activeImageIndex &&
                                            activeTestimonial?.mode === 'light',
                                        'bg-gray-400':
                                            index > activeImageIndex &&
                                            activeTestimonial?.mode === 'dark',
                                        'bg-gray-300':
                                            index > activeImageIndex &&
                                            activeTestimonial?.mode === 'light',
                                    },
                                );

                                return (
                                    <div
                                        key={`artboard-pill-${testimonial.image}-${index}`}
                                        className={pillWrapperClasses}
                                    >
                                        <div className="h-full w-0 bg-white" />
                                    </div>
                                );
                            })}
                    </div>
                    <div
                        className={cn('relative w-full flex-shrink-0 text-[0]', {
                            'aspect-square': !isWide,
                            'bg-white': activeTestimonial?.mode === 'light',
                            'bg-black': activeTestimonial?.mode === 'dark',
                        })}
                    >
                        <div
                            className={cn('absolute z-20 flex flex-col px-7', {
                                'bottom-8 w-full items-center text-center': !isWide,
                                'left-0 h-full w-[60%] justify-center': isWide,
                            })}
                        >
                            <ContentEditable
                                innerRef={headlineRef}
                                className={headlineClasses}
                                html={headlinePlaceholder}
                                onChange={handleHeadlinePlaceholderChange}
                                onBlur={handleHeadlinePlaceholderBlur}
                                onKeyDown={handleHeadlineKeydown}
                                onClick={handlePlaceholderClick}
                            />
                            <ContentEditable
                                innerRef={subHeadlineRef}
                                className={subTitleClasses}
                                html={subHeadlinePlaceholder}
                                onChange={handleSubHeadlinePlaceholderChange}
                                onBlur={handleSubHeadlinePlaceholderBlur}
                                onKeyDown={handleSubHeadlineKeydown}
                                onClick={handlePlaceholderClick}
                            />
                        </div>
                        <div
                            className={cn({
                                'relative right-0 ml-auto w-1/2 aspect-square': isWide,
                            })}
                        >
                            <div className={gradientClasses} />
                            <Image
                                src={testimonials[activeImageIndex]?.image}
                                alt={`slide ${activeImageIndex}`}
                                className="object-cover"
                                fill
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
};

Component.displayName = 'TestimonialSlider';

export default Component;
