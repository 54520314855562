import { useContext } from 'react';

import type { EditorEngineNodeRendererProps } from '@/app/editor/engine/core/components/view/getEditorEngineNodeRenderer';
import type {
    EditorEngineParentId,
    EditorEngineDefaultTypeInput,
} from '@/app/editor/engine/core/types';
import type { ComponentType } from 'react';

export type EditorEngineByParentRendererProps<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = Omit<EditorEngineNodeRendererProps<TEditorEngineTypeInput>, 'nodes'> & {
    /**
     * The parent ID of the nodes to be rendered.
     */
    parentId?: EditorEngineParentId;
};

/**
 * Returns a renderer that renders nodes by their parent ID.
 *
 * This function is used by the editor engine to create a renderer which will be
 * provided when creating an editor engine instance.
 */
export const getEditorEngineByParentRenderer = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    EditorEngineNodeRenderer,
}: {
    /**
     * The renderer being created will use this renderer to eventually render
     * the nodes. `EditorEngineNodeRendererProps` accepts an array of nodes,
     * while the renderer being created will accept a parent ID to obtain the
     * nodes using the node manager.
     */
    EditorEngineNodeRenderer: ComponentType<
        Omit<EditorEngineNodeRendererProps<TEditorEngineTypeInput>, 'context'>
    >;
}) => {
    /**
     * The renderer that renders nodes by their parent ID.
     */
    const EditorEngineByParentRenderer = ({
        parentId,
        ...rest
    }: EditorEngineByParentRendererProps<TEditorEngineTypeInput>) => {
        const { nodeManager } = useContext(rest.context);
        const useNodes = nodeManager.useNodes;

        const nodes = useNodes(parentId);

        return <EditorEngineNodeRenderer nodes={nodes} {...rest} />;
    };

    return EditorEngineByParentRenderer;
};
