import LanguageSwitchComp from '@/ui/components/LanguageSwitch';

import type { WrappedFieldInputProps } from 'redux-form';
import type { Language } from 'types/generic';

interface Props {
    initialValue?: Language;
    input: WrappedFieldInputProps;
    submit?: () => void;
}

const LanguageSwitch = ({ initialValue = 'de', input, submit }: Props) => {
    const handleChange = (language: string) => {
        const { onChange } = input;

        onChange(language);

        if (submit) {
            setTimeout(submit);
        }
    };

    return <LanguageSwitchComp language={initialValue} onLanguageSwitch={handleChange} />;
};

export default LanguageSwitch;
