import { DEFAULT_BLOCK_CONFIG } from '@/app/editor/editor/constants';

import { BorderMenuItem } from '@/app/editor/blocks/types';

import type { BlockConfig } from '@/app/editor/blocks/types';

const config = {
    ...DEFAULT_BLOCK_CONFIG,
    borderMenu: {
        ...DEFAULT_BLOCK_CONFIG.borderMenu,
        items: [
            BorderMenuItem.add,
            BorderMenuItem.move,
            BorderMenuItem.duplicate,
            BorderMenuItem.delete,
        ],
        addTooltip: 'add-new-column',
    },
    maxCount: 4,
} satisfies BlockConfig;

export default config;
