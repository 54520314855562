import { NAME } from '@/app/editor/blocks/constants';

import { StarIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect } from 'react';

import { FieldContainer } from '@/ui/components/_BlockEditFields/FieldContainer';
import { Slider } from '@/ui/components/_BlockEditFields/Slider';

import type { WrappedFieldInputProps } from 'redux-form';

interface Props {
    input: WrappedFieldInputProps;
    submit: () => void;
}

export const Rating = ({ input, submit }: Props) => {
    const { t } = useTranslation(NAME);
    const { value, onChange } = input;

    // Init with a rating of 5 if not set
    useEffect(() => {
        if (typeof value !== 'number') {
            onChange(5);
            setTimeout(submit);
        }
    }, [onChange, submit, value]);

    const handleChangeCommit = useCallback(
        (value: number[]) => {
            onChange(value[0]);

            setTimeout(submit);
        },
        [onChange, submit],
    );

    const handleChange = useCallback(
        (value: number[]) => {
            onChange(value[0]);
        },
        [onChange],
    );

    return (
        <FieldContainer>
            <Slider
                tooltip={
                    input.value === 1 ? t('1-star-rating') : t('rating', { value: input.value })
                }
                value={input.value}
                defaultValue={5}
                min={0}
                max={5}
                step={0.1}
                onChange={handleChange}
                onCommit={handleChangeCommit}
                Icon={StarIcon}
            />
        </FieldContainer>
    );
};
