import type { Placement } from '@floating-ui/core';

export const ORIGIN_MAP: {
    [placement in Placement]: string;
} = {
    'top-start': 'origin-bottom-left',
    top: 'origin-bottom',
    'top-end': 'origin-bottom-right',
    'right-start': 'origin-top-left',
    right: 'origin-left',
    'right-end': 'origin-bottom-left',
    'bottom-start': 'origin-top-left',
    bottom: 'origin-top',
    'bottom-end': 'origin-top-right',
    'left-start': 'origin-bottom-right',
    left: 'origin-right',
    'left-end': 'origin-bottom-right',
};
