import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { getCampaignLatestVersion } from '@/app/campaigns/helpers';
import { getCampaignVersionById } from '@/app/campaigns/models/versions';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, resourceArrayToObject } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { DOMAIN_TYPE_MAP, NAME } from '../constants';

import type { DomainResource } from '../types';
import type { CampaignResource } from '@/app/campaigns/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    isFetchingDomainList: boolean;
    domains: { [id: string]: DomainResource };
}

const initialState: State = {
    isFetchingDomainList: false,
    domains: EMPTY_OBJECT,
};

export const domainListSlice = createSlice({
    name: `${NAME}/domainList`,
    initialState,
    reducers: {
        setLoadingState(state: State, action: PayloadAction<boolean>) {
            state.isFetchingDomainList = action.payload;
        },
        setDomains(state: State, action: PayloadAction<{ [id: string]: DomainResource }>) {
            state.domains = action.payload;
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======

export const { setDomains, setLoadingState, reset } = domainListSlice.actions;

// === Selectors ======
export const getIsDomainListLoading = (state: AppState) =>
    state[NAME]?.domainListReducer?.isFetchingDomainList;

export const getDomains = (state: AppState): { [id: string]: DomainResource } =>
    state[NAME]?.domainListReducer?.domains;

export const getDomainIdList = (state: AppState): string[] => {
    const domains = getDomains(state);

    return Object.keys(domains);
};

export const getDomainById = (state: AppState, domainId: string): DomainResource => {
    return state[NAME]?.domainListReducer?.domains[domainId];
};

export const getCampaignLatestVersionDomain = (
    state: AppState,
    campaign: CampaignResource,
): DomainResource => {
    const campaignLatestVersion = getCampaignLatestVersion(campaign);
    const latestVersion = getCampaignVersionById(campaignLatestVersion?.id)(state);
    const domainId = get(latestVersion, 'relationships.domain.data.id');

    return getDomainById(state, domainId);
};

export const getDefaultDomains = createSelector(getDomains, (domains): DomainResource[] => {
    const domainsAsArray = Object.keys(domains).map((domainId: string) => domains[domainId]);

    return domainsAsArray.filter((domain) => domain?.attributes?.default);
});

// === Thunks ======

export const fetchAllDomains = () => async () => {
    const domainTypes = Object.values(DOMAIN_TYPE_MAP);

    const domainsResponses = await Promise.all(
        domainTypes.map((domainType) => {
            return apiGet<ResponseData>(`/domains/${domainType}`);
        }),
    );

    return domainsResponses.flatMap((domainResponse) => getDataFromResponse(domainResponse));
};

export const fetchDomainList = () => async (dispatch) => {
    dispatch(setLoadingState(true));

    try {
        const domains = await dispatch(fetchAllDomains());

        return await dispatch(setDomains(resourceArrayToObject(domains)));
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'fetching domain list failed:' });
    } finally {
        dispatch(setLoadingState(false));
    }
};

export default domainListSlice.reducer;
