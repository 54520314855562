import Image from 'next/image';

import IconFallback from '@/app/editor/blocks/components/_helper/IconFallback';
import { getDownloading } from '@/app/editor/iconLibrary/models/icons';
import { getColorByLightness, getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';
import LoadingIndicator from '@/ui/components/LoadingIndicator';
import { cn } from '@/utils/cn';

import type { ThemeResource } from '@/app/editor/themes/types';

export interface Props {
    blockId: string;
    color: string;
    activeTheme: ThemeResource;
    iconName: string;
    platform: string;
    bgColor: string;
    icon: string; // legacy icon
}

// Icon in Media Answer

const Icon = ({ blockId, color, activeTheme, iconName, platform, icon, bgColor }: Props) => {
    const downloadingBlockId = useAppSelector(getDownloading);

    // if themedColor === bgColor use color by lightness, otherwise it will be "invisible"
    const colorToDisplay = getColorByLightness(
        getThemedOrCustomColor(color, 'buttonBackgroundColor', activeTheme),
        bgColor,
    );

    const trimmedColor = colorToDisplay.replace('#', '');
    const isDownloading = blockId === downloadingBlockId;

    return (
        <div className="relative flex items-center justify-center aspect-4/3">
            {platform && iconName ? (
                <Image
                    width={64}
                    height={64}
                    src={`https://img.icons8.com/${trimmedColor}/${platform}/128/${iconName}`}
                    alt={iconName}
                    className={cn({ 'animate-pulse': isDownloading })}
                />
            ) : (
                <IconFallback iconName={icon} />
            )}

            <LoadingIndicator visible={isDownloading} className="absolute bottom-2 right-2" />
        </div>
    );
};

export default Icon;
