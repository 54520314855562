import type { HTMLProps } from 'react';

type Props = {
    /**
     * The value of the input.
     */
    value: string;
    /**
     * The function to call when the value of the input changes.
     */
    setValue: (value: string) => void;
} & HTMLProps<HTMLInputElement>;

/**
 * A simple input component used in debug components.
 */
export const Input = ({ value, setValue, ...rest }: Props) => (
    <input
        className="h-8 w-full rounded-md px-2 py-1 text-gray-800 outline outline-1 outline-neutral-300 focus:outline-blue-500"
        value={value}
        onChange={(event) => setValue(event.target.value)}
        {...rest}
    />
);
