import { getBlockComponentType } from '@/app/editor/blocks/helpers';
import { BorderMenuItem } from '@/app/editor/blocks/types';

import { DEFAULT_BLOCK_CONFIG } from '../../../editor/constants';

import type { BlockConfig, BlockResource } from '@/app/editor/blocks/types';

const config: BlockConfig = {
    ...DEFAULT_BLOCK_CONFIG,
    borderMenu: {
        ...DEFAULT_BLOCK_CONFIG.borderMenu,
        items: [
            BorderMenuItem.move,
            BorderMenuItem.delete,
            BorderMenuItem.add,
            BorderMenuItem.textSuggestion,
            BorderMenuItem.duplicate,
        ],
        hiddenForParents: [
            'questionMediaAnswer',
            'questionTextAnswer',
            'listItem',
            'accordionItem',
        ],
    },
    actions: {
        getAdditionalBlocksConfig: (block: BlockResource, parent: BlockResource) => {
            const parentComponentType = getBlockComponentType(parent);

            if (parentComponentType === 'listItem') {
                return [
                    {
                        paths: ['attributes.content.textSize'],
                        target: 'targetParent',
                    },
                ];
            }

            if (parentComponentType === 'questionMediaAnswer') {
                return [
                    {
                        paths: ['attributes.content.linking', 'attributes.content.fieldName'],
                        target: 'targetParent',
                    },
                ];
            }
        },
        getAdditionalBlocksDataTarget: (block: BlockResource, parent: BlockResource) => {
            const parentComponentType = getBlockComponentType(parent);

            if (parentComponentType === 'listItem') {
                return 'list';
            }

            return parentComponentType;
        },
    },
};

export default config;
