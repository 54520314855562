import type { EditorEngineDefaultDraggableConfiguration } from '@/app/editor/engine/core/types';
import type { ValueOrSetterFunction } from '@/app/editor/engine/core/types/util';

/**
 * Override part of the configuration while preserving the rest.
 */
export const extendDraggableConfiguration = <
    TConfiguration extends EditorEngineDefaultDraggableConfiguration,
>({
    canBeDragged,
    canTarget,
    canBeTargeted,
    executeDrop,
}: Partial<TConfiguration>) => {
    return ((previousConfiguration: TConfiguration) => {
        return {
            canBeDragged: canBeDragged ?? previousConfiguration.canBeDragged,
            canTarget: canTarget ?? previousConfiguration.canTarget,
            canBeTargeted: canBeTargeted ?? previousConfiguration.canBeTargeted,
            executeDrop: executeDrop ?? previousConfiguration.executeDrop,
            getMessageForOperation: previousConfiguration.getMessageForOperation,
            getDropPreviewOptionsForOperation:
                previousConfiguration.getDropPreviewOptionsForOperation,
        } satisfies EditorEngineDefaultDraggableConfiguration;
    }) as ValueOrSetterFunction<TConfiguration>;
};
