import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { getFavicons, setFavicons } from '@/app/funnelSettings/favicon/models/fetch';
import { apiDelete, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const deleteFaviconSlice = createSlice({
    name: `funnelSettings/${NAME}/delete`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = deleteFaviconSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.deleteFaviconReducer?.loading;

// === Thunks ======

export const deleteFavicon =
    (faviconId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const favicons = getFavicons(state);

        dispatch(setLoading(true));

        try {
            // optimistic remove
            const withoutFavicon = omit(favicons, faviconId);
            dispatch(setFavicons(withoutFavicon));

            // Remove from DB
            await apiDelete(`/image/favicon/${faviconId}`);
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'deleting favicon failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default deleteFaviconSlice.reducer;
