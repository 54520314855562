import { createPageIcon } from '@/app/editor/pages/helper';

import type { PageResource, PageType } from '@/app/editor/pages/types';
import type { DropdownOption } from '@/ui/types';

export const createPageOptions = (pages: PageResource[], type: PageType): DropdownOption[] => {
    return pages
        .filter((page) => !!page?.id)
        .map((page, index) => {
            return {
                key: page?.attributes?.name,
                value: page?.id,
                icon: createPageIcon(index, type),
            };
        });
};
