import type { Pagination, Relationship, RelationshipArray, Resource } from '@/core/api/types';

export type CampaignTrackingVersion = 'v3' | 'v4';

export const enum CampaignFilter {
    all = 'all',
    live = 'live',
    draft = 'draft',
    archived = 'archived',
    shared = 'shared',
    offline = 'offline',
    search = 'search',
    fav = 'fav',
}

export enum CampaignOrder {
    name = 'name',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    status = 'status',
    leads = 'analytics.leads',
    contacts = 'analytics.contacts',
    conversion = 'analytics.conversion',
    nameReverse = '-name',
    createdAtReverse = '-createdAt',
    updatedAtReverse = '-updatedAt',
    statusReverse = '-status',
    leadsReverse = '-analytics.leads',
    contactsReverse = '-analytics.contacts',
    conversionReverse = '-analytics.conversion',
    isFav = 'isFav',
    isFavReverse = '-isFav',
    trackingVersion = 'trackingVersion',
    trackingVersionReverse = '-trackingVersion',
}

export interface CampaignRelationships {
    activeVersion: Relationship;
    latestVersion: Relationship;
    company: Relationship;
    fields: RelationshipArray;
    footer: Relationship;
    header: Relationship;
    integrations: RelationshipArray;
    pages: RelationshipArray;
    questionMapping: Relationship;
    resultMapping: Relationship;
    theme: Relationship;
    workspace: Relationship;
}

export type CampaignStatus = null | 'live' | 'draft' | 'offline' | 'building';

export interface CampaignVersionAttributes {
    name: string;
    slug?: string;
    updatedAt: string;
    status?: CampaignStatus;
}

export interface CampaignVersionRelationships {
    campaign: Relationship;
    company: Relationship;
    domain: Relationship;
    launchEvent: Relationship;
    workspace: Relationship;
}

export type CampaignVersionResource = Resource<
    CampaignVersionAttributes,
    CampaignVersionRelationships
>;

export type CampaignSubheadlineStatus =
    | 'live'
    | 'building_estimate'
    | 'building_taking_too_long'
    | 'building_progress'
    | 'draft'
    | 'offline'
    | 'shared';

export const enum OverviewDisplayMode {
    grid = 'grid',
    list = 'list',
}

export type CampaignQuery = {
    page?: number;
    limit?: number;
    status?: string;
    order?: string;
    search?: string;
    archived?: string;
    shared?: string;
    fav?: string;
    workspaceId?: string;
    trackingVersion?: string;
};

export type CookieLayout = 'none' | 'explicit' | 'normal';

export interface CookieSettings {
    layout: CookieLayout;
    language: 'de' | 'en';
}

export interface LeadNotificationSettings {
    title: string;
    description: string;
}

export interface CampaignAttributes {
    launchedAt: string;
    metaTitle?: string;
    description?: string;
    favicon?: string;
    imprint?: string;
    status: CampaignStatus;
    name: string;
    url: string;
    fallbackUrl: string;
    showBadge: boolean;
    affiliateLink?: string;
    isArchived: boolean;
    showProgressBar: boolean;
    trackingVersion: CampaignTrackingVersion;
    isShared: boolean;
    isFav?: boolean;
    leadCount: number;
    visitorCount: number;
    createdAt: string;
    updatedAt: string;
    tags: [string];
    coverImage: string;
    cookieOverlay: CookieSettings;
    leadNotifications: LeadNotificationSettings;
}

export type CampaignResource = Resource<CampaignAttributes, CampaignRelationships>;

export type CampaignObj = Record<string, CampaignResource>;

export interface CampaignsWithPagination {
    pagination?: Pagination;
    campaigns?: CampaignResource[];
}

export const enum CampaignColumnName {
    Name = 'name',
    IsFav = 'isFav',
    Status = 'status',
    Contacts = 'contacts',
    Conversion = 'conversion',
    CRM = 'crm',
    Actions = 'actions',
}

export interface CampaignTableColumnItem {
    name: CampaignColumnName;
    text: string;
    sortable: boolean;
}

export enum EmbedMethod {
    Inline = 'inline',
    FullPage = 'fullPage',
    Popup = 'popup',
}

export interface EmbedFunnelOptions {
    method: EmbedMethod;
    header: boolean;
    footer: boolean;
    autoHeight: boolean;
    heightPixels: number;
    heightPercent: number;
    heightUnit: 'px' | '%';
    cookieBanner: boolean;
    button: {
        text: string;
        size: 'S' | 'M' | 'L' | 'XL';
        bold: boolean;
        italic: boolean;
        underline: boolean;
        align: 'left' | 'center';
        foregroundColor: string;
        backgroundColor: string;
    };
}

export interface EmbedSnippet {
    code: string;
    embedUrl: string;
    url: string;
    style: string;
    script: string;
}

export enum TestId {
    AllWorkspaces = 'AllWorkspaces',
    Campaigns = 'Campaigns',
    CampaignFilter = 'CampaignFilter',
    WorkspaceLogo = 'WorkspaceLogo',
    WorkspaceAvatar = 'WorkspaceAvatar',
    WorkspaceModal = 'WorkspaceModal',
    WorkspaceDeleteButton = 'WorkspaceDeleteButton',
    WorkspaceDomainError = 'WorkspaceDomainError',
    WorkspaceSetupContinue = 'WorkspaceSetupContinue',
    WorkspaceSetupSkip = 'WorkspaceSetupSkip',
    WorkspaceSetupIntroduction = 'WorkspaceSetupIntroduction',
    WorkspaceSetupMoveFunnels = 'WorkspaceSetupMoveFunnels',
    WorkspaceSetupComplete = 'WorkspaceSetupComplete',
    WorkspaceSetupInviteUserPressEnterButton = 'WorkspaceSetupInviteUserPressEnterButton',
}
