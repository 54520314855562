import { NAME } from '@/app/editor/blocks/constants';
import { DEFAULT_BG_IMAGE_SRC } from '@/app/editor/editor/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { change } from 'redux-form';

import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useAppDispatch } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import { ToggleButton } from '@/ui/components/_BlockEditFields/ToggleButton';

import type { MappedMedia } from '@/app/mediaLibrary/types';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    submit: () => void;
    isEmpty?: boolean;
}

export const BgImageToggle = ({ input, meta, submit, isEmpty }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const { onChange, value } = input;

    const handleToggle = useCallback(
        (checked: boolean) => {
            track(TRACKING_EVENTS.campaign.editor.bgImage.toggle, {});

            onChange(checked);

            if (isEmpty && checked) {
                dispatch(
                    change(meta.form, 'attributes.content.box.bgImage.src', DEFAULT_BG_IMAGE_SRC),
                );

                dispatch(
                    showModal(
                        Modals.MEDIA_LIBRARY,
                        {
                            currentSrc: null,
                            onChange: (media: MappedMedia) => {
                                dispatch(
                                    change(
                                        meta.form,
                                        'attributes.content.box.bgImage.src',
                                        media.url,
                                    ),
                                );
                                setTimeout(submit);
                            },
                            mediaType: 'image',
                        },
                        MODAL_OPTIONS[Modals.MEDIA_LIBRARY],
                    ),
                );
            }

            setTimeout(submit);
        },
        [dispatch, isEmpty, meta.form, onChange, submit],
    );

    return (
        <FieldContainer>
            <ToggleButton
                checked={!!value}
                onToggle={handleToggle}
                icon="image"
                tooltip={t('background-image')}
            />
        </FieldContainer>
    );
};
