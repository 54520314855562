import { cn } from '@/utils/cn';

export interface Props {
    className?: string;
}

export const BoxSizeIcon = ({ className }: Props) => (
    <div className={cn('size-5 flex-shrink-0', className)}>
        <svg width="100%" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1.09091L17 1.09091"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 5L9 13"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.67859 4.14645C8.8561 3.95118 9.1439 3.95118 9.32141 4.14645L12.8669 8.14645C13.0444 8.34171 13.0444 8.65829 12.8669 8.85355C12.6894 9.04881 12.4016 9.04881 12.224 8.85355L9 5.20711L5.77596 8.85355C5.59845 9.04882 5.31065 9.04882 5.13313 8.85355C4.95562 8.65829 4.95562 8.34171 5.13313 8.14645L8.67859 4.14645Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </div>
);
