import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { change } from 'redux-form';

import { getBlockById } from '@/app/editor/blocks/models/blocks';
import { generateIconObject } from '@/app/editor/iconLibrary/helper';
import { getCurrentPlatform, setCurrentPlatform } from '@/app/editor/iconLibrary/models/platforms';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, getMetaFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_STRING } from '@/utils/empty';

import { UpdateBlockCommand } from '../../commands/commands/updateBlockCommand';
import getHistoryController from '../../commands/utils/HistoryControllers';
import { NAME, DEFAULT_PLATFORM } from '../constants';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { Icon, IconResource } from '@/app/editor/iconLibrary/types';
import type { Pagination } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    fetching: boolean;
    fetchingPage: boolean;
    searching: boolean;
    icons: Icon[];
    selectedIcon: Icon;
    pagination: Pagination;
    searchTerm: string;
    downloading: string;
}

const initialState: State = {
    fetching: false,
    fetchingPage: false,
    searching: false,
    icons: EMPTY_ARRAY,
    selectedIcon: null,
    pagination: null,
    searchTerm: EMPTY_STRING,
    downloading: EMPTY_STRING,
};

export const iconsSlice = createSlice({
    name: `editor/${NAME}/icons`,
    initialState,
    reducers: {
        setFetching(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                fetching: action.payload,
            };
        },
        setSearching(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                searching: action.payload,
            };
        },
        setFetchingPage(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                fetchingPage: action.payload,
            };
        },
        setIcons(state, action: PayloadAction<Icon[]>) {
            return {
                ...state,
                icons: action.payload,
            };
        },
        setSearchTerm(state, action: PayloadAction<string>) {
            return {
                ...state,
                searchTerm: action.payload,
            };
        },
        setDownloading(state, action: PayloadAction<string>) {
            return {
                ...state,
                downloading: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<Pagination>) {
            return {
                ...state,
                pagination: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setFetching,
    setSearching,
    setIcons,
    setPagination,
    setFetchingPage,
    setSearchTerm,
    setDownloading,
    reset,
} = iconsSlice.actions;

// === Selectors ======

export const getFetching = (state: AppState) => state[NAME]?.iconsReducer?.fetching;

export const getFetchingPage = (state: AppState) => state[NAME]?.iconsReducer?.fetchingPage;

export const getSearching = (state: AppState) => state[NAME]?.iconsReducer?.searching;

export const getSearchTerm = (state: AppState) => state[NAME]?.iconsReducer?.searchTerm;

export const getPagination = (state: AppState) => state[NAME]?.iconsReducer?.pagination;

export const getIcons = (state: AppState) => state[NAME]?.iconsReducer?.icons;

export const getDownloading = (state: AppState) => state[NAME]?.iconsReducer?.downloading;

// === Thunks ======

// Helper function
export const setIconsFetching =
    (fetching: boolean): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const searchTerm = getSearchTerm(state);

        if (searchTerm) {
            dispatch(setSearching(fetching));
        } else {
            dispatch(setFetching(fetching));
        }
    };

// Helper function
const getIconsRequestUrl = (searchTerm: string, platform: string): string | null => {
    if (searchTerm) {
        // all-styles is not a "real" platform, so we don't want to include it in the request
        return platform === DEFAULT_PLATFORM
            ? `/components/icons/search?term=${searchTerm}`
            : `/components/icons/search?term=${searchTerm}&platform=${platform}`;
    }

    return platform === DEFAULT_PLATFORM
        ? '/components/icons'
        : platform
          ? `/components/icon-platforms/${platform}/icons`
          : null;
};

export const fetchIcons = (): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();
        const searchTerm = getSearchTerm(state);
        const platformId = getCurrentPlatform(state).id;

        dispatch(setIconsFetching(true));

        const url = getIconsRequestUrl(searchTerm, platformId);

        if (url) {
            try {
                const response = await apiGet(url);

                const icons = get(response, 'data.data', EMPTY_ARRAY).map((icon) =>
                    generateIconObject(icon),
                );

                dispatch(setIcons(icons));
                dispatch(setPagination(getMetaFromResponse(response)));
            } catch (err) {
                handleRuntimeError(err, { debugMessage: 'fetching icons failed:' });
            } finally {
                dispatch(setIconsFetching(false));
            }
        }
    };
};

export const setPlatformAndFetchIcons =
    (platformId: string, isColor: boolean): AppThunk =>
    async (dispatch) => {
        await dispatch(setCurrentPlatform({ id: platformId, isColor }));

        dispatch(fetchIcons());
    };

export const fetchNextIconsPage = (): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();
        const pagination = getPagination(state);
        const existingIcons = getIcons(state);

        dispatch(setFetchingPage(true));

        try {
            const response = await apiGet(pagination.next);

            const icons = getDataFromResponse(response, EMPTY_ARRAY).map((icon: IconResource) =>
                generateIconObject(icon),
            );

            const updatedIcons = existingIcons.concat(icons);

            dispatch(setIcons(updatedIcons));
            dispatch(setPagination(getMetaFromResponse(response)));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching icons page failed:' });
        } finally {
            dispatch(setFetchingPage(false));
        }
    };
};

export const downloadIcon =
    (icon: Icon, blockId: string, overrideCommand?: (values: BlockResource) => void): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const historyController = getHistoryController();

        dispatch(setDownloading(blockId));

        try {
            // start downloading svg
            const response = await apiGet<IconResource>(`/components/icons/${icon.id}`);

            const svg = response?.data?.attributes?.svg;

            // update block in DB after download completed
            if (svg) {
                // update in redux form -> visible change in editor
                await dispatch(change(blockId, 'attributes.content.icon', icon.id));
                await dispatch(change(blockId, 'attributes.content.iconName', icon.name));
                await dispatch(change(blockId, 'attributes.content.platform', icon.platform));
                await dispatch(change(blockId, 'attributes.content.svg', svg));

                const block = getBlockById(state, blockId);
                const updatedBlock = {
                    ...block,
                    attributes: {
                        ...block?.attributes,
                        content: {
                            ...block?.attributes?.content,
                            icon: icon.id,
                            iconName: icon.name,
                            platform: icon.platform,
                            svg,
                        },
                    },
                };

                if (overrideCommand) {
                    overrideCommand(updatedBlock);

                    return;
                }

                const updateBlockCommand = new UpdateBlockCommand(updatedBlock);

                historyController.executeCommand(updateBlockCommand);
            } else {
                throw 'Icon has no SVG';
            }
        } catch (err) {
            handleRuntimeError(err, { message: 'Downloading icon failed:' });
        } finally {
            dispatch(setDownloading(EMPTY_STRING));
        }
    };

export default iconsSlice.reducer;
