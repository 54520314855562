import { randomId } from '@/app/editor/engine/utils/randomId';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { EditorEngineParentId } from '@/app/editor/engine/core/types';
import type { getMakeBlockFromPreview } from '@/app/editor/engine/hooks/usePerspectiveDocumentManager/getMakeBlockFromPreview';
import type { PerspectiveEditorEngineNestedInsertNodeAtPayload } from '@/app/editor/engine/types';

export const getGetPreviewBlockPayloadsRecursively = ({
    getPreviewById,
    makeBlockFromPreview,
}: {
    getPreviewById: (id: string) => BlockResource;
    makeBlockFromPreview: ReturnType<typeof getMakeBlockFromPreview>;
}) => {
    const recursiveFunction = ({
        previewIds,
        parentId,
        index,
    }: {
        previewIds: string[];
        parentId: EditorEngineParentId;
        index: number;
    }): PerspectiveEditorEngineNestedInsertNodeAtPayload[] => {
        return previewIds.map((previewId, rootLevelIndex) => {
            const fakeId = randomId();
            const previewBlock = getPreviewById(previewId);
            const preview = makeBlockFromPreview({ id: fakeId, previewId });
            const children = previewBlock?.relationships?.components?.data || [];
            const childBlocks = children.reduce((previous, child, childIndex) => {
                return [
                    ...previous,
                    ...recursiveFunction({
                        previewIds: [child.id],
                        parentId: fakeId,
                        index: childIndex,
                    }),
                ];
            }, [] as PerspectiveEditorEngineNestedInsertNodeAtPayload[]);

            return {
                payload: {
                    node: {
                        block: preview,
                    },
                    parentId,
                    index: index + rootLevelIndex,
                },
                children: childBlocks,
            };
        });
    };

    return recursiveFunction;
};
