import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { PerspectiveEditorEnginePreviewAwareSequenceRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareSequenceRenderer';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';

export interface Props extends DefaultBlockComponentProps {}

const propsFromParent = { componentType: 'accordionItem' };

export const Accordion = ({ artBoardIndex, box, blockId, isPreview, isDragged }: Props) => {
    const { isInColumn } = useLayoutInfo(blockId, isPreview);

    return (
        <Box
            box={getBoxAttributes({ box, isInColumn })}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            <PerspectiveEditorEnginePreviewAwareSequenceRenderer
                parentId={blockId}
                className="flex-col space-y-4"
                propsFromParent={propsFromParent}
            />
        </Box>
    );
};
