import Image from 'next/image';
import { useEffect, useState } from 'react';

import { checkImageExists } from '@/app/editor/iconLibrary/helper';
import { cn } from '@/utils/cn';

import type { Icon } from '@/app/editor/iconLibrary/types';

export interface Props {
    icon: Icon;
    active: boolean;
    loading: boolean;
    onSelect: (icon: Icon) => void;
}

export const IconTile = ({ active, onSelect, loading, icon }: Props) => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [exists, setExists] = useState(true);

    useEffect(() => {
        checkImageExists(icon.src, (exists) => setExists(exists));
    }, [icon]);

    const handleClick = () => {
        onSelect(icon);
    };

    // icon could not be loaded from Icons8
    if (!exists) {
        return null;
    }

    const handleLoad = () => {
        setHasLoaded(true);
    };

    return (
        <div
            className={cn(
                'flex cursor-pointer items-center justify-center rounded-md bg-gray-100 p-2 ring-2 ring-inset transition aspect-square',
                active ? 'ring-blue-500' : 'ring-transparent hover:ring-gray-200',
                { 'pointer-events-none': loading },
            )}
            onClick={handleClick}
        >
            <Image
                width={55}
                height={55}
                src={icon.src}
                alt={icon.name}
                onLoad={handleLoad}
                className={cn('opacity-0 transition-opacity', {
                    'opacity-100': hasLoaded,
                })}
            />
        </div>
    );
};
