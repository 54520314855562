import { ART_BOARD_SIZE_LS_KEY, COLOR_PICKER_HISTORY_LS_KEY } from '@/app/editor/editor/constants';

import { ArtBoardSize } from '@/app/editor/editor/types';
import { reportMessage } from '@/utils/sentry';

import type { BlockConfig, BorderMenuItem } from '@/app/editor/blocks/types';
import type { SidebarViewId } from '@/app/editor/editor/types';
import type { ColorPickerHistory } from '@/ui/components/_BlockEditFields/ColorPicker';

export const getIsBorderMenuVisible = ({
    config,
    isDragPreview,
    parentComponentType,
}: {
    config: BlockConfig;
    isDragPreview: boolean;
    parentComponentType?: string;
}): boolean => {
    return (
        !config.borderMenu.hiddenForParents?.includes(parentComponentType) &&
        !(config.borderMenu.hidden || isDragPreview)
    );
};

export const getIsBlockSelectable = ({
    config,
    parentComponentType,
}: {
    config: BlockConfig;
    parentComponentType?: string;
}): boolean => {
    return config.selectable && !config.unselectableForParents?.includes(parentComponentType);
};

// Art board size
// ------------------------------

export const setArtboardSizeByCampaignIdInLS = (campaignId: string, size: ArtBoardSize): void => {
    const savedSizes = localStorage.getItem(ART_BOARD_SIZE_LS_KEY);

    try {
        const obj = JSON.parse(savedSizes);

        localStorage.setItem(
            ART_BOARD_SIZE_LS_KEY,
            JSON.stringify({
                ...obj,
                [campaignId]: size,
            }),
        );
    } catch (err) {
        // if something goes wrong, just recreate the whole LS entry
        localStorage.setItem(
            ART_BOARD_SIZE_LS_KEY,
            JSON.stringify({
                [campaignId]: size,
            }),
        );
    }
};

export const getArtboardSizeByCampaignIdFromLS = (campaignId: string): ArtBoardSize => {
    const savedSizes = localStorage.getItem(ART_BOARD_SIZE_LS_KEY);

    try {
        const obj = JSON.parse(savedSizes);

        return obj[campaignId] || ArtBoardSize.MOBILE;
    } catch (err) {
        return ArtBoardSize.MOBILE;
    }
};

export const removeArtboardSizeByCampaignIdFromLS = (campaignId: string): void => {
    const savedSizes = localStorage.getItem(ART_BOARD_SIZE_LS_KEY);

    try {
        const obj = JSON.parse(savedSizes);

        delete obj[campaignId];

        localStorage.setItem(ART_BOARD_SIZE_LS_KEY, JSON.stringify(obj));
    } catch (err) {
        // if something goes wrong, just reset the whole LS entry
        localStorage.setItem(ART_BOARD_SIZE_LS_KEY, JSON.stringify({}));
    }
};

const getColorPickerHistoryStorageObject = (): { [key: string]: ColorPickerHistory } => {
    const rawValue = localStorage.getItem(COLOR_PICKER_HISTORY_LS_KEY);

    if (rawValue) {
        try {
            const parsed = JSON.parse(rawValue);

            return parsed as { [key: string]: ColorPickerHistory };
        } catch (err) {
            reportMessage({
                message: `Failed to read the color picker history storage object/map from LocalStorage: ${err.message}`,
                source: 'local-storage',
            });
        }
    }

    return {};
};

export const getColorPickerHistoryForForm = (formId: string): ColorPickerHistory | undefined => {
    return getColorPickerHistoryStorageObject()[formId];
};

export const saveColorPickerHistoryForForm = (
    formId: string,
    colorPickerHistory: ColorPickerHistory,
) => {
    localStorage.setItem(
        COLOR_PICKER_HISTORY_LS_KEY,
        JSON.stringify({
            ...getColorPickerHistoryStorageObject(),
            [formId]: colorPickerHistory,
        }),
    );
};

export const getHasBorderItem = (blockConfig: BlockConfig, item: BorderMenuItem): boolean => {
    if (!blockConfig || blockConfig?.borderMenu?.hidden) {
        return false;
    }

    return blockConfig.borderMenu.items.includes(item);
};

export const getIsInMessagesSection = (view: SidebarViewId) => {
    return ['messages', 'addEmailBlock', 'editEmailBlock'].includes(view);
};
