import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useMemo } from 'react';

import { getSequenceRenderer } from '@/app/editor/engine/core/components/view/renderers/getSequenceRenderer';

import type { EditorEngineByParentRendererProps } from './getEditorEngineByParentRenderer';
import type { EditorEngineDefaultTypeInput } from '@/app/editor/engine/core/types';
import type { SortingStrategy } from '@dnd-kit/sortable';
import type { ComponentType } from 'react';

export type EditorEngineSequenceRendererProps<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = {
    /**
     * The class name to apply to the rendered sequence.
     */
    className?: string;
    /**
     * The sorting strategy to use for the sequence.
     */
    sortingStrategy?: SortingStrategy;
} & Omit<EditorEngineByParentRendererProps<TEditorEngineTypeInput>, 'renderer'>;

/**
 * Returns a renderer that renders a sequence of nodes.
 */
export const getEditorEngineSequenceRenderer = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    EditorEngineByParentRenderer,
}: {
    /**
     * The renderer to use to render nodes by their parent ID.
     */
    EditorEngineByParentRenderer: ComponentType<
        Omit<EditorEngineByParentRendererProps<TEditorEngineTypeInput>, 'context'>
    >;
}) => {
    /**
     * The renderer that renders a sequence of nodes.
     */
    const EditorEngineSequenceRenderer = ({
        className = '',
        sortingStrategy = verticalListSortingStrategy,
        ...rest
    }: EditorEngineSequenceRendererProps<TEditorEngineTypeInput>) => {
        const renderer = useMemo(
            () =>
                getSequenceRenderer({
                    className,
                    sortingStrategy,
                }),
            [className, sortingStrategy],
        );

        return <EditorEngineByParentRenderer {...rest} renderer={renderer} />;
    };

    return EditorEngineSequenceRenderer;
};
