import { CAMPAIGN_STATUS, FILTER_QUERY_MAP } from '../campaigns/constants';
import { CampaignFilter } from '../campaigns/types';

export const parseCommand = (value: string) => {
    const matches = value.match(/\[(.*?)\]/);

    if (matches) {
        return matches[1];
    }

    return '';
};

export const createCampaignsQuery = ({
    search = '',
    workspaceIds,
    canUpdateCampaign,
}: {
    search?: string;
    workspaceIds: string[];
    canUpdateCampaign: boolean;
}) => ({
    ...FILTER_QUERY_MAP[CampaignFilter.search],
    page: 1,
    limit: 20,
    archived: 'false',
    workspaceId: workspaceIds.join(','),
    ...(search ? { search } : {}),
    ...(canUpdateCampaign
        ? {}
        : {
              status: CAMPAIGN_STATUS.live,
              trackingVersion: 'v4',
          }),
});

export const getIsCmdK = (evt: KeyboardEvent) => evt.key === 'k' && evt.metaKey;
