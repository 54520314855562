import { NAME } from '@/app/crm/constants';

import { useTranslation } from 'react-i18next';

import LoadingSpinner from '@/ui/components/LoadingSpinner';
import Tooltip from '@/ui/components/Tooltip';

export const FileInfoLoadingActions = () => {
    const { t } = useTranslation(NAME);

    return (
        <Tooltip content={t('uploading-file')}>
            <div className="flex size-6 shrink-0 cursor-pointer items-center justify-center rounded-md text-sm text-gray-500 transition-colors hover:bg-gray-200">
                <LoadingSpinner size="tiny" className="text-gray-500" />
            </div>
        </Tooltip>
    );
};
