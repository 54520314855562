import { CustomHtmlEmbed } from '@/app/editor/blocks/components/Embed/components/CustomHtmlEmbed';
import { PredefinedEmbeds } from '@/app/editor/blocks/components/Embed/components/PredefinedEmbeds';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';

export interface EmbedProps extends DefaultBlockComponentProps {
    provider: string;
    head: string;
    body: string;
    backgroundColor?: string;
    artBoardIndex?: number;
    enforceCenteredCustomHtmlContent?: boolean;
}

export const Component = (props: EmbedProps) => {
    // Embed for "custom-html" providers shall be embedded in a separate
    // cross-origin iframe. This is not necessary for the other embeds.
    // That's why I (Peter) favored separate implementations instead
    // of one big generic component to keep things simple.

    // In contrast to the funnel-app, we here also display
    // HTML embeds with the option "enableCustomHtmlEmbedBlockNativeEmbed = true"
    // in a sandbox. This option only affects the funnel-app.

    if (props.provider === 'custom-html') {
        return <CustomHtmlEmbed {...props} />;
    }

    return <PredefinedEmbeds {...props} />;
};

export default Component;
