import type {
    EditorEngineHistoryDefaultEntry,
    EditorEngineHistoryEntryCollectionSetter,
    EditorEngineHistoryEntryRecordedActionResult,
} from '@/app/editor/engine/core/types';

interface Input {
    /**
     * The entry to update.
     */
    entry: EditorEngineHistoryDefaultEntry;
    /**
     * The recorded action result to set.
     */
    result: EditorEngineHistoryEntryRecordedActionResult;
    /**
     * A function to set the entries information.
     */
    setEntriesInfo: EditorEngineHistoryEntryCollectionSetter;
}

/**
 * Update the recorded action result of an entry.
 *
 * Whenever some handler would like to update the result of the record of an
 * action, it can use this utility to do so.
 *
 * todo(editorengine): possibly optimize this function
 */
export const updateEntryActionRecordedResult = ({ entry, result, setEntriesInfo }: Input) => {
    setEntriesInfo((oldEntriesInfo) => ({
        ...oldEntriesInfo,
        entries: oldEntriesInfo.entries.map((eachEntry) => {
            if (eachEntry.id !== entry.id) {
                return eachEntry;
            }

            return {
                ...eachEntry,
                recordedAction: {
                    ...eachEntry.recordedAction,
                    executionResult: result,
                },
            };
        }),
    }));
};
