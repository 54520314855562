import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { Field, reduxForm } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import CalendlyForm from '@/app/editor/blocks/components/QuestionForm/CalendlyForm';
import { getIsTipTapData } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getBlockById } from '@/app/editor/blocks/models/blocks';
import { UpdateBlockCommand } from '@/app/editor/commands/commands/updateBlockCommand';
import getHistoryController from '@/app/editor/commands/utils/HistoryControllers';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import Personalization from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Personalization';
import TextAlign from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextAlign';
import { TextSize } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextSize';
import TextStyle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextStyle';
import TrackingIdInput from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TrackingIdInput';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { usePerspectiveEditorEngine } from '@/app/editor/engine/PerspectiveEditorEngine';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';
import { SidebarPortalTarget } from '@/ui/components/_BlockEditFields/SidebarPortalTarget';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({
    blockId,
    handleSubmit,
    activeTheme,
    initialValues,
}) => {
    const { actions, isActive: isWithinEditorEngine } = usePerspectiveEditorEngine();
    const { t } = useTranslation(NAME);
    const historyController = getHistoryController();

    const childFormId = initialValues?.relationships?.components?.data[0]?.id;
    const formBlock = useAppSelector((state) => getBlockById(state, childFormId));
    const isCalendlyForm = formBlock?.attributes?.componentType === 'formURL';
    const { isInColumn } = useLayoutInfo(blockId);

    const isTipTap = getIsTipTapData(initialValues?.attributes?.content?.wysiwyg);

    const handleCalendlySubmit = (values: BlockResource) => {
        if (isWithinEditorEngine) {
            actions.changeBlock.enqueue({
                values,
            });
        } else {
            const updateBlockCommand = new UpdateBlockCommand(values);

            void historyController.executeCommand(updateBlockCommand);
        }
    };

    return (
        <>
            {isCalendlyForm && (
                <CalendlyForm
                    form={formBlock.id}
                    initialValues={formBlock}
                    onSubmit={handleCalendlySubmit}
                />
            )}

            {isTipTap ? (
                <>
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-size-portal-${blockId}`} />
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-styles-portal-${blockId}`} />
                </>
            ) : (
                <>
                    <TextSize textBlockId={blockId} />
                    <TextStyle textBlockId={blockId} />
                </>
            )}

            <div className="grid grid-cols-3 gap-2">
                <Field
                    name="attributes.content.align"
                    component={TextAlign}
                    submit={handleSubmit}
                />

                {isTipTap ? (
                    <>
                        <SidebarPortalTarget id={`tip-tap-sidebar-text-color-portal-${blockId}`} />
                        <SidebarPortalTarget
                            id={`tip-tap-sidebar-personalization-portal-${blockId}`}
                        />
                    </>
                ) : (
                    <>
                        <Field
                            name="attributes.content.color"
                            component={Color}
                            expand="center"
                            submit={handleSubmit}
                            themeColor={getThemeColor(activeTheme, 'fontColor')}
                            tooltip={t('font-color')}
                        />
                        <Personalization blockId={blockId} />
                    </>
                )}
            </div>

            <Subheading text={t('tracking')} />

            <div className="mt-4">
                <Field
                    name="attributes.content.fieldName"
                    component={TrackingIdInput}
                    submit={handleSubmit}
                />
            </div>

            <BoxSettings
                blockId={blockId}
                activeTheme={activeTheme}
                handleSubmit={handleSubmit}
                hiddenColor={isInColumn}
            />
        </>
    );
};

Form.displayName = 'QuestionFormEditForm';

export default reduxForm({})(Form);
