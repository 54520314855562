import { createSlice } from '@reduxjs/toolkit';
import formatISO from 'date-fns/formatISO';

import { getNow } from '@/utils/common';

import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    startDate?: string;
    endDate?: string;
}

const initialState: State = {
    startDate: null,
    endDate: null,
};

export const dateRangePickerSlice = createSlice({
    name: `${NAME}/dateRangePicker`,
    initialState,
    reducers: {
        setStartDate(state, action: PayloadAction<string>) {
            return {
                ...state,
                startDate: action.payload,
            };
        },
        setEndDate(state, action: PayloadAction<string>) {
            return {
                ...state,
                endDate: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setStartDate, setEndDate, reset } = dateRangePickerSlice.actions;

// === Selectors ======

export const getStartDate = (state: AppState) => state[NAME]?.dateRangePickerReducer?.startDate;
export const getEndDate = (state: AppState) => state[NAME]?.dateRangePickerReducer?.endDate;

// === Thunks ======

export const setDateRange =
    ({
        startDate,
        endDate,
        createdAt,
    }: {
        startDate?: Date;
        endDate?: Date;
        createdAt?: Date;
    }): AppThunk =>
    async (dispatch) => {
        dispatch(setStartDate(formatISO(startDate || createdAt)));
        dispatch(setEndDate(formatISO(endDate || getNow())));
    };

export default dateRangePickerSlice.reducer;
