import { useMemo } from 'react';

import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';

import type { BoxSizeOption, DefaultBlockComponentProps } from '../../types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    dashed: boolean;
    hidden: boolean;
    color: string;
}

const Component: FC<Props> = ({
    blockId,
    artBoardIndex,
    box,
    dashed,
    hidden,
    color,
    activeTheme,
    isDragged,
}) => {
    const { isInColumn } = useLayoutInfo(blockId);
    const borderClassName = cn({
        'border-t': !hidden,
        'border-t border-dashed': dashed,
    });

    const dividerBox = useMemo(() => {
        if (isInColumn) {
            return {
                ...box,
                left: 'none' as BoxSizeOption,
                right: 'none' as BoxSizeOption,
            };
        }

        return box;
    }, [box, isInColumn]);

    return (
        <Box box={dividerBox} isDragged={isDragged} artBoardIndex={artBoardIndex}>
            <div className="h-0 select-none py-5">
                <div
                    className={borderClassName}
                    style={{
                        borderColor: getThemedOrCustomColor(color, 'fontColor', activeTheme),
                    }}
                />
            </div>
        </Box>
    );
};

Component.displayName = 'Divider';

export default Component;
