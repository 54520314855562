import {
    type EditorEngineDefaultTypeInput,
    type EditorEngineEventEmitter,
    EditorEngineEventType,
    EditorEngineExecutionDirection,
    EditorEngineHistoryEntryStepForeachFilter,
} from '@/app/editor/engine/core/types';
import { appendEntry } from '@/app/editor/engine/core/utils/history/appendEntry';
import { canRetryEntry } from '@/app/editor/engine/core/utils/history/canRetryEntry';
import { executeEntry } from '@/app/editor/engine/core/utils/history/executeEntry';
import { getCurrentEntry } from '@/app/editor/engine/core/utils/history/getCurrentEntry';
import { isLatestEntry } from '@/app/editor/engine/core/utils/history/isLatestEntry';
import { makeNewEntry } from '@/app/editor/engine/core/utils/history/makeNewEntry';
import { removeEntriesAfterEntry } from '@/app/editor/engine/core/utils/history/removeEntriesAfterEntry';
import { updateEntryActionRecordedResult } from '@/app/editor/engine/core/utils/history/updateEntryActionRecordedResult';
import { updateEntryStepRecordedResult } from '@/app/editor/engine/core/utils/history/updateEntryStepRecordedResult';
import { withCurrentEntry } from '@/app/editor/engine/core/utils/history/withCurrentEntry';

import type { useProcessingQueue } from '@/app/editor/engine/core/hooks/queue/useProcessingQueue';
import type {
    EditorEngineAction,
    EditorEngineHistoryEntryCollection,
    EditorEnginePersistedUpdate,
} from '@/app/editor/engine/core/types';
import type { Dispatch, SetStateAction } from 'react';

interface Input<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> {
    /**
     * The collection of history entries.
     */
    entriesInfo: EditorEngineHistoryEntryCollection<TEditorEngineTypeInput>;
    /**
     * This function will be called to update the collection of history entries.
     */
    setEntries: Dispatch<
        SetStateAction<EditorEngineHistoryEntryCollection<TEditorEngineTypeInput>>
    >;
    /**
     * The document manager instance.
     */
    documentManager: TEditorEngineTypeInput['DocumentManager'];
    /**
     * The node manager instance.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
    /**
     * Extra context to be provided.
     */
    extraContext: TEditorEngineTypeInput['ExtraContext'];
    /**
     * This function will be called to add a persisted update to the queue.
     */
    addUpdateToQueue: ReturnType<
        typeof useProcessingQueue<EditorEnginePersistedUpdate>
    >['addItemToQueue'];
    /**
     * The event emitter instance.
     */
    eventEmitter: EditorEngineEventEmitter;
}

/**
 * The Editor Engine history has two queues: one for processing an action when
 * it is enqueued (including its optimistic update), and one for processing the
 * persisted update of each action.
 *
 * This function will be passed to the queue managed by `useProcessingQueue`
 * for all enqueued actions. Whenever the user triggers an action, this function
 * will be called by `useProcessingQueue` (when appropriate) so that a new
 * history entry is created and appended to the history.
 *
 * Finally, at the end of the processing, the persisted update will be queued
 * in its own queue. The items in that queue will be processed by a different
 * function.
 */
export const getProcessActionFunction = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    entriesInfo,
    setEntries,
    documentManager,
    nodeManager,
    extraContext,
    addUpdateToQueue,
    eventEmitter,
}: Input<TEditorEngineTypeInput>) => {
    const canRetryCurrent = withCurrentEntry(canRetryEntry, false)({ entriesInfo });
    const isLatestNavigable = withCurrentEntry(isLatestEntry, false)({ entriesInfo });

    return async ({ action }: { action: EditorEngineAction<TEditorEngineTypeInput> }) => {
        if (action.shouldBeSkipped?.({ documentManager, nodeManager, extraContext })) {
            eventEmitter.emit({
                name: EditorEngineEventType.ActionSkipped,
                payload: {
                    action,
                },
            });

            return;
        }

        if (canRetryCurrent || !isLatestNavigable) {
            // If we are queueing a new action and the current action can be
            // retried, we need to remove the current action and all the actions
            // after it, since by enqueueing a new action we are effectively
            // waiving the ability to retry the current action.
            // OR
            // If we are queueing a new action and there are redoable actions
            // after the current one, we need to remove them.
            const currentEntry = getCurrentEntry({
                entriesInfo,
            });
            removeEntriesAfterEntry({
                entry: currentEntry,
                entriesInfo,
                setEntriesInfo: setEntries,
            });
        }

        const transactionPreparationResult = action?.prepareTransaction
            ? await action.prepareTransaction()
            : {};

        // Add the new action to the history, and increment the current index.
        const newEntry = appendEntry({
            entry: makeNewEntry({
                action,
                documentManager,
                nodeManager,
                extraContext,
                transactionPreparationResult,
            }),
            entriesInfo,
            setEntries,
        });

        // Execute the new entry
        // When the entry is executed, the result is updated to reflect the
        // current state of the execution.
        await executeEntry({
            entry: newEntry,
            nextCurrent: newEntry,
            filter: EditorEngineHistoryEntryStepForeachFilter.All,
            direction: EditorEngineExecutionDirection.Forward,
            updateStep: ({ step, result }) => {
                updateEntryStepRecordedResult({
                    entry: newEntry,
                    step,
                    setEntriesInfo: setEntries,
                    result,
                });
            },
            updateAction: ({ result }) => {
                updateEntryActionRecordedResult({
                    entry: newEntry,
                    setEntriesInfo: setEntries,
                    result,
                });
            },
            reportError: (error) => {
                eventEmitter.emit({
                    name: EditorEngineEventType.HistoryError,
                    payload: {
                        error,
                    },
                });
            },
            updateEntries: setEntries,
            addUpdateToQueue,
            extraContext,
            eventEmitter,
            isReExecution: false,
        });
    };
};
