// In lists
export const listItemVisualSizeHorizontalMap = {
    small: 48,
    medium: 64,
    large: 80,
    xLarge: 96,
};

export const listItemVisualSizeVerticalMap = {
    small: 48,
    medium: 120,
    large: 192,
    xLarge: 264,
};

export const listItemEmojiPaddingMap = {
    small: 8,
    medium: 24,
    large: 44,
    xLarge: 68,
};
