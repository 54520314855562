import { useMemo, useRef } from 'react';

import { getAddListener } from '@/app/editor/engine/core/utils/events/getAddListener';
import { getEmit } from '@/app/editor/engine/core/utils/events/getEmit';
import { getRemoveListener } from '@/app/editor/engine/core/utils/events/getRemoveListener';

import type { EditorEngineEventListenerMap } from '@/app/editor/engine/core/types';

/**
 * Hook that creates an event emitter for the editor engine.
 */
export const useEditorEngineEventEmitter = () => {
    const listenersRef = useRef<EditorEngineEventListenerMap>({});
    const emit = useMemo(() => getEmit({ listeners: listenersRef }), []);
    const removeListener = useMemo(() => getRemoveListener({ listeners: listenersRef }), []);
    const addListener = useMemo(
        () => getAddListener({ listeners: listenersRef, removeListener }),

        [removeListener],
    );

    return useMemo(
        () => ({
            emit,
            addListener,
            removeListener,
        }),
        [emit, addListener, removeListener],
    );
};
