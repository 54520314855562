import { XMarkIcon } from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';

import { getIsPerspectiveAdmin } from '@/app/user/helper';
import { getUser } from '@/app/user/models/user';
import { useAppSelector } from '@/core/redux/hooks';

export const SchnitzelBanner = () => {
    const user = useAppSelector(getUser);
    const isSchnitzel = useMemo(() => getIsPerspectiveAdmin(user), [user]);

    const [visible, setVisible] = useState(isSchnitzel);

    if (!visible) {
        return null;
    }

    const handleDismiss = () => setVisible(false);

    return (
        <div className="fixed top-0 z-50 flex w-full items-center justify-center border-t-2 border-amber-500">
            <div className="flex w-fit items-center gap-2 rounded-b-md bg-amber-500 py-1 pl-3 pr-2 text-xs text-white">
                <span className="font-mono">Schnitzel User</span>
                <button
                    onClick={handleDismiss}
                    className="opacity-50 transition-opacity hover:opacity-100"
                    title="Hide banner"
                >
                    <XMarkIcon className="size-4" />
                </button>
            </div>
        </div>
    );
};
