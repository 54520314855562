import { getBlockConfig } from '@/app/editor/blocks/helpers';
import { BorderMenuItem } from '@/app/editor/blocks/types';
import { getHasBorderItem } from '@/app/editor/editor/helper';
import { useBlockDetails } from '@/app/editor/editor/hooks/useBlockDetails';
import { cn } from '@/utils/cn';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

export interface Props {
    block: BlockResource;
    dragListeners: SyntheticListenerMap;
    dragAttributes: DraggableAttributes;
    isDragged: boolean;
    isDraggingDisabled?: boolean;
    isGray?: boolean;
}

export const Label = ({
    block,
    dragListeners,
    dragAttributes,
    isDragged,
    isDraggingDisabled = false,
    isGray = false,
}: Props) => {
    const { title } = useBlockDetails(block);
    const blockConfig = getBlockConfig(block?.attributes?.componentType);
    const canMove = getHasBorderItem(blockConfig, BorderMenuItem.move);

    return (
        <div
            className={cn(
                'absolute -top-8 left-2 z-20 flex h-6 items-center rounded-lg bg-blue-500 px-2 font-sans text-xs font-semibold text-white antialiased',
                {
                    'cursor-grab': canMove && !isDraggingDisabled,
                    'cursor-grabbing': isDragged,
                    'bg-gray-100 text-gray-500 shadow-button-inset': isGray,
                },
            )}
            {...dragListeners}
            {...dragAttributes}
        >
            {title}
        </div>
    );
};
