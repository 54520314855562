import { Children, isValidElement, cloneElement } from 'react';

import NewComponent from '@/app/editor/blocks/components/ListItem/NewComponent';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '../../types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({ children, componentType, align, borderRadius, visualSize, globalSize }) => {
        const childBlocks = Children.toArray(children);
        const imageProps = {
            align,
            wrapperClass: 'w-full',
            borderRadius,
            visualSize,
        };

        return align === 'left' ? (
            <div className="flex items-start p-1">
                <div
                    className={cn('flex-shrink-0 transition-all duration-200 ease-out', {
                        'w-12': visualSize === 'small' || !visualSize,
                        'w-16': visualSize === 'medium',
                        'w-20': visualSize === 'large',
                        'w-24': visualSize === 'xLarge',
                    })}
                >
                    {isValidElement<BlockProps>(childBlocks[0])
                        ? cloneElement(childBlocks[0], {
                              propsFromParent: imageProps,
                          })
                        : null}
                </div>
                <div className="ml-6 min-w-0 self-center">
                    {isValidElement<BlockProps>(childBlocks[1])
                        ? cloneElement(childBlocks[1], {
                              propsFromParent: {
                                  fixedAlign: 'left',
                                  parentComponentType: componentType,
                                  globalSize,
                              },
                          })
                        : null}
                </div>
            </div>
        ) : (
            <div className="flex flex-col items-center justify-center p-1">
                <div
                    className={cn('transition-all duration-200 ease-out', {
                        'w-12': visualSize === 'small' || !visualSize,
                        'w-[120px]': visualSize === 'medium',
                        'w-48': visualSize === 'large',
                        'w-[264px]': visualSize === 'xLarge',
                    })}
                >
                    {isValidElement<BlockProps>(childBlocks[0])
                        ? cloneElement(childBlocks[0], {
                              propsFromParent: imageProps,
                          })
                        : null}
                </div>
                <div className="mt-4 min-w-0">
                    {isValidElement<BlockProps>(childBlocks[1])
                        ? cloneElement(childBlocks[1], {
                              propsFromParent: {
                                  fixedAlign: 'center',
                                  parentComponentType: componentType,
                                  globalSize,
                              },
                          })
                        : null}
                </div>
            </div>
        );
    },
    NewComponent,
);

Component.displayName = 'ListItem';

export default Component;
