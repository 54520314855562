import psl from 'psl';

import type { ParsedDomain } from 'psl';

const extractHostname = (url: string) => {
    let hostname: string;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
};

export const getServiceByUrl = (url: string) => {
    const hostname = extractHostname(url);
    const parsed = psl.parse(hostname) as ParsedDomain;

    return parsed.sld;
};
