import { UserRole } from './types';

import type { UserResource } from './types';

export const getIsCrmUser = (user: UserResource) => user?.attributes?.role === UserRole.crm;

export const getIsAdmin = (user: UserResource) =>
    user?.attributes?.role === UserRole.admin || user?.attributes?.role === UserRole.schnitzel;

export const getIsPerspectiveAdmin = (user: UserResource) =>
    user?.attributes?.role === UserRole.schnitzel;

export const getUserHasEnabledNotifications = (user: UserResource) => {
    const userNotifs = user?.attributes?.notifications;

    return Object.keys(userNotifs).some((key) => !!userNotifs[key]);
};
