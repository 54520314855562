import { Children } from 'react';

import SortableList from '@/app/editor/blocks/components/_wrapper/SortableList';
import NewComponent from '@/app/editor/blocks/components/Form/NewComponent';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { cn } from '@/utils/cn';

import type { Alignment, DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({ children, blockId, nestedLevel, isPreview, align }) => {
        const childBlocks = Children.toArray(children);
        const artBoardSize = useArtboardSize();

        // childBlocks become the form inputs here, since we pop away the submit button
        const submitButton = childBlocks.pop();

        // Align from question block
        const isCentered = align === 'center';

        return (
            <div className="mt-6">
                <div
                    className={cn({
                        'w-full max-w-xl': artBoardSize === ArtBoardSize.TABLET,
                        'mx-auto': artBoardSize === ArtBoardSize.TABLET && isCentered,
                    })}
                >
                    <div>
                        {isPreview ? (
                            // No sorting required for preview
                            <div className="flex flex-col space-y-4">{childBlocks}</div>
                        ) : (
                            <SortableList
                                parentBlockId={blockId}
                                sortingStrategy="vertical"
                                nestedLevel={nestedLevel}
                                className="space-y-4"
                            >
                                {childBlocks}
                            </SortableList>
                        )}
                    </div>

                    <div className="mt-4">{submitButton}</div>
                </div>
            </div>
        );
    },
    NewComponent,
);

Component.displayName = 'Form';

export default Component;
