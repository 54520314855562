import Label from './Label';

export interface Props {
    hotkeys: string[];
}

const HotkeyLabels = ({ hotkeys = [] }: Props) => {
    if (!hotkeys.length) {
        return null;
    }

    return (
        <div className="ml-2 flex items-center space-x-1">
            {hotkeys.map((hotkey) => (
                <Label key={hotkey} hotkey={hotkey} />
            ))}
        </div>
    );
};

export default HotkeyLabels;
