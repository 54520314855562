import { UserRole } from '@/app/user/types';
import { WorkspaceInvitationRoles } from '@/app/workspaces/types';

export const NAME = 'workspaces';
export const NUMBER_OF_SETUP_STEPS = 3;
export const DEFAULT_AVATAR_LETTER = 'A';
export const WORKSPACE_DOMAIN_HOST = process.env.NEXT_PUBLIC_WORKSPACE_DOMAIN;
export const FUNNEL_DEBOUNCE_TIMEOUT = 200;
export const HAS_CLOSED_WORKSPACE_SETTINGS_BADGE = 'has-closed-workspace-settings-badge';
export const MAX_INVITES_COUNT = 10;
export const CREATE_WORKSPACE_PATH = '/workspaces/new';
export const MAX_WORKSPACE_NAME_LENGTH = 40;
export const REPEATED_HYPHEN_REGEX = /--/;
export const WS_LOGO_MAX_RENDERED_SIZE = 200;
export const WS_LOGO_MAX_UPLOAD_SIZE = 3000;
export const LS_LAST_ACTIVE_WORKSPACE_ID_KEY = 'lastActiveWorkspaceId';
export const THREE_SECONDS_IN_MS = 3000;

export const ENDPOINTS = {
    GET: {
        workspacesWithKpis: '/workspaces?kpis=true',
        workspaceMembers: (workspaceId: string) => `/workspaces/${workspaceId}/users`,
        workspaceInvites: (workspaceId: string) => `/workspaces/${workspaceId}/invitations`,
    },
    PATCH: {
        updateMemberRole: (userId: string) => `/users/${userId}/role`,
    },
    POST: {
        cancelWorkspaceInvite: (eventId: string) => `/events/userInvitation/${eventId}/cancel`,
        sendWorkspaceInvite: () => '/events/userInvitation',
    },
    DELETE: {
        removeWorkspaceMember: (workspaceId: string, userId: string) =>
            `/workspaces/${workspaceId}/users/${userId}`,
    },
} as const;

export const MUTATION_KEYS = {
    updateMemberRole: (workspaceId: string) => [
        'workspaces',
        'members',
        'update',
        'role',
        workspaceId,
    ],
    cancelWorkspaceInvite: (workspaceId: string) => [
        'workspaces',
        'invites',
        'cancel',
        workspaceId,
    ],
    removeWorkspaceMember: (workspaceId: string) => [
        'workspaces',
        'members',
        'remove',
        workspaceId,
    ],
    sendWorkspaceInvite: (workspaceId: string) => ['workspaces', 'invites', 'send', workspaceId],
} as const;

export const QUERY_KEYS = {
    workspacesWithKpis: ['workspaces', 'kpis'],
    allMembersInAllWorkspaces: ['workspaces', 'members'],
    allWorkspaceMembers: (workspaceId: string) => ['workspaces', 'members', workspaceId],
    allWorkspaceInvites: (workspaceId: string) => ['workspaces', 'invites', workspaceId],
} as const;

export const AVAILABLE_INVITE_ROLES = {
    [UserRole.schnitzel]: Object.values(WorkspaceInvitationRoles),
    [UserRole.admin]: Object.values(WorkspaceInvitationRoles),
    [UserRole.workspaceAdmin]: [
        WorkspaceInvitationRoles.crm,
        WorkspaceInvitationRoles.editor,
        WorkspaceInvitationRoles.workspaceAdmin,
    ],
    [UserRole.editor]: [WorkspaceInvitationRoles.editor],
    [UserRole.crm]: [],
};

// Part of the `advancedRoles` FF
export const ADVANCED_ROLES = [
    WorkspaceInvitationRoles.editor,
    WorkspaceInvitationRoles.workspaceAdmin,
];
