import { EditorEngineHistoryEntryExecutionAvailability } from '@/app/editor/engine/core/types';

/**
 Whether the user can redo an action with the given execution availability.
 */
export const canUndo = (executionAvailability: EditorEngineHistoryEntryExecutionAvailability) => {
    return [
        EditorEngineHistoryEntryExecutionAvailability.Successful,
        EditorEngineHistoryEntryExecutionAvailability.Failed,
        EditorEngineHistoryEntryExecutionAvailability.OngoingOptimisticUpdate,
        EditorEngineHistoryEntryExecutionAvailability.OngoingPersistedUpdate,
        EditorEngineHistoryEntryExecutionAvailability.QueuedForPersistedUpdate,
    ].includes(executionAvailability);
};
