import { assertNever } from '@/utils/validations';

import { FILTERABLE_FIELD_NAMES } from '../constants';

import type { CRMFieldName, CRMFilter, CRMFilterType, SchemaResource } from '../types';

type GetDefaultFilter = { defaultFilterType: CRMFilterType; defaultValue: string | null };

const SCHEMA_ATTR_TO_FILTER_FIELD = new Map([
    ['address.postalCode', 'zip'],
    ['address.country', 'country'],
    ['address.city', 'city'],
    ['address.street', 'street'],
]);

export const getDefaultFilter = (fieldName: CRMFieldName): GetDefaultFilter => {
    switch (fieldName) {
        case 'status':
            return {
                defaultFilterType: 'in',
                defaultValue: null,
            };
        case 'createdAt':
            return {
                defaultFilterType: 'relative-more-than',
                defaultValue: '1',
            };
        case 'firstName':
        case 'lastName':
        case 'person':
        case 'email':
        case 'city':
        case 'country':
        case 'phone':
        case 'street':
        case 'website':
        case 'zip':
        case 'name':
            return {
                defaultFilterType: 'is',
                defaultValue: '',
            };
        default:
            assertNever(fieldName);
    }

    return { defaultFilterType: 'is', defaultValue: null };
};

export const getAvailableFiltersFromSchema = (schema?: SchemaResource) => {
    const availableFieldNames = [
        'createdAt',
        'status',
        'person',
        ...(schema?.attributes.properties
            .filter(({ fieldName }) => fieldName !== 'status')
            .map(({ fieldName }) => SCHEMA_ATTR_TO_FILTER_FIELD.get(fieldName) ?? fieldName) ?? []),
    ];

    const availableFilters = availableFieldNames.filter((fieldName) =>
        FILTERABLE_FIELD_NAMES.includes(fieldName as CRMFieldName),
    );

    return availableFilters as CRMFieldName[];
};

// Empty values are considered invalid values, so filter is removed. Same when status list is empty.
export const isValidCRMFilter = (filter: CRMFilter) => {
    const hasValidValues = filter.values.every((value) => value !== '');
    const hasValidStatuses = filter.fieldName === 'status' ? filter.values.length > 0 : true;

    return hasValidValues && hasValidStatuses;
};
