/**
 * Types related to logging.
 */

import type { useEditorEngineLogger } from '@/app/editor/engine/core/hooks/log/useEditorEngineLogger';

/**
 * A level for logging messages.
 */
export enum EditorEngineLogLevel {
    /**
     * Log level for debug messages.
     */
    Debug = 'Debug',
    /**
     * Log level for informational messages.
     */
    Info = 'Info',
    /**
     * Log level for warning messages.
     */
    Warning = 'Warning',
    /**
     * Log level for error messages.
     */
    Error = 'Error',
    /**
     * Log level for critical messages.
     */
    Critical = 'Critical',
}

/**
 * A single log record.
 */
export type EditorEngineLogRecord = {
    /**
     * The log level of the record.
     */
    level: EditorEngineLogLevel;
    /**
     * The message of the record.
     */
    message: string;
    /**
     * The timestamp of the record.
     */
    timestamp: number;
    /**
     * The context of the record.
     */
    context?: Record<string, unknown>;
};

/**
 * The logger of an Editor Engine instance.
 */
export type EditorEngineLogger = ReturnType<typeof useEditorEngineLogger>;

/**
 * A handler that processes log records.
 */
export type EditorEngineLogHandler = (record: EditorEngineLogRecord) => void;
