import { getBlockDetails } from '@/app/editor/blocks/helpers';
import { setOrderByPage, getPageBlockIds } from '@/app/editor/blocks/models/blockOrder';
import {
    getBlockById,
    removeBlockById,
    removeChildBlockById,
} from '@/app/editor/blocks/models/blocks';
import { setActiveView } from '@/app/editor/editor/models/sidebar';
import { fetchSinglePage } from '@/app/editor/pages/models/pages';
import { apiDelete, handleRuntimeError } from '@/core/api';
import { getRelationship } from '@/core/api/helper';
import { removeValueFromArrayOnce } from '@/utils/mutation';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { AppThunk } from '@/core/redux/types';

// === Helper ======

export const optimisticallyRemoveBlock =
    (block: BlockResource): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const blockId = block.id;

        const { pageId, parentComponent } = getBlockDetails(block);
        const pageBlockOrder = getPageBlockIds(state, pageId);

        const isChildBlock = !!parentComponent.data?.id;

        if (!isChildBlock) {
            // Remove block from page block order
            const updatedPageBlockOrder = removeValueFromArrayOnce(pageBlockOrder, blockId);
            await dispatch(setOrderByPage({ pageId, blockIds: updatedPageBlockOrder }));
        } else {
            // Remove block from child components relationship
            await dispatch(removeChildBlockById(blockId));
        }

        // Remove block data
        dispatch(removeBlockById(blockId));
    };

// todo(editorengine): the sync version of the function is needed at the moment
// Explanation: the asynchronous version is not actually needed, since the two
// actions dispatched above are synchronous (so they do not need to be awaited).
// However, the old editor is using `optimisticallyRemoveBlock` so we should
// not change it for now. As soon as the old editor is removed, we can simply leave
// the synchronous version.
export const optimisticallyRemoveBlockSync =
    (block: BlockResource): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const blockId = block.id;

        const { pageId, parentComponent } = getBlockDetails(block);
        const pageBlockOrder = getPageBlockIds(state, pageId);

        const isChildBlock = !!parentComponent.data?.id;

        if (!isChildBlock) {
            // Remove block from page block order
            const updatedPageBlockOrder = removeValueFromArrayOnce(pageBlockOrder, blockId);
            dispatch(setOrderByPage({ pageId, blockIds: updatedPageBlockOrder }));
        } else {
            // Remove block from child components relationship
            dispatch(removeChildBlockById(blockId));
        }

        // Remove block data
        dispatch(removeBlockById(blockId));
    };

// === Thunks ======

export const deleteBlock =
    (blockId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const block = getBlockById(state, blockId);

        if (!block) {
            return;
        }

        const pageId = getRelationship(block, 'page')?.id;

        try {
            // optimistic block removal
            dispatch(optimisticallyRemoveBlock(block));

            // back to page overview
            dispatch(setActiveView('pages'));

            // delete block in DB
            await apiDelete(`/components/${blockId}`);

            if (pageId) {
                // Re-fetch page for relationships update
                await dispatch(fetchSinglePage(pageId));
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: `deleting block ${blockId} failed:` });
        }
    };
