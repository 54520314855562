import { Currency } from '../types';

export const currencySymbolMap: Record<Currency, '€' | '$' | '£'> = {
    [Currency.EUR]: '€',
    [Currency.USD]: '$',
    [Currency.GBP]: '£',
};

export const getPlanIdWithoutCurrency = (planId: string) => {
    if (!planId) {
        return;
    }

    const splitPlanId = planId.split('-');
    const splitPlanIdWithoutCurrency = splitPlanId.filter(
        (planIdPart) => !Object.values(Currency).includes(planIdPart as Currency),
    );

    return splitPlanIdWithoutCurrency.join('-');
};

export const getCleanPlan = (planId: string) => {
    if (!planId) {
        return;
    }

    const splitPlanId = planId.split('-');
    const splitCleanPlanId = splitPlanId.slice(0, 2);

    return splitCleanPlanId.join('-');
};

export const getCurrencyFromPlanId = (planId: string): Currency => {
    if (!planId) {
        return;
    }

    const splitPlanId = planId.split('-');

    const currency = splitPlanId.find((planIdPart) => planIdPart in Currency);

    return (currency as Currency) || Currency.EUR;
};

export const getAddonIdWithCurrency = (addonId: string, currency: Currency) => {
    const currencyString = currency && currency !== Currency.EUR ? currency : '';

    return `${addonId}${currencyString ? `-${currencyString}` : ''}`;
};
