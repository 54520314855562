import {
    type EditorEngineDefaultTypeInput,
    EditorEngineHistoryEntryRecordedActionStatusType,
    EditorEngineHistoryExecuteErrorType,
    EditorEngineHistoryExecutionResultType,
} from '@/app/editor/engine/core/types';
import { EditorEngineExecutionDirection } from '@/app/editor/engine/core/types';
import { executeEntryStep } from '@/app/editor/engine/core/utils/history/executeEntryStep';
import { foreachEntryRecordedStep } from '@/app/editor/engine/core/utils/history/foreachEntryRecordedStep';

import type { useProcessingQueue } from '@/app/editor/engine/core/hooks/queue/useProcessingQueue';
import type {
    EditorEngineActionUpdater,
    EditorEngineHistoryEntryCollectionSetter,
    EditorEngineHistoryExecutionError,
    EditorEngineHistoryExecutionResult,
    EditorEnginePersistedUpdate,
    EditorEngineStepUpdater,
    EditorEngineHistoryEntryStepForeachFilter,
    EditorEngineEventEmitter,
    EditorEngineHistoryEntry,
} from '@/app/editor/engine/core/types';

interface Input<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> {
    /**
     * The entry to execute.
     */
    entry: EditorEngineHistoryEntry<TEditorEngineTypeInput>;
    /**
     * This entry will be set as the current entry after the execution.
     */
    nextCurrent: EditorEngineHistoryEntry<TEditorEngineTypeInput> | null;
    /**
     * Whether to execute the entry in the forward or backward direction.
     */
    direction: EditorEngineExecutionDirection;
    /**
     * A filter to apply to the steps of the entry.
     */
    filter: EditorEngineHistoryEntryStepForeachFilter;
    /**
     * This function will be called to update the action status of the entry.
     */
    updateAction: EditorEngineActionUpdater;
    /**
     * This function will be called to update the status of a step of the entry.
     */
    updateStep: EditorEngineStepUpdater<TEditorEngineTypeInput['ExtraContext']>;
    /**
     * This function will be called to update the entries collection.
     */
    updateEntries: EditorEngineHistoryEntryCollectionSetter;
    /**
     * This function will be called when an error occurs during the execution of
     * the entry. While the event emitter already has events related to the
     * history, this function is specifically meant to handle history execution
     * errors only.
     */
    reportError?: (error: EditorEngineHistoryExecutionError<TEditorEngineTypeInput>) => void;
    /**
     * This function will be called to add the persisted update to the queue
     * for processing.
     */
    addUpdateToQueue: ReturnType<
        typeof useProcessingQueue<EditorEnginePersistedUpdate>
    >['addItemToQueue'];
    /**
     * Extra context to be provided.
     */
    extraContext: TEditorEngineTypeInput['ExtraContext'];
    /**
     * The event emitter instance.
     */
    eventEmitter: EditorEngineEventEmitter;
    /**
     * Whether the entry is re-executing (undo or redo).
     */
    isReExecution: boolean;
}

/**
 * Given an entry, this function will apply all of its steps (optimistic
 * update), and then execute its main action (persistent update).
 */
export const executeEntry = async <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    entry,
    nextCurrent,
    direction,
    filter,
    updateStep,
    updateAction,
    updateEntries,
    reportError,
    addUpdateToQueue,
    extraContext,
    eventEmitter,
    isReExecution,
}: Input<TEditorEngineTypeInput>): Promise<EditorEngineHistoryExecutionResult> => {
    if (direction === EditorEngineExecutionDirection.Forward) {
        entry.action.onBeforeOptimisticForward?.(isReExecution);
    } else {
        entry.action.onBeforeOptimisticBackward?.();
    }

    // First, execute all optimistic steps
    const stepResult = await foreachEntryRecordedStep({
        entry,
        direction,
        filter,
        handler: async (step) =>
            await executeEntryStep({
                entry,
                step,
                direction,
                updateStep,
                extraContext,
                eventEmitter,
                reportError,
            }),
    });

    // This means the execution was not stopped by a failed step
    if (stepResult.finished === true) {
        if (direction === EditorEngineExecutionDirection.Forward) {
            entry.action.onAfterOptimisticForward?.(isReExecution);
        } else {
            entry.action.onAfterOptimisticBackward?.();
        }

        updateEntries((entries) => ({
            ...entries,
            current: nextCurrent,
        }));

        addUpdateToQueue({
            entry,
            recordedAction: entry.recordedAction,
            direction,
            isReExecution,
        });

        return {
            type: EditorEngineHistoryExecutionResultType.Successful,
            debug: stepResult.additionalDebugData,
        };
    } else {
        // If there was no crash but the execution failed, the error will be in
        // the result object. Otherwise, there's going to be a top level error.
        const editorEngineError =
            'result' in stepResult ? stepResult.result.error : stepResult.error;

        // In here, some of the steps have either returned a failed result
        // or the step itself crashed. Update the action status.
        updateAction({
            entry,
            recordedAction: entry.recordedAction,
            result: {
                type: EditorEngineHistoryEntryRecordedActionStatusType.FailedBySteps,
                direction,
            },
        });

        reportError?.({
            type: EditorEngineHistoryExecuteErrorType.ActionFailedBySteps,
            entry,
            action: entry.recordedAction,
            error: editorEngineError,
        });

        return {
            type: EditorEngineHistoryExecutionResultType.Failed,
            error: editorEngineError,
        };
    }
};
