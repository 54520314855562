export const countryData = {
    TA: {
        en: 'Tristan da Cunha',
        de: 'Tristan da Cunha',
        native: 'Tristan da Cunha',
        phone: '+290',
    },
    AC: {
        en: 'Ascension Island',
        de: 'Ascension Island',
        native: 'Ascension Island',
        phone: '+247',
    },
    UZ: {
        en: 'Uzbekistan',
        de: 'Usbekistan',
        native: 'Узбекистан',
        phone: '+998',
    },
    AT: {
        en: 'Austria',
        de: 'Österreich',
        native: 'Österreich',
        phone: '+43',
    },
    SL: {
        en: 'Sierra Leone',
        de: 'Sierra Leone',
        native: 'Sierra Leone',
        phone: '+232',
    },
    CC: {
        en: 'Cocos (Keeling) Islands',
        de: 'Kokosinseln',
        native: 'Cocos (Keeling) Islands',
        phone: '+61',
    },
    MR: {
        en: 'Mauritania',
        de: 'Mauretanien',
        native: 'موريتانيا',
        phone: '+222',
    },
    IL: { en: 'Israel', de: 'Israel', native: 'إسرائيل', phone: '+972' },
    MD: { en: 'Moldova', de: 'Moldawien', native: 'Moldova', phone: '+373' },
    FJ: { en: 'Fiji', de: 'Fidschi', native: 'Fiji', phone: '+679' },
    RS: { en: 'Serbia', de: 'Serbien', native: 'Србија', phone: '+381' },
    CM: { en: 'Cameroon', de: 'Kamerun', native: 'Cameroon', phone: '+237' },
    BS: { en: 'Bahamas', de: 'Bahamas', native: 'Bahamas', phone: '+1' },
    NR: { en: 'Nauru', de: 'Nauru', native: 'Nauru', phone: '+674' },
    CZ: {
        en: 'Czechia',
        de: 'Tschechoslowakei',
        native: 'Česko',
        phone: '+420',
    },
    EG: { en: 'Egypt', de: 'Ägypten', native: 'مصر', phone: '+20' },
    MM: { en: 'Myanmar', de: 'Myanmar', native: 'မြန်မာ', phone: '+95' },
    NL: {
        en: 'Netherlands',
        de: 'Niederlande',
        native: 'Nederland',
        phone: '+31',
    },
    UY: { en: 'Uruguay', de: 'Uruguay', native: 'Uruguay', phone: '+598' },
    MP: {
        en: 'Northern Mariana Islands',
        de: 'Nördliche Marianen',
        native: 'Northern Mariana Islands',
        phone: '+1',
    },
    CF: {
        en: 'Central African Republic',
        de: 'Zentralafrikanische Republik',
        native: 'République centrafricaine',
        phone: '+236',
    },
    SH: {
        en: 'Saint Helena, Ascension and Tristan da Cunha',
        de: 'St. Helena',
        native: 'Saint Helena, Ascension and Tristan da Cunha',
        phone: '+290',
    },
    GQ: {
        en: 'Equatorial Guinea',
        de: 'Äquatorialguinea',
        native: 'Guinée équatoriale',
        phone: '+240',
    },
    CO: { en: 'Colombia', de: 'Kolumbien', native: 'Colombia', phone: '+57' },
    KG: {
        en: 'Kyrgyzstan',
        de: 'Kirgisistan',
        native: 'Кыргызстан',
        phone: '+996',
    },
    ES: { en: 'Spain', de: 'Spanien', native: 'España', phone: '+34' },
    MW: { en: 'Malawi', de: 'Malawi', native: 'Malawi', phone: '+265' },
    HU: { en: 'Hungary', de: 'Ungarn', native: 'Magyarország', phone: '+36' },
    MC: { en: 'Monaco', de: 'Monaco', native: 'Monaco', phone: '+377' },
    CV: {
        en: 'Cape Verde',
        de: 'Cabo Verde',
        native: 'Cabo Verde',
        phone: '+238',
    },
    ZA: {
        en: 'South Africa',
        de: 'Südafrika',
        native: 'South Africa',
        phone: '+27',
    },
    DK: { en: 'Denmark', de: 'Dänemark', native: 'Danmark', phone: '+45' },
    GP: {
        en: 'Guadeloupe',
        de: 'Guadeloupe',
        native: 'Guadeloupe',
        phone: '+590',
    },
    IE: { en: 'Ireland', de: 'Irland', native: 'Ireland', phone: '+353' },
    MT: { en: 'Malta', de: 'Malta', native: 'Malta', phone: '+356' },
    WF: {
        en: 'Wallis and Futuna',
        de: 'Wallis/Futuna',
        native: 'Wallis et Futuna',
        phone: '+681',
    },
    SR: { en: 'Suriname', de: 'Surinam', native: 'Suriname', phone: '+597' },
    VA: {
        en: 'Vatican City',
        de: 'Vatikanstadt',
        native: 'Vaticano',
        phone: '+39',
    },
    ML: { en: 'Mali', de: 'Mali', native: 'Mali', phone: '+223' },
    TO: { en: 'Tonga', de: 'Tonga', native: 'Tonga', phone: '+676' },
    SM: {
        en: 'San Marino',
        de: 'San Marino',
        native: 'San Marino',
        phone: '+378',
    },
    BL: {
        en: 'Saint Barthélemy',
        de: 'St. Barthélemy',
        native: 'Saint-Barthélemy',
        phone: '+590',
    },
    GI: {
        en: 'Gibraltar',
        de: 'Gibraltar',
        native: 'Gibraltar',
        phone: '+350',
    },
    CA: { en: 'Canada', de: 'Kanada', native: 'Canada', phone: '+1' },
    BJ: { en: 'Benin', de: 'Benin', native: 'Bénin', phone: '+229' },
    GU: { en: 'Guam', de: 'Guam', native: 'Guåhån', phone: '+1' },
    KP: { en: 'North Korea', de: 'Nordkorea', native: '조선', phone: '+850' },
    TV: { en: 'Tuvalu', de: 'Tuvalu', native: 'Tuvalu', phone: '+688' },
    PA: { en: 'Panama', de: 'Panama', native: 'Panamá', phone: '+507' },
    RW: { en: 'Rwanda', de: 'Ruanda', native: 'Rwanda', phone: '+250' },
    CG: {
        en: 'Republic of the Congo',
        de: 'Republik Kongo',
        native: 'République du Congo',
        phone: '+242',
    },
    JM: { en: 'Jamaica', de: 'Jamaika', native: 'Jamaica', phone: '+1' },
    BH: { en: 'Bahrain', de: 'Bahrain', native: '‏البحرين', phone: '+973' },
    SX: { en: 'Sint Maarten', de: 'Sint Maarten', native: 'Sint Maarten', phone: '+1' },
    TC: {
        en: 'Turks and Caicos Islands',
        de: 'Turks- und Caicosinseln',
        native: 'Turks and Caicos Islands',
        phone: '+1',
    },
    PK: { en: 'Pakistan', de: 'Pakistan', native: 'Pakistan', phone: '+92' },
    KZ: {
        en: 'Kazakhstan',
        de: 'Kasachstan',
        native: 'Қазақстан',
        phone: '+7',
    },
    LA: { en: 'Laos', de: 'Laos', native: 'ສປປລາວ', phone: '+856' },
    TT: {
        en: 'Trinidad and Tobago',
        de: 'Trinidad und Tobago',
        native: 'Trinidad and Tobago',
        phone: '+1',
    },
    ME: {
        en: 'Montenegro',
        de: 'Montenegro',
        native: 'Црна Гора',
        phone: '+382',
    },
    NU: { en: 'Niue', de: 'Niue', native: 'Niue', phone: '+683' },
    LR: { en: 'Liberia', de: 'Liberia', native: 'Liberia', phone: '+231' },
    GD: { en: 'Grenada', de: 'Grenada', native: 'Grenada', phone: '+1' },
    PG: {
        en: 'Papua New Guinea',
        de: 'Papua-Neuguinea',
        native: 'Papua New Guinea',
        phone: '+675',
    },
    TD: { en: 'Chad', de: 'Tschad', native: 'تشاد‎', phone: '+235' },
    CL: { en: 'Chile', de: 'Chile', native: 'Chile', phone: '+56' },
    PR: {
        en: 'Puerto Rico',
        de: 'Puerto Rico',
        native: 'Puerto Rico',
        phone: '+1',
    },
    SA: {
        en: 'Saudi Arabia',
        de: 'Saudi-Arabien',
        native: 'العربية السعودية',
        phone: '+966',
    },
    NO: { en: 'Norway', de: 'Norwegen', native: 'Noreg', phone: '+47' },
    GM: { en: 'Gambia', de: 'Gambia', native: 'Gambia', phone: '+220' },
    PH: {
        en: 'Philippines',
        de: 'Philippinen',
        native: 'Philippines',
        phone: '+63',
    },
    IM: {
        en: 'Isle of Man',
        de: 'Isle Of Man',
        native: 'Isle of Man',
        phone: '+44',
    },
    PT: { en: 'Portugal', de: 'Portugal', native: 'Portugal', phone: '+351' },
    HN: { en: 'Honduras', de: 'Honduras', native: 'Honduras', phone: '+504' },
    CY: { en: 'Cyprus', de: 'Zypern', native: 'Κύπρος', phone: '+357' },
    AI: { en: 'Anguilla', de: 'Anguilla', native: 'Anguilla', phone: '+1' },
    TG: { en: 'Togo', de: 'Togo', native: 'Togo', phone: '+228' },
    LB: { en: 'Lebanon', de: 'Libanon', native: 'لبنان', phone: '+961' },
    MA: { en: 'Morocco', de: 'Morokko', native: 'المغرب', phone: '+212' },
    EE: { en: 'Estonia', de: 'Estland', native: 'Eesti', phone: '+372' },
    FO: {
        en: 'Faroe Islands',
        de: 'Färöer',
        native: 'Færøerne',
        phone: '+298',
    },
    AR: {
        en: 'Argentina',
        de: 'Argentinien',
        native: 'Argentina',
        phone: '+54',
    },
    GA: { en: 'Gabon', de: 'Gabun', native: 'Gabon', phone: '+241' },
    NA: { en: 'Namibia', de: 'Namibia', native: 'Namibië', phone: '+264' },
    VN: { en: 'Vietnam', de: 'Vietnam', native: 'Việt Nam', phone: '+84' },
    GR: { en: 'Greece', de: 'Griechenland', native: 'Ελλάδα', phone: '+30' },
    VG: {
        en: 'British Virgin Islands',
        de: 'Jungferninseln, Britische',
        native: 'British Virgin Islands',
        phone: '+1',
    },
    MZ: {
        en: 'Mozambique',
        de: 'Mosambik',
        native: 'Moçambique',
        phone: '+258',
    },
    NF: {
        en: 'Norfolk Island',
        de: 'Norfolkinsel',
        native: 'Norfolk Island',
        phone: '+672',
    },
    AM: { en: 'Armenia', de: 'Armenien', native: 'Հայաստան', phone: '+374' },
    KE: { en: 'Kenya', de: 'Kenia', native: 'Kenya', phone: '+254' },
    BT: { en: 'Bhutan', de: 'Bhutan', native: 'འབྲུག་ཡུལ་', phone: '+975' },
    AE: {
        en: 'United Arab Emirates',
        de: 'Vereinigte Arabische Emirate',
        native: 'دولة الإمارات العربية المتحدة',
        phone: '+971',
    },
    CK: {
        en: 'Cook Islands',
        de: 'Cookinseln',
        native: 'Cook Islands',
        phone: '+682',
    },
    ET: { en: 'Ethiopia', de: 'Äthiopien', native: 'ኢትዮጵያ', phone: '+251' },
    SG: { en: 'Singapore', de: 'Singapur', native: '新加坡', phone: '+65' },
    PE: { en: 'Peru', de: 'Peru', native: 'Piruw', phone: '+51' },
    PS: { en: 'Palestine', de: 'Palestina', native: 'فلسطين', phone: '+970' },
    WS: { en: 'Samoa', de: 'Samoa', native: 'Samoa', phone: '+685' },
    SS: {
        en: 'South Sudan',
        de: 'Südsudan',
        native: 'South Sudan',
        phone: '+211',
    },
    AD: { en: 'Andorra', de: 'Andorra', native: 'Andorra', phone: '+376' },
    MF: {
        en: 'Saint Martin',
        de: 'St. Martin',
        native: 'Saint-Martin',
        phone: '+590',
    },
    SZ: { en: 'Eswatini', de: 'Swasiland', native: 'Eswatini', phone: '+268' },
    TJ: {
        en: 'Tajikistan',
        de: 'Tadschikistan',
        native: 'Таджикистан',
        phone: '+992',
    },
    ZM: { en: 'Zambia', de: 'Sambia', native: 'Zambia', phone: '+260' },
    US: {
        en: 'United States',
        de: 'USA',
        native: 'United States',
        phone: '+1',
    },
    BI: { en: 'Burundi', de: 'Burundi', native: 'Burundi', phone: '+257' },
    JP: { en: 'Japan', de: 'Japan', native: '日本', phone: '+81' },
    CW: { en: 'Curaçao', de: 'Curaçao', native: 'Curaçao', phone: '+599' },
    UG: { en: 'Uganda', de: 'Uganda', native: 'Uganda', phone: '+256' },
    MN: {
        en: 'Mongolia',
        de: 'Mongolei',
        native: 'Монгол улс',
        phone: '+976',
    },
    NG: { en: 'Nigeria', de: 'Nigeria', native: 'Nigeria', phone: '+234' },
    GT: {
        en: 'Guatemala',
        de: 'Guatemala',
        native: 'Guatemala',
        phone: '+502',
    },
    JE: { en: 'Jersey', de: 'Jersey', native: 'Jersey', phone: '+44' },
    CR: {
        en: 'Costa Rica',
        de: 'Costa Rica',
        native: 'Costa Rica',
        phone: '+506',
    },
    YE: { en: 'Yemen', de: 'Jemen', native: 'اليَمَن', phone: '+967' },
    GL: {
        en: 'Greenland',
        de: 'Grönland',
        native: 'Kalaallit Nunaat',
        phone: '+299',
    },
    MG: {
        en: 'Madagascar',
        de: 'Madagaskar',
        native: 'Madagascar',
        phone: '+261',
    },
    DZ: { en: 'Algeria', de: 'Algerien', native: 'الجزائر', phone: '+213' },
    BE: { en: 'Belgium', de: 'Belgien', native: 'Belgien', phone: '+32' },
    LK: {
        en: 'Sri Lanka',
        de: 'Sri Lanka',
        native: 'ශ්‍රී ලංකාව',
        phone: '+94',
    },
    FI: { en: 'Finland', de: 'Finnland', native: 'Suomi', phone: '+358' },
    BM: { en: 'Bermuda', de: 'Bermuda', native: 'Bermuda', phone: '+1' },
    MK: {
        en: 'North Macedonia',
        de: 'Mazedonien',
        native: 'Македонија',
        phone: '+389',
    },
    VC: {
        en: 'Saint Vincent and the Grenadines',
        de: 'St. Vincent/Die Grenadinen',
        native: 'Saint Vincent and the Grenadines',
        phone: '+1',
    },
    NE: { en: 'Niger', de: 'Niger', native: 'Niger', phone: '+227' },
    IO: {
        en: 'British Indian Ocean Territory',
        de: 'Britisches Territorium des Indischen Ozeans',
        native: 'British Indian Ocean Territory',
        phone: '+246',
    },
    LV: { en: 'Latvia', de: 'Lettland', native: 'Latvija', phone: '+371' },
    NP: { en: 'Nepal', de: 'Nepal', native: 'नेपाल', phone: '+977' },
    CI: {
        en: 'Ivory Coast',
        de: 'Elfenbeinküste',
        native: "Côte d'Ivoire",
        phone: '+225',
    },
    LI: {
        en: 'Liechtenstein',
        de: 'Liechtenstein',
        native: 'Liechtenstein',
        phone: '+423',
    },
    CD: {
        en: 'Democratic Republic of the Congo',
        de: 'Demokratische Republik Kongo',
        native: 'République démocratique du Congo',
        phone: '+243',
    },
    BZ: { en: 'Belize', de: 'Belize', native: 'Belize', phone: '+501' },
    QA: { en: 'Qatar', de: 'Qatar', native: 'قطر', phone: '+974' },
    TK: { en: 'Tokelau', de: 'Tokelau', native: 'Tokelau', phone: '+690' },
    ID: {
        en: 'Indonesia',
        de: 'Indonesien',
        native: 'Indonesia',
        phone: '+62',
    },
    PF: {
        en: 'French Polynesia',
        de: 'Französisch-Polynesien',
        native: 'Polynésie française',
        phone: '+689',
    },
    LS: { en: 'Lesotho', de: 'Lesotho', native: 'Lesotho', phone: '+266' },
    PL: { en: 'Poland', de: 'Polen', native: 'Polska', phone: '+48' },
    PW: { en: 'Palau', de: 'Palau', native: 'Palau', phone: '+680' },
    GG: { en: 'Guernsey', de: 'Guernsey', native: 'Guernsey', phone: '+44' },
    AG: {
        en: 'Antigua and Barbuda',
        de: 'Antigua/Barbuda',
        native: 'Antigua and Barbuda',
        phone: '+1',
    },
    PM: {
        en: 'Saint Pierre and Miquelon',
        de: 'St. Pierre/Miquelon',
        native: 'Saint-Pierre-et-Miquelon',
        phone: '+508',
    },
    XK: { en: 'Kosovo', de: 'Kosovo', native: 'Kosova', phone: '+383' },
    EH: {
        en: 'Western Sahara',
        de: 'Westsahara',
        native: 'Western Sahara',
        phone: '+212',
    },
    LU: {
        en: 'Luxembourg',
        de: 'Luxemburg',
        native: 'Luxemburg',
        phone: '+352',
    },
    TW: { en: 'Taiwan', de: 'Taiwan', native: '台灣', phone: '+886' },
    HK: {
        en: 'Hong Kong',
        de: 'Hong Kong',
        native: 'Hong Kong',
        phone: '+852',
    },
    TM: {
        en: 'Turkmenistan',
        de: 'Turkmenistan',
        native: 'Туркмения',
        phone: '+993',
    },
    RU: { en: 'Russia', de: 'Russland', native: 'Россия', phone: '+7' },
    AZ: {
        en: 'Azerbaijan',
        de: 'Aserbaidschan',
        native: 'Azərbaycan',
        phone: '+994',
    },
    EC: { en: 'Ecuador', de: 'Ecuador', native: 'Ecuador', phone: '+593' },
    KH: {
        en: 'Cambodia',
        de: 'Kambodscha',
        native: 'Kâmpŭchéa',
        phone: '+855',
    },
    YT: { en: 'Mayotte', de: 'Mayotte', native: 'Mayotte', phone: '+262' },
    BW: { en: 'Botswana', de: 'Botsuana', native: 'Botswana', phone: '+267' },
    HR: { en: 'Croatia', de: 'Kroatien', native: 'Hrvatska', phone: '+385' },
    LC: {
        en: 'Saint Lucia',
        de: 'St. Lucia',
        native: 'Saint Lucia',
        phone: '+1',
    },
    PY: { en: 'Paraguay', de: 'Paraguay', native: 'Paraguái', phone: '+595' },
    BO: { en: 'Bolivia', de: 'Bolivien', native: 'Wuliwya', phone: '+591' },
    MV: {
        en: 'Maldives',
        de: 'Maldiven',
        native: 'ދިވެހިރާއްޖޭގެ',
        phone: '+960',
    },
    AS: {
        en: 'American Samoa',
        de: 'Amerikanisch-Samoa',
        native: 'American Samoa',
        phone: '+1',
    },
    IS: { en: 'Iceland', de: 'Island', native: 'Ísland', phone: '+354' },
    SK: {
        en: 'Slovakia',
        de: 'Slowakische Republik',
        native: 'Slovensko',
        phone: '+421',
    },
    BN: {
        en: 'Brunei',
        de: 'Brunei Darussalam',
        native: 'Negara Brunei Darussalam',
        phone: '+673',
    },
    KN: {
        en: 'Saint Kitts and Nevis',
        de: 'St. Kitts/Nevis',
        native: 'Saint Kitts and Nevis',
        phone: '+1',
    },
    AF: {
        en: 'Afghanistan',
        de: 'Afghanistan',
        native: 'افغانستان',
        phone: '+93',
    },
    GH: { en: 'Ghana', de: 'Ghana', native: 'Ghana', phone: '+233' },
    KW: { en: 'Kuwait', de: 'Kuwait', native: 'الكويت', phone: '+965' },
    SJ: {
        en: 'Svalbard and Jan Mayen',
        de: 'Svalbard/Jan Mayen',
        native: 'Svalbard og Jan Mayen',
        phone: '+47',
    },
    BD: {
        en: 'Bangladesh',
        de: 'Bangladesh',
        native: 'বাংলাদেশ',
        phone: '+880',
    },
    GY: { en: 'Guyana', de: 'Guyana', native: 'Guyana', phone: '+592' },
    KI: { en: 'Kiribati', de: 'Kiribati', native: 'Kiribati', phone: '+686' },
    BB: { en: 'Barbados', de: 'Barbados', native: 'Barbados', phone: '+1' },
    AL: { en: 'Albania', de: 'Albanien', native: 'Shqipëria', phone: '+355' },
    BF: {
        en: 'Burkina Faso',
        de: 'Burkina Faso',
        native: 'Burkina Faso',
        phone: '+226',
    },
    MO: { en: 'Macau', de: 'Macao', native: 'Macau', phone: '+853' },
    SC: { en: 'Seychelles', de: 'Seychellen', native: 'Sesel', phone: '+248' },
    CH: { en: 'Switzerland', de: 'Schweiz', native: 'Suisse', phone: '+41' },
    KR: { en: 'South Korea', de: 'Südkorea', native: '한국', phone: '+82' },
    VI: {
        en: 'United States Virgin Islands',
        de: 'Jungferninseln, Amerikanische',
        native: 'United States Virgin Islands',
        phone: '+1',
    },
    TN: { en: 'Tunisia', de: 'Tunisien', native: 'تونس', phone: '+216' },
    IR: { en: 'Iran', de: 'Iran', native: 'ایران', phone: '+98' },
    JO: { en: 'Jordan', de: 'Jordanien', native: 'الأردن', phone: '+962' },
    RE: { en: 'Réunion', de: 'Réunion', native: 'La Réunion', phone: '+262' },
    TR: { en: 'Turkey', de: 'Türkei', native: 'Türkiye', phone: '+90' },
    TZ: { en: 'Tanzania', de: 'Tansania', native: 'Tanzania', phone: '+255' },
    UA: { en: 'Ukraine', de: 'Ukraine', native: 'Україна', phone: '+380' },
    MU: {
        en: 'Mauritius',
        de: 'Mauritius',
        native: 'Mauritius',
        phone: '+230',
    },
    SO: { en: 'Somalia', de: 'Somalia', native: 'الصومال‎‎', phone: '+252' },
    GN: { en: 'Guinea', de: 'Guinea', native: 'Guinée', phone: '+224' },
    GF: {
        en: 'French Guiana',
        de: 'Französisch-Guayana',
        native: 'Guyane française',
        phone: '+594',
    },
    MH: {
        en: 'Marshall Islands',
        de: 'Marshallinseln',
        native: 'Marshall Islands',
        phone: '+692',
    },
    SB: {
        en: 'Solomon Islands',
        de: 'Salomonen',
        native: 'Solomon Islands',
        phone: '+677',
    },
    LT: { en: 'Lithuania', de: 'Litauen', native: 'Lietuva', phone: '+370' },
    SI: {
        en: 'Slovenia',
        de: 'Slowenien',
        native: 'Slovenija',
        phone: '+386',
    },
    NI: {
        en: 'Nicaragua',
        de: 'Nicaragua',
        native: 'Nicaragua',
        phone: '+505',
    },
    DO: {
        en: 'Dominican Republic',
        de: 'Dominikanische Republik',
        native: 'República Dominicana',
        phone: '+1',
    },
    IQ: { en: 'Iraq', de: 'Irak', native: 'العراق', phone: '+964' },
    SV: {
        en: 'El Salvador',
        de: 'El Salvador',
        native: 'El Salvador',
        phone: '+503',
    },
    VE: {
        en: 'Venezuela',
        de: 'Venezuela',
        native: 'Venezuela',
        phone: '+58',
    },
    ZW: { en: 'Zimbabwe', de: 'Simbabwe', native: 'Zimbabwe', phone: '+263' },
    SE: { en: 'Sweden', de: 'Schweden', native: 'Sverige', phone: '+46' },
    TH: { en: 'Thailand', de: 'Thailand', native: 'ประเทศไทย', phone: '+66' },
    TL: {
        en: 'Timor-Leste',
        de: 'Timor-Leste',
        native: 'Timor-Leste',
        phone: '+670',
    },
    BA: {
        en: 'Bosnia and Herzegovina',
        de: 'Bosnien/Herzegowina',
        native: 'Bosna i Hercegovina',
        phone: '+387',
    },
    GW: {
        en: 'Guinea-Bissau',
        de: 'Guinea-Bissau',
        native: 'Guiné-Bissau',
        phone: '+245',
    },
    MS: {
        en: 'Montserrat',
        de: 'Montserrat',
        native: 'Montserrat',
        phone: '+1',
    },
    DM: { en: 'Dominica', de: 'Dominica', native: 'Dominica', phone: '+1' },
    FK: {
        en: 'Falkland Islands',
        de: 'Falklandinseln',
        native: 'Falkland Islands',
        phone: '+500',
    },
    BQ: {
        en: 'Caribbean Netherlands',
        de: 'Karibische Niederlande',
        native: 'Caribisch Nederland',
        phone: '+599',
    },
    DJ: { en: 'Djibouti', de: 'Dschibuti', native: 'جيبوتي‎', phone: '+253' },
    HT: { en: 'Haiti', de: 'Haiti', native: 'Haïti', phone: '+509' },
    KM: { en: 'Comoros', de: 'Komoren', native: 'القمر‎', phone: '+269' },
    IT: { en: 'Italy', de: 'Italien', native: 'Italia', phone: '+39' },
    BY: {
        en: 'Belarus',
        de: 'Weißrussland',
        native: 'Белару́сь',
        phone: '+375',
    },
    KY: {
        en: 'Cayman Islands',
        de: 'Kaimaninseln',
        native: 'Cayman Islands',
        phone: '+1',
    },
    FR: { en: 'France', de: 'Frankreich', native: 'France', phone: '+33' },
    SN: { en: 'Senegal', de: 'Senegal', native: 'Sénégal', phone: '+221' },
    CN: { en: 'China', de: 'China', native: '中国', phone: '+86' },
    SD: { en: 'Sudan', de: 'Sudan', native: 'السودان', phone: '+249' },
    OM: { en: 'Oman', de: 'Oman', native: 'عمان', phone: '+968' },
    GB: {
        en: 'United Kingdom',
        de: 'Großbritannien',
        native: 'United Kingdom',
        phone: '+44',
    },
    MX: { en: 'Mexico', de: 'Mexiko', native: 'México', phone: '+52' },
    AU: {
        en: 'Australia',
        de: 'Australien',
        native: 'Australia',
        phone: '+61',
    },
    CU: { en: 'Cuba', de: 'Cuba', native: 'Cuba', phone: '+53' },
    ER: { en: 'Eritrea', de: 'Eritrea', native: 'إرتريا‎', phone: '+291' },
    BG: { en: 'Bulgaria', de: 'Bulgarien', native: 'България', phone: '+359' },
    RO: { en: 'Romania', de: 'Rumänien', native: 'România', phone: '+40' },
    FM: {
        en: 'Micronesia',
        de: 'Mikronesien',
        native: 'Micronesia',
        phone: '+691',
    },
    VU: { en: 'Vanuatu', de: 'Vanuatu', native: 'Vanuatu', phone: '+678' },
    SY: { en: 'Syria', de: 'Syrien', native: 'سوريا', phone: '+963' },
    ST: {
        en: 'São Tomé and Príncipe',
        de: 'São Tomé/Príncipe',
        native: 'São Tomé e Príncipe',
        phone: '+239',
    },
    DE: {
        en: 'Germany',
        de: 'Deutschland',
        native: 'Deutschland',
        phone: '+49',
    },
    NZ: {
        en: 'New Zealand',
        de: 'Neuseeland',
        native: 'New Zealand',
        phone: '+64',
    },
    AO: { en: 'Angola', de: 'Angola', native: 'Angola', phone: '+244' },
    CX: {
        en: 'Christmas Island',
        de: 'Weihnachtsinsel',
        native: 'Christmas Island',
        phone: '+61',
    },
    AW: { en: 'Aruba', de: 'Aruba', native: 'Aruba', phone: '+297' },
    IN: { en: 'India', de: 'Indien', native: 'India', phone: '+91' },
    MY: { en: 'Malaysia', de: 'Malaysia', native: 'Malaysia', phone: '+60' },
    GE: { en: 'Georgia', de: 'Georgien', native: 'საქართველო', phone: '+995' },
    NC: {
        en: 'New Caledonia',
        de: 'Neukaledonien',
        native: 'Nouvelle-Calédonie',
        phone: '+687',
    },
    LY: { en: 'Libya', de: 'Libyen', native: '‏ليبيا', phone: '+218' },
    AX: { en: 'Åland Islands', de: 'Åland', native: 'Åland', phone: '+358' },
    MQ: {
        en: 'Martinique',
        de: 'Martinique',
        native: 'Martinique',
        phone: '+596',
    },
    BR: { en: 'Brazil', de: 'Brasilien', native: 'Brasil', phone: '+55' },
} as const;

export type CountryCode = keyof typeof countryData;
export const countryCodes = Object.keys(countryData) as CountryCode[];
