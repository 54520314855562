import { cn } from '@/utils/cn';

export interface Props {
    className?: string;
}

const MultiSelectIcon = ({ className }: Props) => {
    return (
        <div className={cn('block p-px', className)}>
            <svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.29063 7.23017C6.00353 6.93159 5.52875 6.92228 5.23017 7.20938C4.93159 7.49647 4.92228 7.97125 5.20938 8.26983L8.45938 11.7698C8.60078 11.9169 8.79599 12 9 12C9.20401 12 9.39922 11.9169 9.54063 11.7698L12.7906 8.26983C13.0777 7.97125 13.0684 7.49647 12.7698 7.20938C12.4713 6.92228 11.9965 6.93159 11.7094 7.23017L9 10.1679L6.29063 7.23017Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 3C0 1.34315 1.34315 0 3 0H15C16.6569 0 18 1.34315 18 3V15C18 16.6569 16.6569 18 15 18H3C1.34315 18 0 16.6569 0 15V3ZM3 1.5H15C15.8284 1.5 16.5 2.17157 16.5 3V15C16.5 15.8284 15.8284 16.5 15 16.5H3C2.17157 16.5 1.5 15.8284 1.5 15V3C1.5 2.17157 2.17157 1.5 3 1.5Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

export default MultiSelectIcon;
