import { combineReducers } from 'redux';

import analyticsReducer from './analytics';
import campaignsReducer from './campaigns';
import domainEditingReducer from './domainEditing';
import overviewReducer from './overview';
import publishReducer from './publish';
import splitTestReducer from './splitTest';
import updateReducer from './update';
import versionsReducer from './versions';

export default combineReducers({
    analyticsReducer,
    campaignsReducer,
    domainEditingReducer,
    overviewReducer,
    publishReducer,
    splitTestReducer,
    updateReducer,
    versionsReducer,
});
