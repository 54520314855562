import { getSavedUnsplashSearchTerm, saveUnsplashSearchTerm } from '@/app/mediaLibrary/helper';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import type { BrandSearchResult } from '../types';
import type { State } from '@/app/mediaLibrary/store/index';
import type { StateCreator } from 'zustand';

export interface SearchSlice {
    brandSearchInputValue: string;
    brandSearchTerm: string;
    brandSearchResults: BrandSearchResult[];

    searchInputValue: string;
    searchTerm: string | null;
    unsplashSearchTerms: string[];

    setBrandSearchInputValue: (brandSearchInputValue: string) => void;
    setBrandSearchTerm: (brandSearchTerm: string) => void;
    setSearchInputValue: (searchInputValue: string) => void;
    setSearchTerm: (searchTerm: string) => void;
    resetSearch: () => void;
    fetchUnsplashSearchTerms: () => void;
    saveUnsplashRecentSearchTerms: (searchTerm: string) => void;
}

export const createSearchSlice: StateCreator<
    State,
    [['zustand/devtools', never]],
    [],
    SearchSlice
> = (set) => ({
    // Brand search
    brandSearchInputValue: '',
    brandSearchTerm: '',
    brandSearchResults: [],

    // Image search
    searchInputValue: '',
    searchTerm: null,
    unsplashSearchTerms: [],

    setBrandSearchInputValue: (brandSearchInputValue) => set({ brandSearchInputValue }),
    setBrandSearchTerm: (brandSearchTerm) => set({ brandSearchTerm }),
    setSearchInputValue: (searchInputValue) => set({ searchInputValue }),
    setSearchTerm: (searchTerm) => set({ searchTerm }),
    fetchUnsplashSearchTerms: () => {
        const campaignId = getCampaignIdFromRouter();

        if (campaignId) {
            const savedSearches = getSavedUnsplashSearchTerm(campaignId);

            if (savedSearches.length) {
                set({ unsplashSearchTerms: savedSearches });
            }
        }
    },
    saveUnsplashRecentSearchTerms: (searchTerm) => {
        const campaignId = getCampaignIdFromRouter();

        if (campaignId) {
            set({ unsplashSearchTerms: saveUnsplashSearchTerm(campaignId, searchTerm) });
        }
    },
    resetSearch: () => {
        set({
            searchInputValue: '',
            searchTerm: null,
            brandSearchTerm: '',
            brandSearchInputValue: '',
            activeBrand: null,
        });
    },
});
