import { EditorState, RichUtils } from 'draft-js';

// get entity key of selection
export const getLinkKey = (editorState: EditorState): string => {
    if (!editorState) {
        return null;
    }

    const selection = editorState.getSelection();

    if (selection.isCollapsed()) {
        return null;
    }

    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);

    return blockWithLinkAtBeginning.getEntityAt(startOffset);
};

// apply inline style to current selection
export const applyInlineStyle = (editorState, style: string): EditorState => {
    const editorStateFocused = EditorState.forceSelection(editorState, editorState.getSelection());

    return RichUtils.toggleInlineStyle(editorStateFocused, style);
};

// apply entity to current selections
export const applyLinkEntity = (editorState: EditorState, url: string): EditorState => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
        url: url,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
    });

    return RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
};

// remove entity from current selections
export const removeLinkFromSelection = (editorState): EditorState => {
    const selection = editorState.getSelection();

    const editorStateFocused = EditorState.forceSelection(editorState, editorState.getSelection());

    return RichUtils.toggleLink(editorStateFocused, selection, null);
};
