import { Bars3BottomLeftIcon, Bars3Icon } from '@heroicons/react/24/outline';
import findIndex from 'lodash/findIndex';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import RadioSlider from '@/ui/components/RadioSlider';

import type { RadioSliderOption, RadioSliderSelection } from '@/ui/types';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    submit: () => void;
}

const listAligns: RadioSliderOption[] = [
    {
        name: 'left',
        value: 'left',
        render: () => <Bars3BottomLeftIcon className="size-5" />,
    },
    {
        name: 'center',
        value: 'center',
        render: () => <Bars3Icon className="size-5" />,
    },
];

export const ListStyle = ({ input, submit }: Props) => {
    const handleChange = (align: RadioSliderSelection) => {
        input.onChange(align.value);

        setTimeout(submit);
    };

    const activeIndex = findIndex(listAligns, { name: input.value });

    return (
        <FieldContainer>
            <RadioSlider onChange={handleChange} items={listAligns} activeIndex={activeIndex} />
        </FieldContainer>
    );
};
