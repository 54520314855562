import { DraggableConfigurationHelper } from '@/app/editor/engine/core/utils/dragAndDrop/configuration';

import type {
    EditorEngineDefaultDraggableConfiguration,
    EditorEngineDefaultTypeInput,
    EditorEngineDraggableConfiguration,
    EditorEngineDropPosition,
} from '@/app/editor/engine/core/types';
import type { SetterFunction, ValueOrSetterFunction } from '@/app/editor/engine/core/types/util';

/**
 * A builder for draggable configurations. This utility can be used to chain
 * configuration changes together in a more readable way.
 *
 * Given a node, a draggable configuration will be active for that node.
 * This configuration is considered when a drag operation wants to target that
 * node.
 *
 * When deciding whether a node can be dragged, however, the configuration that
 * is active for that node is considered instead.
 */
export class DraggableConfiguration<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
    TypedDraggableConfiguration extends
        EditorEngineDefaultDraggableConfiguration = EditorEngineDraggableConfiguration<TEditorEngineTypeInput>,
> {
    private reducers: SetterFunction<TypedDraggableConfiguration>[];

    constructor(startingConfiguration: ValueOrSetterFunction<TypedDraggableConfiguration>) {
        this.reducers = [
            typeof startingConfiguration === 'function'
                ? startingConfiguration
                : () => startingConfiguration,
        ];
    }

    /**
     * Returns the current configuration.
     */
    getConfiguration() {
        return ((initialValue: TypedDraggableConfiguration) => {
            return this.reducers.reduce((previous, current) => {
                return current(previous);
            }, initialValue);
        }) satisfies SetterFunction<TypedDraggableConfiguration>;
    }

    /**
     * Extends the current configuration with the provided configuration.
     */
    extend(configuration: SetterFunction<TypedDraggableConfiguration>): this {
        this.reducers.push(configuration);

        return this;
    }

    /**
     * Broadens the current configuration with the provided configuration.
     *
     * @see {DraggableConfigurationHelper.broaden}
     */
    broaden(configuration: Partial<Omit<TypedDraggableConfiguration, 'executeDrop'>>): this {
        this.extend(DraggableConfigurationHelper.broaden(configuration));

        return this;
    }

    /**
     * Restricts the current configuration with the provided configuration.
     *
     * @see {DraggableConfigurationHelper.restrict}
     */
    restrict(configuration: Partial<Omit<TypedDraggableConfiguration, 'executeDrop'>>): this {
        this.extend(
            DraggableConfigurationHelper.restrict<TypedDraggableConfiguration>(configuration),
        );

        return this;
    }

    /**
     * Restricts the drop position of the current configuration.
     */
    restrictDropPosition({
        restrictWhenTargeting = false,
        restrictWhenTargeted = false,
        restrictedPositions = [],
    }: {
        restrictWhenTargeting?: boolean;
        restrictWhenTargeted?: boolean;
        restrictedPositions: EditorEngineDropPosition[];
    }): this {
        return this.restrict({
            canTarget: ({ preview }) => {
                return (
                    !restrictWhenTargeting || !restrictedPositions.includes(preview.futurePosition)
                );
            },
            canBeTargeted: ({ preview }) => {
                return (
                    !restrictWhenTargeted || !restrictedPositions.includes(preview.futurePosition)
                );
            },
        } as Partial<Omit<TypedDraggableConfiguration, 'executeDrop'>>);
    }

    /**
     * Broadens the drop position of the current configuration.
     */
    broadenDropPosition({
        broadenWhenTargeting = false,
        broadenWhenTargeted = false,
        allowedPositions = [],
    }: {
        broadenWhenTargeting?: boolean;
        broadenWhenTargeted?: boolean;
        allowedPositions: EditorEngineDropPosition[];
    }): this {
        return this.broaden({
            canTarget: ({ preview }) => {
                return broadenWhenTargeting && allowedPositions.includes(preview.futurePosition);
            },
            canBeTargeted: ({ preview }) => {
                return broadenWhenTargeted && allowedPositions.includes(preview.futurePosition);
            },
        } as Partial<Omit<TypedDraggableConfiguration, 'executeDrop'>>);
    }

    /**
     * Creates a new draggable configuration from the provided configuration.
     */
    static fromNewConfiguration<
        TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
        TInstanceType extends DraggableConfiguration<TEditorEngineTypeInput>,
        TypedDraggableConfiguration extends
            EditorEngineDefaultDraggableConfiguration = EditorEngineDraggableConfiguration<TEditorEngineTypeInput>,
    >(
        this: new (
            startingConfiguration: ValueOrSetterFunction<TypedDraggableConfiguration>,
        ) => TInstanceType,
        configuration: ValueOrSetterFunction<EditorEngineDefaultDraggableConfiguration>,
    ): TInstanceType {
        return new this(configuration as SetterFunction<TypedDraggableConfiguration>);
    }

    /**
     * Creates a new draggable configuration from a locked configuration.
     *
     * This is a configuration that disables dragging for all nodes, and will be
     * the active for the sub-tree of nodes in which is is applied (unless it is
     * overridden by a more specific configuration).
     */
    static fromNewLockedConfiguration<
        TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
        TInstanceType extends DraggableConfiguration<TEditorEngineTypeInput>,
    >(this: {
        fromNewConfiguration: (
            configuration: ValueOrSetterFunction<EditorEngineDefaultDraggableConfiguration>,
        ) => TInstanceType;
    }): TInstanceType {
        return this.fromNewConfiguration(DraggableConfigurationHelper.alwaysDisable);
    }

    /**
     * Creates a new draggable configuration that extends the inherited
     * configuration with the provided configuration.
     */
    static fromInherited<
        TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
        TInstanceType extends DraggableConfiguration<TEditorEngineTypeInput>,
        TypedDraggableConfiguration extends
            EditorEngineDefaultDraggableConfiguration = EditorEngineDraggableConfiguration<TEditorEngineTypeInput>,
    >(
        this: new (
            startingConfiguration: ValueOrSetterFunction<TypedDraggableConfiguration>,
        ) => TInstanceType,
        configuration: SetterFunction<TypedDraggableConfiguration> = (config) => config,
    ): TInstanceType {
        return new this(configuration);
    }
}
