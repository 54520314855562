import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';

import type { KeyboardEvent, ChangeEvent, MouseEvent } from 'react';

export interface Props {
    onConfirm: (event: KeyboardEvent | MouseEvent) => void;
    onRemove: () => void;
    onChange: (url: string) => void;
    url: string;
}

const LinkUrlInput = ({ onChange, onRemove, onConfirm, url }: Props) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let url = event.target.value;

        onChange(url);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onConfirm(event);
        }
    };

    return (
        <FieldContainer>
            <div className="mt-2 flex h-12 w-full items-center rounded-lg bg-gray-100 px-4">
                <input
                    className="placeholder-normal flex-1 truncate bg-transparent pr-2 text-sm outline-none"
                    placeholder="https://example.com"
                    onChange={handleChange}
                    value={url}
                    onKeyDown={handleKeyDown}
                    autoFocus
                />
                <button onClick={onConfirm} className="pr-2 text-gray-400 hover:text-blue-500">
                    <CheckCircleIcon className="size-5" />
                </button>
                <button onClick={onRemove} className="text-gray-400 hover:text-gray-600">
                    <XMarkIcon className="size-4" />
                </button>
            </div>
        </FieldContainer>
    );
};

export default LinkUrlInput;
