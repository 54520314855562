import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Children, isValidElement, cloneElement } from 'react';
import { useState } from 'react';

import { AccordionItem } from '@/app/editor/blocks/components/AccordionItem/NewComponent';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { getColorByLightness } from '@/app/editor/themes/helpers';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({ children, componentType, box, nestedLevel }) => {
        const [expanded, setExpanded] = useState(true);
        const borderRadiusClass = useBorderRadius('faqItem');

        const childBlocks = Children.toArray(children);

        const headlineTextBlock = isValidElement<BlockProps>(childBlocks[0])
            ? cloneElement(childBlocks[0], {
                  nestedLevel: nestedLevel + 1,
                  propsFromParent: {
                      parentComponentType: componentType,
                  },
              })
            : null;

        const bodyTextBlock = isValidElement<BlockProps>(childBlocks[1])
            ? cloneElement(childBlocks[1], {
                  nestedLevel: nestedLevel + 1,
                  propsFromParent: {
                      noMentions: true,
                      parentComponentType: componentType,
                  },
              })
            : null;

        const toggleExpand = () => {
            setExpanded(!expanded);
        };

        return (
            <div
                className={cn('pb-0 transition-all ease-out', borderRadiusClass)}
                style={{ backgroundColor: box.backgroundColor }}
            >
                <div className="flex items-start p-4">
                    <div className="flex-1">{headlineTextBlock}</div>
                    <div className="flex-shrink-0 pl-4" onClick={toggleExpand}>
                        <ChevronDownIcon
                            className={cn(
                                'size-6 transform-gpu transition-transform duration-500 ease-out',
                                {
                                    'rotate-180': expanded,
                                },
                            )}
                            style={{
                                fill: getColorByLightness(null, box.backgroundColor),
                            }}
                        />
                    </div>
                </div>
                <div
                    className={cn(
                        'px-4 transition-all duration-500 ease-out',
                        expanded ? 'block' : 'hidden',
                    )}
                >
                    <div className="pt-1" />
                    {bodyTextBlock}
                    <div className="pt-4" />
                </div>
            </div>
        );
    },
    AccordionItem,
);

Component.displayName = 'AccordionItem';

export default Component;
