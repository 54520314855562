import { createContext, useContext } from 'react';

import { createDraggableConfigurationContext } from '@/app/editor/engine/core/context/createDraggableConfigurationContext';
import { createEditorEngineProvider } from '@/app/editor/engine/core/context/createEditorEngineProvider';
import { createEditorEngineRenderers } from '@/app/editor/engine/core/context/createEditorEngineRenderers';
import { getDraggableConfigurationOverride } from '@/app/editor/engine/core/context/getDraggableConfigurationOverride';

import type {
    EditorEngine,
    EditorEngineComponent,
    EditorEngineDefaultManagerProps,
    EditorEngineCustomNodeWrapper,
    EditorEngineDefaultTypeInput,
    EditorEngineActionEnhancement,
} from '@/app/editor/engine/core/types';

interface EditorEngineCreationOptions<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
    TExtraDocumentManagerProps extends object,
    TExtraNodeManagerProps extends object,
> {
    /**
     * The name of this editor engine instance.
     */
    name: string;
    /**
     * The component that will render each node.
     */
    Component: EditorEngineComponent<
        TEditorEngineTypeInput['Document'],
        TEditorEngineTypeInput['Data']
    >;
    /**
     * The actions that this editor engine instance can perform.
     */
    actions?: TEditorEngineTypeInput['ProvidedActions'];
    /**
     * A hook that will provide the document manager for this editor engine
     * instance.
     */
    useDocumentManager: (
        props: Partial<TExtraDocumentManagerProps> &
            EditorEngineDefaultManagerProps<TEditorEngineTypeInput['ExtraContext']>,
    ) => TEditorEngineTypeInput['DocumentManager'];
    /**
     * A hook that will provide the node manager for this editor engine
     * instance.
     */
    useNodeManager: (
        props: Partial<TExtraNodeManagerProps> &
            EditorEngineDefaultManagerProps<TEditorEngineTypeInput['ExtraContext']>,
    ) => TEditorEngineTypeInput['NodeManager'];
    /**
     * A custom node wrapper that will be used to wrap nodes when rendering.
     */
    CustomNodeWrapper?: EditorEngineCustomNodeWrapper<TEditorEngineTypeInput>;
}

/**
 * Creates an editor engine instance.
 */
export const createEditorEngine = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
    TExtraDocumentManagerProps extends object,
    TExtraNodeManagerProps extends object,
>({
    name,
    Component,
    actions = {} as TEditorEngineTypeInput['ProvidedActions'],
    useDocumentManager,
    useNodeManager,
    CustomNodeWrapper,
}: EditorEngineCreationOptions<
    TEditorEngineTypeInput,
    TExtraDocumentManagerProps,
    TExtraNodeManagerProps
>) => {
    const editorEngineContext = createContext<EditorEngine<TEditorEngineTypeInput>>({
        isActive: false,
    } as EditorEngine<TEditorEngineTypeInput>);
    const draggableConfigurationContext =
        createDraggableConfigurationContext<TEditorEngineTypeInput>();

    const OriginalProvider = editorEngineContext.Provider;
    const DraggableConfigurationOverride =
        getDraggableConfigurationOverride<TEditorEngineTypeInput>({
            dragAndDropContext: draggableConfigurationContext.context,
        });

    const Provider = createEditorEngineProvider<
        TEditorEngineTypeInput,
        TExtraDocumentManagerProps,
        TExtraNodeManagerProps,
        EditorEngineActionEnhancement<TEditorEngineTypeInput>
    >({
        name,
        context: editorEngineContext,
        EditorEngineProvider: OriginalProvider,
        providedActions: actions,
        RenderNodeComponent: Component,
        useDocumentManager,
        useNodeManager,
        draggableConfigurationContext: draggableConfigurationContext,
    });
    const useEditorEngine = () => useContext(editorEngineContext);
    const { Renderer, ByParentRenderer, Sequence } =
        createEditorEngineRenderers<TEditorEngineTypeInput>({
            context: editorEngineContext,
            CustomNodeWrapper,
        });

    return {
        context: editorEngineContext,
        Provider,
        Renderer,
        ByParentRenderer,
        Sequence,
        useEditorEngine,
        DraggableConfigurationOverride,
    };
};
