import { DEFAULT_AUTH_URL } from '@/app/auth/constants';

import { i18n } from 'next-i18next';

export const getLocalizedLoginUrl = (enableLoginSuccessRedirectMechanism: boolean = false) => {
    const isWorkpaceDomain = window?.location?.hostname?.includes(
        process.env.NEXT_PUBLIC_WORKSPACE_DOMAIN,
    );

    const locale = i18n?.resolvedLanguage;

    // Including explicit 'de' locale to force langauge consistency between apps
    // Usually it's handled by the `NEXT_LOCALE` cookie but we don't set it unless the user changes the language in the settings manually
    const localizedCheckoutLoginUrl = process.env.NEXT_PUBLIC_AUTH_URL
        ? process.env.NEXT_PUBLIC_AUTH_URL + `/${locale}`
        : undefined;

    const rawUrlRedirectUrl = isWorkpaceDomain
        ? DEFAULT_AUTH_URL
        : localizedCheckoutLoginUrl ?? DEFAULT_AUTH_URL;

    const redirectUrl = new URL(rawUrlRedirectUrl, window.location.href);

    if (enableLoginSuccessRedirectMechanism) {
        const currentPathname = window?.location?.pathname;

        if (currentPathname && currentPathname.length > 0 && currentPathname !== '/') {
            redirectUrl.searchParams.set('redirect_pathname', currentPathname);
        }
    }

    return redirectUrl.href;
};
