import type { ForwardRefExoticComponent, PropsWithoutRef, SVGProps } from 'react';

export interface GroupCommand {
    id: string;
    translationKey: string;
    icon: ForwardRefExoticComponent<PropsWithoutRef<SVGProps<SVGSVGElement>>>;
    isInternal?: boolean;
    isExternal?: boolean;
    isAdmin?: boolean;
}

export const enum CommandMenuType {
    default = 'default',
    workspace = 'workspace',
}

export const enum CommandMenuSection {
    general = 'general',
    help = 'help',
    recentFunnels = 'recentFunnels',
    workspaceFunnels = 'workspaceFunnels',
}
