import { combineReducers } from 'redux';

import columnSelectReducer from './columnSelect';
import dateRangePickerReducer from './dateRangePicker';
import dropOffChartReducer from './dropOffChart';
import kpisReducer from './kpis';
import leadExportReducer from './leadExport';
import resetAnalyticsReducer from './resetAnalytics';
import sessionsReducer from './sessions';
import variantAnalyticsReducer from './variantAnalytics';

export default combineReducers({
    columnSelectReducer,
    dateRangePickerReducer,
    dropOffChartReducer,
    kpisReducer,
    leadExportReducer,
    resetAnalyticsReducer,
    sessionsReducer,
    variantAnalyticsReducer,
});
