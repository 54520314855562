import Image from 'next/image';

import { cn } from '@/utils/cn';
import { isDecember } from '@/utils/dates';

export interface PerspectiveLogoProps {
    size?: number;
    allowXmasLogo?: boolean;
}

export const PerspectiveLogo = ({ size = 32, allowXmasLogo = false }: PerspectiveLogoProps) => {
    // show Christmas logo from 1st Dec to 31st Dec
    const isXmas = isDecember() && allowXmasLogo;

    const src = isXmas ? '/logo_xmas.png' : '/logo_64x64.png';
    const homeSrc = isXmas ? '/logo_home_xmas.png' : '/logo_home_64x64.png';

    const displaySize = isXmas ? 40 : size;

    return (
        <>
            <div
                className={cn('group-hover:hidden', {
                    '-translate-y-1': isXmas,
                })}
            >
                <Image src={src} alt="perspective logo" width={displaySize} height={displaySize} />
            </div>
            <div
                className={cn('hidden group-hover:block', {
                    '-translate-y-1': isXmas,
                })}
            >
                <Image
                    src={homeSrc}
                    alt="perspective logo home icon"
                    width={displaySize}
                    height={displaySize}
                    priority
                />
            </div>
        </>
    );
};
