export const CTA = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="2" y="9" width="20" height="6" rx="2" fill="#007AFF" />
            <path
                opacity="0.15"
                d="M2 4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V5C22 6.10457 21.1046 7 20 7H4C2.89543 7 2 6.10457 2 5V4Z"
                fill="#007AFF"
            />
            <path
                opacity="0.15"
                d="M2 19C2 17.8954 2.89543 17 4 17H20C21.1046 17 22 17.8954 22 19V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V19Z"
                fill="#007AFF"
            />
        </svg>
    );
};
