import { PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS } from '../constants';

export const downloadFile = (downloadUrl: string) => {
    // Using an anchor item allows us to download the file directly
    const linkAnchor = document.createElement('a');
    linkAnchor.href = downloadUrl;
    linkAnchor.style.display = 'none';
    linkAnchor.className = PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS;

    document.body.appendChild(linkAnchor);

    linkAnchor.click();
    linkAnchor.remove();
};
