import { createSlice } from '@reduxjs/toolkit';

import { getCompany } from '@/app/company/models/company';
import { setActiveView } from '@/app/editor/editor/models/sidebar';
import { addThemes, setPreviewTheme } from '@/app/editor/themes/models/themes';
import { apiPost, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { ThemeResource } from '@/app/editor/themes/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const newThemeSlice = createSlice({
    name: `editor/${NAME}/newTheme`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = newThemeSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.newThemeReducer?.loading;

// === Thunks ======

// Create new theme
export const createNewTheme =
    (theme: ThemeResource): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const company = getCompany(state);

        try {
            dispatch(setLoading(true));

            const response = await apiPost<ResponseData<ThemeResource>>('/themes', {
                data: {
                    type: 'theme',
                    attributes: theme.attributes,
                    relationships: {
                        company: { data: { id: company.id, type: 'company' } },
                    },
                },
            });

            const newTheme = response?.data?.data;

            dispatch(addThemes({ [newTheme.id]: newTheme }));
            dispatch(setPreviewTheme(newTheme));
            dispatch(setActiveView('editTheme'));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'creating theme failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default newThemeSlice.reducer;
