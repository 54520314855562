export const Hero = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="2" y="2" width="20" height="12" rx="2" fill="#007AFF" />
            <path
                opacity="0.15"
                d="M2 18C2 16.8954 2.89543 16 4 16H20C21.1046 16 22 16.8954 22 18V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V18Z"
                fill="#007AFF"
            />
        </svg>
    );
};
