import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { Field, FormSection, reduxForm } from 'redux-form';

import { getNextHigherParentByType } from '@/app/editor/blocks/models/blocks';
import GenericSize from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/GenericSize';
import { ListStyle } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/ListStyle';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { useAppSelector } from '@/core/redux/hooks';

import { mediaSizeMap } from '../Media/forms/ListItemForm';
import { textSizeMap } from '../Text/forms/ListItemForm';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends Pick<DefaultEditFormProps, 'form' | 'handleSubmit'> {}

const Form: FC<Props & InjectedFormProps> = ({ handleSubmit, form }) => {
    const { t } = useTranslation(NAME);
    const listBlock = useAppSelector((state) => getNextHigherParentByType(state, form, 'list'));

    return (
        <>
            <FormSection name="additionalBlocks">
                <FormSection name={listBlock.id}>
                    <Field
                        name="attributes.content.align"
                        component={ListStyle}
                        submit={handleSubmit}
                    />

                    <Subheading text={t('text-size')} className="mb-2 mt-4" />

                    <Field
                        name="attributes.content.textSize"
                        component={GenericSize}
                        submit={handleSubmit}
                        valueMap={textSizeMap}
                    />

                    <Subheading text={t('visual-size')} className="mb-2 mt-4" />

                    <Field
                        name="attributes.content.visualSize"
                        component={GenericSize}
                        submit={handleSubmit}
                        valueMap={mediaSizeMap}
                    />
                </FormSection>
            </FormSection>
        </>
    );
};

Form.displayName = 'ListItemEditForm';

export default reduxForm({})(Form);
