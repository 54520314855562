import { useContext } from 'react';

import { getBlockById } from '@/app/editor/blocks/models/blocks';
import { isPreviewContext } from '@/app/editor/engine/core/context/isPreviewContext';
import { normalizePreviewBlock } from '@/app/editor/engine/core/functions/utils/normalizePreviewBlock';
import { getPreviewById } from '@/app/editor/sections/models/sections';
import { useAppSelector } from '@/core/redux/hooks';

/**
 * Returns a block or preview block based on the current context.
 *
 * If we are rendering nodes within a preview block, the block identifiers might
 * refer to preview blocks as opposed to normal ones. Components that render
 * blocks can use this hook so they don't need to make the distinction.
 */
export const useBlockOrPreview = (blockId: string) => {
    const isPreview = useContext(isPreviewContext);
    const block = useAppSelector((state) => getBlockById(state, blockId));
    const preview = useAppSelector((state) => getPreviewById(state, blockId));

    return isPreview ? normalizePreviewBlock(preview) : block;
};
