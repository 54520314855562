import { AUTH_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE } from '@/app/auth/constants';
import { NETWORK_ERROR_CODES_WITH_DEDICATED_MESSAGES } from '@/core/api/constants';

import get from 'lodash/get';
import Router from 'next/router';

import { navigateToBillingAndPromoteFeature } from '@/app/billing/helpers/navigateToBillingAndPromoteFeature';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiPost } from '@/core/api';
import { StatusCodes } from '@/core/api/types';
import { saveCookie, loadCookie, DEFAULT_EXPIRE, EXTENDED_EXPIRE } from '@/utils/cookies';
import { isBrowserEnv } from '@/utils/environments';

import type { FeatureId } from '@/app/billing/types';
import type { Resource, ResponseData } from '@/core/api/types';
import type { AxiosResponse, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import type { AxiosError } from 'axios';

export const resourceArrayToObject = <A = any, R = any>(
    array: Resource<A, R>[],
): Record<string, Resource<A, R>> => {
    const resourceObject: Record<string, Resource<A, R>> = {};

    if (!array) {
        return resourceObject;
    }

    array.forEach((resource) => {
        resourceObject[resource.id] = resource;
    });

    return resourceObject;
};

export const getDataFromResponse = <T = any, E = any, M = any>(
    response: Partial<AxiosResponse<ResponseData<T, E, M>>>,
    defaultValue: any = null,
) => {
    return get(response, 'data.data', defaultValue) as T;
};

export const getIncludedFromResponse = <T = any, E = any, M = any>(
    response: Partial<AxiosResponse<ResponseData<T, E, M>>>,
    defaultValue: any = null,
) => {
    return get(response, 'data.included', defaultValue) as T;
};

export const getMetaFromResponse = <T = any, E = any, M = any>(
    response: Partial<AxiosResponse<ResponseData<T, E, M>>>,
    defaultValue: any = null,
) => {
    return get(response, 'data.meta', defaultValue) as M;
};

// Save auth cookies from login response
export const saveTokensFromResponse = (response: AxiosResponse, rememberMe?: boolean) => {
    const data = getDataFromResponse(response);
    // Extract Headers from login request
    const token = response.headers?.authorization ?? data?.attributes?.token;
    const refreshToken = response.headers['refresh-token'] ?? data?.attributes?.refreshToken;

    // Set Cookies and token for API
    saveCookie(AUTH_TOKEN_COOKIE, `Bearer ${token}`, DEFAULT_EXPIRE);

    if (rememberMe) {
        saveCookie(REFRESH_TOKEN_COOKIE, refreshToken, EXTENDED_EXPIRE);
    }
};

export const getRelationship = (resource: Resource<any, any>, relationship: string) => {
    return get(resource, `relationships.${relationship}.data`, null);
};

// Error & Refresh token flow:
// ==========================

let loggingOut = false;

// prevent fetching via refresh token for each unauthorized request
let isRefreshing = false;

// queue of unauthorized requests during fetching of new access token
let unauthorizedRequests: (() => void)[] = [];

const addToUnauthorizedRequestQueue = (callback: () => void) => {
    unauthorizedRequests.push(callback);
};

const onAccessTokenFetched = () => {
    unauthorizedRequests = unauthorizedRequests.filter((callback) => callback());
};

const handleUnauthorized = (
    apiInstance: AxiosInstance,
    originalRequest?: InternalAxiosRequestConfig & { retry?: boolean },
) => {
    if (originalRequest && !originalRequest?.retry) {
        originalRequest.retry = true;

        const refreshToken = loadCookie(REFRESH_TOKEN_COOKIE);

        // No refresh token -> logout
        if (!refreshToken && !loggingOut) {
            loggingOut = true; // prevent calling /logout more than once

            return Router.replace('/logout');
        }

        // Refresh token -> fetch new access token if not already fetching
        if (refreshToken && !isRefreshing) {
            isRefreshing = true;

            apiPost('/login/refresh', { refreshToken })
                .then((response) => {
                    saveTokensFromResponse(response);
                    isRefreshing = false;

                    // run queued unauthorized requests after fetching new access token
                    onAccessTokenFetched();
                })
                .catch(() => {
                    // in case of error -> logout
                    Router.replace('/logout');
                });
        }

        return new Promise((resolve) => {
            addToUnauthorizedRequestQueue(() => {
                resolve(apiInstance(originalRequest));
            });
        });
    }
};

const handleUpgradeRequired = async (feature: FeatureId) => {
    await navigateToBillingAndPromoteFeature(feature);
};

const handlePaymentRequired = async () => {
    await Router.push('/settings/plan?reactivation=true');
};

export const genericApiResponseErrorCallback = async (
    error: AxiosError<{ message: string }>,
    apiInstance: AxiosInstance,
) => {
    const response = error.response;

    if (isBrowserEnv() && response) {
        const originalRequest = error.config;

        switch (response.status) {
            case StatusCodes.UNAUTHORIZED:
                return handleUnauthorized(apiInstance, originalRequest);
            case StatusCodes.UPGRADE_REQUIRED:
                await handleUpgradeRequired(response.data.message as FeatureId);
                break;
            case StatusCodes.PAYMENT_REQUIRED:
                await handlePaymentRequired();
                break;
        }
    }

    return Promise.reject(error);
};

export const showWarning = (message: string, description?: string, details?: string) => {
    showToast({
        type: 'warning',
        message,
        description,
        details,
    });
};

export const showNetworkError = (error: Error) => {
    const status = Number((error as AxiosError)?.response?.status);
    const hasDedicatedErrorMessage = NETWORK_ERROR_CODES_WITH_DEDICATED_MESSAGES.includes(
        status as (typeof NETWORK_ERROR_CODES_WITH_DEDICATED_MESSAGES)[number],
    );

    // Show generic error message if we don't have a dedicated one
    if (!status || !hasDedicatedErrorMessage) {
        showWarning(
            'networkErrors.generic-title',
            'networkErrors.generic-description',
            error?.message,
        );

        return;
    }

    showWarning(
        `networkErrors.${status}-title`,
        `networkErrors.${status}-description`,
        error?.message,
    );
};
