import { cropCurrentSlide } from '@/app/editor/blocks/models/slider';

import { DEFAULT_BLOCK_CONFIG } from '../../../editor/constants';

import type { BlockConfig } from '@/app/editor/blocks/types';

const config: BlockConfig = {
    ...DEFAULT_BLOCK_CONFIG,
    actions: {
        onDoubleClick: (block) => (dispatch) => {
            dispatch(cropCurrentSlide(block));
        },
    },
};

export default config;
