import { DraggableConfigurationInheritedValueAwareOverride } from '@/app/editor/engine/core/components/view/DraggableConfigurationInheritedValueAwareOverride';

import type { createDraggableConfigurationContext } from '@/app/editor/engine/core/context/createDraggableConfigurationContext';
import type {
    EditorEngineDefaultTypeInput,
    EditorEngineDraggableConfiguration,
} from '@/app/editor/engine/core/types';
import type { ValueOrSetterFunction } from '@/app/editor/engine/core/types/util';
import type { DraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/configuration/builder/DraggableConfiguration';
import type { ReactNode } from 'react';

interface Input<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> {
    /**
     * The drag and drop context to use.
     */
    dragAndDropContext: ReturnType<
        typeof createDraggableConfigurationContext<TEditorEngineTypeInput>
    >['context'];
}

interface Props<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> {
    /**
     * The subtree to which the draggable configuration should be applied.
     */
    children: ReactNode;
    /**
     * The draggable configuration to use.
     *
     * This can be a new draggable configuration or a function that receives the
     * inherited draggable configuration and returns a new one.
     */
    draggableConfiguration:
        | DraggableConfiguration<TEditorEngineTypeInput>
        | ValueOrSetterFunction<EditorEngineDraggableConfiguration<TEditorEngineTypeInput>>;
}

export const getDraggableConfigurationOverride = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    dragAndDropContext,
}: Input<TEditorEngineTypeInput>) => {
    /**
     * Override the draggable configuration for a subtree.
     *
     * This component can be used at any point within the Editor Engine instance
     * context to override the draggable configuration for a subtree.
     *
     * The override can either define a whole new draggable configuration or reuse
     * the existing one and modify it.
     */
    const DraggableConfigurationOverride = ({
        children,
        draggableConfiguration,
    }: Props<TEditorEngineTypeInput>) => {
        return (
            <DraggableConfigurationInheritedValueAwareOverride
                dragAndDropContext={dragAndDropContext}
                draggableConfiguration={draggableConfiguration}
            >
                {children}
            </DraggableConfigurationInheritedValueAwareOverride>
        );
    };

    return DraggableConfigurationOverride;
};
