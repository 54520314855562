import { reset as resetCampaigns } from './campaigns';
import { reset as resetMenu } from './menu';
import { reset as resetRecentCampaigns } from './recentCampaigns';
import { reset as resetWorkspaceCampaigns } from './workspaceCampaigns';

import type { AppThunk } from '@/core/redux/types';

export const resetCmdkState = (): AppThunk => (dispatch) => {
    dispatch(resetMenu());
    dispatch(resetCampaigns());
    dispatch(resetRecentCampaigns());
    dispatch(resetWorkspaceCampaigns());
};
