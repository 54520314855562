import { INVALID_SCRIPT, removeTextNodes } from '../helper';

export const googleMapsDefaultValidator = (container: HTMLDivElement) => {
    let isOnlyOneIFrame = false;
    let hasGoogleSrc = false;
    let hasWidthProp = false;
    let hasHeightProp = false;

    removeTextNodes(container);

    if (container.childNodes.length === 1 && container.firstElementChild?.tagName === 'IFRAME') {
        isOnlyOneIFrame = true;
    }

    const firstElemenSrc = container.firstElementChild?.getAttribute('src') ?? '';

    if (
        firstElemenSrc.startsWith('https://www.google.com/maps/embed') ||
        firstElemenSrc.startsWith('https://www.google.com/maps/d/embed')
    ) {
        hasGoogleSrc = true;
    }

    if (container.firstElementChild?.getAttribute('width')) {
        hasWidthProp = true;
    }

    if (container.firstElementChild?.getAttribute('height')) {
        hasHeightProp = true;
    }

    return {
        valid: isOnlyOneIFrame && hasGoogleSrc && hasWidthProp && hasHeightProp,
        message: INVALID_SCRIPT,
    };
};
