import { cn } from '@/utils/cn';

import type { ReactNode } from 'react';

export type NavbarStyle = 'solid-fill' | 'glass-effect' | 'borderless';

export interface NavbarProps {
    children?: ReactNode;
    style?: NavbarStyle;
}

export const Navbar = ({ children, style }: NavbarProps) => {
    return (
        <>
            <nav
                className={cn(
                    'fixed left-0 top-0 z-50 flex h-16 w-full items-center gap-4 border-b border-gray-200 px-3',
                    {
                        'bg-white': style === 'solid-fill',
                        'backdrop-blur-xl': style === 'glass-effect',
                        'border-none': style === 'borderless',
                    },
                )}
            >
                {children}
            </nav>
            {/*
             * This div acts as a spacer, ensuring that content following the Navbar does not overlap with it.
             */}
            <div className="pt-16" />
        </>
    );
};
