import {
    type EditorEngineDefaultTypeInput,
    EditorEngineDropPosition,
} from '@/app/editor/engine/core/types';
import { isValidDraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/isValidDraggableConfiguration';

import type { EditorEngineDraggableConfiguration } from '@/app/editor/engine/core/types';
import type { EditorEngineOrientation } from '@/app/editor/engine/core/types/util';
import type { Collision } from '@dnd-kit/core';

/**
 * Validates a collision object.
 */
export const isValidCollision = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>(
    collision: Collision,
): collision is Collision & {
    data: {
        position: EditorEngineDropPosition;
        orientation: EditorEngineOrientation;
        droppableContainer: {
            data: {
                current: {
                    draggableConfiguration: EditorEngineDraggableConfiguration<TEditorEngineTypeInput>;
                };
            };
        };
    };
} => {
    // todo(editorengine): consider zod for validation
    return (
        typeof collision === 'object' &&
        collision?.id &&
        collision?.data &&
        Boolean(collision.data['position']) &&
        Object.values(EditorEngineDropPosition).includes(collision.data['position']) &&
        isValidDraggableConfiguration(
            collision.data?.droppableContainer?.data?.current?.draggableConfiguration,
        )
    );
};
