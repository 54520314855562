import { cn } from '@/utils/cn';

export interface Props {
    className?: string;
}

const SelectIcon = ({ className }: Props) => {
    return (
        <div className={cn('block p-px', className)}>
            <svg width="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.29063 8.23017C7.00353 7.93159 6.52875 7.92228 6.23017 8.20938C5.93159 8.49647 5.92228 8.97125 6.20938 9.26983L9.45938 12.7698C9.60078 12.9169 9.79599 13 10 13C10.204 13 10.3992 12.9169 10.5406 12.7698L13.7906 9.26983C14.0777 8.97125 14.0684 8.49647 13.7698 8.20938C13.4713 7.92228 12.9965 7.93159 12.7094 8.23017L10 11.1679L7.29063 8.23017Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

export default SelectIcon;
