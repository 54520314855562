import { useRef, useEffect, useCallback, useState } from 'react';

import Popover from '@/ui/components/Popover';
import { cn } from '@/utils/cn';

import type { HTMLAttributes, KeyboardEvent, FocusEvent } from 'react';

export interface Props extends HTMLAttributes<HTMLTextAreaElement> {
    onSubmit: () => void;
    value?: string;
    inline?: boolean;
}

export const TextArea = ({ onSubmit, value, inline, ...props }: Props) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    const [hasFocus, setFocus] = useState(false);
    const autoResize = useCallback(() => {
        if (!ref.current) {
            return;
        }

        ref.current.style.height = 'auto';
        ref.current.style.height = `${ref.current.scrollHeight}px`;
    }, []);
    const containerRef = useRef<HTMLDivElement>();
    const containerRect = containerRef.current?.getBoundingClientRect();

    const handleKeyDown = useCallback(
        (evt: KeyboardEvent<HTMLTextAreaElement>) => {
            if (evt.key === 'Enter' && evt.shiftKey === false) {
                evt.preventDefault();
                onSubmit();
                setFocus(false);
            }
        },
        [onSubmit],
    );

    const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
        setFocus(true);
        props?.onFocus?.(event);

        autoResize();
    };

    // Resize textarea when changing size of the column
    useEffect(() => {
        if (inline || !containerRef.current) {
            return;
        }

        const observer = new ResizeObserver(autoResize);

        observer.observe(containerRef.current);

        return () => {
            observer.disconnect();
        };
    }, [autoResize, inline]);

    const handleClick = () => {
        setFocus(true);

        if (inline) {
            requestAnimationFrame(() => {
                ref.current.focus();
                autoResize();
            });
        }
    };

    const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
        setFocus(false);
        props?.onBlur(event);
    };

    useEffect(() => {
        autoResize();
    }, [inline, autoResize, value]);

    return (
        <div ref={containerRef}>
            <Popover
                open={inline && hasFocus}
                mainAxisOffsetValue={-8}
                render={() => (
                    <textarea
                        {...props}
                        value={value}
                        className={cn(
                            '!max-h-80 flex-1 resize-none overflow-y-auto whitespace-normal bg-gray-50 !p-2 text-sm outline-offset-0 focus:border-none focus:outline focus:outline-1 focus:outline-blue-500',
                            props.className,
                        )}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        ref={ref}
                        style={{
                            width: containerRect?.width,
                        }}
                    />
                )}
            >
                <div />
            </Popover>

            {inline && !hasFocus && (
                <div
                    tabIndex={-1}
                    onClick={handleClick}
                    className={cn('h-9 w-full truncate', props.className)}
                >
                    {value}
                </div>
            )}

            {!inline && (
                <textarea
                    {...props}
                    value={value}
                    rows={1}
                    className={cn(
                        'min-h-9 flex-1 resize-none overflow-hidden whitespace-normal leading-normal',
                        props.className,
                        {
                            truncate: inline,
                            'py-[7px]': true,
                        },
                    )}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    ref={ref}
                />
            )}
        </div>
    );
};
