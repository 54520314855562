import { createSlice } from '@reduxjs/toolkit';

import { apiGet, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { SharedCampaignInfo } from '@/app/sharedFunnels/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    error: boolean;
    sharedCampaign: SharedCampaignInfo;
}

const initialState: State = {
    loading: false,
    error: false,
    sharedCampaign: null,
};

export const fetchSlice = createSlice({
    name: `${NAME}/fetch`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSharedCampaign(state, action: PayloadAction<SharedCampaignInfo>) {
            return {
                ...state,
                sharedCampaign: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, setError, setSharedCampaign, reset } = fetchSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.fetchReducer?.loading;

export const getError = (state: AppState) => state[NAME]?.fetchReducer?.error;

export const getSharedCampaign = (state: AppState) => state[NAME]?.fetchReducer?.sharedCampaign;

// === Thunks ======

export const fetchSharedCampaignInfo =
    (campaignId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setLoading(true));
        dispatch(setError(false));

        try {
            const response = await apiGet<ResponseData<SharedCampaignInfo>>(
                `/campaigns/${campaignId}/shared-info`,
            );

            dispatch(setSharedCampaign(response?.data?.data));
        } catch (err) {
            dispatch(setError(true));
            handleRuntimeError(err, { debugMessage: 'fetching shared funnel failed:' });
        } finally {
            dispatch(setLoading(false));
            dispatch(setError(false));
        }
    };

export default fetchSlice.reducer;
