import { NAME } from '@/app/editor/blocks/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';

import get from 'lodash/get';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import { reduxForm } from 'redux-form';
import { getFormValues, change } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import { FunnelSetting } from '@/app/funnelSettings/funnelSettings/types';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import TogglePanel from '@/ui/components/_BlockEditFields/TogglePanel';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({
    blockId,
    form,
    handleSubmit,
    campaignId,
    activeTheme,
}) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const formValues = useAppSelector((state) => getFormValues(form)(state));

    const imprintEnabled = useMemo(() => {
        const text = get(formValues, 'attributes.content.legalText');
        const link = get(formValues, 'attributes.content.legalLink');

        return Boolean(text && link);
    }, [formValues]);

    const privacyEnabled = useMemo(() => {
        const text = get(formValues, 'attributes.content.termsText');
        const link = get(formValues, 'attributes.content.termsLink');

        return Boolean(text && link);
    }, [formValues]);

    const brandingEnabled = get(formValues, 'attributes.content.branding');

    // Cookies
    const handleCookieEdit = useCallback(() => {
        dispatch(
            showModal(
                Modals.FUNNEL_SETTINGS,
                { campaignId, setting: FunnelSetting.Cookies },
                MODAL_OPTIONS[Modals.FUNNEL_SETTINGS],
            ),
        );
    }, [campaignId, dispatch]);

    // Branding
    const handleBrandingToggle = useCallback(
        (_, checked: boolean) => {
            dispatch(change(form, 'attributes.content.branding', checked));

            setTimeout(handleSubmit);
        },
        [dispatch, handleSubmit, form],
    );

    // Legal (imprint, privacy)
    const handleToggle = useCallback(
        (id: string, checked: boolean) => {
            if (checked) {
                dispatch(showModal(Modals.LEGAL_SETTINGS, { id, blockId: form }));
            } else {
                dispatch(change(form, `attributes.content.${id}Text`, ''));
                dispatch(change(form, `attributes.content.${id}Link`, ''));

                setTimeout(handleSubmit);
            }
        },
        [dispatch, handleSubmit, form],
    );

    const handleEditClick = useCallback(
        (id: string) => {
            dispatch(showModal(Modals.LEGAL_SETTINGS, { id, blockId: form }));
        },
        [dispatch, form],
    );

    const panels = useMemo(() => {
        return [
            {
                id: 'legal',
                title: 'legal',
                srTitle: 'legal-toggle',
                checked: imprintEnabled,
                onEditClick: handleEditClick,
                onToggle: handleToggle,
            },
            {
                id: 'terms',
                title: 'terms',
                srTitle: 'terms-toggle',
                checked: privacyEnabled,
                onEditClick: handleEditClick,
                onToggle: handleToggle,
            },
            {
                id: 'branding',
                title: 'Branding',
                srTitle: 'branding-toggle',
                checked: brandingEnabled,
                onToggle: handleBrandingToggle,
            },
            {
                id: 'cookies',
                title: 'cookie-settings',
                onEditClick: handleCookieEdit,
            },
        ];
    }, [
        imprintEnabled,
        privacyEnabled,
        brandingEnabled,
        handleEditClick,
        handleToggle,
        handleCookieEdit,
        handleBrandingToggle,
    ]);

    return (
        <>
            {panels.map((panel) => (
                <TogglePanel
                    key={panel.id}
                    id={panel.id}
                    title={t(panel.title)}
                    srTitle={t(panel.srTitle)}
                    checked={panel.checked}
                    onEditClick={panel.onEditClick}
                    onToggle={panel.onToggle}
                />
            ))}

            <BoxSettings
                blockId={blockId}
                activeTheme={activeTheme}
                handleSubmit={handleSubmit}
                colorProps={{ hasGradient: false }}
            />
        </>
    );
};

Form.displayName = 'FooterEditForm';

export default reduxForm({})(Form);
