import { WORKSPACE_DOMAIN_HOST } from '@/app/workspaces/constants';

import { useState } from 'react';

import { useOnMounted } from '@/utils/hooks/useMounted';

import PerspectiveFavicon from './PerspectiveFavicon';
import WorkspacesFavicon from './WorkspacesFavicon';

// Types
type FaviconTypes = 'perspective' | 'workspaces';

export const useFavicon = () => {
    const [favicon, setFavicon] = useState<FaviconTypes | undefined>();

    useOnMounted(() => {
        if (WORKSPACE_DOMAIN_HOST && window.location.host.endsWith(WORKSPACE_DOMAIN_HOST)) {
            setFavicon('workspaces');
        } else {
            setFavicon('perspective');
        }
    });

    if (favicon === 'perspective') {
        return <PerspectiveFavicon />;
    }

    if (favicon === 'workspaces') {
        return <WorkspacesFavicon />;
    }

    return null;
};
