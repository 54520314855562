import { EditorEngineHistoryEntryRecordedActionStatusType } from '@/app/editor/engine/core/types';

import type { EditorEngineHistoryDefaultEntry } from '@/app/editor/engine/core/types';

/**
 * Check if the entry can be retried.
 */
export const canRetryEntry = ({ entry }: { entry: EditorEngineHistoryDefaultEntry }) => {
    return (
        entry.recordedAction.executionResult.type ===
            EditorEngineHistoryEntryRecordedActionStatusType.Failed ||
        entry.recordedAction.executionResult.type ===
            EditorEngineHistoryEntryRecordedActionStatusType.FailedBySteps
    );
};
