export const NAME = 'auth';

export const AUTH_TOKEN_COOKIE = 'perspective_auth_token';
export const REFRESH_TOKEN_COOKIE = 'perspective_refresh_token';

export const WHITELISTED_ROUTES = [
    '/login',
    '/logout',
    '/verify-email/[eventId]',
    '/workspaces/invite/[invitationId]',
    '/workspaces/login',
    '/legal/data-privacy',
    '/legal/terms-and-conditions',
    '/user/[id]/unsubscribe', // @todo: typo, should be '/users/[id]/unsubscribe'. TBI
];

export const DEFAULT_AUTH_URL = '/login';
