import { useContext } from 'react';

import {
    propsFromParentContext,
    PropsFromParentProvider,
} from '@/app/editor/engine/core/context/propsFromParentContext';

import type { PropsFromParent } from '@/app/editor/blocks/types';
import type { ReactNode } from 'react';

interface Props {
    /**
     * The subtree to which the props from parent should be applied.
     */
    children: ReactNode;
    /**
     * The props from parent to use.
     */
    propsFromParent: PropsFromParent | undefined;
}

/**
 * Overrides the props from parent for the subtree in which this component
 * is used.
 *
 * todo(editorengine): should not be part of the core
 */
export const PropsFromParentOverrideProvider = ({ children, propsFromParent }: Props) => {
    const inheritedPropsFromParent = useContext(propsFromParentContext);

    return (
        <PropsFromParentProvider
            value={propsFromParent ? propsFromParent : inheritedPropsFromParent}
        >
            {children}
        </PropsFromParentProvider>
    );
};
