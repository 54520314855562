import Router from 'next/router';

import { getBlockDetails } from '@/app/editor/blocks/helpers';
import {
    getUsedUniqueInputsByFormBlockId,
    updateInputType,
} from '@/app/editor/blocks/models/inputTypes';
import { BorderMenuItem } from '@/app/editor/blocks/types';

import { DEFAULT_BLOCK_CONFIG } from '../../../editor/constants';

import type { BlockConfig, BlockResource } from '@/app/editor/blocks/types';
import type { Language } from 'types/generic';

const config: BlockConfig = {
    ...DEFAULT_BLOCK_CONFIG,
    borderMenu: {
        ...DEFAULT_BLOCK_CONFIG.borderMenu,
        items: [BorderMenuItem.delete, BorderMenuItem.move, BorderMenuItem.duplicate],
        showDuplicateAsAdd: true,
        addTooltip: 'add-input',
    },
    deletionReminder: true,
    actions: {
        onDuplicate: (block: BlockResource) => (dispatch, getState) => {
            const state = getState();
            const parentBlockId = getBlockDetails(block).parentComponent?.data?.id;
            const usedUniqueInputs = getUsedUniqueInputsByFormBlockId(state, parentBlockId);

            // Check if unique input is already present in parent form
            if (!usedUniqueInputs.includes(block?.attributes?.content?.field)) {
                return;
            }

            // -> if yes change inputType to `custom`
            dispatch(updateInputType(block.id, 'custom', Router.locale as Language));
        },
    },
};

export default config;
