/**
 * Step specific types.
 */

import type {
    EditorEngineHistoryEntryRecordedStep,
    EditorEngineHistoryEntryRecordedStepResult,
} from '@/app/editor/engine/core/types/history';

/**
 * When a step is being executed, its record might be updated (for example,
 * to signal that the step has failed, or has finished executing
 * successfully). This is the definition of a function that can be used to
 * update the record.
 */
export type EditorEngineStepUpdater<TExtraContext extends object> = (input: {
    step: EditorEngineHistoryEntryRecordedStep<TExtraContext>;
    result: EditorEngineHistoryEntryRecordedStepResult;
}) => void;

/**
 * The name of a step.
 */
export enum EditorEngineStepName {
    ChangeDocument = 'change-document-step',
    ChangeNode = 'change-node-step',
    InsertNode = 'insert-node-step',
    ReinsertNodeAtIndex = 'reinsert-node-at-index-step',
    RemoveNode = 'remove-node-step',
}
