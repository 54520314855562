import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { change, submit } from 'redux-form';

import { getBlockById } from '@/app/editor/blocks/models/blocks';
import { fieldNameMap } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/InputType/fieldNameMap';
import { getInputTypeData } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/InputType/helper';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { CONVERSION_INPUTS, NAME, UNIQUE_INPUTS, UNIQUE_INPUTS_IN_FORM } from '../constants';

import type { UniqueInputType } from '@/app/editor/blocks/types';
import type { RelationshipObject } from '@/core/api/types';
import type { AppState } from '@/core/redux/types';
import type { AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Language } from 'types/generic';

interface State {
    usedUniqueInputs: Record<string, UniqueInputType[]>;
}

const initialState: State = {
    usedUniqueInputs: EMPTY_OBJECT,
};

export const inputTypesSlice = createSlice({
    name: `editor/${NAME}/inputTypes`,
    initialState,
    reducers: {
        setUsedUniqueInputs(
            state,
            action: PayloadAction<{ formId: string; inputTypes: UniqueInputType[] }>,
        ) {
            return {
                ...state,
                usedUniqueInputs: {
                    ...state.usedUniqueInputs,
                    [action.payload.formId]: action.payload.inputTypes,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setUsedUniqueInputs, reset } = inputTypesSlice.actions;

// === Selectors ======

export const getUsedUniqueInputsByFormBlockId = (state: AppState, formBlockId: string) =>
    state[NAME]?.inputTypesReducer?.usedUniqueInputs[formBlockId];

// === Thunks ======

// set already used unique fields for each form block in redux state
export const setUniqueFormInputs = (formBlockId: string): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        const form = getBlockById(state, formBlockId);
        const formChildren = get(
            form,
            'relationships.components.data',
            EMPTY_ARRAY,
        ) as RelationshipObject[];

        const formInputComponents = formChildren.map((child) => {
            return getBlockById(state, child.id);
        });

        const uniqueFormInputComponents = formInputComponents.filter((component) => {
            return UNIQUE_INPUTS_IN_FORM.indexOf(get(component, 'attributes.content.field')) > -1;
        });

        const fields = uniqueFormInputComponents.map((component) => {
            return get(component, 'attributes.content.field');
        });

        return dispatch(setUsedUniqueInputs({ formId: formBlockId, inputTypes: fields }));
    };
};

// when changing the input type in the input edit form
export const updateInputType =
    (inputBlockId: string, field: string, locale: Language): AppThunk =>
    async (dispatch) => {
        const inputTypeData = getInputTypeData(field, locale as Language);

        // set all the field properties in redux form
        for (const key of Object.keys(inputTypeData)) {
            if (key !== 'name') {
                await dispatch(
                    change(inputBlockId, `attributes.content.${key}`, inputTypeData[key]),
                );
            }
        }

        // set conversion trigger true or false
        await dispatch(
            change(
                inputBlockId,
                'attributes.content.conversionTrigger',
                CONVERSION_INPUTS.indexOf(field) > -1,
            ),
        );

        if (UNIQUE_INPUTS.includes(field)) {
            // unique fields get their unique fieldName
            await dispatch(
                change(inputBlockId, 'attributes.content.fieldName', fieldNameMap[field]),
            );
        } else {
            // non-unique fields can have a custom fieldName but default to their componentId
            await dispatch(
                change(
                    inputBlockId,
                    'attributes.content.fieldName',
                    fieldNameMap[field] ? `${fieldNameMap[field]}-${inputBlockId}` : inputBlockId,
                ),
            );
        }

        // Ask for Tracking ID if not already in `NewInputModal` -> component is saved via the modal
        if (field === 'custom') {
            dispatch(
                showModal(Modals.INPUT_TRACKING_NAME, {
                    blockId: inputBlockId,

                    // update redux form as well
                    submitCallback: async (trackingName: string) => {
                        await dispatch(
                            change(inputBlockId, 'attributes.content.fieldName', trackingName),
                        );
                        dispatch(submit(inputBlockId));
                    },
                }),
            );
        }

        // triggers update block
        dispatch(submit(inputBlockId));
    };

export default inputTypesSlice.reducer;
