import type { DndContextProps } from '@dnd-kit/core';
import type { MutableRefObject } from 'react';

/**
 * Returns a function that handles the start of a drag operation.
 */
export const getOnDragStart = ({
    setDraggedNodeId,
    isDraggingRef,
}: {
    /**
     * A function for setting the ID of the node that is being dragged.
     */
    setDraggedNodeId: (nodeId: string) => void;
    /**
     * A reference to a boolean. The value is `true` if there is currently a drag operation in progress.
     */
    isDraggingRef: MutableRefObject<boolean>;
}) => {
    return (({ active }) => {
        isDraggingRef.current = true;

        setDraggedNodeId(active.id.toString());
    }) satisfies DndContextProps['onDragStart'];
};
