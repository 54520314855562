import dynamic from 'next/dynamic';
import { reduxForm } from 'redux-form';

import { getBlockComponentType, getMediaFormType } from '@/app/editor/blocks/helpers';

import type { DefaultEditFormProps, MediaSrcType } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const PictureForm = dynamic(() => import('@/app/editor/blocks/components/Media/forms/PictureForm'));
const VideoForm = dynamic(() => import('@/app/editor/blocks/components/Media/forms/VideoForm'));
const EmojiForm = dynamic(() => import('@/app/editor/blocks/components/Media/forms/EmojiForm'));
const ListItemForm = dynamic(
    () => import('@/app/editor/blocks/components/Media/forms/ListItemForm'),
);

const Form: FC<Props & InjectedFormProps> = ({
    form,
    initialValues,
    handleSubmit,
    activeTheme,
    parent,
    campaignId,
    pageId,
    blockId,
}) => {
    const srcType: MediaSrcType = initialValues?.attributes?.content?.srcType;
    const parentComponentType = getBlockComponentType(parent);

    const formType = getMediaFormType(srcType, parentComponentType);

    switch (formType) {
        case 'emoji':
            return <EmojiForm handleSubmit={handleSubmit} />;
        case 'video':
            return (
                <VideoForm
                    blockId={blockId}
                    form={form}
                    handleSubmit={handleSubmit}
                    activeTheme={activeTheme}
                />
            );
        case 'listItem':
            return (
                <ListItemForm
                    form={form}
                    handleSubmit={handleSubmit}
                    initialValues={initialValues}
                />
            );
        default:
            return (
                <PictureForm
                    handleSubmit={handleSubmit}
                    activeTheme={activeTheme}
                    form={form}
                    initialValues={initialValues}
                    campaignId={campaignId}
                    pageId={pageId}
                    blockId={blockId}
                />
            );
    }
};

Form.displayName = 'MediaEditForm';

export default reduxForm({})(Form);
