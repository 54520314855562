import { HelpIcon } from '@/app/helpTooltips/components/HelpIcon';

export interface Props {
    title: string;
    editText: string;
    onButtonClick: () => void;
    helpTooltip: {
        contentId: string;
        slug: string;
        onClickOverride?: (contentId: string, slug: string) => void;
    };
}

const ButtonWithTooltip = ({ title, editText, onButtonClick, helpTooltip }: Props) => {
    return (
        <div className="rounded-lg bg-gray-100 p-4" onClick={onButtonClick}>
            <div className="flex items-center">
                <span className="text-sm text-gray-600">{title}</span>
                <HelpIcon className="ml-2" {...helpTooltip} />
            </div>
            <p className="mt-4 cursor-pointer text-sm text-blue-500">{editText}</p>
        </div>
    );
};

export default ButtonWithTooltip;
