/**
 * Utility function that simulates network delay based on the base percentile
 * and network health.
 */
export const simulateNetworkDelay = ({
    basePercentile,
    networkHealth,
}: {
    /**
     * The base percentile of the network delay.
     */
    basePercentile: number;
    /**
     * The health of the network, limited to a range of 0 to 1.
     *
     * 0 is the worst health, and 1 is the best health.
     */
    networkHealth: number;
}) => {
    let fluctuation = (1 - networkHealth) * 10;
    let randomFluctuation =
        Math.random() * fluctuation * basePercentile - (fluctuation * basePercentile) / 2;
    let delay = basePercentile + randomFluctuation;
    delay = Math.max(0, delay);

    return delay;
};
