import Text from '@/app/editor/blocks/components/Text/Component';
import { getThemeColor } from '@/app/editor/themes/helpers';

import type { ThemeResource } from '@/app/editor/themes/types';
import type { RawDraftContentState } from 'draft-js';

export interface Props {
    blockId: string;
    wysiwyg: RawDraftContentState;
    activeTheme?: ThemeResource;
}

const Checkbox = ({ blockId, wysiwyg, activeTheme }: Props) => {
    return (
        <div className="flex items-center py-2">
            <div className="border-1 size-5 flex-shrink-0 rounded border bg-white" />
            <div className="pl-3 font-light text-gray-400">
                <Text
                    wysiwyg={wysiwyg}
                    align="left"
                    blockId={blockId}
                    globalSize="checkbox"
                    draftDataPath="misc.wysiwyg"
                    color={activeTheme ? getThemeColor(activeTheme, 'fontColor') : '#0F0F0F'}
                    linkColor={activeTheme ? getThemeColor(activeTheme, 'fontColor') : '#0F0F0F'}
                />
            </div>
        </div>
    );
};

export default Checkbox;
