import { reset as addFaviconReset } from './add';
import { reset as deleteFaviconReset } from './delete';
import { reset as fetchFaviconReset } from './fetch';
import { reset as updateFaviconReset } from './update';

import type { AppThunk } from '@/core/redux/types';

const reset = (): AppThunk => (dispatch) => {
    dispatch(addFaviconReset());
    dispatch(deleteFaviconReset());
    dispatch(fetchFaviconReset());
    dispatch(updateFaviconReset());
};

export default reset;
