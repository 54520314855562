// https://segment.com/docs/spec/identify/#traits
const reservedTraits = {
    addressCity: 'address.city', // string
    addressCountry: 'address.country', // string
    addressPostalCode: 'address.postalCode', // string
    addressStreet: 'address.street', // string
    addressState: 'address.state', // string
    age: 'age', // number
    avatar: 'avatar', // string
    birthday: 'birthday', // string
    companyName: 'company.name', // string
    companyIndustry: 'company.industry', // string
    companyEmployeeCount: 'company.employee_count', // number
    companyPlan: 'company.plan', // string
    description: 'description', // string
    email: 'email', // string
    phone: 'phone', // string
    firstName: 'firstName', // string
    gender: 'gender', // string
    lastName: 'lastName', // string
    name: 'name', // string
    title: 'title', // string
    username: 'username', // string
    website: 'website', // string
};

export const thirdPartyMap = {
    city: reservedTraits.addressCity,
    country: reservedTraits.addressCountry,
    zip: reservedTraits.addressPostalCode,
    street: reservedTraits.addressStreet,
    email: reservedTraits.email,
    phone: reservedTraits.phone,
    birthday: reservedTraits.birthday,
    name: reservedTraits.name,
    firstName: reservedTraits.firstName,
    lastName: reservedTraits.lastName,
    website: reservedTraits.website,
};

export const fieldNameMap = {
    ...thirdPartyMap,

    // component ID as default (optionally with prefix)
    message: 'message',
    custom: 'custom',
    consent: 'consent',
    file: 'file',
    datePicker: 'date',
};
