import { setUser } from '@sentry/nextjs';

import { infoLog } from '@/utils/debugLogs';
import { isProductionEnv } from '@/utils/environments';

import type { CompanyResource } from '@/app/company/types';
import type { UserResource } from '@/app/user/types';

export const identifySentryUser = (user: UserResource, company: CompanyResource) => {
    if (!user || !company) {
        if (!isProductionEnv()) {
            infoLog('Sentry: No user or company found to identify');
        }

        return;
    }

    if (isProductionEnv()) {
        setUser({
            id: user.id,
            username: user.attributes.fullName,
            email: user.attributes.email,

            // Add your own custom user variables here, ie:
            subscriptionId: company.attributes.chargebee.subscriptionId,
            planId: company.attributes.chargebee.planId,
            customerId: company.attributes.chargebee.customerId,
        });
    } else {
        infoLog('Sentry: Not identifying user in non-production environment');
    }
};
