import {
    type EditorEngineDefaultTypeInput,
    EditorEngineStepName,
} from '@/app/editor/engine/core/types';

import type {
    EditorEngineParentId,
    EditorEngineTransactionStepCreator,
} from '@/app/editor/engine/core/types';

interface ReinsertNodeAtIndexStepInput {
    /**
     * The ID of the node to reinsert.
     */
    id: string;
    /**
     * The ID of the new parent node to insert the node into.
     * If not provided, the parent will not be changed.
     */
    newParentId?: EditorEngineParentId;
    /**
     * The new index at which to insert the node.
     */
    newIndex: number;
}

/**
 * A transaction step that reinserts a node at a specific index in a parent
 * node, or in the same parent node if the new parent ID is not provided.
 */
export const reinsertNodeAtIndexStep = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    id,
    newParentId,
    newIndex,
}: ReinsertNodeAtIndexStepInput) => {
    return (({ nodeManager }) => {
        let oldNodeIndex: number;
        let oldParentId: EditorEngineParentId;

        return {
            name: EditorEngineStepName.ReinsertNodeAtIndex,
            debug: {
                id,
                newIndex,
            },
            affectedNodes: [id],
            apply() {
                oldNodeIndex = nodeManager.getNodeIndex(id);
                oldParentId = nodeManager.getParentId(nodeManager.getNode(id));

                nodeManager.reinsertAtIndex({
                    id,
                    newParentId,
                    newIndex,
                });

                return {
                    success: true,
                };
            },
            revert() {
                nodeManager.reinsertAtIndex({
                    id,
                    newParentId: oldParentId,
                    newIndex: oldNodeIndex,
                });

                return {
                    success: true,
                };
            },
        };
    }) satisfies EditorEngineTransactionStepCreator<TEditorEngineTypeInput>;
};
