import { reset as resetIcons } from './icons';
import { reset as resetPlatforms } from './platforms';

import type { AppThunk } from '@/core/redux/types';

export const resetIconLibraryState = (): AppThunk => (dispatch) => {
    dispatch(resetIcons());
    dispatch(resetPlatforms());
};

export default resetIconLibraryState;
