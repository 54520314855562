import { COLORED_PLATFORMS } from '@/app/editor/iconLibrary/constants';

import Image from 'next/image';
import { useMemo } from 'react';

import IconFallback from '@/app/editor/blocks/components/_helper/IconFallback';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { getDownloading } from '@/app/editor/iconLibrary/models/icons';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';
import LoadingIndicator from '@/ui/components/LoadingIndicator';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps, IconSize } from '../../types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    color?: string;
    iconName?: string;
    platform?: string;
    icon?: string;
    size: IconSize;
}

export const sizeMap = {
    small: 32,
    regular: 77,
    large: 155,
    title2: 278,
};

export const sizeMapTablet = {
    small: 48,
    regular: 120,
    large: 240,
    title2: 432,
};

export const getIconWidth = ({
    artBoardSize,
    isInMultiColumnLayout,
    isInMediaAnswer,
    size,
}: {
    artBoardSize: ArtBoardSize;
    isInMultiColumnLayout: boolean;
    isInMediaAnswer: boolean;
    size: IconSize;
}) => {
    if (isInMultiColumnLayout && !isInMediaAnswer) {
        return sizeMapTablet['title2'];
    }

    return artBoardSize === ArtBoardSize.TABLET ? sizeMapTablet[size] : sizeMap[size];
};

const Component: FC<Props> = ({
    artBoardIndex,
    blockId,
    box,
    color,
    iconName,
    platform,
    size = 'small',
    activeTheme,
    icon,
    isDragged,
    isPreview,
}) => {
    const { artBoardSize, isInMultiColumnLayout, isInColumn, isInMediaAnswer } = useLayoutInfo(
        blockId,
        isPreview,
    );
    const downloadingBlockId = useAppSelector(getDownloading);
    const themedColor = getThemedOrCustomColor(color, 'fontColor', activeTheme);
    const trimmedColor = themedColor.replace('#', '');

    const isDownloading = blockId === downloadingBlockId;
    const isStandaloneInMultiColumn = isInMultiColumnLayout && !isInMediaAnswer;

    const imgAttributes = useMemo(() => {
        const dimensions = getIconWidth({
            artBoardSize,
            isInMediaAnswer,
            size,
            isInMultiColumnLayout,
        });
        const isColor = COLORED_PLATFORMS.includes(platform);

        return {
            src: `https://img.icons8.com/${isColor ? '' : `${trimmedColor}/`}${platform}/${
                dimensions * 2
            }/${iconName}`,
            width: dimensions,
            height: dimensions,
        };
    }, [
        iconName,
        platform,
        size,
        trimmedColor,
        artBoardSize,
        isInMultiColumnLayout,
        isInMediaAnswer,
    ]);

    return (
        <Box
            box={getBoxAttributes({ box, isInColumn, isInMediaAnswer })}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            <div
                className={cn('relative flex items-center justify-center', {
                    'mx-auto max-w-[432px]': isStandaloneInMultiColumn,
                    'w-1/4': isStandaloneInMultiColumn && size === 'small',
                    'w-1/2': isStandaloneInMultiColumn && size === 'regular',
                    'w-3/4': isStandaloneInMultiColumn && size === 'large',
                    'w-full': isStandaloneInMultiColumn && size === 'title2',
                })}
            >
                {iconName && platform ? (
                    <Image
                        {...imgAttributes}
                        alt={iconName}
                        className={cn('transition-all', {
                            'animate-pulse': isDownloading,
                            'w-full': isStandaloneInMultiColumn,
                        })}
                    />
                ) : (
                    <IconFallback iconName={icon} />
                )}
            </div>

            <LoadingIndicator visible={isDownloading} className="absolute bottom-4 right-4" />
        </Box>
    );
};

Component.displayName = 'Icon';

export default Component;
