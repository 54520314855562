import { cn } from '@/utils/cn';

export interface Props {
    className?: string;
}

const StatusIcon = ({ className }: Props) => {
    return (
        <div className={cn('block p-px', className)}>
            <svg
                width="100%"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 0C5.44772 0 5 0.447715 5 1V2C5 2.55228 5.44772 3 6 3C6.55228 3 7 2.55228 7 2V1C7 0.447715 6.55228 0 6 0ZM6 9C5.44772 9 5 9.44771 5 10V11C5 11.5523 5.44772 12 6 12C6.55228 12 7 11.5523 7 11V10C7 9.44772 6.55228 9 6 9ZM2 5C2.55228 5 3 5.44772 3 6C3 6.55229 2.55228 7 2 7H1C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H2ZM12 6C12 5.44772 11.5523 5 11 5H10C9.44772 5 9 5.44772 9 6C9 6.55228 9.44771 7 10 7H11C11.5523 7 12 6.55229 12 6ZM3.87932 2.46428C4.26984 2.8548 4.26984 3.48797 3.87932 3.87849C3.48879 4.26902 2.85563 4.26902 2.46511 3.87849L1.75781 3.1712C1.36728 2.78067 1.36728 2.14751 1.75781 1.75698C2.14833 1.36646 2.7815 1.36646 3.17202 1.75698L3.87932 2.46428ZM10.2431 10.2423C10.6336 9.85174 10.6336 9.21857 10.2431 8.82805L9.53523 8.12019C9.14471 7.72967 8.51154 7.72967 8.12102 8.12019C7.73049 8.51072 7.73049 9.14388 8.12102 9.53441L8.82887 10.2423C9.2194 10.6328 9.85256 10.6328 10.2431 10.2423ZM9.53181 3.88225C9.14129 4.27277 8.50812 4.27277 8.1176 3.88225C7.72708 3.49172 7.72708 2.85856 8.1176 2.46804L8.82234 1.7633C9.21286 1.37277 9.84603 1.37277 10.2366 1.76329C10.6271 2.15382 10.6271 2.78698 10.2366 3.17751L9.53181 3.88225ZM1.75774 10.2421C2.14826 10.6326 2.78143 10.6326 3.17195 10.2421L3.88225 9.53181C4.27277 9.14129 4.27277 8.50812 3.88225 8.1176C3.49172 7.72708 2.85856 7.72708 2.46804 8.1176L1.75774 8.8279C1.36721 9.21842 1.36721 9.85159 1.75774 10.2421Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

export default StatusIcon;
