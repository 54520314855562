import { UserRole } from '@/app/user/types';

export const BROWSER_STORAGE_VALUE_TRUE = 'TRUE';

export const REGEX = {
    DOMAIN_LOWERCASE: /^[a-z0-9.-]+$/,
    SUB_DOMAIN_LOWERCASE: /^[a-z0-9-]+$/,
};

export const enum CapabilitiesTarget {
    Campaign = 'campaign',
    Workspaces = 'workspaces',
    Contacts = 'contacts',
    Invites = 'invites',
    Members = 'members',
    Billing = 'billing',
    Notifications = 'notifications',
    Domains = 'domains',
    // After adding a new capability target, make sure you update the roles accordingly (by default all permissions for the new capability target are set to false)
}

type RoleCapability = {
    [key in CapabilitiesTarget]?: {
        canRead?: boolean;
        canCreate?: boolean;
        canUpdate?: boolean;
        canDelete?: boolean;
    };
};

const CRM_ROLE_CAPABILITIES: RoleCapability = {
    // Full access to CRM related features
    [CapabilitiesTarget.Contacts]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // Can only read Workspaces
    [CapabilitiesTarget.Workspaces]: {
        canRead: true,
    },
    // Can only read Funnels
    [CapabilitiesTarget.Campaign]: {
        canRead: true,
    },
};

const EDITOR_ROLE_CAPABILITIES: RoleCapability = {
    // Can read Workspaces
    [CapabilitiesTarget.Workspaces]: {
        canRead: true,
    },
    // All access to Funnels
    [CapabilitiesTarget.Campaign]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // Read, Create and Delete access to Invites
    [CapabilitiesTarget.Invites]: {
        canRead: true,
        canCreate: true,
    },
    // Read access to Members
    [CapabilitiesTarget.Members]: {
        canRead: true,
    },
};

const WORKSPACE_ADMIN_ROLE_CAPABILITIES: RoleCapability = {
    // All access to CRM related features
    [CapabilitiesTarget.Contacts]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // Cannot create workspaces
    [CapabilitiesTarget.Workspaces]: {
        canRead: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Funnels
    [CapabilitiesTarget.Campaign]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // Read, Create and Delete access to Invites
    [CapabilitiesTarget.Invites]: {
        canRead: true,
        canCreate: true,
        canDelete: true,
    },
    // Read and Delete access to Members
    [CapabilitiesTarget.Members]: {
        canRead: true,
        canDelete: true,
    },
};

const ADMIN_ROLE_CAPABILITIES: RoleCapability = {
    // All access to CRM related features
    [CapabilitiesTarget.Contacts]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Workspaces
    [CapabilitiesTarget.Workspaces]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Funnels
    [CapabilitiesTarget.Campaign]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Invites
    [CapabilitiesTarget.Invites]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Members
    [CapabilitiesTarget.Members]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Billing
    [CapabilitiesTarget.Billing]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Domains
    [CapabilitiesTarget.Domains]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
    // All access to Notifications
    [CapabilitiesTarget.Notifications]: {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
    },
};

export const RoleCapabilities = {
    [UserRole.crm]: CRM_ROLE_CAPABILITIES,
    [UserRole.editor]: EDITOR_ROLE_CAPABILITIES,
    [UserRole.workspaceAdmin]: WORKSPACE_ADMIN_ROLE_CAPABILITIES,
    [UserRole.admin]: ADMIN_ROLE_CAPABILITIES,
    [UserRole.schnitzel]: ADMIN_ROLE_CAPABILITIES,
};
