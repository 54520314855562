export const getHasAnimation = (
    logoCount: number,
    isMobile: boolean,
    columnCount: number,
): boolean => {
    return (
        (isMobile && logoCount > 2) ||
        (columnCount === 4 && logoCount > 2) ||
        (columnCount === 3 && logoCount > 3) ||
        (columnCount === 2 && logoCount > 4) ||
        (columnCount === 1 && logoCount > 5) ||
        (columnCount === 0 && logoCount > 5)
    );
};
