import { createSelector, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { i18n } from 'next-i18next';

import { fetchCampaignAnalytics } from '@/app/campaigns/models/analytics';
import { handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { NAME, ANALYTICS_KEYS } from '../constants';
import { mapPageToChartSchema, mapVariantPageToChartSchema } from '../helper';

import type { ChartPageWithSummary } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    initialized: boolean;
    chartPages: {
        [id: string]: ChartPageWithSummary[];
    };
}

const initialState: State = {
    initialized: false,
    chartPages: EMPTY_OBJECT,
};

export const dropOffChartSlice = createSlice({
    name: `${NAME}/dropOffChart`,
    initialState,
    reducers: {
        setInitialized(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                initialized: action.payload,
            };
        },
        setChartPagesForId(
            state,
            action: PayloadAction<{ campaignId: string; pages: ChartPageWithSummary[] }>,
        ) {
            return {
                ...state,
                chartPages: {
                    ...state.chartPages,
                    [action.payload.campaignId]: action.payload.pages,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setInitialized, setChartPagesForId, reset } = dropOffChartSlice.actions;

// === Selectors ======

export const getInitialized = (state: AppState) => state[NAME]?.dropOffChartReducer?.initialized;

const getChartPages = (state: AppState) =>
    state[NAME]?.dropOffChartReducer?.chartPages || EMPTY_OBJECT;

export const getChartPagesForId = (campaignId: string) =>
    createSelector(getChartPages, (chartPages): ChartPageWithSummary[] => {
        return get(chartPages, campaignId, EMPTY_ARRAY);
    });

// === Thunks ======

export const fetchChartPages =
    (campaignId: string): AppThunk =>
    async (dispatch) => {
        const t = i18n?.getFixedT(null, NAME);

        try {
            const response = await dispatch(
                fetchCampaignAnalytics({
                    analyticsKey: ANALYTICS_KEYS.pages,
                    campaignId,
                }),
            );

            if (!response) {
                return;
            }

            const { attributes: analyticsPages } = getDataFromResponse(response, EMPTY_OBJECT);

            const pages = analyticsPages?.pages?.length
                ? analyticsPages.pages.map(mapPageToChartSchema)
                : EMPTY_ARRAY;

            if (analyticsPages?.hasResults) {
                pages.push({
                    label: t('result'),
                    value: analyticsPages?.result?.visitors,
                    summary: analyticsPages?.result?.pages?.map(mapPageToChartSchema),
                });
            }

            if (analyticsPages?.hasVariants) {
                pages[0] = {
                    label: t('variants'),
                    value: analyticsPages?.variants?.visitors,
                    summary: analyticsPages?.variants?.pages?.map(mapVariantPageToChartSchema),
                };
            }

            dispatch(setChartPagesForId({ campaignId, pages }));
            dispatch(setInitialized(true));
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed fetching Chart Pages' });
        }
    };

export default dropOffChartSlice.reducer;
