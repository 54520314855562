import dynamic from 'next/dynamic';
import { memo, useMemo } from 'react';

import Box from '@/app/editor/blocks/components/_wrapper/Box';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { cn } from '@/utils/cn';

import { getListItemVisualSizeProps } from './helper';
import Emoji from './medias/Emoji';
import Icon from './medias/Icon';
import Picture from './medias/Picture';

import type { DefaultBlockComponentProps, MediaSrcType, Size, VideoType } from '../../types';
import type { FC } from 'react';

const Video = dynamic(() => import('./medias/Video'));

export interface Props extends DefaultBlockComponentProps {
    srcType: MediaSrcType;
    emoji: string;
    src: string;
    size: Size;
    framed: boolean;
    color?: string;
    platform?: string;
    iconName?: string;
    icon?: string; // legacy icons (Material font)
    // Video
    video?: string;
    thumbnail?: string;
    videoType?: VideoType;
}

const Component: FC<Props> = ({
    artBoardIndex,
    blockId,
    box,
    srcType,
    src,
    video,
    size,
    framed,
    emoji,
    wrapperClass,
    borderRadius,
    visualSize,
    align,
    color,
    platform,
    iconName,
    icon,
    activeTheme,
    isDragged,
    isActive,
    themedBackgroundColor,
    formData,
    isPreview,
    thumbnail,
    videoType,
}) => {
    const { isInColumn } = useLayoutInfo(blockId, isPreview);
    const formDataBgColor = formData?.attributes?.content?.box?.backgroundColor;
    const formDataColor = formData?.attributes?.content?.color;

    const bgColor = getThemedOrCustomColor(
        formDataBgColor || box.backgroundColor,
        themedBackgroundColor ? themedBackgroundColor : 'backgroundColor',
        activeTheme,
    );

    const visualProps = useMemo(
        () =>
            visualSize && align
                ? getListItemVisualSizeProps({ visualSize, srcType, align })
                : undefined,
        [align, srcType, visualSize],
    );

    const emojiSize = useMemo(
        () => visualProps?.size && visualProps?.size - visualProps?.emojiPadding * 2,
        [visualProps?.emojiPadding, visualProps?.size],
    );

    const getMediaComponent = (srcType: MediaSrcType) => {
        switch (srcType) {
            case 'icon':
                return (
                    <Box
                        box={{ ...box, backgroundColor: bgColor }}
                        isDragged={isDragged}
                        artBoardIndex={artBoardIndex}
                    >
                        <Icon
                            blockId={blockId}
                            color={formDataColor || color}
                            icon={icon}
                            iconName={iconName}
                            platform={platform}
                            activeTheme={activeTheme}
                            bgColor={bgColor}
                        />
                    </Box>
                );
            case 'emoji':
                return (
                    <Emoji
                        emoji={emoji}
                        size={emojiSize || 32}
                        padding={visualProps?.emojiPadding || 8}
                    />
                );
            case 'video':
                return (
                    <Video
                        url={video}
                        thumbnail={thumbnail}
                        videoType={videoType}
                        blockId={blockId}
                        box={box}
                        isDragged={isDragged}
                        isActive={isActive}
                        isInColumn={isInColumn}
                    />
                );
            default:
                return (
                    <Picture
                        blockId={blockId}
                        src={src}
                        size={size}
                        borderRadius={borderRadius}
                        framed={framed}
                        isDragged={isDragged}
                        isActive={isActive}
                        box={box}
                        artBoardIndex={artBoardIndex}
                        themedBackgroundColor={themedBackgroundColor}
                        isInColumn={isInColumn}
                    />
                );
        }
    };

    return <div className={cn(wrapperClass, 'text-zero')}>{getMediaComponent(srcType)}</div>;
};

Component.displayName = 'Media';

export default memo(Component);
