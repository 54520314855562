import ButtonLoadingSpinner from '@/ui/components/ButtonLoadingSpinner';
import { cn } from '@/utils/cn';

export interface Props {
    visible: boolean;
    className?: string;
}

const LoadingIndicator = ({ visible, className }: Props) => {
    return (
        <div
            className={cn(
                className,
                'flex size-10 items-center justify-center rounded-lg bg-gray-800/50 transition-opacity',
                {
                    'opacity-100 delay-150': visible,
                    'opacity-0': !visible,
                },
            )}
        >
            <ButtonLoadingSpinner className="ml-0 text-white" />
        </div>
    );
};

export default LoadingIndicator;
