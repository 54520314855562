export interface CommandResult {
    success: boolean;
    canUndo?: boolean;
    canRedo?: boolean;
}

export interface Command {
    execute(): Promise<CommandResult>;
    undo(): Promise<CommandResult>;
    redo(): Promise<CommandResult>;
}

export const enum HistoryAction {
    Execute,
    Undo,
    Redo,
}

export interface HistoryEntry {
    id: string;
    action: HistoryAction;
    command: Command;
}
