export const Embed = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 12C20 12.2607 19.9008 12.5096 19.7261 12.687L15.7261 16.7495C15.3792 17.1018 14.8369 17.0798 14.5147 16.7004C14.1926 16.321 14.2127 15.7278 14.5596 15.3755L17.8832 12L14.5596 8.6245C14.2127 8.27218 14.1926 7.679 14.5147 7.29958C14.8369 6.92016 15.3792 6.89819 15.7261 7.25051L19.7261 11.313C19.9008 11.4904 20 11.7393 20 12ZM9.48525 7.29958C9.80737 7.679 9.78728 8.27218 9.44039 8.6245L6.11681 12L9.44039 15.3755C9.78728 15.7278 9.80737 16.321 9.48525 16.7004C9.16313 17.0798 8.62079 17.1018 8.2739 16.7495L4.2739 12.687C4.09924 12.5096 4 12.2607 4 12C4 11.7393 4.09924 11.4904 4.2739 11.313L8.2739 7.25051C8.62079 6.89819 9.16314 6.92016 9.48525 7.29958Z"
                fill="white"
                stroke="white"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
