import type { Relationship, Resource, RelationshipArray } from '@/core/api/types';
import type { Language } from 'types/generic';

export const enum UserRole {
    schnitzel = 'schnitzel',
    admin = 'admin',
    crm = 'crm',
    editor = 'editor',
    workspaceAdmin = 'workspaceAdmin',
}

export interface UserAttributes {
    createdAt: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    isActive: boolean;
    language: Language;
    notifications: {
        campaignLaunch: boolean;
        newLead: boolean;
        weeklyReport: boolean;
    };
    role: UserRole;
    trackingId: string;
}

export interface UserRelationships {
    company: Relationship;
    workspaces: RelationshipArray;
}

export type UserResource = Resource<UserAttributes, UserRelationships>;

export type UnsubscribeStatus = 'confirm' | 'loading' | 'retrying' | 'success' | 'error';
