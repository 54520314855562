import { EditorEngineCriticalError } from '@/app/editor/engine/utils/error/EditorEngineCriticalError';

import type { EditorEngineError } from '@/app/editor/engine/core/types';

/**
 * Create an Editor Engine error object from different sources.
 */
export const makeEditorEngineError = ({
    error,
    ...rest
}:
    | { error: string; debug: Record<string, unknown> }
    | { error: Error; debug: Record<string, unknown> }
    | { error: EditorEngineCriticalError; debug?: Record<string, unknown> }) => {
    if (typeof error === 'string' && 'debug' in rest) {
        return {
            message: error,
            originalError: new Error(error),
            debug: rest.debug,
        } satisfies EditorEngineError;
    }

    if (error instanceof EditorEngineCriticalError) {
        return {
            message: error.message,
            originalError: error,
            debug: {
                ...error.debug,
                ...('debug' in rest ? rest.debug : {}),
            },
        } satisfies EditorEngineError;
    }

    if (error instanceof Error) {
        return {
            message: error.message,
            originalError: error,
            debug: rest?.debug ?? {},
        } satisfies EditorEngineError;
    }

    const message = 'An unknown error occurred.';

    return {
        message,
        originalError: new Error(message),
        debug: {},
    } satisfies EditorEngineError;
};
