import get from 'lodash/get';

import type { BlockResource, LineType } from '@/app/editor/blocks/types';

export const getLineStyle = (initialValues: BlockResource): LineType => {
    if (get(initialValues, 'attributes.content.hidden', false)) {
        return 'hidden';
    }

    if (get(initialValues, 'attributes.content.solid', false)) {
        return 'solid';
    }

    if (get(initialValues, 'attributes.content.dashed', false)) {
        return 'dashed';
    }

    return 'hidden';
};
