import Image from 'next/image';
import Link from 'next/link';
import qs from 'query-string';
import { forwardRef } from 'react';
import { useCallback } from 'react';

import { useAppSelector } from '@/core/redux/hooks';
import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import { WS_LOGO_MAX_RENDERED_SIZE } from '../../constants';
import { getIsPreview } from '../../models/workspaces';

import type { WorkspaceAttributes } from '@/app/workspaces/types';
import type { ForwardRefRenderFunction, HTMLAttributes } from 'react';

export interface Props extends WorkspaceAttributes, HTMLAttributes<HTMLElement> {
    id?: string;
    active?: boolean;
    disabled?: boolean;
    withHover?: boolean;
    linkToDomain?: boolean;
    showTooltip?: boolean;
    onClick?: () => void;
}

const WorkspaceButton: ForwardRefRenderFunction<HTMLDivElement, Props> = (
    {
        active,
        id,
        name,
        logo,
        disabled,
        perspectiveDomain,
        linkToDomain,
        withHover = true,
        showTooltip = true,
        onClick,
        ...otherProps
    },
    ref,
) => {
    const isPreview = useAppSelector(getIsPreview);
    const icon = logo ?? name?.slice(0, 1).toUpperCase();
    const workspaceUrl = linkToDomain ? `https://${perspectiveDomain}` : `/workspaces/${id}`;
    const finalUrl = isPreview ? `${workspaceUrl}?preview=true` : workspaceUrl;
    const logoWithParams = `${logo}?${qs.stringify({ w: WS_LOGO_MAX_RENDERED_SIZE, fit: 'clip', auto: 'format,compress' })}`;

    const wrapperClassName = cn(
        'flex size-10 shrink-0 items-center justify-center overflow-hidden rounded-md border border-gray-200 bg-white outline outline-[1.5px] outline-offset-1',
        {
            'outline-blue-600': active,
            'outline-transparent hover:outline-blue-600': !active && !disabled && withHover,
            'outline-transparent': !withHover,
            'pointer-events-none cursor-default outline-transparent': disabled,
        },
    );

    const Button = useCallback(
        ({ children, href, ...buttonProps }) => {
            if (disabled) {
                return <div {...buttonProps}>{children}</div>;
            }

            return (
                <Link {...buttonProps} href={href} onClick={onClick}>
                    {children}
                </Link>
            );
        },
        [disabled, onClick],
    );

    return (
        <Tooltip
            content={showTooltip ? name : null}
            placement="right"
            offsetValue={8}
            disabled={disabled || !withHover}
        >
            <div {...otherProps} ref={ref}>
                <Button
                    href={finalUrl}
                    className={wrapperClassName}
                    aria-label={`workspace-button-${name}`}
                >
                    {logo ? (
                        <Image
                            draggable={false}
                            src={logoWithParams}
                            alt={name}
                            width={40}
                            height={40}
                            aria-hidden
                        />
                    ) : (
                        <p className="flex size-full items-center justify-center text-base font-semibold text-gray-600">
                            {icon}
                        </p>
                    )}
                </Button>
            </div>
        </Tooltip>
    );
};

export default forwardRef(WorkspaceButton);
