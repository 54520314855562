import { NAME } from '@/app/crm/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { useTranslation } from 'next-i18next';

import { PropertyActionType } from '@/app/crm/types';
import { track } from '@/core/tracking';
import { cn } from '@/utils/cn';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';
import { turnOffAutoComplete } from '@/utils/inputFields';

import { InputSettings } from './InputSettings';
import { TextArea } from './TextArea';
import { TextInputIconButton } from './TextInputIconButton';
import { CopyButton } from '../CopyButton';

import type { PropertyFieldProps } from '@/app/crm/types';
import type { MouseEvent } from 'react';
import type { FieldRenderProps } from 'react-final-form';

export interface Props extends FieldRenderProps<string>, PropertyFieldProps {
    onSubmit: () => void;
}

export const TextInput = ({
    input,
    onSubmit,
    placeholder,
    tooltip,
    hasCopy = true,
    link,
    openLinkInNewTab,
    icon: Icon,
    onIconClick,
    meta: { error, submitting },
    autoFocus,
    fieldType,
    multiline,
    owner,
    inline,
}: Props) => {
    const { t } = useTranslation(NAME);
    const campaignId = getCampaignIdFromRouter();

    const handleBlur = () => {
        onSubmit();
    };

    const handleCopy = () => {
        // Track Copy click
        track(TRACKING_EVENTS.crm.profile.action, {
            campaign_id: campaignId,
            property_action_type: PropertyActionType.copy,
            crm_property_owner: owner,
            crm_property_data_type: fieldType,
        });
    };

    const handleIconClick = (event: MouseEvent<HTMLAnchorElement>) => {
        onIconClick?.(event);

        // Track Action click
        let actionType: PropertyActionType;

        if (link?.startsWith(PropertyActionType.mailto)) {
            actionType = PropertyActionType.mailto;
        } else if (link?.startsWith(PropertyActionType.tel)) {
            actionType = PropertyActionType.tel;
        } else {
            actionType = PropertyActionType.url;
        }

        track(TRACKING_EVENTS.crm.profile.action, {
            campaign_id: campaignId,
            property_action_type: actionType,
            crm_property_owner: owner,
            crm_property_data_type: fieldType,
        });
    };

    const commonProps = {
        ...input,
        autoFocus,
        disabled: submitting,
        // We do not display text input placeholder for table view
        placeholder: inline ? '' : placeholder,
        onBlur: handleBlur,
        ...turnOffAutoComplete(),
    };
    const showCopyIcon = Boolean(hasCopy && input.value);
    const showLinkIcon = Boolean(link || onIconClick);

    const className = cn(
        'w-full flex-1 cursor-text rounded-md border border-transparent bg-transparent p-2 outline-none transition-all focus:border-blue-500',
        {
            'focus:bg-gray-50 group-hover:bg-gray-100 group-hover:focus:bg-gray-50': !inline,
            'text-gray-700 focus:bg-gray-50 group-hover:bg-gray-200 group-hover:focus:bg-gray-50':
                inline,
            'mt-1.5': multiline && !inline,
        },
        multiline && !inline
            ? // Unwrapped Textarea pr styles
              {
                  'pr-[50px]': showCopyIcon && showLinkIcon,
                  'pr-7': showCopyIcon !== showLinkIcon,
              }
            : // Regular pr styles
              {
                  'focus:pr-[50px] group-hover:pr-[50px]': showCopyIcon && showLinkIcon,
                  'focus:pr-7 group-hover:pr-7': showCopyIcon !== showLinkIcon,
              },
    );

    const textField = multiline ? (
        <TextArea {...commonProps} onSubmit={onSubmit} inline={inline} className={className} />
    ) : (
        <input
            {...commonProps}
            className={cn('h-9', { truncate: inline }, className)}
            type="text"
        />
    );

    return (
        <>
            <div className="group relative w-full overflow-hidden text-sm">
                {textField}

                <InputSettings>
                    {showCopyIcon && <CopyButton value={input.value} onCopy={handleCopy} />}

                    {showLinkIcon && (
                        <TextInputIconButton
                            href={link}
                            onClick={handleIconClick}
                            icon={Icon}
                            title={tooltip}
                            openInNewTab={openLinkInNewTab}
                        />
                    )}
                </InputSettings>
            </div>

            {error && <p className="mt-2 text-xs text-red-500">{t(error)}</p>}
        </>
    );
};
