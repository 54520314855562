import { FONT_SIZES } from '@/app/editor/blocks/components/Text/constants';

import dynamic from 'next/dynamic';
import { useMemo } from 'react';

import { getBoxAttributes, getIsTipTapData } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import {
    getColorByLightness,
    getHasTransparentBg,
    getThemedOrCustomColor,
} from '@/app/editor/themes/helpers';

import DraftErrorBoundary from './DraftEditor/DraftErrorBoundary';
import Box, { defaultBox } from '../_wrapper/Box';

import type { Alignment, DefaultBlockComponentProps, GlobalTextSize } from '../../types';
import type { JSONContent } from '@tiptap/core';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

const DraftEditor = dynamic(() => import('./DraftEditor'), { ssr: false });
const TipTapBlock = dynamic(
    () => import('./TipTapBlock/TipTapBlock').then((mod) => mod.TipTapBlock),
    { ssr: false },
);

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
    globalSize?: GlobalTextSize;
    color: string;
    linkColor?: string;
    draftDataPath?: string;
    wysiwyg: RawDraftContentState | JSONContent;
}

const Component: FC<Props> = ({
    artBoardIndex,
    blockId,
    activeTheme,
    box = defaultBox,
    wysiwyg,
    wrapperClass,
    color,
    linkColor,
    align,
    fixedAlign,
    noMentions,
    themedBackgroundColor,
    isLightnessDependent,
    parentComponentType,
    draftDataPath,
    globalSize, // size for entire text block; not just paragraphs
    isDragged,
    isPreview,
}) => {
    const { isInMediaAnswer, isInColumn } = useLayoutInfo(blockId, isPreview);
    const hasTransparentBg = getHasTransparentBg(parentComponentType);

    const bgColor =
        (hasTransparentBg || isInColumn) && !isInMediaAnswer
            ? 'transparent'
            : getThemedOrCustomColor(
                  box.backgroundColor,
                  themedBackgroundColor ? themedBackgroundColor : 'backgroundColor',
                  activeTheme,
              );
    const textColor = getThemedOrCustomColor(color, 'fontColor', activeTheme);
    const lightnessDependantColor = getColorByLightness(color, bgColor);

    const isTipTap = getIsTipTapData(wysiwyg as JSONContent);

    // This is relevant for e.g. the checkbox component
    // and the corresponding font size that has the value "checkbox".
    const globalSizeCssValue = useMemo(() => {
        const fontSize = FONT_SIZES[globalSize];

        return fontSize ? `${fontSize.base.sizePx}px` : undefined;
    }, [globalSize]);

    return (
        <div className={wrapperClass}>
            <Box
                box={{
                    ...getBoxAttributes({ box, isInColumn, isInMediaAnswer }),
                    backgroundColor: bgColor,
                }}
                isDragged={isDragged}
                artBoardIndex={artBoardIndex}
            >
                <div
                    className="cursor-text transition-all"
                    style={
                        isTipTap
                            ? {
                                  textAlign: fixedAlign ?? align,
                                  fontSize: globalSizeCssValue,
                              }
                            : {
                                  color: isLightnessDependent ? lightnessDependantColor : textColor,
                                  textAlign: fixedAlign ?? align,
                                  fontSize: globalSizeCssValue,
                              }
                    }
                >
                    {isTipTap ? (
                        <TipTapBlock
                            wysiwyg={wysiwyg}
                            blockId={blockId}
                            // Only used when no color is set via TipTap
                            textColor={isLightnessDependent ? lightnessDependantColor : textColor}
                        />
                    ) : (
                        <DraftErrorBoundary>
                            <DraftEditor
                                wysiwyg={wysiwyg as RawDraftContentState}
                                draftDataPath={draftDataPath}
                                noMentions={noMentions}
                                textBlockId={blockId}
                                usesGlobalSize={!!globalSize}
                                linkColor={linkColor}
                            />
                        </DraftErrorBoundary>
                    )}
                </div>
            </Box>
        </div>
    );
};

Component.displayName = 'Text';

export default Component;
