import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { memo, useEffect } from 'react';

import { getBlockById, getBlockParentColumn } from '@/app/editor/blocks/models/blocks';
import { setDragInProgress } from '@/app/editor/editor/models/artboard';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import Block from '../Block';

import type { DefaultBlockComponentProps, PropsFromParent } from '../../../../blocks/types';

export interface Props extends DefaultBlockComponentProps {
    artBoardIndex?: number;
    propsFromParent?: PropsFromParent;
}

const SortableBlock = ({ artBoardIndex, blockId, nestedLevel, propsFromParent }: Props) => {
    const dispatch = useAppDispatch();
    const block = useAppSelector((state) => getBlockById(state, blockId));
    const parentColumn = useAppSelector((state) => getBlockParentColumn(state, blockId));

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: blockId,
        data: {
            block,
            parentColumn,
        },
    });

    useEffect(() => {
        dispatch(setDragInProgress(isDragging));
    }, [dispatch, isDragging]);

    const style = {
        opacity: isDragging ? 0.5 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <Block
            blockId={blockId}
            nestedLevel={nestedLevel}
            artBoardIndex={artBoardIndex}
            isDragPreview={isDragging}
            ref={setNodeRef}
            dragStyle={style}
            dragAttributes={{ ...attributes }}
            dragListeners={{ ...listeners }}
            propsFromParent={propsFromParent}
        />
    );
};

export default memo(SortableBlock);
