import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import formatISO from 'date-fns/formatISO';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { createAnalyticsUrl } from '@/app/analytics/helper';
import { getStartDate, getEndDate } from '@/app/analytics/models/dateRangePicker';
import { getCurrentVariant } from '@/app/analytics/models/variantAnalytics';
import { getActiveCampaign } from '@/app/campaigns/models/campaigns';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { getNow } from '@/utils/common';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';

import type { AnalyticsKey, QuestionAttributes } from '@/app/analytics/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { CancelTokenSource } from 'axios';

let cancelTokenSource: { [K in AnalyticsKey]?: CancelTokenSource } = {};

interface State {
    fetching: boolean;
    campaignAnalytics: {
        [id: string]: {
            [key in AnalyticsKey]?: QuestionAttributes[];
        };
    };
}

const initialState: State = {
    fetching: false,
    campaignAnalytics: EMPTY_OBJECT,
};

export const analyticsSlice = createSlice({
    name: `${NAME}/analytics`,
    initialState,
    reducers: {
        setFetching(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                fetching: action.payload,
            };
        },
        setCampaignAnalytics(
            state,
            action: PayloadAction<{
                campaignId: string;
                analyticsKey: string;
                data: QuestionAttributes[];
            }>,
        ) {
            return {
                ...state,
                campaignAnalytics: {
                    ...state.campaignAnalytics,
                    [action.payload.campaignId]: {
                        ...state.campaignAnalytics[action.payload.campaignId],
                        [action.payload.analyticsKey]: action.payload.data,
                    },
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setFetching, setCampaignAnalytics, reset } = analyticsSlice.actions;

// === Selectors ======

export const getFetching = (state: AppState) => state[NAME]?.analyticsReducer?.fetching;

export const getCampaignAnalytics = (
    state: AppState,
    campaignId: string,
    analyticsKey: AnalyticsKey,
) => get(state[NAME], `analyticsReducer.campaignAnalytics[${campaignId}][${analyticsKey}]`);

// === Thunks ======

export const fetchCampaignAnalytics =
    ({
        campaignId,
        analyticsKey,
    }: {
        campaignId: string;
        analyticsKey: AnalyticsKey;
    }): AppThunk<Promise<ResponseData>> =>
    async (dispatch, getState) => {
        const state = getState();
        const variantPage = getCurrentVariant(state);

        let startDate = getStartDate(state);
        let endDate = getEndDate(state);

        if (!startDate) {
            const campaign = getActiveCampaign(state);
            startDate = campaign?.attributes?.createdAt;
        }

        if (!endDate) {
            endDate = formatISO(getNow());
        }

        dispatch(setFetching(true));

        try {
            const url = createAnalyticsUrl({
                campaignId,
                analyticsKey,
                startDate,
                endDate,
                variantPage,
            });

            if (cancelTokenSource[analyticsKey]) {
                cancelTokenSource[analyticsKey].cancel('Cancelling previous request');
            }
            cancelTokenSource[analyticsKey] = axios.CancelToken.source();

            const response = await apiGet(url, {
                cancelToken: cancelTokenSource[analyticsKey].token,
            });

            cancelTokenSource[analyticsKey] = undefined;

            const campaignAnalytics = getCampaignAnalytics(state, campaignId, analyticsKey);
            const { attributes } = getDataFromResponse(response, EMPTY_OBJECT);
            const isSame = isEqual(campaignAnalytics, attributes);

            // No need to update state when data hasn't changed
            if (!isSame) {
                dispatch(setCampaignAnalytics({ campaignId, analyticsKey, data: attributes }));
            }

            return response;
        } catch (err) {
            if (!axios.isCancel(err)) {
                handleRuntimeError(err, { debugMessage: 'fetching campaign analytics failed:' });
            }
        } finally {
            dispatch(setFetching(false));
        }
    };

export default analyticsSlice.reducer;
