import { NAME, SIDEBAR_MIN_WIDTH, SIDEBAR_WIDTH_BREAKPOINT } from '@/app/crm/constants';

import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    sidebarWidth: number;
}

const initialState: State = {
    sidebarWidth: SIDEBAR_MIN_WIDTH['DESKTOP'],
};

const sidebarSlice = createSlice({
    name: `${NAME}/sidebar`,
    initialState,
    reducers: {
        setSidebarWidth(state, action: PayloadAction<number>) {
            state.sidebarWidth = action.payload;
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======
export const { setSidebarWidth, reset } = sidebarSlice.actions;

// === Selectors ======
export const getSidebarWidth = (state: AppState) => state[NAME].sidebarReducer.sidebarWidth;

export const getHasSmallSidebar = createSelector(
    getSidebarWidth,
    (sidebarWidth) => sidebarWidth < SIDEBAR_WIDTH_BREAKPOINT,
);

export default sidebarSlice.reducer;
