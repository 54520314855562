import { createSlice } from '@reduxjs/toolkit';

import { getCampaignWithPagination } from '@/app/campaigns/helpers';
import { getCampaignRequest } from '@/app/campaigns/models/campaigns';
import { CampaignFilter } from '@/app/campaigns/types';
import { getWorkspaces } from '@/app/workspaces/models/workspaces';
import { RequestState } from '@/app/workspaces/types';
import { handleRuntimeError } from '@/core/api';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@/utils/empty';

import { NAME } from '../constants';

import type { CampaignResource, CampaignsWithPagination } from '@/app/campaigns/types';
import type { Pagination } from '@/core/api/types';
import type { AppThunk, AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    filter: string[];
    search: string;
    appliedFilter: string;
    campaigns: CampaignResource[];
    pagination: Partial<Pagination>;
    requestState: Partial<Record<'funnels' | 'search', RequestState>>;
}

const initialState: State = {
    filter: EMPTY_ARRAY,
    search: EMPTY_STRING,
    appliedFilter: EMPTY_STRING,
    campaigns: EMPTY_ARRAY,
    pagination: EMPTY_OBJECT,
    requestState: {
        funnels: RequestState.Idle,
        search: RequestState.Idle,
    },
};

export const filterSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setFilter(state, action: PayloadAction<State['filter']>) {
            state.filter = action.payload;
        },
        setAppliedFilter(state, action: PayloadAction<State['appliedFilter']>) {
            state.appliedFilter = action.payload;
        },
        setSearch(state, action: PayloadAction<State['search']>) {
            state.search = action.payload;
        },
        setCampaigns(state, action: PayloadAction<State['campaigns']>) {
            state.campaigns = action.payload;
        },
        appendCampaigns(state, action: PayloadAction<State['campaigns']>) {
            state.campaigns.push(...action.payload);
        },
        setPagination(state, action: PayloadAction<State['pagination']>) {
            state.pagination = action.payload;
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setFilter,
    setAppliedFilter,
    setSearch,
    setCampaigns,
    appendCampaigns,
    setPagination,
    setRequestState,
    reset,
} = filterSlice.actions;

// === Selectors ======

export const getFilter = (state: AppState) => state[NAME]?.filterReducer?.filter;
export const getAppliedFilter = (state: AppState) => state[NAME]?.filterReducer?.appliedFilter;
export const getSearch = (state: AppState) => state[NAME]?.filterReducer?.search;
export const getCampaigns = (state: AppState) => state[NAME]?.filterReducer?.campaigns;
export const getPagination = (state: AppState) => state[NAME]?.filterReducer?.pagination;
export const getSearching = (state: AppState) =>
    state[NAME]?.filterReducer?.requestState.search === RequestState.InProgress;

// === Thunks ======

const fetchCampaignData =
    (nextPageUrl?: string): AppThunk<Promise<CampaignsWithPagination>> =>
    async (dispatch, getState) => {
        const state = getState();
        const search = getSearch(state);
        const workspaces = getWorkspaces(state);
        const workspaceIdsToSearch = Object.keys(workspaces)?.join(',');

        const response = await dispatch(
            getCampaignRequest({
                workspaceIds: workspaceIdsToSearch,
                queryOptions: {
                    search,
                    filter: CampaignFilter.all,
                },
                nextPageUrl,
            }),
        );

        if (!response) {
            return;
        }

        return getCampaignWithPagination(response);
    };

const fetchAndSetCampaigns =
    (nextUrl?: string, append?: boolean): AppThunk =>
    async (dispatch) => {
        const { pagination, campaigns } = await dispatch(fetchCampaignData(nextUrl));

        const setCampaignsAction = append ? appendCampaigns : setCampaigns;

        if (pagination) {
            dispatch(setPagination(pagination));
        }

        if (campaigns) {
            dispatch(setCampaignsAction(campaigns));
        }
    };

export const fetchCampaigns = (): AppThunk => async (dispatch, getState) => {
    const search = getSearch(getState());
    const requestTarget = search ? 'search' : 'funnels';

    dispatch(setRequestState({ [requestTarget]: RequestState.InProgress }));

    try {
        await dispatch(fetchAndSetCampaigns());

        dispatch(setRequestState({ [requestTarget]: RequestState.Done }));
    } catch (err) {
        dispatch(setRequestState({ [requestTarget]: RequestState.Error }));
        handleRuntimeError(err, { debugMessage: 'fetching campaigns failed:' });
    }
};

export const loadNextCampaignPage = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const nextPageUrl = getPagination(state)?.next;

    if (!nextPageUrl) {
        return;
    }

    dispatch(setRequestState({ funnels: RequestState.InProgress }));

    try {
        await dispatch(fetchAndSetCampaigns(nextPageUrl, true));

        dispatch(setRequestState({ funnels: RequestState.Done }));
    } catch (err) {
        dispatch(setRequestState({ funnels: RequestState.Error }));
        handleRuntimeError(err, { debugMessage: 'fetching next campaign page failed:' });
    }
};

export const toggleCampaignInFilter =
    (campaignId: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const filter = getFilter(state);
        const newFilter = filter ? [...filter] : EMPTY_ARRAY;

        if (newFilter.includes(campaignId)) {
            const index = newFilter.indexOf(campaignId);

            if (index > -1) {
                newFilter.splice(index, 1);
            }
        } else {
            newFilter.push(campaignId);
        }

        dispatch(setFilter(newFilter));
    };

export default filterSlice.reducer;
