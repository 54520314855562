import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { InstantSubDomainSetupSteps } from '../components/InstantSubDomainSetup/types';
import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    currentStep: InstantSubDomainSetupSteps;
    subDomain: string;
}

const initialState: State = {
    currentStep: InstantSubDomainSetupSteps.Intro,
    subDomain: '',
};

export const instantSubDomainSetupSlice = createSlice({
    name: `${NAME}/instantSubDomainSetup`,
    initialState,
    reducers: {
        nextStep(state) {
            state.currentStep++;
        },
        prevStep(state) {
            state.currentStep--;
        },
        resetSetup(state) {
            state.currentStep = initialState.currentStep;
            state.subDomain = initialState.subDomain;
        },
        setSubDomain(state, action: PayloadAction<string>) {
            state.subDomain = action.payload;
        },
    },
});

// === Actions ======

export const { nextStep, prevStep, resetSetup, setSubDomain } = instantSubDomainSetupSlice.actions;

// === Selectors ======

export const getCurrentInstantSubDomainSetupStep = (state: AppState) =>
    state[NAME].instantSubDomainSetupReducer.currentStep;

export const getSubDomain = (state: AppState) => state[NAME].instantSubDomainSetupReducer.subDomain;

// === Thunk ======

export const goBack = (): AppThunk => (dispatch, getState) => {
    const currentStep = getCurrentInstantSubDomainSetupStep(getState());

    if (currentStep !== InstantSubDomainSetupSteps.Intro) {
        dispatch(prevStep());

        return;
    }

    Router.back();
};

export default instantSubDomainSetupSlice.reducer;
