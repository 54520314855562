import { createSlice } from '@reduxjs/toolkit';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import isEqual from 'lodash/isEqual';
import { change } from 'redux-form';

import { fixRawState } from '@/app/editor/blocks/components/Text/DraftEditor/helper';
import { getBlockById } from '@/app/editor/blocks/models/blocks';
import { EMPTY_OBJECT } from '@/utils/empty';

import { UpdateBlockCommand } from '../../commands/commands/updateBlockCommand';
import getHistoryController from '../../commands/utils/HistoryControllers';
import { NAME } from '../constants';

import type { DraftEditorPlugins } from '@/app/editor/blocks/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RawDraftContentState } from 'draft-js';

interface State {
    draftEditorStates: {
        [textBlockId: string]: EditorState;
    };
    draftEditorPlugins: {
        [textBlockId: string]: DraftEditorPlugins;
    };
}

const initialState: State = {
    draftEditorStates: EMPTY_OBJECT,
    draftEditorPlugins: EMPTY_OBJECT,
};

export const draftEditorStatesSlice = createSlice({
    name: `editor/${NAME}/draftEditorStates`,
    initialState,
    reducers: {
        setDraftEditorState(
            state,
            action: PayloadAction<{ textBlockId: string; editorState: EditorState }>,
        ) {
            return {
                ...state,
                draftEditorStates: {
                    ...state.draftEditorStates,
                    [action.payload.textBlockId]: action.payload.editorState,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setDraftEditorState, reset } = draftEditorStatesSlice.actions;

// === Selectors ======

export const getEditorState = (state: AppState, textBlockId: string) =>
    state[NAME]?.draftEditorStatesReducer?.draftEditorStates[textBlockId];

// === Thunks ======

// init editorState in store for text block
export const initDraftEditorState =
    (textBlockId: string, wysiwyg: RawDraftContentState): AppThunk =>
    (dispatch) => {
        const editorState = EditorState.createWithContent(convertFromRaw(fixRawState(wysiwyg)));

        dispatch(setDraftEditorState({ textBlockId, editorState }));
    };

// Update editorState in redux and update Block (redux and DB)
export const saveDraftState =
    (textBlockId: string, editorState: EditorState, path = 'wysiwyg'): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const block = getBlockById(state, textBlockId);
        const content = editorState.getCurrentContent();
        const rawContent = convertToRaw(content);
        const historyController = getHistoryController();

        await dispatch(setDraftEditorState({ textBlockId, editorState }));

        // also update redux form data
        await dispatch(change(textBlockId, `attributes.content.${path}`, rawContent));

        const updatedBlock = {
            ...block,
            attributes: {
                ...block.attributes,
                content: {
                    ...block.attributes.content,
                    ...(path === 'wysiwyg' && {
                        wysiwyg: rawContent,
                    }),
                    ...(path === 'misc.wysiwyg' && {
                        misc: {
                            ...block.attributes.content.misc,
                            wysiwyg: rawContent,
                        },
                    }),
                },
            },
        };

        const isDraftStateEqual = isEqual(block, updatedBlock);

        if (!isDraftStateEqual) {
            const updateBlockCommand = new UpdateBlockCommand(updatedBlock);
            historyController.executeCommand(updateBlockCommand);
        }
    };

export default draftEditorStatesSlice.reducer;
