import Router from 'next/router';

export const getQueryFromRouter = (queryParam: string) => {
    const { query } = Router;

    if (query[queryParam]) {
        const queryName = query[queryParam];

        return Array.isArray(queryName) ? queryName[0] : queryName;
    }

    return;
};
