import { createSlice } from '@reduxjs/toolkit';

import { RequestState } from '@/app/workspaces/types';
import { crmApiGet, crmApiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import { NAME, DEFAULT_WORKSPACE_STATUS } from '../constants';
import { buildWorkspaceStatusRoute } from '../utils/url';

import type { CreateWorkspaceStatusData, Status } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

type WorkspaceStatusRequestState = Partial<Record<'fetchStatus', RequestState>>;

const DEFAULT_REQUEST_STATE: WorkspaceStatusRequestState = {
    fetchStatus: RequestState.Idle,
};

export interface State {
    requestState: WorkspaceStatusRequestState;
    workspaceStatus: Status[] | null;
}

const initialState: State = {
    requestState: DEFAULT_REQUEST_STATE,
    workspaceStatus: null,
};

const workspaceStatusSlice = createSlice({
    name: `${NAME}/workspace-status`,
    initialState,
    reducers: {
        setRequestState(state, action: PayloadAction<WorkspaceStatusRequestState>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        setWorkspaceStatus(state, action: PayloadAction<Status[]>) {
            return {
                ...state,
                workspaceStatus: action.payload,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// Actions
export const { setRequestState, setWorkspaceStatus, reset } = workspaceStatusSlice.actions;

// Selectors
export const getFetching = (state: AppState) =>
    state[NAME].workspaceStatusReducer.requestState.fetchStatus;
export const getWorkspaceStatus = (state: AppState) =>
    state[NAME].workspaceStatusReducer.workspaceStatus;

// Thunks
export const updateWorkspaceStatus =
    (workspaceStatus: CreateWorkspaceStatusData): AppThunk =>
    async (dispatch, getState) => {
        const { workspaceId, status } = workspaceStatus;
        const state = getState();
        const prevWorkspaceStatus = getWorkspaceStatus(state) ?? [];

        try {
            // Optimistically update state
            dispatch(setWorkspaceStatus(status));

            getDataFromResponse(
                await crmApiPost(buildWorkspaceStatusRoute.post(workspaceId), {
                    data: { status },
                }),
            );

            dispatch(
                setRequestState({
                    fetchStatus: RequestState.Success,
                }),
            );
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to update workspace status' });

            // Revert optimistic update
            dispatch(setWorkspaceStatus(prevWorkspaceStatus));

            dispatch(
                setRequestState({
                    fetchStatus: RequestState.Error,
                }),
            );
        }
    };

const createDefaultWorkspaceStatus =
    (workspaceId: string): AppThunk =>
    async (dispatch) => {
        dispatch(updateWorkspaceStatus({ status: DEFAULT_WORKSPACE_STATUS, workspaceId }));

        dispatch(
            setRequestState({
                fetchStatus: RequestState.Success,
            }),
        );
    };

export const fetchWorkspaceStatus =
    (workspaceId: string): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                fetchStatus: RequestState.InProgress,
            }),
        );

        try {
            const data = getDataFromResponse(
                await crmApiGet(buildWorkspaceStatusRoute.get(workspaceId)),
            );
            const workspaceStatus = data.attributes.status;

            dispatch(setWorkspaceStatus(workspaceStatus));

            dispatch(
                setRequestState({
                    fetchStatus: RequestState.Success,
                }),
            );
        } catch (error) {
            if (error.response?.status === 404) {
                // When there is no status, we create a default set of status
                dispatch(createDefaultWorkspaceStatus(workspaceId));
            } else {
                handleRuntimeError(error, { message: 'Failed to fetch workspace status' });
                dispatch(
                    setRequestState({
                        fetchStatus: RequestState.Error,
                    }),
                );
            }
        }
    };

export const workspaceStatusReducer = workspaceStatusSlice.reducer;
