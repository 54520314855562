import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getDownloading } from '@/app/editor/iconLibrary/models/icons';
import { useAppSelector } from '@/core/redux/hooks';
import LoadingIndicator from '@/ui/components/LoadingIndicator';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps } from '../../types';

export interface Props extends DefaultBlockComponentProps {}

const Component = ({ artBoardIndex, blockId, box, isDragged, isPreview }: Props) => {
    const { isInColumn } = useLayoutInfo(blockId, isPreview);
    const downloadingBlockId = useAppSelector(getDownloading);

    const isDownloading = blockId === downloadingBlockId;

    return (
        <Box
            box={getBoxAttributes({ box, isInColumn })}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            <div>This is a payment form</div>

            <LoadingIndicator visible={isDownloading} className="absolute bottom-4 right-4" />
        </Box>
    );
};

Component.displayName = 'PaymentForm';

export default Component;
