import { reset as resetAddPage } from './add';
import { reset as resetDeletePage } from './delete';
import { reset as resetDuplicateReset } from './duplicate';
import { reset as resetPages } from './pages';
import { reset as resetUpdatePage } from './update';
import { reset as resetVariantReset } from './variant';

import type { AppThunk } from '@/core/redux/types';

const reset =
    (withPages: boolean): AppThunk =>
    (dispatch) => {
        dispatch(resetPages(withPages));
        dispatch(resetAddPage());
        dispatch(resetDeletePage());
        dispatch(resetUpdatePage());
        dispatch(resetDuplicateReset());
        dispatch(resetVariantReset());
    };

export default reset;
