import { QueryClient } from '@tanstack/react-query';

export const PerspectiveQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: Infinity,
            staleTime: Infinity,
            refetchOnWindowFocus: false, // default: true
        },
    },
});
