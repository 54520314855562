import { WORKSPACE_DOMAIN_HOST } from '@/app/workspaces/constants';

import { getWorkspaceDomainFromUrl } from '@/app/workspaces/helpers/utils';

// Types
interface IsWorkspaceEnvOptions {
    genericHost?: boolean;
}

export const isBrowserEnv = () => typeof window !== 'undefined';

export const isProductionEnv = () => process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

export const isVercelDevelopmentEnv = () => process.env.NEXT_PUBLIC_VERCEL_ENV === 'development';

export const getIsWorkspaceEnv = (options?: IsWorkspaceEnvOptions) => {
    if (typeof location !== 'undefined') {
        const hostname = getWorkspaceDomainFromUrl();

        // Also match generic Workspace domain
        const genericHostMatch =
            !hostname?.startsWith(WORKSPACE_DOMAIN_HOST ?? '') || !!options?.genericHost;

        return hostname?.includes(WORKSPACE_DOMAIN_HOST ?? '') && genericHostMatch;
    }

    return false;
};

export const getIsLocalhostEnv = () => {
    return process.env.NODE_ENV === 'development';
};
