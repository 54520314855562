/**
 * Types related to drag and drop.
 */

import type { EditorEngineDefaultTypeInput } from '@/app/editor/engine/core/types/engine';
import type { EditorEngineHistory } from '@/app/editor/engine/core/types/history';
import type { EditorEngineNode, EditorEngineNodeData } from '@/app/editor/engine/core/types/node';
import type { UniqueIdentifier } from '@dnd-kit/core';
import type { useSortable } from '@dnd-kit/sortable';

/**
 * The position where a node will be dropped relative to the target node.
 */
export enum EditorEngineDropPosition {
    /**
     * The node will be dropped above the target node.
     */
    Top = 'Top',
    /**
     * The node will be dropped below the target node.
     */
    Bottom = 'Bottom',
    /**
     * The node will be dropped to the left of the target node.
     */
    Left = 'Left',
    /**
     * The node will be dropped to the right of the target node.
     */
    Right = 'Right',
    /**
     * Used when the targeted node is empty, and a node will be dropped inside.
     */
    Within = 'Within',
}

/**
 * The evaluation of a drop operation.
 */
export enum EditorEngineDropEvaluationType {
    Allowed = 'Allowed',
    Disallowed = 'Disallowed',
}

/**
 * An execution configuration is defined in the context that contains the
 * draggable configuration. It determines what will happen once the drop
 * operation is completed.
 */
export type EditorEngineDropExecutionConfiguration<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = {
    /**
     * This handler that will be executed when the drop operation is completed.
     */
    handler: (context: {
        /**
         * The state of the history of the current editor engine instance.
         */
        history: EditorEngineHistory;
        /**
         * The actions that this editor engine instance can execute.
         */
        actions: TEditorEngineTypeInput['Actions'];
    }) => void;
};

/**
 * This value is used when determining if a drop operation is allowed.
 */
export interface EditorEngineDropPreview<TNodeData extends EditorEngineNodeData> {
    /**
     * The node that is being dragged.
     */
    original: EditorEngineNode<TNodeData>;
    /**
     * The node that is being targeted.
     */
    target: EditorEngineNode<TNodeData>;
    /**
     * The position where the node will be dropped relative to the target node.
     */
    futurePosition: EditorEngineDropPosition;
}

/**
 * A drop candidate is a type that joins the execution configuration (what to do
 * when the drop operation is completed) and the preview (the nodes involved in
 * the operation and the drop position itself).
 */
export type EditorEngineDropCandidate<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> =
    {
        executionConfiguration: EditorEngineDropExecutionConfiguration<TEditorEngineTypeInput>;
        preview: EditorEngineDropPreview<TEditorEngineTypeInput['Data']>;
    };

/**
 * A message optionally shown when dragging a node.
 */
export interface EditorEngineDragMessage {
    mainLabel: string;
    subLabel?: string;
}

export interface EditorEngineDropPreviewOptions {
    /**
     * If the drop preview appears between elements, this should be a tailwind class that specifies the size of the
     * gap, in the format `size-N`.
     */
    gapWidth?: string;
}

/**
 * A draggable configuration will be used by the editor engine to determine if a
 * node can be dragged, if it can target another node, and what would happen if
 * the drop operation is completed.
 *
 * The configuration is defined as a context that can be extended or overridden
 * at any point in the node tree.
 */
export type EditorEngineDraggableConfiguration<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = {
    /**
     * Returns true if the node can initiate a drag operation.
     */
    canBeDragged: (input: {
        node: EditorEngineNode<TEditorEngineTypeInput['Data']>;
        documentManager: TEditorEngineTypeInput['DocumentManager'];
        nodeManager: TEditorEngineTypeInput['NodeManager'];
    }) => boolean;
    /**
     * Returns true if the node can target another node during a drag operation.
     */
    canTarget: (input: {
        documentManager: TEditorEngineTypeInput['DocumentManager'];
        nodeManager: TEditorEngineTypeInput['NodeManager'];
        preview: EditorEngineDropPreview<TEditorEngineTypeInput['Data']>;
    }) => boolean;
    /**
     * Returns true if the node can be targeted by another node during a drag operation.
     */
    canBeTargeted: (input: {
        documentManager: TEditorEngineTypeInput['DocumentManager'];
        nodeManager: TEditorEngineTypeInput['NodeManager'];
        preview: EditorEngineDropPreview<TEditorEngineTypeInput['Data']>;
    }) => boolean;
    /**
     * Execute the drop operation. This function is called when a node is
     * dropped on another node, only if `canTarget` and `canBeTargeted` have
     * both allowed the operation.
     */
    executeDrop: (input: {
        preview: EditorEngineDropPreview<TEditorEngineTypeInput['Data']>;
        documentManager: TEditorEngineTypeInput['DocumentManager'];
        nodeManager: TEditorEngineTypeInput['NodeManager'];
    }) => EditorEngineDropExecutionConfiguration<TEditorEngineTypeInput>;
    /**
     * Returns a message to be shown when dragging a node.
     */
    getMessageForOperation: (input: {
        preview: EditorEngineDropPreview<TEditorEngineTypeInput['Data']>;
        documentManager: TEditorEngineTypeInput['DocumentManager'];
        nodeManager: TEditorEngineTypeInput['NodeManager'];
    }) => false | EditorEngineDragMessage;
    /**
     * Returns the options for the drop preview.
     */
    getDropPreviewOptionsForOperation: (input: {
        preview: EditorEngineDropPreview<TEditorEngineTypeInput['Data']>;
        documentManager: TEditorEngineTypeInput['DocumentManager'];
        nodeManager: TEditorEngineTypeInput['NodeManager'];
    }) => EditorEngineDropPreviewOptions;
};

/**
 * The rendering state of a node in relation to the current drag and drop
 * information.
 *
 * Any node that is being rendered will have a drag state, even if they are not
 * currently being dragged.
 */
export enum EditorEngineNodeDragStateType {
    /**
     * This means that the editor engine is rendering this node in its original
     * location. Nodes that are not currently dragged will have this state.
     * When a drag operation starts, the node that stays in its original
     * location will also have this state.
     */
    Original = 'Original',
    /**
     * This means that the editor engine is rendering this node as an overlay
     * that follows the cursor. The node this refers to must be part of a drag
     * operation to have this state.
     *
     * When a node is being dragged, there will be two different renders of the
     * node. One will have this drag state, and the other will have the
     * `Original` state.
     */
    Overlay = 'Overlay',
    /**
     * This means that the node being rendered is currently the target of a drag
     * operation.
     */
    Target = 'Target',
    /**
     * No drag state is available for this node. This means a drag operation is
     * not possible for this node.
     */
    Unavailable = 'Unavailable',
}

/**
 * This info is available for both `Original` and `Overlay` drag states.
 */
export type EditorEngineCommonDragInfo = {
    /**
     * This node is being dragged.
     */
    isDragged: boolean;
    /**
     * A drag operation is in progress, but this might not be the node that is
     * being dragged.
     *
     * If `isDragged` is true, this will also be true.
     */
    isDragOperationInProgress: boolean;
};

export type EditorEngineNodeDragContext = Pick<
    ReturnType<typeof useSortable>,
    'attributes' | 'setNodeRef' | 'listeners' | 'active'
> & { activeId: UniqueIdentifier };

export type EditorEngineNodeDragInfo = EditorEngineCommonDragInfo &
    (
        | {
              type: EditorEngineNodeDragStateType.Original;
              context: EditorEngineNodeDragContext;
          }
        | {
              type: EditorEngineNodeDragStateType.Overlay;
          }
        | {
              type: EditorEngineNodeDragStateType.Target;
              position: EditorEngineDropPosition;
          }
        | {
              type: EditorEngineNodeDragStateType.Unavailable;
          }
    );

/**
 * A shortcut for the default draggable configuration that uses the default
 * editor engine types.
 */
export type EditorEngineDefaultDraggableConfiguration =
    EditorEngineDraggableConfiguration<EditorEngineDefaultTypeInput>;

export type EditorEngineDropCandidateSetter<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = (
    input: null | {
        /**
         * A unique identifier for this candidate. If an attempt is made to call this function with a candidate that
         * has this same ID, the attempt will be ignored.
         */
        candidateId: string;
        /**
         * The draggable configuration for this candidate.
         */
        draggableConfiguration: EditorEngineDraggableConfiguration<TEditorEngineTypeInput>;
        /**
         * The preview of this drag operation.
         */
        preview: EditorEngineDropPreview<TEditorEngineTypeInput['Data']>;
    },
) => void;
