import type { BlockResource } from '@/app/editor/blocks/types';

export const getMakeColumn = ({
    activePageId,
    activeFunnelId,
    companyId,
}: {
    activePageId: string;
    activeFunnelId: string;
    companyId: string;
}) => {
    return ({ id }: { id: string }) => {
        return {
            type: 'component',
            id,
            attributes: {
                name: 'gridColumn',
                componentType: 'gridColumn',
                content: {
                    box: {
                        left: 'none',
                        right: 'none',
                        top: 'small',
                        bottom: 'small',
                    },
                    verticalAlign: 'center',
                    framed: true,
                },
            },
            relationships: {
                page: {
                    data: {
                        type: 'page',
                        id: activePageId,
                    },
                },
                parent: {
                    data: null,
                },
                components: {
                    data: [],
                },
                allowedChildSections: {
                    data: [],
                },
                campaign: {
                    data: {
                        type: 'campaign',
                        id: activeFunnelId,
                    },
                },
                section: {
                    data: null,
                },
                category: {
                    data: null,
                },
                company: {
                    data: {
                        type: 'company',
                        id: companyId,
                    },
                },
            },
        } as BlockResource;
    };
};
