import type { ReactNode, SVGProps } from 'react';

export const enum FunnelSetting {
    Sharing = 'SHARING',
    Favicon = 'FAVICON',
    Cookies = 'COOKIES',
    Affiliate = 'AFFILIATE',
    Archive = 'ARCHIVE',
    ProgressBar = 'PROGRESS_BAR',
    FunnelSharing = 'FUNNEL_SHARING',
    Senders = 'SENDERS',
}

export type ModalView = 'overview' | 'sharingImgLib' | 'sharingImgCrop';

export interface SidebarSetting {
    id: FunnelSetting;
    icon: (props: SVGProps<SVGSVGElement>) => ReactNode;
    activeIcon: (props: SVGProps<SVGSVGElement>) => ReactNode;
    title: string;
    isActive: boolean;
    isDanger?: boolean;
}
