import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from '@/utils/createSelectors';

import { createVirtualIdSlice } from './virtualIdSlice';

import type { VirtualIdSlice } from './virtualIdSlice';

export type State = VirtualIdSlice;

const usePerspectiveEditorEngineStoreBase = create<State>()(
    devtools((...args) => ({
        ...createVirtualIdSlice(...args),
    })),
);

export const usePerspectiveEditorEngineStore = createSelectors(usePerspectiveEditorEngineStoreBase);
