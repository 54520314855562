import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { Field } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import Personalization from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Personalization';
import TextAlign from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextAlign';
import { TextSize } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextSize';
import TextStyle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextStyle';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { SidebarPortalTarget } from '@/ui/components/_BlockEditFields/SidebarPortalTarget';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';

export interface Props
    extends Pick<DefaultEditFormProps, 'blockId' | 'handleSubmit' | 'activeTheme'> {
    isTipTap: boolean;
}

const DefaultForm = ({ blockId, handleSubmit, activeTheme, isTipTap }: Props) => {
    const { t } = useTranslation(NAME);
    const { isInColumn } = useLayoutInfo(blockId);

    return (
        <>
            {isTipTap ? (
                <>
                    {/* Portals from SidebarPortal.tsx */}
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-size-portal-${blockId}`} />
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-styles-portal-${blockId}`} />
                </>
            ) : (
                <>
                    <TextSize textBlockId={blockId} />
                    <TextStyle textBlockId={blockId} />
                </>
            )}
            <div className="grid grid-cols-3 gap-x-2">
                <Field
                    name="attributes.content.align"
                    component={TextAlign}
                    submit={handleSubmit}
                />

                {isTipTap ? (
                    <>
                        <SidebarPortalTarget id={`tip-tap-sidebar-text-color-portal-${blockId}`} />
                        <SidebarPortalTarget
                            id={`tip-tap-sidebar-personalization-portal-${blockId}`}
                        />
                    </>
                ) : (
                    <>
                        <Field
                            name="attributes.content.color"
                            component={Color}
                            expand="center"
                            submit={handleSubmit}
                            themeColor={getThemeColor(activeTheme, 'fontColor')}
                            tooltip={t('font-color')}
                        />
                        <Personalization blockId={blockId} />
                    </>
                )}
            </div>

            <BoxSettings
                blockId={blockId}
                activeTheme={activeTheme}
                handleSubmit={handleSubmit}
                hiddenColor={isInColumn}
            />
        </>
    );
};

export default DefaultForm;
