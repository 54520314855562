import { NAME } from '@/app/editor/editor/constants';

import { FontBoldIcon, FontItalicIcon, UnderlineIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { getEditorState, saveDraftState } from '@/app/editor/blocks/models/draftEditorStates';
import LinkUrlInput from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextStyle/LinkUrlInput';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import ButtonPanel from '@/ui/components/_BlockEditFields/ButtonPanel';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';

import { applyInlineStyle, applyLinkEntity, getLinkKey, removeLinkFromSelection } from './helper';
import LinkIcon from './icons/Link';
import UnlinkIcon from './icons/Unlink';

import type { TextStyle as TextStyleType } from '@/app/editor/blocks/types';
import type { ItemData } from '@/ui/components/_BlockEditFields/ButtonPanel';
import type { KeyboardEvent, MouseEvent } from 'react';

export interface Props {
    textBlockId: string;
    textStyles?: TextStyleType[];
    hideLink?: boolean;
    draftDataPath?: string;
}

const detailsMap = {
    BOLD: {
        icon: FontBoldIcon,
        tooltip: 'tooltip-bold',
        hotkey: ['cmd', 'B'],
    },
    UNDERLINE: {
        icon: UnderlineIcon,
        tooltip: 'tooltip-underline',
        hotkey: ['cmd', 'U'],
    },
    ITALIC: {
        icon: FontItalicIcon,
        tooltip: 'tooltip-italic',
        hotkey: ['cmd', 'I'],
    },
    LINK: {
        icon: LinkIcon,
        tooltip: 'tooltip-link',
        hotkey: [],
    },
    UNLINK: {
        icon: UnlinkIcon,
        tooltip: 'tooltip-unlink',
        hotkey: [],
    },
};

const TextStyle = ({ textBlockId, hideLink, textStyles, draftDataPath }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const editorState = useAppSelector((state) => getEditorState(state, textBlockId));

    const [styleButtons, setStyleButtons] = useState<ItemData[]>([]);
    const [linkUrl, setLinkUrl] = useState<string>('');
    const [linkUrlInputVisible, setLinkUrlInputVisible] = useState<boolean>(false);

    useEffect(() => {
        let buttons: ItemData[];

        const hasInlineStyle = (style: string) => {
            if (!editorState) {
                return false;
            }

            const currentStyle = editorState.getCurrentInlineStyle();

            return currentStyle.has(style);
        };

        const isActiveStyle = (style: string) => {
            if (style === 'LINK') {
                return !!getLinkKey(editorState);
            }

            return hasInlineStyle(style);
        };

        buttons = textStyles.map((textStyle): ItemData => {
            const details = detailsMap[textStyle];
            const Icon = details.icon;

            return {
                name: textStyle,
                value: textStyle,
                render: () => <Icon className="size-5" />,
                active: isActiveStyle(textStyle),
                tooltip: t(details.tooltip),
                hotkey: details.hotkey,
            };
        });

        if (hideLink) {
            buttons.pop();
        }

        setStyleButtons(buttons);
    }, [textStyles, hideLink, editorState, t]);

    const promptForLink = () => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();

        if (!selection.isCollapsed()) {
            let url = '';
            const linkKey = getLinkKey(editorState);

            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }

            setLinkUrl(url);
            setLinkUrlInputVisible(true);
        }
    };

    const handleStyleClick = (style: ItemData) => {
        if (style.value === 'LINK') {
            return promptForLink();
        }

        const updatedEditorState = applyInlineStyle(editorState, style.value);

        // Update Redux Draft.js State
        dispatch(saveDraftState(textBlockId, updatedEditorState, draftDataPath));
    };

    const confirmLink = (event: KeyboardEvent | MouseEvent) => {
        event.preventDefault();

        let url = linkUrl;

        if (
            url &&
            !/^https?:\/\//i.test(linkUrl) &&
            !url.startsWith('mailto:') &&
            !url.startsWith('tel:')
        ) {
            url = 'https://' + url;
        }

        const updatedEditorState = applyLinkEntity(editorState, url);

        // Update Redux Draft.js State
        dispatch(saveDraftState(textBlockId, updatedEditorState, draftDataPath));

        setLinkUrlInputVisible(false);
        setLinkUrl('');
    };

    const removeLink = () => {
        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
            const updatedEditorState = removeLinkFromSelection(editorState);

            // Update Redux Draft.js State
            dispatch(saveDraftState(textBlockId, updatedEditorState, draftDataPath));

            setLinkUrlInputVisible(false);
            setLinkUrl('');
        }
    };

    return (
        <FieldContainer>
            <ButtonPanel items={styleButtons} onItemClick={handleStyleClick} />
            {linkUrlInputVisible && (
                <LinkUrlInput
                    onConfirm={confirmLink}
                    onRemove={removeLink}
                    onChange={setLinkUrl}
                    url={linkUrl}
                />
            )}
        </FieldContainer>
    );
};

TextStyle.defaultProps = { textStyles: ['BOLD', 'ITALIC', 'UNDERLINE', 'LINK'] };

export default TextStyle;
