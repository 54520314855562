import { type BlockConfig, BorderMenuItem } from '../blocks/types';

import type { LineType } from '../blocks/types';

export const NAME = 'editor';

export const HELP_TOOLTIPS_MAP: Record<
    string,
    {
        contentId: string;
        slug: string;
    }
> = {
    header: {
        contentId: '6KJKhImy1ABkZ6S7xFt8Gz',
        slug: 'component-header',
    },
    icon: {
        contentId: '6216lBdOPZyMyJl16WFWVN',
        slug: 'component-icon',
    },
    divider: {
        contentId: '6wlilJmLlhYKfe9hbKw7BC',
        slug: 'component-divider',
    },
    list: {
        contentId: '7zeh7OR8cW8D1GrpZzFiro',
        slug: 'component-list',
    },
    listItem: {
        contentId: '7zeh7OR8cW8D1GrpZzFiro',
        slug: 'component-list-item',
    },
    image: {
        contentId: '4V9p2kXl6XNcA8nvcBiUei',
        slug: 'component-image',
    },
    footer: {
        contentId: '4dT5uLGHBhWyi3J5wZ0xXo',
        slug: 'component-footer',
    },
    media: {
        contentId: '6Gk0OBlY9GYMFllpEYV4Bf',
        slug: 'component-video',
    },
    emoji: {
        contentId: '16VdU6uvi8pTZyWMfVGAki',
        slug: 'component-emoji',
    },
    text: {
        contentId: '3PqP2hpJ12T6GA0TgzdqJc',
        slug: 'component-text',
    },
    button: {
        contentId: '6iOSIqZIX7EplxEig9M4iW',
        slug: 'component-button',
    },
    questionMediaAnswer: {
        contentId: '6fVw0bqt7FliDgQctROcGu',
        slug: 'component-question-media-answer',
    },
    questionMediaAnswerText: {
        contentId: '7AMtfa7bPuSzCdykRBNfGj',
        slug: 'component-question-media-answer-text',
    },
    questionTextAnswer: {
        contentId: '5WNrK6NJjxnQe36O5aRgAj',
        slug: 'component-question-text-answer',
    },
    questionMultipleChoice: {
        contentId: '7qGmMqTrxGnQDoCZ8sUXpd',
        slug: 'component-question-multiple-choice',
    },
    questionForm: {
        contentId: 'tSuMeshfIEG5Eyje23P5O',
        slug: 'component-question-form',
    },
    questionFormCalendly: {
        contentId: '33J1EnDPcfJH0hrR1TolM',
        slug: 'component-question-form-calendly',
    },
    questionMedia: {
        contentId: '3LccYVl5nDAGIu8ao59ATs',
        slug: 'component-question-media',
    },
    questionText: {
        contentId: '5WNrK6NJjxnQe36O5aRgAj',
        slug: 'component-question-text',
    },
    input: {
        contentId: '4TtILQD95WUhRKpWM367ix',
        slug: 'component-input',
    },
    webinar: {
        contentId: '7dAfEPT5lyiJ7kwAJqSeVW',
        slug: 'component-webinar',
    },
    illustration: {
        contentId: '6aFvP7wqnsQx4QhOm02SNE',
        slug: 'component-illustration',
    },
    accordion: {
        contentId: '5cOgDtirMErbi24jpVwzVo',
        slug: 'component-accordion',
    },
    accordionItem: {
        contentId: '5cOgDtirMErbi24jpVwzVo',
        slug: 'component-accordion-item',
    },
    sliderImage: {
        contentId: '1OiJqtICksJKBP0BN3ePi2',
        slug: 'component-image-slider',
    },
    sliderTestimonial: {
        contentId: '5eDx9H9adFN0ZdDPLXFGcm',
        slug: 'component-testimonial-slider',
    },
    embed: {
        contentId: '3mjnDt2HY87flIXTSvQ9j4',
        slug: 'component-embed',
    },
    gridRow: {
        contentId: 'M4n3CTbAXZza6PBsknaLi',
        slug: 'component-grid-row',
    },
    // same as gridRow
    gridColumn: {
        contentId: 'M4n3CTbAXZza6PBsknaLi',
        slug: 'component-grid-column',
    },
    logos: {
        contentId: '5MequgLaAYQiLR5x4qoMKs',
        slug: 'component-logos',
    },
    reviews: {
        contentId: '2XOwm838SaXXveKfQdaTnU',
        slug: 'component-reviews',
    },
};

export const MAX_WORDS_GENERATED_SHORT = 8;
export const MAX_WORDS_GENERATED_LONG = 20;

export const DEFAULT_BLOCK_CONFIG: BlockConfig = {
    borderMenu: {
        hidden: false,
        items: [
            BorderMenuItem.move,
            BorderMenuItem.delete,
            BorderMenuItem.add,
            BorderMenuItem.duplicate,
        ],
    },
    selectable: true,
    highlightWithActiveChild: true,
};

export const BLOCK_DELETION_REMINDER_LS_KEY = 'block-deletion-reminder';
export const ART_BOARD_SIZE_LS_KEY = 'art-board-size';

export const DATE_FORMAT = {
    DEFAULT_EU: 'DD.MM.YYYY',
    DEFAULT_US: 'MM/DD/YYYY',
    MIXED: 'DD/MM/YYYY',
};

export const TALL_BLOCK_OFFSET = 230; // Roughly the worst case height of the block border menu
export const MIN_LABEL_WIDTH = 100; // Roughly the worst case width of the block label

export const ARTBOARD_VIEW_ID = 'artboardView';

export const COLOR_PICKER_HISTORY_LS_KEY = 'color-picker-history';

export const DEFAULT_BG_IMAGE_SRC =
    'https://images.unsplash.com/photo-1437422061949-f6efbde0a471?auto=compress,format&dpr=2&fit=crop&w=1024';

export const LINE_STYLES: LineType[] = ['hidden', 'solid', 'dashed'];
