import {
    EditorEngineDropPosition,
    type EditorEngineNodeData,
} from '@/app/editor/engine/core/types';
import { EditorEngineOrientation } from '@/app/editor/engine/core/types/util';
import { getOrientationFromPosition } from '@/app/editor/engine/core/utils/dragAndDrop/getOrientationFromPosition';

import type {
    EditorEngineDropPreview,
    EditorEngineNodeManager,
} from '@/app/editor/engine/core/types';

/**
 * Will return true if a drag and drop operation will not change any state.
 */
export const isDropNoOp = <TNodeData extends EditorEngineNodeData>({
    nodeManager,
    preview,
}: {
    nodeManager: EditorEngineNodeManager<TNodeData>;
    preview: EditorEngineDropPreview<TNodeData>;
}) => {
    const { futurePosition } = preview;
    const orientation = getOrientationFromPosition(futurePosition);
    const isVertical = orientation === EditorEngineOrientation.Vertical;
    const isHorizontal = orientation === EditorEngineOrientation.Horizontal;
    const asContiguous = nodeManager.getAsContiguous(preview.original, preview.target, orientation);

    if (!asContiguous) {
        return false;
    }

    const [first, second] = asContiguous;
    const isFirstOriginal = nodeManager.identify(first) === nodeManager.identify(preview.original);
    const isSecondOriginal =
        nodeManager.identify(second) === nodeManager.identify(preview.original);
    const isFirstTarget = nodeManager.identify(first) === nodeManager.identify(preview.target);
    const isSecondTarget = nodeManager.identify(second) === nodeManager.identify(preview.target);

    if (
        (isVertical &&
            isFirstOriginal &&
            isSecondTarget &&
            futurePosition === EditorEngineDropPosition.Top) ||
        (isVertical &&
            isFirstTarget &&
            isSecondOriginal &&
            futurePosition === EditorEngineDropPosition.Bottom) ||
        (isHorizontal &&
            isFirstOriginal &&
            isSecondTarget &&
            futurePosition === EditorEngineDropPosition.Left) ||
        (isHorizontal &&
            isFirstTarget &&
            isSecondOriginal &&
            futurePosition === EditorEngineDropPosition.Right)
    ) {
        return true;
    }

    return false;
};
