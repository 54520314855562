import Box from '@/app/editor/blocks/components/_wrapper/Box';
import SortableList from '@/app/editor/blocks/components/_wrapper/SortableList';
import Text from '@/app/editor/blocks/components/Text/Component';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';

import NewComponent from './NewComponent';

import type { Alignment, DefaultBlockComponentProps } from '../../types';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
    color: string;
    fieldName: string;
    wysiwyg: RawDraftContentState;
}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({
        artBoardIndex,
        children,
        box,
        blockId,
        nestedLevel,
        align,
        wysiwyg,
        color,
        activeTheme,
        isPreview,
        isDragged,
    }) => {
        const { isVerticalOnly, isInColumn } = useLayoutInfo(blockId, isPreview);

        const wrapperClass = isVerticalOnly
            ? 'mx-auto flex max-w-md flex-col space-y-4'
            : 'grid grid-cols-2 gap-4';

        return (
            <Box
                box={getBoxAttributes({ box, isInColumn })}
                isDragged={isDragged}
                artBoardIndex={artBoardIndex}
            >
                <div className="mb-6">
                    <Text
                        align={align}
                        wysiwyg={wysiwyg}
                        color={getThemedOrCustomColor(color, 'fontColor', activeTheme)}
                        blockId={blockId}
                    />
                </div>
                {isPreview ? (
                    <div className={wrapperClass}>{children}</div>
                ) : (
                    <SortableList
                        parentBlockId={blockId}
                        sortingStrategy={isVerticalOnly ? 'vertical' : 'rect'}
                        nestedLevel={nestedLevel}
                        className={wrapperClass}
                    >
                        {children}
                    </SortableList>
                )}
            </Box>
        );
    },
    NewComponent,
);

Component.displayName = 'QuestionText';

export default Component;
