import { store } from '@/core/redux/store';

import { getCampaignLatestVersion, getCampaignStatus } from '@/app/campaigns/helpers';
import {
    determineFullCampaignUrl,
    determinePublishDomain,
    determinePublishSlug,
} from '@/app/campaigns/helpers/publish';
import { getCampaignById } from '@/app/campaigns/models/campaigns';
import { fetchCampaignAndVersions } from '@/app/campaigns/models/campaigns';
import {
    getPublishDomainByCampaignId,
    getPublishDomainHasFetchedByCampaignId,
    getPublishSlugByCampaignId,
} from '@/app/campaigns/models/domainEditing';
import { getCampaignVersionById } from '@/app/campaigns/models/versions';
import {
    fetchDomainList,
    getCampaignLatestVersionDomain,
    getDefaultDomains,
} from '@/app/domains/models/domainList';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import type { CampaignVersionResource } from '@/app/campaigns/types';

export const preparePublishState = async () => {
    const campaignId = getCampaignIdFromRouter();

    // Needed for getDefaultDomains() call
    await store.dispatch(fetchDomainList());

    // Needed for getCampaignLatestVersionDomain() call
    await store.dispatch(fetchCampaignAndVersions(campaignId));

    const campaign = getCampaignById(store.getState(), campaignId);
    const latestCampaignVersionId: string | undefined = getCampaignLatestVersion(campaign)?.id;
    const latestCampaignVersion: CampaignVersionResource | undefined = latestCampaignVersionId
        ? getCampaignVersionById(latestCampaignVersionId)(store.getState())
        : undefined;
    const publishSlug = getPublishSlugByCampaignId(store.getState(), campaignId);
    const publishDomain = getPublishDomainByCampaignId(store.getState(), campaignId);
    const publishDomainHasFetched = getPublishDomainHasFetchedByCampaignId(
        store.getState(),
        campaignId,
    );
    const defaultDomains = getDefaultDomains(store.getState());
    const campaignStatus = getCampaignStatus(campaign);
    const latestVersionDomain = getCampaignLatestVersionDomain(store.getState(), campaign);

    // We need this distinction because possibly the user
    // has already selected another domain via the publish page.
    // In such a case we don't want to re-determine the publishDomain
    // and just use what's inside "publishDomain".
    const domain = publishDomainHasFetched
        ? publishDomain
        : determinePublishDomain({
              draftPublishDomain: publishDomain,
              latestVersionDomain,
              defaultDomains,
              campaignStatus,
          });

    const slug = determinePublishSlug({
        overridenPublishSlug: publishSlug,
        domain,
        campaign,
        latestCampaignVersion,
    });

    const publishUrl = determineFullCampaignUrl({
        slug,
        domain,
        campaign,
    });

    return {
        campaign,
        domain,
        slug,
        publishUrl,
    };
};
