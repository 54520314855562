import { isEmpty } from 'lodash/fp';

import {
    DATE_FIELD_NAMES,
    FILTER_TYPE_TO_SERVER_FILTER,
    SERVER_FILTER_TO_FILTER_TYPE,
    VALUELESS_TEXT_FILTER_TYPES,
} from '../constants';

import type {
    CRMFieldName,
    CRMFilter,
    SavedFilterResource,
    ServerSavedFilter,
    ServerSavedFilterResource,
} from '../types';

const extractFilterKey = (obj: Object) => Object.keys(obj)[0] ?? '';

export const parseSavedFilter = (data: ServerSavedFilterResource): SavedFilterResource => {
    const savedFilterFilters = data.attributes.filter;

    if (isEmpty(savedFilterFilters)) {
        return {
            id: data.id,
            name: data.attributes.name,
            emoji: data.attributes.icon,
            filters: [],
        };
    }

    const filters = Object.keys(savedFilterFilters).map((fieldName) => {
        const serverFilterType = extractFilterKey(savedFilterFilters[fieldName]);
        const serverValues = savedFilterFilters[fieldName][serverFilterType];

        const serverFilterTypeId = `${DATE_FIELD_NAMES.includes(fieldName as CRMFieldName) ? 'date' : 'string'}-${serverFilterType}`;
        const filterType = SERVER_FILTER_TO_FILTER_TYPE[serverFilterTypeId];

        const values = Array.isArray(serverValues) ? serverValues : [serverValues];
        const valuesAsString = values.map(String);

        return { fieldName, filterType, values: valuesAsString };
    }) as CRMFilter[];

    return {
        id: data.id,
        name: data.attributes.name,
        emoji: data.attributes.icon,
        filters,
    };
};

const getServerValue = (filter: CRMFilter) => {
    // Note that status always sends an array
    if (filter.fieldName === 'status') {
        return filter.values;
    }

    // BE expects a boolean for filters that do not contain any value
    if (VALUELESS_TEXT_FILTER_TYPES.includes(filter.filterType)) {
        return true;
    }

    if (filter.values.length <= 1) {
        return filter.values[0] ?? '';
    }

    return filter.values.map((value) => value ?? '');
};

export const unparseSavedFilters = (filters: CRMFilter[]): ServerSavedFilter => {
    const serverFilters = Object.fromEntries(
        filters.map((filter) => {
            const serverFilterType = FILTER_TYPE_TO_SERVER_FILTER[filter.filterType];
            const serverValues = getServerValue(filter);

            return [filter.fieldName, { [serverFilterType]: serverValues }];
        }),
    ) as ServerSavedFilter;

    return serverFilters;
};
