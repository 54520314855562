import { getIsCellWrapEnabled } from '@/app/crm/models/table';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';

import { formatBytes } from './utils';

import type { ReactNode } from 'react';

interface Props {
    filename: string;
    filesize?: number;
    actions?: ReactNode;
    statusLabel?: ReactNode;
    isTableView?: boolean;
}

export const FileInfo = ({ filename, filesize, isTableView, statusLabel, actions }: Props) => {
    const isCellWrapEnabled = useAppSelector(getIsCellWrapEnabled);

    return (
        <div
            className={cn('flex size-full items-center', {
                'mt-1.5 items-start': isCellWrapEnabled && isTableView,
                'px-3': isTableView,
            })}
        >
            <div
                className={cn(
                    'flex h-10 min-w-0 cursor-default items-center gap-2 rounded-md p-2',
                    {
                        'bg-gray-100': !isTableView,
                    },
                )}
            >
                <p className="grow truncate" title={filename}>
                    {filename}
                </p>

                <div className="flex items-center">
                    {!!filesize && (
                        <span className="mr-2 whitespace-nowrap text-xs font-medium text-gray-500">
                            {formatBytes(filesize)}
                        </span>
                    )}
                    {statusLabel && (
                        <span className="mr-2 whitespace-nowrap text-xs font-medium">
                            {statusLabel}
                        </span>
                    )}
                    {actions}
                </div>
            </div>
        </div>
    );
};
