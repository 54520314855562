import { NAME } from '@/app/crm/constants';

import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getActiveCampaign } from '@/app/campaigns/models/campaigns';
import { getWorkspaceUsersRequest } from '@/app/workspaces/helpers/apis';
import { getActiveWorkspaceId } from '@/app/workspaces/models/workspaces';
import { RequestState } from '@/app/workspaces/types';
import { handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import type { UserResource } from '@/app/user/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    workspaceUsers: UserResource[];
    requestState: Partial<Record<'fetchUsers', RequestState>>;
}

const initialState: State = {
    workspaceUsers: EMPTY_ARRAY,
    requestState: {
        fetchUsers: RequestState.Idle,
    },
};

const workspaceUsersSlice = createSlice({
    name: `${NAME}/workspaceUsers`,
    initialState,
    reducers: {
        setWorkspaceUsers(state, action: PayloadAction<UserResource[]>) {
            state.workspaceUsers = action.payload;
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======
export const { setWorkspaceUsers, setRequestState, reset } = workspaceUsersSlice.actions;

// === Selectors ======
const getWorkspaceUsers = (state: AppState) => state[NAME].workspaceUsersReducer.workspaceUsers;

export const getWorkspaceUserById = (userId: string) =>
    createSelector(
        getWorkspaceUsers,
        (workspaceUsers): UserResource =>
            workspaceUsers?.find((user) => user.id === userId) || (EMPTY_OBJECT as UserResource),
    );

export const getRequestState = (state: AppState) => state[NAME].workspaceUsersReducer.requestState;

// === Thunks ======
export const fetchWorkspaceUsers = (): AppThunk => async (dispatch, getState) => {
    dispatch(
        setRequestState({
            fetchUsers: RequestState.InProgress,
        }),
    );

    const state = getState();

    const activeWorkspaceId = getActiveWorkspaceId(state);
    const activeCampaignWorkspaceId = getActiveCampaign(state)?.relationships?.workspace?.data?.id;
    const workspaceId = activeWorkspaceId || activeCampaignWorkspaceId;

    try {
        const users = getDataFromResponse(await getWorkspaceUsersRequest(workspaceId));

        dispatch(setWorkspaceUsers(users));

        dispatch(
            setRequestState({
                fetchUsers: RequestState.Success,
            }),
        );
    } catch (error) {
        handleRuntimeError(error, {
            debugMessage: 'Failed to fetch users',
        });

        dispatch(
            setRequestState({
                fetchUsers: RequestState.Error,
            }),
        );
    }
};

export default workspaceUsersSlice.reducer;
