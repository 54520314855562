import { useLocalStorage } from 'react-use';

import { cn } from '@/utils/cn';

import type { ReactNode } from 'react';

interface Props {
    /**
     * The title of the debug panel.
     */
    title: string;
    /**
     * The contents of the debug panel.
     */
    children: ReactNode;
}

/**
 * A collapsible debug panel for the editor engine.
 */
export const EditorEngineDebugPanel = ({ title, children }: Props) => {
    const [isOpen, setIsOpen] = useLocalStorage(title, false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div
            className={cn('overflow-hidden rounded border border-neutral-300', {
                shadow: isOpen,
            })}
        >
            <button
                onClick={toggle}
                className={cn('w-full bg-gray-300 p-3 py-2 font-bold hover:bg-gray-200', {
                    'bg-white': !isOpen,
                })}
            >
                {title}
            </button>
            {isOpen && <div className="flex flex-col items-stretch gap-2 p-3 py-3">{children}</div>}
        </div>
    );
};
