import { createSlice } from '@reduxjs/toolkit';

import { NAME } from '../constants';

import type { SidebarViewId } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface SidebarState {
    activeView: SidebarViewId;
}

const initialState: SidebarState = {
    activeView: 'pages',
};

export const sidebarSlice = createSlice({
    name: `editor/${NAME}/sidebar`,
    initialState,
    reducers: {
        setActiveView: (state, action: PayloadAction<SidebarViewId>) => {
            return {
                ...state,
                activeView: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setActiveView, reset } = sidebarSlice.actions;

// === Selectors ======

export const getActiveView = (state: AppState) => state[NAME]?.sidebarReducer?.activeView;

// === Thunks ======

export const setAddNewColumnView =
    (view: SidebarViewId): AppThunk =>
    (dispatch) => {
        dispatch(setActiveView(view));
    };

export default sidebarSlice.reducer;
