import { NAME } from '@/app/editor/blocks/constants';

import findIndex from 'lodash/findIndex';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import RadioSlider from '@/ui/components/RadioSlider';

import type { RadioSliderOption, RadioSliderSelection } from '@/ui/types';
import type { FieldRenderProps } from 'react-final-form';

interface Props extends FieldRenderProps<string> {
    onSubmit: () => void;
}

export const ButtonStyle = ({ input, onSubmit }: Props) => {
    const { t } = useTranslation(NAME);
    const { value, onChange } = input;

    const buttonStyles = useMemo(
        () =>
            [
                {
                    name: 'filled',
                    value: 'filled',
                    preventDefault: false,
                    render: () => <span className="text-sm">{t('button-style-filled')}</span>,
                },
                {
                    name: 'outline',
                    value: 'outline',
                    preventDefault: false,
                    render: () => <span className="text-sm">{t('button-style-outline')}</span>,
                },
            ] satisfies RadioSliderOption[],
        [t],
    );

    const activeIndex =
        findIndex(buttonStyles, { value }) === -1 ? 0 : findIndex(buttonStyles, { value });

    const handleChange = (style: RadioSliderSelection) => {
        if (style.name === value) {
            return;
        }

        onChange(style.value);

        setTimeout(() => {
            onSubmit();
        });
    };

    return (
        <FieldContainer>
            <RadioSlider onChange={handleChange} items={buttonStyles} activeIndex={activeIndex} />
        </FieldContainer>
    );
};
