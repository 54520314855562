import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import * as Select from '@radix-ui/react-select';
import find from 'lodash/find';
import { useTranslation } from 'next-i18next';
import { type ChangeEvent, useState } from 'react';

import { SelectContent } from '@/ui/components/_BlockEditFields/_wrappers/SelectContent';
import { SelectOption } from '@/ui/components/_BlockEditFields/_wrappers/SelectOption';
import { SelectTrigger } from '@/ui/components/_BlockEditFields/_wrappers/SelectTrigger';
import { Tooltip } from '@/ui/components/Tooltip';

import type { DropdownOption } from '@/ui/types';
import type { ComponentProps, ReactNode } from 'react';

export interface Props {
    options: DropdownOption[];
    value: string;
    icon?: (props: ComponentProps<'svg'>) => JSX.Element;
    onChange: (value: string) => void;
    customSearchHandler?: (query: string) => void;
    dropdownClass?: string;
    buttonClass?: string;
    placeholder?: string;
    hasSearch?: boolean;
    isSearching?: boolean;
    stickySelectedOption?: boolean;
    tooltip?: string;
    hasPortal?: boolean;
    hasTriggerWidth?: boolean;
}

const TooltipWrapper = ({ children, tooltip }: { children: ReactNode; tooltip?: string }) => {
    if (!tooltip) {
        return <>{children}</>;
    }

    return (
        <Tooltip content={tooltip}>
            <div>{children}</div>
        </Tooltip>
    );
};

export const Dropdown = ({
    value,
    onChange,
    tooltip,
    options,
    icon,
    dropdownClass,
    buttonClass,
    hasSearch,
    stickySelectedOption,
    customSearchHandler,
    isSearching,
    hasPortal,
    hasTriggerWidth,
}: Props) => {
    const { t } = useTranslation('common');
    const [query, setQuery] = useState('');
    const IconComponent = !!icon && icon;

    const selectedOption = find(options, { value });

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;

        setQuery(query);

        if (customSearchHandler) {
            customSearchHandler(query);
        }
    };

    let filteredOptions =
        query === ''
            ? options
            : options.filter((option) => option.key.toLowerCase().includes(query.toLowerCase()));

    if (stickySelectedOption) {
        filteredOptions = filteredOptions.reduce((previous, current) => {
            if (current.value === value) {
                return [current, ...previous];
            }

            return [...previous, current];
        }, []);
    }

    return (
        <TooltipWrapper tooltip={tooltip}>
            <Select.Root value={value} onValueChange={onChange}>
                <SelectTrigger selectedOption={selectedOption} buttonClass={buttonClass}>
                    {!!IconComponent && <IconComponent className="mr-2 size-5 flex-shrink-0" />}
                </SelectTrigger>

                <SelectContent
                    dropdownClass={dropdownClass}
                    hasPortal={hasPortal}
                    hasTriggerWidth={hasTriggerWidth}
                >
                    {hasSearch && (
                        <div className="relative z-20 mb-2 flex h-10 items-center rounded border bg-white py-2 pl-3 pr-4 text-gray-400 focus-within:border-blue-500 focus-within:text-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
                            <MagnifyingGlassIcon className="size-5" />
                            <input
                                type="text"
                                onChange={handleSearch}
                                placeholder={t('search')}
                                value={query}
                                className="w-full bg-transparent pl-2 text-sm text-gray-400 outline-none focus:text-gray-600"
                            />
                        </div>
                    )}
                    {filteredOptions.map((option, index) => (
                        <SelectOption option={option} key={`${option.key}-${index}`} />
                    ))}
                    {!filteredOptions.length && (
                        <div className="w-full px-4 py-2 text-sm font-medium text-gray-400">
                            {isSearching ? t('loading') : t('no-results')}
                        </div>
                    )}
                </SelectContent>
            </Select.Root>
        </TooltipWrapper>
    );
};

export default Dropdown;
