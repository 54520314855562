import { CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import { SquaresPlusIcon } from '@heroicons/react/20/solid';

import { About } from '@/app/editor/sections/components/icons/About';
import { CTA } from '@/app/editor/sections/components/icons/CTA';
import { Hero } from '@/app/editor/sections/components/icons/Hero';
import { Product } from '@/app/editor/sections/components/icons/Product';
import { Quiz } from '@/app/editor/sections/components/icons/Quiz';
import { Team } from '@/app/editor/sections/components/icons/Team';
import { Testimonials } from '@/app/editor/sections/components/icons/Testimonials';
import { Trust } from '@/app/editor/sections/components/icons/Trust';

import { Basics } from './components/icons/Basics';
import { Embed } from './components/icons/Embed';
import { Forms } from './components/icons/Forms';
import { Layouts } from './components/icons/Layouts';

import type {
    SectionCategory,
    SectionCategoryNew,
    SectionResource,
} from '@/app/editor/sections/types';

export const NAME = 'sections';

// TODO(CK): Remove once section templates are rolled out
export const COLUMN_SECTION_CATEGORIES: SectionCategory[] = [
    {
        name: 'basics',
        color: 'blue',
        icon: Basics,
        sections: [
            '1b-text',
            '1b-image',
            '1b-video',
            '1b-button',
            '1b-graphic',
            '1b-list',
            '1b-accordion',
            '1b-divider',
            '1b-testimonial',
            '1b-slider',
            '1b-webinar',
            '1b-logos',
            '1b-reviews',
        ],
    },
    {
        name: 'embeds',
        color: 'blue',
        icon: Embed,
        sections: [
            '1b-embed.kununu',
            '1b-embed.trustpilot',
            '1b-embed.provenexpert',
            '1b-embed.googlemaps',
            '1b-embed.customhtml',
        ],
    },
    {
        name: 'forms',
        color: 'blue',
        icon: Forms,
        sections: [
            '1b-form',
            '1b-calendly',
            '1b-form.upload',
            '1b-form.message',
            '1b-form.date',
            '1b-form.dropdown',
            '1b-form.payment',
        ],
    },
    {
        name: 'interactions',
        color: 'blue',
        icon: CursorArrowRaysIcon,
        sections: ['1b-quiz', '1b-multiplechoice', '1b-choice'],
    },
];

// TODO(CK): Remove once section templates are rolled out
export const SECTION_CATEGORIES: SectionCategory[] = [
    {
        name: 'layouts',
        color: 'light-blue',
        icon: Layouts,
        sections: [
            '2c-text-text-button--image',
            '2c-text-list--form',
            '2c-text-text-button--text-list',
            '2c-text-choice--image',
            '2c-text-quiz--image',
            '2c-graphic-text-text--form',
            '2c-graphic-text-text--quiz',
            '2c-graphic-text-text--choice',
            '2c-text-list--multiplechoice',
            '2c-text-text-image--text-form',
            '2c-text-text-button--text-video',
            '2c-text-list--image',
            '2c-video-text-text--2x',
            '3c-image-text-text--3x',
            '4c-graphic-text-text--4x',
        ],
    },
    {
        name: 'cards',
        color: 'light-blue',
        icon: SquaresPlusIcon,
        sections: [
            '1c-text-text-button',
            '1c-text-text-accordion',
            '1c-text-text-list',
            '1c-text-text-form',
            '1c-image-text-text-button',
            '1c-video-text-text',
            '1c-graphic-text-text-button',
            '1c-list-text',
        ],
    },
    ...COLUMN_SECTION_CATEGORIES,
];

export const ELEMENTS_CATEGORY_ID_DE = '5a957843b727462286d2d61e';
export const ELEMENTS_CATEGORY_ID_EN = '61af39fa0ff35780165e4faa';

// Section Templates data

export const BLOCK_SECTIONS: SectionCategoryNew[] = [
    {
        name: 'basics',
        icon: Basics,
        color: 'blue',
        isGrid: true,
        groups: [
            {
                name: 'core',
                sections: [
                    '1b-text',
                    '1b-button',
                    '1b-image',
                    '1b-list',
                    '1b-divider',
                    '1b-logos',
                    '1b-reviews',
                ],
            },
            { name: 'media', sections: ['1b-video', '1b-testimonial', '1b-slider', '1b-graphic'] },
            { name: 'informative', sections: ['1b-webinar', '1b-accordion'] },
            {
                name: 'embed',
                sections: [
                    '1b-embed.kununu',
                    '1b-embed.trustpilot',
                    '1b-embed.provenexpert',
                    '1b-embed.googlemaps',
                    '1b-embed.customhtml',
                ],
            },
        ],
    },
    {
        name: 'interactive',
        icon: CursorArrowRaysIcon,
        color: 'blue',
        isGrid: true,
        groups: [
            { name: 'questions', sections: ['1b-multiplechoice', '1b-choice', '1b-quiz'] },
            {
                name: 'forms',
                sections: [
                    '1b-form',
                    '1b-calendly',
                    '1b-form.upload',
                    '1b-form.message',
                    '1b-form.date',
                    '1b-form.dropdown',
                    '1b-form.payment',
                ],
            },
        ],
    },
] as const;

export const SECTIONS: SectionCategoryNew[] = [
    {
        name: 'hero',
        icon: Hero,
        color: 'white',
        isGrid: false,
        groups: [
            {
                name: 'all',
                sections: [
                    'hero-1',
                    'hero-2',
                    'hero-3',
                    'hero-4',
                    'hero-5',
                    'hero-6',
                    'hero-7',
                    'hero-8',
                    'hero-9',
                ],
            },
        ],
    },
    {
        name: 'product',
        icon: Product,
        color: 'white',
        isGrid: false,
        groups: [
            {
                name: 'all',
                sections: [
                    'feature-1',
                    'feature-2',
                    'feature-3',
                    'feature-4',
                    'feature-5',
                    'feature-6',
                    'feature-7',
                    'feature-8',
                    'feature-9',
                ],
            },
        ],
    },
    {
        name: 'cta',
        icon: CTA,
        color: 'white',
        isGrid: false,
        groups: [
            {
                name: 'all',
                sections: [
                    'cta-1',
                    'cta-2',
                    'cta-3',
                    'cta-4',
                    'cta-6',
                    'cta-5',
                    'cta-7',
                    'cta-8',
                    'cta-9',
                ],
            },
        ],
    },
    {
        name: 'about',
        icon: About,
        color: 'white',
        isGrid: false,
        groups: [
            {
                name: 'all',
                sections: [
                    'about-us-1',
                    'about-us-2',
                    'about-us-3',
                    'about-us-4',
                    'about-us-5',
                    'about-us-6',
                    'about-us-7',
                    'about-us-8',
                    'about-us-9',
                    'about-us-10',
                    'about-us-11',
                    'about-us-12',
                ],
            },
        ],
    },
    {
        name: 'quiz',
        icon: Quiz,
        color: 'white',
        isGrid: false,
        groups: [
            {
                name: 'all',
                sections: [
                    'quiz-1',
                    'quiz-2',
                    'quiz-3',
                    'quiz-4',
                    'quiz-5',
                    'quiz-6',
                    'quiz-7',
                    'quiz-8',
                ],
            },
        ],
    },
    {
        name: 'team',
        icon: Team,
        color: 'white',
        isGrid: false,
        groups: [
            {
                name: 'all',
                sections: [
                    'team-1',
                    'team-2',
                    'team-3',
                    'team-4',
                    'team-5',
                    'team-6',
                    'team-7',
                    'team-8',
                    'team-9',
                    'team-10',
                    'team-11',
                    'team-12',
                ],
            },
        ],
    },
    {
        name: 'testimonials',
        icon: Testimonials,
        color: 'white',
        isGrid: false,
        groups: [
            {
                name: 'all',
                sections: [
                    'testimonial-1',
                    'testimonial-2',
                    'testimonial-3',
                    'testimonial-4',
                    'testimonial-5',
                    'testimonial-6',
                    'testimonial-7',
                    'testimonial-8',
                    'testimonial-9',
                    'testimonial-10',
                    'testimonial-11',
                ],
            },
        ],
    },
    {
        name: 'trust',
        icon: Trust,
        color: 'white',
        isGrid: false,
        groups: [
            {
                name: 'all',
                sections: [
                    'trust-1',
                    'trust-2',
                    'trust-3',
                    'trust-6',
                    'trust-5',
                    'trust-4',
                    'trust-7',
                    'trust-8',
                ],
            },
        ],
    },
] as const;

export const PLACEHOLDER_COUNTDOWN_SECTION: SectionResource = {
    id: '1b-countdown',
    type: 'section',
    attributes: {
        name: '1b-countdown',
    },
};
