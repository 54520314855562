import { reset as resetAccountSetup } from './accountSetup';
import { reset as resetFunnelSelection } from './funnelSelection';
import { reset as resetSetup } from './setup';
import { reset as resetUsers } from './users';
import { reset as resetWorkspace } from './workspaces';

import type { AppThunk } from '@/core/redux/types';

export const resetWorkspaceState = (): AppThunk => (dispatch) => {
    dispatch(resetWorkspace());
    dispatch(resetAccountSetup());
    dispatch(resetSetup());
    dispatch(resetFunnelSelection());
    dispatch(resetUsers());
};
