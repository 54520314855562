import { indexOfEntry } from '@/app/editor/engine/core/utils/history/indexOfEntry';

import type {
    EditorEngineHistoryDefaultEntry,
    EditorEngineHistoryDefaultEntryCollection,
    EditorEngineHistoryEntryCollectionSetter,
} from '@/app/editor/engine/core/types';

/**
 * Remove entries after a specific entry.
 *
 * This is useful when, for example, a new action has been queued. If the user
 * has already undone some actions, the new action will be the latest one, and
 * the following actions will be removed.
 */
export const removeEntriesAfterEntry = ({
    entry,
    entriesInfo,
    setEntriesInfo,
}: {
    /**
     * The entry to remove the following entries after.
     */
    entry: EditorEngineHistoryDefaultEntry;
    /**
     * Information about the entries.
     */
    entriesInfo: EditorEngineHistoryDefaultEntryCollection;
    /**
     * A function to set the entries information.
     */
    setEntriesInfo: EditorEngineHistoryEntryCollectionSetter;
}) => {
    const currentIndex = indexOfEntry({
        entry,
        entries: entriesInfo.entries,
    });

    if (currentIndex === -1) {
        setEntriesInfo({
            current: null,
            entries: [],
        });

        return;
    }

    setEntriesInfo({
        current: entry,
        entries: entriesInfo.entries.slice(0, currentIndex + 1),
    });
};
