import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { imgix } from '@/core/imgix';
import { cn } from '@/utils/cn';

import type { Language } from 'types/generic';

export interface Props {
    onLanguageSwitch: (language: string) => void;
    language: Language;
    wide?: boolean;
    withLabels?: boolean;
}

const languages: Language[] = ['en', 'de'];

const LanguageSwitch = ({ onLanguageSwitch, language, wide, withLabels }: Props) => {
    const { t } = useTranslation('common');
    const [activeLanguage, setActiveLanguage] = useState(language);

    const toggleLanguage = () => {
        const newLanguage = activeLanguage === 'de' ? 'en' : 'de';
        setActiveLanguage(newLanguage);

        onLanguageSwitch(newLanguage);
    };

    const wrapperClassName = cn(
        'relative cursor-pointer select-none rounded-lg border bg-gray-100 transition-colors',
        {
            'h-12 w-full': wide && withLabels,
            'h-8 w-full': wide && !withLabels,
            'h-[29px] w-[73px]': !wide,
        },
    );

    return (
        <div className={wrapperClassName} onClick={toggleLanguage}>
            {languages.map((language, index) => (
                <div
                    key={`choice-${language}`}
                    className={cn(
                        'group absolute z-20 flex h-full w-1/2 min-w-0 items-center justify-center',
                        index === 1 ? 'right-0' : '',
                    )}
                    data-value={language}
                >
                    <Image
                        src={language === 'de' ? imgix.flags.de : imgix.flags.uk}
                        alt={language}
                        width={18}
                        height={12}
                    />

                    {wide && withLabels && (
                        <p
                            className={cn(
                                'ml-2 text-sm font-medium',
                                activeLanguage === language
                                    ? 'text-blue-500'
                                    : 'text-gray-500 group-hover:text-gray-600',
                            )}
                        >
                            {t(`language-${language}`)}
                        </p>
                    )}
                </div>
            ))}
            <div
                className={cn(
                    'absolute z-10 h-full w-1/2 rounded-lg bg-white shadow-sm transition-all',
                    activeLanguage === 'en' ? 'left-0' : 'left-1/2',
                )}
            />
        </div>
    );
};

export default LanguageSwitch;
