import { createSelector, createSlice } from '@reduxjs/toolkit';
import { arrayMoveImmutable } from 'array-move';
import get from 'lodash/get';

import { getCampaignById, getActiveCampaign } from '@/app/campaigns/models/campaigns';
import { apiGet, apiPatch, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY } from '@/utils/empty';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { getPages } from './pages';
import { NAME } from '../constants';

import type { PageMappingResource, PageResource } from '@/app/editor/pages/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    [campaignId: string]: {
        resultPages: string[];
        otherPages: string[];
    };
}

const initialState: State = {};

export const pageMappingSlice = createSlice({
    name: `editor/${NAME}/pageMapping`,
    initialState,
    reducers: {
        setResultPages(state, action: PayloadAction<{ campaignId: string; pageIds: string[] }>) {
            return {
                ...state,
                [action.payload.campaignId]: {
                    ...state[action.payload.campaignId],
                    resultPages: action.payload.pageIds,
                },
            };
        },
        setOtherPages(state, action: PayloadAction<{ campaignId: string; pageIds: string[] }>) {
            return {
                ...state,
                [action.payload.campaignId]: {
                    ...state[action.payload.campaignId],
                    otherPages: action.payload.pageIds,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setResultPages, setOtherPages, reset } = pageMappingSlice.actions;

// === Selectors ======

const getPageMapping = (state: AppState) => state[NAME]?.pageMappingReducer;

export const getActiveCampaignMappingId = (state: AppState) => {
    const activeCampaign = getActiveCampaign(state);

    return get(activeCampaign, 'relationships.questionMapping.data.id');
};

export const getOtherPagesByCampaignId = (state: AppState, campaignId: string) =>
    state[NAME]?.pageMappingReducer[campaignId]?.otherPages;

export const getResultPagesByCampaignId = (state: AppState, campaignId: string) =>
    state[NAME]?.pageMappingReducer[campaignId]?.resultPages;

export const getPopulatedOtherPagesByCampaignId = (campaignId: string) =>
    createSelector(
        getPageMapping,
        getPages,
        (
            pageMappings: { [campaignId: string]: { otherPages: string[]; resultPages: string[] } },
            pages: { [campaignId: string]: { [pageId: string]: PageResource } },
        ) => {
            const otherPages = pageMappings[campaignId].otherPages;

            return otherPages.map((pageId) => {
                return pages[campaignId][pageId];
            });
        },
    );

export const getPopulatedResultPagesByCampaignId = (campaignId: string) =>
    createSelector(
        getPageMapping,
        getPages,
        (
            pageMappings: { [campaignId: string]: { otherPages: string[]; resultPages: string[] } },
            pages: { [campaignId: string]: { [pageId: string]: PageResource } },
        ) => {
            const resultPages = pageMappings[campaignId].resultPages;

            return resultPages.map((pageId) => {
                return pages[campaignId][pageId];
            });
        },
    );

// === Thunks ======

export const reorderPages =
    (oldIndex: number, newIndex: number): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const campaignId = getCampaignIdFromRouter();
        const otherPages = getOtherPagesByCampaignId(state, campaignId);
        const mappingId = getActiveCampaignMappingId(state);
        const movedPageId = otherPages[oldIndex];

        let nextPageId = otherPages[newIndex];

        // dragged to the end
        if (newIndex === otherPages.length - 1) {
            nextPageId = undefined;
        }

        // update page array
        const updatedOtherPages = arrayMoveImmutable(otherPages, oldIndex, newIndex);

        // optimistic update for UI
        dispatch(setOtherPages({ campaignId, pageIds: updatedOtherPages }));

        // update in DB
        try {
            await apiPatch(`/mappings/question/${mappingId}/pages/${movedPageId}`, {
                data: { nextPageId },
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating question mapping failed:' });
        }
    };

// Helper function
const setMappingAndPages =
    (campaignId: string, resultPages: string[], otherPages: string[]): AppThunk =>
    (dispatch) => {
        dispatch(setOtherPages({ campaignId, pageIds: otherPages }));
        dispatch(setResultPages({ campaignId, pageIds: resultPages }));
    };

// Fetch Question mapping and set "other" and "result" pages
export const fetchPageMapping =
    (campaignId: string): AppThunk =>
    async (dispatch, getState) => {
        const campaign = getCampaignById(getState(), campaignId);
        const mappingId = get(campaign, 'relationships.questionMapping.data.id');

        if (!mappingId) {
            return;
        }

        try {
            const response = await apiGet(`/mappings/question/${mappingId}`);
            const mapping: PageMappingResource = getDataFromResponse(response);

            const result = mapping?.attributes?.pages?.result;
            const other = mapping?.attributes?.pages?.other;

            dispatch(setMappingAndPages(campaignId, result || EMPTY_ARRAY, other || EMPTY_ARRAY));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching question mapping failed:' });
        }
    };

export default pageMappingSlice.reducer;
