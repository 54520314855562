import { AnimatePresence, motion } from 'framer-motion';

import { cn } from '@/utils/cn';

import LoadingSpinner from '../LoadingSpinner';

import type { ReactNode } from 'react';

export interface Props {
    loading: boolean;
    children: ReactNode;
    narrow?: boolean;
    size?: 'tiny' | 'small' | 'medium';
    delay?: number;
}

export const Loader = ({ loading, narrow, children, size, delay = 1 }: Props) => {
    return (
        <div className="relative w-full">
            <AnimatePresence mode="popLayout">
                {loading ? (
                    <motion.div
                        key="loading"
                        className={cn(
                            'flex w-full items-center justify-center',
                            narrow ? 'h-auto' : 'h-64',
                        )}
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: 1,
                            transition: { type: 'spring', bounce: 0, delay: delay }, // show loader only after a delay to avoid flickering loading states for potentially fast requests
                        }}
                        exit={{ opacity: 0 }}
                    >
                        <LoadingSpinner size={size} />
                    </motion.div>
                ) : (
                    <motion.div
                        key="loaded"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: 'spring', bounce: 0, duration: 0.2 }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Loader;
