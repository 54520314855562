import { NAME } from '@/app/crm/constants';

import { createSelector, createSlice } from '@reduxjs/toolkit';
import last from 'lodash/last';
import qs from 'query-string';

import { RequestState } from '@/app/workspaces/types';
import { crmApiPost, crmApiGet, handleRuntimeError, crmApiPatch, crmApiDelete } from '@/core/api';
import { getDataFromResponse, getMetaFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { getContactById, setContact } from './contacts';
import { optimisticallyUpdateContactAttributesValue } from '../helpers';

import type { Pagination, ProfileNoteResource } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

type ProfileNotesRequestState = Partial<
    Record<'fetchNotes' | 'addNote' | 'editNote' | 'deleteNote', RequestState>
>;

interface State {
    profileNotes: { [contactId: string]: ProfileNoteResource[] };
    pagination: { [contactId: string]: Pagination };
    editingIds: { [noteId: string]: boolean };
    deletingDraftIds: { [noteId: string]: boolean };
    requestState: {
        [contactId: string]: ProfileNotesRequestState;
    };
}

const initialState: State = {
    profileNotes: EMPTY_OBJECT,
    pagination: EMPTY_OBJECT,
    editingIds: EMPTY_OBJECT,
    deletingDraftIds: EMPTY_OBJECT,
    requestState: EMPTY_OBJECT,
};

const DEFAULT_PAGINATION: Pagination = { limit: 20, count: 0, page: 0 };
const DEFAULT_REQUEST_STATE: ProfileNotesRequestState = {
    fetchNotes: RequestState.Idle,
    addNote: RequestState.Idle,
    editNote: RequestState.Idle,
    deleteNote: RequestState.Idle,
};

const profileNotesSlice = createSlice({
    name: `${NAME}/profileNotes`,
    initialState,
    reducers: {
        appendProfileNotes(
            state,
            action: PayloadAction<{ contactId: string; notes: ProfileNoteResource[] }>,
        ) {
            state.profileNotes[action.payload.contactId] = [
                ...(state.profileNotes[action.payload.contactId] || EMPTY_ARRAY),
                ...action.payload.notes,
            ];
        },
        prependProfileNotes(
            state,
            action: PayloadAction<{ contactId: string; notes: ProfileNoteResource[] }>,
        ) {
            state.profileNotes[action.payload.contactId] = [
                ...action.payload.notes,
                ...(state.profileNotes[action.payload.contactId] || EMPTY_ARRAY),
            ];
        },
        setProfileNotes(
            state,
            action: PayloadAction<{ contactId: string; notes: ProfileNoteResource[] }>,
        ) {
            state.profileNotes[action.payload.contactId] = action.payload.notes;
        },
        setPagination(state, action: PayloadAction<{ contactId: string; pagination: Pagination }>) {
            state.pagination[action.payload.contactId] = action.payload.pagination;
        },
        deleteProfileNote(state, action: PayloadAction<{ contactId: string; noteId: string }>) {
            const contactProfileNotes = state.profileNotes[action.payload.contactId];
            const filteredProfileNotes = contactProfileNotes?.filter(
                (note) => note.id !== action.payload.noteId,
            );
            state.profileNotes[action.payload.contactId] = filteredProfileNotes;
        },
        updateProfileNote(
            state,
            action: PayloadAction<{ contactId: string; note: ProfileNoteResource }>,
        ) {
            const contactNotes = state.profileNotes[action.payload.contactId];
            const indexToUpdate = contactNotes.findIndex(
                (note) => note?.id === action.payload.note.id,
            );
            contactNotes[indexToUpdate] = action.payload.note;
        },
        setEditingIds(state, action: PayloadAction<{ [noteId: string]: boolean }>) {
            state.editingIds = { ...state.editingIds, ...action.payload };
        },
        setDeletingDraftIds(state, action: PayloadAction<{ [noteId: string]: boolean }>) {
            state.deletingDraftIds = { ...state.deletingDraftIds, ...action.payload };
        },
        setRequestState(
            state,
            action: PayloadAction<{ contactId: string; requestState: ProfileNotesRequestState }>,
        ) {
            state.requestState[action.payload.contactId] = {
                ...state.requestState[action.payload.contactId],
                ...action.payload.requestState,
            };
        },
        resetEditingIds(state) {
            state.editingIds = EMPTY_OBJECT;
        },
        resetDeletingDraftIds(state) {
            state.deletingDraftIds = EMPTY_OBJECT;
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======
export const {
    appendProfileNotes,
    prependProfileNotes,
    setProfileNotes,
    deleteProfileNote,
    updateProfileNote,
    setPagination,
    setEditingIds,
    setDeletingDraftIds,
    setRequestState,
    resetEditingIds,
    resetDeletingDraftIds,
    reset,
} = profileNotesSlice.actions;

// === Selectors ======

export const getProfileNotes = (contactId: string) => (state: AppState) =>
    state[NAME].profileNotesReducer.profileNotes[contactId] || EMPTY_ARRAY;

export const getProfileNoteById = (contactId: string, noteId: string) =>
    createSelector([getProfileNotes(contactId)], (profileNotes) =>
        profileNotes.find((profileNote) => profileNote.id === noteId),
    );

export const getEditingIds = (state: AppState) => state[NAME].profileNotesReducer.editingIds;

export const getDeletingDraftIds = (state: AppState) =>
    state[NAME].profileNotesReducer.deletingDraftIds;

export const getPagination = (contactId: string) => (state: AppState) =>
    state[NAME].profileNotesReducer.pagination[contactId] || DEFAULT_PAGINATION;

export const getHasMore = (contactId: string) =>
    createSelector([getPagination(contactId)], (pagination) => {
        const { page, count, limit } = pagination;
        const fetchedCountMax = (page + 1) * limit;

        return fetchedCountMax < count;
    });

export const getRequestState = (contactId: string) => (state: AppState) =>
    state[NAME].profileNotesReducer.requestState[contactId] || DEFAULT_REQUEST_STATE;

// === Thunks ======

const fetchProfileNotesData = async ({
    contactId,
    campaignId,
    page,
    limit,
}: {
    contactId: string;
    campaignId: string;
    page: number;
    limit: number;
}): Promise<{ profileNotes: ProfileNoteResource[]; pagination: Pagination }> => {
    const query = qs.stringify({ page, limit, contactId, campaignId });

    const response = await crmApiGet(`/notes?${query}`);

    const profileNotes = getDataFromResponse(response);
    const pagination = getMetaFromResponse(response);

    return { profileNotes, pagination };
};

export const fetchProfileNotes =
    (contactId: string, campaignId: string): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                contactId,
                requestState: {
                    fetchNotes: RequestState.InProgress,
                },
            }),
        );

        try {
            const { profileNotes, pagination } = await fetchProfileNotesData({
                contactId,
                campaignId,
                limit: DEFAULT_PAGINATION.limit,
                page: DEFAULT_PAGINATION.page,
            });

            dispatch(setProfileNotes({ contactId, notes: profileNotes }));
            dispatch(setPagination({ contactId, pagination }));

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        fetchNotes: RequestState.Success,
                    },
                }),
            );
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to fetch profile notes' });

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        fetchNotes: RequestState.Error,
                    },
                }),
            );
        }
    };

export const fetchNextPage =
    (contactId: string, campaignId: string) => (): AppThunk => async (dispatch, getState) => {
        const paginationCurrent = getPagination(contactId)(getState());

        try {
            const { profileNotes, pagination } = await fetchProfileNotesData({
                contactId,
                campaignId,
                limit: paginationCurrent.limit,
                page: paginationCurrent.page + 1,
            });

            dispatch(appendProfileNotes({ contactId, notes: profileNotes }));
            dispatch(setPagination({ contactId, pagination }));
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to fetch profile notes next page' });
        }
    };

export const updateContactNotesCount =
    ({ contactId, countDiff }: { contactId: string; countDiff: number }) =>
    async (dispatch, getState) => {
        const state = getState();
        const contact = getContactById(state, contactId);

        const updatedContact = optimisticallyUpdateContactAttributesValue({
            contact,
            fieldName: 'notesCount',
            newValue: (contact.attributes.notesCount ?? 0) + countDiff,
        });

        dispatch(setContact(updatedContact));
    };

export const handleAddContactNote =
    ({
        campaignId,
        contactId,
        content,
    }: {
        campaignId: string;
        contactId: string;
        content: string;
    }): AppThunk<Promise<{ success: boolean; error?: string }>> =>
    async (dispatch, getState) => {
        dispatch(
            setRequestState({
                contactId,
                requestState: {
                    addNote: RequestState.InProgress,
                },
            }),
        );

        const pagination = getPagination(contactId)(getState());

        const query = qs.stringify({ contactId, campaignId });

        try {
            const profileNote = getDataFromResponse(
                await crmApiPost(`/notes?${query}`, {
                    data: {
                        content,
                        contactId,
                        campaignId,
                    },
                }),
            );

            dispatch(prependProfileNotes({ contactId, notes: [profileNote] }));

            // Update Contact Notes count
            dispatch(updateContactNotesCount({ contactId, countDiff: 1 }));

            // Update pagination count
            dispatch(
                setPagination({
                    contactId,
                    pagination: { ...pagination, count: pagination.count + 1 },
                }),
            );

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        addNote: RequestState.Success,
                    },
                }),
            );

            return { success: true };
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to add profile note' });

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        addNote: RequestState.Error,
                    },
                }),
            );

            return { success: false, error: 'add-note-network-error' };
        }
    };

export const handleEditContactNote =
    ({
        noteId,
        campaignId,
        contactId,
        content,
    }: {
        noteId: string;
        campaignId: string;
        contactId: string;
        content: string;
    }): AppThunk<Promise<{ success: boolean; error?: string }>> =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                contactId,
                requestState: {
                    editNote: RequestState.InProgress,
                },
            }),
        );

        const query = qs.stringify({ contactId, campaignId });

        try {
            const profileNote = getDataFromResponse(
                await crmApiPatch(`/notes/${noteId}?${query}`, {
                    data: { content },
                }),
            );

            dispatch(updateProfileNote({ contactId, note: profileNote }));

            // Reset editing state
            dispatch(setEditingIds({ [noteId]: false }));

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        editNote: RequestState.Success,
                    },
                }),
            );

            return { success: true };
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to edit profile note' });

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        editNote: RequestState.Error,
                    },
                }),
            );

            return { success: false, error: 'add-note-network-error' };
        }
    };

export const handleDeleteContactNote =
    ({
        noteId,
        campaignId,
        contactId,
    }: {
        noteId: string;
        campaignId: string;
        contactId: string;
    }): AppThunk<Promise<{ success: boolean }>> =>
    async (dispatch, getState) => {
        dispatch(
            setRequestState({
                contactId,
                requestState: {
                    deleteNote: RequestState.InProgress,
                },
            }),
        );

        const state = getState();
        const paginationCurrent = getPagination(contactId)(state);
        const hasMoreNotes = getHasMore(contactId)(state);

        const query = qs.stringify({ contactId, campaignId });

        try {
            await crmApiDelete(`/notes/${noteId}?${query}`);

            // Update Contact Notes count
            dispatch(updateContactNotesCount({ contactId, countDiff: -1 }));

            // Update notes list & pagination count
            if (hasMoreNotes) {
                const { profileNotes: nextPage, pagination } = await fetchProfileNotesData({
                    contactId,
                    campaignId,
                    limit: paginationCurrent.limit,
                    page: paginationCurrent.page,
                });

                const lastItem = last(nextPage);

                // Insert last item from new res of current page, to offset the missing note on next page fetch
                lastItem && dispatch(appendProfileNotes({ contactId, notes: [lastItem] }));
                dispatch(setPagination({ contactId, pagination }));
            }

            // Delete note from state
            dispatch(deleteProfileNote({ contactId, noteId }));

            dispatch(setDeletingDraftIds({ [noteId]: false }));

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        deleteNote: RequestState.Success,
                    },
                }),
            );

            return { success: true };
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to delete profile note' });

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        deleteNote: RequestState.Error,
                    },
                }),
            );

            return { success: false };
        }
    };

export const handleCloseNotesTab = (): AppThunk => async (dispatch) => {
    dispatch(resetEditingIds());
    dispatch(resetDeletingDraftIds());
};

export default profileNotesSlice.reducer;
