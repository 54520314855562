import { ConsoleLogLevel } from '../../types/util';

const ConsoleStyle = {
    [ConsoleLogLevel.Muted]: 'color: #AAAAAA;',
    [ConsoleLogLevel.Debug]: 'background-color: #AAAAAA; color: white; font-weight: bold;',
    [ConsoleLogLevel.Info]: 'background-color: #0074D9; color: white; font-weight: bold;',
    [ConsoleLogLevel.Warning]: 'background-color: #FFDC00; color: white; font-weight: bold;',
    [ConsoleLogLevel.Error]: 'background-color: #FF4136; color: white; font-weight: bold;',
} as const satisfies { [K in ConsoleLogLevel]: string };

/**
 * Helper for building styled console messages.
 */
export class ConsoleMessageBuilder {
    private message: string[] = [];
    private styles: string[] = [];

    public append(text: string, level?: ConsoleLogLevel): ConsoleMessageBuilder {
        if (level) {
            this.message.push(`%c${text}%c`);
            this.styles.push(ConsoleStyle[level]);
            this.styles.push('');
        } else {
            this.message.push(text);
        }

        return this;
    }

    public build(): [string, ...string[]] {
        return [this.message.join(''), ...this.styles];
    }
}
