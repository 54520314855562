import { getCurrentEntry } from '@/app/editor/engine/core/utils/history/getCurrentEntry';

import type {
    EditorEngineHistoryDefaultEntry,
    EditorEngineHistoryDefaultEntryCollection,
} from '@/app/editor/engine/core/types';

/**
 * Execute the handler with the current entry.
 *
 * todo(editorengine): update this to receive its parameters as an object.
 */
export const withCurrentEntry = <T>(
    /**
     * The handler to execute.
     */
    handler: ({
        entry,
        entriesInfo,
    }: {
        entry: EditorEngineHistoryDefaultEntry;
        entriesInfo: EditorEngineHistoryDefaultEntryCollection;
    }) => T,
    /**
     * The default return value.
     *
     * The type of this value should match the return type of the handler. If
     * no entry was found, this value will be returned and the handler will
     * not be executed.
     */
    defaultReturnValue: T,
    /**
     * The offset to apply relative to the current index.
     */
    offset = 0,
) => {
    return ({ entriesInfo }: { entriesInfo: EditorEngineHistoryDefaultEntryCollection }) => {
        const entry = getCurrentEntry({
            entriesInfo,
            offset,
        });

        if (!entry) {
            return defaultReturnValue;
        }

        return handler({ entry, entriesInfo: entriesInfo });
    };
};
