import { useState } from 'react';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import { cn } from '@/utils/cn';

import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    submit: () => void;
    text: string;
    invertSubmitValue?: boolean;
}

const LabeledToggleButton = ({ input, submit, text, invertSubmitValue }: Props) => {
    const { onChange, value } = input;
    const [checked, setChecked] = useState(invertSubmitValue ? !value : value);

    const handleChange = () => {
        const value = !checked;
        const actualValue = invertSubmitValue ? !value : value;
        onChange(actualValue);

        setChecked(value);

        if (submit) {
            setTimeout(submit);
        }
    };

    const toggleWrapperClasses = cn('h-5 w-8 rounded-xl bg-gray-200 transition-all', {
        'bg-blue-500': checked,
    });

    const toggleClasses = cn('ml-0.5 mt-0.5 size-4 rounded-full bg-white transition-all', {
        'ml-3.5': checked,
    });

    return (
        <FieldContainer>
            <button
                onClick={handleChange}
                className="flex h-12 w-full items-center justify-between rounded-lg bg-gray-100 px-4"
            >
                <p className="text-sm font-medium leading-6 text-gray-500">{text}</p>
                <div className={toggleWrapperClasses}>
                    <div className={toggleClasses} />
                </div>
            </button>
        </FieldContainer>
    );
};

export default LabeledToggleButton;
