import { Cog6ToothIcon } from '@heroicons/react/24/outline';

import Toggle from '@/ui/components/Toggle';
import { cn } from '@/utils/cn';

export interface Props {
    id: string;
    checked?: boolean;
    title: string;
    srTitle?: string;
    onEditClick?: (id: string) => void;
    onToggle?: (id: string, checked: boolean) => void;
}

const TogglePanel = ({ id, onEditClick, onToggle, checked, title, srTitle }: Props) => {
    const handleToggle = (checked: boolean) => {
        onToggle?.(id, checked);
    };

    const handleEditClick = () => {
        onEditClick?.(id);
    };

    return (
        <div className="mb-4 flex h-12 items-center rounded-lg bg-gray-100 px-4 text-gray-400">
            {onToggle && (
                <Toggle onChange={handleToggle} checked={!!checked} srTitle={srTitle ?? title} />
            )}
            <p className={cn('flex-1 text-sm', { 'pl-4': onToggle })}>{title}</p>
            {onEditClick && (
                <Cog6ToothIcon
                    className="size-5 cursor-pointer hover:text-gray-600"
                    onClick={handleEditClick}
                />
            )}
        </div>
    );
};

export default TogglePanel;
