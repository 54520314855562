import unescape from 'lodash/unescape';

import { INVALID_SCRIPT, MALFORMED_SCRIPT, removeTextNodes } from '../helper';

export const trustpilotDefaultValidator = (
    headContainer: HTMLDivElement,
    bodyContainer: HTMLDivElement,
    head: string,
    body: string,
) => {
    let headHasOnlyOneScript = false;
    let headHasTrustPilotSrc = false;

    let bodyOnlyOneDivWrapper = false;
    let bodyHasTemplateId = false;
    let bodyHasBusinessUnitId = false;
    let bodyHasNestedAnchor = false;
    let bodyAnchorHasTrustPilotSrc = false;

    headContainer.innerHTML = unescape(head)
        .trim()
        .replace(/\r?\n|\r/g, '');
    bodyContainer.innerHTML = unescape(body)
        .trim()
        .replace(/\r?\n|\r/g, '');

    removeTextNodes(headContainer);
    removeTextNodes(bodyContainer);

    if (headContainer.childNodes.length === 3) {
        headContainer.removeChild(headContainer.firstChild);
        headContainer.removeChild(headContainer.lastChild);
    } else if (headContainer.childNodes.length !== 1) {
        return {
            valid: false,
            message: MALFORMED_SCRIPT,
        };
    }

    if (bodyContainer.childNodes.length === 3) {
        bodyContainer.removeChild(bodyContainer.firstChild);
        bodyContainer.removeChild(bodyContainer.lastChild);
    } else if (bodyContainer.childNodes.length !== 1) {
        return {
            valid: false,
            message: MALFORMED_SCRIPT,
        };
    }

    if (headContainer.firstElementChild?.tagName === 'SCRIPT') {
        headHasOnlyOneScript = true;
    }

    if (
        headContainer.firstElementChild
            ?.getAttribute('src')
            ?.includes('widget.trustpilot.com/bootstrap')
    ) {
        headHasTrustPilotSrc = true;
    }

    if (bodyContainer.firstElementChild?.tagName === 'DIV') {
        bodyOnlyOneDivWrapper = true;
    }

    if (bodyContainer.firstElementChild?.getAttribute('data-template-id')) {
        bodyHasTemplateId = true;
    }

    if (bodyContainer.firstElementChild?.getAttribute('data-businessunit-id')) {
        bodyHasBusinessUnitId = true;
    }

    if (bodyContainer.firstElementChild?.firstElementChild?.tagName === 'A') {
        bodyHasNestedAnchor = true;
    }

    if (
        bodyContainer.firstElementChild?.firstElementChild
            ?.getAttribute('href')
            ?.includes('trustpilot.com')
    ) {
        bodyAnchorHasTrustPilotSrc = true;
    }

    return {
        valid:
            headHasOnlyOneScript &&
            headHasTrustPilotSrc &&
            bodyOnlyOneDivWrapper &&
            bodyHasTemplateId &&
            bodyHasBusinessUnitId &&
            bodyHasNestedAnchor &&
            bodyAnchorHasTrustPilotSrc,
        message: INVALID_SCRIPT,
    };
};
