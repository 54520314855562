import Cookie from 'js-cookie';

import { reportMessage } from '@/utils/sentry';

import { isProductionEnv } from './environments';

export const DEFAULT_EXPIRE = 1;
export const EXTENDED_EXPIRE = 30;

export const getDomainName = (hostName: string) => {
    if (
        process.env.NEXT_PUBLIC_WORKSPACE_DOMAIN &&
        hostName.indexOf(process.env.NEXT_PUBLIC_WORKSPACE_DOMAIN) > -1
    ) {
        return `.${process.env.NEXT_PUBLIC_WORKSPACE_DOMAIN}`;
    }

    return isProductionEnv() ? '.perspective.co' : hostName;
};

export const loadCookie = (key: string): string | void => {
    try {
        const value = Cookie.get(key);

        if (value) {
            return value;
        }
    } catch (error) {
        reportMessage({
            message: `Could not read cookie ${key}: ${error.message}`,
            source: 'cookies',
        });
    }
};

export const saveCookie = (key: string, value: string, expires = DEFAULT_EXPIRE) => {
    const domain = getDomainName(window?.location?.hostname);
    const options = {
        domain,
        expires,
    };

    try {
        Cookie.set(key, value, options);
    } catch (error) {
        reportMessage({
            message: `Could not save cookie ${key}: ${error.message}`,
            source: 'cookies',
        });
    }
};

export const removeCookie = (key: string) => {
    const domain = getDomainName(window?.location?.hostname);

    try {
        Cookie.remove(key, { domain });
    } catch (error) {
        reportMessage({
            message: `Could not remove cookie ${key}: ${error.message}`,
            source: 'cookies',
        });
    }
};
