import { NAME } from '@/app/editor/blocks/constants';

import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { getCampaignCookieSettings } from '@/app/campaigns/helpers';
import { getActiveCampaign } from '@/app/campaigns/models/campaigns';
import Box from '@/app/editor/blocks/components/_wrapper/Box';
import { getThemedOrCustomColor, getIsDark } from '@/app/editor/themes/helpers';
import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    termsText?: string;
    termsLink?: string;
    legalText?: string;
    legalLink?: string;
    branding: boolean;
    cookieOverlay: {
        layout: 'none' | 'explicit' | 'normal';
        language: 'en' | 'de';
    };
}

interface LinkProps {
    text: string;
    withConnector?: boolean;
    isDark?: boolean;
}

interface ConnectorProps {
    isDark?: boolean;
}

const ConnectorElement = ({ isDark }: ConnectorProps) => (
    <span className={isDark ? 'text-white/50' : 'text-black/50'}>&middot;</span>
);

const LinkElement = ({ text, withConnector, isDark }: LinkProps) => (
    <div className="flex items-center">
        <div className={cn('px-1.5 text-xs underline', isDark ? 'text-white/50' : 'text-black/50')}>
            {text}
        </div>
        {withConnector && <ConnectorElement isDark={isDark} />}
    </div>
);

const Component: FC<Props> = ({
    artBoardIndex,
    termsLink,
    termsText,
    legalLink,
    legalText,
    branding,
    box,
}) => {
    const { t } = useTranslation(NAME);
    const campaign = useAppSelector(getActiveCampaign);
    const theme = useAppSelector(getPreviewOrActiveTheme);
    const bgColor = getThemedOrCustomColor(box.backgroundColor, 'backgroundColor', theme);
    const cookieSettings = getCampaignCookieSettings(campaign);

    const hasTerms = !!(termsLink && termsText);
    const hasLegal = !!(legalLink && legalText);
    const hasCookies = cookieSettings.layout !== 'none';
    const cookieLanguage = cookieSettings.language;
    const noLinks = !hasTerms && !hasLegal && !hasCookies;
    const isDark = getIsDark(bgColor);

    return (
        <Box box={box} artBoardIndex={artBoardIndex}>
            <div className="flex flex-col items-center justify-center font-sans font-normal">
                <div className="flex">
                    {hasLegal && (
                        <LinkElement
                            text={legalText}
                            withConnector={hasTerms || hasCookies}
                            isDark={isDark}
                        />
                    )}
                    {hasTerms && (
                        <LinkElement text={termsText} withConnector={hasCookies} isDark={isDark} />
                    )}
                    {hasCookies && (
                        <LinkElement
                            text={t(
                                cookieSettings.layout === 'explicit'
                                    ? 'manage-cookies'
                                    : 'cookie-policy',
                                { lng: cookieLanguage },
                            )}
                            isDark={isDark}
                        />
                    )}
                </div>
                {branding && (
                    <div
                        className={cn(
                            'flex items-center rounded-lg px-3 py-2.5 text-sm',
                            noLinks ? 'mt-0' : 'mt-6',
                            isDark ? 'bg-white/20' : 'bg-black/5',
                        )}
                    >
                        <Image
                            width={20}
                            height={20}
                            alt="Perspective Logo"
                            src="/logo_64x64.png"
                        />
                        <span className={cn('pl-3', isDark ? 'text-white/50' : 'text-black/50')}>
                            We use Perspective
                        </span>
                    </div>
                )}
            </div>
        </Box>
    );
};

Component.displayName = 'Footer';

export default Component;
