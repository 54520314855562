import { cn } from '@/utils/cn';

export interface Props {
    text: string;
    className?: string;
}

export const Label = ({ className, text }: Props) => {
    return <div className={cn('mb-1.5 text-xs text-gray-400', className)}>{text}</div>;
};
