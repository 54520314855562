import { createSlice } from '@reduxjs/toolkit';

import { getWorkspaceById } from '@/app/workspaces/models/workspaces';
import { apiGet, handleRuntimeError } from '@/core/api';
import { resourceArrayToObject } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';

import type { FaviconResource } from '@/app/funnelSettings/favicon/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    favicons: { [faviconId: string]: FaviconResource };
}

const initialState: State = {
    loading: false,
    favicons: EMPTY_OBJECT,
};

export const fetchFaviconSlice = createSlice({
    name: `funnelSettings/${NAME}/fetch`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        addFavicons(state, action: PayloadAction<{ [faviconId: string]: FaviconResource }>) {
            return {
                ...state,
                favicons: {
                    ...state.favicons,
                    ...action.payload,
                },
            };
        },
        setFavicons(state, action: PayloadAction<{ [faviconId: string]: FaviconResource }>) {
            return {
                ...state,
                favicons: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, addFavicons, setFavicons, reset } = fetchFaviconSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.fetchFaviconReducer?.loading;

export const getFavicons = (state: AppState) => state[NAME]?.fetchFaviconReducer?.favicons;

export const getFaviconsAsArray = (state: AppState) => {
    const favicons = state[NAME]?.fetchFaviconReducer?.favicons;

    return Object.keys(favicons).map((faviconId: string) => favicons[faviconId]);
};

// === Thunks ======

export const fetchUserFavicons =
    (workspaceId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const workspace = getWorkspaceById(state, workspaceId);

        try {
            const response = await apiGet<ResponseData>(`/workspaces/${workspace.id}/favicons`);
            const favicons = resourceArrayToObject(response?.data?.data as FaviconResource[]);

            dispatch(addFavicons(favicons));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching favicons failed:' });
        }
    };

export default fetchFaviconSlice.reducer;
