import Tooltip from '@/ui/components/Tooltip';

import type { ItemData } from './index';
import type { ReactNode, MouseEvent } from 'react';

export interface Props {
    item: ItemData;
    activeItem?: string;
    onClick: (item: ItemData) => void;
    children?: ReactNode;
}

export const Item = ({ children, item, activeItem, onClick }: Props) => {
    const radioClassName = `bump flex items-center justify-center flex-1 transition
    ${
        activeItem === item.name || item.active
            ? 'text-blue-500'
            : 'text-gray-400 hover:text-gray-600'
    }`;

    const handleClick = (event: MouseEvent) => {
        event.preventDefault();

        onClick(item);
    };

    const preventDefault = (event: MouseEvent) => {
        event.preventDefault();
    };

    return (
        <Tooltip content={item.tooltip} hotkeys={item.hotkey}>
            <button
                className={radioClassName}
                onClick={handleClick}
                onMouseDown={preventDefault}
                type="button"
            >
                {children}
            </button>
        </Tooltip>
    );
};

export default Item;
