import { EditorEngineDropPosition } from '@/app/editor/engine/core/types';
import { EditorEngineOrientation } from '@/app/editor/engine/core/types/util';

/**
 * Given a position, returns the orientation.
 */
export const getOrientationFromPosition = (position: EditorEngineDropPosition) => {
    if (position === EditorEngineDropPosition.Left || position === EditorEngineDropPosition.Right) {
        return EditorEngineOrientation.Horizontal;
    }

    return EditorEngineOrientation.Vertical;
};
