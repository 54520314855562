import { useMemo, useRef, useState } from 'react';

import type {
    EditorEngineDefaultTypeInput,
    EditorEngineDropCandidate,
    EditorEngineDropCandidateSetter,
} from '@/app/editor/engine/core/types';

/**
 * Holds the state for the drag and drop operations.
 *
 * A drop candidate refers to:
 * - The node that is being dragged
 * - The node that is being targeted
 * - The position where the node will be dropped relative to the target node
 * - The handler which will be executed when the drop is completed
 */
export const useEditorEngineDragAndDropState = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    documentManager,
    nodeManager,
}: {
    documentManager: TEditorEngineTypeInput['DocumentManager'];
    nodeManager: TEditorEngineTypeInput['NodeManager'];
}) => {
    const candidateIdRef = useRef('');
    const [dropCandidate, setDropCandidate] =
        useState<EditorEngineDropCandidate<TEditorEngineTypeInput> | null>(null);

    const enhancedSetDropCandidate = ((input) => {
        if (!input) {
            setDropCandidate(null);
            candidateIdRef.current = '';

            return;
        }

        const { candidateId, draggableConfiguration, preview } = input;

        if (candidateId === candidateIdRef.current) {
            return;
        }

        candidateIdRef.current = candidateId;

        setDropCandidate({
            executionConfiguration: draggableConfiguration.executeDrop({
                documentManager,
                nodeManager,
                preview,
            }),
            preview,
        });
    }) satisfies EditorEngineDropCandidateSetter<TEditorEngineTypeInput>;

    return useMemo(() => {
        return {
            dropCandidateState: {
                dropCandidate,
                setDropCandidate: enhancedSetDropCandidate,
            },
        };
    }, [dropCandidate, enhancedSetDropCandidate]);
};
