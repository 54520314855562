import { NAME } from '@/app/editor/blocks/constants';

import { ChevronRightIcon } from '@heroicons/react/24/outline';
import times from 'lodash/times';
import { useTranslation } from 'next-i18next';

import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';
import { getNow } from '@/utils/common';
import { useDateFormatIntl } from '@/utils/hooks/useDateFormat';

import { getFirstWeekdayOfMonthIndex, formatDateCell } from './helper';

import type { Language } from 'types/generic';

export interface Props {
    language: Language;
    pastDatesDisabled: boolean;
    weekendsDisabled: boolean;
}

const DatePickerPreview = ({ language, pastDatesDisabled, weekendsDisabled }: Props) => {
    const { t } = useTranslation(NAME);
    const dayBorderRadiusClass = useBorderRadius('datePickerDay');
    const buttonBorderRadiusClass = useBorderRadius('button');
    const today = getNow();
    const todaysDate = today.getDate();

    const monthYearTitle = useDateFormatIntl(
        today,
        {
            month: 'long',
            year: 'numeric',
        },
        {
            locale: language,
        },
    );

    const weekdayList: string[] = t('date-picker-weekdays', { lng: language, returnObjects: true });

    const firstWeekdayOfMonthIndex = getFirstWeekdayOfMonthIndex(today);

    const prevMonthPaddingNumber = firstWeekdayOfMonthIndex > 0 ? firstWeekdayOfMonthIndex - 1 : 6;
    const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

    const clearBtnLabel = t('clear', { lng: language });
    const confirmBtnLabel = t('confirm', { lng: language });

    return (
        <div className="pointer-events-none flex w-full flex-col">
            {/* Month & Year Label */}
            <div className="flex w-full items-center justify-between">
                <div className="flex size-8 items-center justify-center rounded bg-gray-50">
                    <ChevronRightIcon className="size-5 rotate-180 text-gray-600" />
                </div>
                <p className="flex-1 text-center font-sans text-sm font-medium text-gray-600">
                    {monthYearTitle}
                </p>
                <div className="flex size-8 items-center justify-center rounded bg-gray-50">
                    <ChevronRightIcon className="size-5 text-gray-600" />
                </div>
            </div>

            {/* Weekdays */}
            <div className="mb-3 mt-4 flex h-7">
                {weekdayList &&
                    weekdayList.map((weekday) => (
                        <span
                            key={weekday}
                            className="flex-1 text-center font-sans text-sm font-medium text-gray-600"
                        >
                            {weekday}
                        </span>
                    ))}
            </div>

            {/* Calendar */}
            <div className="grid w-full grid-cols-7 gap-1.5">
                {prevMonthPaddingNumber > 0 &&
                    times(prevMonthPaddingNumber, (idx) => (
                        <span key={`prev-month-padding-${idx}`} className="invisible h-[37px]" />
                    ))}
                {daysInMonth &&
                    times(daysInMonth, (idx) => {
                        const currDate = idx + 1;
                        const isToday = todaysDate === currDate;
                        // +6 to add some padding for dates in the beginning of month
                        const isWeekend =
                            (currDate + prevMonthPaddingNumber) % 7 === 6 ||
                            (currDate + prevMonthPaddingNumber) % 7 === 0;
                        const disabled =
                            (pastDatesDisabled && todaysDate > currDate) ||
                            (weekendsDisabled && isWeekend);

                        const style = cn(
                            'flex h-[37px] w-[37px] items-center justify-center font-sans text-sm',
                            dayBorderRadiusClass,
                            {
                                'bg-blue-50 text-blue-500': isToday && !disabled,
                                'bg-transparent text-gray-300 line-through': disabled,
                                'bg-gray-50 text-gray-600': !disabled && !isToday,
                            },
                        );

                        return (
                            <span key={`month-day-${idx}`} className={style}>
                                {formatDateCell(currDate)}
                            </span>
                        );
                    })}
            </div>

            {/* Buttons */}
            <div className="mt-4 flex gap-2">
                <div
                    className={cn(
                        'flex h-10 w-full items-center justify-center bg-gray-100 px-3 py-2 text-center font-sans text-sm font-medium text-gray-500',
                        buttonBorderRadiusClass,
                    )}
                >
                    {clearBtnLabel}
                </div>
                <div
                    className={cn(
                        'flex h-10 w-full items-center justify-center bg-blue-500 px-3 py-2 text-center font-sans text-sm font-medium text-white',
                        buttonBorderRadiusClass,
                    )}
                >
                    {confirmBtnLabel}
                </div>
            </div>
        </div>
    );
};

export default DatePickerPreview;
