import Dropdown from '@/ui/components/_BlockEditFields/Dropdown';
import { currencies, getCurrencyIcon } from '@/utils/currency/currencies';

import type { DropdownOption } from '@/ui/types';
import type { WrappedFieldInputProps } from 'redux-form';

interface Props {
    input: WrappedFieldInputProps;
    onChangeSubmit: () => void;
    tooltip?: string;
}

export const DEFAULT_CURRENCY_CODE = 'eur';

const options: DropdownOption[] = currencies.map(({ code, name, icon }) => ({
    key: name,
    value: code,
    icon,
}));

export const CurrencyDropdown = ({ input, onChangeSubmit, tooltip }: Props) => {
    const { onChange } = input;
    const value = input.value ? input.value : DEFAULT_CURRENCY_CODE;

    const handleChange = (val: string) => {
        onChange(val);
        setTimeout(onChangeSubmit);
    };

    return (
        <Dropdown
            icon={({ className }) => getCurrencyIcon(value, className)}
            options={options}
            value={value}
            onChange={handleChange}
            dropdownClass="z-30"
            stickySelectedOption
            tooltip={tooltip}
        />
    );
};
