import { createSlice } from '@reduxjs/toolkit';

import { RequestState } from '@/app/workspaces/types';
import { crmApiDelete, crmApiGet, crmApiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';
import { buildWorkspaceStatusTemplateRoute } from '../utils/url';

import type { CreateStatusTemplateData, StatusTemplate } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

export type ModalViewType = 'start' | 'status-replacement' | 'use-template';
export type ModalViewProps = Record<string, string | number> & {
    status?: string;
    color?: string;
};
export type ModalPreviewsView = { view: ModalViewType; props?: ModalViewProps };

type WorkspaceStatusTemplatesRequestState = Partial<
    Record<'fetchTemplates' | 'addTemplate' | 'deleteTemplate', RequestState>
>;

const DEFAULT_REQUEST_STATE: WorkspaceStatusTemplatesRequestState = {
    fetchTemplates: RequestState.Idle,
    addTemplate: RequestState.Idle,
    deleteTemplate: RequestState.Idle,
};

export interface State {
    requestState: WorkspaceStatusTemplatesRequestState;
    workspaceStatusTemplates: StatusTemplate[] | null;
}

const initialState: State = {
    workspaceStatusTemplates: null,
    requestState: EMPTY_OBJECT,
};

const workspaceStatusTemplatesSlice = createSlice({
    name: `${NAME}/workspace-status-template`,
    initialState,
    reducers: {
        setRequestState(state, action: PayloadAction<WorkspaceStatusTemplatesRequestState>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        setWorkspaceStatusTemplates(state, action: PayloadAction<StatusTemplate[]>) {
            return {
                ...state,
                workspaceStatusTemplates: [
                    ...(state.workspaceStatusTemplates ?? []),
                    ...action.payload,
                ],
            };
        },
        replaceWorkspaceStatusTemplate(
            state,
            action: PayloadAction<[template: StatusTemplate, templateId: string]>,
        ) {
            const [template, templateId] = action.payload;

            const newTemplates = state.workspaceStatusTemplates
                ? [...state.workspaceStatusTemplates]
                : [];
            const templateIndex =
                state.workspaceStatusTemplates?.findIndex(
                    (currentTemplate) => currentTemplate.id === templateId,
                ) ?? 0;
            newTemplates[templateIndex] = template;

            return {
                ...state,
                workspaceStatusTemplates: newTemplates,
            };
        },
        pushWorkspaceStatusTemplate(state, action: PayloadAction<StatusTemplate>) {
            return {
                ...state,
                workspaceStatusTemplates: [
                    ...(state.workspaceStatusTemplates ?? []),
                    action.payload,
                ],
            };
        },
        filterWorkspaceStatusTemplate(state, action: PayloadAction<string>) {
            return {
                ...state,
                workspaceStatusTemplates:
                    state.workspaceStatusTemplates?.filter(({ id }) => id !== action.payload) ??
                    null,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// Actions
export const {
    setRequestState,
    setWorkspaceStatusTemplates,
    replaceWorkspaceStatusTemplate,
    pushWorkspaceStatusTemplate,
    filterWorkspaceStatusTemplate,
    reset,
} = workspaceStatusTemplatesSlice.actions;

// Selectors
export const getWorkspaceStatusTemplates = (state: AppState) =>
    state[NAME].workspaceStatusTemplatesReducer.workspaceStatusTemplates;
export const getRequestState = () => (state: AppState) =>
    state[NAME].workspaceStatusTemplatesReducer.requestState || DEFAULT_REQUEST_STATE;

// Thunks
export const fetchWorkspaceStatusTemplates = (): AppThunk => async (dispatch) => {
    dispatch(
        setRequestState({
            fetchTemplates: RequestState.InProgress,
        }),
    );

    try {
        const response = await crmApiGet(buildWorkspaceStatusTemplateRoute.get());

        const statusTemplateData = response.data.data;
        const statusTemplates = statusTemplateData.map(({ id, attributes }) => ({
            id,
            ...attributes,
        }));

        dispatch(setWorkspaceStatusTemplates(statusTemplates));

        dispatch(
            setRequestState({
                fetchTemplates: RequestState.Success,
            }),
        );
    } catch (error) {
        handleRuntimeError(error, { message: 'Failed to fetch workspace status templates' });
        dispatch(
            setRequestState({
                fetchTemplates: RequestState.Error,
            }),
        );
    }
};

export const addWorkspaceStatusTemplate =
    (newStatusTemplate: CreateStatusTemplateData): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                addTemplate: RequestState.InProgress,
            }),
        );

        try {
            dispatch(
                pushWorkspaceStatusTemplate({
                    id: 'tmp-id',
                    ...newStatusTemplate,
                }),
            );

            const responseData = getDataFromResponse(
                await crmApiPost(buildWorkspaceStatusTemplateRoute.post(), {
                    data: newStatusTemplate,
                }),
            );

            const newTemplate = {
                id: responseData.id,
                ...newStatusTemplate,
            };

            dispatch(replaceWorkspaceStatusTemplate([newTemplate, 'tmp-id']));

            dispatch(
                setRequestState({
                    addTemplate: RequestState.Success,
                }),
            );

            return { success: true };
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to add workspace status template' });

            dispatch(
                setRequestState({
                    addTemplate: RequestState.Error,
                }),
            );

            return { success: false, error: 'add-workspace-template-network-error' };
        }
    };

export const removeWorkspaceStatusTemplate =
    (templateId: string): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                deleteTemplate: RequestState.InProgress,
            }),
        );

        try {
            dispatch(filterWorkspaceStatusTemplate(templateId));

            await crmApiDelete(buildWorkspaceStatusTemplateRoute.delete(templateId));

            dispatch(
                setRequestState({
                    deleteTemplate: RequestState.Success,
                }),
            );

            return { success: true };
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to delete workspace status template' });

            dispatch(
                setRequestState({
                    deleteTemplate: RequestState.Error,
                }),
            );

            return { success: false, error: 'delete-workspace-template-network-error' };
        }
    };

export const workspaceStatusTemplatesReducer = workspaceStatusTemplatesSlice.reducer;
