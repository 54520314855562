import { reduxForm } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({ blockId, handleSubmit, activeTheme }) => {
    return (
        <BoxSettings
            blockId={blockId}
            activeTheme={activeTheme}
            handleSubmit={handleSubmit}
            hiddenHeading
            hasBgImage
        />
    );
};

Form.displayName = 'GridRowEditForm';

export default reduxForm({})(Form);
