import { createSlice } from '@reduxjs/toolkit';

import { getCompany } from '@/app/company/models/company';
import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import { NAME } from '../constants';

import type { CompanyUsage } from '@/app/company/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    usage: CompanyUsage;
}

const initialState: State = {
    usage: null,
};

export const usageSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setUsage(state, action: PayloadAction<CompanyUsage>) {
            return {
                ...state,
                usage: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setUsage, reset } = usageSlice.actions;

// === Selectors ======

export const getCompanyUsage = (state: AppState) => state[NAME]?.usage?.usage;

// === Thunks ======

export const fetchUsage = (): AppThunk => async (dispatch, getState) => {
    const company = getCompany(getState());

    if (!company) {
        return;
    }

    try {
        const response = await apiGet<ResponseData<CompanyUsage>>(`/companies/${company.id}/usage`);

        dispatch(setUsage(getDataFromResponse(response)));
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'fetching usages failed:' });
    }
};

export default usageSlice.reducer;
