import Text from '@/app/editor/blocks/components/Text/Component';
import { getCleanIconId } from '@/app/editor/blocks/helpers';
import { getColorByLightness, getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

import type {
    Alignment,
    DefaultBlockComponentProps,
    GlobalTextSize,
} from '@/app/editor/blocks/types';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    isMultipleChoiceAnswer: boolean;
    wysiwyg: RawDraftContentState;
    align: Alignment;
    color: string;
    backgroundColor?: string;
    size: GlobalTextSize;
    icon?: string;
    showIcon: boolean;
}

const Component: FC<Props> = ({
    blockId,
    activeTheme,
    isMultipleChoiceAnswer,
    wysiwyg,
    align,
    color,
    size,
    icon,
    showIcon,
    backgroundColor,
}) => {
    const textAnswerBorderRadius = useBorderRadius('choiceItem');
    const bgColor = getThemedOrCustomColor(
        backgroundColor,
        'formFieldBackgroundColor',
        activeTheme,
    );
    const textColor = getColorByLightness(color, bgColor);

    const cleanIconId = getCleanIconId(icon);

    return (
        <div
            className={cn(
                'flex items-center border border-gray-400/25 p-4 shadow',
                textAnswerBorderRadius,
            )}
            style={{
                background: bgColor,
            }}
        >
            {showIcon && icon && (
                <div className="text-zero">
                    {/* @ts-ignore */}
                    <em-emoji id={cleanIconId} size="20px" />
                </div>
            )}
            <div className={cn('flex-1', { 'pl-4': showIcon })}>
                <Text
                    wysiwyg={wysiwyg}
                    blockId={blockId}
                    color={textColor}
                    align={align}
                    globalSize={size}
                />
            </div>
            {isMultipleChoiceAnswer && <div className="size-6 rounded-full border-2" />}
        </div>
    );
};

Component.displayName = 'QuestionTextAnswer';

export default Component;
