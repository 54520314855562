import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import ToggleButton from '@/ui/components/_BlockEditFields/ToggleButton';

import type { FieldInputProps } from 'react-final-form';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps | FieldInputProps<boolean>;
    submit?: (checked: boolean) => void;
    formSubmit?: () => void;
}

const FrameToggle = ({ input, submit, formSubmit }: Props) => {
    const { t } = useTranslation(NAME);
    const { onChange } = input;

    const handleToggle = (checked: boolean) => {
        onChange(checked);

        if (submit) {
            setTimeout(() => submit(checked));
        }

        if (formSubmit) {
            setTimeout(formSubmit);
        }
    };

    return (
        <FieldContainer>
            <ToggleButton
                checked={input.value}
                onToggle={handleToggle}
                icon="frame"
                tooltip={t('frame')}
            />
        </FieldContainer>
    );
};

export default FrameToggle;
