import { apiPost, treeApiPost } from '@/core/api';

import type { FetchLoginBody } from './types';
import type { UserResource } from '../user/types';
import type { ResponseData } from '@/core/api/types';

export const loginRequest = async (credentials: FetchLoginBody, useLemonTree = false) => {
    if (useLemonTree) {
        return treeApiPost<ResponseData<UserResource>>('/login', {
            data: credentials,
        });
    }

    return apiPost<ResponseData<UserResource>>('/login', credentials);
};

export const refreshTokenRequest = async (refreshToken: string) => {
    return apiPost('/login/refresh', { refreshToken });
};
