import { arrayMove } from '@dnd-kit/sortable';
import { createSlice } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';

import { getContactById } from './contacts';
import {
    getKanbanColumnOrder,
    handleKanbanContactStatusChange,
    setKanbanColumnOrder,
} from './kanban';
import { getSchema, updateSchemaProperty } from './schema';
import { NAME } from '../constants';
import { getContactStatus, getSchemaProperty, getStatusSchema } from '../helpers';
import { UniqueFieldName } from '../types';

import type { UpdateContactValue } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { Active, Over } from '@dnd-kit/core';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface KanbanDndState {
    draggedContactId?: string;
    draggedColumn?: string;
    dragOverColumn?: string;
}

const initialState: KanbanDndState = {
    draggedContactId: null,
    draggedColumn: null,
    dragOverColumn: null,
};

const kanbanDndSlice = createSlice({
    name: `${NAME}/kanbanDnd`,
    initialState,
    reducers: {
        setDraggedContactId(state, action: PayloadAction<string>) {
            return {
                ...state,
                draggedContactId: action.payload,
            };
        },
        setDraggedColumn(state, action: PayloadAction<string>) {
            return {
                ...state,
                draggedColumn: action.payload,
            };
        },
        setDragOverColumn(state, action: PayloadAction<string | undefined>) {
            return {
                ...state,
                dragOverColumn: action.payload,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======
export const { setDraggedContactId, setDraggedColumn, setDragOverColumn, reset } =
    kanbanDndSlice.actions;

// === Selectors ======
export const getDraggedContactId = (state: AppState) =>
    state[NAME].kanbanDndReducer.draggedContactId;
export const getDraggedColumn = (state: AppState) => state[NAME].kanbanDndReducer.draggedColumn;
export const getDragOverColumn = (state: AppState) => state[NAME].kanbanDndReducer.dragOverColumn;

// === Thunks ======
export const handleContactDragStart =
    ({ active }: { active: Active }): AppThunk =>
    (dispatch) => {
        const activeContactStatus = active?.data?.current?.status;

        dispatch(setDraggedContactId(active?.id as string));
        dispatch(setDragOverColumn(activeContactStatus));
    };

export const handleContactDragOver =
    ({ over }: { over: Over }): AppThunk =>
    async (dispatch) => {
        const columnStatus = over?.data?.current?.status;

        dispatch(setDragOverColumn(columnStatus));
    };

export const handleContactDragCancel = (): AppThunk => async (dispatch) => {
    dispatch(setDraggedContactId(null));
    dispatch(setDragOverColumn(null));
};

export const handleContactDragEnd =
    ({ updateContactValue }: { updateContactValue: UpdateContactValue }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);
        const dragOverColumn = getDragOverColumn(state);
        const draggedContactId = getDraggedContactId(state);
        const defaultContactStatus = getStatusSchema(schema)?.defaultValue;
        const activeContact = getContactById(state, draggedContactId);
        const activeContactOriginalStatus = getContactStatus(activeContact, defaultContactStatus);
        const isOverSelf = dragOverColumn === activeContactOriginalStatus;

        if (!!dragOverColumn && !isOverSelf) {
            // Update Kanban contactIds on 'status' change
            // This should happen on `updateContactValue` optimistic update, but it is not being
            // called instantly, causing a small shift in the placement of the item. As soon as
            // contacts state is fully managed by react-query the problem should be solved.
            dispatch(
                handleKanbanContactStatusChange({
                    contactId: activeContact.id,
                    originalStatus: getContactStatus(activeContact),
                    newStatus: dragOverColumn,
                }),
            );

            updateContactValue({
                contact: activeContact,
                fieldName: UniqueFieldName.status,
                newValue: dragOverColumn,
                skipKanbanUpdate: true,
            });
        }

        dispatch(setDraggedContactId(null));
        dispatch(setDragOverColumn(null));
    };

export const handleColumnDragEnd =
    ({ over, campaignId }: { over: Over; campaignId: string }): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);
        const draggedColumn = getDraggedColumn(state);
        const columnOrder = getKanbanColumnOrder(state);
        const overColumn = over?.data?.current?.status;
        const statusOptions = getSchemaProperty(schema, UniqueFieldName.status)?.options;

        if (overColumn !== draggedColumn) {
            const draggedColumnIndex = columnOrder.indexOf(draggedColumn);
            const overColumnIndex = columnOrder.indexOf(overColumn);

            if (draggedColumnIndex >= 0 && overColumnIndex >= 0) {
                const sortedColumns = arrayMove(columnOrder, draggedColumnIndex, overColumnIndex);

                const sortedStatusOptions = sortBy(statusOptions, (option) =>
                    sortedColumns.indexOf(option.value),
                );

                dispatch(setKanbanColumnOrder(sortedColumns));

                // Update in DB
                dispatch(
                    updateSchemaProperty({
                        fieldName: UniqueFieldName.status,
                        fieldToUpdate: 'options',
                        newValue: sortedStatusOptions,
                        campaignId,
                    }),
                );
            }
        }

        dispatch(setDraggedColumn(null));
    };

export default kanbanDndSlice.reducer;
