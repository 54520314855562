import { useMemo } from 'react';

import { PerspectiveEditorEnginePreviewAwareNodeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';
import { getSequenceRenderer } from '@/app/editor/engine/core/components/view/renderers/getSequenceRenderer';

import type { PerspectiveEditorEnginePreviewAwareNodeRendererProps } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';
import type { GetSequenceRendererArguments } from '@/app/editor/engine/core/components/view/renderers/getSequenceRenderer';

type Props = {} & Omit<PerspectiveEditorEnginePreviewAwareNodeRendererProps, 'nodes' | 'renderer'> &
    GetSequenceRendererArguments;

/**
 * Renders a sequence of nodes. This is a wrapper around the
 * `PerspectiveEditorEnginePreviewAwareNodeRenderer`, so it can be used within
 * the `isPreviewContext` context to render block previews when needed.
 */
export const PerspectiveEditorEnginePreviewAwareSequenceRenderer = ({
    parentId,
    className,
    sortingStrategy,
    ...rest
}: Props) => {
    const renderer = useMemo(
        () =>
            getSequenceRenderer({
                className,
                sortingStrategy,
            }),
        [className, sortingStrategy],
    );

    return (
        <PerspectiveEditorEnginePreviewAwareNodeRenderer
            parentId={parentId}
            renderer={renderer}
            {...rest}
        />
    );
};
