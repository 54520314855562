export interface Props {
    className: string;
}

const FrameIcon = ({ className }: Props) => {
    return (
        <div className={className}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 14 14"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2 9H0V12.5C0 13.3284 0.671573 14 1.5 14H5V12H2.5C2.22386 12 2 11.7761 2 11.5V9ZM0 5H2V2.5C2 2.22386 2.22386 2 2.5 2H5V0H1.5C0.671573 0 0 0.671573 0 1.5V5ZM12 11.5C12 11.7761 11.7761 12 11.5 12H9V14H12.5C13.3284 14 14 13.3284 14 12.5V9H12V11.5ZM9 0V2H11.5C11.7761 2 12 2.22386 12 2.5V5H14V1.5C14 0.671573 13.3284 0 12.5 0H9Z"
                    fill="inherit"
                />
            </svg>
        </div>
    );
};

export default FrameIcon;
