import { EditorEngineHistoryEntryPositioningType } from '@/app/editor/engine/core/types';
import { indexOfEntry } from '@/app/editor/engine/core/utils/history/indexOfEntry';

import type { EditorEngineHistoryDefaultEntryCollection } from '@/app/editor/engine/core/types';

/**
 * Given an entry ID and the entries information, get the positioning of the
 * entry in the history.
 */
export const getHistoryEntryPositioning = ({
    entryId,
    entriesInfo,
}: {
    /**
     * The ID of the entry to get the positioning of.
     */
    entryId: string;
    /**
     * Information about the entries.
     */
    entriesInfo: EditorEngineHistoryDefaultEntryCollection;
}) => {
    const index = indexOfEntry({
        entryId,
        entries: entriesInfo.entries,
    });

    if (index === -1) {
        return EditorEngineHistoryEntryPositioningType.NotFound;
    }

    if (index === entriesInfo.currentIndex) {
        return EditorEngineHistoryEntryPositioningType.Current;
    }

    if (index < entriesInfo.currentIndex) {
        return EditorEngineHistoryEntryPositioningType.Past;
    }

    return EditorEngineHistoryEntryPositioningType.Future;
};
