export const getFirstWeekdayOfMonthIndex = (today: Date) => {
    const newDate = new Date(today);
    newDate.setDate(1);

    return newDate.getDay();
};

export const formatDateCell = (date: number) => {
    const dateCellString = date.toString();

    return dateCellString.length > 1 ? dateCellString : `0${dateCellString}`;
};
