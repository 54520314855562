import { createSlice } from '@reduxjs/toolkit';

import { setArtboardSizeByCampaignIdInLS } from '@/app/editor/editor/helper';
import { ArtBoardSize } from '@/app/editor/editor/types';

import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    size: Record<string, ArtBoardSize>;
    dragInProgress: boolean;
}

const initialState: State = {
    size: {},
    dragInProgress: false,
};

export const artBoardSlice = createSlice({
    name: `editor/${NAME}/artboard`,
    initialState,
    reducers: {
        setSize: (state, action: PayloadAction<{ campaignId: string; size: ArtBoardSize }>) => {
            return {
                ...state,
                size: {
                    ...state.size,
                    [action.payload.campaignId]: action.payload.size,
                },
            };
        },
        setDragInProgress: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                dragInProgress: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setSize, setDragInProgress, reset } = artBoardSlice.actions;

// === Selectors ======

export const getArtBoardSizeByCampaignId = (state: AppState, campaignId: string): ArtBoardSize =>
    state[NAME]?.artBoardReducer?.size[campaignId] || ArtBoardSize.MOBILE;

// TODO: Not used yet, but come in handy when we need to know if the user is dragging something in the artboard
export const getDragInProgress = (state: AppState): boolean =>
    state[NAME]?.artBoardReducer?.dragInProgress;

// === Thunks ======

export const setArtboardSize =
    (campaignId: string, size: ArtBoardSize): AppThunk =>
    async (dispatch) => {
        dispatch(setSize({ campaignId, size }));

        // Update LS
        setArtboardSizeByCampaignIdInLS(campaignId, size);
    };

export default artBoardSlice.reducer;
