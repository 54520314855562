import { rectSortingStrategy, verticalListSortingStrategy } from '@dnd-kit/sortable';

import Box from '@/app/editor/blocks/components/_wrapper/Box';
import Text from '@/app/editor/blocks/components/Text/Component';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { PerspectiveEditorEnginePreviewAwareSequenceRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareSequenceRenderer';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';

import type { Alignment, DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
    color: string;
    fieldName: string;
    wysiwyg: RawDraftContentState;
}

const Component: FC<Props> = ({
    artBoardIndex,
    box,
    blockId,
    align,
    wysiwyg,
    color,
    activeTheme,
    isPreview,
    isDragged,
}) => {
    const { isVerticalOnly, isInColumn } = useLayoutInfo(blockId, isPreview);

    const wrapperClass = isVerticalOnly
        ? 'mx-auto flex max-w-md flex-col space-y-4'
        : 'grid grid-cols-2 gap-4';

    return (
        <Box
            box={getBoxAttributes({ box, isInColumn })}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            <div className="mb-6">
                <Text
                    align={align}
                    wysiwyg={wysiwyg}
                    color={getThemedOrCustomColor(color, 'fontColor', activeTheme)}
                    blockId={blockId}
                />
            </div>

            <PerspectiveEditorEnginePreviewAwareSequenceRenderer
                parentId={blockId}
                className={wrapperClass}
                sortingStrategy={isVerticalOnly ? verticalListSortingStrategy : rectSortingStrategy}
            />
        </Box>
    );
};

Component.displayName = 'QuestionText';

export default Component;
