import {
    listItemEmojiPaddingMap,
    listItemVisualSizeVerticalMap,
    listItemVisualSizeHorizontalMap,
} from '@/app/editor/blocks/components/Media/sizeMaps';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { getComponentBorderRadiusConfiguration } from '@/app/editor/themes/helpers';

import type { Alignment, ListItemVisualSize, MediaSrcType } from '@/app/editor/blocks/types';
import type { BorderRadius } from '@/app/editor/themes/types';

interface GetListItemVisualSizeArgs {
    visualSize: ListItemVisualSize;
    srcType: MediaSrcType;
    align: Alignment;
}

// Helper
export const getMaxWidth = (artBoardSize: ArtBoardSize) => {
    switch (artBoardSize) {
        case ArtBoardSize.MOBILE:
            return 375;
        case ArtBoardSize.TABLET:
            return 768;
        case ArtBoardSize.DESKTOP:
            return 1024;
    }
};

export const getListItemVisualSizeProps = ({
    srcType,
    visualSize,
    align,
}: GetListItemVisualSizeArgs) => {
    const size =
        align === 'left'
            ? listItemVisualSizeHorizontalMap[visualSize]
            : listItemVisualSizeVerticalMap[visualSize];

    const padding = align === 'left' ? 8 : listItemEmojiPaddingMap[visualSize];

    return {
        size: size,
        emojiPadding: srcType === 'emoji' ? padding : null,
    };
};

// For images and videos
export const getMediaBorderRadius = ({
    isInListItem,
    isInMediaAnswer,
    isFullWidth,
    borderRadius,
    forceLarge,
}: {
    isInListItem?: boolean;
    isInMediaAnswer?: boolean;
    isFullWidth?: boolean;
    borderRadius?: BorderRadius;
    forceLarge?: boolean;
}) => {
    if (isInMediaAnswer || isFullWidth) {
        return '';
    }

    if (isInListItem && forceLarge) {
        return 'rounded-full';
    }

    if (isInListItem) {
        return getComponentBorderRadiusConfiguration('listImage', borderRadius);
    }

    return getComponentBorderRadiusConfiguration('image', borderRadius);
};
