import { DEFAULT_BLOCK_CONFIG } from '../../../editor/constants';

import type { BlockConfig } from '@/app/editor/blocks/types';

const config: BlockConfig = {
    ...DEFAULT_BLOCK_CONFIG,
    deletionReminder: true,
};

export default config;
