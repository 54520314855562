import { FEATURE_IDS } from '@/app/billing/constants';

import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { getCampaignIsDraft, getEditorUrl } from '@/app/campaigns/helpers';
import { fetchCampaignAnalytics } from '@/app/campaigns/models/analytics';
import { fetchCampaign, getCampaignById } from '@/app/campaigns/models/campaigns';
import { fetchHasSplitTesting } from '@/app/campaigns/models/splitTest';
import { getFeatureAvailability } from '@/app/company/models/company';
import { deletePage } from '@/app/editor/pages/models/delete';
import { fetchPageMapping } from '@/app/editor/pages/models/pageMapping';
import {
    fetchFirstPage,
    fetchPageBlocksIfNotPresent,
    fetchSinglePage,
    getFirstPageIdByCampaignId,
    setActivePageId,
} from '@/app/editor/pages/models/pages';
import { getPageVariantByCampaignId, removePageVariant } from '@/app/editor/pages/models/variant';
import { apiGet, apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { hideModal } from '../../modals/models/modals';
import { ANALYTICS_KEYS, NAME } from '../constants';
import { createFakeTestResults } from '../helper';

import type { SplitTestResults, Variant } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    variants: Variant[];
    currentVariant: string;
    testResults: SplitTestResults;
    endSplitTestingLoading: boolean;
    failedCalculation: boolean;
}

const initialState: State = {
    variants: EMPTY_ARRAY,
    currentVariant: '',
    testResults: EMPTY_OBJECT as SplitTestResults,
    endSplitTestingLoading: false,
    failedCalculation: false,
};

export const variantAnalyticsSlice = createSlice({
    name: `${NAME}/variantAnalytics`,
    initialState,
    reducers: {
        setVariantAnalytics(state, action: PayloadAction<Variant[]>) {
            return {
                ...state,
                variants: action.payload,
            };
        },
        setCurrentVariant(state, action: PayloadAction<string>) {
            return {
                ...state,
                currentVariant: action.payload,
            };
        },
        setSplitTestResults(state, action: PayloadAction<SplitTestResults>) {
            return {
                ...state,
                testResults: action.payload,
            };
        },
        setFailedCalculation(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                failedCalculation: action.payload,
            };
        },
        setEndSplitTestingLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                endSplitTestingLoading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setVariantAnalytics,
    setCurrentVariant,
    setSplitTestResults,
    setEndSplitTestingLoading,
    setFailedCalculation,
    reset,
} = variantAnalyticsSlice.actions;

// === Selectors ======

export const getVariantsAnalytics = (state: AppState) =>
    state[NAME]?.variantAnalyticsReducer?.variants;

export const getCurrentVariant = (state: AppState) =>
    state[NAME]?.variantAnalyticsReducer?.currentVariant;

export const getSplitTestResults = (state: AppState) =>
    state[NAME]?.variantAnalyticsReducer?.testResults;

export const getEndSplitTestingLoading = (state: AppState) =>
    state[NAME]?.variantAnalyticsReducer?.endSplitTestingLoading;

export const getFailedCalculation = (state: AppState) =>
    state[NAME]?.variantAnalyticsReducer?.failedCalculation;

// === Thunks ======

export const fetchVariantsAnalytics =
    (campaignId: string): AppThunk =>
    async (dispatch) => {
        const hasSplitTestingFeature = getFeatureAvailability(FEATURE_IDS.splitTesting);

        if (!hasSplitTestingFeature) {
            return;
        }

        try {
            const response = await dispatch(
                fetchCampaignAnalytics({
                    campaignId,
                    analyticsKey: ANALYTICS_KEYS.variants,
                }),
            );

            if (!response) {
                return;
            }

            const variants = getDataFromResponse(response);

            dispatch(setVariantAnalytics(variants));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching variant analytics failed:' });
        }
    };

export const fetchSplitTestResults =
    (campaignId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await apiGet(`/analytics/variants/calculation?campaign=${campaignId}`);
            const results = getDataFromResponse(response);

            dispatch(setSplitTestResults(results?.attributes));
            dispatch(setFailedCalculation(false));
        } catch (err) {
            // Edge case: funnel was published w/o variants
            const state = getState();
            const variant = getPageVariantByCampaignId(state, campaignId);
            const originalId = getFirstPageIdByCampaignId(state, campaignId);

            const errorData = err?.response?.data;

            const status = errorData?.statusCode;
            const message = errorData?.message;

            if (status === 400 && message === 'No variant page detected') {
                dispatch(setFailedCalculation(true));
                dispatch(setSplitTestResults(createFakeTestResults(originalId, variant?.id)));
            } else {
                dispatch(setFailedCalculation(false));
                dispatch(setSplitTestResults(EMPTY_OBJECT as SplitTestResults));

                handleRuntimeError(err, {
                    debugMessage: 'fetching split test results failed:',
                });
            }
        }
    };

export const dataSelectWinnerAndRepublish =
    (campaignId: string, winnerPageId: string, loserPageId: string): AppThunk =>
    async () => {
        try {
            await apiPost(`/campaigns/${campaignId}/split-test-winner`, {
                data: {
                    chose: winnerPageId,
                    remove: loserPageId,
                },
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'selecting winner and republish failed:' });
        }
    };

export const endSplitTest =
    ({
        campaignId,
        winnerPageId,
        loserPageId,
        skipAnalytics = false,
        navigate = true,
    }: {
        campaignId: string;
        winnerPageId: string;
        loserPageId: string;
        skipAnalytics?: boolean;
        navigate?: boolean;
    }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const failedCalculation = getFailedCalculation(state);
        const campaign = getCampaignById(state, campaignId);
        const isDraft = getCampaignIsDraft(campaign);

        dispatch(setEndSplitTestingLoading(true));

        try {
            // Delete page
            if (failedCalculation || isDraft) {
                const loserPage = await dispatch(fetchSinglePage(loserPageId));
                await dispatch(deletePage(loserPage));
            } else {
                await dispatch(dataSelectWinnerAndRepublish(campaignId, winnerPageId, loserPageId));
            }

            const promises = [];

            // Re-fetch campaign/mapping
            promises.push(dispatch(fetchCampaign(campaignId)));
            promises.push(dispatch(fetchPageMapping(campaignId)));
            promises.push(dispatch(fetchHasSplitTesting(campaignId)));
            promises.push(dispatch(fetchFirstPage(campaignId)));

            // Remove page variant
            promises.push(dispatch(removePageVariant(campaignId)));

            // Re-fetch analytics
            if (!skipAnalytics) {
                promises.push(dispatch(fetchVariantsAnalytics(campaignId)));
            }

            promises.push(dispatch(setActivePageId({ campaignId, pageId: winnerPageId })));
            promises.push(dispatch(fetchPageBlocksIfNotPresent(winnerPageId)));

            await Promise.all(promises);

            // Navigate to Winner page
            if (navigate) {
                Router.push(getEditorUrl(campaignId));
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'failed ending split test:' });
        }

        // Hide End Split Testing modal
        dispatch(hideModal());
        dispatch(setEndSplitTestingLoading(false));
    };

export default variantAnalyticsSlice.reducer;
