import { NAME } from '@/app/editor/editor/constants';

import { useTranslation } from 'next-i18next';
import { isWindows } from 'react-device-detect';

export interface Props {
    hotkey: string;
}

const iconMap = {
    cmd: '⌘',
    alt: '⌥',
    shift: '⇧',
};

const Label = ({ hotkey }: Props) => {
    const { t } = useTranslation(NAME);
    const OSHotkey = hotkey.toLowerCase() === 'cmd' ? (isWindows ? t('ctrl') : 'cmd') : hotkey;

    return (
        <div className="flex h-4 w-auto items-center justify-center rounded bg-gray-500 px-1 text-[11px] font-normal">
            {iconMap[OSHotkey] ? iconMap[OSHotkey] : OSHotkey}
        </div>
    );
};

export default Label;
