export interface Props {
    className?: string;
}

const DashedIcon = ({ className }: Props) => {
    return (
        <div className={className}>
            <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="100%"
            >
                <path d="M4,48c-0.512,0-1.023-0.195-1.414-0.586c-0.781-0.781-0.781-2.047,0-2.828l9-9c0.781-0.781,2.047-0.781,2.828,0	c0.781,0.781,0.781,2.047,0,2.828l-9,9C5.023,47.805,4.512,48,4,48z M20,32c-0.512,0-1.023-0.195-1.414-0.586	c-0.781-0.781-0.781-2.047,0-2.828l10-10c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828l-10,10	C21.023,31.805,20.512,32,20,32z M37,15c-0.512,0-1.023-0.195-1.414-0.586c-0.781-0.781-0.781-2.047,0-2.828l9-9	c0.781-0.781,2.047-0.781,2.828,0c0.781,0.781,0.781,2.047,0,2.828l-9,9C38.023,14.805,37.512,15,37,15z" />
            </svg>
        </div>
    );
};

export default DashedIcon;
