import type { Currency } from '../billing/types';

interface GroupAttributes {
    created_at: number;
    key: string;
    name: string;
    slug: string;
    updated_at: number;
}

export const enum UsedPartnershipStats {
    linkClicks = 'LINK_CLICKS',
    customerCount = 'CUSTOMER_COUNT',
    paidCount = 'PAID_ACCOUNT_COUNT',
    commissionPaid = 'COMMISSION_PAID',
}
type PartnershipStats = Record<UsedPartnershipStats | string, number>;

export interface PartnershipAttributes {
    address: null | object;
    created_at: number;
    email: string;
    first_name: string;
    group: GroupAttributes;
    joined_at: string | number;
    key: string;
    last_name: string;
    partner_key: string;
    stats: PartnershipStats;
    updated_at: number;
}

export enum CustomerStatus {
    InTrial = 'in_trial',
    Cancelled = 'cancelled',
    Converted = 'converted',
    PaidOut = 'paid_out',
    Declined = 'declined',
    UnsupportedPlan = 'unsupported_plan',
}

export interface RewardAttributes {
    amount: number;
    created_at: number;
    key: string;
    reward_status: string;
    transaction: {
        amount?: number;
        currency?: Currency;
        key?: string;
        product_key?: string;
    };
    updated_at: number;
}

export interface CustomerAttributes {
    created_at: number;
    email: string;
    key: string;
    meta: {
        planId?: string;
        start_date?: string;
        status?: CustomerStatus;
        trial_end_date?: string;
    };
    name: string;
    partner_key: string;
    updated_at: number;
    rewards: { totalAmount: number; latestReward?: RewardAttributes };
}

// Customers Table
export enum CustomerTableColumns {
    createdAt = 'created_at',
    email = 'email',
    status = 'status',
    latestCommission = 'latest_commission',
    totalCommission = 'total_commission',
}

export enum CustomerTableOrder {
    createdAt = CustomerTableColumns.createdAt,
    email = CustomerTableColumns.email,
    status = CustomerTableColumns.status,
    latestCommission = CustomerTableColumns.latestCommission,
    totalCommission = CustomerTableColumns.totalCommission,
    createdAtReverse = `-${CustomerTableColumns.createdAt}`,
    emailReverse = `-${CustomerTableColumns.email}`,
    statusReverse = `-${CustomerTableColumns.status}`,
    latestCommissionReverse = `-${CustomerTableColumns.latestCommission}`,
    totalCommissionReverse = `-${CustomerTableColumns.totalCommission}`,
}
