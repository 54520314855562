import type { EditorEngineDefaultDraggableConfiguration } from '@/app/editor/engine/core/types';
import type { SetterFunction } from '@/app/editor/engine/core/types/util';

/**
 * Change the inherited configuration to potentially override a positive answer.
 * For example, if `canBeDragged` returns true in the inherited configuration,
 * but the new configuration returns false, the node cannot be dragged.
 */
export const restrictDraggableConfiguration = <
    TConfiguration extends EditorEngineDefaultDraggableConfiguration,
>({
    canBeDragged,
    canTarget,
    canBeTargeted,
}: Partial<Omit<TConfiguration, 'executeDrop'>>) => {
    return ((previousConfiguration: TConfiguration) => {
        return {
            canBeDragged: (input) => {
                if (!previousConfiguration.canBeDragged(input)) {
                    return false;
                }

                return canBeDragged ? canBeDragged(input) : true;
            },
            canTarget: (input) => {
                if (!previousConfiguration.canTarget(input)) {
                    return false;
                }

                return canTarget ? canTarget(input) : true;
            },
            canBeTargeted: (input) => {
                if (!previousConfiguration.canBeTargeted(input)) {
                    return false;
                }

                return canBeTargeted ? canBeTargeted(input) : true;
            },
            executeDrop: previousConfiguration.executeDrop,
            getMessageForOperation: previousConfiguration.getMessageForOperation,
            getDropPreviewOptionsForOperation:
                previousConfiguration.getDropPreviewOptionsForOperation,
        } satisfies EditorEngineDefaultDraggableConfiguration;
    }) as SetterFunction<TConfiguration>;
};
