import {
    type EditorEngineDefaultTypeInput,
    EditorEngineStepName,
} from '@/app/editor/engine/core/types';

import type {
    EditorEngineNode,
    EditorEngineNodeData,
    EditorEngineParentId,
    EditorEngineTransactionStepCreator,
} from '@/app/editor/engine/core/types';

export interface InsertNodeStepInput<TNodeData extends EditorEngineNodeData> {
    /**
     * The node to insert.
     */
    node: EditorEngineNode<TNodeData>;
    /**
     * The ID of the parent node to insert the node into.
     */
    parentId: EditorEngineParentId;
    /**
     * The index at which to insert the node.
     */
    index: number;
}

/**
 * A transaction step that inserts a node at a specific index in a parent node.
 */
export const insertNodeStep = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    node,
    parentId,
    index,
}: InsertNodeStepInput<TEditorEngineTypeInput['Data']>) =>
    (({ nodeManager }) => ({
        name: EditorEngineStepName.InsertNode,
        debug: {
            node,
            parentId,
            index,
        },
        affectedNodes: [nodeManager.identify(node)],
        apply() {
            nodeManager.insertNodeAt({
                node,
                parentId,
                index,
            });

            return {
                success: true,
                additionalDebugData: {
                    newNodeId: nodeManager.identify(node),
                },
            };
        },
        revert() {
            nodeManager.removeNode({
                id: nodeManager.identify(node),
            });

            return {
                success: true,
                additionalDebugData: {
                    deletedNodeId: nodeManager.identify(node),
                },
            };
        },
    })) satisfies EditorEngineTransactionStepCreator<TEditorEngineTypeInput>;
