import { Component } from 'react';

import { handleError } from '@/app/editor/engine/utils/error/handleError';

import type { EditorEngineEventEmitter } from '@/app/editor/engine/core/types';
import type { ErrorInfo, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    eventEmitter: EditorEngineEventEmitter;
}

interface State {
    hasError: boolean;
}

export class EditorEngineErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const { eventEmitter } = this.props;

        handleError({
            error,
            errorInfo,
            eventEmitter,
        });
    }

    public render() {
        return this.props.children;
    }
}
