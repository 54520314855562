import {
    COLOR_LIGHT,
    COLOR_DARK,
    PARENTS_WITH_TRANSPARENT_TEXT_CHILDREN,
} from '@/app/editor/themes/constants';

import Color from 'color';
import get from 'lodash/get';

import type { BlockResource } from '@/app/editor/blocks/types';
import type {
    BorderRadius,
    BorderRadiusConfiguration,
    ThemeColorKey,
    ThemeResource,
} from '@/app/editor/themes/types';
import type { ValueOf } from 'types/generic';

const GRADIENT_COLORS_REGEX =
    /(rgba?|RGBA?)\(\d{1,3},\s*\d{1,3},\s*\d{1,3}\s*(,\s*\d*(?:\.\d+)?)?\)/g;

export const getThemedOrCustomColor = (
    color: string,
    themeColorKey: ThemeColorKey,
    theme: ThemeResource,
) => {
    if (!theme) {
        return color;
    }

    const themeColor = theme.attributes[themeColorKey];

    // if no color is defined or the defined color matches the themes color, return the theme color
    if (!color || color === themeColor || color === 'transparent') {
        return theme.attributes[themeColorKey];
    }

    // else return custom color
    return color;
};

export const isGradientColor = (color: string) => {
    if (!color) {
        return false;
    }

    return color.includes('linear-gradient') || color.includes('radial-gradient');
};

export const getGradientColors = (color: string) => {
    const isGradient = isGradientColor(color);

    if (!isGradient) {
        return [color];
    }

    return Array.from(color.matchAll(GRADIENT_COLORS_REGEX)).map((match) => match[0].toLowerCase());
};

export const getColorByLightness = (
    currentColor: string,
    refColor: string,
    hasSameColorCheck = true,
) => {
    // currentColor needs to be themed, otherwise return custom color
    if (
        !currentColor ||
        currentColor === 'transparent' ||
        (currentColor?.toLowerCase() === refColor?.toLowerCase() && hasSameColorCheck)
    ) {
        // TODO: Maybe find some logic that checks if a gradient is light or dark
        if (isGradientColor(refColor)) {
            return COLOR_LIGHT;
        }

        if (refColor === 'transparent') {
            return COLOR_DARK;
        }

        return Color(refColor).lightness() <= 60 ? COLOR_LIGHT : COLOR_DARK;
    }

    return currentColor;
};

export const getThemeColor = (theme: ThemeResource, themeColorKey: ThemeColorKey) => {
    if (!theme) {
        return '';
    }

    return theme.attributes[themeColorKey];
};

export const getCalculatedButtonTextColor = (
    block: BlockResource,
    activeTheme: ThemeResource,
    hasSameColorCheck = true,
) => {
    const customButtonTextColor = block?.attributes?.content?.color;
    const customButtonBackgroundColor = block?.attributes?.content?.backgroundColor;

    /*
        calculated color that is used in case no custom color was set, can be either:
            - lightness dependent on custom backgroundColor if set OR
            - if no custom backgroundColor is set, lightness dependent on theme background color
    */
    return customButtonBackgroundColor
        ? getColorByLightness(customButtonTextColor, customButtonBackgroundColor, hasSameColorCheck)
        : getColorByLightness(
              customButtonTextColor,
              getThemeColor(activeTheme, 'buttonBackgroundColor'),
              hasSameColorCheck,
          );
};

export const getThemeColorsAsArray = (theme: ThemeResource): string[] => {
    return [
        theme?.attributes?.fontColor,
        theme?.attributes?.buttonBackgroundColor,
        theme?.attributes?.backgroundColor,
        theme?.attributes?.formFieldBackgroundColor,
    ];
};

export const getThemeFont = (theme: ThemeResource) => {
    const typography = get(theme, 'attributes.typography', '');

    if (typography) {
        return typography.split(':')[0];
    }

    return '';
};

export const getHasTransparentBg = (parentComponentType: string) => {
    return PARENTS_WITH_TRANSPARENT_TEXT_CHILDREN.includes(parentComponentType);
};

export const getIsDark = (color: string) => {
    if (color === 'transparent') {
        return false;
    }

    try {
        const [red, green, blue] = Color(color).rgb().array();
        const yiq = (red * 2126 + green * 7152 + blue * 722) / 10000;

        return yiq < 128;
    } catch {
        return false;
    }
};

const borderRadiusConfiguration = {
    A: {
        sharp: 'rounded',
        small: 'rounded',
        medium: 'rounded-lg',
        large: 'rounded-lg',
    },
    B: {
        sharp: 'rounded',
        small: 'rounded-lg',
        medium: 'rounded-xl',
        large: 'rounded-full',
    },
    C: {
        sharp: 'rounded',
        small: 'rounded-lg',
        medium: 'rounded-xl',
        large: 'rounded-2xl',
    },
    D: {
        sharp: 'rounded',
        small: 'rounded-xl',
        medium: 'rounded-2xl',
        large: 'rounded-[20px]',
    },
    E: {
        sharp: 'rounded-t',
        small: 'rounded-t-lg',
        medium: 'rounded-t-xl',
        large: 'rounded-t-2xl',
    },
    F: {
        sharp: 'rounded-b',
        small: 'rounded-b-lg',
        medium: 'rounded-b-xl',
        large: 'rounded-b-2xl',
    },
    G: {
        sharp: 'rounded-sm',
        small: 'rounded',
        medium: 'rounded-md',
        large: 'rounded-lg',
    },
} as const satisfies BorderRadiusConfiguration;

const componentBorderRadius = {
    blockFrame: borderRadiusConfiguration.D,
    image: borderRadiusConfiguration.D,
    video: borderRadiusConfiguration.D,
    button: borderRadiusConfiguration.B,
    listImage: borderRadiusConfiguration.A,
    faqItem: borderRadiusConfiguration.C,
    sliderImage: borderRadiusConfiguration.D,
    embed: borderRadiusConfiguration.D,
    choiceItem: borderRadiusConfiguration.C,
    topWrapper: borderRadiusConfiguration.E,
    bottomWrapper: borderRadiusConfiguration.F,
    fieldsWrapper: borderRadiusConfiguration.C,
    dropdown: borderRadiusConfiguration.C,
    menu: borderRadiusConfiguration.C,
    dropdownItem: borderRadiusConfiguration.A,
    datePickerDay: borderRadiusConfiguration.A,
    webinarButton: borderRadiusConfiguration.G,
} as const satisfies Record<string, ValueOf<BorderRadiusConfiguration>>;

export const getComponentBorderRadiusConfiguration = (
    componentType: keyof typeof componentBorderRadius,
    themeSetting: BorderRadius,
) => {
    return componentBorderRadius[componentType][themeSetting ?? 'small'];
};
