import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { Field, FormSection, getFormValues } from 'redux-form';

import { BgImageFocalPoint } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/BgImage/BgImageFocalPoint';
import { BgImageOpacity } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/BgImage/BgImageOpacity';
import { BgImageSrc } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/BgImage/BgImageSrc';
import { BgImageToggle } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/BgImage/BgImageToggle';
import BoxSize from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/BoxSize';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import { ColorPickerHistoryProvider } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color/ColorPickerHistoryProvider';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';

import type { BlockResource, BoxSizeOption, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { Props as ColorProps } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';

export interface Props
    extends Pick<DefaultEditFormProps, 'blockId' | 'handleSubmit' | 'activeTheme'> {
    headingClass?: string;
    hiddenHeading?: boolean;
    hiddenColor?: boolean;
    hasBgImage?: boolean;
    colorProps?: Partial<ColorProps>;
    sizeOptions?: BoxSizeOption[];
}

export const BoxSettings = ({
    blockId,
    handleSubmit,
    activeTheme,
    headingClass,
    hiddenHeading,
    hiddenColor,
    colorProps,
    sizeOptions,
    hasBgImage,
}: Props) => {
    const { t } = useTranslation(NAME);

    const formValues = useAppSelector((state) => getFormValues(blockId)(state)) as BlockResource;
    const bgImageSettingVisible = formValues?.attributes?.content?.box?.bgImage?.visible;
    const bgImageSrc = formValues?.attributes?.content?.box?.bgImage?.src;

    return (
        <FormSection name="attributes.content.box">
            {!hiddenHeading && <Subheading text={t('background')} className={headingClass} />}

            <div
                className={cn('grid grid-cols-2 gap-2', {
                    'mt-4': !hiddenHeading,
                })}
            >
                <Field
                    name="top"
                    component={BoxSize}
                    icon="top"
                    submit={handleSubmit}
                    options={sizeOptions}
                />

                <Field
                    name="bottom"
                    component={BoxSize}
                    icon="bottom"
                    submit={handleSubmit}
                    options={sizeOptions}
                />
            </div>

            {!hiddenColor && (
                <>
                    <div className="grid grid-cols-3 gap-2">
                        <ColorPickerHistoryProvider>
                            <Field
                                name="backgroundColor"
                                component={Color}
                                expand="right"
                                submit={handleSubmit}
                                themeColor={getThemeColor(activeTheme, 'backgroundColor')}
                                tooltip={t('background-color')}
                                hasGradient
                                themeKey="backgroundColor"
                                {...colorProps}
                            />
                        </ColorPickerHistoryProvider>

                        {hasBgImage && (
                            <Field
                                name="bgImage.visible"
                                component={BgImageToggle}
                                submit={handleSubmit}
                                isEmpty={!bgImageSrc}
                            />
                        )}
                    </div>

                    {bgImageSettingVisible && hasBgImage && (
                        <>
                            <Field
                                name="bgImage.focalPoint"
                                component={BgImageFocalPoint}
                                submit={handleSubmit}
                                src={bgImageSrc}
                            />
                            <Field
                                name="bgImage.src"
                                component={BgImageSrc}
                                submit={handleSubmit}
                                blockId={blockId}
                            />
                            <Field
                                name="bgImage.opacity"
                                component={BgImageOpacity}
                                submit={handleSubmit}
                            />
                        </>
                    )}
                </>
            )}
        </FormSection>
    );
};

export default BoxSettings;
