import { createSlice } from '@reduxjs/toolkit';

import { transformFaviconData } from '@/app/funnelSettings/favicon/helper';
import { addFavicons } from '@/app/funnelSettings/favicon/models/fetch';
import { updateFavicon } from '@/app/funnelSettings/favicon/models/update';
import { apiPost, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { FaviconResource } from '@/app/funnelSettings/favicon/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const addFaviconSlice = createSlice({
    name: `funnelSettings/${NAME}/add`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = addFaviconSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.addFaviconReducer?.loading;

// === Thunks ======

export const addFavicon =
    (campaignId: string, workspaceId: string, file: File): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const payload = new FormData();
            payload.append('file', file);
            payload.append('workspace', workspaceId);

            const response = await apiPost<ResponseData>('/image/favicon', payload, {
                headers: { 'Content-Type': undefined },
            });

            const favicon = response?.data?.data as FaviconResource;
            dispatch(addFavicons({ [favicon.id]: favicon }));

            // set as active favicon
            const faviconData = transformFaviconData([favicon]);
            await dispatch(updateFavicon(campaignId, faviconData[0]));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'adding favicon failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default addFaviconSlice.reducer;
