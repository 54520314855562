import { reduxForm } from 'redux-form';

import type { FC } from 'react';

export interface Props {}

const Form: FC<Props> = () => {
    return <div>Not used</div>;
};

Form.displayName = 'FormEditForm';

export default reduxForm({})(Form);
