import { SEQUENCE_COMPONENT_TYPES_CONFIGURATION } from '@/app/editor/engine/constants';

import { store } from '@/core/redux/store';

import {
    getBlockOrPreviewIsInColumn,
    getBlockOrPreviewIsInSingleColumn,
} from '@/app/editor/blocks/models/blocks';
import { ArtBoardSize } from '@/app/editor/editor/types';

import type { BlockResource } from '@/app/editor/blocks/types';

/**
 * Given a component, returns the length of a row of this type of component.
 */
export const getRowLengthForComponent = ({
    component,
    artboardSize,
}: {
    /**
     * The component which is part of the row.
     */
    component: BlockResource;
    /**
     * The current artboard size.
     */
    artboardSize: ArtBoardSize;
}) => {
    if (!(component.attributes.componentType in SEQUENCE_COMPONENT_TYPES_CONFIGURATION)) {
        return 1;
    }

    const configuration =
        SEQUENCE_COMPONENT_TYPES_CONFIGURATION[component.attributes.componentType];
    const componentCanUseWideLayout =
        artboardSize === ArtBoardSize.DESKTOP &&
        (!getBlockOrPreviewIsInColumn(store.getState(), component.id) ||
            getBlockOrPreviewIsInSingleColumn(store.getState(), component.id));

    return componentCanUseWideLayout
        ? configuration.wideLayoutRowLength
        : configuration.narrowLayoutRowLength;
};
