import * as Select from '@radix-ui/react-select';

import { cn } from '@/utils/cn';

import type { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    dropdownClass?: string;
    hasPortal?: boolean;
    hasTriggerWidth?: boolean;
}

export const SelectContent = ({
    children,
    dropdownClass,
    hasPortal = true,
    hasTriggerWidth = true,
}: Props) => {
    const content = (
        <Select.Content
            position="popper"
            side="bottom"
            sideOffset={6}
            className="data-[side=bottom]:origin-top data-[side=top]:origin-bottom data-[state=closed]:animate-dropdown-out data-[state=open]:animate-dropdown-in"
            style={{
                width: hasTriggerWidth ? 'var(--radix-select-trigger-width)' : undefined,
            }}
            asChild
        >
            <div
                className={cn(
                    'max-h-60 w-full overflow-auto rounded-lg border bg-white p-2 text-sm shadow-dropdown outline-none',
                    dropdownClass,
                )}
            >
                {children}
            </div>
        </Select.Content>
    );

    if (hasPortal) {
        return <Select.Portal>{content}</Select.Portal>;
    }

    return content;
};
