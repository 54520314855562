import * as Select from '@radix-ui/react-select';
import { useMemo } from 'react';

import { cn } from '@/utils/cn';

import type { DropdownOption } from '@/ui/types';

export interface Props {
    option: DropdownOption;
    sprite: string;
    offsetMap: {};
}

export const Option = ({ option, sprite, offsetMap }: Props) => {
    const iconStyle = useMemo(
        () => ({
            background: `url(${sprite}) no-repeat`,
            backgroundSize: 'auto 100%',
            backgroundPositionX: `-${offsetMap[option.value]}px`,
        }),
        [sprite, offsetMap, option.value],
    );

    return (
        <Select.Item
            key={option.value}
            className={cn(
                'flex cursor-pointer select-none items-center rounded p-2 text-sm text-gray-500 outline-none hover:bg-gray-100 hover:text-gray-800 data-[highlighted]:bg-gray-100 data-[highlighted]:text-gray-800 data-[state=checked]:text-blue-500',
                {
                    'cursor-not-allowed opacity-50': option.disabled,
                },
            )}
            value={option.value}
        >
            <div className="mr-2 size-5 overflow-hidden" style={iconStyle} />
            <span className="block truncate">{option.key}</span>
        </Select.Item>
    );
};
