export const Basics = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.25095 5C5.91918 5 5.60099 5.13041 5.3664 5.36254C5.1318 5.59468 5 5.90951 5 6.2378C5 6.56608 5.1318 6.88092 5.3664 7.11306C5.60099 7.34519 5.91918 7.4756 6.25095 7.4756L11.1018 7.47561V18.1616C11.1018 18.49 11.2336 18.8049 11.4683 19.0371C11.7029 19.2693 12.0212 19.3997 12.353 19.3997C12.6849 19.3997 13.0032 19.2693 13.2378 19.0371C13.4725 18.8049 13.6043 18.49 13.6043 18.1616V7.47621H18.1487C18.4806 7.47621 18.7989 7.34576 19.0335 7.11358C19.2682 6.88139 19.4 6.56647 19.4 6.23811C19.4 5.90974 19.2682 5.59483 19.0335 5.36264C18.7989 5.13045 18.4806 5.00001 18.1487 5.00001L6.25095 5Z"
                fill="white"
            />
        </svg>
    );
};
