import type { State } from '@/app/mediaLibrary/store/index';
import type { StateCreator } from 'zustand';

export interface ImageSlice {
    currentSrc: string;

    setCurrentSrc: (currentSrc: string) => void;
}

export const createImageSlice: StateCreator<
    State,
    [['zustand/devtools', never]],
    [],
    ImageSlice
> = (set) => ({
    currentSrc: '',

    setCurrentSrc: (currentSrc) => {
        set({ currentSrc });
    },
});
