import { useCallback, useMemo } from 'react';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';

import type { ChangeEventHandler } from 'react';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    submit: () => void;
    text: string;
}

const TextareaList = ({ input, submit }: Props) => {
    const { onChange, value } = input;

    const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
        (event) => {
            let newStringArray = event.target.value.split('\n');

            if (newStringArray.length > 100) {
                return;
            }

            newStringArray = newStringArray.filter((option, index) => {
                const isEmpty = option.length === 0;

                if (isEmpty) {
                    const previousNeighbor = newStringArray[index - 1] ?? 'first';
                    const nextNeighbor = newStringArray[index + 1] ?? 'last';

                    return !(previousNeighbor.length === 0 || nextNeighbor.length === 0);
                } else {
                    return true;
                }
            });

            onChange(newStringArray);
        },
        [onChange],
    );

    const handleBlur = useCallback(() => {
        if (submit) {
            setTimeout(submit);
        }
    }, [submit]);

    const textToDisplay = useMemo<string | null>(() => {
        if (!Array.isArray(value)) {
            return null;
        }

        return value?.join('\n');
    }, [value]);

    if (!textToDisplay) {
        return <></>;
    }

    return (
        <FieldContainer>
            <textarea
                onChange={handleChange}
                onBlur={handleBlur}
                rows={10}
                className="w-full resize-y rounded-md bg-gray-100 px-4 py-2 text-sm font-medium leading-6 text-gray-500"
                value={textToDisplay}
            />
        </FieldContainer>
    );
};

export default TextareaList;
