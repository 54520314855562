import { reduxForm } from 'redux-form';

import DefaultForm from '@/app/editor/blocks/components/Text/forms/DefaultForm';
import ListItemForm from '@/app/editor/blocks/components/Text/forms/ListItemForm';
import QuestionMediaAnswerForm from '@/app/editor/blocks/components/Text/forms/QuestionMediaAnswerForm';
import { getBlockComponentType, getIsTipTapData } from '@/app/editor/blocks/helpers';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({
    handleSubmit,
    activeTheme,
    blockId,
    pageId,
    campaignId,
    form,
    parent,
    initialValues,
}) => {
    const parentComponentType = getBlockComponentType(parent);
    const blockChildIndex = parent?.relationships?.components?.data?.findIndex((childComponent) => {
        return childComponent.id === blockId;
    });

    const isTipTap = getIsTipTapData(
        initialValues?.attributes?.content?.wysiwyg ||
            initialValues?.attributes?.content?.misc?.wysiwyg,
    );

    switch (parentComponentType) {
        case 'accordionItem':
            return (
                <ListItemForm
                    form={form}
                    blockId={blockId}
                    activeTheme={activeTheme}
                    handleSubmit={handleSubmit}
                    // hide personalization for the 'body' text block in the accordion item
                    hidePersonalization={blockChildIndex === 1}
                    isTipTap={isTipTap}
                />
            );
        case 'listItem':
            return (
                <ListItemForm
                    form={form}
                    blockId={blockId}
                    activeTheme={activeTheme}
                    handleSubmit={handleSubmit}
                    useSizeControls
                    isTipTap={isTipTap}
                />
            );
        case 'questionMediaAnswer':
            return (
                <QuestionMediaAnswerForm
                    blockId={blockId}
                    pageId={pageId}
                    campaignId={campaignId}
                    activeTheme={activeTheme}
                    handleSubmit={handleSubmit}
                    isTipTap={isTipTap}
                />
            );
        default:
            return (
                <DefaultForm
                    blockId={blockId}
                    activeTheme={activeTheme}
                    handleSubmit={handleSubmit}
                    isTipTap={isTipTap}
                />
            );
    }
};

Form.displayName = 'TextEditForm';

export default reduxForm({})(Form);
