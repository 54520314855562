import imgix from '@/core/imgix';

import type { FaviconData } from '@/app/funnelSettings/favicon/types';

export const NAME = 'favicon';

export const FAVICON_HELP_ARTICLE_LINK =
    'https://intercom.help/perspective-funnels/de/articles/4634843-favicon-bearbeiten';

export const FAVICON_HELP_ARTICLE_LINK_EN =
    'https://intercom.help/perspective-funnels/en/articles/4634843-how-do-i-edit-the-favicon-of-my-funnel';

export const MAX_USER_FAVICONS = 29;

export const DEFAULT_FAVICON = imgix.favicons.perspective;

export const DEFAULT_FAVICONS: FaviconData[] = [
    {
        id: 'perspective',
        url: imgix.favicons.perspective,
        isDefault: true,
    },
    {
        id: 'calendar',
        url: imgix.favicons.calendar,
        isDefault: true,
    },
    {
        id: 'gift',
        url: imgix.favicons.gift,
        isDefault: true,
    },
    {
        id: 'lightning',
        url: imgix.favicons.lightning,
        isDefault: true,
    },
    {
        id: 'world',
        url: imgix.favicons.world,
        isDefault: true,
    },
    {
        id: 'star',
        url: imgix.favicons.star,
        isDefault: true,
    },
];
