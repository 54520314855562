import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { getCompany } from '@/app/company/models/company';
import { apiPost, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    cancellationStarted: boolean;
    customReasonValid: boolean;
    activeCustomAnswer: string;
    customReasons: { [key: string]: string };
}

const initialState: State = {
    loading: false,
    cancellationStarted: true,
    customReasonValid: false,
    activeCustomAnswer: null,
    customReasons: {},
};

// Slice
export const cancellationSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setCustomReason(state: State, action: PayloadAction<{ answer: string; reason: string }>) {
            return {
                ...state,
                activeCustomAnswer: action.payload.answer,
                customReasons: {
                    ...state.customReasons,
                    [action.payload.answer]: action.payload.reason,
                },
            };
        },
        setCustomReasonValid(state: State, action: PayloadAction<boolean>) {
            return {
                ...state,
                customReasonValid: action.payload,
            };
        },
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
    },
});

// === Actions ======

export const { setCustomReason, setCustomReasonValid, setLoading } = cancellationSlice.actions;

// === Selectors ======

export const getActiveCustomAnswer = (state: AppState) =>
    state[NAME]?.cancellation?.activeCustomAnswer;

export const getCustomReasons = (state: AppState) => state[NAME]?.cancellation?.customReasons;

export const getCustomReasonValid = (state: AppState) =>
    state[NAME]?.cancellation?.customReasonValid;

export const getLoading = (state: AppState) => state[NAME]?.cancellation?.loading;

// === Thunks ======

export const saveSurveyAnswer =
    (survey: string, question: string, answer: string, inputValue?: string): AppThunk =>
    async () => {
        const surveyRequests = [];
        const requestUrl = `/bi/${survey}`;

        try {
            surveyRequests.push(
                apiPost(requestUrl, {
                    data: {
                        title: question,
                        value: answer,
                    },
                }),
            );

            if (inputValue) {
                surveyRequests.push(
                    apiPost(requestUrl, {
                        data: {
                            title: `${question}_detail`,
                            value: inputValue,
                        },
                    }),
                );
            }

            return await Promise.all(surveyRequests);
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'tracking survey answer failed' });
        }
    };

export const trackSurveyAnswer =
    (answer: string): AppThunk =>
    async (dispatch) => {
        await dispatch(saveSurveyAnswer('cancellation', 'reason', answer));
    };

export const trackCustomReason =
    (reason: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const activeCustomAnswer = getActiveCustomAnswer(state);

        return await Promise.all([
            dispatch(trackSurveyAnswer(activeCustomAnswer)),
            dispatch(saveSurveyAnswer('cancellation', 'detail', reason)),
        ]);
    };

export const setCustomCancellationReason =
    (answer: string, reason: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setCustomReason({ answer, reason }));

        const state = getState();
        const isReasonValid = getCustomReasonValid(state);

        if (!isReasonValid && reason.length >= 2) {
            dispatch(setCustomReasonValid(true));
        } else if (isReasonValid && reason.length < 2) {
            dispatch(setCustomReasonValid(false));
        }
    };

export const cancelSubscription = (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const state = getState();
    const company = getCompany(state);
    const subscriptionId = get(company, 'attributes.chargebee.subscriptionId');

    try {
        dispatch(setLoading(true));

        const response = await apiPost<ResponseData<unknown>>(
            `/subscriptions/${subscriptionId}/cancel`,
            {},
        );

        return !response.data.error;
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'cancellation failed:' });
    } finally {
        dispatch(setLoading(false));
    }

    return false;
};

export default cancellationSlice.reducer;
