import { INVALID_SCRIPT, removeTextNodes } from '../helper';

export const kununuDefaultValidator = (container: HTMLDivElement) => {
    let hasOnlyOneChild = false;
    let hasTopLevelAnchor = false;
    let hasNestedImage = false;
    let hasKununuImageSrc = false;

    removeTextNodes(container);

    const imgSrc = container.querySelector('a > img')?.getAttribute('src');

    if (container.childNodes.length === 1) {
        hasOnlyOneChild = true;
    }

    if (container.querySelector('div > a')?.tagName === 'A') {
        hasTopLevelAnchor = true;
    }

    if (container.querySelector('a > img')?.tagName === 'IMG') {
        hasNestedImage = true;
    }

    if (imgSrc?.startsWith('https://widgets.kununu.com')) {
        hasKununuImageSrc = true;
    }

    return {
        valid: hasOnlyOneChild && hasTopLevelAnchor && hasNestedImage && hasKununuImageSrc,
        message: INVALID_SCRIPT,
    };
};
