import { useCallback } from 'react';
import { change, submit } from 'redux-form';

import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getHasActiveParent, getHasParentWithActiveChild } from '@/app/editor/blocks/models/blocks';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { PictureItem } from './PictureItem';

import type { BoxProps, Size } from '../../../types';
import type { BorderRadius, ThemeColorKey } from '@/app/editor/themes/types';

export interface Props {
    blockId: string;
    src: string;
    size: Size;
    framed?: boolean;
    isDragged?: boolean;
    borderRadius?: BorderRadius;
    box: BoxProps;
    artBoardIndex: number;
    themedBackgroundColor?: ThemeColorKey;
    isActive?: boolean;
    isInColumn?: boolean;
}

const Picture = ({
    blockId,
    src,
    size,
    framed,
    borderRadius,
    isDragged,
    box,
    artBoardIndex,
    themedBackgroundColor,
    isActive,
    isInColumn,
}: Props) => {
    const dispatch = useAppDispatch();

    const { artBoardSize, isInMediaAnswer, isInListItem, isInFramedColumn } =
        useLayoutInfo(blockId);
    const hasActiveParent = useAppSelector((state) => getHasActiveParent(state, blockId));
    const hasParentWithActiveChild = useAppSelector((state) =>
        getHasParentWithActiveChild(state, blockId),
    );

    const onUpdateForm = useCallback(
        async (imgixUrl: string) => {
            await dispatch(change(blockId, 'attributes.content.src', imgixUrl));

            dispatch(submit(blockId));
        },
        [blockId, dispatch],
    );

    return (
        <PictureItem
            src={src}
            size={size}
            framed={framed}
            borderRadius={borderRadius}
            isDragged={isDragged}
            box={box}
            artBoardIndex={artBoardIndex}
            themedBackgroundColor={themedBackgroundColor}
            isActive={isActive}
            isInColumn={isInColumn}
            hasActiveParent={hasActiveParent}
            hasParentWithActiveChild={hasParentWithActiveChild}
            artBoardSize={artBoardSize}
            isInMediaAnswer={isInMediaAnswer}
            isInFramedColumn={isInFramedColumn}
            isInListItem={isInListItem}
            onUpdateForm={onUpdateForm}
        />
    );
};

export default Picture;
