import type { CountryCode } from './countries';

export const getFlagByCountryCode = (countryCode: CountryCode) => {
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map((char) => 127397 + char.charCodeAt(0));

    return String.fromCodePoint(...codePoints);
};
