import { createSlice } from '@reduxjs/toolkit';

import { setPage } from '@/app/editor/pages/models/pages';
import { hideModal } from '@/app/modals/models/modals';
import { apiPatch, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { PageResource } from '@/app/editor/pages/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    pageSlugError: boolean;
}

const initialState: State = {
    loading: false,
    pageSlugError: false,
};

export const updatePageSlice = createSlice({
    name: `editor/${NAME}/update`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setPageSlugError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                pageSlugError: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, setPageSlugError, reset } = updatePageSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.updatePageReducer?.loading;
export const getPageSlugError = (state: AppState) => state[NAME]?.updatePageReducer?.pageSlugError;

// === Thunks ======

export const updatePage =
    (page: PageResource): AppThunk =>
    async (dispatch) => {
        const campaignId = page?.relationships?.campaign?.data?.id;

        dispatch(setLoading(true));

        try {
            // optimistic update
            dispatch(setPage({ campaignId, page }));

            // update in DB
            await apiPatch(`/pages/${page.id}`, {
                data: page,
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating page failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export const updatePageSlug =
    (page: PageResource): AppThunk =>
    async (dispatch) => {
        const campaignId = page?.relationships?.campaign?.data?.id;

        dispatch(setLoading(true));

        try {
            // update in DB
            await apiPatch(`/pages/${page.id}`, {
                data: page,
            });

            // update page in redux store
            dispatch(setPage({ campaignId, page }));

            // No error -> success
            dispatch(setPageSlugError(false));
            dispatch(hideModal());
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating page slug failed:' });

            // Page slug is already in use
            if (err?.response?.status === 409) {
                dispatch(setPageSlugError(true));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

export const toggleConfetti =
    (page: PageResource): AppThunk =>
    (dispatch) => {
        try {
            const newPage = {
                ...page,
                attributes: {
                    ...page?.attributes,
                    animation: page?.attributes?.animation === 'confetti' ? 'none' : 'confetti',
                },
            };

            dispatch(updatePage(newPage));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'toggling confetti failed:' });
        }
    };

export default updatePageSlice.reducer;
