import { createSlice } from '@reduxjs/toolkit';
import { change, submit } from 'redux-form';
import isMongoId from 'validator/lib/isMongoId';

import { uploadAsset } from '@/app/assets/models/assets';
import { hideModal } from '@/app/modals/models/modals';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiDelete, apiGet, apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY } from '@/utils/empty';

import { NAME, THEME_CONFIG_FORM } from '../constants';

import type { FontResource } from '@/app/editor/themes/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loadingList: boolean;
    uploading: boolean;
    deleting: boolean;
    customFonts: FontResource[];
}

const initialState: State = {
    loadingList: false,
    uploading: false,
    deleting: false,
    customFonts: EMPTY_ARRAY,
};

export const customFontsSlice = createSlice({
    name: `editor/${NAME}/customFonts`,
    initialState,
    reducers: {
        setLoadingList(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loadingList: action.payload,
            };
        },
        setUploading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                uploading: action.payload,
            };
        },
        setDeleting(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                deleting: action.payload,
            };
        },
        setCustomFonts(state, action: PayloadAction<FontResource[]>) {
            return {
                ...state,
                customFonts: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoadingList, setUploading, setDeleting, setCustomFonts, reset } =
    customFontsSlice.actions;

// === Selectors ======

export const getLoadingList = (state: AppState) => state[NAME].customFontsReducer.loadingList;
export const getUploading = (state: AppState) => state[NAME].customFontsReducer.uploading;
export const getCustomFonts = (state: AppState) => state[NAME].customFontsReducer.customFonts;
export const getDeleting = (state: AppState) => state[NAME].customFontsReducer.deleting;

// === Thunks ======

// Fetch custom fonts list
export const fetchCustomFonts = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingList(true));

        const response = await apiGet('/fonts');
        const fonts = getDataFromResponse(response, EMPTY_ARRAY);

        dispatch(setCustomFonts(fonts));
    } catch (err) {
        handleRuntimeError(err, { message: 'Failed to fetch custom fonts list' });
    } finally {
        dispatch(setLoadingList(false));
    }
};

export const updateThemeFont =
    (themeId: string, fontId: string): AppThunk =>
    async () => {
        try {
            if (isMongoId(fontId)) {
                // Update font relationship
                await apiPost(`/themes/${themeId}/font`, {
                    data: {
                        fontId,
                    },
                });
            } else {
                // Reset font relationship
                await apiDelete(`/themes/${themeId}/font`);
            }
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to update theme font relationship' });
        }
    };

export const createCustomFont =
    ({
        name,
        regular,
        bold,
        themeId,
    }: {
        name: string;
        regular: File;
        bold?: File;
        themeId: string;
    }): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setUploading(true));
            const regularExtension = regular.name.split('.').pop();

            // 1. Upload files to S3
            const uploadPromises = [dispatch(uploadAsset(regular, regularExtension))];

            if (bold) {
                const boldExtension = bold.name.split('.').pop();
                uploadPromises.push(dispatch(uploadAsset(bold, boldExtension)));
            }

            // 2. Create custom font resource
            const uploadResults: string[] = await Promise.all(uploadPromises);

            const payload: {
                data: { name: string; regularFontAssetId: string; boldFontAssetId?: string };
            } = {
                data: {
                    name,
                    regularFontAssetId: uploadResults[0],
                },
            };

            if (bold) {
                payload.data.boldFontAssetId = uploadResults[1];
            }

            const response = await apiPost('/fonts', payload);

            // 3. Update theme font relationship
            const font: FontResource = getDataFromResponse(response);
            dispatch(updateThemeFont(themeId, font.id));

            // 4. Re-fetch custom fonts list & select new font
            dispatch(fetchCustomFonts());
            await dispatch(change(THEME_CONFIG_FORM, 'attributes.typography', font.id));
            dispatch(submit(THEME_CONFIG_FORM));

            // 5. Close modal show success toast
            dispatch(hideModal());
            showToast({ message: `${NAME}:upload-font-success-toast`, type: 'success' });
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to fetch custom fonts list' });
        } finally {
            dispatch(setUploading(false));
        }
    };

export const deleteFont =
    (fontId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setDeleting(true));

            await apiDelete(`/fonts/${fontId}`);

            dispatch(fetchCustomFonts());
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to delete custom font' });
        } finally {
            dispatch(setDeleting(false));
        }
    };

export default customFontsSlice.reducer;
