import type { BlockResource } from '@/app/editor/blocks/types';

/**
 * Normalize the preview block by removing "Template" from the name and
 * componentType.
 *
 * At the moment we're doing this by hand, but a more elegant solution might be
 * introduced in the future.
 *
 * todo(editorengine): this should be moved outside of the editor engine core.
 */
export const normalizePreviewBlock = (block: BlockResource) => {
    return {
        ...block,
        attributes: {
            ...block.attributes,
            name: block.attributes.name.replace('Template', ''),
            componentType: block.attributes.componentType.replace('Template', ''),
        },
    };
};
