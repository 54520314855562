import { combineReducers } from 'redux';

import addFaviconReducer from './add';
import deleteFaviconReducer from './delete';
import fetchFaviconReducer from './fetch';
import updateFaviconReducer from './update';

export default combineReducers({
    addFaviconReducer,
    deleteFaviconReducer,
    fetchFaviconReducer,
    updateFaviconReducer,
});
