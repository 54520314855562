import {
    LAST_INTERCOM_FEATURE_FLAG_EVENT_VALUES_LOCAL_STORAGE_KEY,
    OVERWRITES_STORAGE_KEY_PREFIX,
} from '@/core/loli-feature-flags/constants';

import { useLoliFeatureFlagsStore } from '@/core/loli-feature-flags/store';
import { isBrowserEnv, isProductionEnv } from '@/utils/environments';

import type { AllFeatureFlags } from '@/core/loli-feature-flags/types';

const computeFeatureFlagOverwriteStorageKey = (name: string) => {
    return OVERWRITES_STORAGE_KEY_PREFIX + name;
};

const clearAllStoredFeatureFlagOverwrites = () => {
    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(OVERWRITES_STORAGE_KEY_PREFIX)) {
            localStorage.removeItem(key);
        }
    });
};

export const storeFeatureFlagOverwrites = (overwrites: AllFeatureFlags) => {
    clearAllStoredFeatureFlagOverwrites();

    Object.keys(overwrites).forEach((key) => {
        localStorage.setItem(
            computeFeatureFlagOverwriteStorageKey(key),
            JSON.stringify(overwrites[key]),
        );
    });
};

export const getStoredFeatureFlagOverwrites = (): AllFeatureFlags => {
    if (typeof window === 'undefined') {
        return {};
    }

    const storedOverwrites: AllFeatureFlags = {};

    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(OVERWRITES_STORAGE_KEY_PREFIX)) {
            const storedValue = localStorage.getItem(key);
            const featureFlagName = key.substring(OVERWRITES_STORAGE_KEY_PREFIX.length);

            if (storedValue) {
                storedOverwrites[featureFlagName] = JSON.parse(storedValue);
            }
        }
    });

    return storedOverwrites;
};

export const syncStoreChangesToLocalStorage = () => {
    useLoliFeatureFlagsStore.subscribe((state) => {
        storeFeatureFlagOverwrites(state.featureFlagOverwrites);
    });
};

let sendFeatureFlagsToIntercomTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

export const syncStoreChangesToIntercom = () => {
    if (!isProductionEnv() || !isBrowserEnv()) {
        return;
    }

    clearTimeout(sendFeatureFlagsToIntercomTimeout);
    sendFeatureFlagsToIntercomTimeout = undefined;

    const sendFeatureFlagsToIntercom = (serializedFeatureFlags: string, leftTries = 15) => {
        if (leftTries === 0) {
            return;
        }

        sendFeatureFlagsToIntercomTimeout = setTimeout(() => {
            sendFeatureFlagsToIntercomTimeout = undefined;

            // Retry if Intercom is not available.
            if (typeof window.Intercom !== 'function') {
                sendFeatureFlagsToIntercom(serializedFeatureFlags, leftTries - 1);

                return;
            }

            // Send new state to Intercom.
            localStorage.setItem(
                LAST_INTERCOM_FEATURE_FLAG_EVENT_VALUES_LOCAL_STORAGE_KEY,
                serializedFeatureFlags,
            );

            window.Intercom('trackEvent', 'feature-flags', {
                values: serializedFeatureFlags,
            });
        }, 1000);
    };

    useLoliFeatureFlagsStore.subscribe((state) => {
        const serializedFeatureFlags = Object.entries(state.allFeatureFlagsWithoutOverwrites)
            .sort((left, right) => left[0].localeCompare(right[0]))
            .map(([featureFlagName, value]) => `${featureFlagName}=${JSON.stringify(value)}`)
            .join(';');

        const lastSent = localStorage.getItem(
            LAST_INTERCOM_FEATURE_FLAG_EVENT_VALUES_LOCAL_STORAGE_KEY,
        );

        // Stop if the state sent to Intercom didn't change.
        if (lastSent === serializedFeatureFlags) {
            return;
        }

        sendFeatureFlagsToIntercom(serializedFeatureFlags);
    });
};
