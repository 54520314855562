import type { PageType } from '@/app/editor/pages/types';

export const NAME = 'pages';

export const PAGE_LIMIT = 100;

export const PAGE_TEMPLATES: { [K in PageType]: string } = {
    others: '5a5635fb0b73077c5b8dba58',
    results: '5a5646b7057a5b2ece704374',
};

export const PAGE_DELETION_REMINDER_LS_KEY = 'page-deletion-reminder';
