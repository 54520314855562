import { toast } from 'sonner';

import { Toast } from '@/app/toasts/components/Toast';

import type { ToastOptions } from '@/app/toasts/types';

export const showToast = (options: ToastOptions) => {
    const { duration, fixed } = options;

    // Custom toast
    toast.custom(
        (toastId) => {
            return <Toast id={toastId} {...options} />;
        },
        {
            duration: fixed ? Infinity : duration,
        },
    );
};
