import type { EditorEngineDefaultTypeInput } from '@/app/editor/engine/core/types';
import type { EditorEngineDraggableConfiguration } from '@/app/editor/engine/core/types';

/**
 * Validates a draggable configuration.
 */
export const isValidDraggableConfiguration = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>(
    configuration: unknown,
): configuration is EditorEngineDraggableConfiguration<TEditorEngineTypeInput> => {
    return (
        configuration !== null &&
        typeof configuration === 'object' &&
        'canBeDragged' in configuration &&
        'canBeTargeted' in configuration &&
        'executeDrop' in configuration &&
        typeof configuration.canBeDragged === 'function' &&
        typeof configuration.canBeTargeted === 'function' &&
        typeof configuration.executeDrop === 'function'
    );
};
