import Image from 'next/image';

import { cn } from '@/utils/cn';

import type { Size, Alignment, DefaultBlockComponentProps } from '../../types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    alignment: Alignment;
    size: Size;
    src: string;
    showIcon: boolean;
}

const sizeMap = {
    small: 'h-8',
    medium: 'h-10',
    large: 'h-12',
    xLarge: 'h-16',
};

const alignMap = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
};

const Component: FC<Props> = ({ src, size, alignment, box, showIcon }) => {
    return (
        <div
            className={cn('flex items-center rounded-t-lg p-2', alignMap[alignment])}
            style={{ backgroundColor: box.backgroundColor }}
        >
            <Image
                width={0}
                height={0}
                src={src}
                alt="header logo"
                className={cn('w-auto transition-all', sizeMap[size], { invisible: !showIcon })}
                sizes="100vw"
            />
        </div>
    );
};

Component.displayName = 'Header';

export default Component;
