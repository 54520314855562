import { Children, cloneElement, isValidElement } from 'react';

import NewComponent from '@/app/editor/blocks/components/QuestionForm/NewComponent';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';

import Box from '../_wrapper/Box';
import Text from '../Text/Component';

import type { Alignment, DefaultBlockComponentProps, Size } from '../../types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
    size: Size;
    color: string;
    fieldName: string;
    wysiwyg: RawDraftContentState;
}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({
        artBoardIndex,
        children,
        box,
        blockId,
        align,
        color,
        wysiwyg,
        activeTheme,
        isDragged,
        isPreview,
    }) => {
        const { isInColumn, isDesktop } = useLayoutInfo(blockId, isPreview);
        const childBlocks = Children.map(children, (child) => {
            return isValidElement<BlockProps>(child)
                ? cloneElement(child, {
                      propsFromParent: { align },
                  })
                : null;
        });

        return (
            <Box
                box={getBoxAttributes({ box, isInColumn })}
                isDragged={isDragged}
                artBoardIndex={artBoardIndex}
            >
                <div className={isDesktop && !isInColumn ? 'mx-auto max-w-xl' : 'w-full'}>
                    <Text
                        align={align}
                        blockId={blockId}
                        wysiwyg={wysiwyg}
                        color={getThemedOrCustomColor(color, 'fontColor', activeTheme)}
                    />

                    {/* blocks/components/Form */}
                    {childBlocks}
                </div>
            </Box>
        );
    },
    NewComponent,
);

Component.displayName = 'QuestionForm';

export default Component;
