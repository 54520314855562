import { withTranslation } from 'next-i18next';
import { Component } from 'react';

import ErrorLayout from '@/ui/layouts/Error.layout';
import { reportError } from '@/utils/sentry';

import type { WithTranslation } from 'next-i18next';
import type { ReactNode } from 'react';

export interface Props {
    children?: ReactNode;
}

export interface State {
    hasError: boolean;
    isNewVersion: boolean;
}

class ErrorBoundary extends Component<WithTranslation & Props, State> {
    constructor(props) {
        super(props);

        this.state = { hasError: false, isNewVersion: false };
    }

    static getDerivedStateFromError(error) {
        if (error?.name === 'ChunkLoadError') {
            return { hasError: true, isNewVersion: true };
        }

        return { hasError: true, isNewVersion: false };
    }

    componentDidCatch(error: Error) {
        if (process.env.NODE_ENV === 'production') {
            reportError({
                error,
                source: 'boundary',
            });
        }
    }

    handleTryAgain = () => {
        if (this.state.isNewVersion) {
            return window?.location?.reload();
        }

        this.setState({ hasError: false });
    };

    render() {
        const { t, children } = this.props;
        const { isNewVersion, hasError } = this.state;

        if (hasError) {
            return (
                <ErrorLayout
                    title={isNewVersion ? 'Yeah!' : 'Oops!'}
                    headline={isNewVersion ? t('new-version-message') : t('error-title')}
                    description={
                        isNewVersion ? t('new-version-description') : t('error-description')
                    }
                    buttonText={isNewVersion ? t('click-to-reload') : t('error-try-again')}
                    onButtonClick={this.handleTryAgain}
                />
            );
        }

        return children;
    }
}

export default withTranslation('common')(ErrorBoundary);
