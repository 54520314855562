export const NAME = 'dashboard';

export const ENDPOINTS = {
    GET: {
        kpis: (campaignIds: string) =>
            `/analytics/dashboard/kpis${campaignIds ? `?campaigns=${campaignIds}` : ''}`,
        traffic: (campaignIds: string) =>
            `/analytics/dashboard/traffic-over-time${
                campaignIds ? `?campaigns=${campaignIds}` : ''
            }`,
    },
};

export const QUERY_KEYS = {
    kpis: (campaignIds: string) => ['kpis', campaignIds],
    traffic: (campaignIds: string) => ['traffic', campaignIds],
};
