import { usePerspectiveEditorEngine } from '@/app/editor/engine/PerspectiveEditorEngine';

import type { ReactNode } from 'react';

/**
 * Conditionally renders one or the other component based on whether the
 * Perspective Editor Engine is being used.
 */
export const RedirectWhenUsingEngine = <
    TProps extends object,
    TComponent extends (props: TProps) => ReactNode,
>(
    Component: TComponent,
    OtherComponent: TComponent,
) => {
    const EnhancedComponent = (
        props: JSX.IntrinsicAttributes & JSX.LibraryManagedAttributes<TComponent, TProps>,
    ) => {
        const check = usePerspectiveEditorEngine();

        if ('nodeManager' in check) {
            return <OtherComponent {...props} />;
        }

        return <Component {...props} />;
    };

    return EnhancedComponent;
};
