import { CustomerTableOrder } from './types';
import { BillingTimeframe } from '../billing/types';

export const NAME = 'referrals';

// Customers table
export const VISIBLE_CUSTOMERS_STEP = 10;
export const CUSTOMER_TABLE_DEFAULT_ORDER = CustomerTableOrder.createdAtReverse;
export const REFERRAL_PROGRAM_START_DATE = Number(
    process.env.NEXT_PUBLIC_PARTNERSTACK_REFERRAL_PROGRAM_START_DATE,
);

// Estimated commission amount in cents for each allowed plan
export const ESTIMATED_CUSTOMER_PLAN_COMMISSION = {
    [BillingTimeframe.monthly]: {
        'ps-pro': 2627,
        'ps-business': 4956,
        'ps-agency': 9789,
    },
    [BillingTimeframe.yearly]: {
        'ps-pro': 24998,
        'ps-business': 46751,
        'ps-agency': 95871,
    },
};

export const HOW_IT_WORKS_URL_EN =
    'https://intercom.help/perspective-funnels/en/articles/7181525-perspective-referral-program-how-you-and-your-friends-benefit';
export const HOW_IT_WORKS_URL_DE =
    'https://intercom.help/perspective-funnels/de/articles/7181525-perspective-referral-programm-so-profitieren-du-und-deine-freunde';
export const HAS_SEEN_REFERRAL_DASHBOARD_LS_KEY = 'has_seen_referral_dashboard';
