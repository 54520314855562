import { MAX_API_RETRY_COUNT } from '@/core/api/constants';

import { StatusCodes, type RetryConfig } from '@/core/api/types';

import type { AxiosInstance } from 'axios';

// Retry middleware
export const withRetry = (agent: AxiosInstance): AxiosInstance => {
    agent.interceptors.request.use((config: RetryConfig<unknown>) => {
        if (config && config?._retryCount === undefined) {
            config._retryCount = 0;
        }

        return config;
    });

    agent.interceptors.response.use(null, (error) => {
        const config = error.config as RetryConfig<unknown>;

        // If we have no information to retry the request
        if (!config || config?._retryCount === undefined) {
            return Promise.reject(error);
        }

        // Retry errors with status code 500+
        if (
            config._retryCount < MAX_API_RETRY_COUNT &&
            error.response?.status === StatusCodes.INTERNAL_SERVER_ERROR &&
            error.config.method === 'get'
        ) {
            config._retryCount += 1;

            return new Promise((resolve) => {
                resolve(agent(config));
            });
        }

        return Promise.reject(error);
    });

    return agent;
};
