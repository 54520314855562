import {
    NAME,
    DEFAULT_PLATFORM,
    PREVIEW_ICON_OFFSET_MAP,
} from '@/app/editor/iconLibrary/constants';

import { FaceFrownIcon } from '@heroicons/react/24/outline';
import find from 'lodash/find';
import { useTranslation } from 'next-i18next';

import { PlatformSelection } from '@/app/editor/iconLibrary/components/Library/PlatformSelection';
import imgix from '@/core/imgix';
import SearchInput from '@/ui/components/_BlockEditFields/SearchInput';
import ButtonLoadingSpinner from '@/ui/components/ButtonLoadingSpinner';
import Loader from '@/ui/components/Loader';
import { cn } from '@/utils/cn';

import { IconTile } from './IconTile';

import type { Icon, Platform } from '@/app/editor/iconLibrary/types';
import type { ChangeEventHandler } from 'react';

export interface Props {
    fetchingPlatforms: boolean;
    fetchingIcons: boolean;
    fetchingSearch: boolean;
    fetchingPage: boolean;
    platforms: Platform[];
    selectedPlatform: { id: string; isColor: boolean };
    onPlatformChange: (platform: Platform) => void;
    onSearchChange: ChangeEventHandler<HTMLInputElement>;
    onIconSelect: (icon: Icon) => void;
    onLoadMore: () => void;
    icons: Icon[];
    searchTerm: string;
    blockIconId: string;
}

const LibraryView = ({
    fetchingPlatforms,
    fetchingIcons,
    fetchingSearch,
    fetchingPage,
    platforms,
    selectedPlatform,
    onPlatformChange,
    onSearchChange,
    onIconSelect,
    onLoadMore,
    icons,
    searchTerm,
    blockIconId,
}: Props) => {
    const { t } = useTranslation(NAME);

    const options = [
        {
            value: DEFAULT_PLATFORM,
            key: t('all-styles'),
            isColor: false,
        },
        ...platforms,
    ];

    const handleChange = (value: string) => {
        const platform = find(options, { value });

        onPlatformChange(platform);
    };

    return (
        <Loader loading={fetchingPlatforms}>
            <PlatformSelection
                value={selectedPlatform.id}
                options={options}
                onChange={handleChange}
                sprite={imgix.editor.iconLib.previewIconSprite}
                offsetMap={PREVIEW_ICON_OFFSET_MAP}
            />
            <SearchInput
                onChange={onSearchChange}
                placeholder={t('common:search')}
                loading={fetchingSearch}
                value={searchTerm}
                className="mt-4"
            />
            {/* Empty results */}
            {!icons?.length && !fetchingSearch && !fetchingIcons && (
                <div className="mt-12 text-center text-gray-400">
                    <FaceFrownIcon className="mx-auto size-12" />
                    <p className="mt-4 text-sm font-medium">{t('no-results')}</p>
                    <p className="mt-2 text-xs">{t('try-again')}</p>
                </div>
            )}
            {/* Results */}
            <div
                className={cn(
                    'mt-2 grid grid-cols-3 gap-2 transition-all',
                    fetchingIcons && 'cursor-wait opacity-70',
                )}
            >
                {icons?.map((icon, index) => {
                    return (
                        <IconTile
                            key={`${icon?.id}-${index}`}
                            icon={icon}
                            active={blockIconId === icon?.id}
                            onSelect={onIconSelect}
                            loading={fetchingIcons}
                        />
                    );
                })}
            </div>
            {onLoadMore && !!icons.length && (
                <button
                    className="mt-4 flex h-10 w-full items-center justify-center rounded-lg bg-gray-100 text-center text-sm text-gray-400 hover:text-gray-600"
                    onClick={onLoadMore}
                >
                    {t('common:show-more')}{' '}
                    {fetchingPage && <ButtonLoadingSpinner className="ml-2 text-gray-600" />}
                </button>
            )}
        </Loader>
    );
};

export default LibraryView;
