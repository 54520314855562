import { defaultDropAnimation, DndContext, DragOverlay } from '@dnd-kit/core';
import { closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    horizontalListSortingStrategy,
    rectSortingStrategy,
    rectSwappingStrategy,
    SortableContext,
} from '@dnd-kit/sortable';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Children, isValidElement } from 'react';
import { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { UpdateChildrenOrderCommand } from '@/app/editor/commands/commands/updateChildrenOrderCommand';
import getHistoryController from '@/app/editor/commands/utils/HistoryControllers';
import SortableBlock from '@/app/editor/editor/components/ArtBoard/SortableBlockList/SortableBlock';
import { getThemeFont } from '@/app/editor/themes/helpers';
import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppSelector } from '@/core/redux/hooks';
import { EMPTY_OBJECT } from '@/utils/empty';

import Block from '../../../editor/components/ArtBoard/Block/Block.controller';

import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import type { ReactNode } from 'react';

export interface Props {
    children?: ReactNode;
    parentBlockId: string;
    sortingStrategy: 'vertical' | 'horizontal' | 'swap' | 'rect';
    nestedLevel: number;
    className?: string;
    noContext?: boolean;
}

const strategies = {
    vertical: verticalListSortingStrategy,
    horizontal: horizontalListSortingStrategy,
    rect: rectSortingStrategy,
    swap: rectSwappingStrategy,
};

const SortableList = ({
    children,
    parentBlockId,
    sortingStrategy,
    nestedLevel,
    className,
    noContext,
}: Props) => {
    const historyController = getHistoryController();
    const [activeDragId, setActiveDragId] = useState(null);
    const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);
    const themeFont = getThemeFont(previewOrActiveTheme);
    const fontWeight = themeFont === 'Inter' ? '300' : undefined;

    const childBlocks = Children.toArray(children);
    const childBlockProps = useMemo(
        () =>
            childBlocks.map((child) => {
                if (isValidElement(child)) {
                    return child.props;
                }
            }),
        [childBlocks],
    );
    const childBlockIds: string[] = useMemo(
        () => childBlockProps.map((props) => props.blockId),
        [childBlockProps],
    );

    // Create object of { [blockId] : parentProps }
    const propsFromParent = useMemo(() => {
        let props = EMPTY_OBJECT;

        childBlocks.forEach((child) => {
            if (isValidElement(child)) {
                props = {
                    ...props,
                    [child.props.blockId]: child.props.propsFromParent,
                };
            }
        });

        return props;
    }, [childBlocks]);

    const handleDragStart = ({ active }: DragStartEvent) => {
        if (!active) {
            return;
        }

        setActiveDragId(active.id);
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        if (active?.id !== over?.id && over?.id) {
            const oldIndex = active.data.current.sortable.index;
            const newIndex = over.data.current.sortable.index;

            // update block order
            const updatedBlockOrder = arrayMove(childBlockIds, oldIndex, newIndex);

            // Update in DB
            const updateChildOrderCommand = new UpdateChildrenOrderCommand(
                parentBlockId,
                updatedBlockOrder,
            );
            historyController.executeCommand(updateChildOrderCommand);
        }

        setActiveDragId(null);
    };

    const handleDragCancel = () => setActiveDragId(null);

    const sortableContext = (
        <SortableContext items={childBlockIds} strategy={strategies[sortingStrategy]}>
            <div className={className}>
                {childBlockIds.map((blockId: string, index) => (
                    <SortableBlock
                        key={blockId}
                        blockId={blockId}
                        nestedLevel={nestedLevel + 1}
                        propsFromParent={propsFromParent[blockId]}
                        artBoardIndex={index}
                    />
                ))}
            </div>
        </SortableContext>
    );

    if (noContext) {
        return sortableContext;
    }

    return (
        <DndContext
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            modifiers={sortingStrategy === 'vertical' ? [restrictToVerticalAxis] : undefined}
            collisionDetection={closestCenter}
        >
            {sortableContext}

            {/* Dragging block */}
            {createPortal(
                <DragOverlay
                    dropAnimation={defaultDropAnimation}
                    style={{ fontFamily: themeFont, fontWeight }}
                >
                    {activeDragId ? (
                        <Block
                            blockId={activeDragId}
                            isDragged
                            propsFromParent={propsFromParent[activeDragId]}
                        />
                    ) : null}
                </DragOverlay>,
                document.body,
            )}
        </DndContext>
    );
};

export default SortableList;
