import { EditorEngineRelativePosition } from '@/app/editor/engine/core/types/util';
import { insertNodeStep } from '@/app/editor/engine/core/utils/transaction/steps/insertNodeStep';
import { reinsertNodeAtIndexStep } from '@/app/editor/engine/core/utils/transaction/steps/reinsertNodeAtIndexStep';
import { PerspectiveEditorEngineActionName } from '@/app/editor/engine/types';
import { guid } from '@/app/editor/engine/utils/guid';
import { randomId } from '@/app/editor/engine/utils/randomId';

import type {
    EditorEngineActionCreatorPayload,
    EditorEngineParentId,
} from '@/app/editor/engine/core/types';
import type { PerspectiveEditorEngineActionCreator } from '@/app/editor/engine/types';

interface Payload extends EditorEngineActionCreatorPayload {
    /**
     * The ID of the block that will be child of the left column.
     */
    originalId: string;
    /**
     * The ID of the block that will be child of the right column.
     */
    targetId: string;
    /**
     * Whether original will be in the left or right column.
     */
    position: EditorEngineRelativePosition;
    /**
     * The index where the new layout will be inserted.
     */
    index: number;
}

/**
 * Action that will join two basic blocks into a single layout.
 */
export const joinBasicBlocksAction = (({
    args: { originalId, targetId, position, index },
    documentManager,
    nodeManager,
    extraContext: { backendEntities, clientSideComponents },
}) => {
    const leftId = position === EditorEngineRelativePosition.Before ? originalId : targetId;
    const rightId = position === EditorEngineRelativePosition.Before ? targetId : originalId;

    let leftOldParentId: EditorEngineParentId;
    let leftOldIndexId: number;
    let rightOldParentId: EditorEngineParentId;
    let rightOldIndexId: number;

    const forwardBesidesOperationId = guid();
    const backwardFirstInOperationId = guid();
    const backwardSecondInOperationId = guid();
    const newLayoutId = randomId();
    const leftColumnId = randomId();
    const rightColumnId = randomId();

    return {
        name: PerspectiveEditorEngineActionName.JoinBasicBlocks,
        debug: {},
        getTransaction() {
            return [
                // Creates a new layout
                insertNodeStep({
                    node: {
                        block: documentManager.makeLayout({
                            id: newLayoutId,
                        }),
                    },
                    index,
                    parentId: 'root',
                }),

                // Creates the left column
                insertNodeStep({
                    node: {
                        block: documentManager.makeColumn({
                            id: leftColumnId,
                        }),
                    },
                    index: 0,
                    parentId: newLayoutId,
                }),

                // Creates the right column
                insertNodeStep({
                    node: {
                        block: documentManager.makeColumn({
                            id: rightColumnId,
                        }),
                    },
                    index: 1,
                    parentId: newLayoutId,
                }),

                // Inserts the left block into the left column
                reinsertNodeAtIndexStep({
                    id: leftId,
                    newIndex: 0,
                    newParentId: leftColumnId,
                }),

                // Inserts the right block into the right column
                reinsertNodeAtIndexStep({
                    id: rightId,
                    newIndex: 0,
                    newParentId: rightColumnId,
                }),
            ];
        },
        onBeforeOptimisticForward() {
            leftOldParentId = nodeManager.getParentId(nodeManager.getNode(leftId));
            leftOldIndexId = nodeManager.getNodeIndex(leftId);
            rightOldParentId = nodeManager.getParentId(nodeManager.getNode(rightId));
            rightOldIndexId = nodeManager.getNodeIndex(rightId);
        },
        onAfterOptimisticForward() {
            const newLayout = nodeManager.getNode(newLayoutId)?.block;

            if (newLayout) {
                clientSideComponents.scrollToComponent.focusOn(newLayout);
            }
        },
        onAfterOptimisticBackward() {
            const original = nodeManager.getNode(originalId)?.block;

            if (original) {
                clientSideComponents.scrollToComponent.focusOn(original);
            }
        },
        async execute() {
            await backendEntities.joinBasicBlocks({
                operationId: forwardBesidesOperationId,
                componentId: originalId,
                newSiblingId: targetId,
                position,
                newLayoutVirtualId: newLayoutId,
                newLeftColumnVirtualId: leftColumnId,
                newRightColumnVirtualId: rightColumnId,
                resolveVirtualId: nodeManager.resolveVirtualId,
                mapVirtualIdToConcreteId: nodeManager.mapVirtualIdToConcreteId,
            });

            return {
                success: true,
            };
        },
        async undo() {
            await backendEntities.splitBasicBlocks({
                firstOperationId: backwardFirstInOperationId,
                secondOperationId: backwardSecondInOperationId,
                firstBasicComponent: {
                    componentId: leftId,
                    newIndex: leftOldIndexId,
                    newParentId: leftOldParentId,
                },
                secondBasicComponent: {
                    componentId: rightId,
                    newIndex: rightOldIndexId,
                    newParentId: rightOldParentId,
                },
                resolveVirtualId: nodeManager.resolveVirtualId,
                makeParentIdOptionalObject: nodeManager.makeParentIdOptionalObject,
            });

            return {
                success: true,
            };
        },
    };
}) satisfies PerspectiveEditorEngineActionCreator<Payload, {}>;
