/**
 * All types that relate to history (the user doing, undoing, and redoing
 * things).
 */

import type { FailedResult } from './util';
import type { useEditorEngineHistory } from '@/app/editor/engine/core/hooks/history/useEditorEngineHistory';
import type { EditorEngineAction } from '@/app/editor/engine/core/types/action';
import type {
    EditorEngineDefaultBaseTypeInput,
    EditorEngineDefaultTypeInput,
    EditorEngineError,
    EditorEngineExtraContextHolder,
} from '@/app/editor/engine/core/types/engine';
import type { EditorEngineStepName } from '@/app/editor/engine/core/types/step';
import type { Dispatch, SetStateAction } from 'react';

export type EditorEngineHistory = ReturnType<typeof useEditorEngineHistory>;

/**
 * The type of the timing of a lifecycle handler.
 */
export enum EditorEngineLifecycleHandlerTimingType {
    /**
     * A handler of this type is called before something else.
     */
    Pre = 'Pre',
    /**
     * A handler of this type is called after something else.
     */
    Post = 'Post',
}

/**
 * A transaction has a step, and a step can succeed or fail. This is the
 * type of the result.
 */
export type EditorEngineHistoryEntryTransactionStepResult =
    | {
          success: true;
          additionalDebugData?: object;
      }
    | {
          success: false;
          error: EditorEngineError;
          additionalDebugData?: object;
      };

/**
 * What every step gets as input when it is used.
 */
export type EditorEngineTransactionStepInput<
    TEditorEngineBaseTypeInput extends EditorEngineDefaultBaseTypeInput,
> = {
    documentManager: TEditorEngineBaseTypeInput['DocumentManager'];
    nodeManager: TEditorEngineBaseTypeInput['NodeManager'];
} & Omit<
    EditorEngineExtraContextHolder<TEditorEngineBaseTypeInput['ExtraContext']>,
    'documentManager' | 'nodeManager'
>;

/**
 * The definition of the step itself.
 */
export type EditorEngineTransactionStep<TExtraContext extends object> = {
    /**
     * The name of the step. This is used for debugging and logging.
     */
    name: EditorEngineStepName;
    /**
     * Additional debug data that can be used for debugging and logging.
     */
    debug: object;
    /**
     * The function that will be executed when the step is applied.
     * This is also called when redoing a step.
     */
    apply: (
        context: EditorEngineExtraContextHolder<TExtraContext>,
    ) => EditorEngineHistoryEntryTransactionStepResult;
    /**
     * The function that will be executed when the step is reverted.
     * This is called when undoing a step.
     */
    revert: (
        context: EditorEngineExtraContextHolder<TExtraContext>,
    ) => EditorEngineHistoryEntryTransactionStepResult;
    affectedNodes: string[];
};

/**
 * The developer that is writing a step is actually writing a function that
 * returns the step. This is the type of that function.
 */
export type EditorEngineTransactionStepCreator<
    TEditorEngineBaseTypeInput extends EditorEngineDefaultBaseTypeInput,
> = (
    input: EditorEngineTransactionStepInput<TEditorEngineBaseTypeInput>,
) => EditorEngineTransactionStep<TEditorEngineBaseTypeInput['ExtraContext']>;

/**
 * The step had a result, and the overall action has one also. To be
 * specific, this is the type of the result of the persisted update (either
 * execute or undo).
 */
export type EditorEngineHistoryEntryActionResult<TResult extends object = object> =
    | {
          success: true;
          result?: TResult;
          additionalDebugData?: object;
      }
    | {
          success: false;
          error: EditorEngineError;
          additionalDebugData?: object;
      };

/**
 * Regarding `RecordedX`
 * =====================
 *
 * Whenever a RecordedX is mentioned, that means we're not talking about the
 * specific action or step, but about a record of their execution.
 * We have a step, and that definition is unique. But a step can be executed
 * 5 times, which means we have 5 different RecordedSteps. Same for Actions.
 *
 * This is only used for knowing what happened in the history. It is already
 * known how the actions are defined from the code, but once the engine is
 * executing, a recorded step or a recorded action is what gives you more
 * information about a particular instance of an action or step being
 * executed (which direction? At what time? Did it succeed?).
 */

/**
 * The state of a recorded step.
 */
export enum EditorEngineHistoryEntryRecordedStepStatusType {
    /**
     * The step was not executed.
     */
    NotExecuted = 'NotExecuted',
    /**
     * The step is being executed optimistically.
     */
    ExecutingOptimisticUpdate = 'ExecutingOptimisticUpdate',
    /**
     * The step was executed successfully.
     */
    ExecutedSuccessfully = 'ExecutedSuccessfully',
    /**
     * The step failed.
     */
    Failed = 'Failed',
}

/**
 * The status of a recorded action.
 */
export enum EditorEngineHistoryEntryRecordedActionStatusType {
    /**
     * The action was not executed.
     */
    NotExecuted = 'NotExecuted',
    /**
     * The persisted change of the action is being executed.
     */
    ExecutingPersistedUpdate = 'ExecutingPersistedUpdate',
    /**
     * The persisted change of the action was executed successfully.
     */
    ExecutedSuccessfully = 'ExecutedSuccessfully',
    /**
     * This action failed because the persisted update failed.
     */
    Failed = 'Failed',
    /**
     * This action failed because one of its steps failed.
     * In other words, the optimistic update failed, and that makes the whole
     * action fail. In this case, the persisted update will be skipped.
     */
    FailedBySteps = 'FailedBySteps',
}

export interface EditorEngineHistoryEntryRecordedStepNotExecutedResult {
    type: EditorEngineHistoryEntryRecordedStepStatusType.NotExecuted;
}

/**
 * The direction of execution.
 */
export enum EditorEngineExecutionDirection {
    /**
     * This direction is used when executing or redoing an action or step.
     */
    Forward = 'forward',
    /**
     * This direction is used when undoing an action or step.
     */
    Backward = 'backward',
}

/**
 * The editor engine will often query some or all of the steps of an action.
 * This filter is used to determine which steps to return.
 */
export enum EditorEngineHistoryEntryStepForeachFilter {
    /**
     * Return all steps.
     */
    All = 'All',
    /**
     * Return only the steps that were executed successfully.
     */
    Successful = 'Successful',
    /**
     * Return only the steps that failed.
     */
    Failed = 'Failed',
}

/**
 * The type of error that occurred when executing a history entry.
 *
 * Note: this type contains similar values to `EditorEngineEventType`. However,
 * the two enums have different purposes. The event enum determines what event
 * has been emitted, therefore it will also include values for when a history
 * error has happened. This enum, instead, is specific to history errors.
 *
 * While the event enum can be used to determine what kind of event has
 * happened, this enum can be analyzed to determine the type of error that
 * occurred when executing a history entry.
 */
export enum EditorEngineHistoryExecuteErrorType {
    /**
     * A step has failed.
     */
    StepFailed = 'StepFailed',
    /**
     * An action has failed.
     */
    ActionFailed = 'ActionFailed',
    /**
     * A pre or post action hook has failed.
     */
    PrePostError = 'PrePostError',
    /**
     * An action has failed because one of its steps has failed.
     */
    ActionFailedBySteps = 'ActionFailedBySteps',
    /**
     * A rollback has failed.
     */
    RollbackFailed = 'RollbackFailed',
}

/**
 * When any history entry is executed, whether forward or backward, it will
 * return some kind of result. This is the type of that result.
 */
export enum EditorEngineHistoryExecutionResultType {
    /**
     * The execution was successful.
     */
    Successful = 'Successful',
    /**
     * The execution failed.
     */
    Failed = 'Failed',
    /**
     * The execution failed at rollback.
     */
    FailedAtRollback = 'FailedAtRollback',
}

export type EditorEngineHistoryExecutionResult =
    | {
          type: EditorEngineHistoryExecutionResultType.Successful;
          debug: object;
      }
    | {
          type: EditorEngineHistoryExecutionResultType.Failed;
          error: EditorEngineError;
      }
    | {
          type: EditorEngineHistoryExecutionResultType.FailedAtRollback;
          error: EditorEngineError;
      };

export interface EditorEngineHistoryEntryRecordedStepExecutingOptimisticUpdateResult {
    type: EditorEngineHistoryEntryRecordedStepStatusType.ExecutingOptimisticUpdate;
    optimisticUpdateStartedAt: number;
    direction: EditorEngineExecutionDirection;
}

export interface EditorEngineHistoryEntryRecordedStepFinishedResult {
    optimisticUpdateStartedAt: number;
    direction: EditorEngineExecutionDirection;
    executionDebug: object;
}

export interface EditorEngineHistoryEntryRecordedStepExecutedSuccessfullyResult
    extends EditorEngineHistoryEntryRecordedStepFinishedResult {
    optimisticUpdateFinishedAt: number;
    type: EditorEngineHistoryEntryRecordedStepStatusType.ExecutedSuccessfully;
}

export interface EditorEngineHistoryEntryRecordedStepFailedResult
    extends EditorEngineHistoryEntryRecordedStepFinishedResult {
    optimisticUpdateFailedAt: number;
    type: EditorEngineHistoryEntryRecordedStepStatusType.Failed;
    error: EditorEngineError;
}

export type EditorEngineHistoryEntryRecordedStepResult =
    | EditorEngineHistoryEntryRecordedStepNotExecutedResult
    | EditorEngineHistoryEntryRecordedStepExecutingOptimisticUpdateResult
    | EditorEngineHistoryEntryRecordedStepExecutedSuccessfullyResult
    | EditorEngineHistoryEntryRecordedStepFailedResult;

export interface EditorEngineHistoryEntryRecordedActionNotExecutedResult {
    type: EditorEngineHistoryEntryRecordedActionStatusType.NotExecuted;
}

export interface EditorEngineHistoryEntryRecordedActionExecutingPersistedUpdateResult {
    type: EditorEngineHistoryEntryRecordedActionStatusType.ExecutingPersistedUpdate;
    persistedUpdateStartedAt: number;
    direction: EditorEngineExecutionDirection;
    executionDebug: object;
}

export interface EditorEngineHistoryEntryRecordedActionFinishedResult {
    persistedUpdateStartedAt: number;
    direction: EditorEngineExecutionDirection;
    executionDebug: object;
}

export interface EditorEngineHistoryEntryRecordedActionExecutedSuccessfullyResult
    extends EditorEngineHistoryEntryRecordedActionFinishedResult {
    type: EditorEngineHistoryEntryRecordedActionStatusType.ExecutedSuccessfully;
    persistedUpdateFinishedAt: number;
}

export interface EditorEngineHistoryEntryRecordedActionFailedResult
    extends EditorEngineHistoryEntryRecordedActionFinishedResult {
    type: EditorEngineHistoryEntryRecordedActionStatusType.Failed;
    persistedUpdateFailedAt: number;
}

export interface EditorEngineHistoryEntryRecordedActionFailedByStepsResult {
    type: EditorEngineHistoryEntryRecordedActionStatusType.FailedBySteps;
    direction: EditorEngineExecutionDirection;
}

export type EditorEngineHistoryEntryRecordedActionResult =
    | EditorEngineHistoryEntryRecordedActionNotExecutedResult
    | EditorEngineHistoryEntryRecordedActionExecutingPersistedUpdateResult
    | EditorEngineHistoryEntryRecordedActionExecutedSuccessfullyResult
    | EditorEngineHistoryEntryRecordedActionFailedResult
    | EditorEngineHistoryEntryRecordedActionFailedByStepsResult;

export type EditorEngineHistoryEntryRecordedStep<TExtraContext extends object> = {
    id: string;
    transactionStep: EditorEngineTransactionStep<TExtraContext>;
    executionResult: EditorEngineHistoryEntryRecordedStepResult;
};

/**
 * The record of an execution of an action.
 */
export type EditorEngineHistoryEntryRecordedAction = {
    /**
     * This is the ID of the execution.
     * When an action is executed twice, there will be two executions, and each
     * will have a different ID. However, when undoing or redoing, the IDs of
     * existing records will stay the same.
     */
    id: string;
    /**
     * The related action.
     */
    action: EditorEngineAction<EditorEngineDefaultTypeInput>;
    /**
     * The result of the execution.
     */
    executionResult: EditorEngineHistoryEntryRecordedActionResult;
};

/**
 * Anything that can be undone or redone is an entry in the history.
 *
 * When undoing or redoing, the action will stay the same, since that is
 * only the abstract representation of what the user did. Recorded actions and
 * steps, however, are unique instances of the action and step being executed.
 *
 * So when undoing, their direction might change, as well as any timestamp and
 * possibly their result (if it is a different one than before).
 */
export type EditorEngineHistoryEntry<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> =
    {
        /**
         * The ID of the entry.
         */
        id: string;
        /**
         * The related action.
         */
        action: EditorEngineAction<TEditorEngineTypeInput>;
        /**
         * The recorded action.
         */
        recordedAction: EditorEngineHistoryEntryRecordedAction;
        /**
         * The recorded steps.
         */
        recordedSteps: EditorEngineHistoryEntryRecordedStep<
            TEditorEngineTypeInput['ExtraContext']
        >[];
    };

/**
 * A shortcut to a history entry using the default types.
 */
export type EditorEngineHistoryDefaultEntry =
    EditorEngineHistoryEntry<EditorEngineDefaultTypeInput>;

/**
 * The history is a collection of entries. This is the type for that collection.
 */
export type EditorEngineHistoryEntryCollection<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> = {
    /**
     * The entries in the history.
     */
    entries: EditorEngineHistoryEntry<TEditorEngineTypeInput>[];
    /**
     * The current entry.
     *
     * "Current" means that if a user attempts to undo an action, this is the
     * entry that is undone, while if they redo an action, the entry after
     * this one is redone.
     *
     * When the history is empty, this will be `null`.
     */
    current: EditorEngineHistoryEntry<TEditorEngineTypeInput> | null;
    /**
     * The index of the current entry in the entries array.
     */
    currentIndex: number;
};

/**
 * A shortcut to a history entry collection that uses the default types.
 */
export type EditorEngineHistoryDefaultEntryCollection =
    EditorEngineHistoryEntryCollection<EditorEngineDefaultTypeInput>;

export type EditorEngineHistoryEntryCollectionSetter = Dispatch<
    SetStateAction<Omit<EditorEngineHistoryDefaultEntryCollection, 'currentIndex'>>
>;

export type EditorEngineHistoryExecutionError<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> =
    | {
          type: EditorEngineHistoryExecuteErrorType.StepFailed;
          entry: EditorEngineHistoryEntry<TEditorEngineTypeInput>;
          step: EditorEngineHistoryEntryRecordedStep<TEditorEngineTypeInput['ExtraContext']>;
          error: EditorEngineError;
      }
    | {
          type: EditorEngineHistoryExecuteErrorType.ActionFailed;
          entry: EditorEngineHistoryEntry<TEditorEngineTypeInput>;
          action: EditorEngineHistoryEntryRecordedAction;
          error: EditorEngineError;
      }
    | {
          type: EditorEngineHistoryExecuteErrorType.PrePostError;
          entry: EditorEngineHistoryEntry<TEditorEngineTypeInput>;
          timing: EditorEngineLifecycleHandlerTimingType;
          error: EditorEngineError;
      }
    | {
          type: EditorEngineHistoryExecuteErrorType.ActionFailedBySteps;
          entry: EditorEngineHistoryEntry<TEditorEngineTypeInput>;
          action: EditorEngineHistoryEntryRecordedAction;
          error: EditorEngineError;
      }
    | {
          type: EditorEngineHistoryExecuteErrorType.RollbackFailed;
          entry: EditorEngineHistoryEntry<TEditorEngineTypeInput>;
          step: EditorEngineHistoryEntryRecordedStep<TEditorEngineTypeInput['ExtraContext']>;
          error: EditorEngineError;
      };

/**
 * What type of execution availability an entry has. "Execution availability"
 * refers to what kind of state an entry is in, regarding its execution.
 *
 * This is different than an "entry status" property that is set manually. It is
 * more of a derived attribute given the current state of the entry.
 *
 * Since this type does not refer to a property that can be found in the entry
 * itself, non-existing entries will also have an execution availability—but
 * in that case, it will be always `NoEntry`.
 */
export enum EditorEngineHistoryEntryExecutionAvailability {
    /**
     * The entry is not executing at the moment, and the last execution was
     * successful.
     */
    Successful = 'Successful',
    /**
     * The entry is not executing at the moment, and the last execution failed.
     */
    Failed = 'Failed',
    /**
     * The entry that is being queried does not exist.
     */
    NoEntry = 'NoEntry',
    /**
     * The optimistic update of the entry is ongoing.
     */
    OngoingOptimisticUpdate = 'OngoingOptimisticUpdate',
    /**
     * The entry is queued for a persisted update.
     */
    QueuedForPersistedUpdate = 'QueuedForPersistedUpdate',
    /**
     * The persisted update of the entry is ongoing.
     */
    OngoingPersistedUpdate = 'OngoingPersistedUpdate',
    /**
     * This is the default execution availability when another one cannot be
     * determined.
     */
    UnknownStatus = 'UnknownStatus',
}

/**
 * The positioning of an entry in time.
 */
export enum EditorEngineHistoryEntryPositioningType {
    /**
     * The entry is not part of the history.
     */
    NotFound = 'NotFound',
    /**
     * This is the current entry.
     */
    Current = 'Current',
    /**
     * The entry is in the future.
     */
    Future = 'Future',
    /**
     * The entry is in the past.
     */
    Past = 'Past',
}

/**
 * When an action is executed, its persisted update is queued for processing.
 * The queue is handled by `useProcessingQueue`. This hook is generic and can
 * handle any kind of object. This is the type that is passed as the generic
 * parameter of `useProcessingQueue`.
 *
 * @see {useProcessingQueue}
 */
export type EditorEnginePersistedUpdate = {
    /**
     * The entry this persisted update is part of.
     */
    entry: EditorEngineHistoryDefaultEntry;
    /**
     * The direction of the execution.
     */
    direction: EditorEngineExecutionDirection;
    /**
     * The recorded action that will be updated once the processing queue
     * executes this update.
     */
    recordedAction: EditorEngineHistoryEntryRecordedAction;
    /**
     * Whether the update is re-executing (undo or redo).
     */
    isReExecution: boolean;
};

/**
 * When the history is done going through the steps of an action, it will return
 * a result. This is the type of that result.
 */
export type EditorEngineHistoryForeachStepExecutionResult<
    TResult extends EditorEngineHistoryEntryTransactionStepResult,
    TExtraContext extends object,
> =
    | {
          /**
           * Not all steps were executed due to a controlled failure.
           * This is usually the case when a step intentionally returns a
           * failed result.
           */
          finished: false;
          /**
           * This indicates that a crash (like an exception being thrown) did not
           * cause a failure.
           */
          crash: false;
          /**
           * The result of the execution at the moment of the controlled
           * failure.
           *
           * If the step failed, this will also contain an `EditorEngineError`
           * at the `error` property.
           */
          result: FailedResult<TResult>;
          /**
           * The step that caused the controlled failure.
           */
          step: EditorEngineHistoryEntryRecordedStep<TExtraContext>;
      }
    | {
          /**
           * Not all steps were executed due to a crash.
           * This is the case when an exception is thrown during the execution of a
           * step.
           */
          finished: false;
          /**
           * The step that caused the crash.
           */
          step: EditorEngineHistoryEntryRecordedStep<TExtraContext>;
          /**
           * This indicates that a crash (like an exception being thrown) happened.
           */
          crash: true;
          /**
           * The error that caused the crash.
           */
          error: EditorEngineError;
      }
    | {
          /**
           * All steps were executed successfully.
           */
          finished: true;
          /**
           * Additional debug data that can be used for debugging and logging.
           */
          additionalDebugData: object;
      };
