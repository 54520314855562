import { NAME } from '@/app/crm/constants';

import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';

import { PropertyView, UniqueFieldName } from '@/app/crm/types';
import { hideModal } from '@/app/modals/models/modals';
import { showToast } from '@/app/toasts/utils/showToast';
import { crmApiGet, crmApiPatch, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { bulkUpdateContacts } from './contacts';
import { getTableColumnOrder, setTableColumnOrder } from './table';
import {
    getSchemaProperty,
    optimisticallyAddSchemaProperty,
    optimisticallyDeleteSchemaProperty,
    optimisticallyUpdateSchemaProperty,
    scrollToLastPropertyAndOpenMenu,
} from '../helpers';

import type { FieldType, SchemaResource, SelectOption, ThemeColor } from '@/app/crm/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    fetching: boolean;
    updating: boolean;
    deleting: boolean;
    schema: SchemaResource;
}

const initialState: State = {
    fetching: false,
    updating: false,
    deleting: false,
    schema: undefined,
};

const schemaSlice = createSlice({
    name: `${NAME}/schema`,
    initialState,
    reducers: {
        setFetching(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                fetching: action.payload,
            };
        },
        setUpdating(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                updating: action.payload,
            };
        },
        setDeleting(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                deleting: action.payload,
            };
        },
        setSchema(state, action: PayloadAction<SchemaResource>) {
            return {
                ...state,
                schema: action.payload,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======
export const { setFetching, setUpdating, setDeleting, setSchema, reset } = schemaSlice.actions;

// === Selectors ======
export const getFetching = (state: AppState) => state[NAME].schemaReducer.fetching;
export const getUpdating = (state: AppState) => state[NAME].schemaReducer.updating; // TODO: Not used (yet?)
export const getDeleting = (state: AppState) => state[NAME].schemaReducer.deleting;
export const getSchema = (state: AppState) => state[NAME].schemaReducer.schema;

// === Thunks ======
export const fetchSchema =
    (campaignId: string, silent?: boolean): AppThunk<Promise<SchemaResource>> =>
    async (dispatch) => {
        dispatch(setFetching(true));

        try {
            const response = await crmApiGet(`/schemas?campaignId=${campaignId}`);

            const schemaData = getDataFromResponse<SchemaResource[]>(response);
            const schema = schemaData[0];

            dispatch(setSchema(schema));

            return schema;
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to fetch schema', silent });
        } finally {
            dispatch(setFetching(false));
        }
    };

export const updateSchema = async ({
    updatedSchema,
    campaignId,
}: {
    updatedSchema: SchemaResource;
    campaignId: string;
}) => {
    await crmApiPatch(`/schemas/${updatedSchema?.id}?campaignId=${campaignId}`, {
        data: updatedSchema?.attributes,
    });
};

export const addSchemaProperty =
    ({
        campaignId,
        propertyType,
        autofocus,
    }: {
        campaignId: string;
        propertyType: FieldType;
        autofocus?: boolean;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);
        const columnOrder = getTableColumnOrder(state);

        dispatch(setUpdating(true));

        try {
            const { schema: updatedSchema, property: newProperty } =
                optimisticallyAddSchemaProperty({
                    schema,
                    propertyType,
                });

            await dispatch(setSchema(updatedSchema));
            await dispatch(setTableColumnOrder([...columnOrder, newProperty.fieldName]));

            if (autofocus) {
                // Timeout to make sure the newly added property is in the DOM
                setTimeout(() => {
                    scrollToLastPropertyAndOpenMenu();
                }, 50);
            }

            await updateSchema({ campaignId, updatedSchema });

            return updatedSchema;
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to add schema property' });
        } finally {
            dispatch(setUpdating(false));
        }
    };

export const updateSchemaProperty =
    ({
        fieldName,
        fieldToUpdate,
        newValue,
        campaignId,
        shouldSetSchema = true,
    }: {
        fieldName: string;
        fieldToUpdate: string;
        newValue: any;
        campaignId: string;
        shouldSetSchema?: boolean;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);

        dispatch(setUpdating(true));

        try {
            const propertyToUpdate = getSchemaProperty(schema, fieldName);
            const updatedProperty = cloneDeep(propertyToUpdate);

            set(updatedProperty, fieldToUpdate, newValue);

            const updatedSchema = optimisticallyUpdateSchemaProperty({
                schema,
                fieldName,
                updatedProperty,
            });

            if (shouldSetSchema) {
                dispatch(setSchema(updatedSchema));
            }

            await updateSchema({ campaignId, updatedSchema });

            return updatedSchema;
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to update schema property' });
        } finally {
            dispatch(setUpdating(false));
        }
    };

export const deleteSchemaProperty =
    ({
        fieldName,
        campaignId,
    }: {
        fieldName: string;
        campaignId: string;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);
        const columnOrder = getTableColumnOrder(state);

        dispatch(setDeleting(true));

        try {
            const updatedSchema = optimisticallyDeleteSchemaProperty({
                schema,
                fieldName,
            });

            dispatch(setSchema(updatedSchema));
            dispatch(setTableColumnOrder(columnOrder.filter((columnId) => columnId !== fieldName)));

            await updateSchema({ campaignId, updatedSchema });

            return updatedSchema;
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to delete schema property' });
        } finally {
            dispatch(setDeleting(false));
        }
    };

export const updateSchemaPropertyOption =
    ({
        fieldName,
        index,
        updatedOption = EMPTY_OBJECT,
        isDefaultOption = false,
        campaignId,
    }: {
        fieldName: string;
        index: number;
        updatedOption: { value?: string; color?: ThemeColor } & Omit<SelectOption, 'value'>;
        isDefaultOption?: boolean;
        campaignId: string;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);

        dispatch(setUpdating(true));

        try {
            const propertyToUpdate = getSchemaProperty(schema, fieldName);
            const propertyOptionsToUpdate = [...(propertyToUpdate?.options || EMPTY_ARRAY)];

            const optionToUpdate = propertyOptionsToUpdate[index];

            propertyOptionsToUpdate.splice(index, 1, { ...optionToUpdate, ...updatedOption });

            const updatedSchema = optimisticallyUpdateSchemaProperty({
                schema,
                fieldName,
                updatedProperty: {
                    ...propertyToUpdate,
                    defaultValue:
                        isDefaultOption && updatedOption.value
                            ? updatedOption.value
                            : propertyToUpdate.defaultValue,
                    options: propertyOptionsToUpdate,
                },
            });

            dispatch(setSchema(updatedSchema));

            await updateSchema({ campaignId, updatedSchema });

            return updatedSchema;
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to update schema property option' });
            dispatch(setSchema(schema));
        } finally {
            dispatch(setUpdating(false));
        }
    };

export const addSchemaPropertyOption =
    ({
        option = EMPTY_OBJECT,
        fieldName,
        campaignId,
    }: {
        option?: { value?: string; color?: ThemeColor };
        fieldName: string;
        campaignId: string;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);

        dispatch(setUpdating(true));

        try {
            const propertyToUpdate = getSchemaProperty(schema, fieldName);
            const propertyOptionsToUpdate = [...(propertyToUpdate?.options || EMPTY_ARRAY)];

            propertyOptionsToUpdate.push({
                value: `Option ${propertyOptionsToUpdate.length + 1}`,
                ...option,
            });

            const updatedSchema = optimisticallyUpdateSchemaProperty({
                schema,
                fieldName,
                updatedProperty: {
                    ...propertyToUpdate,
                    options: propertyOptionsToUpdate,
                },
            });

            dispatch(setSchema(updatedSchema));

            await updateSchema({ campaignId, updatedSchema });

            return updatedSchema;
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to add schema property option' });
        } finally {
            dispatch(setUpdating(false));
        }
    };

export const deleteSchemaPropertyOption =
    ({
        fieldName,
        index,
        campaignId,
    }: {
        fieldName: string;
        index: number;
        campaignId: string;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);

        dispatch(setDeleting(true));

        try {
            const propertyToUpdate = getSchemaProperty(schema, fieldName);
            const propertyOptionsToUpdate = [...(propertyToUpdate?.options || EMPTY_ARRAY)];

            propertyOptionsToUpdate.splice(index, 1);

            const updatedSchema = optimisticallyUpdateSchemaProperty({
                schema,
                fieldName,
                updatedProperty: {
                    ...propertyToUpdate,
                    options: propertyOptionsToUpdate,
                },
            });

            dispatch(setSchema(updatedSchema));

            await updateSchema({ campaignId, updatedSchema });

            return updatedSchema;
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to delete schema property option' });
        } finally {
            dispatch(setDeleting(false));
        }
    };

export const deleteStatusPropertyOption =
    ({
        campaignId,
        status,
        newStatus,
        count,
        shouldHideModal = true,
    }: {
        campaignId: string;
        status: string;
        newStatus?: string;
        count?: number;
        shouldHideModal?: boolean;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);

        dispatch(setDeleting(true));

        try {
            const statusProperty = getSchemaProperty(schema, UniqueFieldName.status);
            const propertyIndex = findIndex(
                statusProperty?.options,
                (option) => option.value === status,
            );
            const statusOptionsToUpdate = [...(statusProperty?.options || EMPTY_ARRAY)];

            statusOptionsToUpdate.splice(propertyIndex, 1);

            const updatedSchema = optimisticallyUpdateSchemaProperty({
                schema,
                fieldName: UniqueFieldName.status,
                updatedProperty: {
                    ...statusProperty,
                    options: statusOptionsToUpdate,
                },
            });

            dispatch(setSchema(updatedSchema));

            if (count !== 0) {
                dispatch(
                    bulkUpdateContacts({
                        campaignId,
                        fieldName: UniqueFieldName.status,
                        oldValue: status,
                        newValue: newStatus,
                    }),
                );
            }

            await updateSchema({ campaignId, updatedSchema });

            if (shouldHideModal) {
                dispatch(hideModal());
            }

            showToast({
                type: 'success',
                message: `${NAME}:delete-status-option-success`,
            });

            return updatedSchema;
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to delete status property option' });
        } finally {
            dispatch(setDeleting(false));
        }
    };

export const setSchemaPropertyVisible =
    ({
        fieldName,
        visible,
        campaignId,
        view,
    }: {
        fieldName: string;
        visible: boolean;
        campaignId: string;
        view: PropertyView;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);

        dispatch(setUpdating(true));

        try {
            const propertyToUpdate = getSchemaProperty(schema, fieldName);
            const visibility = {
                [PropertyView.sidebar]: propertyToUpdate?.visible ?? true,
                [PropertyView.table]: true,
                [PropertyView.kanban]: false,
                ...(propertyToUpdate?.visibility ?? {}),
            };

            const updatedSchema = optimisticallyUpdateSchemaProperty({
                schema,
                fieldName,
                updatedProperty: {
                    ...propertyToUpdate,
                    visible,
                    visibility: {
                        ...visibility,
                        [view]: visible,
                    },
                },
            });

            dispatch(setSchema(updatedSchema));

            await updateSchema({ campaignId, updatedSchema });

            return updatedSchema;
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to toggle property visibility' });
        } finally {
            dispatch(setUpdating(false));
        }
    };

export default schemaSlice.reducer;
