import { getLetterByIndex } from '@/utils/alphabet';

import type { PageType } from '@/app/editor/pages/types';

export const createPageIcon = (index: number, pageType: PageType) => {
    // Result pages have letters
    if (pageType === 'results') {
        if (index > 25) {
            const suffix = Math.floor(index / 26);
            const letterIndex = index - 26 * suffix;
            const letter = getLetterByIndex(letterIndex).toUpperCase();

            return `${letter}${suffix}`;
        }

        return getLetterByIndex(index).toUpperCase();
    }

    return (index + 1).toString();
};
