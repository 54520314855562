import { Children, isValidElement, cloneElement } from 'react';

import SortableList from '@/app/editor/blocks/components/_wrapper/SortableList';
import { Accordion } from '@/app/editor/blocks/components/Accordion/NewComponent';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({ children, artBoardIndex, box, blockId, nestedLevel, isPreview, isDragged }) => {
        const { isInColumn } = useLayoutInfo(blockId, isPreview);
        const wrapperClass = 'flex flex-col space-y-4';

        const previewChildBlocks = Children.map(children, (child) => {
            return isValidElement<BlockProps>(child)
                ? cloneElement(child, {
                      propsFromParent: { componentType: 'accordionItem' },
                  })
                : null;
        });

        return (
            <Box
                box={getBoxAttributes({ box, isInColumn })}
                isDragged={isDragged}
                artBoardIndex={artBoardIndex}
            >
                {isPreview ? (
                    <div className={wrapperClass}>{previewChildBlocks}</div>
                ) : (
                    <SortableList
                        parentBlockId={blockId}
                        sortingStrategy="vertical"
                        nestedLevel={nestedLevel}
                        className={wrapperClass}
                    >
                        {children}
                    </SortableList>
                )}
            </Box>
        );
    },
    Accordion,
);

Component.displayName = 'Accordion';

export default Component;
