import { StarIcon } from '@heroicons/react/24/solid';

interface Props {
    value: string;
}

export const RatingInput = ({ value }: Props) => {
    return (
        <div className="mt-1 flex space-x-2">
            {Array.from({ length: parseInt(value) }).map((_, index) => (
                <StarIcon className="size-4 text-amber-500" key={`star-${index}`} />
            ))}
        </div>
    );
};
