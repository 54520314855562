import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import findIndex from 'lodash/findIndex';

import { changeMediaSize } from '@/app/editor/blocks/models/mediaSize';
import { useAppDispatch } from '@/core/redux/hooks';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import RadioSlider from '@/ui/components/RadioSlider';

import type { Size } from '@/app/editor/blocks/types';
import type { RadioSliderSelection } from '@/ui/types';
import type { FieldInputProps } from 'react-final-form';
import type { WrappedFieldInputProps } from 'redux-form';

const renderMap = (size: string): JSX.Element => {
    switch (size) {
        case 'CUSTOM':
            return <EllipsisHorizontalIcon className="w-4 scale-[2]" />;
        default:
            return <span className="font-medium">{size}</span>;
    }
};

export interface Props {
    input?: WrappedFieldInputProps | FieldInputProps<Size>;
    value?: string;
    className?: string;
    valueMap?: {
        S: {
            value: string;
            tooltip?: string;
        };
        M: {
            value: string;
            tooltip?: string;
        };
        L: {
            value: string;
            tooltip?: string;
        };
        XL: {
            value: string;
            tooltip?: string;
        };
        CUSTOM?: {
            value: string;
            tooltip?: string;
        };
    };
    onSizeChange?: (size: RadioSliderSelection) => void;
    submit?: () => void;
}

const GenericSize = ({ input, value, onSizeChange, submit, valueMap, className }: Props) => {
    const dispatch = useAppDispatch();

    const sizes = Object.keys(valueMap).map((size) => {
        return {
            name: size,
            value: valueMap[size].value,
            render: () => renderMap(size),
            tooltip: valueMap[size].tooltip,
        };
    });

    const index = findIndex(sizes, { value: input?.value || value });
    const selectedSizeIndex = index === -1 ? 0 : index;

    const handleChange = (size: RadioSliderSelection) => {
        const { onChange } = input || {};

        if (onChange) {
            onChange(size.value);
        } else {
            dispatch(changeMediaSize(size.value));
        }

        if (onSizeChange) {
            onSizeChange(size);
        }

        if (submit) {
            setTimeout(submit);
        }
    };

    return (
        <FieldContainer className={className}>
            <RadioSlider items={sizes} activeIndex={selectedSizeIndex} onChange={handleChange} />
        </FieldContainer>
    );
};

GenericSize.defaultProps = {
    valueMap: {
        S: { value: 'small' },
        M: { value: 'medium' },
        L: { value: 'large' },
        XL: { value: 'xLarge' },
    },
};

export default GenericSize;
