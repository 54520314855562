export const Forms = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <rect opacity="0.8" x="4" y="6" width="16" height="3" rx="1" fill="white" />
            <rect opacity="0.8" x="4" y="11" width="16" height="3" rx="1" fill="white" />
            <rect x="4" y="16" width="16" height="3" rx="1" fill="white" />
        </svg>
    );
};
