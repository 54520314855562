import { reset as resetCustomFonts } from './customFonts';
import { reset as resetDeleteTheme } from './deleteTheme';
import { reset as resetNewTheme } from './newTheme';
import { reset as resetThemes } from './themes';
import { reset as resetUpdateTheme } from './updateTheme';

import type { AppThunk } from '@/core/redux/types';

const reset =
    (withThemes = true): AppThunk =>
    (dispatch) => {
        if (withThemes) {
            dispatch(resetThemes());
        }
        dispatch(resetNewTheme());
        dispatch(resetUpdateTheme());
        dispatch(resetDeleteTheme());
        dispatch(resetCustomFonts());
    };

export default reset;
