import { useContext } from 'react';

import { propsFromParentContext } from '@/app/editor/engine/core/context/propsFromParentContext';

/**
 * Hook to get the props from parent.
 */
export const usePropsFromParent = () => {
    return useContext(propsFromParentContext);
};
