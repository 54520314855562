import { cn } from '@/utils/cn';
import { getFlagByCountryCode } from '@/utils/countries';

import type { CountryCode } from '@/utils/countries';
import type { HTMLProps } from 'react';

interface Props {
    code: CountryCode;
    className?: HTMLProps<HTMLDivElement>['className'];
}

const FlagEmoji = ({ code, className }: Props) => (
    <span className={cn('text-xl leading-none', className)}>{getFlagByCountryCode(code)}</span>
);

export default FlagEmoji;
