import { DraggableConfigurationHelper } from '@/app/editor/engine/core/utils/dragAndDrop/configuration';

import type {
    EditorEngineDraggableConfiguration,
    EditorEngineDefaultTypeInput,
} from '@/app/editor/engine/core/types';

/**
 * A default draggable configuration that disables dragging for all nodes.
 */
export const DefaultDraggableConfiguration =
    DraggableConfigurationHelper.alwaysDisable satisfies EditorEngineDraggableConfiguration<EditorEngineDefaultTypeInput>;
