import type { BoxProps } from '@/app/editor/blocks/types';

export const getBgImageStyles = (bgImage: BoxProps['bgImage']) => {
    const { visible, src, focalPoint, opacity } = bgImage || {};

    return {
        backgroundImage: visible ? `url(${src})` : '',
        opacity: opacity && !isNaN(opacity) ? opacity / 100 : 0,
        backgroundPosition: focalPoint ? `${focalPoint.x}% ${focalPoint.y}%` : 'center',
    };
};
