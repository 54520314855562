import { createSlice } from '@reduxjs/toolkit';

import { apiDelete, apiGet, apiPatch, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';

import type {
    ResultMappingPayload,
    ResultMappingResource,
    UpdateBlockInMappingArgs,
    UpdateMappingArgs,
} from '@/app/editor/pages/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    mappings: { [campaignId: string]: ResultMappingResource };
}

const initialState: State = {
    mappings: EMPTY_OBJECT,
};

export const resultMappingSlice = createSlice({
    name: `editor/${NAME}/resultMapping`,
    initialState,
    reducers: {
        setResultMapping(
            state,
            action: PayloadAction<{ campaignId: string; mapping: ResultMappingResource }>,
        ) {
            return {
                ...state,
                mappings: {
                    ...state.mappings,
                    [action.payload.campaignId]: action.payload.mapping,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { reset, setResultMapping } = resultMappingSlice.actions;

// === Selectors ======

export const getResultMappingByCampaignId = (state: AppState, campaignId: string) =>
    state[NAME]?.resultMappingReducer?.mappings[campaignId];

// === Thunks ======

export const fetchResultMapping =
    (mappingId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await apiGet(`/mappings/result/${mappingId}`);

            const mapping: ResultMappingResource = getDataFromResponse(response);
            const campaignId = mapping?.relationships?.campaign?.data?.id;

            if (campaignId) {
                dispatch(setResultMapping({ campaignId, mapping }));
            } else {
                throw new Error('Attempt to setResultMapping with undefined campaignId');
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching result mapping failed:' });
        }
    };

export const removeBlockFromMapping =
    (
        mappingId: string,
        pageId: string,
        blockId: string,
    ): AppThunk<Promise<ResultMappingResource>> =>
    async () => {
        try {
            const response = await apiDelete(
                `/mappings/result/${mappingId}/pages/${pageId}/components/${blockId}`,
            );

            return getDataFromResponse(response);
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'deleting block from result mapping:' });
        }
    };

export const updateBlockInMapping =
    ({
        mappingId,
        pageId,
        blockId,
        mappingData,
    }: UpdateBlockInMappingArgs): AppThunk<Promise<ResultMappingResource>> =>
    async () => {
        try {
            const response = await apiPatch(
                `/mappings/result/${mappingId}/pages/${pageId}/components/${blockId}`,
                { data: mappingData },
            );

            return getDataFromResponse(response);
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: 'updating block in result mapping failed:',
            });
        }
    };

export const updateResultMapping =
    ({ mappingId, pageId, blockId, resultId }: UpdateMappingArgs): AppThunk =>
    async (dispatch) => {
        const payload: ResultMappingPayload = {
            source: blockId,
            destination: resultId,
            value: 1,
        };

        const action =
            resultId === 'none'
                ? removeBlockFromMapping(mappingId, pageId, blockId)
                : updateBlockInMapping({ mappingId, pageId, blockId, mappingData: payload });

        try {
            const updatedMapping = await dispatch(action);

            const campaignId = updatedMapping?.relationships?.campaign?.data?.id;

            if (campaignId) {
                dispatch(setResultMapping({ campaignId, mapping: updatedMapping }));
            } else {
                throw new Error('Attempt to setResultMapping with undefined campaignId');
            }
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating result mapping failed:' });
        }
    };

export default resultMappingSlice.reducer;
