import type { Over } from '@dnd-kit/core';

/**
 * Validates an over object, which is what the drag-and-drop library
 * provides when an item is dragged over a container.
 *
 * `Over` refers to the object that is currently under the dragged item, and is
 * a candidate for being a drop target.
 */
export const isValidOver = (
    over: Over,
): over is Over & {
    data: {
        current: {
            sortable: {
                containerId: string;
                items: string[];
                index: number;
            };
        };
    };
} => {
    // todo(editorengine): consider zod for validation
    return (
        over?.data?.current &&
        Boolean(over.data.current['sortable']) &&
        typeof over.data.current['sortable']['containerId'] === 'string' &&
        Array.isArray(over.data.current['sortable']['items']) &&
        over.data.current['sortable']['items'].every((item) => typeof item === 'string') &&
        typeof over.data.current['sortable']['index'] === 'number'
    );
};
