import Item from './Item';

// Types

export interface ItemData<T extends string = string> {
    name: T;
    value: string;
    render: () => JSX.Element;
    active?: boolean;
    tooltip?: string;
    hotkey?: string[];
}

export interface Props<T extends string = string> {
    items: ItemData<T>[];
    onItemClick: (item: ItemData<T>) => void;
    activeItem?: string;
}

export const ButtonPanel = <T extends string>({
    items,
    onItemClick,
    activeItem,
    ...rest
}: Props<T>) => {
    return (
        <div
            className="flex h-12 w-full rounded-lg bg-gray-100 px-1 text-sm hover:border-blue-500"
            {...rest}
        >
            {items.map((item) => {
                return (
                    <Item key={item.name} item={item} onClick={onItemClick} activeItem={activeItem}>
                        {item.render()}
                    </Item>
                );
            })}
        </div>
    );
};

export default ButtonPanel;
