import { EditorEngineLogLevel } from '@/app/editor/engine/core/types/log';

import type {
    EditorEngineActionCreator,
    EditorEngineActionCreatorPayload,
    EditorEngineActionEnhancement,
    EditorEngineDefaultTypeInput,
    EditorEngineEnhancedActionCreator,
    EditorEngineHistory,
} from '@/app/editor/engine/core/types';
import type { EditorEngineLogger } from '@/app/editor/engine/core/types/log';

/**
 * The developer will provide a map of actions. The editor engine will enhance
 * those actions with more functionality, such as the ability to enqueue them
 * into the history.
 */
export const enhanceActions = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
    TEnhancement extends EditorEngineActionEnhancement<TEditorEngineTypeInput>,
>({
    history,
    providedActions,
    actionEnhancement,
    documentManager,
    nodeManager,
    extraContext,
    baseEnhancement,
    logger,
}: {
    /**
     * The history instance.
     */
    history: EditorEngineHistory;
    /**
     * The actions provided by the developer.
     */
    providedActions: TEditorEngineTypeInput['ProvidedActions'];
    /**
     * This is a further enhancement that the editor engine will apply to
     * each of the actions provided by the developer.
     */
    actionEnhancement: TEnhancement;
    /**
     * The document manager instance.
     */
    documentManager: TEditorEngineTypeInput['DocumentManager'];
    /**
     * The node manager instance.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
    /**
     * Extra context to be provided.
     */
    extraContext: TEditorEngineTypeInput['ExtraContext'];
    /**
     * The base enhancement that the editor engine will apply to each of the
     * actions provided by the developer.
     *
     * This enhancement is not provided by the developer, but by the editor
     * engine itself.
     *
     * The network simulation enhancement, for example, will introduce a
     * controlled delay or failure to the actions depending on some parameters,
     * and is always applied in the development environment.
     */
    baseEnhancement: EditorEngineActionEnhancement<TEditorEngineTypeInput>;
    /**
     * The Editor Engine logger.
     */
    logger: EditorEngineLogger;
}) => {
    return Object.entries(providedActions).reduce(
        (acc, [key, action]) => {
            const enhancedAction = baseEnhancement(
                actionEnhancement(
                    action as EditorEngineActionCreator<TEditorEngineTypeInput, object>,
                ),
            );

            return {
                ...acc,
                [key]: {
                    enqueue: (args: Record<string, unknown> & EditorEngineActionCreatorPayload) => {
                        const log = logger.getLogFunctionWithDefaultContext({
                            invocationContext: args?.invocationContext ?? {},
                            action: key,
                        });

                        log({
                            level: EditorEngineLogLevel.Info,
                            message: 'Enqueueing action',
                        });

                        history.enqueue(
                            enhancedAction({
                                args,
                                documentManager,
                                nodeManager,
                                extraContext,
                                log,
                            }),
                        );
                    },
                } satisfies EditorEngineEnhancedActionCreator<
                    TEditorEngineTypeInput,
                    EditorEngineActionCreator<TEditorEngineTypeInput, object>
                >,
            };
        },
        {} as TEditorEngineTypeInput['Actions'],
    );
};
