import get from 'lodash/get';
import { memo } from 'react';

import { getBlockComponent } from '@/app/editor/blocks/helpers';
import { getPreviewById } from '@/app/editor/sections/models/sections';
import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppSelector } from '@/core/redux/hooks';

import TemplateBlockView from './TemplateBlock.view';

import TemplateBlock from './index';

import type { PropsFromParent } from '@/app/editor/blocks/types';
import type { RelationshipObject } from '@/core/api/types';
import type { FC } from 'react';

export interface Props {
    previewBlockId: string;
    nestedLevel: number;
    // can be passed in `Component.tsx` of parent block to
    // its child (this block), e.g. MediaQuestion
    propsFromParent?: PropsFromParent;
}

const TemplateBlockController: FC<Props> = ({ previewBlockId, nestedLevel, propsFromParent }) => {
    const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);
    const previewBlock = useAppSelector((state) => getPreviewById(state, previewBlockId));
    const blockComponentType = previewBlock?.attributes?.componentType?.replace('Template', '');

    // Get according Block from 'editor/blocks/component'
    const BlockComponent = getBlockComponent(blockComponentType);

    if (typeof BlockComponent !== 'undefined') {
        // Get children of Block
        const childComponents: RelationshipObject[] = get(
            previewBlock,
            'relationships.components.data',
            [],
        );

        // Has children: Render Block and with recursive children (Block itself must render `children`)
        if (childComponents.length) {
            return (
                <TemplateBlockView
                    previewBlock={previewBlock}
                    component={BlockComponent}
                    propsFromParent={propsFromParent}
                    nestedLevel={nestedLevel}
                    activeTheme={previewOrActiveTheme}
                >
                    {childComponents.map((child) => {
                        // Recursion
                        return (
                            <TemplateBlock
                                key={child.id}
                                previewBlockId={child.id}
                                nestedLevel={nestedLevel + 1}
                                propsFromParent={propsFromParent}
                            />
                        );
                    })}
                </TemplateBlockView>
            );
        }

        // No children: Just render the Block
        return (
            <TemplateBlockView
                previewBlock={previewBlock}
                component={BlockComponent}
                propsFromParent={propsFromParent}
                nestedLevel={nestedLevel}
                activeTheme={previewOrActiveTheme}
            />
        );
    }

    return null;
};

TemplateBlockController.displayName = 'TemplateBlockController';

export default memo(TemplateBlockController);
