export const secondsToHms = (seconds: number) => {
    if (isNaN(seconds)) {
        return '00:00:00';
    }

    return new Date(seconds * 1000).toISOString().slice(11, 19);
};

export const hmsToSeconds = (hms: string): number => {
    const parts = hms.split(':');

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return +parts[0] * 60 * 60 + +parts[1] * 60 + +parts[2];
};
