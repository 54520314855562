import { AUTH_TOKEN_COOKIE } from '@/app/auth/constants';

import axios from 'axios';

import { guid } from '@/app/editor/engine/utils/guid';
import { genericApiResponseErrorCallback, showNetworkError, showWarning } from '@/core/api/helper';
import { withRetry } from '@/core/api/middlewares';
import { loadCookie } from '@/utils/cookies';
import { reportError } from '@/utils/sentry';

import { CORRELATION_ID_HEADER } from './constants';

import type { ApiError, ResponseData } from './types';
import type {
    AxiosError,
    AxiosRequestConfig,
    InternalAxiosRequestConfig,
    AxiosResponse,
} from 'axios';

const attachAuthHeader = (config: InternalAxiosRequestConfig) => {
    const token = loadCookie(AUTH_TOKEN_COOKIE);

    if (token) {
        config.headers['Authorization'] = token;
    }

    return config;
};

const attachCorrelationIdHeader = (config: InternalAxiosRequestConfig) => {
    config.headers[CORRELATION_ID_HEADER] = guid();

    return config;
};

const createApi = (baseURL: string) => {
    const api = withRetry(axios.create({ baseURL }));

    // Methods
    const apiGet = <T = ResponseData>(path: string, config?: AxiosRequestConfig) => {
        return api.get<T>(path, config);
    };

    const apiPost = <T = ResponseData>(path: string, data: object, config?: AxiosRequestConfig) => {
        return api.post<T>(path, data, config);
    };

    const apiPatch = <T = ResponseData>(path: string, data: object) => {
        return api.patch<T>(path, data);
    };

    const apiDelete = <T = ResponseData>(path: string) => {
        return api.delete<T>(path);
    };

    const apiPut = <T = ResponseData>(path: string, data: object) => {
        return api.put<T>(path, data);
    };

    return { api, apiGet, apiPost, apiPatch, apiDelete, apiPut };
};

// Create API instances
export const {
    api: toolApi,
    apiGet,
    apiPost,
    apiPatch,
    apiDelete,
} = createApi(process.env.NEXT_PUBLIC_API_URL as string);

export const {
    api: automationsApi,
    apiGet: automationsApiGet,
    apiPost: automationsApiPost,
    apiPatch: automationsApiPatch,
    apiDelete: automationsApiDelete,
} = createApi(process.env.NEXT_PUBLIC_AUTOMATION_API_URL as string);

export const {
    api: crmApi,
    apiGet: crmApiGet,
    apiPost: crmApiPost,
    apiPatch: crmApiPatch,
    apiPut: crmApiPut,
    apiDelete: crmApiDelete,
} = createApi(process.env.NEXT_PUBLIC_CRM_API_URL as string);

export const {
    api: metricsApi,
    apiGet: metricsApiGet,
    apiPost: metricsApiPost,
    apiPatch: metricsApiPatch,
    apiPut: metricsApiPut,
    apiDelete: metricsApiDelete,
} = createApi(process.env.NEXT_PUBLIC_METRICS_API_URL as string);

export const {
    api: treeApi,
    apiGet: treeApiGet,
    apiPost: treeApiPost,
    apiPatch: treeApiPatch,
    apiPut: treeApiPut,
    apiDelete: treeApiDelete,
} = createApi(process.env.NEXT_PUBLIC_LEMON_TREE_URL as string);

export const toolApiResponseErrorCallback = async (error: AxiosError<{ message: string }>) => {
    return genericApiResponseErrorCallback(error, toolApi);
};

export const crmApiResponseErrorCallback = async (error: AxiosError<{ message: string }>) => {
    return genericApiResponseErrorCallback(error, crmApi);
};

export const automationsApiResponseErrorCallback = async (
    error: AxiosError<{ message: string }>,
) => {
    return genericApiResponseErrorCallback(error, automationsApi);
};

export const metricsApiResponseErrorCallback = async (error: AxiosError<{ message: string }>) => {
    return genericApiResponseErrorCallback(error, metricsApi);
};

// Request interceptors
toolApi.interceptors.request.use(attachAuthHeader);
toolApi.interceptors.request.use(attachCorrelationIdHeader);
crmApi.interceptors.request.use(attachAuthHeader);
crmApi.interceptors.request.use(attachCorrelationIdHeader);
automationsApi.interceptors.request.use(attachAuthHeader);
automationsApi.interceptors.request.use(attachCorrelationIdHeader);
metricsApi.interceptors.request.use(attachAuthHeader);

// Response interceptors
toolApi.interceptors.response.use(
    (response: AxiosResponse) => response,
    toolApiResponseErrorCallback,
);
crmApi.interceptors.response.use(
    (response: AxiosResponse) => response,
    crmApiResponseErrorCallback,
);
automationsApi.interceptors.response.use(
    (response: AxiosResponse) => response,
    automationsApiResponseErrorCallback,
);

metricsApi.interceptors.response.use(
    (response: AxiosResponse) => response,
    metricsApiResponseErrorCallback,
);

// Error handler
export const handleRuntimeError = (
    error: ApiError | Error,
    options: { debugMessage?: string; silent?: boolean; message?: string } = {},
) => {
    const { debugMessage, silent, message } = options;

    if (debugMessage) {
        console.warn(debugMessage, error);
    }

    const isApiError = !!(error as ApiError).response;

    if (!silent) {
        isApiError
            ? showNetworkError(error)
            : showWarning('runtime-error-title', message, error?.message);
    }

    // We don't want to report api errors to Sentry
    if (!isApiError) {
        reportError({
            error,
            source: 'runtime',
        });
    }
};
