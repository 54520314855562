import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { cn } from '@/utils/cn';

import type { EditorEngineNodeRendererProps } from '@/app/editor/engine/core/components/view/getEditorEngineNodeRenderer';
import type { EditorEngineDefaultTypeInput } from '@/app/editor/engine/core/types';
import type { SortingStrategy } from '@dnd-kit/sortable';

export interface GetSequenceRendererArguments {
    /**
     * The class name to apply to the rendered sequence.
     */
    className?: string;
    /**
     * The sorting strategy to use for the sequence.
     */
    sortingStrategy?: SortingStrategy;
}

type Renderer = EditorEngineNodeRendererProps<EditorEngineDefaultTypeInput>['renderer'];

/**
 * Returns a renderer that renders a sequence of nodes.
 */
export const getSequenceRenderer = ({
    className,
    sortingStrategy = verticalListSortingStrategy,
}: GetSequenceRendererArguments) => {
    const SequenceRenderer = ({ nodes, renderNode, identify }: Parameters<Renderer>[0]) => {
        const identifiers = nodes.map(identify);

        return (
            <SortableContext items={identifiers} strategy={sortingStrategy}>
                <div className={cn('flex', className)}>
                    {nodes.map((node) => {
                        return renderNode({
                            node,
                        });
                    })}
                </div>
            </SortableContext>
        );
    };

    return SequenceRenderer satisfies Renderer;
};
