import Accordion from './Accordion';
import AccordionItem from './AccordionItem';
import Button from './Button';
import Divider from './Divider';
import Embed from './Embed';
import Footer from './Footer';
import Form from './Form';
import GridColumn from './GridColumn';
import GridRow from './GridRow';
import Header from './Header';
import Icon from './Icon';
import Illustration from './Illustration';
import ImageSlider from './ImageSlider';
import Input from './Input';
import List from './List';
import ListItem from './ListItem';
import Logos from './Logos';
import Media from './Media';
import PaymentForm from './PaymentForm';
import QuestionForm from './QuestionForm';
import QuestionMedia from './QuestionMedia';
import QuestionMediaAnswer from './QuestionMediaAnswer';
import QuestionMultipleChoice from './QuestionMultipleChoice';
import QuestionText from './QuestionText';
import QuestionTextAnswer from './QuestionTextAnswer';
import Reviews from './Reviews';
import TestimonialSlider from './TestimonialSlider';
import Text from './Text';
import Webinar from './Webinar';

export const BlockMap = {
    accordion: Accordion,
    accordionItem: AccordionItem,
    button: Button,
    embed: Embed,
    divider: Divider,
    footer: Footer,
    form: Form,
    formURL: Form,
    gridColumn: GridColumn,
    gridRow: GridRow,
    header: Header,
    icon: Icon,
    illustration: Illustration,
    input: Input,
    list: List,
    listItem: ListItem,
    logos: Logos,
    media: Media,
    paymentForm: PaymentForm,
    questionMedia: QuestionMedia,
    questionMediaAnswer: QuestionMediaAnswer,
    questionText: QuestionText,
    questionTextAnswer: QuestionTextAnswer,
    questionForm: QuestionForm,
    questionMultipleChoice: QuestionMultipleChoice,
    reviews: Reviews,
    sliderImage: ImageSlider,
    sliderTestimonial: TestimonialSlider,
    text: Text,
    webinar: Webinar,
};
