import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { ReactElement, ReactNode } from 'react';

declare global {
    interface Window {
        analytics: any;
        FS: any;
        dataLayer: any;
        Intercom: any;
        Chargebee: any;
        store: any;
    }
}

export type NextPageWithLayout<P = {}> = NextPage<P> & {
    getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & { Component: NextPageWithLayout };

export type Language = 'en' | 'de';

export interface DateFnsFormatOptions {
    localeMatcher?: 'lookup' | 'best fit';
    weekday?: 'narrow' | 'short' | 'long';
    era?: 'narrow' | 'short' | 'long';
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
    day?: 'numeric' | '2-digit';
    hour?: 'numeric' | '2-digit';
    minute?: 'numeric' | '2-digit';
    second?: 'numeric' | '2-digit';
    timeZoneName?: 'short' | 'long';
    formatMatcher?: 'basic' | 'best fit';
}

export const enum LocalStorageValue {
    TRUE = 'true',
    FALSE = 'false',
    SEEN = 'seen',
}

export type ValueOf<T> = T[keyof T];
export type Maybe<T> = T | undefined;
export type Nullable<T> = T | null | undefined;

export type Direction = 'top' | 'right' | 'bottom' | 'left';
