import { CurrencyDollarIcon, CurrencyEuroIcon } from '@heroicons/react/24/outline';

export type CurrencyCode = 'eur' | 'usd';

interface Currency {
    code: CurrencyCode;
    name: string;
    icon: JSX.Element;
}

export const currencies: Currency[] = [
    {
        code: 'eur',
        name: 'Euro',
        icon: <CurrencyEuroIcon />,
    },
    {
        code: 'usd',
        name: 'US Dollar',
        icon: <CurrencyDollarIcon />,
    },
];

export const getCurrencyIcon = (
    currency: CurrencyCode,
    className: string,
): JSX.Element | undefined => {
    switch (currency) {
        case 'eur':
            return <CurrencyEuroIcon className={className} />;
        case 'usd':
            return <CurrencyDollarIcon className={className} />;
        default:
            return undefined;
    }
};
