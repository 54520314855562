import { createSlice } from '@reduxjs/toolkit';

import { getCampaignById, addSetCampaigns } from '@/app/campaigns/models/campaigns';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiPatch, handleRuntimeError } from '@/core/api';
import { PerspectiveQueryClient } from '@/core/queryClient';
import { EMPTY_STRING } from '@/utils/empty';

import { NAME, QUERY_KEYS } from '../constants';

import type { CampaignResource } from '@/app/campaigns/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    renaming: string;
}

const initialState: State = {
    loading: false,
    renaming: EMPTY_STRING,
};

export const updateSlice = createSlice({
    name: `${NAME}/archive`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setRenaming(state, action: PayloadAction<string>) {
            return {
                ...state,
                renaming: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, setRenaming, reset } = updateSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.updateReducer?.loading;

export const getRenaming = (state: AppState) => state[NAME]?.updateReducer?.renaming;

// === Thunks ======

export const updateCampaign =
    (campaign: CampaignResource, successMessage?: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const oldCampaign = getCampaignById(state, campaign.id);

        dispatch(setLoading(true));

        try {
            // optimistic update
            dispatch(addSetCampaigns({ [campaign.id]: campaign }));

            // Quick fix for FUN-965 + FUN-966 (parent issue FUN-964)
            PerspectiveQueryClient.setQueryData(QUERY_KEYS.campaign(campaign?.id ?? ''), campaign);

            // update in DB
            await apiPatch(`/campaigns/${campaign.id}`, {
                data: campaign,
            });

            if (successMessage) {
                showToast({ type: 'success', message: successMessage });
            }
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: `updating campaign ${campaign.id} failed:`,
            });

            // revert optimistic update
            dispatch(addSetCampaigns({ [campaign.id]: oldCampaign }));
        } finally {
            dispatch(setLoading(false));
        }
    };

export default updateSlice.reducer;
