import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { Field, FormSection } from 'redux-form';

import { getNextHigherParentByType } from '@/app/editor/blocks/models/blocks';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import GenericSize from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/GenericSize';
import Personalization from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Personalization';
import TextStyle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextStyle';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';
import { SidebarPortalTarget } from '@/ui/components/_BlockEditFields/SidebarPortalTarget';

import type { DefaultEditFormProps } from '@/app/editor/blocks/types';

export interface Props
    extends Pick<DefaultEditFormProps, 'blockId' | 'form' | 'handleSubmit' | 'activeTheme'> {
    useSizeControls?: boolean;
    hidePersonalization?: boolean;
    isTipTap: boolean;
}

export const textSizeMap = {
    S: { value: 'small' },
    M: { value: 'medium' },
};

const ListItemForm = ({
    blockId,
    form,
    handleSubmit,
    activeTheme,
    useSizeControls,
    hidePersonalization,
    isTipTap,
}: Props) => {
    const { t } = useTranslation(NAME);
    const listBlock = useAppSelector((state) => getNextHigherParentByType(state, form, 'list'));

    return (
        <>
            {useSizeControls && (
                <FormSection name="additionalBlocks">
                    <FormSection name={listBlock.id}>
                        <Field
                            name="attributes.content.textSize"
                            component={GenericSize}
                            submit={handleSubmit}
                            valueMap={textSizeMap}
                        />
                    </FormSection>
                </FormSection>
            )}

            {isTipTap ? (
                <SidebarPortalTarget id={`tip-tap-sidebar-text-styles-portal-${blockId}`} />
            ) : (
                <TextStyle textBlockId={blockId} />
            )}

            <div className="grid grid-cols-3 gap-2">
                {isTipTap ? (
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-color-portal-${blockId}`} />
                ) : (
                    <Field
                        name="attributes.content.color"
                        component={Color}
                        expand="right"
                        submit={handleSubmit}
                        themeColor={getThemeColor(activeTheme, 'fontColor')}
                        tooltip={t('font-color')}
                    />
                )}

                {!hidePersonalization &&
                    (isTipTap ? (
                        <SidebarPortalTarget
                            id={`tip-tap-sidebar-personalization-portal-${blockId}`}
                        />
                    ) : (
                        <Personalization blockId={blockId} />
                    ))}
            </div>
        </>
    );
};

export default ListItemForm;
