import { ADVANCED_ROLES, AVAILABLE_INVITE_ROLES } from '@/app/workspaces/constants';

import Router from 'next/router';

import {
    WorkspaceInfoFields,
    InvitationStatus,
    WorkspaceInvitationRoles,
} from '@/app/workspaces/types';
import { isGlobalAdminRoleEnabled } from '@/core/loli-feature-flags/features/adminRoleManagement';
import { isWorkspaceAdminRoleEnabled } from '@/core/loli-feature-flags/features/workspaceAdminRoleManagement';
import { getIsWorkspaceEnv, isVercelDevelopmentEnv, isProductionEnv } from '@/utils/environments';

import type { CampaignResource } from '@/app/campaigns/types';
import type { UserRole } from '@/app/user/types';
import type {
    NewWorkspaceFormData,
    WorkspaceInvitationResource,
    WorkspaceResource,
    Workspaces,
} from '@/app/workspaces/types';

export const getHasExceededWorkspaceLimit = (workspaces: Workspaces, limit: number) =>
    Object.keys(workspaces)?.length >= limit;

export const getWorkspaceName = (workspace?: WorkspaceResource) => workspace?.attributes?.name;

export const getWorkspaceLogo = (workspace?: WorkspaceResource) => workspace?.attributes?.logo;

export const getIsDefaultWorkspace = (workspace?: WorkspaceResource) =>
    workspace?.attributes?.default;

export const getWorkspaceInfoFormData = (data: NewWorkspaceFormData) => {
    const formData = new FormData();

    if (data[WorkspaceInfoFields.Name]) {
        formData.append(WorkspaceInfoFields.Name, data[WorkspaceInfoFields.Name]);
    }

    if (data[WorkspaceInfoFields.Domain]) {
        formData.append(WorkspaceInfoFields.Domain, data[WorkspaceInfoFields.Domain]);
    }

    // Allow nulls for `logo` field
    if (Object.prototype.hasOwnProperty.call(data, WorkspaceInfoFields.Logo)) {
        formData.append(WorkspaceInfoFields.Logo, data[WorkspaceInfoFields.Logo]);
    }

    return formData;
};

export const getInviteExists = (email: string, invites: WorkspaceInvitationResource[]) => {
    return invites.find(
        (invite) =>
            invite?.attributes.status !== InvitationStatus.Cancelled &&
            invite?.attributes?.email === email,
    );
};

export const getWorkspaceLoginUrl = (workspaceDomain?: string) => {
    const isWorkspaceEnv = getIsWorkspaceEnv();

    if (isWorkspaceEnv && workspaceDomain) {
        return `https://${workspaceDomain}/login`;
    }

    return '/workspaces/login';
};

export const getWorkspaceBackUrl = ({
    campaign,
    defaultWorkspace,
    canUpdate = false,
    isPreview = false,
}: {
    campaign?: CampaignResource;
    defaultWorkspace: WorkspaceResource;
    canUpdate?: boolean;
    isPreview?: boolean;
}) => {
    const campaignWorkspaceId = campaign?.relationships?.workspace?.data?.id;
    const isDefaultWorkspace = campaignWorkspaceId === defaultWorkspace?.id;
    const workspaceUrl = isDefaultWorkspace ? '/' : `/workspaces/${campaignWorkspaceId}`;

    if (isPreview) {
        return workspaceUrl;
    }

    return isProductionEnv() && !canUpdate ? '/' : workspaceUrl;
};

export const getWorkspaceDomainFromUrl = () => {
    const localDomain = process.env['NEXT_PUBLIC_MIMIC_WORKSPACE_USER'];

    if (localDomain) {
        return localDomain;
    }

    return !isVercelDevelopmentEnv() ? window?.location.host : undefined;
};

export const getNonDefaultWorkspaceIds = (workspaces: Workspaces) => {
    return Object.keys(workspaces).filter(
        (workspaceId) => !workspaces?.[workspaceId]?.attributes?.default,
    );
};

export const getFirstNonDefaultWorkspace = (workspaces: Workspaces) => {
    const id = getNonDefaultWorkspaceIds(workspaces)[0];

    if (id) {
        return workspaces[id];
    }
};

export const redirectToFirstNonDefaultWorkspace = (workspaces: Workspaces) => {
    const workspace = getFirstNonDefaultWorkspace(workspaces);
    const workspaceDomain = workspace?.attributes?.perspectiveDomain;

    if (!isVercelDevelopmentEnv() && workspaceDomain) {
        Router.push(`https://${workspaceDomain}`);

        return;
    }

    if (workspace?.id) {
        Router.push(`workspaces/${workspace?.id}`);
    }
};

export const getIsValidInvitation = (status?: InvitationStatus) => {
    return !!status && ['pending', 'success'].includes(status);
};

export const getImageDimensions = (
    file: File,
    callback: (file: File, { width, height }: { width: number; height: number }) => void,
) => {
    const img = new Image();
    const src = URL.createObjectURL(file);

    img.onload = () => {
        callback(file, {
            width: img.naturalWidth,
            height: img.naturalHeight,
        });
    };

    img.src = src;
};

export const generateInitialWorkspaceName = ({
    firstName,
    language,
}: {
    firstName?: string;
    language?: string;
}) => {
    const trimmedFirstName = (firstName ?? '').trim();

    if (trimmedFirstName.length > 0) {
        if (language === 'de') {
            const isPlural = /([sxzß]|ce)$/.test(trimmedFirstName);

            return [trimmedFirstName, isPlural ? "'" : 's', ' Workspace'].join('');
        } else {
            return `${trimmedFirstName}'s Workspace`;
        }
    } else {
        return language === 'de' ? 'Standard Workspace' : 'Default Workspace';
    }
};

export const getAvailableInviteRoles = (role: UserRole) => {
    const availableInviteRoles = AVAILABLE_INVITE_ROLES[role];
    const isGlobalAdminRoleFFEnabled = isGlobalAdminRoleEnabled();
    const isWorkspaceAdminRoleFFEnabled = isWorkspaceAdminRoleEnabled();

    if (!availableInviteRoles) {
        return [];
    }

    return availableInviteRoles.filter((role) => {
        if (role === WorkspaceInvitationRoles.admin) {
            return isGlobalAdminRoleFFEnabled;
        }

        if (role === WorkspaceInvitationRoles.workspaceAdmin) {
            return isWorkspaceAdminRoleFFEnabled;
        }

        return true;
    });
};

export const isAdvancedRole = (role: WorkspaceInvitationRoles) => {
    return ADVANCED_ROLES.find((advancedRole) => advancedRole === role);
};

export const isWorkspaceInvite = (resource: any) => {
    return resource?.type === 'userInvitationEvent';
};
