import type { FaviconData, FaviconResource } from '@/app/funnelSettings/favicon/types';

export const getOptimizedFaviconURL = (url: string): string | undefined => {
    if (!url) {
        return undefined;
    }

    return `${url}?fit=crop&crop=left&w=128&h=128&q=80&auto=compress`;
};

export const transformFaviconData = (favicons: FaviconResource[]): FaviconData[] => {
    return favicons.map((favicon) => ({
        id: favicon?.id,
        url: favicon?.attributes?.imgix,
        isDefault: false,
    }));
};
