import { LS_LAST_ACTIVE_WORKSPACE_ID_KEY } from '@/app/workspaces/constants';

import Router from 'next/router';
import { toast } from 'sonner';

import { resetAnalyticsState } from '@/app/analytics/models/reset';
import { getLocalizedLoginUrl } from '@/app/auth/helper';
import { resetBillingState } from '@/app/billing/models/reset';
import { resetCampaignsState } from '@/app/campaigns/models/reset';
import { resetCmdkState } from '@/app/cmdk/models/reset';
import { resetCompanyState } from '@/app/company/models/reset';
import { resetCrmState } from '@/app/crm/models/reset';
import { resetDashboardState } from '@/app/dashboard/models/reset';
import { resetDomainsState } from '@/app/domains/models/reset';
import { resetEditorState } from '@/app/editor/editor/models/reset';
import { resetFunnelSettingsState } from '@/app/funnelSettings/funnelSettings/models/reset';
import { hideModal } from '@/app/modals/models/modals';
import { resetPathToSuccessState } from '@/app/pathToSuccess/models/reset';
import { resetReferralsState } from '@/app/referrals/models/reset';
import { resetSettingsState } from '@/app/settings/models/reset';
import { resetSharedFunnelsState } from '@/app/sharedFunnels/models/reset';
import { resetUserState } from '@/app/user/models/reset';
import { resetWorkspaceState } from '@/app/workspaces/models/reset';
import { removeCookie } from '@/utils/cookies';
import { getIsWorkspaceEnv, isProductionEnv } from '@/utils/environments';

import { setIsLoggedIn } from './login';
import { AUTH_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE } from '../constants';

import type { AppThunk } from '@/core/redux/types';

// === Thunks =======

export const logout = (): AppThunk => async (dispatch) => {
    // Reset Cookies
    removeCookie(AUTH_TOKEN_COOKIE);
    removeCookie(REFRESH_TOKEN_COOKIE);

    // Reset Local storage
    localStorage.removeItem(LS_LAST_ACTIVE_WORKSPACE_ID_KEY);

    dispatch(setIsLoggedIn(false));

    // hide modals that might still be open
    dispatch(hideModal());

    // hide all toasts that might still be there
    toast.dismiss();

    await Router.replace(getLocalizedLoginUrl());

    // Reset Redux store in dev/preview environments, will be reset in production anyway when redirecting to login page (start.perspective.co)
    // If workspace environment, reset the store, as the app won't redirect to start.perspective.co
    if (!isProductionEnv() || getIsWorkspaceEnv()) {
        dispatch(resetAnalyticsState());
        dispatch(resetCampaignsState());
        dispatch(resetEditorState({}));
        dispatch(resetPathToSuccessState());
        dispatch(resetUserState());
        dispatch(resetCompanyState());
        dispatch(resetWorkspaceState());
        dispatch(resetDomainsState());
        dispatch(resetSettingsState());
        dispatch(resetDashboardState());
        dispatch(resetBillingState());
        dispatch(resetFunnelSettingsState());
        dispatch(resetSharedFunnelsState());
        dispatch(resetReferralsState());
        dispatch(resetCrmState());
        dispatch(resetCmdkState());
    }
};
