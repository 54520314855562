import { createContext } from 'react';

/**
 * Context for the preview information. This is used throughout the editor
 * to determine if a block is in preview (not yet inserted in the funnel).
 * The value will be used to change the appearance of the block or alter its
 * functionality (for example, disabling drag and drop for that block).
 *
 * todo(editorengine): this should be moved outside of the editor engine core.
 */
export const isPreviewContext = createContext<boolean>(false);

/**
 * Provider for the `isPreviewContext`.
 */
export const IsPreviewProvider = isPreviewContext.Provider;
