import {
    type EditorEngineDefaultTypeInput,
    EditorEngineEventType,
    EditorEngineExecutionDirection,
    EditorEngineHistoryEntryStepForeachFilter,
} from '@/app/editor/engine/core/types';
import { canRedo } from '@/app/editor/engine/core/utils/history/canRedo';
import { executeEntry } from '@/app/editor/engine/core/utils/history/executeEntry';
import { getCurrentEntry } from '@/app/editor/engine/core/utils/history/getCurrentEntry';
import { getEntryExecutionAvailability } from '@/app/editor/engine/core/utils/history/getEntryExecutionAvailability';
import { updateEntryActionRecordedResult } from '@/app/editor/engine/core/utils/history/updateEntryActionRecordedResult';
import { updateEntryStepRecordedResult } from '@/app/editor/engine/core/utils/history/updateEntryStepRecordedResult';

import type { useProcessingQueue } from '@/app/editor/engine/core/hooks/queue/useProcessingQueue';
import type {
    EditorEngineHistoryEntryCollection,
    EditorEngineEventEmitter,
    EditorEnginePersistedUpdate,
} from '@/app/editor/engine/core/types';
import type { Dispatch, SetStateAction } from 'react';

interface Input<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> {
    /**
     * The collection of history entries.
     */
    entriesInfo: EditorEngineHistoryEntryCollection<TEditorEngineTypeInput>;
    /**
     * This function will be called to update the collection of history entries.
     */
    setEntries: Dispatch<
        SetStateAction<EditorEngineHistoryEntryCollection<TEditorEngineTypeInput>>
    >;
    /**
     * This function will add an update to the persisted updates queue.
     */
    addUpdateToQueue: ReturnType<
        typeof useProcessingQueue<EditorEnginePersistedUpdate>
    >['addItemToQueue'];
    /**
     * This function will check if an update is in the queue.
     */
    isInQueue: ReturnType<typeof useProcessingQueue<EditorEnginePersistedUpdate>>['isInQueue'];
    /**
     * Extra context to be provided.
     */
    extraContext: TEditorEngineTypeInput['ExtraContext'];
    /**
     * The event emitter instance.
     */
    eventEmitter: EditorEngineEventEmitter;
}

/**
 * Returns a function that will redo the next entry in the history.
 */
export const getRedoFunction = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    entriesInfo,
    setEntries,
    addUpdateToQueue,
    isInQueue,
    extraContext,
    eventEmitter,
}: Input<TEditorEngineTypeInput>) => {
    return async () => {
        const nextEntry = getCurrentEntry({ entriesInfo, offset: 1 });

        if (!nextEntry) {
            return;
        }

        const executionAvailability = getEntryExecutionAvailability({
            entry: nextEntry,
            isInQueue,
        });

        if (!canRedo(executionAvailability)) {
            return;
        }

        await executeEntry({
            entry: nextEntry,
            nextCurrent: nextEntry,
            filter: EditorEngineHistoryEntryStepForeachFilter.All,
            direction: EditorEngineExecutionDirection.Forward,
            updateStep: ({ step, result }) => {
                updateEntryStepRecordedResult({
                    entry: nextEntry,
                    step,
                    setEntriesInfo: setEntries,
                    result,
                });
            },
            updateAction: ({ result }) => {
                updateEntryActionRecordedResult({
                    entry: nextEntry,
                    setEntriesInfo: setEntries,
                    result,
                });
            },
            reportError: (error) => {
                eventEmitter.emit({
                    name: EditorEngineEventType.HistoryError,
                    payload: {
                        error,
                    },
                });
            },
            updateEntries: setEntries,
            addUpdateToQueue,
            extraContext,
            eventEmitter,
            isReExecution: true,
        });
    };
};
