import { combineReducers } from 'redux';

import domainListReducer from './domainList';
import instantSubDomainSetupReducer from './instantSubDomainSetup';
import ownDomainSetupReducer from './ownDomainSetup';

export default combineReducers({
    domainListReducer,
    ownDomainSetupReducer,
    instantSubDomainSetupReducer,
});
