import { store } from '@/core/redux/store';

import { moveBlock } from '@/app/editor/blocks/models/update';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { getActivePageId, setActivePage } from '../../pages/models/pages';

import type { Command } from '../types';

export class MoveBlockCommand implements Command {
    private previousActivePageId: string;
    private previousNewParentId: string;
    private previousOldParentId: string;
    private previousIndex: number;

    constructor(
        private blockId: string,
        private oldParentId: string,
        private newParentId: string,
        private newIndex: number,
    ) {}

    private async updateView() {
        const state = store.getState();
        const activePageId = getActivePageId(state);
        const activeCampaignId = getCampaignIdFromRouter();

        if (activePageId !== this.previousActivePageId) {
            await store.dispatch(setActivePage(activeCampaignId, this.previousActivePageId));
        }

        const blockElement = document.getElementById(this.blockId);

        if (blockElement) {
            blockElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }

    public async execute() {
        const state = store.getState();

        store.dispatch(
            moveBlock({
                blockId: this.blockId,
                newIndex: this.newIndex,
                newParentId: this.newParentId,
                oldParentId: this.oldParentId,
            }),
        );

        this.previousActivePageId = getActivePageId(state);
        this.previousNewParentId = this.newParentId;
        this.previousOldParentId = this.oldParentId;
        this.previousIndex = this.newIndex;

        return { success: true, canUndo: true };
    }

    public async undo() {
        await this.updateView();
        store.dispatch(
            moveBlock({
                blockId: this.blockId,
                newIndex: this.previousIndex,
                newParentId: this.previousOldParentId,
                oldParentId: this.previousNewParentId,
            }),
        );

        return { success: true, canRedo: true };
    }

    public async redo() {
        await this.updateView();

        return await this.execute();
    }
}
