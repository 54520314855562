import { NAME } from '@/app/domains/constants';

import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { OwnDomainSetupSteps } from '../components/OwnDomainSetup/types';

import type { DomainAvailability } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    currentStep: OwnDomainSetupSteps;
    domain: string;
    availability?: DomainAvailability;
}

const initialState: State = {
    currentStep: OwnDomainSetupSteps.Intro,
    domain: '',
};

export const ownDomainSetupSlice = createSlice({
    name: `${NAME}/ownDomainSetup`,
    initialState,
    reducers: {
        nextStep(state) {
            state.currentStep++;
        },
        prevStep(state) {
            state.currentStep--;
        },
        resetSetup(state) {
            state.currentStep = initialState.currentStep;
            state.domain = initialState.domain;
        },
        setDomain(state, action: PayloadAction<string>) {
            state.domain = action.payload;
        },
        setAvailability(state, action: PayloadAction<DomainAvailability>) {
            state.availability = action.payload;
        },
    },
});

// === Actions ======

export const { nextStep, prevStep, resetSetup, setDomain, setAvailability } =
    ownDomainSetupSlice.actions;

// === Selectors ======

export const getCurrentOwnDomainSetupStep = (state: AppState) =>
    state[NAME].ownDomainSetupReducer.currentStep;

export const getDomain = (state: AppState) => state[NAME].ownDomainSetupReducer.domain;

export const getAvailability = (state: AppState) => state[NAME].ownDomainSetupReducer.availability;

export const getDnsProvider = (state: AppState) =>
    state[NAME].ownDomainSetupReducer.availability?.dnsProvider;

// === Thunk ======

export const goBack = (): AppThunk => (dispatch, getState) => {
    const currentStep = getCurrentOwnDomainSetupStep(getState());

    if (currentStep !== OwnDomainSetupSteps.Intro) {
        dispatch(prevStep());

        return;
    }

    Router.back();
};

export default ownDomainSetupSlice.reducer;
