import type { CancellationSurveyAnswer } from './types';

export const NAME = 'cancellation';

export const ANSWERS: CancellationSurveyAnswer[] = [
    {
        trackingName: 'Missing knowledge',
        label: 'survey missing knowledge',
        emoji: '🎓',
        takeInput: true,
        placeholder: 'what knowledge are you missing',
    },
    {
        trackingName: 'Missing functionality',
        label: 'survey missing functionality',
        emoji: '💻',
        takeInput: true,
        placeholder: 'what functionality are you missing',
    },
    {
        trackingName: 'Using it too little',
        label: 'survey using it too little',
        emoji: '⏱',
        takeInput: true,
        placeholder: 'why are you using it too little',
    },
    {
        trackingName: 'Software bugs or support issues',
        label: 'survey software bugs or support issues',
        emoji: '🏗',
        takeInput: true,
        placeholder: 'what errors and problems did you encounter',
    },
    {
        trackingName: 'Switching to a different provider',
        label: 'survey switching to a different provider',
        emoji: '🔄',
        takeInput: true,
        placeholder: 'what provider are you switching to',
    },
    {
        trackingName: 'Its too expensive for me',
        label: 'survey too expensive for me',
        emoji: '💰',
        takeInput: true,
        placeholder: 'what would be a good deal for you',
    },
    {
        trackingName: 'Other reasons',
        label: 'survey other reasons',
        emoji: '🤔',
        takeInput: true,
        placeholder: 'describe your reason',
    },
];
