import { useCallback, useMemo } from 'react';

import type {
    EditorEngineLogHandler,
    EditorEngineLogRecord,
} from '@/app/editor/engine/core/types/log';

/**
 * Hook that creates a logging system for the Editor Engine.
 */
export const useEditorEngineLogger = ({
    logHandlers,
}: {
    logHandlers: EditorEngineLogHandler[];
}) => {
    const log = useCallback(
        (record: Omit<EditorEngineLogRecord, 'timestamp'>) => {
            logHandlers.forEach((handler) => {
                handler({
                    ...record,
                    timestamp: Date.now(),
                    context: record.context || {},
                });
            });
        },
        [logHandlers],
    );

    /**
     * Get a log function that logs with a default context.
     */
    const getLogFunctionWithDefaultContext = useCallback(
        (context: Record<string, unknown>) => {
            return (record: Omit<EditorEngineLogRecord, 'timestamp'>) => {
                log({
                    ...record,
                    context: {
                        ...context,
                        ...record.context,
                    },
                });
            };
        },
        [log],
    );

    return useMemo(() => {
        return {
            log,
            getLogFunctionWithDefaultContext,
        };
    }, [log, getLogFunctionWithDefaultContext]);
};
