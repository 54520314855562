import { useState } from 'react';

import Dropdown from '@/ui/components/_BlockEditFields/Dropdown';
import { Label } from '@/ui/components/_BlockEditFields/Label';

import { getDateFormatOptions } from './helper';

import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    initialValue: string;
    input: WrappedFieldInputProps;
    label?: string;
    submit?: () => void;
}

const DateFormat = ({ initialValue, input, submit, label }: Props) => {
    const dateFormatList = getDateFormatOptions();

    const [selectedValue, setSelectedValue] = useState<string>(initialValue || '');

    const handleSelect = (value: string) => {
        const { onChange } = input;

        onChange(value);
        setSelectedValue(value);

        if (submit) {
            setTimeout(submit);
        }
    };

    return (
        <>
            {label && <Label text={label} />}
            <Dropdown
                options={dateFormatList}
                value={selectedValue || initialValue}
                onChange={handleSelect}
            />
        </>
    );
};

export default DateFormat;
