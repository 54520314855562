import {
    ExclamationCircleIcon,
    XCircleIcon,
    InformationCircleIcon,
    XMarkIcon,
    CheckCircleIcon,
} from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { cn } from '@/utils/cn';
import { stopPropagation } from '@/utils/common';

import type { ToastOptions } from '@/app/toasts/types';

export interface Props extends ToastOptions {
    id: string | number;
}

const iconMap = {
    success: <CheckCircleIcon className="size-6 text-green-500" aria-hidden="true" />,
    warning: <ExclamationCircleIcon className="size-6 text-yellow-500" aria-hidden="true" />,
    error: <XCircleIcon className="size-6 text-red-500" aria-hidden="true" />,
    info: <InformationCircleIcon className="size-6 text-blue-500" aria-hidden="true" />,
};

const colorMap = {
    success: 'bg-green-500',
    warning: 'bg-yellow-500',
    error: 'bg-red-500',
    info: 'bg-blue-500',
};

export const Toast = ({
    id,
    message,
    description,
    details,
    actionText,
    duration = 4000,
    onActionClick,
    type,
    fixed,
    messageValues = {},
    descriptionValues = {},
}: Props) => {
    const { t, i18n } = useTranslation();

    const toastMessage = i18n.exists(message, { ...messageValues })
        ? t(message, { ...messageValues })
        : message;
    const toastDescription =
        typeof description === 'string' && i18n.exists(description, { ...descriptionValues })
            ? t(description, { ...descriptionValues })
            : description;
    const toastActionText = i18n.exists(actionText) ? t(actionText) : actionText;

    const [mounted, setMounted] = useState(false);
    const [detailsVisible, setDetailsVisible] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleDismissClick = () => {
        toast.dismiss(id);
    };

    const handleShowDetails = () => {
        setDetailsVisible(true);
    };

    return (
        <div className="flex w-[356px] justify-center px-4 pt-4 sm:px-0" onClick={stopPropagation}>
            <div className="pointer-events-auto relative w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                    <div className="flex items-start">
                        {iconMap[type] && <div className="flex-shrink-0">{iconMap[type]}</div>}
                        <div className="flex-shrink-1 flex-basis-auto ml-3 w-0 min-w-0 flex-1 pt-0.5">
                            <p
                                className={cn('text-sm text-gray-900', {
                                    'font-semibold': toastDescription,
                                })}
                            >
                                {toastMessage}
                            </p>
                            {toastDescription && (
                                <p className="mt-1 text-sm text-gray-500">{toastDescription}</p>
                            )}
                            {details && (
                                <div className="mt-2">
                                    {!detailsVisible && (
                                        <button
                                            className="rounded bg-white text-sm font-medium text-blue-500 hover:text-blue-600"
                                            onClick={handleShowDetails}
                                        >
                                            {t('show-details')}
                                        </button>
                                    )}
                                    {detailsVisible && (
                                        <div className="rounded border bg-gray-50 p-2 font-mono text-xs text-gray-500">
                                            {details}
                                        </div>
                                    )}
                                </div>
                            )}
                            {toastActionText && onActionClick && (
                                <div className="mt-3 flex space-x-7">
                                    <button
                                        onClick={onActionClick}
                                        type="button"
                                        className="rounded bg-white text-sm font-medium text-blue-500 hover:text-blue-600"
                                    >
                                        {toastActionText}
                                    </button>
                                </div>
                            )}
                        </div>
                        {!fixed && (
                            <div className="ml-4 flex flex-shrink-0">
                                <button
                                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500"
                                    onClick={handleDismissClick}
                                >
                                    <span className="sr-only">{t('common:close')}</span>
                                    <XMarkIcon className="size-6" aria-hidden="true" />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {duration > 0 && !fixed && (
                    <div
                        className={cn(
                            'absolute bottom-0 h-1 ease-linear',
                            colorMap[type],
                            mounted ? 'w-full' : 'w-0',
                        )}
                        style={{ transitionDuration: `${duration}ms` }}
                    />
                )}
            </div>
        </div>
    );
};

export default Toast;
