import { CheckIcon, LinkIcon } from '@heroicons/react/24/outline';

import { cn } from '@/utils/cn';
import { tw } from '@/utils/tw';

import type { FC, ForwardRefExoticComponent, PropsWithoutRef, SVGProps } from 'react';

interface Props {
    animate: boolean;
    className?: string;
    iconClassName?: string;
    defaultIconComponent?: ForwardRefExoticComponent<PropsWithoutRef<SVGProps<SVGSVGElement>>>;
}

const iconClassName = tw`absolute inset-0 size-full transition-all duration-300 ease-in-out`;

const MorphingIcons: FC<Props> = ({
    animate,
    className,
    iconClassName: iconClassNameFromProps,
    defaultIconComponent,
}) => {
    const DefaultIcon = defaultIconComponent || LinkIcon;

    return (
        <div className={cn('relative size-5', className)}>
            <DefaultIcon
                className={cn(iconClassName, iconClassNameFromProps)}
                style={{
                    strokeDasharray: 50,
                    strokeDashoffset: animate ? -50 : 0,
                    visibility: animate ? 'hidden' : 'visible',
                }}
            />
            <CheckIcon
                className={cn(iconClassName, iconClassNameFromProps)}
                style={{
                    strokeDasharray: 50,
                    strokeDashoffset: animate ? 0 : -50,
                    visibility: animate ? 'visible' : 'hidden',
                }}
            />
        </div>
    );
};

export default MorphingIcons;
