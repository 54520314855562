import { Transition } from '@headlessui/react';
import { useEffect, useMemo, useState } from 'react';

import {
    getPreviewOrActiveTheme,
    getTriggerTransition,
    setTriggerTransition,
} from '@/app/editor/themes/models/themes';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import {
    getPageTransitionDelay,
    getPageTransitionDuration,
    getPageTransitionProps,
} from '../../helpers';

import type { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    artBoardIndex?: number;
}

const TransitionWrapper = ({ children, artBoardIndex }: Props) => {
    const dispatch = useAppDispatch();
    const triggerTransition = useAppSelector(getTriggerTransition);
    const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);
    const [render, setRender] = useState(true);
    const transitionEffect = previewOrActiveTheme?.attributes?.transition;

    useEffect(() => {
        // Only trigger animation for top level parents, and
        // Only trigger animation if 'triggerTransition' flag is truthy
        if (!isNaN(artBoardIndex) && render && triggerTransition) {
            setRender(false);
        } else if (!render) {
            dispatch(setTriggerTransition(false));
            setRender(true);
        }
    }, [dispatch, artBoardIndex, render, triggerTransition]);

    const transitionProps = useMemo(() => {
        return getPageTransitionProps(transitionEffect, artBoardIndex);
    }, [transitionEffect, artBoardIndex]);

    const transitionDuration = useMemo(() => {
        return getPageTransitionDuration(transitionEffect);
    }, [transitionEffect]);

    const transitionDelay = useMemo(() => {
        return getPageTransitionDelay(artBoardIndex);
    }, [artBoardIndex]);

    return (
        <Transition
            show={render && !triggerTransition}
            enter="transition-all ease-out"
            leave="transition-none"
            className="transition-all"
            {...transitionProps}
            leaveTo={transitionProps.enterFrom}
            leaveFrom={transitionProps.enterFrom}
            style={{
                transitionDelay: `${transitionDelay}ms`,
                transitionDuration: `${transitionDuration}ms`,
            }}
        >
            {children}
        </Transition>
    );
};

export default TransitionWrapper;
