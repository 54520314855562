import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

import { cn } from '@/utils/cn';
import { infoLog } from '@/utils/debugLogs';
import { isProductionEnv } from '@/utils/environments';

let DevComponent: () => JSX.Element;

const DevToolsLocalStorageKey = 'dev-tools-open';

export const DevLoader = () => {
    const [hasDevComponent, setHasDevComponent] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(localStorage.getItem(DevToolsLocalStorageKey) === 'true');
    }, []);

    useEffect(() => {
        if (!isProductionEnv()) {
            const load = async () => {
                const Dev = await import('./DevTools');
                DevComponent = Dev.DevTools;
                setHasDevComponent(true);
                infoLog('-- Dev tools enabled');
            };

            void load();
        }
    }, []);

    if (!DevComponent || !hasDevComponent) {
        return null;
    }

    const handleToggleIsOpen = () => {
        if (isOpen) {
            localStorage.removeItem(DevToolsLocalStorageKey);
        } else {
            localStorage.setItem(DevToolsLocalStorageKey, 'true');
        }

        setIsOpen((value) => !value);
    };

    return (
        <>
            <button
                className={cn(
                    'fixed bottom-0 left-0 z-50 flex size-10 items-center justify-center opacity-60 hover:opacity-95',
                    {
                        'text-neutral-100': isOpen,
                    },
                )}
                onClick={handleToggleIsOpen}
            >
                <WrenchScrewdriverIcon className="size-4" />
            </button>
            {isOpen && <DevComponent />}
        </>
    );
};
