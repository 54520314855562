import { createPageIcon } from '@/app/editor/pages/helper';

import type { LinkingPayload } from '@/app/editor/blocks/types';
import type { PageResource, PageType } from '@/app/editor/pages/types';
import type { CategorizedDropdownButtonIcon } from '@/ui/components/_BlockEditFields/CategorizedDropdown';

// create linking payload for component attributes
export const createLinkingPayload = (value: string, url: string): LinkingPayload => {
    if (value === 'next') {
        return { isInternal: true, pageId: undefined, url: undefined };
    }

    if (value === 'external') {
        return { isInternal: false, pageId: undefined, url };
    }

    return { isInternal: true, pageId: value, url: undefined };
};

export const getLinkingAsString = (value: LinkingPayload): string => {
    // internal dynamic link
    if (value.isInternal && value.pageId) {
        return value.pageId;
    }

    // internal next page
    if (value.isInternal && !value.pageId) {
        return 'next';
    }

    // external
    if (!value.isInternal) {
        return 'external';
    }
};

export const getTrackingLinkingType = (
    value: string,
    otherPages: PageResource[],
    resultPages: PageResource[],
) => {
    if (value === 'next') {
        return 'next_page';
    }

    if (value === 'external') {
        return 'external_url';
    }

    if (resultPages.find((page) => page?.id === value)) {
        return 'result_page';
    }

    if (otherPages.find((page) => page?.id === value)) {
        return 'funnel_page';
    }

    return 'next_page';
};

export const getPageIconComponent = (
    pageId: string,
    pages: PageResource[],
    pageType: PageType,
): CategorizedDropdownButtonIcon => {
    const pageIndex = pages.findIndex((resultPage) => resultPage?.id === pageId);

    const PageIconComponent = (props) => (
        <span {...props}>{pageIndex >= 0 ? createPageIcon(pageIndex, pageType) : '?'}</span>
    );

    return PageIconComponent;
};
