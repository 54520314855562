import Router from 'next/router';
import { useEffect, useState } from 'react';

import { getFeatureAvailability } from '@/app/company/models/company';
import { useAppSelector } from '@/core/redux/hooks';
import { isProductionEnv } from '@/utils/environments';

import type { FeatureId } from '@/app/billing/types';
import type { AppState } from '@/core/redux/types';

export const checkFeatureAvailability = (
    state: AppState,
    featureId: FeatureId,
    options?: {
        fallbackId?: string;
    },
) => {
    if (typeof window === 'undefined') {
        return false;
    }

    const { fallbackId } = options || {};

    const isFeatureByLocalStoragePresent = !isProductionEnv()
        ? localStorage.getItem('feature_' + featureId) === 'true'
        : false;

    const isFeatureByParameterPresent = isProductionEnv()
        ? false
        : new URLSearchParams(window.location.search).get('feature_' + featureId) === 'true';

    const isMainAvailable =
        getFeatureAvailability(featureId)(state) ||
        isFeatureByParameterPresent ||
        isFeatureByLocalStoragePresent;

    const isFallbackFeatureAvailable =
        getFeatureAvailability(fallbackId)(state) ||
        isFeatureByParameterPresent ||
        isFeatureByLocalStoragePresent;

    return isMainAvailable || isFallbackFeatureAvailable;
};

export const useFeatureAvailability = (
    featureId: FeatureId,
    options?: {
        fallbackId?: string;
        redirectUrl?: string;
    },
) => {
    // This is a dummy state we change once we received a custom "local_feature_flag_changed" event.
    // Changing the state causes components that use the hook to rerender whenever a local feature
    // override was changed (localStorage) and effectively causes these components to get
    // the latest feature flag value. See the useEffect at the end of this hook.
    const [, setRerenderCounter] = useState(0);

    const { fallbackId, redirectUrl } = options || {};

    const isFeatureAvailable = useAppSelector((state) =>
        checkFeatureAvailability(state, featureId, { fallbackId }),
    );

    // Redirect if feature is not available
    useEffect(() => {
        if (!isFeatureAvailable && redirectUrl) {
            Router.replace(redirectUrl);
        }
    }, [redirectUrl, isFeatureAvailable]);

    // Listen for local feature flag override changes from component "DebugLocalFlags.tsx"
    // respectively "toggleLocalFlag.ts".
    useEffect(() => {
        const listener = () => {
            setRerenderCounter((old) => old + 1);
        };

        window.addEventListener('local_feature_flag_changed', listener);

        return () => {
            window.removeEventListener('local_feature_flag_changed', listener);
        };
    }, []);

    // Guarantees that all browser tabs show the same state based on
    // the most recent localStorage feature flag override.
    useEffect(() => {
        // eslint-disable-next-line no-undef
        const listener = (event: WindowEventMap['storage']) => {
            if (event.storageArea === localStorage && event.key?.startsWith('feature_')) {
                setRerenderCounter((old) => old + 1);
            }
        };

        window.addEventListener('storage', listener);

        return () => {
            window.removeEventListener('storage', listener);
        };
    }, []);

    return isFeatureAvailable;
};
