import type { EditorEngineNodeDragInfo } from '@/app/editor/engine/core/types';
import type { BorderRadius, ThemeColorKey, ThemeResource } from '@/app/editor/themes/types';
import type { PhoneValidationKind } from '@/app/editor/types';
import type { Relationship, RelationshipArray, Resource } from '@/core/api/types';
import type { AppThunk } from '@/core/redux/types';
import type { CountryCode } from '@/utils/countries';
import type { EditorPlugin } from '@draft-js-plugins/editor';
import type { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions';
import type { Editor } from '@tiptap/core';
import type { EditorState } from '@tiptap/pm/state';
import type { EditorView } from '@tiptap/pm/view';
import type { Tone } from '@tiptap-pro/extension-ai';
import type { Language as AiLanguage } from '@tiptap-pro/extension-ai';
import type { ComponentType, ReactNode } from 'react';
import type { DecoratedFormProps } from 'redux-form';
import type { Language } from 'types/generic';

export const enum BlockComponentType {
    ACCORDION = 'accordion',
    ACCORDION_ITEM = 'accordionItem',
    BUTTON = 'button',
    DIVIDER = 'divider',
    EMBED = 'embed',
    FOOTER = 'footer',
    FORM = 'form',
    FORM_URL = 'formURL',
    GRID_COLUMN = 'gridColumn',
    GRID_ROW = 'gridRow',
    HEADER = 'header',
    ICON = 'icon',
    ILLUSTRATION = 'illustration', // deprecated
    INPUT = 'input',
    LIST = 'list',
    LIST_ITEM = 'listItem',
    LOGOS = 'logos',
    MEDIA = 'media',
    QUESTION_FORM = 'questionForm',
    QUESTION_MEDIA = 'questionMedia',
    QUESTION_MEDIA_ANSWER = 'questionMediaAnswer',
    QUESTION_MULTIPLE_CHOICE = 'questionMultipleChoice',
    QUESTION_TEXT = 'questionText',
    QUESTION_TEXT_ANSWER = 'questionTextAnswer',
    REVIEWS = 'reviews',
    SLIDER_IMAGE = 'sliderImage',
    SLIDER_TESTIMONIAL = 'sliderTestimonial',
    SUBMIT = 'submit',
    TEXT = 'text',
    WEBINAR = 'webinar',
}

export const enum TemplateBlockComponentType {
    // Templates
    ACCORDION_TEMPLATE = 'accordionTemplate',
    ACCORDION_ITEM_TEMPLATE = 'accordionItemTemplate',
    BUTTON_TEMPLATE = 'buttonTemplate',
    DIVIDER_TEMPLATE = 'dividerTemplate',
    EMBED_TEMPLATE = 'embedTemplate',
    FORM_TEMPLATE = 'formTemplate',
    FORM_URL_TEMPLATE = 'formURLTemplate',
    GRID_COLUMN_TEMPLATE = 'gridColumnTemplate',
    GRID_ROW_TEMPLATE = 'gridRowTemplate',
    ICON_TEMPLATE = 'iconTemplate',
    ILLUSTRATION_TEMPLATE = 'illustrationTemplate',
    INPUT_TEMPLATE = 'inputTemplate',
    LIST_TEMPLATE = 'listTemplate',
    LIST_ITEM_TEMPLATE = 'listItemTemplate',
    MEDIA_TEMPLATE = 'mediaTemplate',
    QUESTION_FORM_TEMPLATE = 'questionFormTemplate',
    QUESTION_MEDIA_TEMPLATE = 'questionMediaTemplate',
    QUESTION_MEDIA_ANSWER_TEMPLATE = 'questionMediaAnswerTemplate',
    QUESTION_MULTIPLE_CHOICE_TEMPLATE = 'questionMultipleChoiceTemplate',
    QUESTION_TEXT_TEMPLATE = 'questionTextTemplate',
    QUESTION_TEXT_ANSWER_TEMPLATE = 'questionTextAnswerTemplate',
    SLIDER_IMAGE_TEMPLATE = 'sliderImageTemplate',
    SLIDER_TESTIMONIAL_TEMPLATE = 'sliderTestimonialTemplate',
    TEXT_TEMPLATE = 'textTemplate',
    WEBINAR_TEMPLATE = 'webinarTemplate',
}

// === Resource ======

export interface BlockAttributes {
    name: string;
    componentType: BlockComponentType | string;
    content?: any;
}

export interface BlockRelationships {
    page: Relationship;
    campaign: Relationship;
    parent: Relationship;
    category: Relationship;
    section: Relationship;
    components: RelationshipArray;
    allowedChildSections: RelationshipArray;
    company: Relationship;
}

export type BlockResource = Resource<BlockAttributes, BlockRelationships>;

export interface ConnectPaymentAccount {
    onboardingLink: string;
}

export interface ConnectPaymentAccountRelationships {
    // TODO Maybe add the component itself here later
}

export type ConnectPaymentAccountResource = Resource<
    ConnectPaymentAccount,
    ConnectPaymentAccountRelationships
>;

export interface EditFormValues extends BlockResource {
    additionalBlocks?: { [blockId: string]: BlockResource };
}

export const enum BorderMenuItem {
    move = 'move',
    duplicate = 'duplicate',
    delete = 'delete',
    crop = 'crop',
    add = 'add',
    copy = 'copy',
    paste = 'paste',
    textSuggestion = 'text_suggestion',
}

export interface AdditionalBlocks {
    additionalBlocks?: {
        [parentId: string]: BlockResource;
    };
}

export type AdditionalBlocksConfig = {
    paths?: string[];
    ommitedPaths?: string[];
    target: 'parent' | 'targetParent' | 'child';
    childType?: string;
};

export interface BlockConfig {
    selectable: boolean;
    unselectableForParents?: string[];
    deletionReminder?: boolean;
    highlightWithActiveChild?: boolean;
    maxCount?: number;
    borderMenu: {
        hidden: boolean;
        hiddenForParents?: string[];
        items: BorderMenuItem[];
        showDuplicateAsAdd?: boolean;
        addTooltip?: string;
    };
    actions?: {
        onDuplicate?: (block: BlockResource) => AppThunk;
        onDoubleClick?: (block: BlockResource, parent?: BlockResource) => AppThunk;
        getAdditionalBlocksConfig?: (
            block?: BlockResource,
            parent?: BlockResource,
        ) => AdditionalBlocksConfig[];
        getAdditionalBlocksDataTarget?: (block?: BlockResource, parent?: BlockResource) => string;
    };
}

// Bundle Object exported for each Block in `/blocks/components`
export interface BlockBundle {
    component: ComponentType;
    form: ComponentType<DecoratedFormProps>;
    config: BlockConfig;
}

export type Size = 'small' | 'medium' | 'large' | 'xLarge' | 'custom' | 'answer';

export type BoxSizeOption = 'none' | 'small' | 'medium' | 'large' | 'xLarge';

export type IconSize = 'small' | 'regular' | 'large' | 'title2';

export type GlobalTextSize = 'small' | 'medium' | 'large' | 'xLarge' | 'checkbox';

export type ListItemVisualSize = 'small' | 'medium' | 'large' | 'xLarge';

export type TextSizeShort = 'S' | 'M' | 'L' | 'XL' | 'CUSTOM';

export type TextStyle = 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'LINK';

export type Alignment = 'left' | 'center' | 'right';

export type MediaSrcType = 'image' | 'video' | 'emoji' | 'icon';

export type LineType = 'hidden' | 'solid' | 'dashed';

export const enum VideoType {
    THIRD_PARTY = 'thirdParty',
    CUSTOM = 'custom',
}

// Passed from wrapper component, e.g. in QuestionMediaAnswer
export interface PropsFromParent {
    parentComponentType?: string;
    outerWrapperClass?: string;
    wrapperClass?: string;
    draggedBlockContainerClass?: string;
    blockContainerClass?: string;
    blockWrapperClass?: string;
    fixedAlign?: Alignment;
    isMultipleChoiceAnswer?: boolean;
    noMentions?: boolean;
    isLightnessDependent?: boolean;
    themedBackgroundColor?: ThemeColorKey;
    borderMenuPosition?: 'left' | 'right';
    componentType?: string;
    align?: Alignment;
    borderRadius?: BorderRadius;
    globalSize?: GlobalTextSize;
    visualSize?: ListItemVisualSize;
    formData?: BlockResource;
    isPreview?: boolean;
}

export interface DefaultEditFormProps {
    blockId: string;
    campaignId: string;
    pageId: string;
    activeTheme: ThemeResource;
    parent?: BlockResource;
    // Redux form
    form: string;
    initialValues: BlockResource;
    onSubmit: (values: BlockResource) => void;
    handleSubmit?: (values: BlockResource) => void;
    enableReinitialize?: boolean;
}

// === Block Attributes ======
export interface BoxProps {
    backgroundColor: string;
    bottom: BoxSizeOption;
    top: BoxSizeOption;
    left: BoxSizeOption;
    right: BoxSizeOption;
    bgImage?: {
        visible: boolean;
        src: string;
        focalPoint: { x: number; y: number };
        opacity: number;
    };
}

export interface DefaultBlockComponentProps extends PropsFromParent {
    artBoardIndex?: number;
    children?: ReactNode;
    blockId: string;
    componentType?: string;
    activeTheme?: ThemeResource;
    nestedLevel?: number;
    isDragged?: boolean;
    isDragPreview?: boolean;
    isActive?: boolean;
    box?: BoxProps;
    additionalBlocks?: EditFormValues['additionalBlocks'];
    dragInfo?: EditorEngineNodeDragInfo;
}

export interface LinkingPayload {
    isInternal: boolean;
    pageId?: string;
    url?: string;
}

export type HtmlInputType =
    | 'file'
    | 'text'
    | 'textarea'
    | 'url'
    | 'phone'
    | 'email'
    | 'number'
    | 'datePicker'
    | 'dropdown'
    | 'checkbox';

export type UniqueInputType =
    | 'email'
    | 'phone'
    | 'name'
    | 'firstName'
    | 'lastName'
    | 'birthday'
    | 'website'
    | 'street'
    | 'city'
    | 'zip'
    | 'country'
    | 'file';

export interface BlockDetails {
    campaignId: string;
    pageId: string;
    childComponents: RelationshipArray;
    parentComponent: Relationship;
    componentType: string;
    config: BlockConfig;
}

export interface CustomInputBlockOptions {
    language?: Language;
    pastDatesDisabled?: boolean;
    futureDatesDisabled?: boolean;
    weekendsDisabled?: boolean;
    twoColumn?: boolean;
    multiSelect?: boolean;
    choices?: string[];
    phoneValidationKind?: PhoneValidationKind;
    defaultCountry?: CountryCode;
}

// === Draft Editor ======

export type DraftEditorPlugins = [
    EditorPlugin & { MentionSuggestions: ComponentType<MentionSuggestionsPubProps> },
];

// === Hotkeys ======

export type HotkeyAction = 'duplicate' | 'delete' | 'copy' | 'paste';

// === Footer ======

export interface LegalFormValues {
    text?: string;
    link?: string;
}

// === Personalization ======

export interface TrackingPropertyAttributes {
    defaultValue: string;
    fieldName: string;
    source: string;
    title: string;
}

export interface TrackingPropertyRelationships {
    campaign: Relationship;
    company: Relationship;
    components: RelationshipArray;
}

export type TrackingPropertyResource = Resource<
    TrackingPropertyAttributes,
    TrackingPropertyRelationships
>;

export interface TrackingProperty {
    id?: string | number;
    name: string;
    title?: string;
    fieldName?: string;
    source?: string;
    defaultValue?: string;
    componentId?: string;
}

export interface TrackingPropertyWithLabel extends Omit<TrackingProperty, 'name'> {
    label: string;
    name?: string;
}

// === Testimonial Slider ======

export interface Testimonial {
    headline: string;
    subHeadline: string;
    image: string;
    mode: 'dark' | 'light';
}

// === Block Intros ======

export const enum BlockIntro {
    CALENDLY = 'calendly',
    EMBED = 'embed',
}

// === TipTap ======
export interface ShouldShowProps {
    editor?: Editor;
    view: EditorView;
    state?: EditorState;
    oldState?: EditorState;
    from?: number;
    to?: number;
}

export type AiLanguageOption = {
    label: string;
    value: AiLanguage;
};
export type AiToneOption = {
    label: string;
    value: Tone;
};
