import axios from 'axios';
import get from 'lodash/get';

import { defaultDetails, type VideoDetails } from '@/hooks/useVideoDetails';
import { reportMessage } from '@/utils/sentry';

export const getThumbnailUrl = (url?: string) => {
    if (!url) {
        return undefined;
    }

    const withoutSize = url.split('_')[0];

    return `${withoutSize}_750x422`;
};

export const normalizeVimeoData = (videoResponse: any): VideoDetails => {
    return {
        title: get(videoResponse, 'title'),
        thumbnail: getThumbnailUrl(get(videoResponse, 'thumbnail_url')),
        duration: get(videoResponse, 'duration'),
    };
};

export const requestVimeoDetails = async (id: string): Promise<VideoDetails> => {
    const videoUrl = id.indexOf('vimeo.com') > -1 ? id : `https://vimeo.com/${id}`;

    try {
        const response = await axios.get(`https://vimeo.com/api/oembed.json?url=${videoUrl}`);

        if (response.status === 200) {
            return normalizeVimeoData(response.data);
        }
    } catch (err) {
        reportMessage({
            message: `fetching Vimeo video details for ${videoUrl} failed: ${err.message}`,
            source: 'vimeo',
        });
    }

    return defaultDetails;
};
