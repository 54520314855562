import { DEFAULT_BLOCK_CONFIG } from '../../../editor/constants';

import type { BlockConfig } from '@/app/editor/blocks/types';

const config: BlockConfig = {
    ...DEFAULT_BLOCK_CONFIG,
    borderMenu: {
        ...DEFAULT_BLOCK_CONFIG.borderMenu,
        hidden: true,
    },
    selectable: false,
    highlightWithActiveChild: false,
};

export default config;
