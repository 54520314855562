import { BlockComponentType } from '@/app/editor/blocks/types';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { PerspectiveEditorEngineNodeTypeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEngineNodeTypeRenderer';
import { PerspectiveEditorEnginePreviewAwareSequenceRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareSequenceRenderer';
import { DraggableConfigurationHelper } from '@/app/editor/engine/core/utils/dragAndDrop/configuration';
import { cn } from '@/utils/cn';

import type { Alignment, DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
}

const Component: FC<Props> = ({ blockId, align }) => {
    const artBoardSize = useArtboardSize();

    // Align from question block
    const isCentered = align === 'center';

    return (
        <div className="mt-6">
            <div
                className={cn({
                    'w-full max-w-xl': artBoardSize === ArtBoardSize.TABLET,
                    'mx-auto': artBoardSize === ArtBoardSize.TABLET && isCentered,
                })}
            >
                <PerspectiveEditorEnginePreviewAwareSequenceRenderer
                    parentId={blockId}
                    className="flex flex-col space-y-4"
                    filter={({ block }) =>
                        block.attributes.componentType !== BlockComponentType.BUTTON
                    }
                />

                <div className="mt-4">
                    <PerspectiveEditorEngineNodeTypeRenderer
                        parentId={blockId}
                        componentType={BlockComponentType.BUTTON}
                        draggableConfiguration={DraggableConfigurationHelper.alwaysDisable}
                    />
                </div>
            </div>
        </div>
    );
};

Component.displayName = 'Form';

export default Component;
