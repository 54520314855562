import { getActiveBlockId } from '@/app/editor/blocks/models/blocks';
import { useAppSelector } from '@/core/redux/hooks';

import Checkbox from './InputTypes/Checkbox';
import DatePicker from './InputTypes/DatePicker';
import Dropdown from './InputTypes/Dropdown';
import FileInput from './InputTypes/FileInput';
import TextArea from './InputTypes/TextArea';
import TextInput from './InputTypes/TextInput';

import type {
    CustomInputBlockOptions,
    DefaultBlockComponentProps,
    HtmlInputType,
} from '@/app/editor/blocks/types';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    placeholder: string;
    icon: string;
    required: boolean;
    showIcon: boolean;
    inputType: HtmlInputType;
    customOptions?: CustomInputBlockOptions;
    misc: {
        wysiwyg: RawDraftContentState;
    };
}

const Component: FC<Props> = ({
    blockId,
    placeholder,
    icon,
    inputType,
    showIcon,
    misc,
    customOptions,
    required,
    activeTheme,
}) => {
    const activeBlockId = useAppSelector(getActiveBlockId);

    switch (inputType) {
        case 'file':
            return (
                <FileInput
                    required={required}
                    placeholder={placeholder}
                    showIcon={showIcon}
                    icon={icon}
                    blockId={blockId}
                    isActiveBlock={blockId === activeBlockId}
                />
            );
        case 'checkbox':
            return <Checkbox blockId={blockId} wysiwyg={misc.wysiwyg} activeTheme={activeTheme} />;
        case 'datePicker':
            return (
                <DatePicker
                    placeholder={placeholder}
                    showIcon={showIcon}
                    icon={icon}
                    blockId={blockId}
                    customOptions={customOptions}
                    isActiveBlock={blockId === activeBlockId}
                />
            );
        case 'dropdown':
            return (
                <Dropdown
                    placeholder={placeholder}
                    showIcon={showIcon}
                    icon={icon}
                    blockId={blockId}
                    customOptions={customOptions}
                    isActiveBlock={blockId === activeBlockId}
                />
            );
        case 'textarea':
            return (
                <TextArea
                    placeholder={placeholder}
                    showIcon={showIcon}
                    icon={icon}
                    blockId={blockId}
                    isActiveBlock={blockId === activeBlockId}
                />
            );
        default:
            return (
                <TextInput
                    placeholder={placeholder}
                    showIcon={showIcon}
                    icon={icon}
                    blockId={blockId}
                    isActiveBlock={blockId === activeBlockId}
                    inputType={inputType}
                    customOptions={customOptions}
                />
            );
    }
};

Component.displayName = 'Input';

export default Component;
