import { MODAL_OPTIONS } from '@/app/modals/constants';

import isEmpty from 'lodash/isEmpty';
import { change, submit } from 'redux-form';

import { getBlockComponentType } from '@/app/editor/blocks/helpers';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';

import { DEFAULT_BLOCK_CONFIG } from '../../../editor/constants';

import type { BlockConfig, BlockResource, MediaSrcType } from '@/app/editor/blocks/types';

const config: BlockConfig = {
    ...DEFAULT_BLOCK_CONFIG,
    unselectableForParents: ['questionMediaAnswer'],
    borderMenu: {
        ...DEFAULT_BLOCK_CONFIG.borderMenu,
        hiddenForParents: ['questionMediaAnswer', 'listItem'],
    },
    actions: {
        onDoubleClick: (block, parent) => (dispatch) => {
            const srcType = block?.attributes?.content?.srcType;
            const src = block?.attributes?.content?.src;
            const size = block?.attributes?.content?.size;

            const handleSave = async (url: string) => {
                // update edit form data and submit
                await dispatch(change(block.id, 'attributes.content.src', url));
                dispatch(submit(block.id));
            };

            if (srcType === 'image') {
                dispatch(
                    showModal(
                        Modals.IMAGE_CROPPING,
                        {
                            src: src,
                            originalSize: size,
                            onSave: handleSave,
                            withSizeSlider: isEmpty(parent),
                        },
                        MODAL_OPTIONS[Modals.IMAGE_CROPPING],
                    ),
                );
            }
        },
        getAdditionalBlocksConfig: (block: BlockResource, parent: BlockResource) => {
            const srcType: MediaSrcType = block?.attributes?.content?.srcType;
            const parentComponentType = getBlockComponentType(parent);
            const formType = parentComponentType ? parentComponentType : srcType;

            if (formType === 'listItem') {
                return [
                    {
                        paths: ['attributes.content.visualSize'],
                        target: 'targetParent',
                    },
                    {
                        paths: ['attributes.content.borderRadius'],
                        target: 'targetParent',
                    },
                ];
            }
        },
        getAdditionalBlocksDataTarget: (block: BlockResource, parent: BlockResource) => {
            const srcType: MediaSrcType = block?.attributes?.content?.srcType;
            const parentComponentType = getBlockComponentType(parent);
            const formType = parentComponentType ? parentComponentType : srcType;

            if (formType === 'listItem') {
                return 'list';
            }

            return parentComponentType;
        },
    },
};

export default config;
