import { useEffect } from 'react';

import { EditorEngineEventType } from '../../types';
import { EditorEngineLogLevel } from '../../types/log';

import type { EditorEngineEventEmitter } from '../../types';
import type { EditorEngineLogger } from '../../types/log';

/**
 * Automatically log when error events are emitted.
 */
export const useLogErrorEvents = ({
    eventEmitter,
    logger,
}: {
    eventEmitter: EditorEngineEventEmitter;
    logger: EditorEngineLogger;
}) => {
    useEffect(() => {
        const removeCriticalErrorListener = eventEmitter.addListener({
            name: EditorEngineEventType.CriticalError,
            listener: (payload) => {
                logger.log({
                    level: EditorEngineLogLevel.Critical,
                    message: payload.error.message,
                    context: payload.error,
                });
            },
        });

        const removeHistoryErrorListener = eventEmitter.addListener({
            name: EditorEngineEventType.HistoryError,
            listener: (payload) => {
                logger.log({
                    level: EditorEngineLogLevel.Error,
                    message: payload.error.error.message,
                    context: payload.error,
                });
            },
        });

        const removeUnknownErrorListener = eventEmitter.addListener({
            name: EditorEngineEventType.UnknownError,
            listener: (payload) => {
                logger.log({
                    level: EditorEngineLogLevel.Error,
                    message: payload.error.message,
                    context: payload.error,
                });
            },
        });

        return () => {
            removeCriticalErrorListener();
            removeHistoryErrorListener();
            removeUnknownErrorListener();
        };
    }, [eventEmitter, logger]);
};
