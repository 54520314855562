import { cn } from '@/utils/cn';

export interface Props {
    size?: 'tiny' | 'small' | 'medium';
    className?: string;
    customSize?: boolean;
}

export const LoadingSpinner = ({ size = 'medium', className = '', customSize = false }: Props) => {
    const sizeMap = {
        tiny: 'size-4',
        small: 'size-6',
        medium: 'size-12',
    };

    return (
        <div className={cn('text-blue-500', className, !customSize && sizeMap[size])}>
            <svg className="size-full animate-spin-moderate text-inherit" viewBox="0 0 50 50">
                <circle
                    className="animate-dash stroke-current"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeLinecap="round"
                    strokeWidth="5"
                />
            </svg>
        </div>
    );
};

export default LoadingSpinner;
