export const NAME = 'iconLibrary';

export const DEFAULT_PLATFORM = 'all-styles';

export const PREVIEW_ICON_OFFSET_MAP: { [icon: string]: number } = {
    'all-styles': 0,
    bubbles: 20,
    clouds: 40,
    color: 60,
    dusk: 80,
    doodle: 100,
    emoji: 120,
    fluent: 140,
    'fluent-systems-regular': 160,
    'fluent-systems-filled': 180,
    ios7: 200,
    ios11: 220,
    cotton: 240,
    office30: 260,
    m_sharp: 280,
};

export const COLORED_PLATFORMS = ['doodle', 'dusk', 'cool', 'color', 'cotton', 'clouds', 'bubbles'];

export const LAST_SELECTED_PLATFORM_LS_KEY = 'icon-library-last-selected-platform';
