import { Children, cloneElement, isValidElement } from 'react';

import Box from '@/app/editor/blocks/components/_wrapper/Box';
import SortableList from '@/app/editor/blocks/components/_wrapper/SortableList';
import Text from '@/app/editor/blocks/components/Text/Component';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';

import NewComponent from './NewComponent';

import type { Alignment, DefaultBlockComponentProps } from '../../types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
    color: string;
    fieldName: string;
    wysiwyg: RawDraftContentState;
}

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({
        artBoardIndex,
        children,
        box,
        blockId,
        wysiwyg,
        align,
        color,
        nestedLevel,
        activeTheme,
        isPreview,
        isDragged,
    }) => {
        const { isVerticalOnly, isInColumn } = useLayoutInfo(blockId, isPreview);
        const childBlocks = Children.toArray(children);
        const wrapperClass = isVerticalOnly ? 'flex flex-col space-y-4' : 'grid grid-cols-2 gap-4';

        // childBlocks become the form inputs here, since we pop away the submit button
        const submitButton = childBlocks.pop();

        const sortablesAnswers = Children.map(childBlocks, (child) =>
            isValidElement<BlockProps>(child)
                ? cloneElement(child, {
                      propsFromParent: { isMultipleChoiceAnswer: true },
                  })
                : null,
        );

        return (
            <Box
                box={getBoxAttributes({ box, isInColumn })}
                isDragged={isDragged}
                artBoardIndex={artBoardIndex}
            >
                <div className="mb-6">
                    <Text
                        align={align}
                        wysiwyg={wysiwyg}
                        color={getThemedOrCustomColor(color, 'fontColor', activeTheme)}
                        blockId={blockId}
                    />
                </div>

                {isPreview ? (
                    <div className={wrapperClass}>{sortablesAnswers}</div>
                ) : (
                    <SortableList
                        parentBlockId={blockId}
                        sortingStrategy={isVerticalOnly ? 'vertical' : 'rect'}
                        nestedLevel={nestedLevel}
                        className={wrapperClass}
                    >
                        {sortablesAnswers}
                    </SortableList>
                )}

                <div className="mt-4">{submitButton}</div>
            </Box>
        );
    },
    NewComponent,
);

Component.displayName = 'QuestionMultipleChoice';

export default Component;
