import { useState } from 'react';

import type {
    EditorEngineDocument,
    EditorEngineDocumentManager,
} from '@/app/editor/engine/core/types';

/**
 * A hook that manages the state of a document.
 *
 * Can be used as the document manager hook for an editor engine instance. When
 * creating a custom hook, that custom hook can extend this hook, or use a
 * completely different implementation.
 */
export const useDocumentStateManager = <TDocument extends EditorEngineDocument>(initialData?: {
    /**
     * The initial document to use.
     */
    initialDocument: TDocument;
}) => {
    const [document, setDocument] = useState<TDocument>(initialData.initialDocument);

    return {
        document,
        setDocument,
    } satisfies EditorEngineDocumentManager<TDocument>;
};
