import { createSelector, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { apiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { NAME } from '../constants';

import type { CampaignVersionResource } from '../types';
import type { AppThunk, AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    versions: {
        [versionId: string]: CampaignVersionResource;
    };
}

const initialState: State = {
    versions: EMPTY_OBJECT,
};

export const versionsSlice = createSlice({
    name: `${NAME}/versions`,
    initialState,
    reducers: {
        setCampaignVersion(
            state,
            action: PayloadAction<{ versionId: string; data: CampaignVersionResource }>,
        ) {
            return {
                ...state,
                versions: {
                    ...state.versions,
                    [action.payload.versionId]: action.payload.data,
                },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setCampaignVersion, reset } = versionsSlice.actions;

// === Selectors ======

const getCampaignVersion = (state: AppState) => get(state[NAME], 'versionsReducer.versions');

export const getCampaignVersionById = (versionId: string) =>
    createSelector(getCampaignVersion, (versions): CampaignVersionResource => {
        return get(versions, versionId, undefined);
    });

// === Thunks ======

export const fetchVersion = (versionId: string): AppThunk<Promise<void>> => {
    return async (dispatch) => {
        try {
            const response = await apiGet(`/versions/campaign/${versionId}`);
            const data = getDataFromResponse(response);

            dispatch(setCampaignVersion({ versionId, data }));

            return data;
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching versions failed:' });
        }
    };
};

export default versionsSlice.reducer;
