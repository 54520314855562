import { NAME } from '@/app/editor/blocks/constants';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

import type { FC } from 'react';

export interface Props {}

const Component: FC<Props> = () => {
    const { t } = useTranslation(NAME);

    return (
        <div className="flex flex-col items-center bg-amber-100 p-8 text-center font-sans text-sm text-gray-900">
            <ExclamationTriangleIcon className="size-10 fill-amber-500" />
            <p className="mt-4">{t('block-deprecated-illustration')}</p>
        </div>
    );
};

Component.displayName = 'Illustration';

export default Component;
