import {
    type EditorEngineDefaultTypeInput,
    EditorEngineStepName,
} from '@/app/editor/engine/core/types';

import type {
    EditorEngineParentId,
    EditorEngineTransactionStepCreator,
} from '@/app/editor/engine/core/types';

interface RemoveNodeStepInput {
    /**
     * The ID of the node to remove.
     */
    id: string;
}

/**
 * A transaction step that removes a node.
 */
export const removeNodeStep = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    id,
}: RemoveNodeStepInput) => {
    return (({ nodeManager }) => {
        let oldNode: TEditorEngineTypeInput['Data'];
        let oldNodeIndex: number;
        let oldParentId: EditorEngineParentId;

        return {
            name: EditorEngineStepName.RemoveNode,
            debug: {
                id,
            },
            affectedNodes: [id],
            apply() {
                oldNode = { ...nodeManager.getNode(id) };
                oldNodeIndex = nodeManager.getNodeIndex(id);
                oldParentId = nodeManager.getParentId(oldNode);

                nodeManager.removeNode({
                    id,
                });

                return {
                    success: true,
                };
            },
            revert() {
                nodeManager.insertNodeAt({
                    node: oldNode,
                    parentId: oldParentId,
                    index: oldNodeIndex,
                });

                return {
                    success: true,
                };
            },
        };
    }) satisfies EditorEngineTransactionStepCreator<TEditorEngineTypeInput>;
};
