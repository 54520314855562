export const buildWorkspaceStatusTemplateRoute = {
    get: () => '/status/templates',
    post: () => '/status/templates',
    delete: (templateId: string) => `/status/templates/${templateId}`,
};

export const buildWorkspaceStatusRoute = {
    get: (workspaceId: string) => `/status/workspace/${workspaceId}`,
    post: (workspaceId: string) => `/status/workspace/${workspaceId}`,
};

export const buildCampaignStatusRoute = {
    post: (campaignId: string) => `/status/campaign/${campaignId}`,
    patch: (campaignId: string) => `/status/campaign/${campaignId}`,
    usage: {
        get: (campaignId: string) => `/status/campaign/${campaignId}/usage`,
    },
    bulkUpdate: {
        post: (campaignId: string) => `/status/campaign/${campaignId}/bulk-update`,
    },
};
