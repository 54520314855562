import { useContext } from 'react';

import { PropsFromParentOverrideProvider } from '@/app/editor/engine/core/components/view/PropsFromParentOverrideProvider';
import { isPreviewContext } from '@/app/editor/engine/core/context/isPreviewContext';
import {
    PerspectiveEditorEngineByParentRenderer,
    PerspectiveEditorEngineRenderer,
} from '@/app/editor/engine/PerspectiveEditorEngine';
import { getPreviewChildren } from '@/app/editor/sections/models/sections';
import { useAppSelector } from '@/core/redux/hooks';

import type { PropsFromParent } from '@/app/editor/blocks/types';
import type { PerspectiveEditorEngineByParentRendererProps } from '@/app/editor/engine/types';

export type PerspectiveEditorEnginePreviewAwareNodeRendererProps = {
    /**
     * If provided, these props from parent will override the inherited ones.
     */
    propsFromParent?: PropsFromParent;
} & Omit<PerspectiveEditorEngineByParentRendererProps, 'nodes' | 'context'>;

/**
 * Extends the Editor Engine ByParent and default renderers to render blocks or
 * block previews depending on the value received from `isPreviewContext`.
 */
export const PerspectiveEditorEnginePreviewAwareNodeRenderer = ({
    parentId,
    renderer,
    propsFromParent,
    ...rest
}: PerspectiveEditorEnginePreviewAwareNodeRendererProps) => {
    const isPreview = useContext(isPreviewContext);
    const previewChildren = useAppSelector((state) => getPreviewChildren(state, parentId));

    if (isPreview && parentId === 'root') {
        return null;
    }

    const content = isPreview ? (
        <PerspectiveEditorEngineRenderer {...rest} nodes={previewChildren} renderer={renderer} />
    ) : (
        <PerspectiveEditorEngineByParentRenderer
            parentId={parentId}
            {...rest}
            renderer={renderer}
        />
    );

    return (
        <PropsFromParentOverrideProvider propsFromParent={propsFromParent}>
            {content}
        </PropsFromParentOverrideProvider>
    );
};
