import type { TextSizeShort } from '@/app/editor/blocks/types';
import type { EditorState } from 'draft-js';

export interface SizeMap {
    S: 'SIZE_S';
    M: 'SIZE_M';
    L: 'SIZE_L';
    XL: 'SIZE_XL';
}

export const sizeMap: SizeMap = {
    S: 'SIZE_S',
    M: 'SIZE_M',
    L: 'SIZE_L',
    XL: 'SIZE_XL',
};

export const getActiveTextSize = (editorState: EditorState): TextSizeShort => {
    if (!editorState) {
        return;
    }

    const selection = editorState.getSelection();

    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    if (blockType === 'unstyled') {
        return 'S';
    }

    const keys = Object.keys(sizeMap) as TextSizeShort[];

    return keys.find((key: TextSizeShort) => sizeMap[key as keyof SizeMap] === blockType);
};
