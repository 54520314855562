import { createSlice } from '@reduxjs/toolkit';
import differenceInDays from 'date-fns/differenceInDays';
import Router from 'next/router';
import { toast } from 'sonner';

import { fetchCompany, getCompany } from '@/app/company/models/company';
import { hideModal } from '@/app/modals/models/modals';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiGet, apiPatch, apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { getNow } from '@/utils/common';

import { NAME, SUB_CANCELLED, SUB_IN_TRIAL, SUB_NON_RENEWING } from '../constants';
import { getCurrencyFromPlanId } from '../helpers/currency';

import type { Currency, PlanResource, SubscriptionResource } from '../types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosResponse } from 'axios';

interface State {
    loading: boolean;
    subscription: SubscriptionResource;
    plan: PlanResource;
}

const initialState: State = {
    loading: false,
    subscription: null,
    plan: null,
};

export const subscriptionSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setSubscription(state, action: PayloadAction<SubscriptionResource>) {
            return {
                ...state,
                subscription: action.payload,
            };
        },
        setPlan(state, action: PayloadAction<PlanResource>) {
            return {
                ...state,
                plan: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, setSubscription, setPlan, reset } = subscriptionSlice.actions;

// === Selectors ======

export const getSubscription = (state: AppState) => state[NAME]?.subscription?.subscription;

export const getPlan = (state: AppState) => state[NAME]?.subscription?.plan;

export const getPlanCurrency = (state: AppState): Currency => {
    let planCurrency = state[NAME]?.subscription?.plan?.attributes?.currencyCode;

    // Default to currency from PlanId on reactivation
    if (!planCurrency) {
        const company = getCompany(state);
        planCurrency = getCurrencyFromPlanId(company?.attributes.chargebee.planId);
    }

    return planCurrency as Currency;
};

export const getSubscriptionCancelled = (state: AppState) => {
    const subscription = getSubscription(state);

    return subscription?.attributes?.status === 'non_renewing';
};

export const getLoading = (state: AppState) => state[NAME]?.subscription?.loading;

// === Thunks ======

export const fetchSubscription = (): AppThunk => async (dispatch, getState) => {
    const company = getCompany(getState());

    if (!company) {
        return;
    }

    const subscriptionId = company.relationships.subscription?.data?.id;

    try {
        const promises = [
            apiGet<ResponseData<SubscriptionResource>>(`/subscriptions/${subscriptionId}`),
            apiGet<ResponseData<PlanResource>>(`/subscriptions/${subscriptionId}/plan`),
        ];

        const responses = await Promise.all(promises);
        const subscriptionResponse = responses[0] as AxiosResponse<
            ResponseData<SubscriptionResource, any, any>,
            any
        >;
        const planResponse = responses[1] as AxiosResponse<
            ResponseData<PlanResource, any, any>,
            any
        >;

        const subscription = getDataFromResponse(subscriptionResponse);
        const plan = getDataFromResponse(planResponse);

        dispatch(setSubscription(subscription));
        dispatch(setPlan(plan));

        // Check subscription status and show reactivation toast if necessary
        const status = subscription?.attributes?.status;
        const cancelledAt = subscription?.attributes?.cancelledAt;

        const cancelledInTrial = status === SUB_IN_TRIAL && !!cancelledAt;

        if ([SUB_CANCELLED, SUB_NON_RENEWING].includes(status) || cancelledInTrial) {
            const endDate = cancelledInTrial
                ? new Date(subscription.attributes.trialEnd * 1000)
                : new Date(subscription.attributes.currentTermEnd * 1000);
            const daysLeft = differenceInDays(endDate, getNow());

            showToast({
                type: 'info',
                message: 'common:subscription-ending-in',
                messageValues: { days: daysLeft },
                actionText: 'common:reactivate',

                // eslint-disable-next-line no-use-before-define
                onActionClick: () => dispatch(reactivateSubscription()),
                duration: 0,
            });
        }
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'fetching subscription failed:' });
    }
};

export const reactivateSubscription = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const subscription = getSubscription(state);

    try {
        await apiPost(`/subscriptions/${subscription.id}/reactivate`, {});
        toast.dismiss();
        showToast({ type: 'success', message: 'common:subscription-reactivated' });
        dispatch(fetchSubscription());
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'reactivating subscription failed:' });
    }
};

export const reactivateWithPlanId =
    (planId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const payload = { data: { plan: planId } };

            await apiPost('/subscriptions/reactivate', payload);
            dispatch(fetchSubscription());
            dispatch(fetchCompany());
            await Router.push('/');

            showToast({ type: 'success', message: 'common:subscription-reactivated' });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'reactivating plan failed:' });
        } finally {
            dispatch(setLoading(false));
            dispatch(hideModal());
        }
    };

export const upgradeSubscription =
    (planId: string, addons = []): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));
        const state = getState();
        const subscription = getSubscription(state);

        try {
            const data = {
                data: {
                    plan: planId,
                    addons: addons,
                },
            };

            await apiPatch(`/subscriptions/${subscription.id}/plan`, data);
            dispatch(fetchSubscription());
            dispatch(fetchCompany());
            await Router.push('/');

            showToast({
                type: 'success',
                message: `${NAME}:subscription-upgrade-success`,
                description: `${NAME}:subscription-upgrade-success-description`,
            });
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: 'updating subscription failed:',
                silent: true,
            });

            showToast({
                type: 'error',
                message: `${NAME}:subscription-upgrade-error`,
                description: `${NAME}:subscription-upgrade-error-description`,
            });
        } finally {
            dispatch(hideModal());
            dispatch(setLoading(false));
        }
    };

export default subscriptionSlice.reducer;
