import { rectSortingStrategy, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useMemo } from 'react';

import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { PerspectiveEditorEnginePreviewAwareSequenceRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareSequenceRenderer';
import { useBlockOrPreview } from '@/app/editor/engine/hooks/useBlockOrPreview';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';

import type {
    Alignment,
    DefaultBlockComponentProps,
    GlobalTextSize,
    ListItemVisualSize,
} from '@/app/editor/blocks/types';
import type { BorderRadius } from '@/app/editor/themes/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align: Alignment;
    borderRadius: BorderRadius;
    textSize: GlobalTextSize;
    visualSize: ListItemVisualSize;
}

const NewComponent: FC<Props> = ({
    artBoardIndex,
    box,
    blockId,
    align,
    isPreview,
    borderRadius,
    textSize,
    visualSize,
    isDragged,
}) => {
    const { isInColumn, isVerticalOnly } = useLayoutInfo(blockId, isPreview);
    const block = useBlockOrPreview(blockId);
    const childCount = block.relationships.components.data.length;

    const wrapperClass = isVerticalOnly
        ? 'flex flex-col space-y-4'
        : cn('grid gap-6', {
              'grid-cols-1': childCount < 2,
              'grid-cols-2': childCount >= 2,
          });

    const propsFromParent = useMemo(
        () => ({
            align,
            borderRadius,
            visualSize,
            globalSize: textSize ?? 'small',
        }),
        [align, borderRadius, textSize, visualSize],
    );

    return (
        <Box
            box={getBoxAttributes({ box, isInColumn })}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            <PerspectiveEditorEnginePreviewAwareSequenceRenderer
                className={wrapperClass}
                parentId={blockId}
                sortingStrategy={isVerticalOnly ? verticalListSortingStrategy : rectSortingStrategy}
                propsFromParent={propsFromParent}
            />
        </Box>
    );
};

NewComponent.displayName = 'List';

export default NewComponent;
