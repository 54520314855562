import {
    type EditorEngineDefaultTypeInput,
    EditorEngineStepName,
} from '@/app/editor/engine/core/types';

import type {
    EditorEngineNodeData,
    EditorEngineParentId,
    EditorEngineTransactionStepCreator,
} from '@/app/editor/engine/core/types';

interface ChangeNodeStepInput<TNodeData extends EditorEngineNodeData> {
    /**
     * The ID of the node to change.
     */
    id: EditorEngineParentId;
    /**
     * A function that returns the new data for the node.
     */
    newDataGetter: (oldData: TNodeData) => Partial<Omit<TNodeData, keyof EditorEngineNodeData>>;
}

/**
 * A transaction step that changes a node.
 */
export const changeNodeStep = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    id,
    newDataGetter,
}: ChangeNodeStepInput<TEditorEngineTypeInput['Data']>) => {
    return (({ nodeManager }) => {
        let oldNode: TEditorEngineTypeInput['Data'];

        return {
            name: EditorEngineStepName.ChangeNode,
            debug: {
                id,
            },
            affectedNodes: [id],
            apply() {
                oldNode = { ...nodeManager.getNode(id) };

                nodeManager.changeNode({
                    id,
                    newDataGetter,
                });

                return {
                    success: true,
                };
            },
            revert() {
                nodeManager.changeNode({
                    id,
                    newDataGetter: () => oldNode,
                });

                return {
                    success: true,
                };
            },
        };
    }) satisfies EditorEngineTransactionStepCreator<TEditorEngineTypeInput>;
};
