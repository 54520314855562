import { CALENDLY_URL_REGEX, NAME } from '@/app/editor/blocks/constants';
import { MODAL_OPTIONS } from '@/app/modals/constants';

import { CalendarIcon, EyeIcon } from '@heroicons/react/24/outline';
import get from 'lodash/get';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { Field, getFormValues, initialize, reduxForm } from 'redux-form';

import TextInput from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextInput';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { FormErrors, InjectedFormProps } from 'redux-form';

export interface Props {
    className?: string;
}

const validate = (values: BlockResource): FormErrors => {
    const errors: FormErrors<BlockResource, any> = {
        attributes: {
            content: {},
        },
    };

    const url = get(values, 'attributes.content.url', '');

    if (!url) {
        errors.attributes.content.url = 'calendly-url-required';
    } else if (!CALENDLY_URL_REGEX.test(url)) {
        errors.attributes.content.url = 'invalid-calendly-url';
    }

    return errors;
};

const CalendlyForm = ({
    handleSubmit,
    initialValues,
    className,
}: Props & InjectedFormProps<BlockResource, Props>) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const formValues = useAppSelector(
        (state) => getFormValues(initialValues.id)(state) as BlockResource,
    );

    const handlePreviewClick = () => {
        dispatch(
            showModal(
                Modals.CALENDLY_PREVIEW,
                { url: formValues?.attributes?.content?.url },
                MODAL_OPTIONS.tall,
            ),
        );
    };

    // Re-init on mount is necessary to avoid the form having empty values when user switches between form and submit button
    useEffect(() => {
        dispatch(initialize(initialValues.id, initialValues));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={className || 'mb-2 mt-4'}>
            <Field
                name="attributes.content.url"
                component={TextInput}
                placeholder="https://calendly.com/john-doe/30min"
                icon={CalendarIcon}
                onBlurSubmit={handleSubmit}
                action={{
                    icon: EyeIcon,
                    onClick: handlePreviewClick,
                    tooltip: t('see-calendly-preview'),
                    disabled: !formValues?.attributes?.content?.url,
                }}
            />
        </div>
    );
};

export default reduxForm<BlockResource, Props>({
    validate,
})(CalendlyForm);
