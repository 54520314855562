import { ChevronLeftIcon } from '@heroicons/react/20/solid';

import { cn } from '@/utils/cn';

interface Props {
    direction: 'forward' | 'backward';
    disabled: boolean;
    handleMonthChange: () => void;
}

export const NavButton = ({ direction, disabled, handleMonthChange }: Props) => {
    return (
        <div
            onClick={handleMonthChange}
            className={cn(
                'flex cursor-pointer items-center justify-center p-1.5 text-gray-500 transition-all',
                {
                    'cursor-default text-gray-300': disabled,
                    'hover:text-blue-500': !disabled,
                },
            )}
        >
            <ChevronLeftIcon
                className={cn('size-5 stroke-2', {
                    'rotate-180': direction === 'forward',
                })}
            />
        </div>
    );
};
