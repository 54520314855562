import { MODAL_OPTIONS } from '@/app/modals/constants';

import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { getModal, showModal } from '@/app/modals/models/modals';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { Modals } from '../types';

// TODO: Pass also the props of the modal to the query params for more complex modals
export const useSyncModalWithQueryParams = () => {
    const { query, push } = useRouter();
    const modal = useAppSelector(getModal);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const modalTypeQueryParam = query?.['modal_type'];
        const modalType = String(modalTypeQueryParam).toUpperCase() as keyof typeof Modals;
        const shouldShowModal =
            Modals[modalType] &&
            modal?.type !== Modals[modalType] &&
            MODAL_OPTIONS[modalType]?.canBeOpenedViaUrl;

        // Set modal_type if modal is visible, and it should update query params on open, and modal type is not set in query params
        const shouldSetModalTypeInQueryParams =
            modal?.visible && MODAL_OPTIONS[modal.type]?.canBeOpenedViaUrl && !modalTypeQueryParam;

        // Remove modal_type if modal is no longer visible, but modal_id is still set in query params
        const shouldRemoveModalIdFromQuery = !modal?.visible && modalTypeQueryParam;

        if (shouldShowModal) {
            dispatch(showModal(Modals[modalType], {}, MODAL_OPTIONS[modalType]));

            return;
        } else if (shouldSetModalTypeInQueryParams) {
            push(
                { query: { ...query, modal_type: String(modal?.type).toLowerCase() } },
                undefined,
                {
                    shallow: true,
                },
            );

            return;
        } else if (shouldRemoveModalIdFromQuery) {
            const newQuery = { ...query };
            delete newQuery.modal_type;
            push({ query: { ...newQuery } }, undefined, { shallow: true });

            return;
        }
    }, [dispatch, modal?.type, modal?.visible, push, query]);
};
