import axios from 'axios';
import { toSeconds } from 'duration-fns/dist/toUnit';
import get from 'lodash/get';

import { defaultDetails, type VideoDetails } from '@/hooks/useVideoDetails';
import { reportMessage } from '@/utils/sentry';

export const getYoutubeThumbnail = (videoResponse: any): string => {
    const thumbnails = get(videoResponse, 'snippet.thumbnails');
    let thumbnail: string;

    if (thumbnails && thumbnails.maxres) {
        thumbnail = get(thumbnails, 'maxres.url');
    } else if (thumbnails && thumbnails.high) {
        thumbnail = get(thumbnails, 'high.url');
    } else if (thumbnails && thumbnails.medium) {
        thumbnail = get(thumbnails, 'medium.url');
    } else {
        thumbnail = get(thumbnails, 'default.url');
    }

    return thumbnail;
};

export const normalizeYoutubeData = (videoResponse: any): VideoDetails => {
    return {
        title: get(videoResponse, 'snippet.title'),
        thumbnail: getYoutubeThumbnail(videoResponse),
        duration: toSeconds(get(videoResponse, 'contentDetails.duration')),
    };
};

export const requestYoutubeDetails = async (id: string): Promise<VideoDetails> => {
    try {
        const response = await axios.get(
            `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=${process.env.NEXT_PUBLIC_YOUTUBE_API_KEY}&part=snippet,contentDetails`,
        );

        if (response.status === 200 && response.data.items.length) {
            return normalizeYoutubeData(response.data.items[0]);
        }
    } catch (err) {
        reportMessage({
            message: `fetching Youtube video details for id ${id} failed: ${err.message}`,
            source: 'youtube',
        });
    }

    return defaultDetails;
};
