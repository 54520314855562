import {
    LAST_EDITED_FIELD_NAME,
    NAME,
    PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS,
} from '@/app/crm/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';
import { ORIGIN_MAP } from '@/ui/constants';

import {
    autoUpdate,
    flip,
    offset,
    useFloating,
    FloatingPortal,
    useDismiss,
    useInteractions,
} from '@floating-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Calendar } from 'react-date-range';

import { useDate } from '@/app/crm/hooks/useDate';
import { PropertyActionType } from '@/app/crm/types';
import { track } from '@/core/tracking';
import Navigation from '@/ui/components/DatePicker/Navigation';
import { cn } from '@/utils/cn';
import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { CopyButton } from './CopyButton';
import { InputSettings } from './TextInput/InputSettings';
import { DateCell } from '../../DataGrid/Body/cellTypes/Date';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import type { MouseEvent } from 'react';
import type { FieldRenderProps } from 'react-final-form';

export interface Props extends FieldRenderProps<string> {
    onSubmit: () => void;
}

const nowTimestamp = Date.now();

export const DateInput = ({ input, owner, fieldType, fieldName, onSubmit, inline }: Props) => {
    const { t } = useTranslation(NAME);
    const campaignId = getCampaignIdFromRouter();
    const [open, setOpen] = useState(false);

    const { DateString, formattedTime } = useDate({
        timestamp: input.value,
        tooltipFormatOptions: {
            weekday: inline ? 'short' : 'long',
            day: 'numeric',
            month: inline ? 'short' : 'long',
            year: 'numeric',
        },
    });

    const handleOpen = (event: MouseEvent) => {
        event.stopPropagation();
        setOpen(!open);
    };

    const handleDateChange = (date: Date) => {
        input.onChange(date.toISOString());
        onSubmit();

        setOpen(false);
    };

    const handleCopy = () => {
        // Track 'Copy' click
        track(TRACKING_EVENTS.crm.profile.action, {
            campaign_id: campaignId,
            property_action_type: PropertyActionType.copy,
            crm_property_owner: owner,
            crm_property_data_type: fieldType,
        });
    };

    const { x, y, strategy, context, refs, placement } = useFloating({
        open,
        onOpenChange: setOpen,
        middleware: [
            offset(4),
            flip({
                fallbackPlacements: ['top-end'],
                padding: 10,
            }),
        ],
        placement: 'bottom-end',
        whileElementsMounted: autoUpdate,
    });

    const dismiss = useDismiss(context, {
        bubbles: false,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    // Last edited field is non-editable and use the same format as "created_at"
    if (fieldName === LAST_EDITED_FIELD_NAME) {
        return (
            <DateCell
                value={input.value}
                formatOptions={{
                    day: 'numeric',
                    month: 'short',
                    hour: '2-digit',
                    minute: '2-digit',
                }}
                tooltipFormatOptions={{
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                }}
            />
        );
    }

    return (
        <div className="relative">
            <button
                className={cn(
                    'group relative flex w-full items-center overflow-hidden rounded-md p-2 text-left text-sm text-gray-700 ring-1 transition-all focus:bg-gray-50 hover:focus:bg-gray-50',
                    PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS,
                    {
                        'ring-blue-500': open,
                        'ring-transparent': !open,
                        'hover:bg-gray-200': inline,
                        'hover:bg-gray-100': !inline,
                    },
                )}
                onClick={handleOpen}
                ref={refs.setReference}
                {...getReferenceProps()}
            >
                {input.value ? (
                    <>
                        <div className="max-w-full flex-1 group-focus-within:pr-7 group-hover:pr-7">
                            <DateString
                                tooltipHidden
                                className={cn({
                                    truncate: inline,
                                })}
                            />
                        </div>

                        <InputSettings>
                            <CopyButton value={formattedTime} onCopy={handleCopy} />
                        </InputSettings>
                    </>
                ) : (
                    <span className="text-gray-400">{t('select-a-date')}</span>
                )}
            </button>

            <AnimatePresence>
                {open && (
                    <FloatingPortal>
                        <motion.div
                            className={cn(
                                'z-50 overflow-hidden rounded-lg border bg-white shadow-dropdown',
                                ORIGIN_MAP[placement],
                            )}
                            // Framer motion props
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{
                                opacity: 1,
                                scale: 1,
                                transition: { type: 'spring', duration: 0.2, bounce: 0 },
                            }}
                            exit={{
                                opacity: 0,
                                scale: 0.9,
                                transition: { type: 'spring', duration: 0.15, bounce: 0 },
                            }}
                            // Floating UI props
                            ref={refs.setFloating}
                            style={{
                                position: strategy,
                                top: y ?? 0,
                                left: x ?? 0,
                            }}
                            {...getFloatingProps()}
                        >
                            <Calendar
                                date={input.value ? Date.parse(input.value) : nowTimestamp}
                                onChange={handleDateChange}
                                navigatorRenderer={(
                                    currentFocusedDate: Date,
                                    changeShownDate: Function,
                                ) => (
                                    <Navigation
                                        className="mb-2 flex items-center px-4"
                                        currentFocusedDate={currentFocusedDate}
                                        changeShownDate={changeShownDate}
                                    />
                                )}
                                className={cn('p-0', PREVENT_SIDEBAR_CLICK_OUTSIDE_CLASS)}
                            />
                        </motion.div>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </div>
    );
};
