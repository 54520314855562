import {
    PlusIcon,
    ChevronRightIcon,
    NoSymbolIcon,
    FolderOpenIcon,
} from '@heroicons/react/20/solid';
import {
    ArrowRightStartOnRectangleIcon,
    ArrowTopRightOnSquareIcon,
    BoltIcon,
    FolderIcon,
    LinkIcon,
} from '@heroicons/react/24/outline';
import * as Select from '@radix-ui/react-select';

import { BrandfetchLogo } from '@/app/mediaLibrary/components/MediaLibraryModal/MediaLibContainer/Sidebar/IntegrationList/icons/BrandfetchLogo';
import { UnsplashLogo } from '@/app/mediaLibrary/components/MediaLibraryModal/MediaLibContainer/Sidebar/IntegrationList/icons/UnsplashLogo';
import { cn } from '@/utils/cn';

import type { DropdownOption } from '@/ui/types';
import type { ReactNode } from 'react';

export interface Props {
    option: DropdownOption;
}

export const iconMap = {
    external: ArrowTopRightOnSquareIcon,
    plus: PlusIcon,
    link: LinkIcon,
    bolt: BoltIcon,
    none: NoSymbolIcon,
    arrow: ChevronRightIcon,
    next: ArrowRightStartOnRectangleIcon,
    folder: FolderIcon,
    folderOpen: FolderOpenIcon,
    unsplash: UnsplashLogo,
    brandfetch: BrandfetchLogo,
};

const OptionIcon = ({ children }: { children: ReactNode }) => (
    <span className="mr-3 flex size-5 items-center justify-center font-medium">{children}</span>
);

export const SelectOption = ({ option }: Props) => {
    const Icon = option.icon
        ? typeof option.icon === 'string' && iconMap[option.icon]
            ? iconMap[option.icon]
            : () => option.icon
        : () => null;

    const ActionIcon = option.actionIcon ? iconMap[option.actionIcon] : () => null;

    if (option.isButton) {
        return (
            <button
                onClick={option.action}
                className="flex w-full items-center justify-between rounded p-2 text-sm leading-6 text-gray-500 outline-none hover:bg-gray-100 hover:text-gray-800"
            >
                {option.icon && (
                    <OptionIcon>
                        <Icon className="size-4" />
                    </OptionIcon>
                )}
                <span className="block flex-1 truncate text-left">{option.key}</span>
                {option.action && <ActionIcon className="size-5" />}
            </button>
        );
    }

    return (
        <Select.Item
            key={option.key}
            className={cn(
                'flex cursor-pointer select-none items-center rounded p-2 text-sm text-gray-500 outline-none hover:bg-gray-100 hover:text-gray-800 data-[highlighted]:bg-gray-100 data-[highlighted]:text-gray-800 data-[state=checked]:text-blue-500',
                {
                    'cursor-not-allowed opacity-50': option.disabled,
                },
            )}
            value={option.value}
            disabled={option.disabled}
        >
            {option.icon && (
                <OptionIcon>
                    <Icon className="size-4" />
                </OptionIcon>
            )}
            <span className="block flex-1 truncate">{option.key}</span>
            {option.action && (
                <ActionIcon
                    className="size-5 text-gray-400 hover:text-gray-600"
                    onClick={option.action}
                />
            )}
        </Select.Item>
    );
};
