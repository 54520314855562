import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { setActiveView } from '@/app/editor/editor/models/sidebar';
import { getThemes, setThemes } from '@/app/editor/themes/models/themes';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiDelete, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const deleteThemeSlice = createSlice({
    name: `editor/${NAME}/deleteTheme`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = deleteThemeSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.deleteThemeReducer?.loading;

// === Thunks ======

// Update a theme
export const deleteTheme =
    (themeId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const themes = getThemes(state);

        try {
            dispatch(setLoading(true));

            await apiDelete(`/themes/${themeId}`);

            // Remove from redux
            const withoutTheme = omit(themes, themeId);
            dispatch(setThemes(withoutTheme));

            dispatch(setActiveView('themes'));
        } catch (err) {
            if (err?.response?.status === 400) {
                showToast({
                    type: 'warning',
                    message: `${NAME}:cannot-be-deleted`,
                    description: `${NAME}:cannot-be-deleted-description`,
                });
            } else {
                handleRuntimeError(err, { debugMessage: 'deleting theme failed:' });
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

export default deleteThemeSlice.reducer;
