import { useCallback, useRef } from 'react';

import { usePerspectiveEditorEngineStore } from '@/app/editor/engine/store';

export const usePerspectiveEditorEngineVirtualIdMap = () => {
    const storedVirtualIdMap = usePerspectiveEditorEngineStore.use.virtualIdMap();
    const mapVirtualId = usePerspectiveEditorEngineStore.use.mapVirtualId();
    const virtualIdMap = useRef<Map<string, string>>(storedVirtualIdMap);

    const mapVirtualIdToConcreteId = useCallback(
        (virtualId: string, concreteId: string) => {
            virtualIdMap.current.set(virtualId, concreteId);
            mapVirtualId(virtualId, concreteId);
        },
        [mapVirtualId],
    );

    const resolveVirtualId = useCallback((virtualId: string) => {
        return virtualIdMap.current.get(virtualId) ?? virtualId;
    }, []);

    return {
        virtualIdMap,
        mapVirtualIdToConcreteId,
        resolveVirtualId,
    };
};
