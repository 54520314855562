import { NAME } from '@/app/billing/constants';

import { createSlice } from '@reduxjs/toolkit';

import { BillingTimeframe } from '@/app/billing/types';

import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    timeframe: BillingTimeframe;
}

const initialState: State = {
    timeframe: BillingTimeframe.yearly,
};

export const timeframeSlice = createSlice({
    name: `${NAME}/timeframe`,
    initialState,
    reducers: {
        setTimeframe(state, action: PayloadAction<BillingTimeframe>) {
            state.timeframe = action.payload;
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======

export const { reset, setTimeframe } = timeframeSlice.actions;

// === Selectors ======

export const getTimeframe = (state: AppState) => state[NAME]?.timeframe?.timeframe;

export default timeframeSlice.reducer;
