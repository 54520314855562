import { changeBlockAction } from '@/app/editor/engine/actions/changeBlockAction';
import { deleteBlockAction } from '@/app/editor/engine/actions/deleteBlockAction';
import { duplicateBlockAction } from '@/app/editor/engine/actions/duplicateBlockAction';
import { insertBlockAction } from '@/app/editor/engine/actions/insertBlockAction';
import { insertColumnAction } from '@/app/editor/engine/actions/insertColumnAction';
import { joinBasicBlocksAction } from '@/app/editor/engine/actions/joinBasicBlocksAction';
import { moveBlockAction } from '@/app/editor/engine/actions/moveBlockAction';
import { PerspectiveEditorEngineComponent } from '@/app/editor/engine/components/PerspectiveEditorEngineComponent';
import { PerspectiveEditorEngineNodeWrapper } from '@/app/editor/engine/components/PerspectiveEditorEngineNodeWrapper';
import { createEditorEngine } from '@/app/editor/engine/core/context/createEditorEngine';
import { usePerspectiveDocumentManager } from '@/app/editor/engine/hooks/usePerspectiveDocumentManager';
import { usePerspectiveNodeManager } from '@/app/editor/engine/hooks/usePerspectiveNodeManager';

import type {
    PerspectiveEditorEngineDocumentManagerProps,
    PerspectiveEditorEngineNodeManagerProps,
    PerspectiveEditorEngineTypeInput,
} from '@/app/editor/engine/types';

const actions = {
    insertBlock: insertBlockAction,
    insertColumn: insertColumnAction,
    deleteBlock: deleteBlockAction,
    changeBlock: changeBlockAction,
    duplicateBlock: duplicateBlockAction,
    joinBasicBlocks: joinBasicBlocksAction,
    moveNode: moveBlockAction,
} as const;

export const perspectiveEditorActions = actions;

export const perspectiveEditorEngine = createEditorEngine<
    PerspectiveEditorEngineTypeInput,
    PerspectiveEditorEngineDocumentManagerProps,
    PerspectiveEditorEngineNodeManagerProps
>({
    name: 'PerspectiveEditorEngine',
    Component: PerspectiveEditorEngineComponent,
    actions,
    useDocumentManager: usePerspectiveDocumentManager,
    useNodeManager: usePerspectiveNodeManager,
    CustomNodeWrapper: PerspectiveEditorEngineNodeWrapper,
});

export const PerspectiveEditorEngine = perspectiveEditorEngine.Provider;
export const usePerspectiveEditorEngine = perspectiveEditorEngine.useEditorEngine;
/* eslint-disable-next-line testing-library/render-result-naming-convention */
export const PerspectiveEditorEngineRenderer = perspectiveEditorEngine.Renderer;
/* eslint-disable-next-line testing-library/render-result-naming-convention */
export const PerspectiveEditorEngineByParentRenderer = perspectiveEditorEngine.ByParentRenderer;
export const PerspectiveEditorEngineDraggableConfigurationOverride =
    perspectiveEditorEngine.DraggableConfigurationOverride;
