import { FEATURE_IDS } from '@/app/billing/constants';
import { PERSONALIZATION_TRIGGER } from '@/app/editor/blocks/constants';
import { NAME } from '@/app/editor/editor/constants';

import { AtSymbolIcon } from '@heroicons/react/20/solid';
import { EditorState, Modifier } from 'draft-js';
import { useTranslation } from 'next-i18next';

import { navigateToBillingAndPromoteFeature } from '@/app/billing/helpers/navigateToBillingAndPromoteFeature';
import { getFeatureAvailability } from '@/app/company/models/company';
import { getEditorState, saveDraftState } from '@/app/editor/blocks/models/draftEditorStates';
import { hideModal } from '@/app/modals/models/modals';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import Tooltip from '@/ui/components/Tooltip';

export interface Props {
    blockId: string;
}

const Personalization = ({ blockId }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const editorState = useAppSelector((state) => getEditorState(state, blockId));
    const hasPersonalizationFeature = useAppSelector(
        getFeatureAvailability(FEATURE_IDS.personalization),
    );

    const insertMention = () => {
        const currentContent = editorState.getCurrentContent();
        const currentSelection = editorState.getSelection();

        // Check if last character is a space
        const blockKey = currentSelection.getAnchorKey();
        const block = currentContent.getBlockForKey(blockKey);
        const blockText = block.getText();
        const lastCharacter = blockText.slice(-1);

        // insert with prefixed space if necessary
        const insertCharacter =
            lastCharacter === ' ' ? PERSONALIZATION_TRIGGER : ` ${PERSONALIZATION_TRIGGER}`;

        const newContent = Modifier.replaceText(currentContent, currentSelection, insertCharacter);

        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    };

    const handleClick = async () => {
        if (!hasPersonalizationFeature) {
            dispatch(hideModal());

            return await navigateToBillingAndPromoteFeature(FEATURE_IDS.personalization);
        }

        const newEditorState = insertMention();

        dispatch(saveDraftState(blockId, newEditorState));
    };

    return !editorState ? null : (
        <FieldContainer>
            <Tooltip content={t('personalization')}>
                <button
                    className="bump flex h-12 w-full items-center justify-center rounded-lg bg-gray-100 text-gray-400 hover:text-gray-600"
                    onClick={handleClick}
                >
                    <AtSymbolIcon className="size-5" />
                </button>
            </Tooltip>
        </FieldContainer>
    );
};

export default Personalization;
