import type { EditorEngineHistoryDefaultEntryCollection } from '@/app/editor/engine/core/types';

/**
 * Get the current entry.
 */
export const getCurrentEntry = ({
    entriesInfo: { entries, currentIndex },
    offset = 0,
}: {
    /**
     * Information about the entries.
     */
    entriesInfo: EditorEngineHistoryDefaultEntryCollection;
    /**
     * The offset to apply relative to the current index.
     *
     * This is useful to get the next or previous entry.
     */
    offset?: number;
}) => {
    return entries[currentIndex + offset] ?? null;
};
