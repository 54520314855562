import { Listbox } from '@headlessui/react';

import { ListBoxDropdown } from './ListBoxDropdown';

import type { SelectOption } from '@/app/crm/types';
import type { FieldRenderProps } from 'react-final-form';

interface Props extends FieldRenderProps<string> {
    onSubmit: () => void;
    options: SelectOption[];
}

export const SelectInput = ({ input, options, onSubmit, inline }: Props) => {
    const handleChange = (value: string) => {
        // Deselects value when clicking on selected option
        const isDeselecting = value === input.value;
        const newValue = isDeselecting ? undefined : value;

        input.onChange(newValue);

        onSubmit();
    };

    return (
        <Listbox onChange={handleChange} value={input.value}>
            {({ open }) => (
                <ListBoxDropdown
                    options={options}
                    selected={input.value}
                    open={open}
                    inline={inline}
                />
            )}
        </Listbox>
    );
};
