import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { reduxForm } from 'redux-form';

import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props {}

const Form: FC<Props & InjectedFormProps> = () => {
    const { t } = useTranslation(NAME);

    return (
        <p className="text-sm leading-relaxed text-gray-400">
            {t('block-deprecated-illustration')}
        </p>
    );
};

Form.displayName = 'IllustrationEditForm';

export default reduxForm({})(Form);
