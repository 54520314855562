import { reset as modalReset } from './modal';
import { reset as sharingReset } from './sharing';
import faviconReset from '../../favicon/models/reset';

import type { AppThunk } from '@/core/redux/types';

export const resetFunnelSettingsState = (): AppThunk => (dispatch) => {
    dispatch(modalReset());
    dispatch(sharingReset());
    dispatch(faviconReset());
};
