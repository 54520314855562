import { NAME } from '@/app/user/constants';

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { reloadUser } from '@/app/user/models/user';
import { apiGet } from '@/core/api';
import { StatusCodes } from '@/core/api/types';

import type { UnsubscribeStatus } from '@/app/user/types';
import type { AppState, AppThunk } from '@/core/redux/types';

interface State {
    status: UnsubscribeStatus;
}

const initialState: State = {
    status: 'confirm',
};

const unsubscribeSlice = createSlice({
    name: `${NAME}/unsubscribe`,
    initialState,
    reducers: {
        setStatus(state, action: PayloadAction<UnsubscribeStatus>) {
            return {
                ...state,
                status: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======
export const { setStatus, reset } = unsubscribeSlice.actions;

// === Selectors ======
export const getStatus = (state: AppState) => state[NAME].unsubscribeReducer.status;

// === Thunks ======
export const unsubscribeEmail =
    (userId: string, isRetry = false): AppThunk =>
    async (dispatch) => {
        if (isRetry) {
            dispatch(setStatus('retrying'));
        } else {
            dispatch(setStatus('loading'));
        }

        try {
            const response = await apiGet(`/users/${userId}/unsubscribe`);

            if (response.status === StatusCodes.OK) {
                dispatch(setStatus('success'));
            } else {
                dispatch(setStatus('error'));
            }
        } catch (error) {
            dispatch(setStatus('error'));
        } finally {
            dispatch(reloadUser(userId));
        }
    };

export default unsubscribeSlice.reducer;
