import { useContext, useEffect, useMemo, useState } from 'react';
import { getFormValues } from 'redux-form';

import {
    getBlockComponent,
    getBlockConfig,
    getPreviewIdsToShow,
} from '@/app/editor/blocks/helpers';
import {
    getActiveBlockId,
    getActiveBlockParent,
    getBlockParent,
    setActiveBlock,
} from '@/app/editor/blocks/models/blocks';
import BlockView from '@/app/editor/editor/components/ArtBoard/Block/Block.view';
import { getIsBlockSelectable, getIsBorderMenuVisible } from '@/app/editor/editor/helper';
import { isPreviewContext } from '@/app/editor/engine/core/context/isPreviewContext';
import { EditorEngineNodeDragStateType } from '@/app/editor/engine/core/types';
import {
    getActiveSectionPreviewIds,
    getInsertingInBlankColumnId,
    getPreviewBlockId,
} from '@/app/editor/sections/models/insert';
import { getPreviewOrActiveTheme } from '@/app/editor/themes/models/themes';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import type { EditFormValues } from '@/app/editor/blocks/types';
import type { Props as BlockViewProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.view';
import type { EditorEngineComponent } from '@/app/editor/engine/core/types';
import type { PerspectiveEditorEngineNodeData } from '@/app/editor/engine/types';
import type { MouseEvent } from 'react';

/**
 * The component that the Editor Engine uses to render Perspective nodes in the
 * Perspective Editor Engine.
 */
export const PerspectiveEditorEngineComponent = (({
    node,
    propsFromParent,
    childIndex,
    dragInfo,
    isDraggingDisabled = false,
}) => {
    const isPreview = useContext(isPreviewContext);
    const block = node.block;
    const blockId = block?.id;
    const dispatch = useAppDispatch();
    const formData = useAppSelector(getFormValues(blockId)) as EditFormValues;
    const activeParent = useAppSelector(getActiveBlockParent);
    const previewOrActiveTheme = useAppSelector(getPreviewOrActiveTheme);
    const previewBlockId = useAppSelector(getPreviewBlockId);
    const sectionPreviewIds = useAppSelector(getActiveSectionPreviewIds);
    const insertingInBlankColumnId = useAppSelector(getInsertingInBlankColumnId);
    const activeBlockId = useAppSelector(getActiveBlockId);
    const parentBlock = useAppSelector((state) => getBlockParent(state, blockId));
    const blockComponentType = node.block?.attributes?.componentType;
    const BlockComponent = useMemo(() => {
        return getBlockComponent(blockComponentType);
    }, [blockComponentType]);
    const blockConfig = useMemo(() => getBlockConfig(blockComponentType), [blockComponentType]);
    // show Menu according to block config and position
    const hasBorderMenu = getIsBorderMenuVisible({
        config: blockConfig,
        isDragPreview: dragInfo.type === EditorEngineNodeDragStateType.Overlay,
        parentComponentType: activeParent?.attributes?.componentType,
    });

    // Check if block is selectable
    const isSelectable = getIsBlockSelectable({
        config: blockConfig,
        parentComponentType: parentBlock?.attributes?.componentType,
    });

    // Local state for hover and block data
    const [hovered, setHovered] = useState(false);
    const [blockData, setBlockData] = useState(block);
    const isActiveBlock = blockId === activeBlockId;

    // Determine if a section preview is active/should be shown below the block
    const previewIdsToShow = useMemo(
        () =>
            getPreviewIdsToShow({
                blockId,
                previewBlockId,
                sectionPreviewIds,
            }),
        [blockId, previewBlockId, sectionPreviewIds],
    );

    // Populate block data
    useEffect(() => {
        const data = isActiveBlock && formData ? formData : block;

        setBlockData(data);
    }, [isActiveBlock, formData, block]);

    const dragAttributes =
        dragInfo.type === EditorEngineNodeDragStateType.Original
            ? dragInfo.context.attributes
            : null;
    const dragListeners =
        dragInfo.type === EditorEngineNodeDragStateType.Original ? dragInfo.context.listeners : {};

    // Click a block and start editing
    const handleClick = (event: MouseEvent) => {
        if (!isSelectable || isPreview) {
            return;
        }

        event.stopPropagation();

        // Check for active text selection
        // fixes this issue: https://linear.app/perspective/issue/PER-1886/selecting-text-and-release-on-other-block)
        const selection = window?.getSelection();

        // only handle click when there is no active text selection
        if (selection?.toString().length === 0 && isSelectable) {
            const clickCount = event.detail;

            if (clickCount === 1) {
                dispatch(setActiveBlock(blockId));
            }

            // Double-click on top level block -> run double click from block config
            if (clickCount === 2 && blockConfig?.actions?.onDoubleClick) {
                dispatch(blockConfig.actions.onDoubleClick(blockData, activeParent));
            }
        }
    };

    // Hover states
    const handleHover = (event: MouseEvent) => {
        if (isSelectable && !dragInfo.isDragOperationInProgress) {
            event.stopPropagation();
            setHovered(true);
        }
    };

    const handleLeave = (event: MouseEvent) => {
        if (isSelectable) {
            event.stopPropagation();
        }

        setHovered(false);
    };

    if (typeof blockData === 'undefined' || !blockData?.id || blockData.id !== blockId) {
        return null;
    }

    const sharedBlockProps = {
        blockId,
        block: blockData,
        component: BlockComponent,
        hasBorderMenu,
        isActive: isActiveBlock,
        isHovered: hovered,
        onClick: handleClick,
        onHover: handleHover,
        onLeave: handleLeave,
        dragInfo,
        isDragged: dragInfo.isDragged,
        isDragPreview: dragInfo.type === EditorEngineNodeDragStateType.Overlay,
        dragStyle: {
            opacity: dragInfo.isDragged ? 0.5 : 1,
        }, // todo(editorengine), sync with Felix on this
        dragAttributes,
        dragListeners,
        propsFromParent,
        activeTheme: previewOrActiveTheme,
        insertingInBlankColumnId,
        sectionPreviewIds: previewIdsToShow,
        additionalBlocks: formData?.additionalBlocks,
        isHighlighted: false,
        artBoardIndex: childIndex,
        nestedLevel: 0,
        isDraggingDisabled,
    } satisfies BlockViewProps;

    return (
        <BlockView
            {...sharedBlockProps}
            ref={
                dragInfo.type === EditorEngineNodeDragStateType.Original
                    ? dragInfo.context.setNodeRef
                    : null
            }
        />
    );
}) satisfies EditorEngineComponent<{}, PerspectiveEditorEngineNodeData>;
