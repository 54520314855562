import { INVALID_SCRIPT, MALFORMED_SCRIPT, removeTextNodes } from '../helper';

export const provenexpertRatingValidator = (container: HTMLDivElement) => {
    let hasPewlElement = false;
    let hasScriptTag = false;
    let hasStyleSheet = false;

    removeTextNodes(container);

    if (container.childNodes.length === 5) {
        container.removeChild(container.firstChild);
        container.removeChild(container.lastChild);
    } else {
        return {
            valid: false,
            message: MALFORMED_SCRIPT,
        };
    }

    if (container.firstElementChild?.id === 'pewl') {
        hasPewlElement = true;
    }

    if (container.children[1]?.getAttribute('src')?.includes('provenexpert.com')) {
        hasScriptTag = true;
    }

    if (
        container.children[2]?.getAttribute('href')?.includes('provenexpert.com') &&
        container.children[2]?.tagName === 'LINK'
    ) {
        hasStyleSheet = true;
    }

    return {
        valid: hasPewlElement && hasScriptTag && hasStyleSheet,
        message: INVALID_SCRIPT,
    };
};
