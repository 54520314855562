import { useRef } from 'react';
import ContentEditable from 'react-contenteditable';

import { getCleanIconId } from '@/app/editor/blocks/helpers';
import { useContentEditablePlaceholder } from '@/app/editor/blocks/hooks/useContentEditablePlaceholder';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

export interface Props {
    showIcon: boolean;
    required: boolean;
    placeholder: string;
    icon: string;
    blockId: string;
    isActiveBlock: boolean;
}

const FileInput = ({ icon, showIcon, placeholder, required, blockId, isActiveBlock }: Props) => {
    const placeholderRef = useRef(null);
    const { updatedPlaceholder, handlePlaceholderChange, handlePlaceholderBlur, handleKeyDown } =
        useContentEditablePlaceholder({
            blockId,
            initialPlaceholder: placeholder,
            ref: placeholderRef,
        });
    const borderRadius = useBorderRadius('fieldsWrapper');

    const cleanIconId = getCleanIconId(icon);

    return (
        <div className={cn('border bg-white p-2', borderRadius)}>
            <div className="flex items-start rounded-md border border-dashed p-4">
                {showIcon && (
                    <div className="text-zero">
                        {/* @ts-ignore */}
                        <em-emoji id={cleanIconId} size="20px" />
                    </div>
                )}
                <div className={showIcon ? 'pl-4' : undefined}>
                    <div className="text-sm">
                        <ContentEditable
                            innerRef={placeholderRef}
                            html={updatedPlaceholder}
                            onChange={handlePlaceholderChange}
                            onBlur={handlePlaceholderBlur}
                            className="ring-none cursor-text outline-none"
                            onKeyDown={handleKeyDown}
                            disabled={!isActiveBlock}
                        />
                    </div>
                    <div className="text-xs text-gray-400">
                        ({required ? '' : 'optional, '}max. 5MB, .pdf, .png, .jpg)
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileInput;
