import type { TransitionEffect, BorderRadius } from '@/app/editor/themes/types';
import type { RadioSliderOption, RadioTilesOption } from '@/ui/types';

export const NAME = 'themes';

// Themes to show
export const THEMES_TO_SHOW = [
    '5a97ffea2995db0013723494',
    '5d5b04db87f853001d0c7c84',
    '5d6ed7fb8076d2001d42b459',
    '6167f75b2d8fdb0020707f54',
    '616847e6fedd4100204b631a',
    '617f2195b1ce50002005ab0e',
    '618872e11f56730020f6248a',
    '618878694894bd0020c3bb02',
    '618882a41f56730020f626ec',
    '618889781f56730020f628a9',
    '618895eb4894bd0020c3bf38',
    '61a905412f1cf400a10de7b3',
    '617f4614d1c6930020458ada',
    '642ab160c01e0c00143a618c',
    '6467682516972300147a16c6',
    '64cd00dc0afbb70014e0cee3',
    '671892881a96d876a0c191b7',
];

// Lightness dependent colors
export const COLOR_LIGHT = '#FCFCFC';
export const COLOR_DARK = '#2A2A2A';

// Parents with transparent Text children
export const PARENTS_WITH_TRANSPARENT_TEXT_CHILDREN = ['accordionItem', 'listItem'];

// Create/update
export const THEME_CONFIG_FORM = 'theme-config';

// "Don't ask again" LS key
export const APPLY_THEME_REMEMBER_LS_KEY = 'apply-theme-remember';
export const FONT_DELETION_REMINDER_LS_KEY = 'font-deletion-reminder';

// Fonts
export const THEMING_FONTS = [
    {
        key: 'Inter',
        value: 'Inter:300,600',
    },
    {
        key: 'Open Sans',
        value: 'Open Sans:300,600',
    },
    {
        key: 'Lato',
        value: 'Lato:300,700',
    },
    {
        key: 'Roboto',
        value: 'Roboto:300,700',
    },
    {
        key: 'Roboto Condensed',
        value: 'Roboto Condensed:300,700',
    },
    {
        key: 'Roboto Slab',
        value: 'Roboto Slab:300,700',
    },
    {
        key: 'Montserrat',
        value: 'Montserrat:300,700',
    },
    {
        key: 'Source Sans Pro',
        value: 'Source Sans Pro:300,700',
    },
    {
        key: 'Source Serif Pro',
        value: 'Source Serif Pro:300,700',
    },
    {
        key: 'Merriweather',
        value: 'Merriweather:300,700',
    },
    {
        key: 'Playfair Display',
        value: 'Playfair Display:400,700',
    },
    {
        key: 'Merienda',
        value: 'Merienda:400,700',
    },
    {
        key: 'Poppins',
        value: 'Poppins:400,700',
    },
    {
        key: 'Oswald',
        value: 'Oswald:400,700',
    },
    {
        key: 'Raleway',
        value: 'Raleway:400,700',
    },
    {
        key: 'Roboto Mono',
        value: 'Roboto Mono:400,700',
    },
    {
        key: 'Martel Sans',
        value: 'Martel Sans:400,700',
    },
    {
        key: 'Cambay',
        value: 'Cambay:400,700',
    },
    {
        key: 'Mulish',
        value: 'Mulish:400,700',
    },
    {
        key: 'Nunito',
        value: 'Nunito:400,700',
    },
    {
        key: 'Nunito Sans',
        value: 'Nunito Sans:400,700',
    },
    {
        key: 'Fira Sans',
        value: 'Fira Sans:400,700',
    },
    {
        key: 'Barlow',
        value: 'Barlow:400,700',
    },
    {
        key: 'Libre Franklin',
        value: 'Libre Franklin:400,700',
    },
    {
        key: 'Exo 2',
        value: 'Exo 2:400,700',
    },
    {
        key: 'Noto Sans',
        value: 'Noto Sans:400,700',
    },
    {
        key: 'Noto Serif',
        value: 'Noto Serif:400,700',
    },
    {
        key: 'Libre Baskerville',
        value: 'Libre Baskerville:400,700',
    },
    {
        key: 'Bitter',
        value: 'Bitter:400,700',
    },
    {
        key: 'Crimson Pro',
        value: 'Crimson Pro:400,700',
    },
    {
        key: 'Karla',
        value: 'Karla:400,700',
    },
    {
        key: 'Rubik',
        value: 'Rubik:400,700',
    },
    {
        key: 'Chivo',
        value: 'Chivo:400,700',
    },
    {
        key: 'Work Sans',
        value: 'Work Sans:400,700',
    },
    {
        key: 'Cabin',
        value: 'Cabin:400,700',
    },
    {
        key: 'Space Mono',
        value: 'Space Mono:400,700',
    },
    {
        key: 'Space Grotesk',
        value: 'Space Grotesk:400,700',
    },
    {
        key: 'Syne',
        value: 'Syne:400,700',
    },
    {
        key: 'Cormorant',
        value: 'Cormorant:400,700',
    },
    {
        key: 'Eczar',
        value: 'Eczar:400,700',
    },
    {
        key: 'Alegreya Sans',
        value: 'Alegreya Sans:400,700',
    },
    {
        key: 'Alegreya',
        value: 'Alegreya:400,700',
    },
    {
        key: 'Lora',
        value: 'Lora:400,700',
    },
    {
        key: 'IBM Plex Sans',
        value: 'IBM Plex Sans:400,700',
    },
    {
        key: 'Anonymous Pro',
        value: 'Anonymous Pro:400,700',
    },
    {
        key: 'Ubuntu',
        value: 'Ubuntu:400,700',
    },
    {
        key: 'Oxygen',
        value: 'Oxygen:400,700',
    },
    {
        key: 'Arimo',
        value: 'Arimo:400,700',
    },
];

// Fonts
export const THEMING_TRANSITIONS = [
    {
        key: 'Default',
        value: 'default',
    },
    {
        key: 'Slide',
        value: 'slide',
    },
    {
        key: 'Scale',
        value: 'scale',
    },
    {
        key: 'Fade',
        value: 'fade',
    },
    {
        key: 'Blur',
        value: 'blur',
    },
    {
        key: 'None',
        value: 'none',
    },
] satisfies RadioTilesOption<TransitionEffect>[];

export const THEMING_BORDER_RADII = [
    {
        name: 'Sharp',
        value: 'sharp',
    },
    {
        name: 'Small',
        value: 'small',
    },
    {
        name: 'Medium',
        value: 'medium',
    },
    {
        name: 'Large',
        value: 'large',
    },
] satisfies RadioSliderOption<BorderRadius>[];

export const CUSTOM_FONTS_HELP_ARTICLE_URL_EN =
    'https://intercom.help/perspective-funnels/en/articles/8703425-custom-font';
export const CUSTOM_FONTS_HELP_ARTICLE_URL_DE =
    'https://intercom.help/perspective-funnels/de/articles/8703425-benutzerdefinierte-schriftart';

export const CUSTOM_FONTS_ALLOWED_MIME_TYPES = ['font/woff', 'font/ttf', 'font/otf'];
