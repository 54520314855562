const KILO = 1000;
const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

// From bytes to short version. 1000 Bytes -> 1 KB
export const formatBytes = (bytes: number, decimals = 2) => {
    if (!bytes) {
        return '0 Bytes';
    }

    const dm = Math.max(0, decimals);
    const i = Math.floor(Math.log(bytes) / Math.log(KILO));

    return `${parseFloat((bytes / Math.pow(KILO, i)).toFixed(dm))} ${SIZES[i]}`;
};
