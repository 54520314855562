import { createSelector, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { fetchCampaignAnalytics } from '@/app/campaigns/models/analytics';
import { handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import { ANALYTICS_KEYS, NAME } from '../constants';
import { createKpis, navigateToContacts, navigateToSessions } from '../helper';

import type { Kpi } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    kpis: {
        [id: string]: Kpi[];
    };
}

const initialState: State = { kpis: EMPTY_OBJECT };

export const kpisSlice = createSlice({
    name: `${NAME}/kpis`,
    initialState,
    reducers: {
        setKpisForId(state, action: PayloadAction<{ id: string; kpis: Kpi[] }>) {
            return {
                ...state,
                kpis: { [action.payload.id]: action.payload.kpis },
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setKpisForId, reset } = kpisSlice.actions;

// === Selectors ======

const getCampaignKpis = (state: AppState) => state[NAME]?.kpisReducer?.kpis || EMPTY_OBJECT;

export const getKpisForCampaignId = (campaignId: string) =>
    createSelector(getCampaignKpis, (kpis) => {
        return get(kpis, campaignId, EMPTY_ARRAY);
    });

// === Thunks ======

export const fetchCampaignKpis =
    (campaignId: string): AppThunk =>
    async (dispatch) => {
        try {
            const kpisRes = await dispatch(
                fetchCampaignAnalytics({
                    campaignId,
                    analyticsKey: ANALYTICS_KEYS.kpis,
                }),
            );

            if (!kpisRes) {
                return;
            }

            const { attributes: kpisData } = getDataFromResponse(kpisRes, EMPTY_OBJECT);

            const kpis: Kpi[] =
                !isEmpty(kpisData) &&
                createKpis(
                    kpisData,
                    navigateToSessions(campaignId),
                    navigateToContacts(campaignId),
                );

            return dispatch(setKpisForId({ id: campaignId, kpis }));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching campaign KPIs failed:' });
        }
    };

export default kpisSlice.reducer;
