import { createSlice } from '@reduxjs/toolkit';

import { getCompany } from '@/app/company/models/company';
import {
    addThemes,
    getActiveTheme,
    getPreviewTheme,
    setActiveTheme,
    setPreviewTheme,
} from '@/app/editor/themes/models/themes';
import { apiPatch, handleRuntimeError } from '@/core/api';

import { NAME } from '../constants';

import type { ThemeResource } from '@/app/editor/themes/types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = {
    loading: false,
};

export const updateThemeSlice = createSlice({
    name: `editor/${NAME}/updateTheme`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = updateThemeSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.updateThemeReducer?.loading;

// === Thunks ======

// Update a theme
export const updateTheme =
    (theme: ThemeResource): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();

        const activeTheme = getActiveTheme(state);
        const previewTheme = getPreviewTheme(state);
        const company = getCompany(state);

        const isUpdatingActiveTheme = activeTheme.id === previewTheme?.id;

        try {
            dispatch(setLoading(true));

            // Optimistic update on active theme
            if (isUpdatingActiveTheme) {
                dispatch(setActiveTheme(theme));
            }

            const response = await apiPatch<ResponseData<ThemeResource>>(`/themes/${theme.id}`, {
                data: {
                    type: 'theme',
                    attributes: theme.attributes,
                    relationships: {
                        company: { data: { id: company.id, type: 'company' } },
                    },
                },
            });

            const updatedTheme = response?.data?.data;

            dispatch(addThemes({ [updatedTheme.id]: updatedTheme }));

            const newState = getState();
            const isPreviewActive = !!getPreviewTheme(newState);

            if (isPreviewActive) {
                dispatch(setPreviewTheme(updatedTheme));
            }
        } catch (err) {
            // Reverts optimistic update on active theme
            if (isUpdatingActiveTheme) {
                dispatch(setActiveTheme(activeTheme));
            }

            handleRuntimeError(err, { debugMessage: 'updating theme failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default updateThemeSlice.reducer;
