import { insertNodeStep } from '@/app/editor/engine/core/utils/transaction/steps/insertNodeStep';
import { PerspectiveEditorEngineActionName } from '@/app/editor/engine/types';
import { apiDelete } from '@/core/api';

import type { EditorEngineActionCreatorPayload } from '@/app/editor/engine/core/types';
import type { PerspectiveEditorEngineActionCreator } from '@/app/editor/engine/types';

/**
 * Action that will insert a block.
 */
export const insertBlockAction = (({
    documentManager,
    nodeManager,
    extraContext: { backendEntities, clientSideComponents },
}) => {
    const { payloads, rootLevelBlockIds } =
        documentManager.makeNewNodePayloadsRecursivelyFromPreview();
    const virtualBlocks = payloads.map((payload) => payload.node.block);

    const shouldSkip = documentManager.isUsingSection() || virtualBlocks.length === 0;

    documentManager.setUsingSection(true);

    const { activeSectionId, previewIndex } = documentManager.getPreviewInfo();

    // Props must be calculated here, so redoing will work correctly even if the
    // values returned by documentManager have changed in the meantime.
    const props = {
        funnelId: documentManager.activeFunnel.id,
        pageId: documentManager.activePage.id,
        sectionId: activeSectionId,
        parentId: payloads[0].parentId,
        insertIndex: previewIndex,
        mapVirtualIdToConcreteId: nodeManager.mapVirtualIdToConcreteId,
        resolveVirtualId: nodeManager.resolveVirtualId,
        makeParentIdOptionalObject: nodeManager.makeParentIdOptionalObject,
        virtualBlocks,
        updateBlockAttributes: nodeManager.updateBlockAttributes,
        updateTrackingId: nodeManager.updateTrackingId,
    } satisfies Parameters<typeof backendEntities.createBlock>[0];

    return {
        name: PerspectiveEditorEngineActionName.InsertBlock,
        debug: {},
        shouldBeSkipped() {
            // This is to prevent double insertions.
            // todo(editorengine): this function should probably be pure. Find a different way.
            documentManager.setUsingSection(false);

            return shouldSkip;
        },
        getTransaction() {
            return payloads.map(insertNodeStep);
        },
        onAfterOptimisticForward() {
            documentManager.clearPreview();
            clientSideComponents.scrollToComponent.focusOn(virtualBlocks[0]);
        },
        onAfterOptimisticBackward() {
            documentManager.setActiveView('pages');
        },
        async execute() {
            await backendEntities.createBlock(props);

            return {
                success: true,
            };
        },
        async undo() {
            for (const rootLevelBlockId of rootLevelBlockIds) {
                await apiDelete(`/components/${nodeManager.resolveVirtualId(rootLevelBlockId)}`);
            }

            return {
                success: true,
            };
        },
    };
}) satisfies PerspectiveEditorEngineActionCreator<EditorEngineActionCreatorPayload, {}>;
