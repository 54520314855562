import { NAME } from '@/app/editor/blocks/constants';

import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import Box from '@/app/editor/blocks/components/_wrapper/Box';
import { providerLogoMap } from '@/app/editor/blocks/components/Embed/helper';
import imgix from '@/core/imgix';
import { cn } from '@/utils/cn';

import type { BoxProps } from '@/app/editor/blocks/types';

interface Props {
    provider: string;
    isDragged: boolean;
    box: BoxProps;
}

const Placeholder = ({ box, isDragged, provider }: Props) => {
    const { t } = useTranslation(NAME);
    const boxClasses = cn(
        'relative isolate flex items-center justify-center overflow-hidden rounded-xl border border-dashed p-6',
        {
            'border-[#2F9D7A] bg-[#EFFAF7] text-[#2F9D7A]': provider === 'trustpilot',
            'border-gray-400 bg-gray-50 text-gray-900': provider !== 'trustpilot',
        },
    );

    const providerLogoSrc: string | null = providerLogoMap[provider];

    return (
        <Box box={{ ...box, left: 'medium', right: 'medium' }} isDragged={isDragged}>
            <div className={boxClasses}>
                {provider === 'google-maps' && (
                    <Image
                        src={imgix.editor.embedBlock.googleMapsBg}
                        alt="google maps placeholder"
                        className="absolute inset-0 z-10 object-cover"
                        fill
                    />
                )}
                <div className="relative z-20 flex flex-col items-center">
                    {providerLogoSrc && (
                        <Image src={providerLogoSrc} alt="embed placeholder" className="mb-5" />
                    )}
                    <p className="text-center font-sans text-sm font-medium">
                        {t('embed-placeholder-text')}
                    </p>
                </div>
            </div>
        </Box>
    );
};

export default Placeholder;
