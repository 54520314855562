import { ENDPOINTS, MUTATION_KEYS } from '@/app/editor/blocks/constants';

import { useMutation } from '@tanstack/react-query';

import { apiPatch } from '@/core/api';
import { getDataFromResponse, showNetworkError } from '@/core/api/helper';

import type { ConnectPaymentAccountResource } from '@/app/editor/blocks/types';
import type { ResponseData } from '@/core/api/types';

export const usePaymentAccountConnection = (componentId: string) => {
    return useMutation({
        mutationKey: MUTATION_KEYS.connectPaymentAccount(componentId),
        mutationFn: async () =>
            apiPatch<ResponseData<ConnectPaymentAccountResource>>(
                ENDPOINTS.PATCH.connectPaymentAccount(componentId),
                {},
            ),
        onSuccess: (response) => {
            const connectPaymentAccount = getDataFromResponse(response);
            const { onboardingLink } = connectPaymentAccount.attributes;
            window.open(onboardingLink, '_blank');
        },
        onError: (err) => {
            showNetworkError(err);
        },
    });
};
