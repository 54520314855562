import { UNIQUE_INPUTS } from '@/app/editor/blocks/constants';
import { TRACKING_ID_FIELD_NAMES } from '@/app/editor/engine/constants';

import type { BlockResource } from '@/app/editor/blocks/types';

export const emptyNonReservedTrackingFields = (block: BlockResource) => {
    const { content = {} } = block.attributes;

    const overrideNonReservedId = (key: string) => {
        return key in content && !UNIQUE_INPUTS.includes(content[key]) ? { [key]: '' } : {};
    };

    let overrides = {};

    for (const fieldName of TRACKING_ID_FIELD_NAMES) {
        overrides = {
            ...overrides,
            ...overrideNonReservedId(fieldName),
        };
    }

    return {
        ...block,
        attributes: {
            ...block.attributes,
            content: {
                ...block.attributes.content,
                ...overrides,
            },
        },
    };
};
