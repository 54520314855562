import { reset as resetBlockOrder } from './blockOrder';
import { reset as resetBlocks } from './blocks';
import { reset as duplicateReset } from './duplicate';
import { reset as resetInputTypes } from './inputTypes';
import { reset as resetMediaSize } from './mediaSize';
import { reset as personalizationReset } from './personalization';
import { reset as tipTapReset } from './tiptap';
import { reset as webinarReset } from './webinar';

import type { AppThunk } from '@/core/redux/types';

const reset =
    (withBlockCopyPasteClipboard = true): AppThunk =>
    (dispatch) => {
        dispatch(resetBlocks());
        dispatch(resetBlockOrder());
        dispatch(resetInputTypes());
        dispatch(resetMediaSize());
        dispatch(duplicateReset(withBlockCopyPasteClipboard));
        dispatch(personalizationReset());
        dispatch(webinarReset());
        dispatch(webinarReset());
        dispatch(tipTapReset());
    };

export default reset;
