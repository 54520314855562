import { DATE_FORMAT } from '@/app/editor/editor/constants';

import type { InputTypeOptions } from '@/app/editor/editor/types';

export const inputTypeOptions: InputTypeOptions = {
    en: [
        {
            icon: ':email:',
            name: 'Email',
            field: 'email',
            inputType: 'email',
            placeholder: 'Your email address',
        },
        {
            icon: ':bust_in_silhouette:',
            customSelectIcon: ':card_index:',
            name: 'Full name',
            field: 'name',
            inputType: 'text',
            placeholder: 'Your first and last name',
        },
        {
            icon: ':wave:',
            name: 'First Name',
            field: 'firstName',
            inputType: 'text',
            placeholder: 'Your first name',
        },
        {
            icon: ':wave:',
            customSelectIcon: ':bust_in_silhouette:',
            name: 'Last Name',
            field: 'lastName',
            inputType: 'text',
            placeholder: 'Your last name',
        },
        {
            icon: ':telephone_receiver:',
            name: 'Phone',
            field: 'phone',
            inputType: 'phone',
            placeholder: 'Your phone number',
            customOptions: {
                language: 'en',
            },
        },
        {
            icon: ':tada:',
            name: 'Birthday',
            field: 'birthday',
            inputType: 'birthday',
            placeholder: 'Your birthday',
            customOptions: {
                dateFormat: DATE_FORMAT.DEFAULT_US,
            },
        },
        {
            icon: ':calendar:',
            name: 'Date',
            field: 'datePicker',
            inputType: 'datePicker',
            placeholder: 'Pick a date',
            customOptions: {
                language: 'en',
            },
        },
        {
            icon: ':mantelpiece_clock:',
            name: 'Time',
            field: 'time',
            inputType: 'dropdown',
            placeholder: 'Pick a time',
            customOptions: {
                language: 'en',
                twoColumn: false,
                multiSelect: false,
                choices: ['8:00', '9:00', '10:00', '11:00', '12:00', '13:00'],
            },
        },
        {
            icon: ':clipboard:',
            name: 'Dropdown',
            field: 'dropdown',
            inputType: 'dropdown',
            placeholder: 'Pick a day',
            customOptions: {
                language: 'en',
                twoColumn: false,
                multiSelect: false,
                choices: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                ],
            },
        },
        {
            icon: ':link:',
            name: 'Website',
            field: 'website',
            inputType: 'url',
            placeholder: 'Your company website',
        },
        {
            icon: ':speech_balloon:',
            name: 'Message',
            field: 'message',
            inputType: 'textarea',
            placeholder: 'Your message',
        },
        {
            icon: ':file_folder:',
            name: 'File Upload',
            field: 'file',
            inputType: 'file',
            placeholder: 'Click here to upload file',
        },
        {
            icon: ':house:',
            customSelectIcon: ':house_with_garden:',
            name: 'Street',
            field: 'street',
            inputType: 'text',
            placeholder: 'Your street',
        },
        {
            icon: ':cityscape:',
            name: 'City',
            field: 'city',
            inputType: 'text',
            placeholder: 'Your city',
        },
        {
            icon: ':mailbox:',
            name: 'Zip Code',
            field: 'zip',
            inputType: 'text',
            placeholder: 'Your zip code',
        },
        {
            icon: ':earth_africa:',
            name: 'Country',
            field: 'country',
            inputType: 'text',
            placeholder: 'Your country',
        },
        {
            icon: ':gear:',
            name: 'Custom',
            field: 'custom',
            inputType: 'text',
            placeholder: 'Custom text',
        },
        {
            icon: ':white_check_mark:',
            misc: {
                wysiwyg: {
                    blocks: [
                        {
                            key: 'df6bf7',
                            data: {},
                            text: 'I accept the terms of use',
                            type: 'SIZE_CHECKBOX',
                            depth: 0,
                            entityRanges: [
                                {
                                    key: 0,
                                    length: 12,
                                    offset: 13,
                                },
                            ],
                            inlineStyleRanges: [],
                        },
                    ],
                    entityMap: {
                        '0': {
                            data: { url: 'https://perspective.co/datenschutzerklaerung/' },
                            type: 'LINK',
                            mutability: 'MUTABLE',
                        },
                    },
                },
            },
            name: 'Checkbox',
            field: 'consent',
            inputType: 'checkbox',
            placeholder: 'consent',
        },
    ],
    de: [
        {
            icon: ':email:',
            name: 'E-Mail',
            field: 'email',
            inputType: 'email',
            placeholder: 'Deine E-Mail Adresse',
        },
        {
            icon: ':bust_in_silhouette:',
            customSelectIcon: ':card_index:',
            name: 'Voller Name',
            field: 'name',
            inputType: 'text',
            placeholder: 'Dein Vor- und Nachname',
        },
        {
            icon: ':wave:',
            name: 'Vorname',
            field: 'firstName',
            inputType: 'text',
            placeholder: 'Dein Vorname',
        },
        {
            icon: ':wave:',
            customSelectIcon: ':bust_in_silhouette:',
            name: 'Nachname',
            field: 'lastName',
            inputType: 'text',
            placeholder: 'Dein Nachname',
        },
        {
            icon: ':telephone_receiver:',
            name: 'Telefon',
            field: 'phone',
            inputType: 'phone',
            placeholder: 'Deine Telefonnummer',
            customOptions: {
                language: 'de',
            },
        },
        {
            icon: ':tada:',
            name: 'Geburtstag',
            field: 'birthday',
            inputType: 'birthday',
            placeholder: 'Dein Geburtstag',
            customOptions: {
                dateFormat: DATE_FORMAT.DEFAULT_EU,
            },
        },
        {
            icon: ':mantelpiece_clock:',
            name: 'Uhrzeit',
            field: 'time',
            inputType: 'dropdown',
            placeholder: 'Wähle eine Uhrzeit',
            customOptions: {
                language: 'de',
                twoColumn: false,
                multiSelect: false,
                choices: ['8:00', '9:00', '10:00', '11:00', '12:00', '13:00'],
            },
        },
        {
            icon: ':clipboard:',
            name: 'Dropdown',
            field: 'dropdown',
            inputType: 'dropdown',
            placeholder: 'Wähle ein Datum',
            customOptions: {
                language: 'de',
                twoColumn: false,
                multiSelect: false,
                choices: [
                    'Montag',
                    'Dienstag',
                    'Mittwoch',
                    'Donnerstag',
                    'Freitag',
                    'Samstag',
                    'Sonntag',
                ],
            },
        },
        {
            icon: ':calendar:',
            name: 'Datum',
            field: 'datePicker',
            inputType: 'datePicker',
            placeholder: 'Wähle ein Datum',
            customOptions: {
                language: 'de',
            },
        },
        {
            icon: ':link:',
            name: 'Webseite',
            field: 'website',
            inputType: 'url',
            placeholder: 'Deine Firmenwebsite',
        },
        {
            icon: ':speech_balloon:',
            name: 'Nachricht',
            field: 'message',
            inputType: 'textarea',
            placeholder: 'Deine Nachricht',
        },
        {
            icon: ':file_folder:',
            name: 'Datei Upload',
            field: 'file',
            inputType: 'file',
            placeholder: 'Hier klicken und Datei hochladen',
        },
        {
            icon: ':house:',
            customSelectIcon: ':house_with_garden:',
            name: 'Straße',
            field: 'street',
            inputType: 'text',
            placeholder: 'Deine Straße',
        },
        {
            icon: ':cityscape:',
            name: 'Stadt',
            field: 'city',
            inputType: 'text',
            placeholder: 'Deine Stadt',
        },
        {
            icon: ':mailbox:',
            name: 'Postleitzahl',
            field: 'zip',
            inputType: 'text',
            placeholder: 'Deine Postleitzahl',
        },
        {
            icon: ':earth_africa:',
            name: 'Land',
            field: 'country',
            inputType: 'text',
            placeholder: 'Dein Land',
        },
        {
            icon: ':gear:',
            name: 'Benutzerdefiniert',
            field: 'custom',
            inputType: 'text',
            placeholder: 'Benutzerdefiniert',
        },
        {
            icon: ':white_check_mark:',
            misc: {
                wysiwyg: {
                    blocks: [
                        {
                            key: 'df6bf7',
                            data: {},
                            text: 'Ich akzeptiere die Datenschutzbestimmungen',
                            type: 'SIZE_CHECKBOX',
                            depth: 0,
                            entityRanges: [
                                {
                                    key: 0,
                                    length: 23,
                                    offset: 19,
                                },
                            ],
                            inlineStyleRanges: [],
                        },
                    ],
                    entityMap: {
                        '0': {
                            data: { url: 'https://perspective.co/datenschutzerklaerung/' },
                            type: 'LINK',
                            mutability: 'MUTABLE',
                        },
                    },
                },
            },
            name: 'Checkbox',
            field: 'consent',
            inputType: 'checkbox',
            placeholder: 'consent',
        },
    ],
};
