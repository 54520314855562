import type {
    EditorEngineDefaultTypeInput,
    EditorEngineHistoryEntry,
    EditorEngineHistoryEntryCollection,
} from '@/app/editor/engine/core/types';
import type { Dispatch, SetStateAction } from 'react';

/**
 * Append an entry to the history.
 */
export const appendEntry = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    entry,
    entriesInfo,
    setEntries,
}: {
    /**
     * The entry to append.
     */
    entry: EditorEngineHistoryEntry<TEditorEngineTypeInput>;
    /**
     * Information about the entries.
     */
    entriesInfo: EditorEngineHistoryEntryCollection<TEditorEngineTypeInput>;
    /**
     * Function to set the entries.
     */
    setEntries: Dispatch<
        SetStateAction<
            Omit<EditorEngineHistoryEntryCollection<TEditorEngineTypeInput>, 'currentIndex'>
        >
    >;
}) => {
    setEntries((oldEntriesInfo) => {
        if (entriesInfo.currentIndex !== oldEntriesInfo.entries.length - 1) {
            // If the current entry is not the latest entry, do not do anything.
            // This function should not be responsible for removing entries
            // after the current entry.

            return oldEntriesInfo;
        }

        return {
            ...oldEntriesInfo,
            entries: [...oldEntriesInfo.entries, entry],
        };
    });

    return entry;
};
