import { QUERY_KEYS } from '@/core/contentful/constants';

import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import client from '@/core/contentful/client';

import type { IHelpTooltipFields } from 'types/contentful';
import type { Language } from 'types/generic';

export const useEntry = (entryId: string) => {
    const { locale } = useRouter();
    const localeKey = (locale as Language) || 'en';

    return useQuery({
        queryKey: QUERY_KEYS.entry(entryId, localeKey),
        queryFn: async () => {
            return await client.getEntry<IHelpTooltipFields>(entryId, {
                locale: localeKey,
            });
        },
    });
};
