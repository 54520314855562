import { NAME } from '@/app/editor/editor/constants';

import { useTranslation } from 'next-i18next';

import { Align } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Align';

import type { AlignFormProps } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Align';

const TextAlign = (props: AlignFormProps) => {
    const { t } = useTranslation(NAME);

    return <Align {...props} tooltip={t('text-align')} />;
};

export default TextAlign;
