import { rectSortingStrategy } from '@dnd-kit/sortable';

import Box from '@/app/editor/blocks/components/_wrapper/Box';
import { getBgImageStyles } from '@/app/editor/blocks/helpers/getBgImageStyles';
import { useArtboardSize } from '@/app/editor/editor/hooks/useArtboardSize';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { PerspectiveEditorEnginePreviewAwareSequenceRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareSequenceRenderer';
import { useBlockOrPreview } from '@/app/editor/engine/hooks/useBlockOrPreview';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {}

const propsFromParent = {
    blockWrapperClass: 'h-full',
    wrapperClass: 'h-full',
    blockContainerClass: 'h-full',
};

const Component: FC<Props> = ({ blockId, box, isDragged, artBoardIndex, activeTheme }) => {
    const artboardSize = useArtboardSize();
    const block = useBlockOrPreview(blockId);

    const columnCount = block.relationships.components.data.length;

    const wrapperClass = cn('transition-all duration-200', {
        'px-4': artboardSize === ArtBoardSize.MOBILE,
        'px-6': artboardSize === ArtBoardSize.TABLET,
        'px-12': artboardSize === ArtBoardSize.DESKTOP,
    });

    const gridClass = cn('grid gap-4', {
        'grid-cols-1': columnCount === 1 || artboardSize === ArtBoardSize.MOBILE,
        'grid-cols-2': columnCount >= 2 && artboardSize !== ArtBoardSize.MOBILE,
        'grid-cols-3': columnCount === 3 && artboardSize === ArtBoardSize.DESKTOP,
        'grid-cols-4': columnCount >= 4 && artboardSize === ArtBoardSize.DESKTOP,
    });

    const { backgroundColor, bgImage } = box || {};
    const { visible, src } = bgImage || {};

    return (
        <div
            className="relative"
            style={{
                background: getThemedOrCustomColor(backgroundColor, 'backgroundColor', activeTheme),
            }}
        >
            {/* Background Image */}
            {visible && src && (
                <div
                    className="pointer-events-none absolute inset-0 bg-cover bg-no-repeat"
                    style={getBgImageStyles(bgImage)}
                />
            )}

            {/* Content */}
            <div className={wrapperClass}>
                <Box
                    box={box}
                    isDragged={isDragged}
                    artBoardIndex={artBoardIndex}
                    limiterClass="max-w-screen-2xl"
                    noBackground
                >
                    <PerspectiveEditorEnginePreviewAwareSequenceRenderer
                        parentId={blockId}
                        className={gridClass}
                        propsFromParent={propsFromParent}
                        sortingStrategy={rectSortingStrategy}
                    />
                </Box>
            </div>
        </div>
    );
};

Component.displayName = 'GridRow';

export default Component;
