import { createSlice } from '@reduxjs/toolkit';

import { FunnelSetting } from '@/app/funnelSettings/funnelSettings/types';

import { NAME } from '../constants';

import type { ModalView } from '@/app/funnelSettings/funnelSettings/types';
import type { AppState } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    activeSetting: FunnelSetting;
    activeView: ModalView;
}

const initialState: State = {
    activeSetting: FunnelSetting.Sharing,
    activeView: 'overview',
};

export const modalSlice = createSlice({
    name: `funnelSettings/${NAME}/modal`,
    initialState,
    reducers: {
        setActiveView(state, action: PayloadAction<ModalView>) {
            return {
                ...state,
                activeView: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setActiveView, reset } = modalSlice.actions;

// === Selectors ======

export const getActiveView = (state: AppState) => state[NAME]?.modalReducer?.activeView;

export default modalSlice.reducer;
