import Image from 'next/image';
import { useRef } from 'react';
import ContentEditable from 'react-contenteditable';

import Box from '@/app/editor/blocks/components/_wrapper/Box';
import { useContentEditablePlaceholder } from '@/app/editor/blocks/hooks/useContentEditablePlaceholder';
import { getWebinarPreviewByBlockId } from '@/app/editor/blocks/models/webinar';
import { getThemedOrCustomColor, getColorByLightness } from '@/app/editor/themes/helpers';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { useAppSelector } from '@/core/redux/hooks';
import { useVideoDetails } from '@/hooks/useVideoDetails';
import PlayButton from '@/ui/components/PlayButton';
import { cn } from '@/utils/cn';

import type { DefaultBlockComponentProps } from '@/app/editor/blocks/types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    video: string;
    text: string;
    placeholderText: string;
    backgroundColor: string;
    color?: string;
}

const Component: FC<Props> = ({
    artBoardIndex,
    box,
    text,
    placeholderText,
    backgroundColor,
    color,
    video,
    activeTheme,
    blockId,
    isDragged,
}) => {
    const btnPlaceholderRef = useRef(null);
    const previewPlaceholderRef = useRef(null);
    const videoBorderRadius = useBorderRadius('topWrapper');
    const buttonWrapperBorderRadius = useBorderRadius('bottomWrapper');
    const buttonBorderRadius = useBorderRadius('webinarButton');
    const videoDetails = useVideoDetails(video);
    const buttonBgColor = getThemedOrCustomColor(
        backgroundColor,
        'buttonBackgroundColor',
        activeTheme,
    );
    const placeholderVisible = useAppSelector((state) =>
        getWebinarPreviewByBlockId(state, blockId),
    );

    const {
        updatedPlaceholder: btnPlaceholder,
        handlePlaceholderChange: handleBtnPlaceholderChange,
        handlePlaceholderBlur: handleBtnPlaceholderBlur,
        handleKeyDown: handleBtnKeyDown,
    } = useContentEditablePlaceholder({
        ref: btnPlaceholderRef,
        blockId,
        initialPlaceholder: text,
        dataPath: 'attributes.content.text',
    });

    const { updatedPlaceholder, handlePlaceholderChange, handlePlaceholderBlur, handleKeyDown } =
        useContentEditablePlaceholder({
            ref: previewPlaceholderRef,
            blockId,
            initialPlaceholder: placeholderText,
            dataPath: 'attributes.content.placeholderText',
        });

    return (
        <Box box={box} isDragged={isDragged} artBoardIndex={artBoardIndex}>
            <div
                className={cn(
                    'relative flex w-full items-center justify-center overflow-hidden rounded-b-none bg-black aspect-video',
                    videoBorderRadius,
                )}
            >
                {videoDetails.thumbnail && (
                    <Image
                        src={videoDetails.thumbnail}
                        alt={`${videoDetails.title} thumbnail`}
                        sizes="50vw"
                        fill
                        priority
                    />
                )}
                <div
                    className="absolute bottom-0 h-16 w-full"
                    style={{ background: 'linear-gradient(0deg, black 0%, transparent 100%)' }}
                />
                <PlayButton />
            </div>
            <div
                className={cn(
                    'relative h-[72px] overflow-hidden rounded-t-none bg-black p-2',
                    buttonWrapperBorderRadius,
                )}
            >
                <div
                    className={cn(
                        'relative transition-all',
                        placeholderVisible ? 'top-0' : '-top-[64px]',
                    )}
                >
                    {/* Placeholder Text */}
                    <div className="px-6 py-4 text-center text-gray-400">
                        <ContentEditable
                            innerRef={previewPlaceholderRef}
                            html={updatedPlaceholder}
                            onChange={handlePlaceholderChange}
                            onBlur={handlePlaceholderBlur}
                            className="ring-none cursor-text outline-none"
                            onKeyDown={handleKeyDown}
                        />
                    </div>

                    {/* Button */}
                    <div
                        className={cn(
                            'mt-2 w-full truncate px-6 py-4 text-center font-semibold',
                            buttonBorderRadius,
                        )}
                        style={{
                            background: buttonBgColor,
                            color: getColorByLightness(color, buttonBgColor),
                        }}
                    >
                        <ContentEditable
                            innerRef={btnPlaceholderRef}
                            html={btnPlaceholder}
                            onChange={handleBtnPlaceholderChange}
                            onBlur={handleBtnPlaceholderBlur}
                            className="ring-none cursor-text outline-none"
                            onKeyDown={handleBtnKeyDown}
                        />
                    </div>
                </div>
            </div>
        </Box>
    );
};

Component.displayName = 'Webinar';

export default Component;
