import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { getActiveIndexBySliderId } from '@/app/editor/blocks/models/slider';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';

import type { DefaultBlockComponentProps, Size } from '../../types';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    images: string[];
    framed: boolean;
    size: Size;
    backgroundColor?: string;
}

const Component: FC<Props> = ({ artBoardIndex, blockId, box, size, images, framed, isPreview }) => {
    const { isInColumn, isDesktop, isTablet } = useLayoutInfo(blockId, isPreview);
    const activeImageIndex = useAppSelector((state) => getActiveIndexBySliderId(state, blockId));
    const sliderImageBorderRadius = useBorderRadius('sliderImage');

    const tabletWrapperClasses = cn({
        'px-14 py-6': isTablet && framed,
    });

    const wrapperClasses = cn('relative select-none overflow-hidden', {
        [sliderImageBorderRadius]: framed || isInColumn || (!isInColumn && isDesktop),
    });

    return (
        <Box box={getBoxAttributes({ box, isInColumn })} artBoardIndex={artBoardIndex}>
            <div className={tabletWrapperClasses}>
                <div className={wrapperClasses}>
                    <div className="absolute bottom-3 left-0 right-0 z-20 flex w-full justify-between space-x-1 px-3">
                        {images.length > 1 &&
                            images.map((imageUrl, index) => {
                                const pillWrapperClasses = cn(
                                    'h-1 w-full flex-grow overflow-hidden rounded-sm',
                                    {
                                        'bg-white': index <= activeImageIndex,
                                        'bg-gray-400': index > activeImageIndex,
                                    },
                                );

                                return (
                                    <div
                                        key={`artboard-pill-${imageUrl}-${index}`}
                                        className={pillWrapperClasses}
                                    >
                                        <div className="h-full w-0 bg-white" />
                                    </div>
                                );
                            })}
                    </div>
                    <div className="absolute inset-x-0 bottom-0 z-10 h-16 bg-gradient-to-t from-black/40 to-black/0" />
                    <div className="flex">
                        <div
                            className={cn(
                                'relative w-full flex-shrink-0 bg-cover bg-center transition-all duration-200 ease-out',
                                {
                                    'aspect-16/9': size === 'small',
                                    'aspect-4/3': size === 'medium',
                                    'aspect-5/4': size === 'large',
                                    'aspect-square': size === 'xLarge',
                                },
                            )}
                            style={{
                                backgroundImage: `url('${images[activeImageIndex]}')`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </Box>
    );
};

Component.displayName = 'ImageSlider';

export default Component;
