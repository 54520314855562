import { createSlice } from '@reduxjs/toolkit';

import { hideModal } from '@/app/modals/models/modals';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiDelete, handleRuntimeError } from '@/core/api';

import { fetchChartPages } from './dropOffChart';
import { fetchCampaignKpis } from './kpis';
import { NAME } from '../constants';

import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
}

const initialState: State = { loading: false };

export const resetAnalyticsSlice = createSlice({
    name: `${NAME}/resetAnalytics`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, reset } = resetAnalyticsSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.resetAnalyticsReducer?.loading;

// === Thunks ======

export const dataResetResponses = (campaignId: string) => {
    return async () => {
        await apiDelete(`/analytics/responses?campaign=${campaignId}`);
    };
};

export const dataResetKpis = (campaignId: string) => {
    return async () => {
        await apiDelete(`/analytics/kpis?campaign=${campaignId}`);
    };
};

export const dataResetPageCounts = (campaignId: string) => {
    return async () => {
        await apiDelete(`/analytics/pages?campaign=${campaignId}`);
    };
};

export const resetAnalytics = (campaignId: string, successMessage: string): AppThunk => {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const deletePromises = [
                dispatch(dataResetResponses(campaignId)),
                dispatch(dataResetPageCounts(campaignId)),
                dispatch(dataResetKpis(campaignId)),
            ];
            await Promise.all(deletePromises);

            // Refresh data
            const refreshPromises = [
                dispatch(fetchCampaignKpis(campaignId)),
                dispatch(fetchChartPages(campaignId)),
            ];
            await Promise.all(refreshPromises);

            showToast({
                message: successMessage,
                type: 'success',
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'Resetting analytics failed:' });
        } finally {
            dispatch(hideModal());
            dispatch(setLoading(false));
        }
    };
};

export default resetAnalyticsSlice.reducer;
