import * as Select from '@radix-ui/react-select';
import find from 'lodash/find';

import { getCleanIconId } from '@/app/editor/blocks/helpers';
import { SelectContent } from '@/ui/components/_BlockEditFields/_wrappers/SelectContent';
import { SelectTrigger } from '@/ui/components/_BlockEditFields/_wrappers/SelectTrigger';

import type { DropdownOption } from '@/ui/types';

export interface Props {
    options: DropdownOption[];
    value: string;
    onChange: (value: string) => void;
    dropdownClass?: string;
    buttonClass?: string;
}

export const IconDropdown = ({ options, value, onChange, dropdownClass }: Props) => {
    const selectedOption = find(options, { value });

    return (
        <Select.Root onValueChange={onChange} value={value}>
            <SelectTrigger selectedOption={selectedOption}>
                {selectedOption?.icon && (
                    <div className="mr-3 size-5">
                        {/* @ts-ignore */}
                        <em-emoji
                            id={getCleanIconId(
                                (selectedOption?.customSelectIcon ||
                                    selectedOption?.icon) as string,
                            )}
                            size="20px"
                        />
                    </div>
                )}
            </SelectTrigger>

            <SelectContent dropdownClass={dropdownClass}>
                {options.map((option, index) => (
                    <Select.Item key={`${option.key}-${index}`} value={option.value} asChild>
                        <button className="flex w-full items-center rounded p-2 text-left text-sm text-gray-500 outline-none hover:bg-gray-100 hover:text-gray-800 data-[state=checked]:text-blue-500">
                            {option.icon && (
                                <div className="size-5">
                                    {/* @ts-ignore */}
                                    <em-emoji
                                        id={getCleanIconId(
                                            (option.customSelectIcon || option.icon) as string,
                                        )}
                                        size="20px"
                                    />
                                </div>
                            )}
                            <span className="ml-3 block flex-1 truncate">{option.key}</span>
                        </button>
                    </Select.Item>
                ))}
            </SelectContent>
        </Select.Root>
    );
};
