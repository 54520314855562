import { combineReducers } from 'redux';

import accountSetupReducer from './accountSetup';
import funnelSelectionReducer from './funnelSelection';
import setupReducer from './setup';
import usersReducer from './users';
import workspaceLoginReducer from './workspaceLogin';
import workspaceReducer from './workspaces';

export default combineReducers({
    workspaceReducer,
    workspaceLoginReducer,
    accountSetupReducer,
    setupReducer,
    funnelSelectionReducer,
    usersReducer,
});
