import { createSelector, createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { getCampaignWithPagination } from '@/app/campaigns/helpers';
import {
    dataFetchCampaigns,
    getCampaignRequest,
    getCampaignsAsArray,
} from '@/app/campaigns/models/campaigns';
import { CampaignFilter } from '@/app/campaigns/types';
import { fetchCampaigns as fetchWorkspaceSelectionCampaigns } from '@/app/workspaces/models/funnelSelection';
import { handleRuntimeError } from '@/core/api';
import { getDataFromResponse, resourceArrayToObject } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import {
    getActiveWorkspace,
    getActiveWorkspaceId,
    getDefaultWorkspace,
    getWorkspaces,
    setWorkspace,
} from './workspaces';
import { NAME } from '../constants';
import { addFunnelsToWorkspaceRequest } from '../helpers/apis';
import { RequestState } from '../types';

import type { CampaignResource, CampaignsWithPagination } from '@/app/campaigns/types';
import type { Pagination } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

interface State {
    availableCampaigns: CampaignResource[];
    selectedCampaigns: CampaignResource[];
    pagination: Partial<Pagination>;
    selectedPagination: Partial<Pagination>;
    searchTerm?: string;
    movingCampaignIds?: Record<string, boolean>;
    requestState: Partial<Record<'funnels' | 'searching' | 'moveCampaigns', RequestState>>;
}

const initialState: State = {
    pagination: EMPTY_OBJECT,
    selectedPagination: EMPTY_OBJECT,
    searchTerm: undefined,
    availableCampaigns: EMPTY_ARRAY,
    selectedCampaigns: EMPTY_ARRAY,
    movingCampaignIds: EMPTY_OBJECT,
    requestState: {
        funnels: RequestState.Idle,
        searching: RequestState.Idle,
        moveCampaigns: RequestState.Idle,
    },
};

export const funnelSelectionSlice = createSlice({
    name: `${NAME}/funnelSelection`,
    initialState,
    reducers: {
        setAvailableCampaigns(state, action: PayloadAction<CampaignResource[]>) {
            state.availableCampaigns = action.payload;
        },
        setSelectedCampaigns(state, action: PayloadAction<CampaignResource[]>) {
            state.selectedCampaigns = action.payload;
        },
        appendAvailableCampaigns(state, action: PayloadAction<CampaignResource[]>) {
            state.availableCampaigns.push(...action.payload);
        },
        appendSelectedCampaigns(state, action: PayloadAction<CampaignResource[]>) {
            state.selectedCampaigns.push(...action.payload);
        },
        setSearchTerm(state, action: PayloadAction<string>) {
            state.searchTerm = action.payload;
        },
        setPagination(state, action: PayloadAction<Pagination>) {
            state.pagination = action.payload;
        },
        setSelectedPagination(state, action: PayloadAction<Pagination>) {
            state.selectedPagination = action.payload;
        },
        setMovingCampaignIds(state, action: PayloadAction<string[]>) {
            // Fill in funnel Ids object with truthy values
            const mappedObject = {};
            action.payload?.forEach((id) => {
                mappedObject[id] = true;
            });

            state.movingCampaignIds = { ...state.movingCampaignIds, ...mappedObject };
        },
        setFunnelsMoved(state, action: PayloadAction<string[]>) {
            state.movingCampaignIds = omit(state.movingCampaignIds, action.payload);
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setAvailableCampaigns,
    setSelectedCampaigns,
    appendAvailableCampaigns,
    appendSelectedCampaigns,
    setSearchTerm,
    setPagination,
    setSelectedPagination,
    setMovingCampaignIds,
    setFunnelsMoved,
    setRequestState,
    reset,
} = funnelSelectionSlice.actions;

// === Selectors ======

export const getAvailableCampaigns = (state: AppState) =>
    state[NAME].funnelSelectionReducer?.availableCampaigns;

export const getSelectedCampaigns = (state: AppState) =>
    state[NAME].funnelSelectionReducer?.selectedCampaigns;

export const getPagination = (state: AppState) => state[NAME].funnelSelectionReducer?.pagination;

export const getSelectedPagination = (state: AppState) =>
    state[NAME].funnelSelectionReducer?.selectedPagination;

export const getPageCount = (state: AppState) =>
    state[NAME].funnelSelectionReducer?.pagination?.pageCount ?? 0;

export const getSearchTerm = (state: AppState) => state[NAME].funnelSelectionReducer?.searchTerm;

export const getFetching = (state: AppState) =>
    state[NAME].funnelSelectionReducer?.requestState.funnels === RequestState.InProgress;

export const getSearching = (state: AppState) =>
    state[NAME].funnelSelectionReducer?.requestState.searching === RequestState.InProgress;

export const getCampaignIds = createSelector(getAvailableCampaigns, (funnels = []) => {
    return funnels.map((funnel) => funnel.id);
});

export const getMovingCampaignIds = (state: AppState) =>
    state[NAME].funnelSelectionReducer?.movingCampaignIds;

export const getCampaignsAsObject = createSelector(getAvailableCampaigns, (funnels = []) => {
    return resourceArrayToObject(funnels);
});

export const getSelectedCampaignIds = (state: AppState) => {
    const funnels = getCampaignsAsArray(state);

    if (!funnels) {
        return EMPTY_ARRAY;
    }

    return funnels?.map((funnel) => funnel.id);
};

// === Thunk ======

const fetchFunnelData =
    ({
        workspaceIds,
        searchQuery,
        filter,
        nextPageUrl,
    }: {
        workspaceIds: string[];
        searchQuery?: string;
        filter?: CampaignFilter;
        nextPageUrl?: string;
    }): AppThunk<Promise<CampaignsWithPagination>> =>
    async (dispatch) => {
        const response = await dispatch(
            getCampaignRequest({
                workspaceIds: workspaceIds.join(','),
                queryOptions: {
                    search: searchQuery,
                    filter,
                },
                nextPageUrl: nextPageUrl,
            }),
        );

        if (!response) {
            return;
        }

        return getCampaignWithPagination(response);
    };

const fetchAndSetSelectedFunnels =
    ({ workspaceIds }: { workspaceIds: string[] }): AppThunk =>
    async (dispatch) => {
        const { pagination, campaigns } = await dispatch(fetchFunnelData({ workspaceIds }));
        dispatch(setSelectedPagination(pagination));
        dispatch(setSelectedCampaigns(campaigns));
    };

const fetchAndSetAvailableFunnels =
    ({
        workspaceIds,
        searchQuery,
        filter,
    }: {
        workspaceIds: string[];
        searchQuery?: string;
        filter?: CampaignFilter;
    }): AppThunk =>
    async (dispatch) => {
        const { pagination, campaigns } = await dispatch(
            fetchFunnelData({ workspaceIds, searchQuery, filter }),
        );
        dispatch(setPagination(pagination));
        dispatch(setAvailableCampaigns(campaigns));
    };

const loadAndSetNextCampaigns =
    (workspaceIds: string[], nextUrl?: string, selectedOnly?: boolean): AppThunk =>
    async (dispatch) => {
        // Fetch selected Workspace funnels
        const { pagination, campaigns } = await dispatch(
            fetchFunnelData({ workspaceIds, nextPageUrl: nextUrl }),
        );

        const setPaginationAction = selectedOnly ? setSelectedPagination : setPagination;
        const setFunnelAction = selectedOnly ? appendSelectedCampaigns : appendAvailableCampaigns;

        if (pagination) {
            dispatch(setPaginationAction(pagination));
        }

        if (campaigns) {
            dispatch(setFunnelAction(campaigns));
        }
    };

export const fetchCampaigns = (): AppThunk => async (dispatch, getState) => {
    dispatch(setRequestState({ funnels: RequestState.InProgress }));

    const state = getState();
    const workspaces = getWorkspaces(state);
    const activeWorkspaceId = getActiveWorkspaceId(state);

    try {
        // Fetches all funnels for the active workspace (selected funnels)
        await dispatch(fetchAndSetSelectedFunnels({ workspaceIds: [activeWorkspaceId] }));

        // Returns all workspaces except the active one
        const workspaceIdsToSearch = Object.keys(workspaces)?.filter(
            (workspaceId) => workspaceId !== activeWorkspaceId,
        );

        // Fetches all funnels for all workspaces except the active one (available funnels)
        await dispatch(fetchAndSetAvailableFunnels({ workspaceIds: workspaceIdsToSearch }));
        dispatch(setRequestState({ funnels: RequestState.Done }));
    } catch (err) {
        dispatch(setRequestState({ funnels: RequestState.Error }));
    }
};

export const loadNextFunnelPage = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const nextPageUrl = getPagination(state)?.next;

    if (!nextPageUrl) {
        return;
    }

    dispatch(setRequestState({ funnels: RequestState.InProgress }));

    const workspaces = getWorkspaces(state);
    const activeWorkspaceId = getActiveWorkspaceId(state);

    const workspaceIdsToSearch = Object.keys(workspaces)?.filter(
        (workspaceId) => workspaceId !== activeWorkspaceId,
    );

    try {
        await dispatch(loadAndSetNextCampaigns(workspaceIdsToSearch, nextPageUrl));

        dispatch(setRequestState({ funnels: RequestState.Done }));
    } catch (err) {
        dispatch(setRequestState({ funnels: RequestState.Error }));
    }
};

export const loadNextSelectedCampaignsPage = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const activeWorkspaceId = getActiveWorkspaceId(state);
    const nextSelectedPageUrl = getSelectedPagination(state)?.next;

    if (!nextSelectedPageUrl) {
        return;
    }

    dispatch(setRequestState({ funnels: RequestState.InProgress }));

    try {
        await dispatch(loadAndSetNextCampaigns([activeWorkspaceId], nextSelectedPageUrl, true));

        dispatch(setRequestState({ funnels: RequestState.Done }));
    } catch (err) {
        dispatch(setRequestState({ funnels: RequestState.Error }));
    }
};

export const searchCampaigns =
    (query: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setRequestState({ searching: RequestState.InProgress }));

        const state = getState();
        const workspaces = getWorkspaces(state);
        const activeWorkspaceId = getActiveWorkspaceId(state);

        const workspaceIdsToSearch = Object.keys(workspaces)?.filter(
            (workspaceId) => workspaceId !== activeWorkspaceId,
        );

        try {
            dispatch(
                fetchAndSetAvailableFunnels({
                    workspaceIds: workspaceIdsToSearch,
                    searchQuery: query,
                    filter: CampaignFilter.search,
                }),
            );
            dispatch(setRequestState({ searching: RequestState.Done }));
        } catch (err) {
            dispatch(setRequestState({ searching: RequestState.Error }));
        }
    };

export const moveCampaigns =
    (workspaceId: string, values: string[]): AppThunk =>
    async (dispatch) => {
        if (workspaceId && values.length) {
            dispatch(
                setRequestState({
                    moveCampaigns: RequestState.InProgress,
                }),
            );

            try {
                const data = getDataFromResponse(
                    await addFunnelsToWorkspaceRequest(workspaceId, values),
                );

                dispatch(setWorkspace(data));

                dispatch(
                    setRequestState({
                        moveCampaigns: RequestState.Success,
                    }),
                );
            } catch (err) {
                handleRuntimeError(err as AxiosError<{ message: string }>, {
                    debugMessage: 'error validating domain',
                });

                dispatch(
                    setRequestState({
                        moveCampaigns: RequestState.Error,
                    }),
                );
            }
        }
    };

export const moveCampaignToDefaultWorkspace =
    (campaignId: string, shouldUpdateWorkspaceCampaigns?: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            const workspaceId = getDefaultWorkspace(getState())?.id;

            dispatch(setMovingCampaignIds([campaignId]));

            await dispatch(moveCampaigns(workspaceId, [campaignId]));

            if (shouldUpdateWorkspaceCampaigns) {
                await dispatch(fetchWorkspaceSelectionCampaigns());
            }

            dispatch(setFunnelsMoved([campaignId]));
        } catch (err) {
            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'error moving campaign to default workspace',
            });
        }
    };

type MoveCampaignParams = {
    campaignId: string;
    workspaceId?: string;
    shouldUpdateWorkspaceCampaigns?: boolean;
};

export const moveCampaign =
    ({
        campaignId,
        workspaceId,
        shouldUpdateWorkspaceCampaigns = false,
    }: MoveCampaignParams): AppThunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        try {
            const id = workspaceId ?? getActiveWorkspace(getState()).id;

            dispatch(setMovingCampaignIds([campaignId]));

            await dispatch(moveCampaigns(id, [campaignId]));

            if (shouldUpdateWorkspaceCampaigns) {
                // Fetch available and selected campaigns used in Search
                await dispatch(fetchCampaigns());

                // Re-fetch funnels in Funnels page
                await dispatch(dataFetchCampaigns());
            }

            dispatch(setFunnelsMoved([campaignId]));

            return true;
        } catch (err) {
            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'error moving campaign',
            });

            return false;
        }
    };

export default funnelSelectionSlice.reducer;
