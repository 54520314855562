import {
    COLORED_PLATFORMS,
    LAST_SELECTED_PLATFORM_LS_KEY,
} from '@/app/editor/iconLibrary/constants';

import { getIsLocalhostEnv } from '@/utils/environments';
import { reportMessage } from '@/utils/sentry';

import type { Icon, IconResource } from '@/app/editor/iconLibrary/types';

const checks = {};

export const checkImageExists = (src: string, callback: (exists: boolean) => void) => {
    if (src in checks) {
        return callback(checks[src]);
    }

    const img = new Image();

    img.onload = () => {
        checks[src] = true;
        callback(true);
    };

    img.onerror = () => {
        if (getIsLocalhostEnv()) {
            console.log('DEV: image/icon could not be loaded:', src);
        }
        checks[src] = false;
        callback(false);
    };

    img.src = src;
};

export const generateIconSrc = ({
    name,
    platformId,
    color,
    size,
}: {
    name: string;
    platformId: string;
    color: string;
    size: number;
}) => {
    const sanitizedColor = color.replace('#', '');
    const isColor = COLORED_PLATFORMS.includes(platformId);

    return `https://img.icons8.com/${
        color && !isColor ? `${sanitizedColor}/` : ''
    }${platformId}/${size}/${name}`;
};

export const generateIconObject = (icon: IconResource): Icon => {
    const src = generateIconSrc({
        name: icon.attributes.commonName,
        platformId: icon.attributes.platform,
        color: '#202020',
        size: 120,
    });

    return {
        id: icon.id,
        name: icon.attributes.commonName,
        platform: icon.attributes.platform,
        src,
    };
};

export const getLastSelectedPlatformFromLocalStorage = (): null | {
    id: string;
    isColor: boolean;
} => {
    const rawValue = localStorage.getItem(LAST_SELECTED_PLATFORM_LS_KEY);

    if (rawValue) {
        try {
            const objectValue = JSON.parse(rawValue);

            if (objectValue.id !== undefined && objectValue.isColor !== undefined) {
                return objectValue;
            }
        } catch (err) {
            reportMessage({
                message: `failed parsing LS value for key ${LAST_SELECTED_PLATFORM_LS_KEY}: ${err.message}`,
                source: 'local-storage',
            });
        }
    }

    return null;
};

export const setLastSelectedPlatformToLocalStorage = (platform: {
    id: string;
    isColor: boolean;
}) => {
    localStorage.setItem(LAST_SELECTED_PLATFORM_LS_KEY, JSON.stringify(platform));
};
