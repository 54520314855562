import { insertNodeStep } from '@/app/editor/engine/core/utils/transaction/steps/insertNodeStep';
import { PerspectiveEditorEngineActionName } from '@/app/editor/engine/types';
import { duplicateBlockStructure } from '@/app/editor/engine/utils/node/duplicateBlockStructure';
import { randomId } from '@/app/editor/engine/utils/randomId';
import { apiDelete } from '@/core/api';

import type { BlockResource } from '@/app/editor/blocks/types';
import type {
    EditorEngineActionCreatorPayload,
    EditorEngineParentId,
} from '@/app/editor/engine/core/types';
import type { InsertNodeStepInput } from '@/app/editor/engine/core/utils/transaction/steps/insertNodeStep';
import type {
    PerspectiveEditorEngineActionCreator,
    PerspectiveEditorEngineDuplicationMethod,
    PerspectiveEditorEngineDuplicationUseCase,
    PerspectiveEditorEngineNodeData,
} from '@/app/editor/engine/types';

interface Payload extends EditorEngineActionCreatorPayload {
    /**
     * The ID of the block to duplicate.
     *
     * If the ID is not provided, the block from the clipboard will be used.
     *
     * If neither is provided, the action will be skipped in the
     * `shouldBeSkipped` method.
     */
    id?: string;
    /**
     * The position of the new block.
     *
     * If not provided, the new block will be inserted after the original block.
     */
    position?: {
        /**
         * The ID of the new parent block.
         */
        newParentId: EditorEngineParentId;
        /**
         * The index of the new block.
         */
        newIndex: number;
    };
    /**
     * @inheritDoc
     */
    invocationContext: {
        /**
         * The method used to duplicate the block.
         */
        duplicationMethod: PerspectiveEditorEngineDuplicationMethod;
        duplicationUseCase: PerspectiveEditorEngineDuplicationUseCase;
    };
}

/**
 * Action that will duplicate a block.
 */
export const duplicateBlockAction = (({
    args: { id: incomingId, position },
    documentManager,
    nodeManager,
    extraContext: { backendEntities, clientSideComponents },
}) => {
    const clipboardBlockId = documentManager.clipboardBlockId;
    const resolvedId = incomingId ?? clipboardBlockId;
    const block = nodeManager.getNode(resolvedId)?.block;
    const rootParentId = position?.newParentId ?? block?.relationships?.parent?.data?.id;
    const rootIndex = position?.newIndex ?? nodeManager.getNodeIndex(resolvedId) + 1;

    const getTransactionRecursively = (
        block: BlockResource,
        currentParent: string,
        currentIndex: number,
        steps: InsertNodeStepInput<PerspectiveEditorEngineNodeData>[],
    ) => {
        const id = randomId();

        steps.push({
            node: {
                block: duplicateBlockStructure({
                    block,
                    id,
                    pageId: documentManager.activePage.id,
                    funnelId: documentManager.activeFunnel.id,
                }),
            },
            parentId: currentParent ?? 'root',
            index: currentIndex,
        });

        const children = block.relationships?.components?.data || [];

        children.forEach((child, index) => {
            getTransactionRecursively(nodeManager.getNode(child.id).block, id, index, steps);
        });

        return steps;
    };

    const payloads = getTransactionRecursively(block, rootParentId, rootIndex, []);
    const virtualBlocks = payloads.map((payload) => payload.node.block);

    return {
        name: PerspectiveEditorEngineActionName.DuplicateBlock,
        debug: {},
        shouldBeSkipped() {
            return !block;
        },
        getTransaction() {
            return payloads.map(insertNodeStep);
        },
        onAfterOptimisticForward() {
            documentManager.setActiveBlock(virtualBlocks[0].id);

            clientSideComponents.scrollToComponent.focusOn(virtualBlocks[0]);
        },
        onAfterOptimisticBackward() {
            clientSideComponents.scrollToComponent.focusOn(block);
        },
        async execute(isRedo) {
            await backendEntities.duplicateBlock({
                blockId: nodeManager.resolveVirtualId(resolvedId),
                mapVirtualIdToConcreteId: nodeManager.mapVirtualIdToConcreteId,
                virtualBlocks,
                duplicateLocation: position
                    ? {
                          index: position.newIndex,
                      }
                    : undefined,
                isRedo,
                updateBlockAttributes: nodeManager.updateBlockAttributes,
                updateTrackingId: nodeManager.updateTrackingId,
            });

            return {
                success: true,
            };
        },
        async undo() {
            await apiDelete(`/components/${nodeManager.resolveVirtualId(virtualBlocks[0].id)}`);

            return {
                success: true,
            };
        },
    };
}) satisfies PerspectiveEditorEngineActionCreator<Payload, {}, {}>;
