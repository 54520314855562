import { NAME } from '@/app/editor/blocks/constants';

import findIndex from 'lodash/findIndex';
import { useTranslation } from 'next-i18next';

import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import RadioSlider from '@/ui/components/RadioSlider';

import { CenterIcon } from './CenterIcon';
import { TopIcon } from './TopIcon';

import type { RadioSliderSelection } from '@/ui/types';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input?: WrappedFieldInputProps;
    value?: string;
    className?: string;
    onSizeChange?: (size: RadioSliderSelection) => void;
    submit?: () => void;
    valueMap?: {
        start: {
            value: string;
            tooltip?: string;
            render: () => JSX.Element;
        };
        center: {
            value: string;
            tooltip?: string;
            render: () => JSX.Element;
        };
        end: {
            value: string;
            tooltip?: string;
            render: () => JSX.Element;
        };
    };
}

const FlexAlign = ({ input, value, submit, valueMap, className }: Props) => {
    const { t } = useTranslation(NAME);

    const options = Object.keys(valueMap).map((align) => {
        return {
            name: align,
            ...valueMap[align],
            tooltip: t(valueMap[align].tooltip),
        };
    });

    const index = findIndex(options, { value: input?.value || value });
    const selectedSizeIndex = index === -1 ? 0 : index;

    const handleChange = (selected: RadioSliderSelection) => {
        const { onChange } = input;

        onChange(selected.value);

        if (submit) {
            setTimeout(submit);
        }
    };

    return (
        <FieldContainer className={className}>
            <RadioSlider items={options} activeIndex={selectedSizeIndex} onChange={handleChange} />
        </FieldContainer>
    );
};

FlexAlign.defaultProps = {
    valueMap: {
        start: {
            value: 'start',
            tooltip: 'vertical-align-top',
            render: () => <TopIcon />,
        },
        center: {
            value: 'center',
            tooltip: 'vertical-align-center',
            render: () => <CenterIcon />,
        },
        end: {
            value: 'end',
            tooltip: 'vertical-align-bottom',
            render: () => <TopIcon className="rotate-180" />,
        },
    },
};

export default FlexAlign;
