import { cloneElement } from 'react';
import { FormName } from 'redux-form';

import {
    getColorPickerHistoryForForm,
    saveColorPickerHistoryForForm,
} from '@/app/editor/editor/helper';

import type { ColorPickerHistory } from '@/ui/components/_BlockEditFields/ColorPicker';
import type { ReactElement } from 'react';

interface HelperProps {
    formName: string;
    children: ReactElement;
}

/**
 * We need this helper component to make use of the formName, that the
 * EnableColorPickerHistory component "fetches" via <FormName>.
 *
 * In this helper component we use the FormName to (a) grab the current value
 * from the LocalStorage and (b) to save changes to the LocalStorage    .
 *
 * This helper component in the end populates the colorPickerHistory value and the change
 * callback to the child Field component.
 *
 * @param formName Name of the form.
 * @param children Field element.
 */
const Helper = ({ formName, children }: HelperProps) => {
    const colorPickerHistory = getColorPickerHistoryForForm(formName);

    const onColorPickerHistoryChange = (newHistory: ColorPickerHistory) => {
        saveColorPickerHistoryForForm(formName, newHistory);
    };

    return cloneElement(children, {
        ...children.props,
        ...{ colorPickerHistory, onColorPickerHistoryChange },
    });
};

export interface Props {
    children: ReactElement;
}

/**
 * This component utilizes redux-form utilities to fetch the current
 * colorPickerHistory value based on the given key. The component also creates
 * an onColorPickerHistoryChange callback. The value and callback are added
 * to the props of the single child element this component expects
 * (usually a "Field" component).
 *
 * This component uses another internal helper component to achieve this. The
 * color picker history is stored in the LocalStorage of the client.
 *
 * @param children Field element.
 */
export const ColorPickerHistoryProvider = ({ children }: Props) => {
    return (
        <FormName>
            {({ form }) => {
                return <Helper formName={form}>{children}</Helper>;
            }}
        </FormName>
    );
};
