import { Children, cloneElement, isValidElement } from 'react';

import NewComponent from '@/app/editor/blocks/components/QuestionMedia/NewComponent';
import { getBoxAttributes } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import { ArtBoardSize } from '@/app/editor/editor/types';
import { RedirectWhenUsingEngine } from '@/app/editor/engine/RedirectWhenUsingEngine';
import { getThemedOrCustomColor } from '@/app/editor/themes/helpers';
import { tw } from '@/utils/tw';

import Box from '../_wrapper/Box';
import SortableList from '../_wrapper/SortableList';
import Text from '../Text/Component';

import type { Alignment, BoxProps, DefaultBlockComponentProps } from '../../types';
import type { Props as BlockProps } from '@/app/editor/editor/components/ArtBoard/Block/Block.controller';
import type { RawDraftContentState } from 'draft-js';
import type { FC } from 'react';

export interface Props extends DefaultBlockComponentProps {
    align?: Alignment;
    box: BoxProps;
    color: string;
    fieldName: string;
    wysiwyg: RawDraftContentState;
}

const answersWrapperClass = tw`flex flex-wrap items-stretch justify-center gap-4`;

const Component: FC<Props> = RedirectWhenUsingEngine(
    ({
        artBoardIndex,
        children,
        box,
        blockId,
        align = 'center',
        color,
        wysiwyg,
        nestedLevel,
        activeTheme,
        isPreview,
        isDragged,
    }) => {
        const { artBoardSize, isInColumn, isInSingleColumn } = useLayoutInfo(blockId, isPreview);

        // custom Box for better editing
        const customBox: BoxProps = { ...box, left: 'medium', right: 'medium' };

        const getAnswerClass = (
            artBoardSize: ArtBoardSize,
            isInColumn: boolean,
            isInSingleColumn: boolean,
        ) => {
            const isTwoColumns =
                // if artboard size is mobile or artboard size is tablet or desktop and in column and not in single column
                artBoardSize === ArtBoardSize.MOBILE ||
                ((artBoardSize === ArtBoardSize.TABLET || artBoardSize === ArtBoardSize.DESKTOP) &&
                    isInColumn &&
                    !isInSingleColumn);

            // else 4 columns (25%)
            return isTwoColumns
                ? 'basis-[calc(50%-16px)] max-w-[calc(50%-16px)]'
                : 'basis-[calc(25%-16px)] max-w-[calc(25%-16px)]';
        };

        const answerClass = getAnswerClass(artBoardSize, isInColumn, isInSingleColumn);

        const answers = Children.map(children, (child) => {
            return isValidElement<BlockProps>(child)
                ? cloneElement(child, {
                      propsFromParent: {
                          blockWrapperClass: 'h-full',
                          blockContainerClass: answerClass,
                          draggedBlockContainerClass: 'w-full',
                      },
                  })
                : null;
        });

        return (
            <Box
                box={getBoxAttributes({ box: customBox, isInColumn })}
                isDragged={isDragged}
                artBoardIndex={artBoardIndex}
            >
                <div className="mb-6 px-2">
                    <Text
                        align={align}
                        wysiwyg={wysiwyg}
                        color={getThemedOrCustomColor(color, 'fontColor', activeTheme)}
                        blockId={blockId}
                    />
                </div>
                {isPreview ? (
                    <div className={answersWrapperClass}>{answers}</div>
                ) : (
                    <SortableList
                        parentBlockId={blockId}
                        sortingStrategy="rect"
                        nestedLevel={nestedLevel}
                        className={answersWrapperClass}
                    >
                        {answers}
                    </SortableList>
                )}
            </Box>
        );
    },
    NewComponent,
);

Component.displayName = 'QuestionMedia';

export default Component;
