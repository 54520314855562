import { getEditorEngineByParentRenderer } from '@/app/editor/engine/core/components/view/getEditorEngineByParentRenderer';
import { getEditorEngineNodeRenderer } from '@/app/editor/engine/core/components/view/getEditorEngineNodeRenderer';
import { getEditorEngineSequenceRenderer } from '@/app/editor/engine/core/components/view/getEditorEngineSequenceRenderer';

import type {
    EditorEngine,
    EditorEngineCustomNodeWrapper,
    EditorEngineDefaultTypeInput,
} from '@/app/editor/engine/core/types';
import type { ComponentType, Context, FC } from 'react';

/**
 * Creates the renderers for an editor engine instance.
 */
export const createEditorEngineRenderers = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
    TContext = Context<EditorEngine<TEditorEngineTypeInput>>,
>({
    context,
    CustomNodeWrapper,
}: {
    /**
     * The context that holds the editor engine instance.
     */
    context: TContext;
    /**
     * A custom node wrapper to use for rendering nodes.
     */
    CustomNodeWrapper?: EditorEngineCustomNodeWrapper<TEditorEngineTypeInput>;
}) => {
    const WithContext = <TComponent extends FC, TComponentProps extends Parameters<TComponent>[0]>(
        Component: ComponentType<TComponentProps>,
    ) => {
        const EnhancedComponent = (props: Omit<TComponentProps, 'context'>) => {
            return <Component {...(props as TComponentProps)} context={context} />;
        };

        return EnhancedComponent as ComponentType<Omit<TComponentProps, 'context'>>;
    };

    const Renderer = WithContext(
        getEditorEngineNodeRenderer<TEditorEngineTypeInput>({ CustomNodeWrapper }),
    );
    const ByParentRenderer = WithContext(
        getEditorEngineByParentRenderer<TEditorEngineTypeInput>({
            EditorEngineNodeRenderer: Renderer,
        }),
    );
    const Sequence = WithContext(
        getEditorEngineSequenceRenderer<TEditorEngineTypeInput>({
            EditorEngineByParentRenderer: ByParentRenderer,
        }),
    );

    return {
        Renderer,
        ByParentRenderer,
        Sequence,
    };
};
