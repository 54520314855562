export const Quiz = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="2" y="10" width="9" height="12" rx="2" fill="#007AFF" />
            <rect x="13" y="10" width="9" height="12" rx="2" fill="#007AFF" />
            <path
                opacity="0.15"
                d="M2 4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V6C22 7.10457 21.1046 8 20 8H4C2.89543 8 2 7.10457 2 6V4Z"
                fill="#007AFF"
            />
        </svg>
    );
};
