export const Layouts = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <rect x="3" y="4" width="8" height="16" rx="2" fill="white" />
            <rect opacity="0.7" x="13" y="4" width="8" height="16" rx="2" fill="white" />
        </svg>
    );
};
