import { AUTH_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE, WHITELISTED_ROUTES } from '@/app/auth/constants';

import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { getLocalizedLoginUrl } from '@/app/auth/helper';
import { fetchBoot } from '@/app/auth/models/boot';
import { fetchLoginWithRefreshToken, getIsLoggedIn } from '@/app/auth/models/login';
import { useAppSelector, useAppDispatch } from '@/core/redux/hooks';
import { loadCookie } from '@/utils/cookies';

export const useAuthGuard = (): boolean => {
    const dispatch = useAppDispatch();
    const { replace, route } = useRouter();

    const isLoggedIn = useAppSelector(getIsLoggedIn);
    const isWhitelistedRoute = WHITELISTED_ROUTES.includes(route);

    useEffect(() => {
        const authToken = loadCookie(AUTH_TOKEN_COOKIE);
        const refreshToken = loadCookie(REFRESH_TOKEN_COOKIE);

        if (!isWhitelistedRoute) {
            // If there is no access token nor refresh token -> redirect to "/login" page.
            if (!authToken && !refreshToken) {
                replace(getLocalizedLoginUrl(true)).then();
            }

            // If there is no access token but a refresh token -> try to login via refresh token
            else if (!authToken && refreshToken) {
                dispatch(fetchLoginWithRefreshToken(refreshToken));
            }

            // If not booted/logged in yet -> boot
            else if (!isLoggedIn && authToken) {
                dispatch(fetchBoot());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isLoggedIn, isWhitelistedRoute]); // CAUTION: leave `replace` on purpose because it will trigger the effect indefinitely

    return isLoggedIn || isWhitelistedRoute;
};
