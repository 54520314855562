import { NAME } from '@/app/crm/constants';

import { createSelector, createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import qs from 'query-string';

import { BulkDeleteOption } from '@/app/crm/types';
import { hideModal } from '@/app/modals/models/modals';
import { showToast } from '@/app/toasts/utils/showToast';
import { crmApiDelete, crmApiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, getMetaFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY } from '@/utils/empty';

import { getCRMFilters } from './filters';
import { getKanbanPagination, handleDeleteKanbanContact } from './kanban';
import { getSchema, updateSchemaProperty } from './schema';
import {
    getPagination,
    getSelection,
    getSorting,
    initialState as tableInitialState,
    removePaginatedContactIds,
    setPagination,
    setSelection,
    setTablePagination,
    addPaginatedContactIds,
    handleDeleteTableContact,
} from './table';
import {
    createPropertyOrderValueAccessor,
    getContactStatus,
    getIsKanbanView,
    getSortingQuery,
    getTotalSelected,
} from '../helpers';
import { unparseSavedFilters } from '../helpers/data';
import { trackPropertyReordered } from '../helpers/property';

import type { State as TableState } from './table';
import type { ContactResource, SchemaResource, PropertyView } from '@/app/crm/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    fetching: boolean;
    searching: boolean;
    hasFinishedFetching: boolean;
    deleting: boolean;
    loadingMore: boolean;
    contacts: { [contactId: string]: ContactResource };
    search: string;
}

const initialState: State = {
    fetching: false,
    searching: false,
    hasFinishedFetching: false,
    deleting: false,
    loadingMore: false,
    contacts: {},
    search: '',
};

const contactsSlice = createSlice({
    name: `${NAME}/contacts`,
    initialState,
    reducers: {
        setFetching(state, action: PayloadAction<boolean>) {
            state.fetching = action.payload;
        },
        setSearching(state, action: PayloadAction<boolean>) {
            state.searching = action.payload;
        },
        setHasFinishedFetching(state, action: PayloadAction<boolean>) {
            state.hasFinishedFetching = action.payload;
        },
        setDeleting(state, action: PayloadAction<boolean>) {
            state.deleting = action.payload;
        },
        setLoadingMore(state, action: PayloadAction<boolean>) {
            state.loadingMore = action.payload;
        },
        addContacts(state, action: PayloadAction<{ [contactId: string]: ContactResource }>) {
            state.contacts = {
                ...state.contacts,
                ...action.payload,
            };
        },
        removeContacts(
            state,
            action: PayloadAction<{ selectedIds: string[]; shouldKeepInstead?: boolean }>,
        ) {
            const contacts = { ...state.contacts };

            state.contacts = action.payload.shouldKeepInstead
                ? pick(contacts, action.payload.selectedIds)
                : omit(contacts, action.payload.selectedIds);
        },
        setContact(state, action: PayloadAction<ContactResource>) {
            state.contacts = {
                ...state.contacts,
                [action.payload.id]: action.payload,
            };
        },
        setSearch(state, action: PayloadAction<string>) {
            state.search = action.payload;
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======
export const {
    setFetching,
    setSearching,
    setHasFinishedFetching,
    setLoadingMore,
    setDeleting,
    setContact,
    addContacts,
    removeContacts,
    setSearch,
    reset,
} = contactsSlice.actions;

// === Selectors ======

export const getFetching = (state: AppState) => state[NAME].contactsReducer.fetching;
export const getHasFinishedFetching = (state: AppState) =>
    state[NAME].contactsReducer.hasFinishedFetching;
export const getSearching = (state: AppState) => state[NAME].contactsReducer.searching;
export const getDeleting = (state: AppState) => state[NAME].contactsReducer.deleting;
export const getLoadingMore = (state: AppState) => state[NAME].contactsReducer.loadingMore; // TODO: Not used (yet?)
export const getContacts = (state: AppState) => state[NAME].contactsReducer.contacts;
export const getContactById = (state: AppState, contactId: string) =>
    state[NAME].contactsReducer.contacts[contactId];
export const getSearch = (state: AppState) => state[NAME].contactsReducer.search;
export const getHasContacts = createSelector(
    [getPagination, getKanbanPagination, getSearch],
    (tablePagination, kanbanPagination, search) => {
        const hasTableContacts = !!tablePagination.count;
        const kanbanPaginationValues = Object.values(kanbanPagination);
        const hasKanbanContacts = kanbanPaginationValues.some((pagination) => !!pagination?.count);

        return hasTableContacts || hasKanbanContacts || !!search;
    },
);

// === Helpers ======

const getSearchField = (searchValue?: string) => {
    if (!searchValue) {
        return;
    }

    return ['person'];
};

const getSearchValue = (searchValue?: string, searchFields?: string[]) => {
    if (!searchValue) {
        return;
    }

    return searchFields?.map(() => searchValue);
};

const createSearchQuery = (state: AppState, overrides?: { page?: number; limit?: number }) => {
    const sorting = getSorting(state);
    const search = getSearch(state);
    const { page, limit } = tableInitialState.pagination;
    const { sortField, sortOrder } = getSortingQuery(sorting);

    const searchField = getSearchField(search);

    return {
        page: overrides?.page ?? page,
        limit: overrides?.limit ?? limit,
        sortField,
        sortOrder,
        searchField,
        searchValue: getSearchValue(search, searchField),
    };
};

const stringifySearchQuery = (state: AppState, overrides?: { page?: number; limit?: number }) => {
    return qs.stringify(createSearchQuery(state, overrides));
};

const getContactUrl = (
    campaignId: string,
    state: AppState,
    overrides?: { page?: number; limit?: number },
) => {
    return `/contacts/list?campaignId=${campaignId}&${stringifySearchQuery(state, overrides)}`;
};

export const updateFieldsOrder =
    ({
        campaignId,
        activeId,
        overId,
        isMovingForward,
        view,
    }: {
        campaignId: string;
        activeId: string;
        overId: string;
        isMovingForward: boolean;
        view: PropertyView;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        const state = getState();
        const schema = getSchema(state);

        const newPropertyOrder = createPropertyOrderValueAccessor(view)(
            schema,
            overId,
            isMovingForward,
        );

        await dispatch(
            updateSchemaProperty({
                campaignId,
                fieldName: activeId,
                fieldToUpdate: `orders.${view}`,
                newValue: isNaN(newPropertyOrder) ? 0 : newPropertyOrder,
            }),
        );

        trackPropertyReordered(view, activeId);

        return getSchema(getState());
    };

export const updateFieldSize =
    ({
        campaignId,
        activeId,
        newSize,
    }: {
        campaignId: string;
        activeId: string;
        newSize: number;
    }): AppThunk<Promise<SchemaResource>> =>
    async (dispatch, getState) => {
        await dispatch(
            updateSchemaProperty({
                campaignId,
                fieldName: activeId,
                fieldToUpdate: 'size',
                newValue: newSize,
            }),
        );

        return getSchema(getState());
    };

// === Thunks ======

export const fetchContactsResource =
    (
        url: string,
    ): AppThunk<Promise<{ contacts: State['contacts']; pagination: TableState['pagination'] }>> =>
    async (_, getState) => {
        const crmFilters = getCRMFilters(getState());

        const response = await crmApiPost(url, {
            data: unparseSavedFilters(crmFilters),
        });

        // From DB
        const contactsData: ContactResource[] = getDataFromResponse(response);
        const byId = contactsData.reduce((accumulator, contact) => {
            accumulator[contact.id] = contact;

            return accumulator;
        }, {});

        return {
            contacts: byId,
            pagination: getMetaFromResponse(response),
        };
    };

export const fetchContacts =
    (campaignId: string, initialFetching = true): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setFetching(initialFetching));

        try {
            const { contacts, pagination } = await dispatch(
                fetchContactsResource(getContactUrl(campaignId, getState())),
            );

            dispatch(addContacts(contacts));
            dispatch(setTablePagination(Object.keys(contacts), pagination));
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to fetch contacts' });
        } finally {
            dispatch(setSearching(false));
            dispatch(setFetching(false));
            dispatch(setHasFinishedFetching(true));
        }
    };

export const fetchNextContactsPage =
    (campaignId: string) => (): AppThunk => async (dispatch, getState) => {
        dispatch(setLoadingMore(true));

        const state = getState();
        const { next: nextPageUrl, page, limit } = getPagination(state);
        const contacts = getContacts(state);
        const localContactsLength = Object.keys(contacts)?.length;
        const maxLocalContacts = (page + 1) * limit;
        const shouldRefetchCurrentPage = maxLocalContacts > localContactsLength;

        if (nextPageUrl === null) {
            return;
        }

        try {
            // Refetch current page if there are missing items
            if (shouldRefetchCurrentPage) {
                const { contacts: currContacts } = await dispatch(
                    fetchContactsResource(getContactUrl(campaignId, getState(), { page, limit })),
                );

                dispatch(addContacts(currContacts));
                dispatch(addPaginatedContactIds(Object.keys(currContacts)));
            }

            const { contacts, pagination: nextPagination } = await dispatch(
                fetchContactsResource(nextPageUrl),
            );

            dispatch(addContacts(contacts));
            dispatch(setTablePagination(Object.keys(contacts), nextPagination, true));
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to fetch next contacts page' });
        } finally {
            dispatch(setLoadingMore(false));
        }
    };

export const deleteContact =
    (contactId: string, campaignId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setDeleting(true));

        const isKanban = getIsKanbanView();
        const contact = getContactById(getState(), contactId);
        const contactStatus = getContactStatus(contact);

        try {
            await crmApiDelete(`/contacts/${contactId}?campaignId=${campaignId}`);

            dispatch(removeContacts({ selectedIds: [contactId] }));

            // Handle deleting contact from state
            if (isKanban) {
                dispatch(handleDeleteKanbanContact(contactId, contactStatus));
            } else {
                dispatch(handleDeleteTableContact(contactId));
            }

            dispatch(hideModal());

            showToast({
                type: 'success',
                message: `${NAME}:delete-contacts-success`,
                messageValues: { count: 1 },
            });
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to delete contact' });
        } finally {
            dispatch(setDeleting(false));
        }
    };

export const deleteSelectedContacts =
    (campaignId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setDeleting(true));

        try {
            const state = getState();
            const selection = getSelection(state);
            const pagination = getPagination(state);

            await crmApiPost(`/contacts/bulk-delete?campaignId=${campaignId}`, {
                data: {
                    option: selection.isAllSelected
                        ? BulkDeleteOption.exclude
                        : BulkDeleteOption.include,
                    contacts: selection.selectedIds,
                },
            });

            dispatch(
                removeContacts({
                    selectedIds: selection.selectedIds,
                    shouldKeepInstead: selection.isAllSelected,
                }),
            );
            dispatch(
                removePaginatedContactIds({
                    selectedIds: selection.selectedIds,
                    shouldKeepInstead: selection.isAllSelected,
                }),
            );
            dispatch(
                setSelection({
                    isAllSelected: false,
                    selectedIds: EMPTY_ARRAY,
                }),
            );
            dispatch(
                setPagination({
                    ...pagination,
                    count: selection.isAllSelected
                        ? selection.selectedIds.length
                        : pagination.count - selection.selectedIds.length,
                }),
            );
            dispatch(hideModal());
            showToast({
                type: 'success',
                message: `${NAME}:delete-contacts-success`,
                messageValues: { count: getTotalSelected(selection, pagination.count) },
            });
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to delete contacts' });
        } finally {
            dispatch(setDeleting(false));
        }
    };

export const bulkUpdateContacts =
    ({
        oldValue,
        newValue,
        fieldName,
        campaignId,
    }: {
        oldValue: string;
        newValue: string;
        fieldName: string;
        campaignId: string;
    }): AppThunk =>
    async () => {
        try {
            await crmApiPost(`/contacts/bulk-update?campaignId=${campaignId}`, {
                data: {
                    fieldName,
                    oldValue,
                    newValue,
                },
            });
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to bulk update contacts' });
        }
    };

export default contactsSlice.reducer;
