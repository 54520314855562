import { useRouter } from 'next/router';
import { useEffect } from 'react';

let prevRoute: string;

export const usePreviousRoute = () => {
    const { asPath } = useRouter();

    useEffect(() => {
        prevRoute = asPath;
    }, [asPath]);

    return prevRoute;
};

export const getPrevRoute = () => prevRoute;
