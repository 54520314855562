import { combineReducers } from 'redux';

import addPageReducer from './add';
import deletePageReducer from './delete';
import duplicatePageReducer from './duplicate';
import pageMappingReducer from './pageMapping';
import pagesReducer from './pages';
import resultMappingReducer from './resultMapping';
import updatePageReducer from './update';
import variantPageReducer from './variant';

export default combineReducers({
    pagesReducer,
    pageMappingReducer,
    resultMappingReducer,
    addPageReducer,
    deletePageReducer,
    updatePageReducer,
    duplicatePageReducer,
    variantPageReducer,
});
