import { NAME } from '@/app/crm/constants';

import { createSelector, createSlice } from '@reduxjs/toolkit';
import qs from 'query-string';

import { RequestState } from '@/app/workspaces/types';
import { crmApiGet, handleRuntimeError } from '@/core/api';
import { getDataFromResponse, getMetaFromResponse } from '@/core/api/helper';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@/utils/empty';

import type { ActivityResource, Pagination } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

type ActivitiesRequestState = Partial<
    Record<'fetchActivities' | 'refetchActivities', RequestState>
>;

interface State {
    activities: { [contactId: string]: ActivityResource[] };
    pagination: { [contactId: string]: Pagination };
    requestState: {
        [contactId: string]: ActivitiesRequestState;
    };
}

const initialState: State = {
    activities: EMPTY_OBJECT,
    pagination: EMPTY_OBJECT,
    requestState: EMPTY_OBJECT,
};

const DEFAULT_PAGINATION: Pagination = { limit: 30, count: 0, page: 0 };
const DEFAULT_REQUEST_STATE: ActivitiesRequestState = {
    fetchActivities: RequestState.Idle,
    refetchActivities: RequestState.Idle,
};

const activitiesSlice = createSlice({
    name: `${NAME}/activities`,
    initialState,
    reducers: {
        addActivities(
            state,
            action: PayloadAction<{ contactId: string; activities: ActivityResource[] }>,
        ) {
            state.activities[action.payload.contactId] = [
                ...(state.activities[action.payload.contactId] || EMPTY_ARRAY),
                ...action.payload.activities,
            ];
        },
        setActivities(
            state,
            action: PayloadAction<{ contactId: string; activities: ActivityResource[] }>,
        ) {
            state.activities[action.payload.contactId] = action.payload.activities;
        },
        setPagination(state, action: PayloadAction<{ contactId: string; pagination: Pagination }>) {
            state.pagination[action.payload.contactId] = action.payload.pagination;
        },
        setRequestState(
            state,
            action: PayloadAction<{ contactId: string; requestState: ActivitiesRequestState }>,
        ) {
            state.requestState[action.payload.contactId] = {
                ...state.requestState[action.payload.contactId],
                ...action.payload.requestState,
            };
        },
        reset() {
            return initialState;
        },
    },
});

// === Actions ======

export const { addActivities, setActivities, setPagination, setRequestState, reset } =
    activitiesSlice.actions;

// === Selectors ======

export const getActivitiesByContactId = (contactId: string) => (state: AppState) =>
    state[NAME].activitiesReducer.activities[contactId] || EMPTY_ARRAY;

export const getPagination = (contactId: string) => (state: AppState) =>
    state[NAME].activitiesReducer.pagination[contactId] || DEFAULT_PAGINATION;

export const getHasMore = (contactId: string) =>
    createSelector([getPagination(contactId)], (pagination) => {
        const { page, count, limit } = pagination;
        const fetchedCountMax = (page + 1) * limit;

        return fetchedCountMax < count;
    });

export const getRequestState = (contactId: string) => (state: AppState) =>
    state[NAME].activitiesReducer.requestState[contactId] || DEFAULT_REQUEST_STATE;

// === Thunks ======

const fetchActivitiesData = async ({
    contactId,
    campaignId,
    page,
    limit,
}: {
    contactId: string;
    campaignId: string;
    page: number;
    limit: number;
}): Promise<{ activities: ActivityResource[]; pagination: Pagination }> => {
    const query = qs.stringify({ page, limit, contactId, campaignId });

    const response = await crmApiGet(`/activities?${query}`);

    const activities = getDataFromResponse(response);
    const pagination = getMetaFromResponse(response);

    return { activities, pagination };
};

export const fetchActivities =
    (contactId: string, campaignId: string): AppThunk =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                contactId,
                requestState: {
                    fetchActivities: RequestState.InProgress,
                },
            }),
        );

        try {
            const { activities, pagination } = await fetchActivitiesData({
                contactId,
                campaignId,
                limit: DEFAULT_PAGINATION.limit,
                page: DEFAULT_PAGINATION.page,
            });

            dispatch(setActivities({ contactId, activities }));
            dispatch(setPagination({ contactId, pagination }));

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        fetchActivities: RequestState.Success,
                    },
                }),
            );
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to fetch activities' });

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        fetchActivities: RequestState.Error,
                    },
                }),
            );
        }
    };

export const refetchActivities =
    (contactId: string, campaignId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(
            setRequestState({
                contactId,
                requestState: {
                    refetchActivities: RequestState.InProgress,
                },
            }),
        );

        const firstContactActivity = getActivitiesByContactId(contactId)(getState())[0];

        try {
            const { activities, pagination } = await fetchActivitiesData({
                contactId,
                campaignId,
                limit: DEFAULT_PAGINATION.limit,
                page: DEFAULT_PAGINATION.page,
            });

            if (firstContactActivity?.id !== activities[0]?.id) {
                dispatch(setActivities({ contactId, activities }));
                dispatch(setPagination({ contactId, pagination }));
            }

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        refetchActivities: RequestState.Success,
                    },
                }),
            );
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to refetch activities' });

            dispatch(
                setRequestState({
                    contactId,
                    requestState: {
                        refetchActivities: RequestState.Error,
                    },
                }),
            );
        }
    };

export const fetchNextPage =
    (contactId: string, campaignId: string) => (): AppThunk => async (dispatch, getState) => {
        const paginationCurrent = getPagination(contactId)(getState());

        try {
            const { activities, pagination } = await fetchActivitiesData({
                contactId,
                campaignId,
                limit: paginationCurrent.limit,
                page: paginationCurrent.page + 1,
            });

            dispatch(addActivities({ contactId, activities }));
            dispatch(setPagination({ contactId, pagination }));
        } catch (error) {
            handleRuntimeError(error, { message: 'Failed to fetch activities next page' });
        }
    };

export default activitiesSlice.reducer;
