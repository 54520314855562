import { PerspectiveEditorEnginePreviewAwareNodeRenderer } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';

import type { PerspectiveEditorEnginePreviewAwareNodeRendererProps } from '@/app/editor/engine/components/PerspectiveEditorEnginePreviewAwareNodeRenderer';

type Props = {
    /**
     * The component type to render.
     */
    componentType: string;
} & Omit<PerspectiveEditorEnginePreviewAwareNodeRendererProps, 'renderer'>;

const renderer = ({ nodes, renderNode }) => {
    if (nodes.length === 0) {
        return null;
    }

    return renderNode({ node: nodes[0] });
};

/**
 * A renderer which will render only nodes with a specific component type.
 */
export const PerspectiveEditorEngineNodeTypeRenderer = ({ componentType, ...rest }: Props) => {
    return (
        <PerspectiveEditorEnginePreviewAwareNodeRenderer
            filter={({ block }) => block.attributes.componentType === componentType}
            {...rest}
            renderer={renderer}
        />
    );
};
