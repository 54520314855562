import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { getBoxAttributes, getIsTipTapData } from '@/app/editor/blocks/helpers';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import {
    getColorByLightness,
    getGradientColors,
    getThemedOrCustomColor,
    isGradientColor,
} from '@/app/editor/themes/helpers';
import { useBorderRadius } from '@/app/editor/themes/hooks/useBorderRadius';
import { cn } from '@/utils/cn';

import Box from '../_wrapper/Box';

import type { Alignment, DefaultBlockComponentProps } from '../../types';
import type { JSONContent } from '@tiptap/core';
import type { RawDraftContentState } from 'draft-js';

const DraftEditor = dynamic(() => import('../Text/DraftEditor'), { ssr: false });
const TipTapBlock = dynamic(
    () => import('../Text/TipTapBlock/TipTapBlock').then((mod) => mod.TipTapBlock),
    { ssr: false },
);

export interface Props extends DefaultBlockComponentProps {
    wysiwyg: RawDraftContentState | JSONContent;
    align: Alignment;
    color?: string;
    backgroundColor?: string;
    buttonType: 'button' | 'submit';
    animation?: undefined | 'jittery';
    buttonStyle?: 'filled' | 'outline';
}

const Component = ({
    artBoardIndex,
    blockId,
    box,
    align,
    color,
    wysiwyg,
    buttonType,
    backgroundColor,
    activeTheme,
    animation,
    buttonStyle,
    isDragged,
    themedBackgroundColor,
    isPreview,
}: Props) => {
    const { isInColumn, isDesktop, isInSingleColumn } = useLayoutInfo(blockId, isPreview);
    const [animating, setAnimating] = useState(false);
    const borderRadiusClass = useBorderRadius('button');

    useEffect(() => {
        if (animation === 'jittery') {
            setAnimating(true);
        } else {
            setAnimating(false);
        }
    }, [animation]);

    const isTipTap = getIsTipTapData(wysiwyg);

    // Colors
    const bgColor =
        isInColumn || buttonType === 'submit'
            ? 'transparent'
            : getThemedOrCustomColor(
                  box.backgroundColor,
                  themedBackgroundColor ? themedBackgroundColor : 'backgroundColor',
                  activeTheme,
              );
    const buttonBackgroundColor = getThemedOrCustomColor(
        backgroundColor,
        'buttonBackgroundColor',
        activeTheme,
    );
    const fillColor = buttonStyle === 'outline' ? 'transparent' : buttonBackgroundColor;
    const outlineColor = isGradientColor(buttonBackgroundColor)
        ? getGradientColors(buttonBackgroundColor)[0]
        : buttonBackgroundColor;
    const textColor =
        buttonStyle === 'outline'
            ? color
            : getColorByLightness(color, buttonBackgroundColor, false);

    return (
        <Box
            box={{
                ...getBoxAttributes({ box, isInColumn }),
                backgroundColor: bgColor,
            }}
            isDragged={isDragged}
            artBoardIndex={artBoardIndex}
        >
            <div
                className={cn('text-left', {
                    'max-w-lg': isDesktop && isInSingleColumn,
                    'mx-auto': isDesktop && align === 'center',
                    'text-center': align === 'center',
                })}
            >
                <div
                    className={cn(
                        'box-border max-w-full bg-cover bg-fixed bg-no-repeat px-6 py-4 shadow-button-inset transition-all',
                        borderRadiusClass,
                        {
                            'shadow-none outline outline-[1.5px] -outline-offset-[1.5px]':
                                buttonStyle === 'outline',
                            'animate-jitter-once': animating,
                            'inline-block px-8':
                                isDesktop &&
                                (isInSingleColumn || !isInColumn) &&
                                buttonType === 'button',
                        },
                    )}
                    style={{
                        background: `${fillColor} no-repeat`,
                        color: textColor,
                        textAlign: align,
                        outlineColor: outlineColor,
                    }}
                >
                    {isTipTap ? (
                        <TipTapBlock blockId={blockId} wysiwyg={wysiwyg} textColor={textColor} />
                    ) : (
                        <DraftEditor
                            wysiwyg={wysiwyg as RawDraftContentState}
                            textBlockId={blockId}
                        />
                    )}
                </div>
            </div>
        </Box>
    );
};

Component.displayName = 'Button';

export default Component;
