import {
    type EditorEngineDefaultTypeInput,
    EditorEngineEventType,
    EditorEngineExecutionDirection,
    EditorEngineHistoryEntryRecordedStepStatusType,
    type EditorEngineHistoryEntryTransactionStepResult,
    EditorEngineHistoryExecuteErrorType,
    type EditorEngineHistoryExecutionError,
} from '@/app/editor/engine/core/types';

import { makeEditorEngineError } from '../../../utils/error/makeEditorEngineError';

import type {
    EditorEngineEventEmitter,
    EditorEngineHistoryEntryRecordedStep,
    EditorEngineStepUpdater,
    EditorEngineHistoryEntry,
} from '@/app/editor/engine/core/types';

export interface ExecuteEntryStepInput<
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
> {
    /**
     * The entry that the step belongs to.
     */
    entry: EditorEngineHistoryEntry<TEditorEngineTypeInput>;
    /**
     * The step to execute.
     */
    step: EditorEngineHistoryEntryRecordedStep<TEditorEngineTypeInput['ExtraContext']>;
    /**
     * Whether to apply or revert the step.
     */
    direction: EditorEngineExecutionDirection;
    /**
     * This function will be called to update the current state of the step.
     * This might happen when the process starts, finishes, or fails.
     */
    updateStep: EditorEngineStepUpdater<TEditorEngineTypeInput['ExtraContext']>;
    /**
     * Extra context to pass to the step.
     */
    extraContext: TEditorEngineTypeInput['ExtraContext'];
    /**
     * The event emitter to emit events.
     */
    eventEmitter: EditorEngineEventEmitter;
    /**
     * This function will be called when an error occurs during the execution of
     * the step. While the event emitter already has events related to the
     * history, this function is specifically meant to handle history execution
     * errors only.
     */
    reportError?: (error: EditorEngineHistoryExecutionError<TEditorEngineTypeInput>) => void;
}

/**
 * Execute a step from an entry.
 *
 * When an action is enqueued, this function will be called when either applying
 * or reverting its transaction, for each of the steps in the transaction.
 *
 * It is worth reminding that since this is a "step", we are handling the
 * _optimistic_ part of an action.
 */
export const executeEntryStep = async <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    entry,
    step,
    direction,
    updateStep,
    extraContext,
    eventEmitter,
    reportError,
}: ExecuteEntryStepInput<TEditorEngineTypeInput>): Promise<EditorEngineHistoryEntryTransactionStepResult> => {
    const optimisticUpdateStartedAt = Date.now();
    updateStep({
        step,
        result: {
            type: EditorEngineHistoryEntryRecordedStepStatusType.ExecutingOptimisticUpdate,
            optimisticUpdateStartedAt,
            direction,
        },
    });
    eventEmitter.emit({
        name: EditorEngineEventType.StepStarted,
        payload: {
            step,
        },
    });

    try {
        let result: EditorEngineHistoryEntryTransactionStepResult;

        if (direction === EditorEngineExecutionDirection.Forward) {
            result = step.transactionStep.apply({ extraContext });
        } else {
            result = step.transactionStep.revert({ extraContext });
        }

        updateStep({
            step,
            result: {
                optimisticUpdateStartedAt,
                direction,
                executionDebug: result.additionalDebugData || {},
                ...(result.success === false
                    ? {
                          type: EditorEngineHistoryEntryRecordedStepStatusType.Failed,
                          optimisticUpdateFailedAt: Date.now(),
                          error: result.error,
                      }
                    : {
                          type: EditorEngineHistoryEntryRecordedStepStatusType.ExecutedSuccessfully,
                          optimisticUpdateFinishedAt: Date.now(),
                      }),
            },
        });

        if (result.success) {
            eventEmitter.emit({
                name: EditorEngineEventType.StepFinished,
                payload: {
                    step,
                },
            });
        }

        if (result.success === false) {
            eventEmitter.emit({
                name: EditorEngineEventType.StepFailed,
                payload: {
                    step,
                },
            });

            reportError?.({
                type: EditorEngineHistoryExecuteErrorType.StepFailed,
                entry,
                step,
                error: result.error,
            });
        }

        return result;
    } catch (error) {
        const editorEngineError = makeEditorEngineError({
            error,
        });

        updateStep({
            step,
            result: {
                type: EditorEngineHistoryEntryRecordedStepStatusType.Failed,
                optimisticUpdateStartedAt,
                optimisticUpdateFailedAt: Date.now(),
                direction,
                error: editorEngineError,
                executionDebug: {},
            },
        });

        eventEmitter.emit({
            name: EditorEngineEventType.StepFailed,
            payload: {
                step,
            },
        });

        reportError?.({
            type: EditorEngineHistoryExecuteErrorType.StepFailed,
            entry,
            step,
            error: editorEngineError,
        });

        return {
            success: false,
            error: editorEngineError,
            additionalDebugData: {},
        };
    }
};
