export const NAME = 'funnelSettings';

// Cookie Settings
export const SIMPLE_COOKIE_EXAMPLE_LINK = 'https://vorlage.perspective.co/einfacher-cookie-banner';
export const ADVANCED_COOKIE_EXAMPLE_LINK =
    'https://vorlage.perspective.co/erweiterter-cookie-banner';

// Sharing image dimensions
export const SHARING_IMAGE_WIDTH = 1200;
export const SHARING_IMAGE_HEIGHT = 630;
