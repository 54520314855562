import { ConsoleLogLevel } from '@/app/editor/engine/core/types/util';
import { ConsoleMessageBuilder } from '@/app/editor/engine/core/utils/log/ConsoleMessageBuilder';

import { EditorEngineLogLevel } from '../../types/log';

import type { EditorEngineLogHandler } from '../../types/log';

/**
 * Get the console reporter used for reporting log records in the development
 * and production environments.
 */
export const getLogConsoleReporter = ({
    name,
}: {
    /**
     * The name of the Editor Engine instance.
     */
    name: string;
}) =>
    ((record) => {
        const dateTimeFormat = Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        });
        const getItems = ({
            logLevel,
            consoleLevel,
        }: {
            logLevel: EditorEngineLogLevel;
            consoleLevel: ConsoleLogLevel;
        }) => {
            const builder = new ConsoleMessageBuilder();

            builder.append(dateTimeFormat.format(new Date()), ConsoleLogLevel.Muted);
            builder.append(' ');
            builder.append(`[${name}:${logLevel}]`, consoleLevel);
            builder.append(' ');
            builder.append(record.message);

            return [...builder.build(), record.context];
        };

        switch (record.level) {
            case EditorEngineLogLevel.Debug:
                console.debug(
                    ...getItems({
                        logLevel: record.level,
                        consoleLevel: ConsoleLogLevel.Debug,
                    }),
                );

                break;
            case EditorEngineLogLevel.Info:
                console.info(
                    ...getItems({
                        logLevel: record.level,
                        consoleLevel: ConsoleLogLevel.Info,
                    }),
                );

                break;
            case EditorEngineLogLevel.Warning:
                console.warn(
                    ...getItems({
                        logLevel: record.level,
                        consoleLevel: ConsoleLogLevel.Warning,
                    }),
                );

                break;
            case EditorEngineLogLevel.Error:
            case EditorEngineLogLevel.Critical:
                console.error(
                    ...getItems({
                        logLevel: record.level,
                        consoleLevel: ConsoleLogLevel.Error,
                    }),
                );

                break;
        }
    }) satisfies EditorEngineLogHandler;
