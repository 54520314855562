import { NAME } from '@/app/crm/constants';

import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import Tooltip from '@/ui/components/Tooltip';

interface Props {
    onDelete: () => void;
    onRetry: () => void;
}

export const FileInfoErrorActions = ({ onDelete, onRetry }: Props) => {
    const { t } = useTranslation(NAME);

    return (
        <>
            <Tooltip content={t('file-upload-retry')}>
                <button
                    className="flex size-6 shrink-0 cursor-pointer items-center justify-center rounded-md text-sm text-gray-500 transition-colors hover:bg-gray-200"
                    onClick={onRetry}
                >
                    <ArrowUpTrayIcon className="size-4" />{' '}
                </button>
            </Tooltip>

            <button
                className="flex size-6 shrink-0 cursor-pointer items-center justify-center rounded-md text-sm text-gray-500 transition-colors hover:bg-gray-200"
                onClick={onDelete}
            >
                <TrashIcon className="size-4" />
            </button>
        </>
    );
};
