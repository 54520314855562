import styles from './emojiStyles.module.scss';

export interface Props {
    emoji: string;
    size?: number;
    padding?: number;
}

const Emoji = ({ emoji, size = 32, padding = 8 }: Props) => {
    const wrapperSize = size + padding * 2;

    return (
        <div
            // Necessary to apply styles to emoji-mart's child elements
            className={styles.emojiWrapper}
            style={{
                padding: padding,
                height: wrapperSize,
            }}
        >
            {/* @ts-ignore */}
            <em-emoji id={emoji} size={size} style={{ lineHeight: `${size}px` }} set="native" />
        </div>
    );
};

export default Emoji;
