import { getAllChildrenBlocksRecursively } from '@/app/editor/commands/utils/getAllChildrenBlocksRecursively';
import { EditorEngineLogLevel } from '@/app/editor/engine/core/types/log';
import { removeNodeStep } from '@/app/editor/engine/core/utils/transaction/steps/removeNodeStep';
import { PerspectiveEditorEngineActionName } from '@/app/editor/engine/types';
import { apiDelete, apiPost } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import type { BlockResource } from '../../blocks/types';
import type {
    EditorEngineActionCreatorPayload,
    EditorEngineNode,
} from '@/app/editor/engine/core/types';
import type {
    PerspectiveEditorEngineActionCreator,
    PerspectiveEditorEngineNodeData,
} from '@/app/editor/engine/types';
import type { PageResource } from '@/app/editor/pages/types';
import type { ResponseData } from '@/core/api/types';

interface Payload extends EditorEngineActionCreatorPayload {
    /**
     * The ID of the block to delete.
     */
    id: string;
}

/**
 * Action that will delete a block.
 */
export const deleteBlockAction = (({
    args: { id },
    documentManager,
    nodeManager,
    extraContext: { clientSideComponents },
    log,
}) => {
    let originalNode: EditorEngineNode<PerspectiveEditorEngineNodeData>;
    let originalPage: PageResource;
    let originalIndex: number;
    let componentsToRestore: BlockResource[];

    return {
        name: PerspectiveEditorEngineActionName.DeleteBlock,
        debug: {},
        onBeforeOptimisticForward(isRedo) {
            if (isRedo) {
                return;
            }

            originalNode = nodeManager.getNode(id);
            originalPage = documentManager.activePage;
            originalIndex = nodeManager.getNodeIndex(id);
            componentsToRestore = getAllChildrenBlocksRecursively(originalNode.block);
        },
        getTransaction() {
            return [
                removeNodeStep({
                    id,
                }),
            ];
        },
        async execute() {
            await apiDelete(`/components/${nodeManager.resolveVirtualId(id)}`);

            return {
                success: true,
            };
        },
        onAfterOptimisticBackward() {
            clientSideComponents.scrollToComponent.focusOn(originalNode.block);
        },
        async undo() {
            const componentsToRestoreWithConcreteIds = componentsToRestore.map(
                nodeManager.resolveVirtualIdsInBlock,
            );

            const updatedPage = await apiPost<ResponseData<PageResource>>(
                `/pages/${originalPage.id}/components`,
                {
                    data: {
                        position: originalIndex,
                        components: [componentsToRestoreWithConcreteIds],
                    },
                },
            );

            const updatedPageId = getDataFromResponse(updatedPage)?.id;

            if (!updatedPageId) {
                log({
                    level: EditorEngineLogLevel.Warning,
                    message: 'Did not find page response after undoing block deletion',
                    context: {
                        originalPageId: originalPage.id,
                    },
                });
            }

            return {
                success: true,
                result: {
                    updatedPageId,
                },
            };
        },
        onAfterOptimisticForward() {
            if (documentManager.activeBlock?.id === id) {
                documentManager.setActiveView('pages');
            }
        },
        async onAfterUndo({ result }) {
            if (!result.updatedPageId) {
                return;
            }

            documentManager.setActivePage(result.updatedPageId);
        },
    };
}) satisfies PerspectiveEditorEngineActionCreator<
    Payload,
    {},
    {},
    {
        updatedPageId?: string;
    }
>;
