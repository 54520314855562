import type { FEATURE_IDS } from './constants';
import type { Relationship, Resource } from '@/core/api/types';

export const enum BillingTimeframe {
    monthly = 'monthly',
    yearly = 'yearly',
}

type Keys = keyof typeof FEATURE_IDS;

export type FeatureId = (typeof FEATURE_IDS)[Keys];

export interface SubscriptionAddon {
    id: string;
    quantity: number;
    amount: number;
    unitPrice: number;
    object: string;
}

export interface SubscriptionAttributes {
    planId: string;
    planQuantity: number;
    planUnitPrice: number;
    billingPeriod: number;
    billingPeriodUnit: string;
    trialEnd: number;
    customerId: string;
    planAmount: number;
    planFreeQuantity: number;
    status: string;
    trialStart: number;
    nextBillingAt: number;
    createdAt: number;
    startedAt: number;
    updatedAt: number;
    hasScheduledChanges: boolean;
    channel: string;
    object: string;
    currencyCode: string;
    dueInvoicesCount: number;
    currentTermEnd: number;
    cancelledAt: number;
    addons: SubscriptionAddon[];
}

export interface SubscriptionRelationships {
    company: Relationship;
}

export type SubscriptionResource = Resource<SubscriptionAttributes, SubscriptionRelationships>;

export interface PlanAddon {
    id: string;
    quantity: number;
    type: string;
    object: string;
}

export interface PlanAttributes {
    name: string;
    invoiceName: string;
    price: number;
    period: number;
    periodUnit: string;
    trialPeriod: number;
    trialPeriodUnit: string;
    pricingModel: string;
    freeQuantity: number;
    status: string;
    enabledInHostedPages: boolean;
    enabledInPortal: boolean;
    addonApplicability: string;
    isShippable: boolean;
    updatedAt: number;
    giftable: boolean;
    resourceVersion: number;
    object: string;
    chargeModel: string;
    taxable: boolean;
    taxProfileId: string;
    currencyCode: string;
    showDescriptionInInvoices: boolean;
    showDescriptionInQuotes: boolean;
    channel: string;
    attachedAddons: PlanAddon[];
}

export interface PlanRelationships {
    company: Relationship;
}

export type PlanResource = Resource<PlanAttributes, PlanRelationships>;

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP',
}
