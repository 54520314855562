export interface Props {
    className?: string;
}

const SolidIcon = ({ className }: Props) => {
    return (
        <div className={className}>
            <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="100%"
            >
                <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="2.613"
                    strokeWidth="4"
                    d="M46 4L4 46"
                />
            </svg>
        </div>
    );
};

export default SolidIcon;
