/**
 * Utility types that do not belong to other categories.
 */

/**
 * Function that sets a new value based on the previous value.
 */
export type SetterFunction<T> = (previousValue: T) => T;
/**
 * Value or a function that sets a new value based on the previous value.
 */
export type ValueOrSetterFunction<T> = T | SetterFunction<T>;

/**
 * This refers to any kind of orientation.
 */
export enum EditorEngineOrientation {
    Horizontal = 'Horizontal',
    Vertical = 'Vertical',
}

/**
 * An enum that represents the relative position of an item in relation to
 * another item.
 */
export enum EditorEngineRelativePosition {
    Before = 'Before',
    After = 'After',
}

export enum ConsoleLogLevel {
    Muted = 'Muted',
    Debug = 'Debug',
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',
}

/**
 * Given a type with a boolean `success` property, this type extracts the type
 * that represents a failed result.
 */
export type FailedResult<TResult extends { success: boolean }> = Extract<
    TResult,
    {
        success: false;
    }
>;
