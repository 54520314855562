import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';
import qs from 'query-string';

import { logout } from '@/app/auth/models/logout';
import { saveTokensFromResponse } from '@/core/api/helper';
import { StatusCodes } from '@/core/api/types';
import { EMPTY_STRING } from '@/utils/empty';

import { fetchBoot } from './boot';
import { loginRequest, refreshTokenRequest } from '../apis';
import { NAME } from '../constants';

import type { FetchLoginBody } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

interface State {
    isLoggedIn: boolean;
    loading: boolean;
    error: string;
}

const initialState: State = {
    isLoggedIn: false,
    loading: false,
    error: '',
};

export const loginSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setIsLoggedIn(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isLoggedIn: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>) {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

// === Actions ======

export const { setLoading, setIsLoggedIn, setError } = loginSlice.actions;

// === Selectors ======

export const getIsLoggedIn = (state: AppState) => state[NAME]?.loginReducer?.isLoggedIn;

export const getError = (state: AppState) => state[NAME]?.loginReducer?.error;

export const getLoading = (state: AppState) => state[NAME]?.loginReducer?.loading;

// === Thunks ======

// set login error message
const setLoginError =
    (err: AxiosError): AppThunk =>
    (dispatch) => {
        dispatch(
            setError(
                err?.response?.status === StatusCodes.BAD_REQUEST
                    ? 'wrong-credentials'
                    : err.message,
            ),
        );
        dispatch(setLoading(false));
        dispatch(setIsLoggedIn(false));
    };

// After setting the tokens (cookies and API) -> boot and redirect
const postLogin = (): AppThunk => async (dispatch) => {
    // Boot
    await dispatch(fetchBoot());

    // Redirect
    const editorRedirectPathnameWithoutLeadingSlash = (
        !window?.location
            ? ''
            : (qs.parse(window.location.search)?.redirect_pathname as string) ?? ''
    ).replace(/^\/+/, ''); // Remove leading slashes

    await Router.push(`/${editorRedirectPathnameWithoutLeadingSlash}`);

    // clear Login state
    dispatch(setError(EMPTY_STRING));
    dispatch(setLoading(false));
};

// Fetch login token via credentials (only used in Vercel preview or local dev)
// Returns response status
export const fetchLoginWithCredentials =
    (
        body: FetchLoginBody,
        {
            triggerPostLogin = true,
            useLemonTree = false,
        }: { triggerPostLogin?: boolean; useLemonTree?: boolean } = {},
    ): AppThunk<Promise<number>> =>
    async (dispatch) => {
        dispatch(setLoading(true));

        try {
            // Send Login request
            const response = await loginRequest(
                {
                    email: body.email,
                    password: body.password,
                },
                useLemonTree,
            );

            // Set Tokens
            saveTokensFromResponse(response, body.rememberMe);

            if (triggerPostLogin) {
                await dispatch(postLogin());
            }

            return response?.status;
        } catch (err) {
            dispatch(setLoginError(err));

            return err?.response?.status;
        }
    };

// fetch login via refreshToken
export const fetchLoginWithRefreshToken =
    (refreshToken: string): AppThunk =>
    async (dispatch) => {
        try {
            // Send Login request
            const response = await refreshTokenRequest(refreshToken);

            // set Tokens
            saveTokensFromResponse(response);

            await dispatch(postLogin());
        } catch (err) {
            await dispatch(setLoginError(err));
            dispatch(logout());
        }
    };

export default loginSlice.reducer;
