import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import * as Select from '@radix-ui/react-select';
import find from 'lodash/find';
import get from 'lodash/get';

import { SelectContent } from '@/ui/components/_BlockEditFields/_wrappers/SelectContent';

import { Option } from './Option';

import type { DropdownOption } from '@/ui/types';

export interface Props {
    value: string;
    onChange: (value: string) => void;
    options: DropdownOption[];
    sprite: string;
    offsetMap: { [icon: string]: number };
}

export const PlatformSelection = ({ value, onChange, options, sprite, offsetMap }: Props) => {
    const selectedOption = find(options, { value });

    return (
        <Select.Root value={value} onValueChange={onChange}>
            <Select.Trigger asChild>
                <button className="group relative flex w-full items-center text-left text-sm outline-none">
                    <span className="text-xl font-medium">{get(selectedOption, 'key', '-')}</span>
                    <span className="pointer-events-none inset-y-0 ml-2 flex items-center">
                        <ChevronUpDownIcon
                            className="size-5 text-gray-400 group-hover:text-gray-600"
                            aria-hidden="true"
                        />
                    </span>
                </button>
            </Select.Trigger>

            <SelectContent>
                {options.map((option, index) => (
                    <Option
                        option={option}
                        key={`${option.key}-${index}`}
                        sprite={sprite}
                        offsetMap={offsetMap}
                    />
                ))}
            </SelectContent>
        </Select.Root>
    );
};
