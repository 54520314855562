import { combineReducers } from 'redux';

import blockOrderReducer from './blockOrder';
import blocksReducer from './blocks';
import draftEditorStatesReducer from './draftEditorStates';
import duplicateBlockReducer from './duplicate';
import inputTypesReducer from './inputTypes';
import mediaSizeReducer from './mediaSize';
import personalizationReducer from './personalization';
import sliderReducer from './slider';
import tipTapReducer from './tiptap';
import webinarReducer from './webinar';

export default combineReducers({
    blocksReducer,
    blockOrderReducer,
    draftEditorStatesReducer,
    duplicateBlockReducer,
    inputTypesReducer,
    mediaSizeReducer,
    personalizationReducer,
    webinarReducer,
    sliderReducer,
    tipTapReducer,
});
