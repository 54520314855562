import { useMemo } from 'react';

import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';
import { reportMessage } from '@/utils/sentry';

import type { RadioSliderOption, RadioSliderSelection } from '@/ui/types';
import type { MouseEvent } from 'react';

export interface Props extends RadioSliderOption {
    index: number;
    onClick: (option: RadioSliderSelection) => void;
    preventDefault?: boolean;
    size: 'small' | 'default';
}

const Item = ({
    name,
    value,
    index,
    active,
    render,
    onClick,
    tooltip,
    size,
    preventDefault = true,
}: Props) => {
    const Content = useMemo(() => render, [render]);

    const itemClass = cn(
        'inline-flex h-10 flex-1 cursor-pointer items-center justify-center rounded-md px-4 transition',
        {
            'text-blue-500': active,
            'text-gray-400 hover:text-gray-600': !active,
            'h-6 px-3': size === 'small',
        },
    );

    const handleClick = (event: MouseEvent) => {
        if (preventDefault) {
            event.preventDefault();
        }

        onClick({
            name,
            value,
            index,
        });
    };

    const handleMouseDown = (event: MouseEvent) => {
        if (preventDefault) {
            event.preventDefault();
        }
    };

    if (!Content) {
        reportMessage({
            message: 'Attempted to render radio slides without content',
            source: 'radioSlider',
        });

        return null;
    }

    return (
        <Tooltip content={tooltip}>
            <button
                className={itemClass}
                onClick={handleClick}
                type="button"
                onMouseDown={handleMouseDown}
            >
                <Content />
            </button>
        </Tooltip>
    );
};

export default Item;
