import { MULTI_SELECT_SEPARATOR } from '@/app/crm/constants';

import { Listbox } from '@headlessui/react';
import { useEffect, useState } from 'react';

import { EMPTY_ARRAY } from '@/utils/empty';

import { ListBoxDropdown } from './ListBoxDropdown';

import type { Props as ListBoxDropdownProps } from './ListBoxDropdown';
import type { SelectOption } from '@/app/crm/types';
import type { MouseEvent } from 'react';
import type { FieldRenderProps } from 'react-final-form';

interface Props extends FieldRenderProps<string> {
    onSubmit: () => void;
    options: SelectOption[];
    inline?: ListBoxDropdownProps['inline'];
}

export const MultiSelectInput = ({ input, options, onSubmit, inline }: Props) => {
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        const value = input.value?.trim();
        const selectedTextValue = selected.join(MULTI_SELECT_SEPARATOR);

        if (selectedTextValue !== value) {
            const selectedValue = value ? value.split(MULTI_SELECT_SEPARATOR) : EMPTY_ARRAY;
            setSelected(selectedValue);
        }
    }, [input.value, selected]);

    const handleChange = (value: string[]) => {
        setSelected(value);

        const textValue = value.join(MULTI_SELECT_SEPARATOR);
        input.onChange(textValue);

        onSubmit();
    };

    const handleDeselectOption = (index: number) => (event: MouseEvent) => {
        event.preventDefault();

        const updatedSelection = [...selected];
        updatedSelection.splice(index, 1);

        handleChange(updatedSelection);
    };

    return (
        <Listbox onChange={handleChange} value={selected} multiple>
            {({ open }) => {
                return (
                    <ListBoxDropdown
                        options={options}
                        selected={selected}
                        open={open}
                        inline={inline}
                        handleDeselectOption={handleDeselectOption}
                        isMultiSelect
                    />
                );
            }}
        </Listbox>
    );
};
