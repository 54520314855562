import type { EditorState, RawDraftContentState } from 'draft-js';

// `convertFromRaw` expects an `entityMap` object in the raw state
// `entityMap` might be missing since mongodb strips out empty objects
export const fixRawState = (state: RawDraftContentState) => {
    const fixedState = Object.assign({}, state);

    if (!fixedState.entityMap) {
        fixedState.entityMap = {};
    }

    return fixedState;
};

// check if there is text
export const hasText = (editorState: EditorState): boolean => {
    const content = editorState.getCurrentContent();
    const isEditorEmpty = !content.hasText();
    const currentPlainText = content.getPlainText();
    const lengthOfTrimmedContent = currentPlainText.trim().length;

    return !!(!isEditorEmpty && lengthOfTrimmedContent);
};
