import unescape from 'lodash/unescape';

import { MISSING_SCRIPT } from '@/app/editor/blocks/components/Embed/helper';

import { googleMapsDefaultValidator } from './googleMapsDefaultValidator';
import { kununuDefaultValidator } from './kununuDefaultValidator';
import { provenexpertDefaultValidator } from './provenexpertDefaultValidator';
import { provenexpertRatingValidator } from './provenexpertRatingValidator';
import { trustpilotDefaultValidator } from './trustpilotDefaultValidator';

export const kununuValidator = (head: string, body: string) => {
    if (!body) {
        return {
            valid: false,
            message: MISSING_SCRIPT,
        };
    }

    const container = document.createElement('div');
    container.innerHTML = unescape(body)
        .trim()
        .replace(/\r?\n|\r/g, '');

    return kununuDefaultValidator(container);
};

export const trustpilotValidator = (head: string, body: string) => {
    if (!head || !body) {
        return {
            valid: false,
            message: MISSING_SCRIPT,
        };
    }

    const headContainer = document.createElement('div');
    const bodyContainer = document.createElement('div');

    return trustpilotDefaultValidator(headContainer, bodyContainer, head, body);
};

export const provenexpertValidator = (head: string, body: string) => {
    if (!body) {
        return {
            valid: false,
            message: MISSING_SCRIPT,
        };
    }

    const container = document.createElement('div');
    container.innerHTML = unescape(body)
        .trim()
        .replace(/\r?\n|\r/g, '');

    const ratingSnippet = container.querySelector('#pewl');

    if (ratingSnippet) {
        return provenexpertRatingValidator(container);
    }

    return provenexpertDefaultValidator(container, head, body);
};

export const googleMapsValidator = (head: string, body: string) => {
    if (!body) {
        return {
            valid: false,
            message: MISSING_SCRIPT,
        };
    }

    const container = document.createElement('div');
    container.innerHTML = unescape(body)
        .trim()
        .replace(/\r?\n|\r/g, '');

    return googleMapsDefaultValidator(container);
};

export const customHtmlValidator = (
    head: string,
    body: string,
): { message: string; valid: boolean } => {
    const normalizedBody = (body ?? '').trim();

    if (normalizedBody.length === 0) {
        return {
            valid: false,
            message: MISSING_SCRIPT,
        };
    }

    return {
        message: '',
        valid: true,
    };
};

export const providerValidatorMap = {
    kununu: kununuValidator,
    trustpilot: trustpilotValidator,
    provenexpert: provenexpertValidator,
    'google-maps': googleMapsValidator,
    'custom-html': customHtmlValidator,
};
