import isEmail from 'validator/lib/isEmail';

export const validateEmail = (email: string) => isEmail(email);

export const validatePossibleEmptySlug = (value: string): boolean =>
    /^$|^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(value);
export const validateSlug = (value: string): boolean => /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(value);
export const validatePageSlug = (value: string) => /^[a-z0-9]+(?:[-_][a-z0-9]+)*$/.test(value);

export const validateFunnelUrl = (value: string): boolean => {
    const isValueNotOnlyNumbers = !/^\d+$/.test(value);

    return isValueNotOnlyNumbers && validatePossibleEmptySlug(value);
};

export const validateSlugReduxForm = (value: string) => {
    const result = validateSlug(value);

    if (!value || result) {
        return undefined;
    }

    return 'invalid-slug';
};

export const validatePageSlugReduxForm = (value: string) => {
    const result = validatePageSlug(value);

    if (!value || result) {
        return undefined;
    }

    return 'invalid-page-slug';
};

export const isValidUrl = (url: string) => {
    try {
        new URL(url);

        return true;
    } catch (error) {
        return false;
    }
};

// Used to validate that switch statements cover all union cases
export const assertNever = (caseKey: never): never => {
    throw new Error('Unexpected case: ' + caseKey);
};
