import { DragOverlay, useDndContext } from '@dnd-kit/core';
import { useMemo } from 'react';

import { EditorEngineNodeDragStateType } from '@/app/editor/engine/core/types';

import type {
    EditorEngineComponent,
    EditorEngineNodeData,
    EditorEngineNode,
    EditorEngineDocument,
    EditorEngineNodeDragInfo,
} from '@/app/editor/engine/core/types';

interface Props<TDocument extends EditorEngineDocument, TNodeData extends EditorEngineNodeData> {
    /**
     * The component to render the dragged node.
     */
    Component: EditorEngineComponent<TDocument, TNodeData>;
    /**
     * The document that the editor engine is working with.
     */
    document: TDocument;
    /**
     * A function that identifies a node.
     */
    identify: (node: EditorEngineNode<TNodeData>) => string;
    /**
     * The node that is being dragged.
     */
    draggedNode: EditorEngineNode<TNodeData>;
}

/**
 * A drag overlay that renders the dragged node.
 * This node will follow the cursor while dragging.
 */
export const EditorEngineDragOverlay = <
    TDocument extends EditorEngineDocument,
    TNodeData extends EditorEngineNodeData,
>({
    Component,
    document,
    identify,
    draggedNode,
}: Props<TDocument, TNodeData>) => {
    const dragContext = useDndContext();

    const dragInfo = useMemo(
        () => ({
            type: EditorEngineNodeDragStateType.Overlay,
            isDragged: dragContext.active?.id === identify(draggedNode),
            isDragOperationInProgress: Boolean(dragContext.active),
        }),
        [dragContext.active, draggedNode, identify],
    ) satisfies EditorEngineNodeDragInfo;

    return (
        <DragOverlay dropAnimation={null}>
            {draggedNode && (
                <Component
                    document={document}
                    node={draggedNode}
                    dragInfo={dragInfo}
                    childIndex={0}
                />
            )}
        </DragOverlay>
    );
};
