import throttle from 'lodash/throttle';

import { isDropAllowed } from '@/app/editor/engine/core/utils/dragAndDrop/isDropAllowed';
import { isValidCollision } from '@/app/editor/engine/core/utils/dragAndDrop/isValidCollision';
import { isValidDraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/isValidDraggableConfiguration';
import { isValidOver } from '@/app/editor/engine/core/utils/dragAndDrop/isValidOver';

import type {
    EditorEngineDefaultTypeInput,
    EditorEngineDropCandidateSetter,
} from '@/app/editor/engine/core/types';
import type { DndContextProps } from '@dnd-kit/core';
import type { MutableRefObject } from 'react';

/**
 * Returns a function that handles the movement of a dragged item.
 */
export const getOnDragMove = <TEditorEngineTypeInput extends EditorEngineDefaultTypeInput>({
    documentManager,
    nodeManager,
    setDropCandidate,
    isDraggingRef,
}: {
    /**
     * The document manager.
     */
    documentManager: TEditorEngineTypeInput['DocumentManager'];
    /**
     * The node manager.
     */
    nodeManager: TEditorEngineTypeInput['NodeManager'];
    /**
     * A function for setting the drop candidate.
     */
    setDropCandidate: EditorEngineDropCandidateSetter<TEditorEngineTypeInput>;
    /**
     * A reference to a boolean. The value is `true` if there is currently a drag operation in progress.
     */
    isDraggingRef: MutableRefObject<boolean>;
}) => {
    const onDragMove = (({ active, over, collisions }) => {
        if (!isDraggingRef.current) {
            return;
        }

        const activeDraggableConfiguration = active.data.current?.draggableConfiguration;

        if (!isValidOver(over) || !isValidDraggableConfiguration(activeDraggableConfiguration)) {
            setDropCandidate(null);

            return;
        }

        for (const collision of collisions) {
            if (!isValidCollision<TEditorEngineTypeInput>(collision)) {
                continue;
            }

            const original = nodeManager.getNode(active.id.toString());
            const target = nodeManager.getNode(collision.id.toString());

            const { draggableConfiguration } = collision.data.droppableContainer.data.current;
            const preview = {
                original,
                target,
                futurePosition: collision.data.position,
            };
            const canBeTargeted = draggableConfiguration.canBeTargeted({
                documentManager,
                nodeManager,
                preview,
            });

            if (
                isDropAllowed({
                    nodeManager,
                    preview,
                }) &&
                canBeTargeted
            ) {
                setDropCandidate({
                    candidateId: `${collision.id}-${preview.futurePosition}`,
                    draggableConfiguration,
                    preview,
                });

                return;
            }
        }

        setDropCandidate(null);
    }) satisfies DndContextProps['onDragMove'];

    return throttle(onDragMove, 150);
};
