import omit from 'lodash/omit';
import { getFormValues } from 'redux-form';

import { apiPatch, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import { setBlock, getBlockById, getActiveBlockId, removeChildBlockById } from './blocks';
import { UpdateBlockCommand } from '../../commands/commands/updateBlockCommand';
import getHistoryController from '../../commands/utils/HistoryControllers';

import type { BlockResource, EditFormValues } from '../types';
import type { RelationshipObject } from '@/core/api/types';
import type { AppThunk } from '@/core/redux/types';

// === Thunks =======

export const updateBlock =
    (block: BlockResource): AppThunk =>
    async (dispatch, getState) => {
        const previousBlockState = getBlockById(getState(), block.id);

        // Optimistic update in Redux state
        dispatch(setBlock(block));

        // Update in DB
        try {
            const response = await apiPatch(`/components/${block.id}`, {
                data: { attributes: { content: block.attributes.content } },
            });
            const updatedBlock = getDataFromResponse(response);

            // We update the store state based on the API response (only attributes.content).
            dispatch(
                setBlock({
                    ...block,
                    attributes: {
                        ...block.attributes,
                        content: updatedBlock?.attributes.content,
                    },
                }),
            );
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating block failed:' });

            // Revert optimistic update
            if (previousBlockState) {
                dispatch(setBlock(previousBlockState));
            }
        }
    };

export const submitEditForm =
    (values: EditFormValues): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const historyController = getHistoryController();
        const additionalBlockValues = values.additionalBlocks;

        // if there are form values for additional blocks; update those as well
        if (additionalBlockValues) {
            const blockIds = Object.keys(additionalBlockValues);

            blockIds.forEach((blockId) => {
                const block = getBlockById(state, blockId);
                const updatedBlock = {
                    ...block,
                    attributes: {
                        ...block.attributes,
                        content: {
                            ...block.attributes.content,
                            ...additionalBlockValues[blockId].attributes.content,
                        },
                    },
                };

                dispatch(updateBlock(updatedBlock));
            });
        }

        // remove `additionalBlocks` from values to get plain block data from the form values
        const block = omit(values, 'additionalBlocks');

        const updateBlockCommand = new UpdateBlockCommand(block);

        historyController.executeCommand(updateBlockCommand);
    };

// Update currently active block
export const updateActiveBlock = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const blockId = getActiveBlockId(state);

    if (!blockId) {
        return;
    }

    const formData = getFormValues(blockId)(state) as BlockResource;

    dispatch(updateBlock(formData));
};

// Helper function
const createUpdatedChildren = (
    reorderedBlockIds: string[],
    parentBlock: BlockResource,
): RelationshipObject[] => {
    const reorderedChildren = reorderedBlockIds.map((blockId) => ({
        type: 'component',
        id: blockId,
    }));

    // add eventually existing child components to reordered array, e.g. submit button of forms
    const existingChildren = parentBlock.relationships.components;
    const extraChildren = existingChildren.data.filter(
        (child) => !reorderedBlockIds.includes(child.id),
    );

    return [...reorderedChildren, ...extraChildren];
};

// Sorting Child components
export const optimisticallyUpdateChildOrder =
    (parentBlockId: string, reorderedBlockIds: string[]): AppThunk<RelationshipObject[]> =>
    (dispatch, getState) => {
        const state = getState();
        const parentBlock = getBlockById(state, parentBlockId);

        const updatedChildren = createUpdatedChildren(reorderedBlockIds, parentBlock);

        // `parentBlock` itself is immutable -> create new object
        const updatedBlock = {
            ...parentBlock,
            relationships: {
                ...parentBlock.relationships,
                components: {
                    data: updatedChildren,
                },
            },
        };

        // Optimistic update in Redux state
        dispatch(setBlock(updatedBlock));

        return updatedChildren;
    };

export const updateChildOrder =
    (parentBlockId: string, reorderedBlockIds: string[]): AppThunk =>
    async (dispatch) => {
        const updatedChildren = dispatch(
            optimisticallyUpdateChildOrder(parentBlockId, reorderedBlockIds),
        );

        // Update in DB
        const updatedBlockIds = updatedChildren.map((child) => child.id);

        try {
            await apiPatch(`/components/${parentBlockId}/order`, {
                data: { components: updatedBlockIds },
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'updating child order failed:' });
        }
    };

export const optimisticallyMoveBlock =
    ({
        blockId,
        oldParentId,
        newParentId,
        newIndex,
    }: {
        blockId: string;
        oldParentId: string;
        newParentId: string;
        newIndex: number;
    }): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const block = getBlockById(state, blockId);
        const oldParent = getBlockById(state, oldParentId);
        const newParent = getBlockById(state, newParentId);

        // Optimistic update in Redux state
        // 1. remove block from old parent `components` relationships
        const oldParentBlockIds = oldParent?.relationships.components.data.map(
            (component) => component.id,
        );
        const updatedOldParentBlockIds = oldParentBlockIds?.filter((id) => id !== blockId);

        dispatch(optimisticallyUpdateChildOrder(oldParentId, updatedOldParentBlockIds));
        dispatch(removeChildBlockById(blockId));

        // 2. add block to new parent `components` relationships at the correct index
        const newParentBlockIds = newParent?.relationships.components.data.map((child) => child.id);

        // if the block is already in the new parent's `components` relationships, remove it again
        if (newParentBlockIds?.includes(blockId)) {
            newParentBlockIds?.splice(newParentBlockIds.indexOf(blockId), 1);
        }

        newParentBlockIds?.splice(newIndex, 0, blockId);

        dispatch(optimisticallyUpdateChildOrder(newParentId, newParentBlockIds));

        // 3. update moved block's `parent` relationship
        const updatedMovedBlock: BlockResource = {
            ...block,
            relationships: {
                ...block.relationships,
                parent: {
                    data: {
                        type: 'component',
                        id: newParentId,
                    },
                },
            },
        };

        dispatch(setBlock(updatedMovedBlock));
    };

export const dataMoveBlock =
    ({
        blockId,
        newParentId,
        newIndex,
    }: {
        blockId: string;
        newParentId: string;
        newIndex: number;
    }): AppThunk =>
    async () => {
        try {
            await apiPatch(`/components/${blockId}/moveBetweenColumns`, {
                data: {
                    toComponentId: newParentId,
                    index: newIndex,
                },
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'moving block failed:' });
        }
    };

export const moveBlock =
    ({
        blockId,
        oldParentId,
        newParentId,
        newIndex,
    }: {
        blockId: string;
        oldParentId: string;
        newParentId: string;
        newIndex: number;
    }): AppThunk =>
    async (dispatch) => {
        dispatch(optimisticallyMoveBlock({ blockId, oldParentId, newParentId, newIndex }));
        dispatch(dataMoveBlock({ blockId, newParentId, newIndex }));
    };
