import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useMemo } from 'react';

import {
    getActiveWorkspace,
    getWorkspaceLimit,
    getWorkspaceOrder,
    getWorkspaces,
} from '@/app/workspaces/models/workspaces';
import { useAppSelector } from '@/core/redux/hooks';

import { getHasExceededWorkspaceLimit } from '../helpers/utils';

import type { WorkspaceResource, Workspaces } from '../types';

export const useWorkspaces = (): {
    workspaceOrder: string[];
    workspaceLimit: number;
    activeWorkspace: WorkspaceResource;
    defaultWorkspace: WorkspaceResource;
    allWorkspaces: Workspaces;
    customWorkspaces: Workspaces;
    nonActiveWorkspaces: Workspaces;
    nonActiveCustomWorkspaces: Workspaces;
    hasCustomWorkspaces: boolean;
    isAllowedToHaveCustomWorkspaces: boolean;
    hasExceededWorkspaceLimit: boolean;
    sortedWorkspaces: WorkspaceResource[];
} => {
    const allWorkspaces = useAppSelector(getWorkspaces);
    const activeWorkspace = useAppSelector(getActiveWorkspace);
    const workspaceLimit = useAppSelector(getWorkspaceLimit);
    const workspaceOrder = useAppSelector(getWorkspaceOrder);

    return useMemo(() => {
        const workspaceIds = Object.keys(allWorkspaces);

        const defaultWorkspaceId = workspaceIds.find((id) => {
            return allWorkspaces?.[id]?.attributes?.default;
        });

        const defaultWorkspace = allWorkspaces?.[defaultWorkspaceId];
        const customWorkspaces = omit(allWorkspaces, [defaultWorkspaceId]);
        const nonActiveWorkspaces = omit(allWorkspaces, [activeWorkspace?.id]);
        const nonActiveCustomWorkspaces = omit(allWorkspaces, [
            activeWorkspace?.id,
            defaultWorkspaceId,
        ]);

        const hasExceededWorkspaceLimit = getHasExceededWorkspaceLimit(
            allWorkspaces,
            workspaceLimit,
        );

        const sortedWorkspaces: WorkspaceResource[] = workspaceOrder.map((workspaceId) => {
            return allWorkspaces[workspaceId];
        });

        return {
            workspaceOrder,
            workspaceLimit,
            activeWorkspace,
            defaultWorkspace,
            allWorkspaces,
            customWorkspaces,
            nonActiveWorkspaces,
            nonActiveCustomWorkspaces,
            hasCustomWorkspaces: !isEmpty(customWorkspaces),
            isAllowedToHaveCustomWorkspaces: workspaceLimit > 1,
            hasExceededWorkspaceLimit,
            sortedWorkspaces,
        };
    }, [allWorkspaces, activeWorkspace, workspaceLimit, workspaceOrder]);
};
