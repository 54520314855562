import { reset as resetDomainList } from './domainList';
import { resetSetup as resetInstantDomainSetup } from './instantSubDomainSetup';
import { resetSetup as resetOwnDomainSetup } from './ownDomainSetup';

import type { AppThunk } from '@/core/redux/types';

export const resetDomainsState = (): AppThunk => (dispatch) => {
    dispatch(resetDomainList());
    dispatch(resetOwnDomainSetup());
    dispatch(resetInstantDomainSetup());
};
