import { NAME } from '@/app/editor/blocks/constants';
import { HELP_TOOLTIP_CONTENT } from '@/app/helpTooltips/constants';

import { useTranslation } from 'next-i18next';
import { Field, FormSection, getFormValues } from 'redux-form';

import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import Linking from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Linking';
import ResultMapping from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/ResultMapping';
import { TextSize } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextSize';
import TextStyle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TextStyle';
import TrackingIdInput from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/TrackingIdInput';
import Subheading from '@/app/editor/editor/components/Sidebar/BlockEdit/Subheading';
import {
    getColorByLightness,
    getThemeColor,
    getThemedOrCustomColor,
} from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';
import { SidebarPortalTarget } from '@/ui/components/_BlockEditFields/SidebarPortalTarget';

import { getNextHigherParentByType } from '../../../models/blocks';

import type { BlockResource, DefaultEditFormProps, TextSizeShort } from '@/app/editor/blocks/types';

export interface Props
    extends Pick<
        DefaultEditFormProps,
        'blockId' | 'handleSubmit' | 'activeTheme' | 'pageId' | 'campaignId'
    > {
    isTipTap: boolean;
}

const textSizes: TextSizeShort[] = ['S', 'M', 'L'];

const QuestionMediaAnswerForm = ({
    blockId,
    pageId,
    campaignId,
    handleSubmit,
    activeTheme,
    isTipTap,
}: Props) => {
    const { t } = useTranslation(NAME);
    const formValues = useAppSelector((state) => getFormValues(blockId)(state) as BlockResource);
    const questionMediaAnswer = useAppSelector((state) =>
        getNextHigherParentByType(state, blockId, 'questionMediaAnswer'),
    );

    const backgroundColor = getThemedOrCustomColor(
        formValues?.attributes?.content?.box?.backgroundColor,
        'formFieldBackgroundColor',
        activeTheme,
    );

    const calculatedTextColor = getColorByLightness(
        formValues?.attributes?.content?.color,
        backgroundColor,
    );

    return (
        <>
            {isTipTap ? (
                <>
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-size-portal-${blockId}`} />
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-styles-portal-${blockId}`} />
                </>
            ) : (
                <>
                    <TextSize textBlockId={blockId} sizes={textSizes} />
                    <TextStyle textBlockId={blockId} hideLink />
                </>
            )}

            <div className="grid grid-cols-3 gap-2">
                <Field
                    name="attributes.content.box.backgroundColor"
                    component={Color}
                    expand="right"
                    submit={handleSubmit}
                    themeColor={getThemeColor(activeTheme, 'formFieldBackgroundColor')}
                    tooltip={t('background-color')}
                />

                {isTipTap ? (
                    <SidebarPortalTarget id={`tip-tap-sidebar-text-color-portal-${blockId}`} />
                ) : (
                    <Field
                        name="attributes.content.color"
                        component={Color}
                        expand="right"
                        submit={handleSubmit}
                        themeColor={calculatedTextColor}
                        tooltip={t('font-color')}
                    />
                )}
            </div>

            <Subheading
                text={t('linking')}
                className="my-4"
                helpTooltip={HELP_TOOLTIP_CONTENT.EDIT_FORM_LINKING}
            />

            <FormSection name="additionalBlocks">
                <FormSection name={questionMediaAnswer?.id}>
                    <Field
                        name="attributes.content.linking"
                        component={Linking}
                        pageId={pageId}
                        blockId={questionMediaAnswer?.id}
                        campaignId={campaignId}
                        submit={handleSubmit}
                    />

                    <ResultMapping
                        pageId={pageId}
                        campaignId={campaignId}
                        blockId={questionMediaAnswer?.id}
                        className="mt-2"
                    />

                    <div className="mt-2">
                        <Field
                            name="attributes.content.fieldName"
                            component={TrackingIdInput}
                            submit={handleSubmit}
                        />
                    </div>
                </FormSection>
            </FormSection>
        </>
    );
};

export default QuestionMediaAnswerForm;
