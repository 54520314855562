import { BorderMenuItem } from '@/app/editor/blocks/types';

import { DEFAULT_BLOCK_CONFIG } from '../../../editor/constants';

import type { BlockConfig } from '@/app/editor/blocks/types';

const config: BlockConfig = {
    ...DEFAULT_BLOCK_CONFIG,
    borderMenu: {
        ...DEFAULT_BLOCK_CONFIG.borderMenu,
        items: [BorderMenuItem.delete, BorderMenuItem.move, BorderMenuItem.duplicate],
        showDuplicateAsAdd: true,
        addTooltip: 'add-item',
    },
    actions: {
        getAdditionalBlocksConfig: () => {
            return [
                {
                    paths: [
                        'attributes.content.align',
                        'attributes.content.visualSize',
                        'attributes.content.textSize',
                    ],
                    target: 'parent',
                },
            ];
        },
    },
};

export default config;
