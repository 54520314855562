import {
    EditorEngineHistoryEntryExecutionAvailability,
    EditorEngineHistoryEntryRecordedActionStatusType,
    EditorEngineHistoryEntryRecordedStepStatusType,
} from '@/app/editor/engine/core/types';

import type { useProcessingQueue } from '@/app/editor/engine/core/hooks/queue/useProcessingQueue';
import type {
    EditorEngineHistoryDefaultEntry,
    EditorEnginePersistedUpdate,
} from '@/app/editor/engine/core/types';

/**
 * Given an entry, get its execution availability.
 *
 * Other utilities, such as the ones that check if an entry can be undone or
 * redone, use the execution availability instead of the entry itself.
 *
 * For this reason it is best to keep the availability calculation separate
 * from the undo/redo evaluation. The former will not change or change very
 * rarely, while it is easy to reason about the latter should that logic change.
 */
export const getEntryExecutionAvailability = ({
    entry,
    isInQueue,
}: {
    /**
     * The entry for which to get the execution availability.
     */
    entry: EditorEngineHistoryDefaultEntry;
    /**
     * Returns whether an entry is in the queue for a persisted update.
     */
    isInQueue: ReturnType<typeof useProcessingQueue<EditorEnginePersistedUpdate>>['isInQueue'];
}) => {
    if (
        [
            EditorEngineHistoryEntryRecordedActionStatusType.FailedBySteps,
            EditorEngineHistoryEntryRecordedActionStatusType.Failed,
        ].includes(entry.recordedAction.executionResult.type)
    ) {
        return EditorEngineHistoryEntryExecutionAvailability.Failed;
    }

    if (
        entry.recordedAction.executionResult.type ===
        EditorEngineHistoryEntryRecordedActionStatusType.ExecutingPersistedUpdate
    ) {
        return EditorEngineHistoryEntryExecutionAvailability.OngoingPersistedUpdate;
    }

    if (
        entry.recordedSteps.some(
            (eachStep) =>
                eachStep.executionResult.type ===
                EditorEngineHistoryEntryRecordedStepStatusType.ExecutingOptimisticUpdate,
        )
    ) {
        return EditorEngineHistoryEntryExecutionAvailability.OngoingOptimisticUpdate;
    }

    if (isInQueue(entry.id)) {
        return EditorEngineHistoryEntryExecutionAvailability.QueuedForPersistedUpdate;
    }

    if (
        entry.recordedAction.executionResult.type ===
            EditorEngineHistoryEntryRecordedActionStatusType.ExecutedSuccessfully &&
        entry.recordedSteps.every(
            (eachStep) =>
                eachStep.executionResult.type ===
                EditorEngineHistoryEntryRecordedStepStatusType.ExecutedSuccessfully,
        )
    ) {
        return EditorEngineHistoryEntryExecutionAvailability.Successful;
    }

    return EditorEngineHistoryEntryExecutionAvailability.UnknownStatus;
};
