import { createSlice } from '@reduxjs/toolkit';
import qs from 'query-string';

import { getCampaignWithPagination } from '@/app/campaigns/helpers';
import { getActiveWorkspaceId, getWorkspaces } from '@/app/workspaces/models/workspaces';
import { apiGet, handleRuntimeError } from '@/core/api';
import { EMPTY_ARRAY } from '@/utils/empty';

import { NAME } from '../constants';
import { createCampaignsQuery } from '../helper';

import type { CampaignResource, CampaignsWithPagination } from '@/app/campaigns/types';
import type { Pagination } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    workspacePagination: Pagination;
    workspaceCampaigns: CampaignResource[];
    otherPagination: Pagination;
    otherCampaigns: CampaignResource[];
}

const initialState: State = {
    workspacePagination: undefined,
    workspaceCampaigns: EMPTY_ARRAY,
    otherPagination: undefined,
    otherCampaigns: EMPTY_ARRAY,
};

export const workspaceCampaignsSlice = createSlice({
    name: `${NAME}/workspaceCampaigns`,
    initialState,
    reducers: {
        setWorkspaceCampaigns(state, action: PayloadAction<CampaignResource[]>) {
            state.workspaceCampaigns = action.payload;
        },

        appendWorkspaceCampaigns(state, action: PayloadAction<CampaignResource[]>) {
            state.workspaceCampaigns.push(...action.payload);
        },
        setOtherCampaigns(state, action: PayloadAction<CampaignResource[]>) {
            state.otherCampaigns = action.payload;
        },
        appendOtherCampaigns(state, action: PayloadAction<CampaignResource[]>) {
            state.otherCampaigns.push(...action.payload);
        },
        setWorkspacePagination(state, action: PayloadAction<Pagination>) {
            state.workspacePagination = action.payload;
        },
        setOtherPagination(state, action: PayloadAction<Pagination>) {
            state.otherPagination = action.payload;
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    setWorkspaceCampaigns,
    appendWorkspaceCampaigns,
    setOtherCampaigns,
    appendOtherCampaigns,
    setOtherPagination,
    setWorkspacePagination,
    reset,
} = workspaceCampaignsSlice.actions;

// === Selectors ======

export const getWorkspaceCampaigns = (state: AppState) =>
    state[NAME]?.workspaceCampaignsReducer?.workspaceCampaigns;

export const getOtherCampaigns = (state: AppState) =>
    state[NAME]?.workspaceCampaignsReducer?.otherCampaigns;

export const getWorkspacePagination = (state: AppState) =>
    state[NAME]?.workspaceCampaignsReducer?.workspacePagination;

export const getOtherPagination = (state: AppState) =>
    state[NAME]?.workspaceCampaignsReducer?.otherPagination;

// === Thunks ======

export const fetchActiveWorkspaceCampaigns =
    (search = '', canUpdateCampaign: boolean): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const activeWorkspaceId = getActiveWorkspaceId(state);

        const query = createCampaignsQuery({
            search,
            workspaceIds: [activeWorkspaceId],
            canUpdateCampaign,
        });

        try {
            const response = await apiGet(`/campaigns/filter?${qs.stringify(query)}`);

            const { pagination, campaigns } = getCampaignWithPagination(response);

            dispatch(setWorkspaceCampaigns(campaigns));
            dispatch(setWorkspacePagination(pagination));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'fetching workspace campaigns failed:' });
        }
    };

export const fetchNextCampaignsPage =
    (nextUrl: string): AppThunk<Promise<CampaignsWithPagination>> =>
    async () => {
        try {
            const response = await apiGet(nextUrl);

            return getCampaignWithPagination(response);
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: 'fetching next campaigns page failed:',
            });
        }
    };

export const fetchNextWorkspaceCampaigns = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const nextUrl = getWorkspacePagination(state)?.next;

    if (!nextUrl) {
        return;
    }

    const { pagination, campaigns } = await dispatch(fetchNextCampaignsPage(nextUrl));

    dispatch(appendWorkspaceCampaigns(campaigns));
    dispatch(setWorkspacePagination(pagination));
};

export const fetchOtherCampaigns =
    (search = '', canUpdateCampaign: boolean): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();

        const activeWorkspaceId = getActiveWorkspaceId(state);
        const workspaceIds = Object.keys(getWorkspaces(state))?.filter(
            (workspaceId) => workspaceId !== activeWorkspaceId,
        );

        if (!workspaceIds?.length) {
            return;
        }

        const query = createCampaignsQuery({ search, workspaceIds, canUpdateCampaign });

        try {
            const response = await apiGet(`/campaigns/filter?${qs.stringify(query)}`);

            const { pagination, campaigns } = getCampaignWithPagination(response);

            dispatch(setOtherCampaigns(campaigns));
            dispatch(setOtherPagination(pagination));
        } catch (err) {
            handleRuntimeError(err, {
                debugMessage: 'fetching other campaigns failed:',
            });
        }
    };

export const fetchNextOtherCampaigns = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const nextUrl = getOtherPagination(state)?.next;

    if (!nextUrl) {
        return;
    }

    const { pagination, campaigns } = await dispatch(fetchNextCampaignsPage(nextUrl));

    dispatch(appendOtherCampaigns(campaigns));
    dispatch(setOtherPagination(pagination));
};

export default workspaceCampaignsSlice.reducer;
