import { useContext } from 'react';

import { DraggableConfiguration } from '@/app/editor/engine/core/utils/dragAndDrop/configuration/builder/DraggableConfiguration';

import type { createDraggableConfigurationContext } from '@/app/editor/engine/core/context/createDraggableConfigurationContext';
import type {
    EditorEngineDefaultTypeInput,
    EditorEngineDraggableConfiguration,
} from '@/app/editor/engine/core/types';
import type { ValueOrSetterFunction } from '@/app/editor/engine/core/types/util';
import type { ReactNode } from 'react';

interface Props<TEditorEngineTypeInput extends EditorEngineDefaultTypeInput> {
    /**
     * The subtree to which the draggable configuration should be applied.
     */
    children: ReactNode;
    /**
     * The drag and drop context to use.
     */
    dragAndDropContext: ReturnType<
        typeof createDraggableConfigurationContext<TEditorEngineTypeInput>
    >['context'];
    /**
     * The draggable configuration to use.
     *
     * This can be a new draggable configuration or a function that receives the
     * inherited draggable configuration and returns a new one.
     */
    draggableConfiguration:
        | DraggableConfiguration<TEditorEngineTypeInput>
        | ValueOrSetterFunction<EditorEngineDraggableConfiguration<TEditorEngineTypeInput>>;
}

/**
 * Override the draggable configuration for a subtree.
 *
 * This component can be used at any point within the Editor Engine instance
 * context to override the draggable configuration for a subtree.
 *
 * The override can either define a whole new draggable configuration or reuse
 * the existing one and modify it.
 *
 * This component is not specific to a single Editor Engine instance, so it must
 * receive the drag and drop context.
 */
export const DraggableConfigurationInheritedValueAwareOverride = <
    TEditorEngineTypeInput extends EditorEngineDefaultTypeInput,
>({
    children,
    dragAndDropContext,
    draggableConfiguration,
}: Props<TEditorEngineTypeInput>) => {
    const inheritedDraggableConfiguration = useContext(dragAndDropContext);
    const resolvedConfiguration =
        draggableConfiguration instanceof DraggableConfiguration
            ? draggableConfiguration.getConfiguration()
            : draggableConfiguration;

    const newValue =
        typeof resolvedConfiguration === 'function'
            ? resolvedConfiguration(inheritedDraggableConfiguration)
            : resolvedConfiguration
              ? resolvedConfiguration
              : inheritedDraggableConfiguration;

    return <dragAndDropContext.Provider value={newValue}>{children}</dragAndDropContext.Provider>;
};
