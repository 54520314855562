import { useContext, useMemo } from 'react';

import { PropsFromParentOverrideProvider } from '@/app/editor/engine/core/components/view/PropsFromParentOverrideProvider';
import { isPreviewContext } from '@/app/editor/engine/core/context/isPreviewContext';
import { usePropsFromParent } from '@/app/editor/engine/hooks/usePropsFromParent';

import type { EditorEngineCustomNodeWrapper } from '@/app/editor/engine/core/types';
import type { PerspectiveEditorEngineTypeInput } from '@/app/editor/engine/types';

/**
 * A custom wrapper for the Perspective Editor Engine which will disable drag
 * and drop when the block is a preview, and will apply the wrapper class
 * received in the props from parent.
 */
export const PerspectiveEditorEngineNodeWrapper = (({ Wrapper, ...rest }) => {
    const isPreview = useContext(isPreviewContext);
    const inheritedPropsFromParent = usePropsFromParent();
    const sortableOptions = useMemo(() => (isPreview ? { disabled: true } : {}), [isPreview]);

    return (
        <PropsFromParentOverrideProvider propsFromParent={inheritedPropsFromParent}>
            <Wrapper
                {...rest}
                sortableOptions={sortableOptions}
                wrapperClass={inheritedPropsFromParent.outerWrapperClass}
            />
        </PropsFromParentOverrideProvider>
    );
}) satisfies EditorEngineCustomNodeWrapper<PerspectiveEditorEngineTypeInput>;
