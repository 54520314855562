import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { getFormValues } from 'redux-form';
import { Field, reduxForm } from 'redux-form';

import BoxSettings from '@/app/editor/blocks/components/_helper/BoxSettings';
import { getLineStyle } from '@/app/editor/blocks/components/Divider/helper';
import { useLayoutInfo } from '@/app/editor/blocks/hooks/useLayoutInfo';
import Color from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Color';
import LineStyle from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/LineStyle';
import { getThemeColor } from '@/app/editor/themes/helpers';
import { useAppSelector } from '@/core/redux/hooks';

import type { BlockResource, DefaultEditFormProps } from '@/app/editor/blocks/types';
import type { LineType } from '@/app/editor/blocks/types';
import type { FC } from 'react';
import type { InjectedFormProps } from 'redux-form';

export interface Props extends DefaultEditFormProps {}

const Form: FC<Props & InjectedFormProps> = ({
    blockId,
    handleSubmit,
    change,
    initialValues,
    activeTheme,
    form,
}) => {
    const { t } = useTranslation(NAME);
    const [currentStyle, setCurrentStyle] = useState(getLineStyle(initialValues));
    const formValues = useAppSelector((state) => getFormValues(form)(state)) as BlockResource;
    const { isInColumn } = useLayoutInfo(form);

    useEffect(() => {
        const lineStyle = getLineStyle(formValues);

        setCurrentStyle(lineStyle);
    }, [formValues]);

    const handleLineStyleChange = (style: LineType) => {
        switch (style) {
            case 'hidden':
                change('attributes.content.hidden', true);
                change('attributes.content.solid', false);
                change('attributes.content.dashed', false);
                break;
            case 'solid':
                change('attributes.content.hidden', false);
                change('attributes.content.solid', true);
                change('attributes.content.dashed', false);
                break;
            case 'dashed':
                change('attributes.content.hidden', false);
                change('attributes.content.solid', false);
                change('attributes.content.dashed', true);
                break;
            default:
                break;
        }

        setTimeout(handleSubmit);
    };

    return (
        <>
            <LineStyle value={currentStyle} onChange={handleLineStyleChange} />

            <div className="grid grid-cols-3 gap-2">
                <Field
                    name="attributes.content.color"
                    component={Color}
                    expand="right"
                    submit={handleSubmit}
                    themeColor={getThemeColor(activeTheme, 'fontColor')}
                    tooltip={t('stroke-color')}
                />
            </div>

            <BoxSettings
                blockId={blockId}
                activeTheme={activeTheme}
                handleSubmit={handleSubmit}
                hiddenColor={isInColumn}
            />
        </>
    );
};

Form.displayName = 'DividerEditForm';

export default reduxForm({})(Form);
