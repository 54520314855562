import { cn } from '@/utils/cn';

import { AnswerPill } from './AnswerPill';

import type { MouseEvent } from 'react';

interface Props {
    selected: string[];
    inline?: boolean;
    answerClassName?: string;
    handleDeselectOption?: (index: number) => (event: MouseEvent) => void;
}

export const AnswerPills = ({ selected, inline, answerClassName, handleDeselectOption }: Props) => {
    const wrapperClassName = cn('flex max-w-full gap-1', { 'flex-wrap': !inline });

    const [option, ...restOptions] = selected;
    const remainingCount = inline && restOptions.length;

    return (
        <div className={wrapperClassName}>
            <AnswerPill
                inline={inline}
                className={cn(answerClassName, {
                    'max-w-[calc(100%-34px)]': !!remainingCount,
                    'max-w-full': !remainingCount,
                })}
                onDeselect={handleDeselectOption?.(0)}
            >
                {option}
            </AnswerPill>

            {inline
                ? !!remainingCount && (
                      <AnswerPill
                          className={cn('w-[34px] shrink-0', answerClassName)}
                      >{`+${remainingCount}`}</AnswerPill>
                  )
                : restOptions.map((opt, idx) => (
                      <AnswerPill
                          key={idx}
                          inline={inline}
                          className={cn(answerClassName, {
                              'max-w-[calc(100%-34px)]': !!remainingCount,
                              'max-w-full': !remainingCount,
                          })}
                          onDeselect={handleDeselectOption?.(idx + 1)}
                      >
                          {opt}
                      </AnswerPill>
                  ))}
        </div>
    );
};
