export const Product = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="11"
                y="2"
                width="20"
                height="9"
                rx="2"
                transform="rotate(90 11 2)"
                fill="#007AFF"
            />
            <path
                opacity="0.15"
                d="M20 2C21.1046 2 22 2.89543 22 4L22 20C22 21.1046 21.1046 22 20 22L15 22C13.8954 22 13 21.1046 13 20L13 4C13 2.89543 13.8954 2 15 2L20 2Z"
                fill="#007AFF"
            />
        </svg>
    );
};
