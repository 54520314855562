import { store } from '@/core/redux/store';

import { getCampaignIdFromRouter } from '@/utils/getCampaignIdFromRouter';

import { deleteBlock } from '../../blocks/models/delete';
import { duplicateBlock } from '../../blocks/models/duplicate';
import { getActivePageId, setActivePage } from '../../pages/models/pages';

import type { Command } from '../types';

export class DuplicateBlockCommand implements Command {
    private previousActivePageId: string;
    private newBlockId?: string;

    constructor(
        private blockId: string,
        private data: {} = {},
    ) {}

    private async updateView() {
        const state = store.getState();
        const activePageId = getActivePageId(state);
        const activeCampaignId = getCampaignIdFromRouter();

        if (activePageId !== this.previousActivePageId) {
            await store.dispatch(setActivePage(activeCampaignId, this.previousActivePageId));
        }

        const blockElement = document.getElementById(this.blockId);

        if (blockElement) {
            blockElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }

    public async execute() {
        const state = store.getState();

        const newBlock = await store.dispatch(duplicateBlock(this.blockId, this.data));

        this.previousActivePageId = getActivePageId(state);
        this.newBlockId = newBlock?.id;

        return { success: true, canUndo: true };
    }

    public async undo() {
        await this.updateView();
        await store.dispatch(deleteBlock(this.newBlockId));

        return { success: true, canRedo: true };
    }

    public async redo() {
        await this.updateView();

        return await this.execute();
    }
}
