import type { FontSizeDescriptor, FontSizeValue } from '@/app/editor/blocks/components/Text/types';

// This is the single source of truth
// regarding font sizes for the TipTap editor.
export const FONT_SIZES: Record<FontSizeValue, FontSizeDescriptor> = {
    checkbox: {
        internal: true,
        advanced: false,
        labelI18nKey: 'blocks:font-size-small',
        abbreviationI18nKey: 'blocks:font-size-abbreviation-small',
        base: {
            sizePx: 14,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
        desktop: {
            sizePx: 14,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
    },
    '14px': {
        base: {
            sizePx: 14,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
        desktop: {
            sizePx: 14,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
        advanced: true,
    },
    small: {
        advanced: false,
        labelI18nKey: 'blocks:font-size-small',
        abbreviationI18nKey: 'blocks:font-size-abbreviation-small',
        base: {
            sizePx: 16,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
        desktop: {
            sizePx: 16,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
    },
    '18px': {
        base: {
            sizePx: 18,
            lineHeight: 1.5,
            letterSpacing: 'normal',
        },
        desktop: {
            sizePx: 20,
            lineHeight: 1.25,
            letterSpacing: 'normal',
        },
        advanced: true,
    },
    medium: {
        advanced: false,
        labelI18nKey: 'blocks:font-size-medium',
        abbreviationI18nKey: 'blocks:font-size-abbreviation-medium',
        base: {
            sizePx: 20,
            lineHeight: 1.25,
            letterSpacing: 'normal',
        },
        desktop: {
            sizePx: 24,
            lineHeight: 1.25,
            letterSpacing: 'normal',
        },
    },
    large: {
        advanced: false,
        labelI18nKey: 'blocks:font-size-large',
        abbreviationI18nKey: 'blocks:font-size-abbreviation-large',
        base: {
            sizePx: 24,
            lineHeight: 1.25,
            letterSpacing: 'normal',
        },
        desktop: {
            sizePx: 36,
            lineHeight: 1.15,
            letterSpacing: '-0.02em',
        },
    },
    xLarge: {
        advanced: false,
        labelI18nKey: 'blocks:font-size-xLarge',
        abbreviationI18nKey: 'blocks:font-size-abbreviation-xLarge',
        base: {
            sizePx: 30,
            lineHeight: 1.15,
            letterSpacing: '-0.02em',
        },
        desktop: {
            sizePx: 48,
            lineHeight: 1.15,
            letterSpacing: '-0.02em',
        },
    },
    '40px': {
        base: {
            sizePx: 40,
            lineHeight: 1.1,
            letterSpacing: '-0.02em',
        },
        desktop: {
            sizePx: 56,
            lineHeight: 1.1,
            letterSpacing: '-0.02em',
        },
        advanced: true,
    },
};

export const FontSizeValues = Object.keys(FONT_SIZES) as FontSizeValue[];
