import { EditorEngineEventType } from '@/app/editor/engine/core/types';
import { EditorEngineCriticalError } from '@/app/editor/engine/utils/error/EditorEngineCriticalError';
import { makeEditorEngineError } from '@/app/editor/engine/utils/error/makeEditorEngineError';

import type { EditorEngineEventEmitter } from '@/app/editor/engine/core/types';
import type { ErrorInfo } from 'react';

/**
 * Handle an error caught by the Editor Engine error boundary.
 */
export const handleError = ({
    error,
    errorInfo,
    eventEmitter,
}: {
    error: Error;
    errorInfo?: ErrorInfo;
    eventEmitter: EditorEngineEventEmitter;
}) => {
    if (error instanceof EditorEngineCriticalError) {
        eventEmitter.emit({
            name: EditorEngineEventType.CriticalError,
            payload: {
                error: makeEditorEngineError({
                    error,
                    debug: errorInfo
                        ? {
                              componentStack: errorInfo.componentStack,
                          }
                        : {},
                }),
            },
        });

        return;
    }

    eventEmitter.emit({
        name: EditorEngineEventType.UnknownError,
        payload: {
            error: makeEditorEngineError({
                error,
                debug: {},
            }),
        },
    });
};
