import { createSlice } from '@reduxjs/toolkit';

import { getCompany } from '@/app/company/models/company';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import { NAME } from '../constants';

import type { ChargebeePortalSession } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    session: ChargebeePortalSession;
}

const initialState: State = {
    session: null,
};

export const portalSlice = createSlice({
    name: `${NAME}/portal`,
    initialState,
    reducers: {
        reset: () => initialState,
        setSession: (state, action: PayloadAction<ChargebeePortalSession>) => {
            state.session = action.payload;
        },
    },
});

// === Actions ======

export const { reset, setSession } = portalSlice.actions;

// === Selectors ======

export const getPortalSession = (state: AppState) => state[NAME]?.portal?.session;

// === Thunks ======

export const createUserPortalSession = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const company = getCompany(state);

    try {
        const response = await apiPost(`/companies/${company.id}/portal`, {});

        await dispatch(setSession(getDataFromResponse(response)));
    } catch (err) {
        handleRuntimeError(err, { debugMessage: 'creating user portal failed:' });
    }
};

export default portalSlice.reducer;
