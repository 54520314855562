import { store } from '@/core/redux/store';

import { getBlockById } from '@/app/editor/blocks/models/blocks';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { AppState } from '@/core/redux/types';

export const getAllChildrenBlocksRecursively = (
    block: BlockResource,
    state: AppState = store.getState(),
) => {
    const componentsToReturn = [block];

    const getChildBlocks = (block: BlockResource) => {
        const childComponentIds = block?.relationships?.components?.data?.map(
            (component) => component.id,
        );
        const childComponents = childComponentIds?.map((id) => getBlockById(state, id));

        if (childComponents?.length > 0) {
            return childComponents;
        }
    };

    const traverseBlocks = (blocks: BlockResource[]) => {
        if (!blocks) {
            return componentsToReturn;
        }

        componentsToReturn.push(...blocks);

        blocks.forEach((block) => {
            const childBlocks = getChildBlocks(block);

            traverseBlocks(childBlocks);
        });
    };

    traverseBlocks(getChildBlocks(block));

    return componentsToReturn;
};
