import type {
    EditorEngineHistoryDefaultEntry,
    EditorEngineHistoryDefaultEntryCollection,
} from '@/app/editor/engine/core/types';

/**
 * Get the index of the entry.
 */
export const indexOfEntry = ({
    entries,
    ...rest
}: {
    /**
     * The entries to search in.
     */
    entries: EditorEngineHistoryDefaultEntryCollection['entries'];
} & (
    | {
          /**
           * The entry to get the index of.
           */
          entry: EditorEngineHistoryDefaultEntry | null;
          entryId?: never;
      }
    | {
          /**
           * The ID of the entry to get the index of.
           */
          entryId: string;
          entry?: never;
      }
)) => {
    const entryId = 'entryId' in rest ? rest.entryId : rest.entry?.id ?? null;

    if (!entryId) {
        return -1;
    }

    return entries.findIndex((eachEntry) => eachEntry.id === entryId);
};
