import { NAME } from '@/app/editor/blocks/constants';

import { useTranslation } from 'next-i18next';

import { Align } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Align';

import type { AlignFormProps } from '@/app/editor/editor/components/Sidebar/BlockEdit/elements/Align';

export const HeaderLogoAlign = (props: AlignFormProps) => {
    const { t } = useTranslation(NAME);

    return (
        <Align
            {...props}
            tooltip={t(props.disabled ? 'header.logo.align.disabled' : 'header.logo.align.enabled')}
            showTooltipWhenDisabled={true}
        />
    );
};
