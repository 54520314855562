import { createSlice } from '@reduxjs/toolkit';

import { fetchCampaign } from '@/app/campaigns/models/campaigns';
import { setActivePage, setPage } from '@/app/editor/pages/models/pages';
import { apiPost, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_STRING } from '@/utils/empty';

import { NAME, PAGE_TEMPLATES } from '../constants';

import type { PageResource, PageType } from '@/app/editor/pages/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: PageType | string;
    renameAddedPageId?: string;
}

const initialState: State = {
    loading: EMPTY_STRING,
};

export const addPageSlice = createSlice({
    name: `editor/${NAME}/add`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<PageType | string>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setRenameAddedPageId(state, action: PayloadAction<string | undefined | null>) {
            return {
                ...state,
                renameAddedPageId: action.payload ?? undefined,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, setRenameAddedPageId, reset } = addPageSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.addPageReducer?.loading;

export const getRenameAddedPageId = (state: AppState) =>
    state[NAME]?.addPageReducer?.renameAddedPageId;

// === Thunks ======

export const addPage =
    (campaignId: string, type: PageType): AppThunk =>
    async (dispatch) => {
        const templateId = PAGE_TEMPLATES[type];

        dispatch(setLoading(type));

        try {
            const response = await apiPost(`templates/pages/${templateId}/use`, {
                data: { campaign: campaignId },
            });

            const newPage = getDataFromResponse(response) as PageResource;

            // fetch updated campaign + mapping
            await dispatch(fetchCampaign(campaignId));

            // add page to redux store
            await dispatch(setPage({ campaignId, page: newPage }));

            // set active page
            dispatch(setActivePage(campaignId, newPage.id));

            // Activate renaming for newly added page
            dispatch(setRenameAddedPageId(newPage.id));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'adding page failed:' });
        } finally {
            dispatch(setLoading(EMPTY_STRING));
        }
    };

export default addPageSlice.reducer;
