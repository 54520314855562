import { NAME as SETTINGS_NAME } from '@/app/settings/constants';

import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { hideModal } from '@/app/modals/models/modals';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiDelete, apiGet, apiPatch, handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { StatusCodes } from '@/core/api/types';

import { NAME } from '../constants';

import type { UserAttributes, UserResource } from '../types';
import type { ResponseData } from '@/core/api/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Language } from 'types/generic';

interface State {
    deleting: boolean;
    loading: boolean;
    user: UserResource;
}

interface UpdateUserConfig {
    url: string;
    payload: object;
    successMessage?: string;
    successDescription?: string;
    errorMessage?: string;
}

const initialState: State = {
    deleting: false,
    loading: false,
    user: null,
};

export const userSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setDeleting(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                deleting: action.payload,
            };
        },
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setUser(state, action: PayloadAction<UserResource>) {
            return {
                ...state,
                user: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setDeleting, setLoading, setUser, reset } = userSlice.actions;

// === Selectors ======

export const getDeleting = (state: AppState) => state[NAME]?.userReducer?.deleting;
export const getLoading = (state: AppState) => state[NAME]?.userReducer?.loading;
export const getUser = (state: AppState) => state[NAME]?.userReducer?.user;
export const getUserEmail = (state: AppState) => state[NAME]?.userReducer?.user?.attributes?.email;

// === Thunks ======

export const reloadUser =
    (userId?: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const currentUser = getUser(state);

        const id = userId || currentUser.id;

        try {
            const response = await apiGet<ResponseData<UserResource>>(`/users/${id}`);

            const user = getDataFromResponse(response);

            dispatch(setUser(user));
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'reloading user failed:' });
        }
    };

// Helper function
const updateUser =
    (config: UpdateUserConfig): AppThunk =>
    async (dispatch) => {
        dispatch(setLoading(true));

        try {
            await apiPatch(config.url, {
                data: config.payload,
            });
            dispatch(reloadUser());
            await dispatch(hideModal());

            if (!config.successMessage) {
                return;
            }

            showToast({
                message: config.successMessage,
                description: config.successDescription,
                type: 'success',
            });
        } catch (err) {
            if (config.errorMessage) {
                const is400 = err?.response?.status === StatusCodes.BAD_REQUEST;

                showToast({
                    message: config.errorMessage,
                    description: is400 ? `${SETTINGS_NAME}:check-current-password` : err.message,
                    type: 'error',
                });
            } else {
                handleRuntimeError(err, { debugMessage: 'updating user failed:' });
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

export const updateEmailAddress =
    (email: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const user = getUser(state);

        dispatch(
            updateUser({
                url: `/users/${user.id}/email`,
                payload: {
                    email,
                },
                successMessage: `${SETTINGS_NAME}:verification-email-sent`,
                successDescription: `${SETTINGS_NAME}:please-verify-email`,
                errorMessage: `${SETTINGS_NAME}:trouble-changing-email`,
            }),
        );
    };

export const updatePassword =
    (oldPassword: string, newPassword: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const user = getUser(state);

        dispatch(
            updateUser({
                url: `/users/${user.id}/password`,
                payload: {
                    oldPassword,
                    newPassword,
                },
                successMessage: `${SETTINGS_NAME}:toast-password-updated`,
                errorMessage: `${SETTINGS_NAME}:trouble-changing-password`,
            }),
        );
    };

export const updateName =
    (firstName: string, lastName: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const user = getUser(state);

        dispatch(
            updateUser({
                url: `/users/${user.id}`,
                payload: {
                    attributes: {
                        firstName,
                        lastName,
                    },
                },
                successMessage: `${SETTINGS_NAME}:toast-name-updated`,
                errorMessage: `${SETTINGS_NAME}:trouble-changing-name`,
            }),
        );
    };

export const deleteWorkspaceAccount = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const user = getUser(state);

    dispatch(setDeleting(true));

    try {
        await apiDelete(`/users/${user.id}`);

        await dispatch(hideModal());

        showToast({
            message: `${SETTINGS_NAME}:toast-user-deleted`,
            type: 'success',
        });

        Router.push('/logout');
    } catch (err) {
        showToast({
            message: `${SETTINGS_NAME}:trouble-deleting-account`,
            type: 'error',
        });
    } finally {
        dispatch(setDeleting(false));
    }
};

export const updateNotificationSettings =
    (notifications: UserAttributes['notifications']): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const user = getUser(state);

        dispatch(
            updateUser({
                url: `/users/${user.id}`,
                payload: {
                    attributes: {
                        notifications,
                    },
                },
                successMessage: `${SETTINGS_NAME}:toast-notifications-updated`,
                errorMessage: `${SETTINGS_NAME}:trouble-changing-notifications`,
            }),
        );
    };

export const updateLanguage =
    (language: Language): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const user = getUser(state);

        dispatch(
            updateUser({
                url: `/users/${user.id}`,
                payload: {
                    attributes: {
                        language,
                    },
                },
            }),
        );
    };

export default userSlice.reducer;
